<panel-header class="header" (cancelEvent)="onCloseDialog('close')" header="Occupant Template Editor">
    <button *ngIf="data?.occupantTemplate.id" type="button" class="button button--delete"
        (click)="onDeleteOccupantTemplate()">
    </button>
</panel-header>

<form [formGroup]="occupantTemplateForm" (ngSubmit)="onSubmit()" class="occupant-template-form">
    <navigatable-container>
        <!-- General -->
        <navigatable-section header="General">
            <!-- Category -->
            <occupant-category-dropdown class="setting"
                formControlName="occupantCategoryId"></occupant-category-dropdown>

            <!-- Translations Info -->
            <translations-form class="setting" formControlName="translations"></translations-form>

            <!-- Aliases -->
            <aliases-list class="setting" formControlName="aliases"></aliases-list>

            <!-- Type -->
            <occupant-type-dropdown class="setting" formControlName="occupantType"></occupant-type-dropdown>
        </navigatable-section>

        <!-- Logo -->
        <navigatable-section header="Logo">
            <image-upload [imageSrc]="occupantTemplateForm.controls['logoURI'].value"
                (imageUrlChanged)="onLogoURIChange($event)"></image-upload>
        </navigatable-section>

        <!-- Opening Hours -->
        <navigatable-section header="Opening Hours" formGroupName="businessHours">
            <standard-opening-hours-form formControlName="standardOpeningHours"></standard-opening-hours-form>
        </navigatable-section>

        <!-- Address -->
        <navigatable-section header="Address">
            <address-form formControlName="address"></address-form>
        </navigatable-section>

        <!-- Contact -->
        <navigatable-section header="Contact">
            <contact-form formControlName="contactInformation"></contact-form>
        </navigatable-section>
    </navigatable-container>

    <panel-footer *ngIf="occupantTemplateForm.dirty" class="footer" text="You have unsaved changes.">
        <button type="button" class="mi-button mi-button--link" (click)="onDiscard()">Discard</button>
        <button type="submit" class="mi-button mi-button--primary" [disabled]="!occupantTemplateForm.valid">
            Save and close</button>
    </panel-footer>
</form>