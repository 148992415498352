import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'zoom-to-input',
    templateUrl: './zoom-to-input.component.html',
    styleUrls: ['./zoom-to-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ZoomToInputComponent),
            multi: true
        }
    ]
})
export class ZoomToInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
    #isLocked: boolean = false;
    @ViewChild('zoomToInput', { static: true }) zoomToInputElement: ElementRef<HTMLInputElement>;
    @ViewChild('maxZoomInput', { static: true }) maxZoomInputElement: ElementRef<HTMLInputElement>;

    /**
     * Every time we lock/unlock the setting, we enable/disable the form control.
     */
    @Input() set isLocked(locked: boolean) {
        this.#isLocked = locked;
    }

    @Input() max: number;
    @Input() min: number = 1;
    @Input() inputId: string;

    /**
     * Getter for the zoom input disabled attribute.
     *
     * @readonly
     * @type {('' | null)}
     */
    public get zoomInputDisabled(): '' | null {
        return (this.maxZoomChecked || this.#isLocked) ? '' : null;
    }

    constructor() { }

    private maxZoomLevel: number = 999;
    public maxZoomChecked: boolean = false;
    public zoomToFormControl = new UntypedFormControl();

    /**
     * NgOnInt.
     */
    ngOnInit(): void {
        this.zoomToFormControl.valueChanges.subscribe((value) => {
            if (value > this.max && value !== this.maxZoomLevel) {
                value = this.max;
            } else if (value < this.min && value !== null) {
                value = this.min;
            }

            this.zoomToFormControl.patchValue(value, { emitEvent: false });
            this.onChange(value);
        });
    }

    /**
     * NgAfterViewInit.
     */
    ngAfterViewInit(): void {
        this.maxZoomInputElement.nativeElement.disabled = this.#isLocked;
    }

    onChange: any = () => { };
    onTouch: any = () => { };

    /**
     * Enables/disables the zoom input and sets the correct value.
     */
    public onMaxZoomChange(): void {
        const zoomTo = this.maxZoomChecked ? this.maxZoomLevel : this.max;
        this.zoomToFormControl.patchValue(zoomTo);
    }

    /**
     * Blocks user to type '-' (dash).
     *
     * @param {KeyboardEvent} event
     */
    public onKeyDown(event: KeyboardEvent): void {
        if (event.key === '-') {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    /**
     * Writes a new value to the input.
     *
     * @param {number} value
     */
    writeValue(value: number): void {
        this.maxZoomChecked = value === this.maxZoomLevel;
        this.zoomToFormControl.patchValue(value, { emitEvent: false });
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     *
     * @param {Function} fn
     */
    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     *
     * @param {Function} fn
     */
    registerOnTouched(fn: Function): void {
        this.onTouch = fn;
    }

    /**
     * SetDisabledState.
     *
     * @param {boolean} isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.zoomToFormControl.disable() : this.zoomToFormControl.enable();
        this.maxZoomInputElement.nativeElement.disabled = isDisabled ? true : false;
    }
}