import { Injectable } from '@angular/core';
import { MediaCategory } from './media.enum';
import { IMediaItem } from './media-item/media-item.model';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class MapsIndoorsLabels {
    private labelUrl: string = environment.labelsBaseUrl;

    /**
     * List of MapsIndoors labels..
     *
     * @returns {IMediaItem[]}
     */
    public getLabels(): IMediaItem[] {
        return [
            {
                id: 'mi-label-graphic-gray',
                category: MediaCategory.MILabel,
                name: 'graphic-label-gray',
                preview: this.labelUrl + 'graphic-label-gray.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-lightgray',
                category: MediaCategory.MILabel,
                name: 'graphic-label-lightgray',
                preview: this.labelUrl + 'graphic-label-lightgray.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-red',
                category: MediaCategory.MILabel,
                name: 'graphic-label-red',
                preview: this.labelUrl + 'graphic-label-red.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-orange',
                category: MediaCategory.MILabel,
                name: 'graphic-label-orange',
                preview: this.labelUrl + 'graphic-label-orange.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-lime',
                category: MediaCategory.MILabel,
                name: 'graphic-label-lime',
                preview: this.labelUrl + 'graphic-label-lime.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-green',
                category: MediaCategory.MILabel,
                name: 'graphic-label-green',
                preview: this.labelUrl + 'graphic-label-green.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-emerald',
                category: MediaCategory.MILabel,
                name: 'graphic-label-emerald',
                preview: this.labelUrl + 'graphic-label-emerald.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-cyan',
                category: MediaCategory.MILabel,
                name: 'graphic-label-cyan',
                preview: this.labelUrl + 'graphic-label-cyan.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-blue',
                category: MediaCategory.MILabel,
                name: 'graphic-label-blue',
                preview: this.labelUrl + 'graphic-label-blue.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-indigo',
                category: MediaCategory.MILabel,
                name: 'graphic-label-indigo',
                preview: this.labelUrl + 'graphic-label-indigo.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-violet',
                category: MediaCategory.MILabel,
                name: 'graphic-label-violet',
                preview: this.labelUrl + 'graphic-label-violet.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-fuchsia',
                category: MediaCategory.MILabel,
                name: 'graphic-label-fuchsia',
                preview: this.labelUrl + 'graphic-label-fuchsia.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-pink',
                category: MediaCategory.MILabel,
                name: 'graphic-label-pink',
                preview: this.labelUrl + 'graphic-label-pink.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            },
            {
                id: 'mi-label-graphic-rose',
                category: MediaCategory.MILabel,
                name: 'graphic-label-rose',
                preview: this.labelUrl + 'graphic-label-rose.png',
                type: 'png',
                width: 80,
                height: 32,
                lastModified: '1970-01-01T00:00:00.000Z',
                stretchX: [[15, 64]],
                stretchY: [[15, 16]],
                content: [10, 10, 69, 22]
            }
        ];
    }
}
