import isEqual from 'fast-deep-equal';
import { Anchor, Geometry, Location, LocationType, StreetViewConfig } from '../../../locations/location.model';
import { BaseMapAdapter } from '../../../MapAdapter/BaseMapAdapter';
import { Building, OUTDOOR_BUILDING } from '../../../buildings/building.model';
import { BuildingService } from '../../../buildings/building.service';
import { Component, ElementRef, OnDestroy, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { ExtendedLocation, LocationService } from '../../../locations/location.service';
import { Floor } from '../../../buildings/floor.model';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocationType as TypeOfLocation } from '../../../location-types/location-type.model';
import { MapSidebar } from '../../map-sidebar/map-sidebar.interface';
import { MapUIComponents, MapUIService } from '../../map-ui-service/map-ui.service';
import { NetworkService } from '../../../network-access/network.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../services/notification.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { Solution } from '../../../solutions/solution.model';
import { SolutionService } from '../../../services/solution.service';
import { Translation, CustomProperty } from '../../../shared/interfaces/translation.model';
import { TypesService } from '../../../services/types.service';
import { UserService } from '../../../services/user.service';
import { createDropdownItemElement } from '../../../shared/mi-dropdown/mi-dropdown';
import { finalize, map, tap } from 'rxjs/operators';
import { formatToShortDate } from '../../../shared/datetime-helper';
import { intersectingFeaturePoint } from '../../../shared/geometry-helper';
import { maximumDate, minimumDate } from '../../../shared/directives/date-interval.directive';
import { getKeyPathValue, mergeObjects, primitiveClone } from '../../../shared/object-helper';
import { Occupant } from '../../../services/OccupantServices/Occupant';
import { OccupantTemplate } from '../../../services/OccupantServices/occupantTemplate.model';
import { SolutionConfig } from '../../../solution-settings/solution-settings.model';
import { getFormControl } from '../../../shared/form-helper';

export enum LocationFormField {
    Obstacle,
    Angle,
    Type
}

@Component({
    selector: 'location-details-editor',
    templateUrl: './location-details-editor.component.html',
    styleUrls: ['./location-details-editor.component.scss'],
})
export class LocationDetailsEditorComponent implements OnInit, OnDestroy, MapSidebar {
    @ViewChild('buildingsDropdown', { static: true })
    private buildingsDropdownElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('floorsDropdown', { static: true })
    private floorsDropdownElement: ElementRef<HTMLMiDropdownElement>;

    private _buildings: Building[];
    private _location: ExtendedLocation;
    private _selectedSolution: Solution;
    private _solutionConfig: SolutionConfig;
    private _subscriptions: Subscription = new Subscription();
    private _originalFormState;
    private _type: TypeOfLocation;
    private _inheritableFormControls: string[] = ['locationSettings', 'restrictions'];
    private _inheritableFormControlValues: { [key: string]: any };
    private _dirtyStateChange: Subject<boolean> = new Subject<boolean>();
    private _discardChange: Subject<unknown> = new Subject<unknown>();
    private _locationKeyPaths: string[];
    private _formSavedSubject: Subject<{ location?: ExtendedLocation, occupantData?: { occupant: Occupant, occupantTemplate: OccupantTemplate } }> = new Subject<{}>();

    private readonly _outsideBuilding: Building = {
        ...OUTDOOR_BUILDING,
        floors: [{
            floorIndex: 0,
            geometry: null,
            pathData: null,
            floorInfo: null,
            solutionId: null,
            id: null,
            displayName: ''
        }]
    };

    @Output()
    public closed: EventEmitter<void> = new EventEmitter();

    @Output()
    public formChanged: EventEmitter<{ field: LocationFormField, value: boolean | number | TypeOfLocation }> = new EventEmitter();

    /**
     * Location form control getter.
     */
    public getFormControl = getFormControl;

    /**
     * Location getter.
     *
     * @readonly
     * @type {ExtendedLocation}
     * @memberof LocationDetailsComponent
     */
    public get location(): ExtendedLocation {
        return this._location;
    }

    /**
     * Location form dirty state getter.
     *
     * @readonly
     * @type {Subject<boolean>}
     */
    public get dirtyStateChange(): Subject<boolean> {
        return this._dirtyStateChange;
    }

    /**
     * Location form discard action listener.
     *
     * @readonly
     * @type {Subject<unknown>}
     */
    public get discardChange(): Subject<unknown> {
        return this._discardChange;
    }

    /**
     * Location form save getter.
     *
     * @readonly
     * @type {Subject<{location?: ExtendedLocation, occupantData?: { occupant: Occupant, occupantTemplate: OccupantTemplate }}>}
     */
    public get formSavedSubject(): Subject<{ location?: ExtendedLocation, occupantData?: { occupant: Occupant, occupantTemplate: OccupantTemplate } }> {
        return this._formSavedSubject;
    }

    /**
     * Property that reflects if the location's building property can be updated.
     *
     * @readonly
     * @type {boolean}
     * @memberof LocationDetailsEditorComponent
     */
    public get cannotUpdateBuilding(): boolean {
        return this._location?.locationType === LocationType.Room;
    }

    /**
     * Property that reflects if the location's floor property can be updated.
     *
     * @readonly
     * @type {boolean}
     * @memberof LocationDetailsEditorComponent
     */
    public get cannotUpdateFloor(): boolean {
        return this.cannotUpdateBuilding || this.locationForm.get('pathData.building').value === this._outsideBuilding.administrativeId;
    }

    /**
     * Property that reflects if the location can be deleted.
     *
     * @readonly
     * @type {boolean}
     * @memberof LocationDetailsEditorComponent
     */
    public get canLocationBeDeleted(): boolean {
        return (this?._location?.id > '' && this?._location?.locationType !== LocationType.Room);
    }

    /**
     * The Location's anchor point.
     *
     * @readonly
     * @type {number[]}
     */
    public get anchorPoint(): number[] {
        return this._location.geometry.type === 'Point' ? this.locationForm.get('geometry').value : this.locationForm.get('anchor').value;
    }

    public discardChangesSubject: Subject<unknown> = new Subject<unknown>();
    public isOwnerOrAdmin: boolean = false;
    public typeName: string;
    public readonly MIN_DATE = '1999-01-01';
    public readonly MAX_DATE = '2099-12-31';

    public locationForm = this.formBuilder.group({
        activeFrom: ['', [minimumDate(this.MIN_DATE), maximumDate(this.MAX_DATE)]],
        activeTo: ['', [minimumDate(this.MIN_DATE), maximumDate(this.MAX_DATE)]],
        aliases: [[]],
        anchor: [],
        categories: [[]],
        customProperties: [],
        imageURL: [],
        externalId: [''],
        pathData: this.formBuilder.group({
            building: [],
            floor: []
        }),
        restrictions: [[]],
        status: [1],
        locationSettings: this.formBuilder.group({
            selectable: [],
            obstacle: []
        }),
        streetViewConfig: this.formBuilder.group({
            panoramaId: [],
            povHeading: [],
            povPitch: []
        }),
        type: ['', [Validators.required]],
        translations: [],
        angle: [0],
        geometry: [{}, [Validators.required]]
    });

    constructor(
        private buildingService: BuildingService,
        private formBuilder: UntypedFormBuilder,
        private locationService: LocationService,
        private notificationService: NotificationService,
        private solutionService: SolutionService,
        private spinner: NgxSpinnerService,
        private mapUIService: MapUIService,
        private networkService: NetworkService,
        private typeService: TypesService,
        private userService: UserService
    ) {
        const buildingsSubscription = this.buildingService.buildings$
            .subscribe(buildings => {
                this._buildings = [this._outsideBuilding, ...buildings];
            });

        const solutionConfigSubscription = this.solutionService.solutionConfig$
            .subscribe(solutionConfig => {
                this._solutionConfig = solutionConfig;
            });

        this._subscriptions.add(buildingsSubscription);
        this._subscriptions.add(solutionConfigSubscription);

        this.userService.getCurrentUserObservable().subscribe(() => {
            this.isOwnerOrAdmin = this.userService.hasOwnerPrivileges() && this.userService.hasAdminPrivileges();
        });
    }

    /**
     * Setter for setting the location to be edited.
     */
    @Input()
    set data(input: Location) {
        this._location = primitiveClone(input) as ExtendedLocation;
        this.typeName = this._location?.type;
        this.buildingService.setCurrentFloor(this._location?.floor);
        this.setLocationFormValues(this._location);
        this._locationKeyPaths = Object.keys(this.locationForm.controls).filter(key => !this._inheritableFormControls.includes(key));
    }

    /**
     * Setter for setting the location's geometry and anchor point.
     */
    @Input()
    set locationGeometry(value: Subject<{ geometry: Geometry, anchor: Anchor }>) {
        value?.subscribe((e) => {
            this.locationForm.patchValue({
                geometry: e.geometry,
                anchor: e.anchor
            });

            const geometryFormControl = this.locationForm.get('geometry');
            const anchorFormControl = this.locationForm.get('anchor');

            !isEqual(this._originalFormState.geometry, geometryFormControl.value)
                ? geometryFormControl.markAsDirty()
                : geometryFormControl.markAsPristine();

            !isEqual(this._originalFormState.anchor, anchorFormControl.value)
                ? anchorFormControl.markAsDirty()
                : anchorFormControl.markAsPristine();
        });
    }

    /**
     * Property that reflects if the location has an occupant attached to it.
     */
    @Input() locationHasOccupant: boolean = false;
    @Input() mapAdapter: BaseMapAdapter;

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.mapUIService.show(MapUIComponents.LocationDetailsEditor);

        const locationType = this.locationForm.get('type').valueChanges
            .subscribe(value => {
                this._type = this.typeService.getTypeFromStore(value);
                this.typeName = value;
                this.formChanged.emit({ field: LocationFormField.Type, value: this._type });
            });

        const obstacleSubscription = this.locationForm.get('locationSettings.obstacle').valueChanges
            .subscribe((isObstacle) => this.formChanged.emit({ field: LocationFormField.Obstacle, value: isObstacle }));

        const solutionSubscription = this.solutionService.selectedSolution$
            .subscribe(solution => {
                if (this._selectedSolution) {
                    this.closed.emit();
                }

                this._selectedSolution = solution;
            });

        const rotationSubscription =
            this.locationForm.controls['angle'].valueChanges
                .subscribe(angle => this.formChanged.emit({ field: LocationFormField.Angle, value: angle }));

        const locationFormsSubscription = this.locationForm.valueChanges
            .subscribe(formState => {
                if (formState.geometry?.type === 'Polygon' || formState.geometry?.type === 'MultiPolygon') {
                    // Checks if any of Polygon's edges are intersecting with each other.
                    const intersectingPoints = intersectingFeaturePoint(formState.geometry);

                    if (intersectingPoints.features.length > 0) {
                        this.notificationService.showError('Polygon edges cannot intersect!');
                        this.locationForm.setErrors({ invalid: true });
                    }
                }

                this.isFormDirty() ? this.locationForm.markAsDirty() : this.locationForm.markAsPristine();
                this._dirtyStateChange.next(this.locationForm.dirty);
            });

        this._subscriptions
            .add(locationFormsSubscription)
            .add(locationType)
            .add(obstacleSubscription)
            .add(rotationSubscription)
            .add(solutionSubscription);
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();

        const buildings = this._buildings?.filter(building => building.administrativeId !== OUTDOOR_BUILDING.administrativeId);
        const nearestBuilding = this.buildingService.getNearestBuilding(buildings, this.mapAdapter.getCenter());
        this.buildingService.setCurrentBuilding(nearestBuilding);
    }

    /**
     * Evaluates if the form is dirty based on the original form state.
     *
     * @private
     * @returns {boolean}
     */
    private isFormDirty(): boolean {
        // If it's a new location, the form must always be marked dirty.
        if (!this._location.id) {
            return true;
        }

        // Check if any of the form controls are dirty and have different values than the original value.
        const dirtyControls = this._locationKeyPaths
            .filter((keyPath) => getFormControl(this.locationForm, keyPath)?.dirty)
            .filter((keyPath) => {
                const formControl = getFormControl(this.locationForm, keyPath);
                const initialFormControlValue = this._originalFormState[keyPath];
                return formControl.value !== initialFormControlValue;
            });

        // Check if any of the inheritable form controls are dirty and have different values than the original value.
        const dirtyInheritableControls = this._inheritableFormControls
            .filter((keyPath) => getFormControl(this.locationForm, keyPath)?.dirty)
            .filter((keyPath) => {
                const formControl = getFormControl(this.locationForm, keyPath);
                let initialFormControlValue = this._originalFormState[keyPath];

                // The locationSettings property has nested form controls which need to be checked separately.
                // E.g. location.locationSettings.selectable and location.locationSettings.obstacle
                if (keyPath === 'locationSettings') {
                    return Object.keys((this.locationForm.get(keyPath) as FormGroup).controls).some(childControlName => {
                        const formControl = getFormControl(this.locationForm, `${keyPath}.${childControlName}`);
                        const initialFormControlValue = this._originalFormState[keyPath] ? this._originalFormState[keyPath][childControlName] : null;
                        return formControl.value !== initialFormControlValue;
                    });
                } else {
                    // E.g. location.restrictions
                    initialFormControlValue = this._originalFormState[keyPath];
                    return formControl.value !== initialFormControlValue;
                }
            });

        // If there are any dirty controls or inheritable controls, the form is dirty.
        return dirtyControls.length > 0 || dirtyInheritableControls.length > 0;
    }

    /**
     * Resets the Location Details form.
     *
     * @private
     * @memberof LocationDetailsEditorComponent
     */
    private _resetForm(): void {
        if (this._location) {
            const originalFormValue = primitiveClone(this._originalFormState);
            this._discardChange.next(originalFormValue);
            this.locationForm.reset(originalFormValue);
            this.discardChangesSubject.next(originalFormValue);
            this.locationForm.markAsPristine();

            // reset Buildings & Floors dropdown control
            const building = this.buildingService.getBuildingByAdministrativeId(this._originalFormState, this._buildings);
            this.populateBuildingsDropdown(this._buildings, this._originalFormState.pathData.building);
            this.populateFloorsDropdown(building?.floors, this._originalFormState.pathData.floor);
        }
    }

    /**
     * Populates the buildings dropdown with items.
     *
     * @private
     * @param {Building[]} buildings
     * @param {string} selectedBuildingId
     * @memberof LocationDetailsComponent
     */
    private populateBuildingsDropdown(buildings: Building[], selectedBuildingId: string): void {
        const buildingDropdownItems = buildings?.map(building => {
            const selected = (selectedBuildingId.toLowerCase() === building.administrativeId.toLowerCase());
            return createDropdownItemElement({ label: building.displayName, value: building.administrativeId, selected });
        });

        this.buildingsDropdownElement.nativeElement.items = buildingDropdownItems;
    }

    /**
     * Populates the floors dropdown with items.
     *
     * @private
     * @param {Floor[]} floors
     * @memberof LocationDetailsComponent
     */
    private populateFloorsDropdown(floors: Floor[], selectedFloor?: number): void {
        const sortedFloorsList = floors?.sort((a, b) => a.floorIndex > b.floorIndex ? -1 : 1);
        const currentFloor = selectedFloor ?? sortedFloorsList[0]?.floorIndex;

        const floorsDropdownItems = sortedFloorsList.map(floor => {
            const selected = floor.floorIndex === currentFloor;
            return createDropdownItemElement({ label: floor.displayName, value: floor.floorIndex.toString(), selected });
        });

        this.floorsDropdownElement.nativeElement.items = floorsDropdownItems ?? [];
    }

    /**
     * Set the location form's values.
     *
     * @private
     * @param {ExtendedLocation} location
     * @memberof LocationDetailsComponent
     */
    private setLocationFormValues(location: ExtendedLocation): void {
        const building = this.buildingService.getBuildingByAdministrativeId(location, this._buildings);
        const customProperties = location.translations.map(({ language, fields }) => ({ language, fields }));
        const floorIndex = location.pathData?.floor ?? 0;

        this._inheritableFormControlValues = {
            'locationSettings.selectable': location?.typeOfLocation?.locationSettings?.selectable ?? this._solutionConfig?.locationSettings?.selectable ?? false,
            'locationSettings.obstacle': location?.typeOfLocation?.locationSettings?.obstacle ?? this._solutionConfig?.locationSettings?.obstacle ?? false,
            'restrictions': location?.typeOfLocation?.restrictions ?? []
        };

        this.locationForm.patchValue({
            activeFrom: formatToShortDate(location?.activeFrom),
            activeTo: formatToShortDate(location?.activeTo),
            aliases: location?.aliases,
            anchor: location?.anchor,
            angle: 0,
            categories: location?.categories,
            customProperties,
            externalId: location?.externalId || '',
            imageURL: location?.imageURL,
            pathData: {
                building: location?.pathData?.building,
                floor: location?.pathData?.floor
            },
            restrictions: location?.restrictions ?? this._inheritableFormControlValues['restrictions'],
            status: location?.status,
            locationSettings: {
                selectable: location?.locationSettings?.selectable ?? this._inheritableFormControlValues['locationSettings.selectable'],
                obstacle: location?.locationSettings?.obstacle ?? this._inheritableFormControlValues['locationSettings.obstacle'],
            },
            streetViewConfig: location?.streetViewConfig ? location.streetViewConfig : {
                panoramaId: null,
                povHeading: null,
                povPitch: null
            },
            translations: location.translations,
            type: location?.type || null,
            geometry: location?.geometry
        });

        this._inheritableFormControls.forEach(controlName => {
            if (controlName === 'locationSettings') {
                Object.keys((this.locationForm.get(controlName) as FormGroup).controls).forEach(subControlName => {
                    const locationSettingControl = getFormControl(this.locationForm, `${controlName}.${subControlName}`);
                    if (!this._location.id || !location[controlName] || location[controlName][subControlName] === null) {
                        locationSettingControl.disable();
                    } else {
                        locationSettingControl.enable();
                    }
                });
            } else {
                const control = getFormControl(this.locationForm, controlName);
                if (!this._location.id || location[controlName] === null) {
                    control.disable();
                } else {
                    control.enable();
                }
            }
        });

        this._outsideBuilding.floors[0].floorIndex = floorIndex;
        this.populateBuildingsDropdown(this._buildings, location?.pathData?.building);
        this.populateFloorsDropdown(building?.floors, location?.pathData?.floor);

        this._originalFormState = primitiveClone(this.locationForm.value);
    }

    /**
     * On change handler that sets the value ot the building formcontrol.
     *
     * @param {CustomEvent} detail
     * @memberof LocationDetailsComponent
     */
    public onBuildingsDropdownChange({ detail }: CustomEvent): void {
        const administrativeId = (detail as HTMLMiDropdownItemElement[])?.map(item => item.value).toString();
        const buildingControl = this.locationForm.get('pathData.building');
        if (buildingControl.value?.toLowerCase() !== administrativeId.toLowerCase()) {
            buildingControl?.setValue(administrativeId);

            const building = this._buildings.find(building => building.administrativeId.toLowerCase() === administrativeId.toLowerCase());
            if (building !== this._outsideBuilding) {
                this.floorsDropdownElement.nativeElement.disabled = false;
                this.populateFloorsDropdown(building?.floors);
            } else {
                this.floorsDropdownElement.nativeElement.items = [];
                this.floorsDropdownElement.nativeElement.disabled = true;
            }
        }
        buildingControl.value === this._originalFormState.pathData.building ? buildingControl.markAsPristine() : buildingControl.markAsDirty();
    }

    /**
     * On change handler that sets the value of the floor formcontrol.
     *
     * @param {CustomEvent} detail
     * @memberof LocationDetailsComponent
     */
    public onFloorsDropdownChange({ detail }: CustomEvent): void {
        const floorIndex = (detail as HTMLMiDropdownItemElement[])?.map(item => item.value).toString();
        const floorControl = this.locationForm.get('pathData.floor');
        floorControl?.setValue(+ floorIndex);

        const building = this.buildingService.getBuildingByAdministrativeId(this._originalFormState, this._buildings);
        const newFloor = building?.floors.find(floor => floor.floorIndex === parseInt(floorIndex));

        if (newFloor) {
            this.buildingService.setCurrentFloor(newFloor);
        }
        floorControl.value === this._originalFormState.pathData.floor ? floorControl.markAsPristine() : floorControl.markAsDirty();
    }

    /**
     * Helper function to Save or SaveAndClose location editor.
     *
     * @returns {Observable}
     */
    private saveLocation(): Observable<Location> {
        if (!this.locationForm.valid) {
            return;
        }

        const rotation = this.locationForm.value.angle;
        const translations = this.locationForm.value.translations as Translation[];
        const customProperties = this.locationForm.value.customProperties as [{ language: string, fields: { [key: string]: CustomProperty } }];

        for (const translation of translations) {
            const language = translation.language;
            translation.fields = customProperties.find(fields => fields.language === language)?.fields;
        }

        let location = mergeObjects(this._location, this.locationForm.value) as ExtendedLocation;
        location = this.formatBeforeSave(location);

        this._inheritableFormControls.forEach(controlName => {
            if (controlName === 'locationSettings' && location.locationSettings) {
                Object.keys((this.locationForm.get(controlName) as FormGroup).controls).forEach(childControlName => {
                    const locationSettingControl = getFormControl(this.locationForm, `${controlName}.${childControlName}`);
                    if (locationSettingControl.disabled) {
                        location[controlName][childControlName] = null;
                    }
                });
            } else {
                const control = getFormControl(this.locationForm, controlName);
                if (control.disabled) {
                    location[controlName] = null;
                }
            }
        });

        const model2D = location?.displayRule?.model2D;
        const model3D = location?.displayRule?.model3D;

        if (model2D) {
            const bearing = model2D.bearing ?? 0;
            model2D.bearing = normalizeBearing(bearing + rotation);
        }

        if (model3D) {
            const rotationZ = model3D.rotationZ ?? 0;
            model3D.rotationZ = normalizeBearing(rotationZ + rotation);
        }

        const obstacleStatusChanged = this.locationForm.value.locationSettings?.obstacle !== this._location.locationSettings?.obstacle;
        const obstacleWithNewGeometry = !isEqual(this._location.geometry, this.locationForm.value.geometry) && this.locationForm.value.locationSettings?.obstacle;
        
        // The route elements have to be updated if:
        // the location is an area
        // AND
        // either the existing location's 'obstacle' status has changed or the location's geometry has changed while being an obstacle
        // or the nw location is an obstacle
        // https://mapspeople.atlassian.net/browse/MIBAPI-4449 - This piece of code will be obsolete when we will be able to get information from the BE when the route has been regenerated.
        if (this._location.locationType === LocationType.Area) {
            if ((location.id !== '' && (obstacleStatusChanged || obstacleWithNewGeometry))
                || (location.id === '' && this.locationForm.value.locationSettings?.obstacle)) {
                this.networkService.updateRouteElements();
            }
        }

        if (location.id) {
            const locationTranslations = this.locationForm.controls['translations'].value;
            if (locationTranslations.length > 0 && locationTranslations[0].name) {
                const defaultLanguage = locationTranslations.find(translation => translation.language === this._selectedSolution.defaultLanguage);

                // Updating 'location.name' with the solutions' default language.
                location.name = defaultLanguage.name;
            }

            return this.updateLocation(location);
        }

        return this.createLocation(location);
    }

    /**
     * Updates the location.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {Observable<Location>}
     * @memberof LocationDetailsComponent
     */
    private updateLocation(location: ExtendedLocation): Observable<Location> {
        this.spinner.show();
        return this.locationService.updateLocation(location)
            .pipe(
                tap(() => this.notificationService.showSuccess('Location updated successfully!')),
                map(() => location),
                finalize(() => this.spinner.hide())
            );
    }

    /**
     * Creates a location.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {Observable<void>}
     * @memberof LocationDetailsComponent
     */
    private createLocation(location: ExtendedLocation): Observable<Location> {
        this.spinner.show();
        return this.locationService.createLocation(location)
            .pipe(
                tap(() => this.notificationService.showSuccess('Location created successfully!')),
                finalize(() => this.spinner.hide())
            );
    }

    /**
     * Format the Location's properties before saving it.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {ExtendedLocation}
     * @memberof LocationsComponent
     */
    private formatBeforeSave(location: ExtendedLocation): ExtendedLocation {
        // Format activeFrom and activeTo dates back to ISO format with Date.toISOString();
        if (location?.activeFrom > '') {
            location.activeFrom = new Date(location.activeFrom)?.toISOString() || location.activeFrom;
        }

        if (location?.activeTo > '') {
            location.activeTo = new Date(location.activeTo)?.toISOString() || location.activeTo;
        }

        // Nullify the streetViewConfig if no panoramaId is set.
        if (!location.streetViewConfig?.panoramaId) {
            location.streetViewConfig = null;
        }

        // The backend expects the deprecated path string to be null
        location.path = null;

        delete location.cloneOf;

        return location;
    }

    /**
     * Closes the location details editor.
     *
     * @param {boolean} [skipConfirm=false]
     * @returns {boolean}
     * @memberof LocationDetailsEditorComponent
     */
    public close(skipConfirm: boolean = false): boolean {
        if (skipConfirm || this.confirmDiscard()) {
            this.locationForm.reset({}, { emitEvent: false });
            this._location = null;
            this.closed.emit();
            return true;
        }
        return false;
    }

    /**
     * On submit form handler that formats location properties before sending it over the wire. Then it closes the location details editor.
     *
     * @memberof LocationDetailsComponent
     */
    public onSaveAndClose(): void {
        this.saveLocation().subscribe(() => {
            this.locationService.selectLocation(null);
            this.close(true);
            this._dirtyStateChange.next(false);
            this._formSavedSubject.next(null);
        });
    }

    /**
     * On submit form that just saves the form without closing the location details editor.
     *
     * @memberof LocationDetailsComponent
     */
    public onSave(): void {
        this.saveLocation().subscribe((location: ExtendedLocation) => {
            this._location = location;
            this.setLocationFormValues(location);
            this.locationForm.markAsPristine();
            this._dirtyStateChange.next(false);
            this._formSavedSubject.next({ location });
        });
    }

    /**
     * Resets the form and set the values.
     *
     * @memberof LocationDetailsComponent
     */
    public onDiscard(): void {
        if (this._location.id > '') {
            if (this.confirmDiscard()) {
                this._resetForm();
                this._dirtyStateChange.next(false);
            }
        } else {
            this.close();
        }
    }

    /**
     * On change handler that updates the value of streetViewConfig formcontrol.
     *
     * @param {StreetViewConfig} panorama
     * @memberof LocationDetailsComponent
     */
    public onUpdatePanorama(panorama: StreetViewConfig): void {
        const streetViewConfigControl = this.locationForm.get('streetViewConfig');
        streetViewConfigControl?.markAsDirty();
        streetViewConfigControl?.setValue(panorama || {
            panoramaId: null,
            povHeading: null,
            povPitch: null
        });
    }

    /**
     * Show a info notification.
     *
     * @param {string} message
     * @memberof LocationDetailsComponent
     */
    public showInfoNotification(message: string): void {
        this.notificationService.showInfo(message, false);
    }

    /**
     * On change handler that sets the value of the imageURL formcontrol.
     *
     * @param {string} imageUrl
     * @memberof LocationDetailsComponent
     */
    public onImageUrlChange(imageUrl: string): void {
        const imageUrlControl = this.locationForm.get('imageURL');
        imageUrlControl?.patchValue(imageUrl);
        imageUrlControl?.value === this._originalFormState?.imageURL ? imageUrlControl?.markAsPristine() : imageUrlControl?.markAsDirty();
    }

    /**
     * Prompt the user to confirm before discarding.
     *
     * @private
     * @returns {boolean}
     * @memberof LocationDetailsComponent
     */
    public confirmDiscard(): boolean {
        // eslint-disable-next-line no-alert
        return (this._location?.id !== '' && !this.locationForm.dirty) || confirm('Your unsaved changes will be lost! Would you like to continue?');
    }

    /**
     * Toggles the inheritance of the given key path.
     *
     * @param {string} keyPath
     * @param {MouseEvent} event
     */
    public toggleInheritance(keyPath: string, event?: MouseEvent): void {
        event?.stopPropagation();
        const formControl = getFormControl(this.locationForm, keyPath);
        const originalValue = getKeyPathValue(this._location, keyPath);

        if (formControl.disabled) {
            formControl.enable();
            originalValue !== formControl.value ? formControl.markAsDirty() : formControl.markAsPristine();
        } else {
            formControl.setValue(this._inheritableFormControlValues[keyPath]);
            formControl.disable();
            originalValue === null ? formControl.markAsPristine() : formControl.markAsDirty();
        }
    }

    /**
     * Disables the inheritance of the given key path.
     *
     * @param {string} keyPath
     * @param {MouseEvent} event
     */
    public disableInheritance(keyPath: string, event: MouseEvent): void {
        event.stopPropagation();
        const formControl = getFormControl(this.locationForm, keyPath);
        const originalValue = getKeyPathValue(this._location, keyPath);

        if (formControl.disabled) {
            formControl.enable();
            originalValue !== formControl.value ? formControl.markAsDirty() : formControl.markAsPristine();
        }
    }
}

/**
 * Normalizes the bearing to be between 0 and 360.
 *
 * @param {number} bearing
 * @returns {number}
 */
function normalizeBearing(bearing: number): number {
    return ((bearing % 360) + 360) % 360;
}