import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup } from '@angular/forms';
import { noDuplicateValidator } from '../shared/directives/no-duplicate.directive';

@Component({
    selector: 'aliases',
    templateUrl: './aliases.component.html',
    styleUrls: ['./aliases.component.scss']
})
export class AliasesComponent implements OnInit {
    @Input() aliasList: string[] = [];
    @Output() updatedAliasList: EventEmitter<string[]> = new EventEmitter();

    public aliasForm: FormGroup;

    @ViewChild('aliasInput') aliasInputElement: ElementRef;

    ngOnInit(): void {
        this.aliasForm = new FormGroup({
            alias: new UntypedFormControl('', Validators.compose([
                Validators.minLength(1),
                noDuplicateValidator(this.aliasList)
            ]))
        });
    }

    /**
     * Submit aliasForm and add alias.
     *
     * @memberof AliasesComponent
     */
    public onSubmit(): void {
        this.aliasList.push(this.aliasForm.get('alias').value);
        this.updateAliasList();

        this.aliasForm.reset();
        this.aliasInputElement.nativeElement.focus();
    }

    /**
     * Remove alias from list.
     *
     * @param {number} index - Index of alias to remove.
     * @memberof AliasesComponent
     */
    public removeAlias(index: number): void {
        this.aliasList.splice(index, 1);
        this.updateAliasList();
    }

    /**
     * Emit list of aliases to parent component.
     *
     * @private
     * @memberof AliasesComponent
     */
    private updateAliasList(): void {
        this.updatedAliasList.emit(this.aliasList);
    }
}
