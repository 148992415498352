<div *ngIf="totalPages !== 0" class="pagination">
    <button 
        class="mi-button pagination__button pagination__button--arrow-left" 
        (click)="previousPage()" 
        [disabled]="previousPageDisabled">
    </button>
    <div class="pagination__page-control">
        <input
            type="number" 
            class="mi-input pagination__input" 
            [ngClass]="{'pagination__input--error': currentPage > totalPages || currentPage < 1}" 
            [value]="currentPage" 
            (input)="onInputChange($event)">
        <p class="pagination__total">of {{totalPages}}</p>
    </div>
    <button 
        class="mi-button pagination__button pagination__button--arrow-right" 
        (click)="nextPage()" 
        [disabled]="nextPageDisabled">
    </button>
</div>