<mat-card>
  <mat-card-header>
    <mat-card-title>
      <span class="documentation-reference">App User Roles <a class="documentation-reference__link" href="https://docs.mapsindoors.com/content/map/displaying-objects/app-user-roles/"target="_blank">Help</a></span>
    </mat-card-title>
    <mat-card-subtitle>Manage access to Doors by creating App User Roles.</mat-card-subtitle>
    <div fxFlex></div>
    <mat-card-actions>
      <button mat-raised-button (click)="onOpenAppUserRoleDialog()">+ Add App User Role</button>
    </mat-card-actions>
  </mat-card-header>
  
    <mat-card-content>
      <mat-table #table [dataSource]=appUserRoleTableDataSource class="mat-elevation-z4"
        *ngIf="appUserRoleTableDataSource.data.length > 0; else noRolesTemplate">
        <ng-container matColumnDef="edit">
          <mat-header-cell fxFlex="noshrink" *matHeaderCellDef></mat-header-cell>
          <mat-cell fxFlex="noshrink" *matCellDef="let appUserRole">
            <button (click)="onOpenAppUserRoleDialog(appUserRole)" mat-icon-button>
              <mat-icon>edit</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="displayName">
          <mat-header-cell fxFlex="90" *matHeaderCellDef> Display Name </mat-header-cell>
          <mat-cell fxFlex="90" *matCellDef="let appUserRole"> {{appUserRole.names ? appUserRole.names[0].name : 'n/a'}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
      </mat-table>
      <ng-template #noRolesTemplate>
        <p class="empty-state">Roles have not been created yet</p>
      </ng-template>
    </mat-card-content>
  
</mat-card>