import { generateOffsetPolygon, getMetersPerPixel } from '../../shared/geometry-helper';
import { MapViewModel, MapViewModelFactory } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { Location } from '../../locations/location.model';
import { BaseMapAdapter } from '../../MapAdapter/BaseMapAdapter';
import { MapsIndoorsData } from '../../shared/enums/MapsIndoorsData';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { AreaAsObstacleViewModel } from '../../../viewmodels/AreaAsObstacleViewModel/AreaAsObstacleViewModel';
import { Floor } from '../../buildings/floor.model';
import { BBox } from 'geojson';
import { bboxPolygon, feature as toFeature } from '@turf/turf';
import booleanIntersects from '@turf/boolean-intersects';

export class AreaAsObstacleMapViewModelFactory extends MapViewModelFactory<Location> {
    constructor(
        private mapAdapter: BaseMapAdapter,
        private displayRuleService: DisplayRuleService,
    ) {
        super();
    }

    /**
     * Creates MapViewModels for the given Area as Obstacle.
     *
     * @param {Location} location
     * @returns {Promise<MapViewModel[]>}
     * @memberof AreaAsObstacleMapViewModelFactory
     */
    async create(location: Location, sortKey: number): Promise<MapViewModel[]> {
        const viewModels: MapViewModel[] = [];
        const displayRule = await this.displayRuleService.getDisplayRule(location.id);

        if (!this.isVisible(displayRule))
            return viewModels;

        const polygonPixelOffset = ((displayRule?.polygon?.strokeWidth ?? 0) + 3) / 2;
        const offsetPolygon = generateOffsetPolygon(location.geometry as GeoJSON.Polygon, polygonPixelOffset, getMetersPerPixel(location.geometry as GeoJSON.Polygon, this.mapAdapter.getZoom()));

        viewModels.push(await AreaAsObstacleViewModel.create(location.id, offsetPolygon.geometry, displayRule, sortKey, MapsIndoorsData.RouteElement));

        return Promise.all(viewModels);
    }

    /**
     * Checks if the given obstacle intersects with the given bounds.
     *
     * @param {Location} obstacle
     * @param {BBox} bounds
     * @returns {boolean}
     * @memberof AreaAsObstacleMapViewModelFactory
     */
    intersectsWithBounds(obstacle: Location, bounds: BBox): boolean {
        const boundsAsPolygon = bboxPolygon(bounds);
        return booleanIntersects(boundsAsPolygon, toFeature(obstacle.geometry));
    }

    /**
     * Checks if the floorIndex for the given location equals the given floorIndex.
     *
     * @param {Location} obstacle
     * @param {floor} floor
     * @returns {boolean}
     * @memberof AreaAsObstacleMapViewModelFactory
     */
    floorEquals(obstacle: Location, floor: Floor): boolean {
        return obstacle.pathData.floor === floor?.floorIndex;
    }
}