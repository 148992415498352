import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { saveAs } from 'file-saver/dist/FileSaver';

import { ImportExportDialogComponent } from './import-export-dialog.component';
import { ImportService } from '../services/import.service';
import { SolutionService } from '../services/solution.service';
import { NotificationService } from '../services/notification.service';
import { VenueService } from '../venues/venue.service';

export interface DataOption {
    title: string;
    path: string;
    file: File;
    fileData: any;
    fileType: 'csv' | 'osm';
    selectedVenues?: string[];
}

@Component({
    templateUrl: './import-export.component.html',
    styleUrls: ['./import-export.component.scss']
})
export class ImportExportComponent implements OnInit, OnDestroy {
    public showSpinner: boolean = false;
    currentSolution: any;
    selectedGraphDropdownItem: HTMLMiDropdownItemElement;

    /**
     * Event handler for when the selected graph dropdown item changes.
     *
     * @param {CustomEvent} event - The event.
     * @memberof ImportExportComponent
     */
    public onSelectedGraphDropdownItemChanged(event: CustomEvent<HTMLMiDropdownItemElement>): void {
        this.selectedGraphDropdownItem = event?.detail;
        this.selectedGraphId = event?.detail[0]?.value;
    }

    public graphIds: string[] = [];
    public graphDropdownItems: HTMLMiDropdownItemElement[] = [];
    public selectedGraphId: string;

    dialogRef: MatDialogRef<ImportExportDialogComponent>;

    private solutionSubscription: Subscription;

    public isProgressbarVisible = false;
    public dataOptions: DataOption[] = [
        { title: 'Locations', path: 'locations', file: null, fileData: null, fileType: 'csv', selectedVenues: [] },
        { title: 'Location Types', path: 'locationtypes', file: null, fileData: null, fileType: 'csv' },
        { title: 'Categories', path: 'categories', file: null, fileData: null, fileType: 'csv' },
        { title: 'Venues', path: 'venues', file: null, fileData: null, fileType: 'csv' },
        { title: 'Buildings', path: 'buildings', file: null, fileData: null, fileType: 'csv', selectedVenues: [] },
        { title: 'Floors', path: 'floors', file: null, fileData: null, fileType: 'csv', selectedVenues: [] },
        { title: 'Route Layer Elements', path: 'routelayer', file: null, fileData: null, fileType: 'csv' },
        { title: 'Live Data Mapping', path: 'livedatamapping', file: null, fileData: null, fileType: 'csv' },
        { title: 'Graphs(.csv)', path: 'graphs', file: null, fileData: null, fileType: 'csv' },
        { title: 'Graphs(.osm)', path: 'graphs', file: null, fileData: null, fileType: 'osm' }
    ];

    venues: { text: string, value: string }[] = [];
    public readonly selectedVenues: Map<DataOption, string[]> = new Map();

    constructor(
        private importService: ImportService,
        private solutionService: SolutionService,
        private venueService: VenueService,
        private dialog: MatDialog,
        private notificationService: NotificationService,

    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        this.solutionSubscription = this.solutionService.getCurrentSolution()
            .pipe(
                switchMap(solution => {
                    this.currentSolution = solution;
                    return this.venueService.venues$;
                })
            )
            .subscribe(venues => {
                for (const dataOption of this.dataOptions) {
                    if (dataOption.selectedVenues?.length > 0) {
                        dataOption.selectedVenues = [];
                    }
                }
                this.venues = venues.map(venue => ({ text: venue.displayName, value: venue.id }));
                this.getGraphIds();
            });
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.solutionSubscription.unsubscribe();
    }

    /**
     * Open the file selection window.
     *
     * @param {*} event
     * @param {DataOption} dataOption
     * @memberof ImportExportComponent
     */
    public chooseFile(event, dataOption: DataOption): void {
        dataOption.file = event.target ? event.target.files[0] : event[0];
        const reader = new FileReader();
        reader.readAsArrayBuffer(dataOption.file);
        reader.onloadend = () => {
            dataOption.fileData = reader.result;
            this.upload(dataOption, true);
        };
    }

    /**
     * Event handler for when the selected venues change.
     *
     * @param {{ value: string, text: string }[]} selectedItems
     * @param {DataOption} dataOption
     */
    onVenueSelectionChange(selectedItems: { value: string, text: string }[], dataOption: DataOption): void {
        dataOption.selectedVenues = selectedItems.map(item => item.value);
    }

    /**
     * Call the service to upload the selected file.
     *
     * @param {DataOption} dataOption
     * @param {boolean} preview
     * @memberof ImportExportComponent
     */
    public upload(dataOption: DataOption, preview: boolean): void {
        this.showSpinner = true;
        const fileType = dataOption.fileType;
        const graphId = dataOption.path === 'graphs' && fileType === 'osm' ? this.selectedGraphId : '';
        this.importService.upload(dataOption.path, preview, dataOption.fileData, fileType, graphId)
            .pipe(finalize(() => this.showSpinner = false))
            .subscribe(
                res => {
                    if (preview && res) {
                        this.openResponseDialog(res, dataOption);
                    } else {
                        this.notificationService.showSuccess('Upload successful');
                        this.reset(dataOption);
                    }
                },
                error => {
                    this.notificationService.showError(error);
                    this.reset(dataOption);
                }
            );
    }

    /**
     * Open the dialog to view the server response.
     *
     * @private
     * @param {*} response
     * @param {DataOption} dataOption
     * @memberof ImportExportComponent
     */
    private openResponseDialog(response, dataOption: DataOption): void {
        const dialogOptions = {
            autoFocus: false,
            hasBackdrop: true,
            disableClose: true,
            data: response
        };

        this.dialogRef = this.dialog.open(ImportExportDialogComponent, dialogOptions);
        this.dialogRef.afterClosed().subscribe(result => {
            result ? this.upload(dataOption, false) : this.reset(dataOption);
        });
    }

    /**
     * Reset the upload input.
     *
     * @param {DataOption} dataOption
     * @memberof ImportExportComponent
     */
    public reset(dataOption: DataOption): void {
        const input = document.getElementById(dataOption.path + '-input') as HTMLInputElement;
        input.value = '';
        dataOption.file = null;
        dataOption.fileData = null;
        this.showSpinner = false;
    }

    /**
     * Downloads the given data via the service.
     *
     * @param {DataOption} dataOption
     * @memberof ImportExportComponent
     */
    public download(dataOption: DataOption): void {
        this.showSpinner = true;
        const selectedVenues = dataOption.selectedVenues;

        const graphId = dataOption.path === 'graphs' ? this.selectedGraphId : '';
        const fileType = dataOption.fileType;
        this.importService.download(dataOption.path, { venueIds: selectedVenues, graphId, fileType })
            .pipe(finalize(() => this.showSpinner = false))
            .subscribe(
                res => {
                    this.writeResponseToFile(`${dataOption.path}_${this.currentSolution.name.replace(/\s/g, '')}_${this.getDate()}.${fileType}`, res);
                    this.notificationService.showSuccess('Download successful');
                },
                err => this.notificationService.showError(err)
            );
    }

    /**
     * Get the list of graph ids.
     *
     * @private
     * @memberof ImportExportComponent
     */
    private getGraphIds(): void {
        this.isProgressbarVisible = true;
        this.importService.getGraphIds()
            .pipe(finalize(() => this.isProgressbarVisible = false))
            .subscribe(graphIds => {
                this.graphDropdownItems = graphIds.map((graphId, index) => {
                    const item = document.createElement('mi-dropdown-item');
                    item.text = graphId;
                    item.value = graphId;
                    item.selected = index === 0;

                    return item;
                });
                this.graphIds = graphIds;
                this.selectedGraphId = this.graphIds[0];
            }, error => this.notificationService.showError(error)
            );
    }

    /**
     * Save the response data to a file.
     *
     * @private
     * @param {string} filename
     * @param {*} fileData
     * @memberof ImportExportComponent
     */
    private writeResponseToFile(filename: string, fileData): void {
        const blob = new Blob([fileData],
            { type: 'application/octet-stream' }
        );
        saveAs(blob, filename);
    }

    /**
     * Get current date and time.
     *
     * @private
     * @returns {string} Datetime string in format YYYYMMDDhhmmss.
     * @memberof ImportExportComponent
     */
    private getDate(): string {
        const today = new Date();
        const month = ((today.getMonth() + 1) < 10 ? '0' : '') + (today.getMonth() + 1);
        const date = (today.getDate() < 10 ? '0' : '') + today.getDate();
        const fullDate = `${today.getFullYear()}${month}${date}`;

        const hours = (today.getHours() < 10 ? '0' : '') + today.getHours();
        const minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();
        const seconds = (today.getSeconds() < 10 ? '0' : '') + today.getSeconds();
        const time = `${hours}${minutes}${seconds}`;

        return `${fullDate}${time}`;
    }
}
