import { Component, ElementRef, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { createDropdownItemElement } from '../../mi-dropdown/mi-dropdown';
import { OccupantType } from '../../../services/OccupantServices/occupant.model';

@Component({
    selector: 'occupant-type-dropdown',
    templateUrl: './occupant-type-dropdown.component.html',
    styleUrls: ['./occupant-type-dropdown.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OccupantKindDropdownComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => OccupantKindDropdownComponent),
        multi: true,
    }]
})
export class OccupantKindDropdownComponent implements OnInit, ControlValueAccessor, Validator {
    @ViewChild('occupantKindDropdown', { static: true }) occupantKindDropdownElement: ElementRef<HTMLMiDropdownElement>;

    public onChange: (id: number) => void = () => { };
    public onTouch: () => void = () => { };
    public onValidatorChange: () => void = () => { };

    public isFormValid: boolean = false;

    private occupantTypes: any[];
    private dropdownItems: HTMLMiDropdownItemElement[] = [];
    private selectedKind;

    constructor() { }

    /**
     * Angular lifecycle ngOnInit.
     */
    ngOnInit(): void {
        this.occupantTypes = Object.keys(OccupantType)
            .filter(key => isNaN(Number(key)))
            .map(key => ({ displayName: key, id: OccupantType[key] }));

        let selectedDropdownItem: HTMLMiDropdownItemElement;
        const defaultDropdownItem = createDropdownItemElement({ label: 'Select Type/kind', value: null });
        const dropdownItems = new Array(this.occupantTypes.length);

        for (const [index, kind] of this.occupantTypes.entries()) {
            dropdownItems[index] = createDropdownItemElement({ label: kind.displayName, value: kind.displayName });
            if (this.selectedKind === kind) {
                selectedDropdownItem = dropdownItems[index];
                selectedDropdownItem.selected = true;
            }
        }

        this.dropdownItems = [].concat(defaultDropdownItem, dropdownItems);
        this.occupantKindDropdownElement.nativeElement.items = this.dropdownItems;

        if (selectedDropdownItem) {
            this.onChange(OccupantType[selectedDropdownItem.value]);
        }
    }

    /**
     * Sets the selected dropdown item.
     *
     * @param {HTMLMiDropdownItemElement} selectedDropdownItem
     */
    private setSelectedDropdownItem(selectedDropdownItem: HTMLMiDropdownItemElement): void {
        selectedDropdownItem.selected = true;
        this.occupantKindDropdownElement.nativeElement.selected = [selectedDropdownItem];
    }

    /**
     * On occupant kind dropdown change.
     *
     * @param {CustomEvent} event
     */
    public onOccupantTypeDropdownChange(event: CustomEvent): void {
        this.onChange(parseInt(OccupantType[event.detail[0].value]));
    }

    /**
     * Angular lifecycle writeValue.
     */
    writeValue(value: number): void {
        this.selectedKind = this.occupantTypes.find(kind => kind.id === value);
        if (this.dropdownItems?.length > 0) {
            const selectedDropdownItem = this.dropdownItems.find(item => OccupantType[item.value] === this.selectedKind?.id);

            if (!selectedDropdownItem || !this.selectedKind) {
                this.setSelectedDropdownItem(this.dropdownItems[0]);
            } else {
                this.setSelectedDropdownItem(selectedDropdownItem);
            }
        }
    }

    /**
     * Angular lifecycle registerOnChange.
     */
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Angular lifecycle registerOnTouched.
     */
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * Angular lifecycle validate.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        this.isFormValid = (this.occupantKindDropdownElement.nativeElement.selected?.[0] !== this.dropdownItems[0]);
        return this.isFormValid ? null : { invalid: true };
    }
}
