import { Injectable, OnDestroy } from '@angular/core';
import { SolutionService } from '../solution.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { OccupantCategory } from './OccupantCategory';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../notification.service';

const API_ENDPOINT = environment.APIEndpoint;

@Injectable({ providedIn: 'root' })
export class OccupantCategoryService implements OnDestroy {
    private _occupantCategoriesMap: Map<string, OccupantCategory> = new Map();
    private currentSolutionId: string;
    private solutionSubscription: Subscription;
    private _occupantCategoriesSubject: BehaviorSubject<OccupantCategory[]> = new BehaviorSubject<OccupantCategory[]>([]);

    constructor(
        private solutionService: SolutionService,
        private http: HttpClient,
        private notificationService: NotificationService
    ) {
        this.solutionSubscription = this.solutionService.getCurrentSolution()
            .pipe(
                switchMap((solution) => {
                    this.currentSolutionId = solution.id;
                    return this.getOccupantCategoriesList();
                }),
                map((occupantCategories) => {
                    const categoriesMap = new Map(occupantCategories.map(category => ([category.id, category])));
                    this._occupantCategoriesMap = this.createOccupantCategories(categoriesMap);
                    this._occupantCategoriesSubject.next(Array.from(this._occupantCategoriesMap.values()));

                    return Array.from(this._occupantCategoriesMap.values());
                })
            )
            .subscribe();
    }

    /**
     * Get occupant categories as an observable.
     *
     * @returns {Observable<OccupantCategory[]>}
     */
    get occupantCategories$(): Observable<OccupantCategory[]> {
        return this._occupantCategoriesSubject.asObservable();
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.solutionSubscription.unsubscribe();
    }

    /**
     * Get all occupant categories.
     *
     * @returns {Observable<OccupantCategory[]>}
     */
    private getOccupantCategoriesList(): Observable<OccupantCategory[]> {
        return this.http.get<OccupantCategory[]>(`${API_ENDPOINT}${this.currentSolutionId}/api/occupantCategory`).pipe(
            catchError((err: Error) => {
                this.notificationService.showError(err);
                return of([]);
            })
        );
    }

    /**
     * Function to create OccupantCategory instances from a map of occupant categories.
     *
     * @param {Map<string, any>} occupantCategories
     * @returns {Map<string, OccupantCategory>}
     */
    private createOccupantCategories(occupantCategories: Map<string, any>): Map<string, OccupantCategory> {
        // Create a new map to store the transformed occupant categories
        const transformedCategories = new Map<string, OccupantCategory>();

        /**
         * Inner function to create an OccupantCategory from an occupant category.
         *
         * @param {any} occupantCategory
         * @returns {OccupantCategory}
         */
        function createOccupantCategory(occupantCategory: any): OccupantCategory {
            // Create a new OccupantCategory instance
            const newOccupantCategory = new OccupantCategory(occupantCategory);

            // If the occupant category has a parent
            if (occupantCategory.parentId) {
                // Try to get the parent OccupantCategory from the transformed categories
                let parentOccupantCategory = transformedCategories.get(occupantCategory.parentId);
                // If the parent OccupantCategory doesn't exist, create it
                if (!parentOccupantCategory) {
                    parentOccupantCategory = createOccupantCategory(occupantCategories.get(occupantCategory.parentId));
                }
                // Set the parent of the new OccupantCategory
                newOccupantCategory.parentCategory = parentOccupantCategory;
            }

            // Add the new OccupantCategory to the transformed categories
            transformedCategories.set(occupantCategory.id, newOccupantCategory);
            // Return the new OccupantCategory
            return newOccupantCategory;
        }

        // Loop through all occupant categories and create OccupantCategory instances
        for (const occupantCategory of occupantCategories.values()) {
            createOccupantCategory(occupantCategory);
        }

        return transformedCategories;
    }

    /**
     * Get occupant category by id.
     *
     * @param {string} id
     * @returns {OccupantCategory}
     */
    public getOccupantCategoryById(id: string): Observable<OccupantCategory> {
        if (this._occupantCategoriesMap.has(id)) {
            return of(this._occupantCategoriesMap.get(id));
        } else {
            return this.http.get<OccupantCategory>(`${API_ENDPOINT}${this.currentSolutionId}/api/occupantCategory/details/${id}`)
                .pipe(
                    tap((occupantCategory) => {
                        // Don't need to call createOccupantCategories because we do not need the final name for single categories.
                        this._occupantCategoriesMap.set(occupantCategory.id, occupantCategory);
                    })
                );
        }
    }
}