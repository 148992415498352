<div *ngIf="isDisabled" class="message message--warning">
    {{ warningMessage }}
</div>

<label class="mi-label">
    Location Type *
    <mi-dropdown #locationTypesDropdown (change)="onLocationTypesDropdownChange($event)" filterable [disabled]="isDisabled"></mi-dropdown>
    <small role="alert" class="mi-form__error" *ngIf="!isFormValid">
        Location type is required.
     </small>
</label>