import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { CustomerService } from '../../customers/customer.service';
import { MapService } from '../../services/map.service';
import { NotificationService } from '../../services/notification.service';
import { SolutionService } from '../../services/solution.service';
import { SplitService } from './split.service';

import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import * as turf from '@turf/turf';

import { ExtendedLocation } from '../../locations/location.service';
import { Venue } from '../../venues/venue.model';

import { Feature, Point } from 'geojson';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Customer } from '../../customers/customer.model';
import { Geometry } from '../../locations/location.model';

enum SnapSplitTo {
    Nearest = 'nearest', // snap to location nearest to the mouse pointer
    Perpendicular = 'perpendicular' // snap to location polygon opposite of the first selected split point at a 90 degrees angle
}

enum MapCursorMode {
    Valid = 'valid',
    Invalid = 'invalid'
}

@Component({
    selector: 'app-split-location',
    templateUrl: './split-location.component.html',
    styleUrls: ['./split-location.component.scss']
})
export class SplitLocationComponent implements OnInit {
    @ViewChild('map', { static: true }) mapElement: ElementRef;

    public isProcessing = false;
    public isSelectingNewLocation = false;
    public isNewLocationSelected = false;

    private mapService: MapService;
    private locationPolygon: google.maps.Polygon;

    private selectSplitPoint2KeyPressedHandler; // document event handler bound to instance
    private selectSplitPoint2KeyReleasedHandler; // document event handler bound to instance

    // These three properties are used to mitigate a bug where the mousemove event sometimes stops being triggered when clicking (https://issuetracker.google.com/issues/121006350#comment6)
    private dragStartListener;
    private dragEndListener;
    private dragStartCenter;

    private snapMarker1: google.maps.Marker; // Map marker for snap point 1
    private snapMarker2: google.maps.Marker; // Map marker for snap point 2
    private snapMarkerEdgeIndex: number; // On which edge on the polygon is the snap marker

    private splitPoint1Marker: google.maps.Marker; // Map marker for split point 1
    private splitPoint2Marker: google.maps.Marker; // Map marker for split point 2

    private splitLine: google.maps.Polyline; // Line on map that shows split between locations

    private splitSnapMode: SnapSplitTo = SnapSplitTo.Nearest;
    private snapToleranceMeters = 5; // When mouse cursor is closer than this to the location, snap to the location outline

    private mouseMoveListener: google.maps.MapsEventListener;
    private mouseClickListener: google.maps.MapsEventListener;

    private splitLocationPolygons: google.maps.Polygon[] = [];

    constructor(
        private dialogRef: MatDialogRef<SplitLocationComponent>,
        private splitService: SplitService,
        private customerService: CustomerService,
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: {
            location: ExtendedLocation,
            locations?: ExtendedLocation[],
            venue: Venue
        }
    ) {
        // We will instantiate a separate instance of the MapService to not clash with the existing map.
        this.mapService = new MapService(this.solutionService);
    }

    ngOnInit(): void {
        const customer = this.customerService.getCurrentCustomer(true) as Customer;
        if (customer.modules?.includes('splitandcombine')) {
            this.initMap(this.data.venue)
                .then(this.startSplitMode.bind(this));
        }


    }

    /**
     * Close the modal for this component if the user confirms.
     *
     * @memberof SplitLocationComponent
     */
    public closeDialog(): void {
        const shouldDialogClose = confirm('Closing this window will discard any changes you have made.');
        if (shouldDialogClose) {
            this.dialogRef.close();
        }
    }

    /**
     * Reset and start over the interaction.
     *
     * @memberof SplitLocationComponent
     */
    public reset(): void {
        this.cancelSplitMode();
        this.startSplitMode();
    }

    /**
     * Initialize the map.
     * Set map's bounds to the selected location geometry and render a polygon to clarify what's selected.
     *
     * @private
     * @param {Venue} venue
     * @memberof SplitLocationComponent
     */
    private async initMap(venue: Venue): Promise<void> {
        await this.mapService.createMap(this.mapElement.nativeElement);
        this.mapService.setVenue(venue, this.data.location.floor.floorIndex);

        // Set bounds to fit rooom while leaving space for the controls in the bottom
        this.mapService.setBounds(this.data.location.geometry.bbox, { bottom: 60, left: 0, right: 0, top: 0 });

        this.drawLocationPolygon();
    }

    /**
     * Render the location as an extra polygon on the map.
     *
     * @memberof SplitLocationComponent
     */
    private drawLocationPolygon(): void {
        this.locationPolygon = this.mapService.drawPolygon(this.data.location.geometry);
        this.locationPolygon.setOptions({
            fillOpacity: 0,
            strokeWeight: 2,
            strokeOpacity: 1,
            strokeColor: '#ef6cce',
            zIndex: 1,
            clickable: false // otherwise mousemove event will not register while pointer is on top of polygon
        });
    }

    /**
     * Start Split mode by registering listeners and setting up map assets.
     *
     * @memberof SplitLocationComponent
     */
    private startSplitMode(): void {
        // Mitigate a bug where the mousemove event sometimes stops being triggered when clicking (https://issuetracker.google.com/issues/121006350#comment6)
        this.dragStartListener = this.mapService.addEventListener('dragstart', this.dragStarted.bind(this));
        this.dragEndListener = this.mapService.addEventListener('dragend', this.dragEnded.bind(this));

        // Bind keyboard event listeners used for snapping.
        this.selectSplitPoint2KeyPressedHandler = this.selectSplitPoint2KeyPressed.bind(this);
        this.selectSplitPoint2KeyReleasedHandler = this.selectSplitPoint2KeyReleased.bind(this);

        this.splitService.initialize(this.data.location);

        const splitMarkerOptions = {
            scaledSize: new google.maps.Size(12, 12),
            anchor: new google.maps.Point(6, 6),
            zIndex: 2
        };

        // Marker that snaps to the location when selecting split point 1
        this.snapMarker1 = this.mapService.drawMarker(null, {
            url: '/assets/images/line-marker-blue.svg',
            ...splitMarkerOptions
        });
        this.snapMarker1.setClickable(false);

        // Marker for selected split point 1
        this.splitPoint1Marker = this.mapService.drawMarker(null, {
            url: '/assets/images/line-marker-blue.svg',
            ...splitMarkerOptions
        });
        this.splitPoint1Marker.setClickable(false);

        // Marker that snaps to the location when selecting split point 2
        this.snapMarker2 = this.mapService.drawMarker(null, {
            url: '/assets/images/line-marker-white.svg',
            ...splitMarkerOptions
        });
        this.snapMarker2.setClickable(false);

        // Marker for selected split point 2
        this.splitPoint2Marker = this.mapService.drawMarker(null, {
            url: '/assets/images/line-marker-white.svg',
            ...splitMarkerOptions
        });
        this.splitPoint2Marker.setClickable(false);

        // Line between the two split points
        this.splitLine = this.mapService.drawPolyline([], {
            strokeColor: midt['tailwind-colors'].green[400].value,
            strokeOpacity: 1,
            strokeWeight: 2,
            clickable: false,
            zIndex: 2
        });

        this.selectSplitPoint1();
    }

    /**
     * Unregister listeners and reset state to clear up after being in Split mode.
     *
     * @memberof SplitLocationComponent
     */
    private cancelSplitMode(): void {
        this.isSelectingNewLocation = false;
        this.isNewLocationSelected = false;
        this.setMapCursor(MapCursorMode.Valid);

        this.mapService.removeEventListener(this.mouseMoveListener);
        this.mapService.removeEventListener(this.mouseClickListener);

        this.mapService.removeEventListener(this.dragStartListener);
        this.mapService.removeEventListener(this.dragEndListener);

        document.removeEventListener('keydown', this.selectSplitPoint2KeyPressedHandler);
        document.removeEventListener('keyup', this.selectSplitPoint2KeyReleasedHandler);

        if (this.snapMarker1) {
            this.snapMarker1.setMap(null);
            this.snapMarker1 = null;
        }
        if (this.snapMarker2) {
            this.snapMarker2.setMap(null);
            this.snapMarker2 = null;
        }

        this.splitService.splitPoint1 = null;
        if (this.splitPoint1Marker) {
            this.splitPoint1Marker.setMap(null);
            this.splitPoint1Marker = null;
        }

        this.splitService.splitPoint2 = null;
        if (this.splitPoint2Marker) {
            this.splitPoint2Marker.setMap(null);
            this.splitPoint2Marker = null;
        }

        if (this.splitLine) {
            this.splitLine.setMap(null);
            this.splitLine = null;
        }

        this.splitLocationPolygons.forEach(polygon => {
            polygon.setMap(null);
        });
        this.splitLocationPolygons = [];

        if (!this.locationPolygon) {
            this.drawLocationPolygon();
        }
    }

    /**
     * Add event listeners for selecting split point 1 on the split line.
     *
     * @memberof SplitLocationComponent
     */
    private selectSplitPoint1(): void {
        this.mouseMoveListener = this.mapService.addEventListener('mousemove', this.selectSplitPoint1MouseMoved.bind(this));
        this.mouseClickListener = this.mapService.addEventListener('click', this.selectSplitPoint1MouseClicked.bind(this));
    }

    /**
     * Place a marker on the edge of the location if mouse pointer is close enough.
     *
     * @param {google.maps.MapMouseEvent} mouseEvent
     * @memberof SplitLocationComponent
     */
    private selectSplitPoint1MouseMoved(mouseEvent: google.maps.MapMouseEvent): void {
        const nearestPointOnLocationPolygon = this.splitService.getNearestPointOnLocationPolygon([mouseEvent.latLng.lng(), mouseEvent.latLng.lat()]);

        if (nearestPointOnLocationPolygon.properties.dist < this.snapToleranceMeters / 1000) {
            this.snapMarker1.setPosition({ lat: nearestPointOnLocationPolygon.geometry.coordinates[1], lng: nearestPointOnLocationPolygon.geometry.coordinates[0] });
            this.snapMarkerEdgeIndex = nearestPointOnLocationPolygon.properties.index;
        } else {
            this.snapMarker1.setPosition(null);
            this.snapMarkerEdgeIndex = null;
        }
    }

    /**
     * Register split point 1 and place marker on the map for it.
     *
     * @memberof SplitLocationComponent
     */
    private selectSplitPoint1MouseClicked(): void {
        if (!this.snapMarker1.getPosition()) {
            return; // There is nothing snapped
        }

        this.splitService.splitPoint1 = turf.point([this.snapMarker1.getPosition().lng(), this.snapMarker1.getPosition().lat()]);
        this.splitService.splitPoint1EdgeIndex = this.snapMarkerEdgeIndex;
        this.splitPoint1Marker.setPosition(this.snapMarker1.getPosition());

        this.selectSplitPoint2();
    }

    /**
     * Remove/add event listeners and setup presentations for selecting split point 2 on the split line.
     *
     * @memberof SplitLocationComponent
     */
    private selectSplitPoint2(): void {
        this.mapService.removeEventListener(this.mouseClickListener);
        this.mapService.removeEventListener(this.mouseMoveListener);

        this.splitSnapMode = SnapSplitTo.Nearest;
        document.addEventListener('keydown', this.selectSplitPoint2KeyPressedHandler);
        document.addEventListener('keyup', this.selectSplitPoint2KeyReleasedHandler);

        this.mouseMoveListener = this.mapService.addEventListener('mousemove', this.selectSplitPoint2MouseMoved.bind(this));
        this.mouseClickListener = this.mapService.addEventListener('click', this.selectSplitPoint2MouseClicked.bind(this));
        this.snapMarker1.setMap(null);
        this.snapMarker1 = null;
    }

    /**
     * Place a marker on the edge of the location if mouse pointer is close enough and the resulting
     * location split is valid.
     *
     * Depending on the split snap mode it will either be
     * (a) nearest to to mouse pointer,
     * (b) or perpendicular from the edge of the first split point.
     *
     * @param {google.maps.MapMouseEvent} mouseEvent
     * @memberof SplitLocationComponent
     */
    private selectSplitPoint2MouseMoved(mouseEvent: google.maps.MapMouseEvent): void {
        switch (this.splitSnapMode) {
            case SnapSplitTo.Nearest: {
                const nearestPointOnLocationOutline: Feature<Point> = this.splitService.getNearestPointOnLocationPolygon([mouseEvent.latLng.lng(), mouseEvent.latLng.lat()]);
                if (nearestPointOnLocationOutline.properties.dist > this.snapToleranceMeters / 1000) {
                    // Mouse pointer is too far away from the location: Show no snap line or snap marker
                    this.snapMarker2.setPosition(null);
                    this.unsetSplitLine();
                    this.setMapCursor(MapCursorMode.Invalid);
                } else {
                    // Mouse pointer is close enough to the location
                    this.snapMarker2.setPosition({ lat: nearestPointOnLocationOutline.geometry.coordinates[1], lng: nearestPointOnLocationOutline.geometry.coordinates[0] });
                    const isSplitValid = this.splitService.isSplitValid(nearestPointOnLocationOutline);
                    if (isSplitValid) {
                        this.drawSplitLine(true);
                        this.setMapCursor(MapCursorMode.Valid);
                    } else {
                        this.drawSplitLine(false);
                        this.setMapCursor(MapCursorMode.Invalid);
                    }
                }
                break;
            }
            case SnapSplitTo.Perpendicular: {
                const perpendicularPoint: Feature<Point> = this.splitService.getPerpendicularPoint();
                if (!perpendicularPoint) {
                    this.snapMarker2.setPosition(null);
                    this.unsetSplitLine();
                    this.setMapCursor(MapCursorMode.Valid);
                } else {
                    this.snapMarker2.setPosition({ lat: perpendicularPoint.geometry.coordinates[1], lng: perpendicularPoint.geometry.coordinates[0] });
                    const isSplitValid = this.splitService.isSplitValid(perpendicularPoint);
                    if (isSplitValid) {
                        this.drawSplitLine(true);
                        this.setMapCursor(MapCursorMode.Valid);
                    } else {
                        this.drawSplitLine(false);
                        this.setMapCursor(MapCursorMode.Invalid);
                    }
                }
                break;
            }
        }
    }

    /**
     * Enable 90 degrees snapping if Shift key is pressed.
     *
     * @param {KeyboardEvent} keyboardEvent
     * @memberof SplitLocationComponent
     */
    private selectSplitPoint2KeyPressed(keyboardEvent: KeyboardEvent): void {
        if (['ShiftLeft', 'ShiftRight'].includes(keyboardEvent.code)) {
            this.splitSnapMode = SnapSplitTo.Perpendicular;
        }
    }

    /**
     * Disable 90 degrees snapping if Shift key is released.
     *
     * @param {KeyboardEvent} keyboardEvent
     * @memberof SplitLocationComponent
     */
    private selectSplitPoint2KeyReleased(keyboardEvent: KeyboardEvent): void {
        if (['ShiftLeft', 'ShiftRight'].includes(keyboardEvent.code)) {
            this.splitSnapMode = SnapSplitTo.Nearest;
        }
    }

    /**
     * Draw split line between split point 1 and the snap marker to signify how the location split will be.
     *
     * @param {boolean} isSplitValid
     * @memberof SplitLocationComponent
     */
    private drawSplitLine(isSplitValid: boolean): void {
        const splitLineGooglePolyline: google.maps.LatLng[] = [
            this.splitPoint1Marker.getPosition(),
            this.snapMarker2.getPosition()
        ];
        this.splitLine.setPath(splitLineGooglePolyline);
        this.splitLine.setOptions({
            strokeColor: isSplitValid ? midt['tailwind-colors'].green[400].value : midt['tailwind-colors'].red[400].value
        });
        this.splitLine.setVisible(true);
    }

    /**
     * Disable the split line.
     *
     * @memberof SplitLocationComponent
     */
    private unsetSplitLine(): void {
        this.splitLine.setVisible(false);
    }

    /**
     * Change mouse cursor on the map.
     *
     * @param {MapCursorMode} mode
     * @memberof SplitLocationComponent
     */
    private setMapCursor(mode: MapCursorMode): void {
        const googleMapCursorType = mode === MapCursorMode.Invalid ? 'not-allowed' : ''; // The empty string will set the default Google Maps "grab" cursor.
        this.mapService.getMap().setOptions({ draggableCursor: googleMapCursorType });
    }

    /**
     * Register split point 2 and place marker on the map for it.
     *
     * @memberof SplitLocationComponent
     */
    private selectSplitPoint2MouseClicked(): void {
        if (!this.snapMarker2.getPosition()) {
            this.notificationService.showError('The split is not possible. Try again');
            return;
        }

        const splitPoint2 = turf.point([this.snapMarker2.getPosition().lng(), this.snapMarker2.getPosition().lat()]);
        if (!this.splitService.isSplitValid(splitPoint2)) {
            this.notificationService.showError('The split is not possible. Try again');
            return;
        }

        this.splitService.splitPoint2 = splitPoint2;
        this.splitPoint2Marker.setPosition(this.snapMarker2.getPosition());
        this.selectNewLocationFromSplit();
    }

    /**
     * Render the split location and setup event listeners for selecting the new location of the two split locations.
     *
     * @memberof SplitLocationComponent
     */
    private selectNewLocationFromSplit(): void {
        this.mapService.removeEventListener(this.mouseClickListener);
        this.mapService.removeEventListener(this.mouseMoveListener);

        document.removeEventListener('keydown', this.selectSplitPoint2KeyPressedHandler);
        document.removeEventListener('keyup', this.selectSplitPoint2KeyReleasedHandler);

        this.snapMarker2.setMap(null);
        this.snapMarker2 = null;

        this.splitService.generateTwoLocationsFromSplitLine();
        this.renderSplitLocationFeaturesOnMap();

        this.isSelectingNewLocation = true;
    }

    /**
     * Render two polygons representing the two split locations.
     * The polygons are hightlighted when hovered.
     *
     * @memberof SplitLocationComponent
     */
    private renderSplitLocationFeaturesOnMap(): void {
        this.locationPolygon.setMap(null);
        this.locationPolygon = null;
        this.splitService.splitLocationFeatures.forEach((locationFeature, index) => {
            this.splitLocationPolygons[index] = this.mapService.drawPolygon(locationFeature.geometry as Geometry);
            this.splitLocationPolygons[index].setOptions({
                fillColor: '#ef6cce',
                fillOpacity: 0,
                strokeWeight: 2,
                strokeOpacity: 1,
                strokeColor: '#ef6cce',
                zIndex: 3
            });

            this.mapService.addEventListener('click', () => this.splitLocationClicked(index), this.splitLocationPolygons[index]);

            this.mapService.addEventListener('mouseover', () => {
                this.setMapCursor(MapCursorMode.Valid);
                this.splitLocationPolygons[index].setOptions({
                    fillOpacity: 0.3
                });
            }, this.splitLocationPolygons[index]);

            this.mapService.addEventListener('mouseout', () => {
                this.setMapCursor(MapCursorMode.Invalid);
                this.splitLocationPolygons[index].setOptions({
                    fillOpacity: 0
                });
            }, this.splitLocationPolygons[index]);
        });
    }

    /**
     * When clicking a split location, set that as selected, clean up map elements and present a confirmation button.
     *
     * @param splitLocationIndex
     * @memberof SplitLocationComponent
     */
    private splitLocationClicked(splitLocationIndex: number): void {
        this.mapService.clearInstanceListeners(this.splitLocationPolygons[0]);
        this.mapService.clearInstanceListeners(this.splitLocationPolygons[1]);

        this.splitService.newSplitLocationFeature = this.splitService.splitLocationFeatures[splitLocationIndex];
        this.splitService.originalSplitLocationFeature = this.splitService.splitLocationFeatures.find((locationFeature, index) => index !== splitLocationIndex);

        // Remove now irrelevant elements on the map
        this.splitLocationPolygons.forEach((splitLocationPolygon, index) => {
            if (index !== splitLocationIndex) {
                splitLocationPolygon.setMap(null);
            }
        });
        this.splitPoint1Marker.setMap(null);
        this.splitPoint2Marker.setMap(null);
        this.splitLine.setMap(null);

        // Make location clearer
        this.splitLocationPolygons[splitLocationIndex].setOptions({
            fillOpacity: 0.7
        });

        this.isSelectingNewLocation = false;
        this.isNewLocationSelected = true;
    }

    /**
     * Make the split in the data.
     *
     * @memberof SplitLocationComponent
     */
    public performSplit(): void {
        this.spinner.show();
        this.isProcessing = true;
        this.splitService.saveSplit()
            .pipe(finalize(() => this.isProcessing = false))
            .subscribe(
                // TODO: Improve error handling (MICMS-1389)
                newLocation => {
                    if (!newLocation) {
                        this.spinner.hide();
                        this.notificationService.showError('Something went wrong. The split is not saved. Please try again');
                    } else {
                        return this.dialogRef.close({
                            originalLocation: this.data.location,
                            newLocation
                        });
                    }
                },
                () => {
                    this.spinner.hide();
                    this.notificationService.showError('Something went wrong. The split is not saved. Please try again');
                }
            );
    }

    /**
     * Track the map center when dragstart event is triggered.
     * Part of mitigating a bug where the mousemove event sometimes stops being triggered when clicking (https://issuetracker.google.com/issues/121006350#comment6).
     *
     * @memberof SplitLocationComponent
     */
    private dragStarted(): void {
        this.dragStartCenter = this.mapService.getMap().getCenter();
    }

    /**
     * Trigger panBy on map if dragend map center is the same as when the dragstart fired.
     * Part of mitigating a bug where the mousemove event sometimes stops being triggered when clicking (https://issuetracker.google.com/issues/121006350#comment6).
     *
     * @memberof SplitLocationComponent
     */
    private dragEnded(): void {
        const map = this.mapService.getMap();
        if (this.dragStartCenter && this.dragStartCenter.equals(map.getCenter())) {
            map.panBy(0, 0);
        }
    }
}
