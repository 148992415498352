import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { Graph } from './Graph';
import { VenueService } from '../../venues/venue.service';
import { DataService } from '../data.service';

@Injectable({ providedIn: 'root' })
export class GraphsService {
    private endpoint = environment.APIEndpoint;
    private graphSubject = new ReplaySubject<Graph>(1);

    constructor(
        private http: HttpClient,
        private dataService: DataService,
        private venueService: VenueService,
    ) {
        this.venueService.selectedVenue$.pipe(
            switchMap(venue => this.loadGraph(venue.solutionId, venue.graphId)),
            shareReplay(1)
        ).subscribe(this.graphSubject);
    }

    /**
     * Represents an observable stream of the graph data.
     * Emits a new Graph object whenever the selected venue changes.
     */
    public readonly graph$: Observable<Graph> = this.graphSubject.asObservable();

    /**
     * Saves the graph.
     *
     * @param {Graph} graph - The graph to be saved.
     * @returns {Observable<void>} An Observable that emits void.
     */
    public save(graph: Graph): Observable<any> {
        const serializedGraph = graph.serialize();
        return this.dataService.updateItem(`${graph.solutionId}/api/graphs/`, serializedGraph)
            .pipe(tap(() => this.graphSubject.next(graph)));
    }

    /**
     * Loads the graph.
     *
     * @private
     * @param {string} solutionId - The solution id.
     * @param {string} graphId - The graph id.
     * @returns {Observable<Graph>} An Observable that emits the graph.
     */
    private loadGraph(solutionId: string, graphId: string): Observable<Graph> {
        return this.http.get<unknown[]>(`${this.endpoint}${solutionId}/api/graphs/${graphId ? graphId : ''}`)
            .pipe(map(graph => new Graph(graph)));
    }
}