import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Address } from '../../address/address.model';

@Component({
    selector: 'address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AddressFormComponent),
        multi: true,
    }]
})
export class AddressFormComponent implements OnInit, ControlValueAccessor {
    public onChange: (value: Address | null) => void = () => { };
    public onTouch: () => void = () => { };

    public addressForm: FormGroup = this.formBuilder.group({
        street1: [null, []],
        street2: [null, []],
        city: [null, []],
        region: [null, []],
        postalCode: [null, []],
        country: [null, []]
    });

    constructor(
        private formBuilder: FormBuilder
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.addressForm.valueChanges.subscribe((address: Address) => {
            this.onChange(Object.assign({}, Address.createAddress(address)));
        });
    }

    /**
     * WriteValue.
     *
     * @param {Address} address
     */
    writeValue(address: Address): void {
        this.addressForm.setValue(address ?? Address.EMPTY);
    }

    /**
     * RegisterOnChange.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnChange(fn: (address: Address) => void): void {
        this.onChange = (address: Address) => {
            const finalAddress = (Object.values(address).every(value => value === null) || this.addressForm.disabled) ? null : address;
            fn(finalAddress);
        };
    }

    /**
     * RegisterOnTouched.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * SetDisabledState.
     *
     * @param {boolean} isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.addressForm.disable() : this.addressForm.enable();
    }
}