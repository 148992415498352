<div class="search">
    <div class="search__container">
        <input #searchFilter
            type="search"
            [attr.aria-label]="placeholder"
            class="mi-input search__input"
            tabindex="0"
            [placeholder]="placeholder"
            (input)="onSearchInputEntered($event)">
        <span class="search__shadow"></span>
        <button #searchClearButton
            tabindex="{{ isClearButtonVisible ? 0 : -1 }}"
            type="button"
            (click)="onSearchFilterClear()"
            [ngClass]="{ 'search__clear': true, 'search__clear--visible': isClearButtonVisible }"
            aria-label="Clear">
            <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
            </svg>
        </button>
    </div>
</div>