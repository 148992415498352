import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatIconRegistry } from '@angular/material/icon';

import { finalize } from 'rxjs/operators';

import { SolutionService } from '../../services/solution.service';
import { SyncManagerService } from './sync-manager.service';
import { NotificationService } from '../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocationType } from '../../location-types/location-type.model';
import { Category } from '../../categories/category.model';
import { environment } from '../../../environments/environment';
import { MediaLibraryService } from '../../media-library/media-library.service';
import { MediaSyncService } from '../../media-library/media-sync/media-sync.service';
import { IMediaItem } from '../../media-library/media-item/media-item.model';


@Component({
    selector: 'app-sync-manager',
    templateUrl: './sync-manager.component.html',
    styleUrls: ['./sync-manager.component.scss']
})
export class SyncManagerComponent {
    public isSynced = false;
    public solutionSelectionModel = new SelectionModel<any>(true, []); //TODO: set solution type

    constructor(
        public dialogRef: MatDialogRef<SyncManagerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private solutionService: SolutionService,
        private syncManagerService: SyncManagerService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private mediaSyncService: MediaSyncService,
        private mediaLibraryService: MediaLibraryService
    ) {
        this.matIconRegistry.addSvgIcon(
            'checkmark',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/checkmark.svg')
        );
    }

    /**
     * Selects all solutions if they are not all selected or clear all selection.
     *
     * @memberof SyncManagerComponent
     */
    public toggleSolutions(): void {
        if (this.isAllSolutionsSelected()) this.solutionSelectionModel.clear();
        else this.solutionSelectionModel.select(...this.data.solutions);
    }

    /**
     * Does the number of selected solutions match the total number of solutions.
     *
     * @returns {boolean}
     * @memberof SyncManagerComponent
     */
    public isAllSolutionsSelected(): boolean {
        return this.solutionSelectionModel.selected.length === this.data.solutions.length;
    }

    /**
     * Synchronize icon, type or category.
     *
     * @returns {void}
     * @memberof SyncManagerComponent
     */
    public synchronize(): void {
        const sourceId = this.solutionService.getStaticSolution().id;
        const targetIds = this.solutionSelectionModel.selected.map(solution => solution.id);

        if (this.data.icon as IMediaItem) {
            this.syncIcon(this.data.icon.id, sourceId, targetIds);
            return;
        }

        if (this.data.locationType as LocationType) {
            this.syncLocationType(this.data.locationType.id, sourceId, targetIds);
            if (!this.data.locationType.displayRule.icon.includes(environment.iconsBaseUrl)) {
                this.mediaLibraryService.getMediaItems()
                    .subscribe((medias) => {
                        const mediaUrl = this.data.locationType.displayRule.icon;
                        const filename = mediaUrl.substring(mediaUrl.lastIndexOf('/') + 1, mediaUrl.lastIndexOf('?'));
                        const usedItem = medias.find(item => `${item.name}.${item.type}` === filename);
                        this.mediaSyncService.syncMedia(usedItem.id, sourceId, targetIds);
                    }, () => this.notificationService.showError('Something went wrong. Please try again.'));
            }
            return;
        }

        if (this.data.category as Category) {
            this.syncCategory(this.data.category, sourceId, targetIds);
            return;
        }
    }

    /**
     * Synchronize an icon from one solution to other solutions.
     *
     * @private
     * @param {string} iconId
     * @param {string} sourceSolutionId
     * @param {string[]} targetSolutionIds
     * @memberof SyncManagerComponent
     */
    private syncIcon(iconId: string, sourceSolutionId: string, targetSolutionIds: string[]): void {
        this.spinner.show();
        this.syncManagerService.syncImageToSolutions(iconId, sourceSolutionId, targetSolutionIds)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(
                () => this.isSynced = true,
                error => this.notificationService.showError(error)
            );
    }

    /**
     * Synchronize a location type from one solution to other solutions.
     *
     * @private
     * @param {string} locationTypeId
     * @param {string} sourceSolutionId
     * @param {string[]} targetSolutionIds
     * @memberof SyncManagerComponent
     */
    private syncLocationType(locationTypeId: string, sourceSolutionId: string, targetSolutionIds: string[]): void {
        this.spinner.show();
        this.syncManagerService.syncLocationTypeToSolutions(locationTypeId, sourceSolutionId, targetSolutionIds)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(
                () => this.isSynced = true,
                error => this.notificationService.showError(error)
            );
    }

    /**
     * Synchronize a category from one solution to other solutions.
     *
     * @private
     * @param {Category} category
     * @param {string} sourceSolutionId
     * @param {string[]} targetSolutionIds
     * @memberof SyncManagerComponent
     */
    private syncCategory(category: Category, sourceSolutionId: string, targetSolutionIds: string[]): void {
        this.spinner.show();
        this.syncManagerService.syncCategoryToSolutions(category, sourceSolutionId, targetSolutionIds)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(
                () => this.isSynced = true,
                error => this.notificationService.showError(error),
                () => this.isSynced = true
            );
    }
}
