import { Component, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'venues-select-list',
    templateUrl: 'venues-select-list.component.html',
    styleUrls: ['venues-select-list.component.scss'],
})
export class VenuesSelectListComponent {
    #selectionSubject = new Subject<{ value: string, text: string }[]>();

    /**
     * The dropdown items.
     *
     * @type {{value: string, text: string}[]}
     */
    @Input() set items(items: { value: string, text: string }[]) {
        this.#dropdownItems = items.map(item => {
            const dropdownItem = document.createElement('mi-dropdown-item') as HTMLMiDropdownItemElement;
            dropdownItem.value = item.value;
            dropdownItem.text = item.text;
            return dropdownItem;
        });
    }

    @Input() label: string = '';

    #dropdownItems: HTMLMiDropdownItemElement[] = [];

    /**
     * The dropdown items.
     *
     * @type {HTMLMiDropdownItemElement[]}
     */
    public get dropdownItems(): HTMLMiDropdownItemElement[] {
        return this.#dropdownItems;
    }

    /**
     * Handles the change event from the dropdown.
     *
     * @param {CustomEvent} event - The event object from the dropdown.
     */
    public onChange(event: CustomEvent): void {
        this.#selectionSubject.next(event.detail);
    }

    /**
     * An Observable that emits when the dropdown selection changes.
     *
     * @type {Observable<{value: string, text: string}[]>}
     */
    @Output() public readonly selectionChange: Observable<{ value: string, text: string }[]> = this.#selectionSubject.asObservable();
}