import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { SolutionService } from '../solution.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { OccupantTemplate } from './occupantTemplate.model';
import { NotificationService } from '../notification.service';
import { Solution } from '../../solutions/solution.model';
import { OrderByLanguage } from '../../pipes/order-by-language.pipe';
import { Translation } from '../../shared/interfaces/translation.model';
import { SimplifiedOccupantTemplate } from './simplifiedOccupantTemplate.model';
import { LocationType } from '../../location-types/location-type.model';

const API_ENDPOINT = environment.APIEndpoint;

@Injectable({ providedIn: 'root' })
export class OccupantTemplateService {
    private _occupantTemplatesMap: Map<string, Promise<OccupantTemplate>> = new Map();
    private _simplifiedOccupantTemplatesMap: Map<string, SimplifiedOccupantTemplate> = new Map();
    private _simplifiedOccupantTemplatesSubject: BehaviorSubject<SimplifiedOccupantTemplate[]> = new BehaviorSubject<SimplifiedOccupantTemplate[]>([]);
    private currentSolution: Solution;

    constructor(
        private solutionService: SolutionService,
        private http: HttpClient,
        private notificationService: NotificationService,
        private orderByLanguagePipe: OrderByLanguage
    ) {
        this.solutionService.getCurrentSolution().pipe(
            switchMap((solution) => {
                this.currentSolution = solution;
                return this.getOccupantTemplatesPaginated(0, 100);
            }),
            tap((occupantTemplates) => {
                const simplifiedOccupantTemplatesList = occupantTemplates?.items?.map(occupantTemplate => new SimplifiedOccupantTemplate(occupantTemplate)) ?? [];
                this._simplifiedOccupantTemplatesMap = new Map(simplifiedOccupantTemplatesList.map(occupantTemplate => ([occupantTemplate.id, occupantTemplate])));
                this._simplifiedOccupantTemplatesSubject.next(simplifiedOccupantTemplatesList);
            }),
            catchError((err: Error) => {
                this.notificationService.showError(err);
                return of([]);
            })
        ).subscribe();
    }


    /**
     * Get occupant templates as an observable.
     *
     * @returns {Observable<SimplifiedOccupantTemplate[]>}
     */
    get simplifiedOccupantTemplates$(): Observable<SimplifiedOccupantTemplate[]> {
        return this._simplifiedOccupantTemplatesSubject.asObservable();
    }

    /**
     * Get all occupant templates.
     *
     * @returns {Observable<OccupantTemplate[]>}
     */
    private getOccupantTemplates(): Observable<OccupantTemplate[]> {
        return this.http.get<OccupantTemplate[]>(`${API_ENDPOINT}${this.currentSolution.id}/api/occupantTemplate/simplified?language=${this.currentSolution.defaultLanguage}`);
    }

    /**
     * Adds a new occupant template to the lists.
     *
     * @param {OccupantTemplate} occupantTemplate
     */
    private addNewOccupantTemplateToLists(occupantTemplate: OccupantTemplate): void {
        // Occupant template map
        this._occupantTemplatesMap.set(occupantTemplate.id, Promise.resolve(OccupantTemplate.createOccupantTemplate({ occupantTemplate })));

        // Simplified occupant template map
        const simplifiedOccupantTemplate = new SimplifiedOccupantTemplate(occupantTemplate);
        simplifiedOccupantTemplate.name = occupantTemplate.translations.find((translation: Translation) => translation.language === this.currentSolution.defaultLanguage).name;
        simplifiedOccupantTemplate.occupantReferenceCount = occupantTemplate.occupantReferenceCount;
        simplifiedOccupantTemplate.lastModified = new Date().toISOString();
        this._simplifiedOccupantTemplatesMap.set(simplifiedOccupantTemplate.id, simplifiedOccupantTemplate);
    }

    /**
     * Get occupant template details.
     *
     * @param {string} id
     * @returns {Observable<OccupantTemplate>}
     */
    public getOccupantTemplateDetails(id: string): Promise<OccupantTemplate> {
        if (this._occupantTemplatesMap.has(id)) {
            return this._occupantTemplatesMap.get(id);
        } else {
            const occupantTemplate = this.http.get<OccupantTemplate>(`${API_ENDPOINT}${this.currentSolution.id}/api/occupantTemplate/details/${id}`).toPromise();
            this._occupantTemplatesMap.set(id, occupantTemplate);

            return occupantTemplate;
        }
    }

    /**
     * Get paginated occupant templates.
     *
     * @param {number} skip - Skips specific amount of items, in this case Occupant Templates. If skip is assigned to 0, it will start from the beginning.
     * @param {number} take - Takes specific amount of items, after skip, in this case Occupant Templates.
     * @param {string} filter - Filters all Occupant Templates by a specific string.
     * @returns {Observable<{ items: SimplifiedOccupantTemplate[], totalCount: number }>}
     */
    public getOccupantTemplatesPaginated(skip: number, take: number, filter: string = ''): Observable<{ items: SimplifiedOccupantTemplate[], totalCount: number }> {
        const url = new URL(`${API_ENDPOINT}${this.currentSolution.id}/api/occupantTemplate/simplified/paginated`);
        url.searchParams.set('language', this.currentSolution.defaultLanguage);
        url.searchParams.set('skip', `${skip}`);
        url.searchParams.set('take', `${take}`);
        url.searchParams.set('filter', filter);
        return this.http.get<{ items: SimplifiedOccupantTemplate[], totalCount: number }>(url.toString());
    }

    /**
     * Get simplified occupant template.
     *
     * @param {string} id - Occupant template id.
     * @returns {Observable<SimplifiedOccupantTemplate>}
     */
    public getSimplifiedOccupantTemplate(id: string): Observable<SimplifiedOccupantTemplate> {
        return new Observable(subscribe => {
            this.getOccupantTemplateDetails(id).then(occupantTemplate => {
                if (!occupantTemplate) {
                    return null;
                }

                const translations = occupantTemplate.translations.find((translation: Translation) => translation.language === this.currentSolution.defaultLanguage);

                if (!translations) {
                    return null;
                }

                subscribe.next({
                    id: occupantTemplate.id,
                    name: translations.name,
                    occupantReferenceCount: occupantTemplate.occupantReferenceCount,
                    lastModified: occupantTemplate.lastModified
                } as SimplifiedOccupantTemplate);
            });
        });
    }

    /**
     * Get occupant template details.
     *
     * @param {string} id
     * @returns {Promise<OccupantTemplate>}
     */
    public getOccupantTemplate(id: string): Promise<OccupantTemplate> {
        return this.getOccupantTemplateDetails(id);
    }

    /**
     * Get location type by occupant template id.
     *
     * @param {string} id
     * @returns {Observable<LocationType>}
     */
    public getLocationTypeByOccupantTemplateId(id: string): Observable<LocationType> {
        return this.http.get<LocationType>(`${API_ENDPOINT}${this.currentSolution.id}/api/occupantTemplate/${id}/locationType`);
    }

    /**
     * Creates translation objects from solution's languages.
     *
     * @param {Solution} solution
     * @returns {Translation[]}
     */
    public createTranslationsArray(solution: Solution): Translation[] {
        const translations: Translation[] = [];
        if (solution) {
            for (const lang of solution.availableLanguages) {
                const newLanguage: Translation = {
                    language: lang,
                    name: null,
                    description: null,
                    fields: {}
                };

                translations.push(newLanguage);
            }
        }
        return this.orderByLanguagePipe.transform(translations, solution.defaultLanguage);
    }

    /**
     * Creates a new occupant templates.
     *
     * @param {OccupantTemplate} newOccupantTemplate
     * @returns {Observable<OccupantTemplate[]>}
     */
    public createOccupantTemplate(newOccupantTemplate: OccupantTemplate): Observable<OccupantTemplate[]> {
        return this.http.post<OccupantTemplate[]>(`${API_ENDPOINT}${this.currentSolution.id}/api/occupantTemplate`, [newOccupantTemplate])
            .pipe(
                tap((newOccupantTemplates: OccupantTemplate[]) => {
                    for (const newOccupantTemplate of newOccupantTemplates) {
                        this.addNewOccupantTemplateToLists(newOccupantTemplate);
                    }
                    this._simplifiedOccupantTemplatesSubject.next(Array.from(this._simplifiedOccupantTemplatesMap.values()));
                }),
                catchError((err: Error) => {
                    this.notificationService.showError(err);
                    return of([]);
                })
            );
    }

    /**
     * Updates existing occupant templates.
     *
     * @param {OccupantTemplate} updatedOccupantTemplate
     * @returns {Observable<OccupantTemplate>}
     */
    public updateOccupantTemplate(updatedOccupantTemplate: OccupantTemplate): Observable<void> {
        return this.http.put<void>(`${API_ENDPOINT}${this.currentSolution.id}/api/occupantTemplate`, updatedOccupantTemplate)
            .pipe(
                tap(() => {
                    this.addNewOccupantTemplateToLists(updatedOccupantTemplate);
                    this._simplifiedOccupantTemplatesSubject.next(Array.from(this._simplifiedOccupantTemplatesMap.values()));
                })
            );
    }

    /**
     * Deletes occupant templates and notifies observers on completion.
     *
     * @param {OccupantTemplate[] | SimplifiedOccupantTemplate[]} occupantTemplates
     * @returns {Observable<void>}
     */
    public deleteOccupantTemplates(occupantTemplates: OccupantTemplate[] | SimplifiedOccupantTemplate[]): Observable<void> {
        return forkJoin(occupantTemplates.map(occupantTemplate =>
            this.http.delete<void>(`${API_ENDPOINT}${this.currentSolution.id}/api/occupantTemplate/${occupantTemplate.id}`)
        ))
            .pipe(
                tap(() => {
                    const occupantTemplateName: string = occupantTemplates[0] instanceof SimplifiedOccupantTemplate ?
                        occupantTemplates[0].name
                        : occupantTemplates[0].translations.find((translation: Translation) => translation.language === this.currentSolution.defaultLanguage).name;

                    const message: string = occupantTemplates.length > 1 ?
                        `${occupantTemplates.length} Occupant Templates deleted`
                        : `${occupantTemplateName} deleted`;
                    this.notificationService.showSuccess(message);

                    occupantTemplates.forEach(occupantTemplate => {
                        this._simplifiedOccupantTemplatesMap.delete(occupantTemplate.id);
                        this._occupantTemplatesMap.delete(occupantTemplate.id);
                    });
                    this._simplifiedOccupantTemplatesSubject.next(Array.from(this._simplifiedOccupantTemplatesMap.values()));
                }),
                catchError(() => {
                    return of(null);
                })
            );
    }
}