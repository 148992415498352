import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { ContactInformation } from '../../contactInformation/contactInformation.model';

@Component({
    selector: 'contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ContactFormComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => ContactFormComponent),
        multi: true,
    }]
})
export class ContactFormComponent implements OnInit, ControlValueAccessor {
    public onChange: (value: ContactInformation | null) => void = () => { };
    public onTouch: () => void = () => { };

    public contactForm: FormGroup = this.formBuilder.group({
        webAddress: [null, [Validators.pattern(/^(http|https):\/\/[^ "]+$/)]],
        email: [null, [Validators.email]],
        phoneNumber: [null, [Validators.pattern(/.*[0-9].*/)]] // The agreed validation with the BE team is that the phone number MUST contain at least one number.
    });

    constructor(
        private formBuilder: FormBuilder
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.contactForm.valueChanges.subscribe((contactInformation: ContactInformation) => {
            this.onChange(Object.assign({}, ContactInformation.createContactInformation(contactInformation)));
        });
    }

    /**
     * WriteValue.
     *
     * @param {ContactInformation} contactInformation
     */
    writeValue(contactInformation: ContactInformation): void {
        this.contactForm.setValue(contactInformation ?? ContactInformation.EMPTY);
    }

    /**
     * RegisterOnChange.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnChange(fn: (contactInformation: ContactInformation) => void): void {
        this.onChange = (contactInformation: ContactInformation) => {
            const finalContactInformation = (Object.values(contactInformation).every(value => value === null) || this.contactForm.disabled) ? null : contactInformation;
            fn(finalContactInformation);
        };
    }

    /**
     * RegisterOnTouched.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * Method that performs synchronous validation against the provided control.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return this.contactForm.invalid ? { invalid: true } : null;
    }

    /**
     * SetDisabledState.
     *
     * @param {boolean} isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.contactForm.disable() : this.contactForm.enable();
    }
}