import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    templateUrl: './import-export-dialog.component.html',
    styleUrls: ['./import-export-dialog.component.scss']
})
export class ImportExportDialogComponent implements OnInit {
    public anyEntriesChanged = false;

    constructor(
        public dialogRef: MatDialogRef<ImportExportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        // Check for changed entries
        if (this.data.entriesTotal > 0) {
            if (this.data.entriesAdded > 0
                || this.data.entriesUpdated > 0
                || this.data.entriesDeleted > 0
                || this.data.entriesFailed > 0) {
                this.anyEntriesChanged = true;
            }
        }

    }
}