<form [formGroup]="locationForm" class="form">
    <navigatable-container>
        <navigatable-section header="General">
            <section class="section">
                <location-type-dropdown
                    [isDisabled]="locationHasOccupant"
                    warningMessage="The type cannot be changed here. It's determined by the selected Occupant Template and is changed automatically." 
                    formControlName="type"></location-type-dropdown>
                <translations-form *ngIf="locationHasOccupant === false" formControlName="translations"></translations-form>
                <location-categories formControlName="categories"></location-categories>
                <section class="section__row">
                    <label class="mi-label">
                        <span class="documentation-reference">
                            External ID
                            <a class="documentation-reference__link"
                                href="https://docs.mapsindoors.com/content/various/external-id" target="_blank">
                                Help
                            </a>
                        </span>
                        <input class="mi-input" type="text" formControlName="externalId" placeholder="1.234-abc" />
                    </label>
                </section>
            </section>
        </navigatable-section>

        <navigatable-section header="Area" *ngIf="location?.locationType === 'area'">
            <location-rotation formControlName="angle"></location-rotation>
            <section class="section" *ngIf="isOwnerOrAdmin" formGroupName="locationSettings">
                <div>
                    <div class="section__header">
                        <lock-button 
                            class="setting__lock" 
                            [locked]="getFormControl(locationForm, 'locationSettings.obstacle').disabled"
                            (toggled)="toggleInheritance('locationSettings.obstacle', $event)">
                        </lock-button>
                        <h3 class="section__sub-title">Area as an Obstacle</h3>
                    </div>
                    <hr>
                    <p>Set the Area as an Obstacle if the Area should have an effect on the routing.</p>
                    <obstacle-input formControlName="obstacle"></obstacle-input>
                </div>
            </section>
        </navigatable-section>

        <navigatable-section 
            header="Selectable"
            formGroupName="locationSettings"
            [isLockable]="true" 
            [locked]="getFormControl(locationForm, 'locationSettings.selectable').disabled" 
            (lockedChange)="toggleInheritance('locationSettings.selectable', $event)"
            (click)="disableInheritance('locationSettings.selectable', $event)">
            <section class="section">
                <div [ngClass]="{'section__description--disabled': getFormControl(locationForm, 'locationSettings.selectable').disabled}">
                    The "selectable" setting controls whether it is possible to click on a Location on the Map.
                    <info-tooltip text="The 'Not Selectable' option does not affect the visibility in the search-result list. Go to the Search section to change this behavior."></info-tooltip>
                </div>
                <selectable-input formControlName="selectable"></selectable-input>
            </section>
        </navigatable-section>

        <navigatable-section
            header="Restrictions"
            documentationLink="https://docs.mapsindoors.com/content/map/displaying-objects/app-user-roles/#features-affected-by-app-user-roles"
            [isLockable]="true" 
            [locked]="getFormControl(locationForm, 'restrictions').disabled" 
            (lockedChange)="toggleInheritance('restrictions', $event)"
            (click)="disableInheritance('restrictions', $event)">
            <section class="section">
                <restrictions-input formControlName="restrictions" [location]="location">
                </restrictions-input>
            </section>
        </navigatable-section>

        <navigatable-section header="Search" documentationLink="https://docs.mapsindoors.com/content/cms/#location">
            <section class="section">
                <toggle-searchable formControlName="status"></toggle-searchable>
                <aliases-list *ngIf="locationHasOccupant === false" formControlName="aliases"></aliases-list>
            </section>
        </navigatable-section>

        <navigatable-section header="Visibility"
            documentationLink="https://docs.mapsindoors.com/content/cms/#editing-data">
            <section class="section">
                <div class="section__row">
                    <label class="mi-label">Active from
                        <input class="mi-input" type="date" formControlName="activeFrom" [min]="MIN_DATE"
                            [max]="MAX_DATE" />
                        <small role="alert" class="mi-form__error"
                            *ngIf="locationForm.get('activeFrom').hasError('minError')">
                            {{locationForm.get('activeFrom').errors.minError}}
                        </small>
                        <small role="alert" class="mi-form__error"
                            *ngIf="locationForm.get('activeFrom').hasError('maxError')">
                            {{locationForm.get('activeFrom').errors.maxError}}
                        </small>
                    </label>
                    <label class="mi-label">Active to
                        <input class="mi-input" type="date" formControlName="activeTo" [min]="MIN_DATE"
                            [max]="MAX_DATE" />
                        <small role="alert" class="mi-form__error"
                            *ngIf="locationForm.get('activeTo').hasError('minError')">
                            {{locationForm.get('activeTo').errors.minError}}
                        </small>
                        <small role="alert" class="mi-form__error"
                            *ngIf="locationForm.get('activeTo').hasError('maxError')">
                            {{locationForm.get('activeTo').errors.maxError}}
                        </small>
                    </label>
                </div>
            </section>
        </navigatable-section>

        <navigatable-section header="Image" *ngIf="locationHasOccupant === false" documentationLink="https://docs.mapsindoors.com/products/cms/editing-data#image">
            <section class="section">
                <mi-tabs>
                    <mi-tab tab-for="image" label="Image"></mi-tab>
                    <mi-tab tab-for="google-street-view" label="Google Street View"></mi-tab>
                    <mi-tab-panel id="image">
                        <image-upload #imageUploadComponent
                            [imageSrc]="location?.imageURL"
                            [reset]="discardChange.asObservable()"
                            (imageUrlChanged)="onImageUrlChange($event)">
                        </image-upload>
                    </mi-tab-panel>
                    <mi-tab-panel id="google-street-view">
                        <image-street-view
                            [streetViewConfig]="location?.streetViewConfig"
                            [reset]="discardChange.asObservable()"
                            [location]="location"
                            (updatePanorama)="onUpdatePanorama($event)"
                            (snackBarMessage)="showInfoNotification($event)">
                        </image-street-view>
                    </mi-tab-panel>
                </mi-tabs>
            </section>
        </navigatable-section>

        <navigatable-section header="Custom Properties"
            documentationLink="https://docs.mapsindoors.com/content/data/customisable-data/custom-properties/">
            <section class="section">
                <location-custom-properties [typeName]="typeName" formControlName="customProperties"></location-custom-properties>
            </section>
        </navigatable-section>

        <navigatable-section header="Details">
            <section class="section">
                <div class="section__row">
                    <label class="mi-label">Building
                        <mi-dropdown
                            [disabled]="cannotUpdateBuilding"
                            #buildingsDropdown
                            filterable
                            (change)="onBuildingsDropdownChange($event)">
                        </mi-dropdown>
                    </label>
                    <label class="mi-label">Floor
                        <mi-dropdown
                            class="right-align"
                            [disabled]="cannotUpdateFloor"
                            #floorsDropdown
                            filterable
                            (change)="onFloorsDropdownChange($event)">
                        </mi-dropdown>
                    </label>
                </div>

                <dl class="description-list">
                    <dt class="mi-label description-list__term">MapsIndoors Location ID:</dt>
                    <dd class="description-list__detail"><code>{{ location?.id }}</code></dd>
                    
                    <div class="section__row">
                        <ng-container *ngIf="!location?.anchor && location?.locationType.toLowerCase() === 'area'">
                            <div class="mi-label">Save Area to see latitude and longitude for the anchor point.</div>
                        </ng-container>

                        <ng-container *ngIf="location?.anchor">
                            <div class="description-list__container">
                                <dt class="mi-label description-list__term">Latitude:</dt>
                                <dd class="description-list__detail"><code>{{ this.anchorPoint?.coordinates[1] }}</code></dd>
                            </div>

                            <div class="description-list__container">
                                <dt class="mi-label description-list__term">Longitude:</dt>
                                <dd class="description-list__detail"><code>{{ this.anchorPoint?.coordinates[0] }}</code></dd>
                            </div>
                        </ng-container>
                    </div>
                </dl>
            </section>
        </navigatable-section>
    </navigatable-container>

    <panel-footer *ngIf="locationForm.dirty" class="footer" text="You have unsaved changes.">
        <button type="button" class="mi-button mi-button--link" (click)="onDiscard()">Discard</button>
        <button type="submit" class="mi-button mi-button--primary" (click)="onSave()" *ngIf="location.id" [disabled]="!locationForm.valid">Save</button>
        <button type="submit" class="mi-button mi-button--primary" (click)="onSaveAndClose()" [disabled]="!locationForm.valid">Save and close</button>
    </panel-footer>
</form>