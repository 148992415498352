import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { BaseMapAdapter } from '../../../../../MapAdapter/BaseMapAdapter';
import { MapAdapterMediator } from '../../../../map-adapter.mediator';
import { GraphsService } from '../../../../../services/GraphService/graps.service';
import { GraphEditor, GraphEditorMode } from '../../../../../GeodataEditor.v2/GraphEditor';
import { BuildingService } from '../../../../../buildings/building.service';
import { NotificationService } from '../../../../../services/notification.service';
import { finalize } from 'rxjs/operators';

enum EditorStatus {
    Editing,
    Saving,
}

@Component({
    selector: 'edit-graph',
    templateUrl: './edit-graph.component.html',
    styleUrls: ['./edit-graph.component.scss']
})
export class EditGraphComponent implements OnInit, OnDestroy {
    #destroySubject: Subject<void> = new Subject();
    #mapAdapter: BaseMapAdapter;
    #graphEditor: GraphEditor;
    #subscriptions: Subscription = new Subscription();
    #status: EditorStatus = EditorStatus.Editing;

    /**
     * The different statuses of the graph editor.
     *
     * @readonly
     */
    public readonly EditorStatus: typeof EditorStatus = EditorStatus;

    /**
     * The different modes of the graph editor.
     *
     * @readonly
     */
    public readonly EditorMode: typeof GraphEditorMode = GraphEditorMode;

    /**
     * The current mode of the graph editor.
     *
     * @readonly
     * @returns {GraphEditorMode}
     */
    public get mode(): GraphEditorMode {
        return this.#graphEditor.mode;
    }

    /**
     * The current status of the graph editor.
     *
     * @readonly
     * @returns {EditorStatus}
     */
    public get status(): EditorStatus {
        return this.#status;
    }

    /**
     * Checks if there are steps to undo.
     *
     * @readonly
     * @returns {boolean}
     */
    public get canUndo(): boolean {
        return this.#graphEditor.canUndo;
    }

    /**
     * Checks if there are steps to redo.
     *
     * @readonly
     * @returns {boolean}
     */
    public get canRedo(): boolean {
        return this.#graphEditor.canRedo;
    }

    /**
     * Observable for the destroy event.
     *
     * @readonly
     */
    @Output() public readonly destroy = this.#destroySubject.asObservable();

    constructor(
        private mapAdapterMediator: MapAdapterMediator,
        private buildingService: BuildingService,
        private graphsService: GraphsService,
        private notificationService: NotificationService,
    ) {
        this.#mapAdapter = this.mapAdapterMediator.getMapAdapter();
        this.#graphEditor = new GraphEditor(this.#mapAdapter, this.buildingService);
        this.#mapAdapter.isClickable = false;
        this.#mapAdapter.isHoverable = false;
    }

    /** NgOnInit. */
    ngOnInit(): void {
        const graphSubscription =
            this.graphsService.graph$
                .subscribe(graph => {
                    this.#graphEditor.setGraph(graph);
                });

        this.#subscriptions
            .add(graphSubscription);
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.#graphEditor.clear();
        this.#mapAdapter.isClickable = true;
        this.#mapAdapter.isHoverable = true;
        this.#subscriptions.unsubscribe();
        this.#destroySubject.complete();
    }

    /**
     * Returns whether the graph editor has a selection.
     *
     * @returns {boolean}
     */
    public get hasSelection(): boolean {
        return this.#graphEditor.hasSelection();
    }

    /**
     * Cancels the current graph editing.
     */
    public onCancel(): void {
        // eslint-disable-next-line no-alert
        if (confirm('Canceling will discard any unsaved changes, are you sure you want to continue?')) {
            this.#destroySubject.next();
        }
    }

    /**
     * Saves the current graph.
     */
    public onSave(closeAfter: boolean = false): void {
        this.#status = EditorStatus.Saving;
        this.graphsService.save(this.#graphEditor.getGraph())
            .pipe(
                finalize(() => {
                    if (closeAfter) {
                        this.#destroySubject.next();
                    }
                }))
            .subscribe(
                () => this.notificationService.showSuccess('Your Navigation Network was successfully saved.'),
                () => this.notificationService.showError('An error occurred, please try again.'),
                () => this.#status = EditorStatus.Editing
            );
    }

    /**
     * Deletes the selected graph elements.
     */
    onDeleteButtonClick(): void {
        this.#graphEditor.deleteSelected();
    }

    /**
     * Sets the mode of the graph editor.
     *
     * @param {GraphEditorMode} mode - The mode to set.
     */
    public setMode(mode: GraphEditorMode = GraphEditorMode.EDIT): void {
        this.#graphEditor.mode = mode;
    }

    /**
     * Redo the last undone action in the graph editor.
     */
    public redo(): void {
        this.#graphEditor.redo();
    }

    /**
     * Undo the last action in the graph editor.
     */
    public undo(): void {
        this.#graphEditor.undo();
    }
}