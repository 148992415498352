import { Component, OnInit, Output, EventEmitter, Optional, OnDestroy, Input, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeoJSONGeometryType, RegexPatterns, UnitSystem } from '../../shared/enums';
import { getDoorWidth } from '../../shared/geometry-helper';
import * as turf from '@turf/turf';

import { AppUserRole } from '../../app-settings/config/app-user-roles/app-user-role.model';
import { AppUserRolesService } from '../../app-settings/config/app-user-roles/app-user-roles.service';

import { PartialObserver, Subscription } from 'rxjs';
import { UserAgentService } from '../../services/user-agent.service';
import isEqual from 'fast-deep-equal';
import { mergeObjects, primitiveClone } from '../../shared/object-helper';
import { RouteElement, RouteElementType } from '../../map/route-element-details/route-element.model';
import { NetworkService } from '../../network-access/network.service';
import { finalize, map, tap } from 'rxjs/operators';
import { BaseMapAdapter } from '../../MapAdapter/BaseMapAdapter';
import { MapAdapterMediator } from '../map-adapter.mediator';
import { GeodataEditor, GeodataEditorFactory } from '../../GeodataEditor/GeodataEditor.factory';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { NotificationService } from '../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RouteElementEditorOperation } from '../../GeodataEditor/GeodataEditorOperation/RouteElementEditorOperation';
import { MapSidebar } from '../map-sidebar/map-sidebar.interface';
import { Position } from 'geojson';
import { LocationService } from '../../locations/location.service';
import { convertCentimetersToMeters, convertInchesToMeters } from '../../shared/conversion-helper';
import { FloorService } from '../../services/floor.service';
import { MapUIComponents, MapUIService } from '../map-ui-service/map-ui.service';
import { Router } from '@angular/router';
import { stayAtCurrentUrl } from '../../solution-settings/solution-settings-shared-functions.component';
import { BuildingService } from '../../buildings/building.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'route-element-details-editor',
    templateUrl: './route-element-details-editor.component.html',
    styleUrls: ['./route-element-details-editor.component.scss']
})
export class RouteElementDetailsEditorComponent implements OnInit, OnDestroy, MapSidebar {
    #appUserRoles: AppUserRole[] = [];
    private originalFormState;
    private _routeElement: RouteElement;
    private subscriptions = new Subscription();
    private _geoDataEditor: GeodataEditor;
    private _mapAdapter: BaseMapAdapter;
    private _editRouteElement: RouteElementEditorOperation;
    private _duplicationInProgress: boolean = false;
    private isOwner: boolean = false;

    public isDiscardButtonDisabled: boolean = false;
    public unitSystem: UnitSystem;
    public geoJSONGeometryType = GeoJSONGeometryType;
    public lineStringLengthInputValue: number; // in browser unit system
    public routeElementTypes = RouteElementType;
    public form: FormGroup = this.formBuilder.group({
        elementValue: [''],
        width: ['', [Validators.min(0)]],
        waitTime: ['', [Validators.min(0)]],
        radius: ['', [Validators.min(0), Validators.required]],
        floorIndex: ['', [Validators.required, Validators.pattern(RegexPatterns.NumericalNoDecimals)]],
        onewayDirection: ['', [Validators.min(0), Validators.max(360)]],
        subtype: [''],
        restrictions: [[]],
        geometry: [{}, [Validators.required]],
    });

    @Output()
    public closed: EventEmitter<void> = new EventEmitter();

    /**
     * The selected route element.
     *
     * @returns {RouteElement}
     * @memberof RouteElementDetailsComponent
     */
    get routeElement(): RouteElement {
        return this._routeElement;
    }

    /**
     * App UserRoles.
     *
     * @readonly
     * @type {AppUserRole[]}
     * @memberof RouteElementDetailsComponent
     */
    get appUserRoles(): AppUserRole[] {
        return this.#appUserRoles;
    }

    /**
     * RouteElementType enum.
     *
     * @readonly
     * @type {typeof RouteElementType}
     * @memberof RouteElementDetailsComponent
     */
    public get routeElementType(): typeof RouteElementType {
        return RouteElementType;
    }

    /**
     * Property that reflects if the route element can be duplicated.
     *
     * @readonly
     * @type {boolean}
     * @memberof LocationDetailsEditorComponent
     */
    public get canRouteElementBeDuplicated(): boolean {
        return this.isOwner
            && !this._duplicationInProgress
            && (this?.routeElement?.id > '' && this.routeElement?.type === this.routeElementType.Connector);
    }

    constructor(
        @Optional() private mapAdapterMediator: MapAdapterMediator,
        private appUserRolesService: AppUserRolesService,
        private buildingService: BuildingService,
        private displayRuleService: DisplayRuleService,
        private floorService: FloorService,
        private formBuilder: FormBuilder,
        private locationService: LocationService,
        private mapUIService: MapUIService,
        private networkService: NetworkService,
        private notificationService: NotificationService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private userAgentService: UserAgentService,
        private userService: UserService
    ) {
        this.isOwner = this.userService.hasOwnerPrivileges();

        this._mapAdapter = this.mapAdapterMediator?.getMapAdapter();
        if (this._mapAdapter) {
            this._geoDataEditor = GeodataEditorFactory.create(this._mapAdapter, this.displayRuleService, this.locationService);
        }
        this.unitSystem = this.userAgentService.unitSystem;

        const appUserRolesSubscription = this.appUserRolesService.appUserRoles$
            .subscribe(roles => this.#appUserRoles = roles);

        this.subscriptions
            .add(appUserRolesSubscription);
    }

    /**
     * Setter for setting the route element to be edited.
     */
    @Input()
    set data(input: RouteElement) {
        this._routeElement = primitiveClone(input) as RouteElement;
        this.form.markAsPristine();

        const anchor = turf.center(input.geometry);

        const routeElementOriginalState: any = {
            elementValue: this._routeElement.elementValue === undefined ? null : this._routeElement.elementValue,
            floorIndex: this._routeElement.floorIndex,
            onewayDirection: this._routeElement.onewayDirection === undefined ? null : this._routeElement.onewayDirection,
            radius: this._routeElement.radius === undefined ? null : this._routeElement.radius,
            // Besides the default value, we also need to set the value to null if the route element type is 0 (Barrier - in every other case the subtype is required)
            subtype: this._routeElement.type === 0 ? null : this._routeElement.subtype === undefined ? 1 : this._routeElement.subtype,
            restrictions: this._routeElement.restrictions ?? [],
            waitTime: this._routeElement.waitTime,
            width: getDoorWidth(this._routeElement, this.unitSystem) || NetworkService.DEFAULT_DOOR_WIDTH,
            geometry: this._routeElement.geometry
        };

        this.setFormValidators(this.routeElement);
        this.originalFormState = Object.freeze(primitiveClone(routeElementOriginalState));
        this.form.patchValue(this.originalFormState);

        if (!this._routeElement.id) {
            this.isDiscardButtonDisabled = true;
            this.form.markAsDirty();
        } else {
            this.isDiscardButtonDisabled = false;
        }

        //Ensure that the map is showing the correct floor.
        this.buildingService.getNearestBuildingAsObservable(anchor.geometry)
            .pipe(
                map(building => building.floors.find(floor => floor.floorIndex === input.floorIndex)),
                tap(floor => this.buildingService.setCurrentFloor(floor))
            ).subscribe();

        this.makeRouteElementDraggable(this._routeElement);
        //Centers the route element on the map between the route element details editor and the floor selector.
        this._mapAdapter.panToGeometry(input.geometry as GeoJSON.Geometry, { left: 396, right: 66, top: 0, bottom: 0 });
        this._mapAdapter.viewState.refresh();
    }

    /**
     * Called after Angular has initialized all data-bound properties of a directive.
     */
    ngOnInit(): void {
        this.mapUIService.show(MapUIComponents.RouteElementDetailsEditor);
        if (this._routeElement.type === RouteElementType.Door) {
            this.floorService.disableFloorSelector(true);
        }

        const routeElementsFormSubscription = this.form.valueChanges
            .subscribe(formState => {
                if (!this._routeElement) {
                    return;
                }

                if (this._routeElement.id) {
                    !isEqual(this.originalFormState, formState)
                        ? this.form.markAsDirty({ onlySelf: true })
                        : this.form.markAsPristine({ onlySelf: true });
                } else {
                    !isEqual(this.originalFormState, formState)
                        ? this.isDiscardButtonDisabled = false
                        : this.isDiscardButtonDisabled = true;
                }
            });

        const widthChanges = this.form.controls['width'].valueChanges
            .subscribe((width) => {
                if (!width || width < 0) return;

                width = UserAgentService.UNIT_SYSTEM === UnitSystem.Imperial ?
                    convertInchesToMeters(width) :
                    convertCentimetersToMeters(width);
                this._editRouteElement.setDoorWidth(width);
            });

        const urlSubscription = this.router.events.subscribe(() => {
            if (this.form.dirty) {
                if (!this.confirmDiscard()) {
                    stayAtCurrentUrl(this.router);
                    return;
                } else {
                    this.close(true);
                }
            }
        });

        this.subscriptions
            .add(widthChanges)
            .add(routeElementsFormSubscription)
            .add(urlSubscription);
    }

    /**
     * Angular OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Set extra form validators depending on route element type.
     *
     * @private
     * @param {RouteElement} Object - With type property.
     * @memberof RouteElementDetailsComponent
     */
    private setFormValidators({ type }: RouteElement): void {
        // Required when type of connector, door or entry point
        if (type === RouteElementType.Connector) {
            this.form.controls['elementValue'].setValidators(Validators.required);
        } else {
            this.form.controls['elementValue'].setValidators([]);
        }
    }

    /**
     * Makes Route Element draggable on the map.
     *
     * @param { RouteElement } routeElement
     */
    private makeRouteElementDraggable(routeElement: RouteElement): void {
        this._editRouteElement?.complete();
        this._editRouteElement = this._geoDataEditor?.editRouteElement(routeElement);
        this._editRouteElement.changes.subscribe((e) => {
            this.form.patchValue({
                geometry: e.geometry
            });
        });
    }

    /**
     * The Route element's geometry point.
     *
     * @readonly
     * @type {number[]}
     */
    public getAnchorPoint(): Position {
        if (this._routeElement.geometry.type === 'LineString') {
            const doorLineString = turf.lineString(this._routeElement.geometry.coordinates);
            const currentLineStringLength = turf.length(doorLineString);
            const lineStringCenterPoint = turf.along(doorLineString, currentLineStringLength / 2);

            return lineStringCenterPoint.geometry.coordinates;
        } else if (this._routeElement.geometry.type === 'Point') {
            return (this.form.get('geometry').value).coordinates;
        } else {
            return [];
        }
    }

    /**
     * Route Element save handler.
     */
    private _saveRouteElementHandler: PartialObserver<void | string | RouteElement> = {
        next: (routeElement: RouteElement) => {
            this._routeElement = routeElement;
            this.form.markAsPristine();
            this.networkService.selectRouteElement(null);
            this.close();
        },
        error: error => this.notificationService.showError(error)
    };

    /**
     * Save route element.
     *
     * @memberof RouteElementDetailsComponent
     */
    public onSave(): void {
        if (!this.form.valid) {
            return;
        }

        const routeElement = mergeObjects(this._routeElement, this.form.value) as RouteElement;

        if (routeElement.id) {
            this.spinner.show();
            this.networkService.updateRouteElement(routeElement)
                .pipe(tap(() => this.notificationService.showSuccess('Route Element updated')),
                    finalize(() => this.spinner.hide()))
                .subscribe(this._saveRouteElementHandler);
        } else {
            this.spinner.show();
            this.networkService.createRouteElement(routeElement)
                .pipe(tap(() => this.notificationService.showSuccess('Route Element created')),
                    finalize(() => this.spinner.hide()))
                .subscribe(this._saveRouteElementHandler);
        }
    }

    /**
     * Closes the route element details editor.
     *
     * @param {boolean} [skipConfirm=false]
     * @returns {boolean}
     * @memberof RouteElementDetailsEditorComponent
     */
    public close(skipConfirm: boolean = false): boolean {
        if (skipConfirm || this.confirmDiscard()) {
            this.floorService.disableFloorSelector(false);
            this._editRouteElement?.complete();
            this.form.reset({}, { emitEvent: false });
            this._routeElement = null;
            this._mapAdapter.viewState.refresh();
            this.closed.emit();
            return true;
        }
        return false;
    }

    /**
     * Resets the form and set the values.
     *
     * @memberof RouteElementDetailsComponent
     */
    public onDiscard(): void {
        this.confirmDiscard() && this._resetForm();
        if (!this.routeElement.id) {
            this.form.markAsDirty();
        }
    }

    /**
     * Prompt the user to confirm before discarding.
     *
     * @private
     * @returns {boolean}
     * @memberof RouteElementDetailsComponent
     */
    private confirmDiscard(): boolean {
        // eslint-disable-next-line no-alert
        return (this._routeElement?.id !== '' && !this.form.dirty) || confirm('Your unsaved changes will be lost! Would you like to continue?');
    }

    /**
     * Resets the Location Details form.
     *
     * @private
     * @memberof RouteElementDetailsEditorComponent
     */
    private _resetForm(): void {
        if (this._routeElement) {
            this.form.reset(this.originalFormState);
            this.form.markAsPristine();
            this._editRouteElement.reset();
        }
    }

    /**
     * Delete route element.
     *
     * @memberof RouteElementDetailsComponent
     */
    public onDelete(): void {
        // eslint-disable-next-line no-alert
        if (confirm('Do you want to delete this Route Element?')) {
            if (!this.routeElement.id) {
                return;
            }

            this.networkService.deleteRouteElement(this.routeElement);
            this.close();
        }
    }

    /**
     * React on keyboard presses.
     *
     * @param {KeyboardEvent} event
     * @memberof RouteElementDetailsComponent
     */
    public handleHotkeys(event: KeyboardEvent): void {
        if (event.key.toLowerCase() === 'escape') {
            this.close();
        }
    }

    /**
     * Duplicates the route element.
     *
     * @param {KeyboardEvent} [event]
     * @memberof RouteElementDetailsComponent
     */
    @HostListener('window:keydown.control.d', ['$event'])
    public onDuplicate(event?: KeyboardEvent): void {
        event?.preventDefault();
        if (this.canRouteElementBeDuplicated && this.confirmDiscard()) {
            this._editRouteElement.complete();
            this._duplicationInProgress = true;
            const duplicate = this.networkService.duplicateRouteElement(this._routeElement);
            this.spinner.show();
            this.networkService.createRouteElement(duplicate)
                .subscribe((newRouteElement) => {
                    this._duplicationInProgress = false;
                    this.data = newRouteElement;
                    this.spinner.hide();
                });
        }
    }
}
