import { BBox } from 'geojson';

import booleanIntersects from '@turf/boolean-intersects';
import { bboxPolygon, feature as toFeature } from '@turf/turf';

import { MapViewModel, MapViewModelFactory } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { Floor } from '../floor.model';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { MapsIndoorsData } from '../../shared/enums/MapsIndoorsData';
import { LineStringViewModel } from '../../../viewmodels/LineStringViewModel/LineStringViewModel';
import { toMultiLineString } from '../../shared/geometry-helper/geojson';
import { SolutionService } from '../../services/solution.service';
import { mergeObjects, removeEmptyValues } from '../../shared/object-helper';

export class FloorOutlineMapViewModelFacory extends MapViewModelFactory<Floor> {
    private highlightDisplayRule;

    constructor(
        private displayRuleService: DisplayRuleService,
        private solutionService: SolutionService
    ) {
        super();

        this.solutionService.solutionConfig$
            .subscribe(solutionConfig => {
                // The removeEmptyValues function makes sure that we won't have null/empty values anywhere to avoid storing unnecessary data.
                this.highlightDisplayRule = removeEmptyValues(mergeObjects(
                    DisplayRuleService.BUILDING_HIGHLIGHT_DISPLAY_RULE,
                    removeEmptyValues(solutionConfig.buildingHighlightDisplayRule)
                ));
            });
    }

    /**
     * Creates MapViewModels for the given Floor.
     *
     * @param {Floor} floor
     * @param {number} sortKey
     * @returns {Promise<MapViewModel[]>}
     * @memberof FloorOutlineMapViewModelFacory
     */
    async create(floor: Floor, sortKey?: number): Promise<MapViewModel[]> {
        const viewModels: MapViewModel[] = [];
        const displayRule = await this.displayRuleService.getDisplayRule();

        const multiLineString = toMultiLineString(floor?.geometry);

        // LineString geometry exists and the DisplayRule's visibility is set to true.
        if (floor?.id && multiLineString && displayRule.visible && this.highlightDisplayRule.polygon.visible) {
            displayRule.clickable = false;
            displayRule.polygon.strokeColor = this.highlightDisplayRule.polygon.strokeColor;
            displayRule.polygon.strokeWidth = this.highlightDisplayRule.polygon.strokeWidth;
            displayRule.polygon.strokeOpacity = this.highlightDisplayRule.polygon.strokeOpacity;
            displayRule.polygon.zoomFrom = this.highlightDisplayRule.polygon.zoomFrom;
            displayRule.polygon.zoomTo = this.highlightDisplayRule.polygon.zoomTo;

            viewModels.push(await LineStringViewModel.create(floor.id, multiLineString, displayRule, sortKey, MapsIndoorsData.Floor));
        }

        return Promise.resolve(viewModels);
    }

    /**
     * Checks if the given location intersects with the given bounds.
     *
     * @param {Floor} floor
     * @param {BBox} bounds
     * @returns {boolean}
     * @memberof LocationMapViewModelFactory
     */
    intersectsWithBounds(floor: Floor, bounds: BBox): boolean {
        const boundsAsPolygon = bboxPolygon(bounds);
        return booleanIntersects(boundsAsPolygon, toFeature(floor.geometry));
    }

    /**
     * Checks if the floorIndex for the given location equals the given floorIndex.
     *
     * @param {Floor} data
     * @param {Floor} floor
     * @returns {boolean}
     * @memberof FloorOutlineMapViewModelFacory
     */
    floorEquals(data: Floor, floor: Floor): boolean {
        return data === floor;
    }
}