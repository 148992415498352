export class ContactInformation {
    webAddress: string = null;
    email: string = null;
    phoneNumber: string = null;

    constructor(contactInformation?: ContactInformation) {
        Object.assign(this, contactInformation);
    }

    static readonly EMPTY: ContactInformation = Object.freeze({
        webAddress: null,
        email: null,
        phoneNumber: null
    });

    /**
     * Creates a ContactInformation object. Returns null if every property is empty.
     *
     * @param {ContactInformation} contactInformation
     * @returns {ContactInformation}
     */
    static createContactInformation(contactInformation?: ContactInformation): ContactInformation {
        if (contactInformation && Object.values(contactInformation).every((value) => value === null)) {
            return null;
        } else {
            return new ContactInformation(contactInformation);
        }
    }

}