import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { ThreeDSettingsComponent } from './3d-settings/settings-3d.component';
import { MainDisplayRuleComponent } from './main-display-rule/main-display-rule.component';
import { MapBehaviorComponent } from './map-behavior/map-behavior.component';
import { SolutionSettingsRoutingModule } from './solution-settings-routing.component';
import { SolutionSettingsComponent } from './solution-settings.component';
import { BuildingHighlightComponent } from './building-highlight/building-highlight.component';

@NgModule({
    declarations: [
        SolutionSettingsComponent,
        MainDisplayRuleComponent,
        BuildingHighlightComponent,
        MapBehaviorComponent,
        ThreeDSettingsComponent,
    ],
    imports: [
        SharedModule,
        SolutionSettingsRoutingModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SolutionSettingModule { }