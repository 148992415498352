import { Component, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { MapUIComponents, MapUIService } from '../../../map-ui-service/map-ui.service';
import { MapToolbar } from '../../map-toolbar.interface';
import { AddPOIToolbarComponent } from '../add-poi/add-poi-toolbar.component';
import { CreateAreaComponent } from '../create-area/create-area.component';
import { RouteNetworkToolsComponent } from '../route-network-tools/route-network-tools.component';
import { MapToolbarService } from '../../map-toolbar.service';


@Component({
    selector: 'default-map-tools',
    templateUrl: './default-map-tools.component.html',
    styleUrls: ['./default-map-tools.component.scss'],
})
export class DefaultMapToolsComponent {
    private readonly activeToolSubject: Subject<Type<MapToolbar>> = new Subject();
    public readonly Tools = {
        AddPOIToolbarComponent,
        CreateAreaComponent,
        RouteNetworkToolsComponent
    };

    constructor(
        private mapUIService: MapUIService,
        private mapToolbarService: MapToolbarService
    ) { }

    /**
     * Sets the selected tool active.
     *
     * @param {Type<MapToolbar>} typeOfTool
     * @memberof DefaultMapToolsComponent
     */
    public setToolActive(typeOfTool: Type<MapToolbar>): void {
        this.mapUIService.show(MapUIComponents.MapsIndoorsTools);
        this.mapToolbarService.show(typeOfTool);
    }
}