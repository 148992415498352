import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';

import { Solution } from '../../../solutions/solution.model';
import { SolutionService } from '../../../services/solution.service';
import { Translation } from '../../interfaces/translation.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoryService } from '../../../categories/category.service';
import { Observable, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { NotificationService } from '../../../services/notification.service';
import { isTextEmpty } from '../../../shared/validators/validators';

const GENERIC_LANGUAGE = 'generic';
@Component({
    selector: 'translations-form',
    templateUrl: './translations-form.component.html',
    styleUrls: ['./translations-form.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TranslationsFormComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => TranslationsFormComponent),
        multi: true,
    }]
})
export class TranslationsFormComponent implements ControlValueAccessor, Validator, OnInit {
    #heritage: Map<string, Translation>;

    @Input() allTranslationsAreRequired: boolean = true;

    /**
     * Set the translations to be inherited.
     */
    @Input() set heritage(translations: Translation[]) {
        this.#heritage = new Map(translations.map(translation => [translation.language, translation]));
        this.updateForm(this.translations);
    }

    public selectedSolution: Solution;
    public translations: Translation[] = [];
    public languages: Set<string>;

    public onChange: (value: Translation[]) => void = () => { };
    public onTouch: () => void = () => { };
    public isDisabled: boolean = false;

    public translationsForm: FormGroup = new FormGroup({});

    constructor(
        private categoryService: CategoryService,
        private formBuilder: FormBuilder,
        private notificationService: NotificationService,
        private solutionService: SolutionService,
        private spinner: NgxSpinnerService
    ) {
        this.solutionService.selectedSolution$.subscribe(solution => this.selectedSolution = solution);
        this.translationsForm = this.formBuilder.group({});
    }

    /**
     * Angular lifecycle hook.
     */
    ngOnInit(): void {
        this.translationsForm.valueChanges.subscribe((formValues) => {
            const translations = this.translations
                .map((translation) => {
                    if (translation.language === GENERIC_LANGUAGE) {
                        return translation;
                    }

                    const { inheritName, inheritDescription } = formValues?.[translation.language] ?? {};

                    const name = inheritName ? null : formValues[translation.language].name;
                    const description = inheritDescription ? null : formValues[translation.language].description;

                    return { ...translation, name, description };
                });

            this.onChange(translations);
            this.translations = translations;
        });
    }

    /**
     * Create translation's form-group.
     *
     * @private
     * @param {Translation[]} translations
     */
    private updateForm(translations: Translation[] = []): void {
        if (!(translations instanceof Array)) {
            return;
        }

        translations.reduce((form, translation) => {
            if (translation.language === GENERIC_LANGUAGE)
                return form;

            // If the language group already exists, update the values
            const languageGroup = form.get(translation.language) as FormGroup;
            if (languageGroup) {
                const { name, description } = translation;
                languageGroup.patchValue({ name, description, inheritName: !name, inheritDescription: !description });
                return form;
            }

            // Create a new language group
            const required = translation.language === this.selectedSolution.defaultLanguage || this.allTranslationsAreRequired ? Validators.required : null;

            type TranslationForm = { name, description, inheritName?, inheritDescription?};
            const group = this.formBuilder.group<TranslationForm>({
                name: [translation.name ?? this.#heritage?.get(translation.language)?.name, [required, isTextEmpty()]],
                description: [translation.description ?? this.#heritage?.get(translation.language)?.description ?? '']
            });

            if (this.#heritage?.has(translation.language)) {
                const inheritName = this.formBuilder.control(!translation.name);
                inheritName.valueChanges.subscribe((value) => {
                    const nameControl = form.get(translation.language).get('name');
                    if (value) {
                        nameControl.setValue(this.#heritage.get(translation.language)?.name);
                        nameControl.disable();
                    } else {
                        nameControl.enable();
                    }
                });

                if (!translation.name) {
                    group.get('name').disable();
                }


                group.addControl('inheritName', inheritName);

                const inheritDescription = this.formBuilder.control(!translation.description);
                inheritDescription.valueChanges.subscribe((value) => {
                    const descriptionControl = form.get(translation.language).get('description');
                    if (value) {
                        descriptionControl.setValue(this.#heritage.get(translation.language)?.description);
                        descriptionControl.disable();
                    } else {
                        descriptionControl.enable();
                    }
                });

                group.addControl('inheritDescription', inheritDescription);

                if (!translation.description) {
                    group.get('description').disable();
                }
            }

            form.addControl(translation.language, group);
            return form;
        }, this.translationsForm);
    }

    /**
     * Prevents the default behavior of the event and stops the event from
     * propagating up the DOM tree.
     *
     * @param {MouseEvent} e
     */
    public preventDefaultAndStopPropagation(e: MouseEvent): void {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Creates an observable that translates the given field.
     *
     * @param {string} field
     * @param {FormControl} languageFromControl
     * @param {string} lng
     * @param {string} language
     * @returns {Observable<unknown>}
     */
    private createTranslateObservable(field: string, languageFromControl: FormControl, lng: string, language: string): Observable<unknown> {
        return this.categoryService.googleTranslate(languageFromControl[field], lng, language).pipe(
            map((translatedValue) => {
                translatedValue.field = field;
                return translatedValue;
            })
        );
    }

    /**
     * Translates the translations based on the provided language.
     *
     * @param {string} language
     */
    public onTranslate(language: string): void {
        const googleTranslateObservables = [];
        this.spinner.show();

        for (const lng of this.languages) {
            if (lng !== language) {
                const languageFromControl = this.translationsForm.controls[language].value;

                googleTranslateObservables.push(this.createTranslateObservable('name', languageFromControl, lng, language));
                googleTranslateObservables.push(this.createTranslateObservable('description', languageFromControl, lng, language));
            }
        }

        forkJoin(googleTranslateObservables)
            .pipe(
                finalize(() => this.spinner.hide())
            )
            .subscribe(lng => {
                lng.forEach((value: any) => {
                    const languageFormControl = this.translationsForm.controls[value.language];
                    languageFormControl.setValue({
                        ...languageFormControl.value,
                        [value.field]: value.translations[0].translatedText
                    });
                });
                this.notificationService.showSuccess('Successfully translated languages');
            }, () => {
                this.notificationService.showError('Could not translate. Please try again.');
            });
    }

    /**
     * Writes a new value to the element.
     *
     * This method is called by the forms API to write to the view when programmatic
     * changes from model to view are requested.
     *
     * @param {Translation[]} translations - The new value for the element.
     */
    writeValue(translations: Translation[]): void {
        if (Array.isArray(translations)) {
            this.languages = new Set(translations.map(translation => translation.language));
            this.languages.delete(GENERIC_LANGUAGE);
            this.updateForm(translations);
            this.translations = translations;
        }
    }

    /**
     * Registers a callback function that is called when the control's value
     * changes in the UI.
     *
     * This method is called by the forms API on initialization to update the form
     * model when values propagate from the view to the model.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnChange(fn: (value: Translation[]) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization
     * to update the form model on blur.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Function that is called by the forms API when the control status changes to
     * or from 'DISABLED'. Depending on the status, it enables or disables the
     * appropriate DOM element.
     *
     * @param {boolean} isDisabled - The disabled status to set on the element.
     */
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
    /**
     * Method that performs synchronous validation against the provided control.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return this.translationsForm.invalid ? { invalid: true } : null;
    }
}