class ImageCache {
    #cache: Map<string, Promise<HTMLImageElement>> = new Map();

    /**
     * Checks if an image has already been cached with the given id.
     *
     * @param {string} id
     * @returns {boolean}
     */
    has(id: string): boolean {
        return this.#cache.has(id);
    }

    /**
     * Caches a new image.
     *
     * @param {string} id
     * @param {string} src
     */
    set(id: string, src?: string): void {
        const url = src ?? id;

        const imgPromise = fetch(url)
            .then(fetchErrorHandler)
            .then(res => res.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(objUrl => getImageFromUrl(objUrl))
            .catch(() => getImageFromUrl('data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='));
        this.#cache.set(id, imgPromise);
    }

    /**
     * Get image from the cache.
     *
     * @param {string} src
     * @returns {Promise<HTMLImageElement>}
     * @memberof ImageCache
     */
    get(src: string): Promise<HTMLImageElement> {
        if (src) {
            if (!this.#cache.has(src)) {
                this.set(src);
            }
            return this.#cache.get(src);
        }

        return Promise.resolve(null);
    }

    /**
     * Clear the image cache.
     *
     * @memberof ImageCache
     */
    clear(): void {
        // Revoke object urls
        this.#cache.forEach(async cachedImagePromise => {
            const img = await cachedImagePromise;
            URL.revokeObjectURL(img.src);
        });

        this.#cache.clear();
    }
}

/**
 * Will throw an Error if the response status in not 200 OK.
 *
 * @param {Response} response
 * @returns {Response}
 */
function fetchErrorHandler(response: Response): Response {
    if (!response.ok) {
        throw Error(response.statusText);
    }

    return response;
}

/**
 * Get Image from url.
 *
 * @param {string} url
 * @returns {Promise<HTMLImageElement>}
 */
function getImageFromUrl(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', () => resolve(null));
        image.src = url;
    });
}

const imageCache = new ImageCache();
export default imageCache;