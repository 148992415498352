import * as turf from '@turf/turf';

import { Observable, Subject, Subscription } from 'rxjs';

import { BBox } from 'geojson';
import { BaseMapAdapter } from '../../MapAdapter/BaseMapAdapter';
import { Floor } from '../../buildings/floor.model';
import { Location } from '../../locations/location.model';
import { MapViewModel, MapViewModelFactory } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { RouteElement } from '../../map/route-element-details/route-element.model';
import { isNullOrUndefined } from '../../../utilities/Object';
import { Venue } from '../../venues/venue.model';
import { bufferBounds } from '../../shared/geometry-helper';
import { debounce } from 'throttle-debounce';

/**
 * MapViewState takes care of providing what elements to show on the map.
 */
export class MapViewState {
    private mapViewStateSubject: Subject<MapViewModel[]> = new Subject();
    private currentFloor: Floor;
    private dataSources: Map<Observable<unknown>, [unknown | unknown[], MapViewModelFactory<unknown>]>;
    private subscriptions: Map<Observable<unknown>, Subscription>;

    /**
     * Create an instance of MapViewState.
     *
     * @param {BaseMapAdapter} mapView - An instance of a MapView.
     */
    constructor(private mapView: BaseMapAdapter) {
        this.subscriptions = new Map<Observable<unknown>, Subscription>();
        this.dataSources = new Map<Observable<unknown>, [unknown | unknown[], MapViewModelFactory<unknown>]>();
        this.mapView.bounds$.subscribe(this.refresh);
    }

    /**
     * For registering an observable for observing the current floor.
     *
     * @param {Observable<Floor>} currentFloorObservable
     * @memberof MapViewState
     */
    public registerFloorObservable(currentFloorObservable: Observable<Floor>): void {
        this.subscriptions.set(currentFloorObservable, currentFloorObservable.subscribe((currentFloor: Floor) => {
            this.currentFloor = currentFloor;
            this.refresh();
        }));
    }
    /**
     * Add a data source to the MapViewState.
     *
     * @template T
     * @param {(Observable<T|T[]>)} source
     * @memberof MapViewState
     */
    public addDataSource<T>(source: Observable<T | T[]>, mapViewModelFactory: MapViewModelFactory<T>): void {
        const subscription = source
            .subscribe(data => {
                this.dataSources.set(source, [data, mapViewModelFactory]);
                this.refresh();
            });
        this.subscriptions.set(source, subscription);
    }

    /**
     * Remove a data source from the MapViewState.
     *
     * @template T
     * @param {(Observable<T>)} source
     * @memberof MapViewState
     */
    public removeDataSource<T>(source: Observable<T>): void {
        if (this.subscriptions.has(source)) {
            this.subscriptions.get(source).unsubscribe();
            this.subscriptions.delete(source);
            this.dataSources.delete(source);
            this.refresh();
        }
    }

    public refresh = debounce(50, async (): Promise<void> => {
        const mapBounds: BBox = this.mapView.getBounds();
        // Early exit if the map is still loading and we do not have map bounds yet.
        if (mapBounds === null) return;

        // Buffered map bounds by 20 meters.
        const extendedMapBounds = bufferBounds(mapBounds, 20);

        if (this.currentFloor !== null && mapBounds?.length > 0) {
            const promises = Array.from(this.dataSources.values())
                .flatMap(([data, factory]) => {
                    // In case data is null or undefined (when still loading map), we do not want it to crash.
                    if (isNullOrUndefined(data)) {
                        return Promise.resolve([]);
                    }

                    const arrayOfData = data instanceof Array ? data : [data];
                    return arrayOfData
                        .sort(sortByArea)
                        .filter(feature => factory.floorEquals(feature, this.currentFloor)
                            && factory.intersectsWithBounds(feature, extendedMapBounds))
                        .map(factory.create, factory);
                });

            const viewModels = (await Promise.all(promises)).flat();

            this.mapViewStateSubject.next(viewModels);
        }
    });

    /**
     * Clean up resources associated with the instance.
     */
    public deallocate(): void {
        this.subscriptions.forEach((value: Subscription) => value.unsubscribe());
        this.subscriptions.clear();
        this.dataSources.clear();
        this.mapView.off('idle', this.refresh);
        this.mapViewStateSubject.complete();
    }

    /**
     * Returns an Observable for subscribing to ViewState changes.
     *
     * @returns {Observable<GeoJSON.Feature[]>}
     * @memberof MapViewState
     */
    public asObservable(): Observable<GeoJSON.Feature[]> {
        return this.mapViewStateSubject.asObservable();
    }
}

/**
 * Returns true if a feature is completely or partly within bounds.
 *
 * @param {Location|RouteElement} feature - Location or RouteElement.
 * @param {BBox} bounds - The bounds to investigate against.
 * @returns {boolean}
 */
export function isWithinBounds(feature: Location | RouteElement, bounds: BBox): boolean {
    const geoJSONFeature = {
        type: 'Feature',
        properties: {},
        geometry: feature.geometry
    };
    const boundsPolygon = turf.bboxPolygon(bounds);
    return (turf as any).booleanIntersects(geoJSONFeature, boundsPolygon); // Assigned "any" type because turf does not define it in the interface, but the function exists.
}

/**
 * Returns true if a feature is on the current floor.
 *
 * @param {any} feature - Location or RouteElement.
 * @param {Floor} floor - The current floor.
 * @returns {boolean}
 */
export function isOnCurrentFloor(feature: Location | RouteElement, floor: Floor): boolean {
    if ((feature as Location).pathData) {
        return floor.floorIndex === (feature as Location).pathData.floor;
    } else {
        return floor.floorIndex === (feature as RouteElement).floorIndex;
    }
}

/**
 * Compare function for sorting MapObjects by area.
 *
 * @template T
 * @param {T} a
 * @param {T} b
 * @returns {number}
 */
function sortByArea<T extends object>(a: T, b: T): number {
    const areaOfA = Reflect.get(a, 'area') as number ?? 0;
    const areaOfB = Reflect.get(b, 'area') as number ?? 0;
    return areaOfB - areaOfA;
}


/**
 * Get folder name for browser specific map style.
 *
 * @private
 * @param {Venue} venue
 * @returns {string}
 * @memberof MapService
 */
export function getTileStyleFolderName(venue: Venue): string {
    const currentTileStyle = localStorage.getItem('styleSettings');
    if (currentTileStyle) {
        const styleSettings = JSON.parse(currentTileStyle);
        const venueStyleSettings = styleSettings.find(setting => setting.venueId === venue.id);
        if (venueStyleSettings) {
            return venueStyleSettings.style;
        }
    }

    return venue.styles ? venue.styles[0]?.folder : '';
}
