import { ConnectorType, DoorType, EntryPointTypes, RouteElement, RouteElementType, RouteNetwork } from '../map/route-element-details/route-element.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject, of, throwError, BehaviorSubject, Subject, forkJoin } from 'rxjs';

import { Icons, UnitSystem } from '../shared/enums';
import { Injectable } from '@angular/core';
import { Solution } from '../solutions/solution.model';
import { SolutionService } from '../services/solution.service';
import { Venue } from '../venues/venue.model';
import { VenueService } from '../venues/venue.service';
import { environment } from '../../environments/environment';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../services/notification.service';
import { UserService } from '../services/user.service';
import { UserAgentService } from '../services/user-agent.service';
import { GraphData } from '../map/graph-data.model';
import { primitiveClone } from '../shared/object-helper';

export interface ElementSubType {
    key: number;
    value: string;
}

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    private api = environment.APIEndpoint;
    private readonly ICON_SIZE = 24;
    private selectedSolution: Solution;
    private selectedVenue: Venue;
    private routeElementsSubject: ReplaySubject<RouteElement[]> = new ReplaySubject(1);
    private selectedRouteElementSubject = new BehaviorSubject<RouteElement>(null);
    private routeNetworkChangedSubject = new Subject<void>();
    private routeElements: RouteElement[];
    private currentGraphId: string;
    private multipleDoorsBooleanValue = new BehaviorSubject<boolean>(false);

    public routeNetworkChanged: boolean = false;

    constructor(
        private solutionService: SolutionService,
        private venueService: VenueService,
        private http: HttpClient,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        private user: UserService,
    ) {
        this.solutionService.selectedSolution$.subscribe(solution => {
            this.selectedSolution = solution;
            this.currentGraphId = '';
        });

        this.venueService.selectedVenue$.subscribe(venue => this.selectedVenue = venue);
    }

    /**
     * Boolean setter for creating multiple doors.
     *
     * @param {boolean} value
     */
    public setMultipleDoorsCreation(value: boolean): void {
        this.multipleDoorsBooleanValue.next(value);
    }

    /**
     * Getter for creating multiple doors.
     *
     * @returns {Observable<boolean>}
     */
    public getMultipleDoorsCreation(): Observable<boolean> {
        return this.multipleDoorsBooleanValue.asObservable();
    }


    /**
     * Observable for the list of route elements.
     *
     * @readonly
     * @memberof NetworkService
     * @returns {Observable<RouteElement[]>}
     */
    get routeElements$(): Observable<RouteElement[]> {
        return this.venueService.selectedVenue$.pipe(
            switchMap(venue => this.getRouteLayer(venue.graphId)));
    }

    /**
     * Observable for the selcted route element.
     *
     * @readonly
     * @memberof NetworkService
     * @returns {Observable<RouteElement>}
     */
    get selectedRouteElement$(): Observable<RouteElement> {
        return this.selectedRouteElementSubject.asObservable();
    }

    /**
     * Get route element by id.
     *
     * @param {string} id
     * @returns {RouteElement}
     * @memberof NetworkService
     */
    public getRouteElement(id: string): RouteElement {
        return this.routeElements.find(routeElement => routeElement.id === id);
    }

    /**
     * Set the current route element.
     *
     * @param {RouteElement} routeElement
     * @memberof NetworkService
     */
    public selectRouteElement(routeElement: RouteElement): void {
        this.selectedRouteElementSubject.next(routeElement);
    }

    /**
     * Get subtypes of route element type.
     *
     * @param {number} elementType
     * @returns {ElementSubType[]}
     * @memberof NetworkMapService
     */
    public getSubtypes(elementType: number): ElementSubType[] {
        const subtypes: ElementSubType[] = [];

        if (elementType === RouteElementType.Connector) {
            for (const i in ConnectorType) {
                if (typeof ConnectorType[i] === 'number') {
                    subtypes.push({ key: +ConnectorType[i], value: i });
                }
            }
        } else if (elementType === RouteElementType.Door) {
            for (const i in DoorType) {
                if (typeof DoorType[i] === 'number') {
                    subtypes.push({ key: +DoorType[i], value: i });
                }
            }
        } else if (elementType === RouteElementType.EntryPoint) {
            subtypes.push(...[
                { key: EntryPointTypes.AnyEntry, value: 'Any Entry Point' },
                { key: 0, value: 'Specific Entry Points' }
            ]);
        }

        return subtypes;
    }

    /**
     * Get route elements.
     *
     * @param {string} graphId
     * @returns {Observable<any>}
     * @memberof NetworkService
     */
    getRouteLayer(graphId: string): Observable<RouteElement[]> {
        if (this.selectedVenue?.hasGraph === false) {
            return of(undefined);
        } else if (this.currentGraphId !== graphId) {
            this.currentGraphId = graphId;
            this.http.get<RouteElement[]>(`${this.api}${this.selectedSolution.id}/api/routelayer?graphId=${graphId}`)
                .subscribe(routeElements => {
                    this.routeElementsSubject.next(routeElements), error => this.routeElementsSubject.error(error);
                    this.routeElements = routeElements;
                });
        }

        return this.routeElementsSubject.asObservable();
    }

    /**
     * Get isolated route layer items.
     *
     * @param {string} graphId
     * @returns {Observable<any>}
     */
    getIsolatedRouteLayerItems(graphId: string): Observable<any> {
        return this.http.get(`${this.api}${this.selectedSolution.id}/api/routelayer?graphId=${graphId}&onlyOrphanedElements=true`);
    }

    /**
     * Save a route layer item.
     *
     * @param {RouteElement} item
     * @returns {Observable<string>} The ID of the generated route layer item.
     */
    createRouteLayerItem(item: RouteElement): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<string>(`${this.api}${this.selectedSolution.id}/api/routelayer/`, item, { headers: headers });
    }

    /**
     * Update a route layer item.
     *
     * @param  {RouteElement} item
     * @returns {Observable<any>}
     */
    updateRouteLayerItem(item: RouteElement): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(`${this.api}${this.selectedSolution.id}/api/routelayer/`, item, { headers: headers });
    }

    /**
     * Delete a route element given its ID.
     *
     * @param {string} id
     * @returns {Observable<any>}
     * @memberof NetworkService
     */
    public deleteRouteLayerItem(id: string): Observable<any> {
        return this.http.delete(`${this.api}${this.selectedSolution.id}/api/routelayer/${id}`);
    }

    /**
     * Get route elements updated observable.
     * Used for triggering the "network graph updated" indicator in the toolbar.
     *
     * @returns {Observable<void>}
     * @memberof NetworkService
     */
    public get routeElementsUpdates$(): Observable<void> {
        return this.routeNetworkChangedSubject.asObservable();
    }

    /**
     * Notifies the listeners for the routeNetworkChangedSubject about a change.
     */
    public updateRouteElements(): void {
        this.routeNetworkChangedSubject.next();
        this.routeNetworkChanged = true;
    }

    /**
     * Loads a graph with the given ID at the given Floor index.
     *
     * @param {string} graphId
     * @param {number} floorIndex
     * @returns {Observable<any>}
     * @memberof NetworkService
     */
    public loadGraph(graphId: string, floorIndex: number): Observable<RouteNetwork> {
        if (this.selectedVenue?.hasGraph === false) {
            return of(undefined);
        }

        return this.http.get<RouteNetwork>(`${this.api}${this.selectedSolution.id}/api/routelayer/geojson/${graphId}?floor=${floorIndex.toString()}`);
    }

    /**
     * Loads a graph with the given ID at the given Floor index.
     *
     * @param {string} graphId
     * @param {number} floorIndex
     * @returns {Observable<any>}
     * @memberof NetworkService
     */
    public loadRouteNetwork(graphId: string, floorIndex: number): Observable<GeoJSON.Feature[]> {
        if (this.selectedVenue?.hasGraph === false) {
            return of(undefined);
        }

        return this.http.get<RouteNetwork>(`${this.api}${this.selectedSolution.id}/api/routelayer/geojson/${graphId}?floor=${floorIndex.toString()}`)
            .pipe(
                filter(network => network?.features?.length > 0),
                map(network => {
                    const edges = network.features.find(feature => feature.id === 'edges') as GeoJSON.Feature<GeoJSON.MultiLineString>;
                    const lockedEdges = network.features.find(feature => feature.id === 'lockedEdges');
                    const unreachableEdgeIdList = network.features.find(feature => feature.id === 'unreachableEdgeIdList');
                    const modifiedEdgeIdList = network.features.find(feature => feature.id === 'modifiedEdgeIdList');
                    const unreachableNodes = network.features.find(featue => featue.id === 'unreachablenodes') as GeoJSON.Feature<GeoJSON.MultiPoint>;

                    const lineStrings = edges.geometry.coordinates.map((coordinates, index): GeoJSON.Feature<GeoJSON.LineString> => {
                        const edgeIndex = edges.properties.edgeIndexes[index];

                        return {
                            id: edgeIndex,
                            type: 'Feature',
                            geometry: { type: 'LineString', coordinates },
                            properties: {
                                edgeIndex,
                                floorIndex,
                                locked: lockedEdges.properties.edgeIndexes.indexOf(edgeIndex) > -1,
                                unreachable: unreachableEdgeIdList.properties.edgeIndexes.indexOf(edgeIndex) > -1,
                                modified: modifiedEdgeIdList.properties.edgeIndexes.indexOf(edgeIndex) > -1
                            }
                        };
                    });

                    const points = unreachableNodes.geometry.coordinates.map((coordinates, index): GeoJSON.Feature<GeoJSON.Point> => {
                        return {
                            id: index,
                            type: 'Feature',
                            geometry: { type: 'Point', coordinates },
                            properties: {
                                floorIndex,
                                unreachable: true
                            }
                        };
                    });


                    return [...lineStrings, ...points];
                }));
    }

    /**
     * Regenerate all graph data.
     *
     * @returns {Observable<any>}
     */
    public regenerateGraphData(graphId: string): Observable<any> {
        return this.http.delete(`${this.api}${this.selectedSolution.id}/api/graphs/graph?graphId=${graphId}`)
            .pipe(
                catchError(error => {
                    return throwError(error);
                })
            );
    }

    /**
     * Get solution graphs.
     *
     * @returns {Observable<any>}
     */
    getSolutionGraphs(): Observable<any> {
        return this.http.get(`${this.api}${this.selectedSolution.id}/api/graphs`);
    }

    /**
     * Retrieves the solution graphs.
     *
     * @returns {Observable<string[]>} - An observable that emits the solution graphs.
     */
    getSolutionGraphsIds(): Observable<string[]> {
        const url = `${this.api}${this.selectedSolution.id}/api/graphs?graphIdOnly=true`;
        return this.http.get(url) as Observable<string[]>;
    }

    /**
     * Gets graph by venue id.
     *
     * @param {Venue} venueId
     * @returns {Subject<GraphData>}
     */
    public getGraphByVenueId(venueId: Venue): Observable<GraphData> {
        const solutionGraphs = this.getSolutionGraphs();

        return solutionGraphs.pipe(
            map(graphs => graphs.find(graph => graph.graphId === venueId.graphId)));
    }

    /**
     * Sets the icon of a specific route layer item.
     *
     * @param {*} routeLayerItem
     * @memberof NetworkAccessComponent
     */
    public setRouteLayerIcon(routeLayerItem: any): void {
        if (routeLayerItem.type === RouteElementType.Door) {
            this.setDoorIcon(routeLayerItem);
        } else if (routeLayerItem.type === RouteElementType.EntryPoint) {
            this.setEntryPointIcon(routeLayerItem);
        } else if (routeLayerItem.type === RouteElementType.Connector) {
            this.setConnectorIcon(routeLayerItem);
        } else if (routeLayerItem.type === RouteElementType.Barrier) {
            routeLayerItem.icon = this.formatIcon(Icons.RouteModifier, this.ICON_SIZE);
        }
    }

    /**
     * Sets door-icons.
     *
     * @param {*} routeLayerItem
     * @memberof NetworkAccessComponent
     */
    private setDoorIcon(routeLayerItem: any): void {
        const restrictions: string[] = routeLayerItem.restrictions ? routeLayerItem.restrictions : [];

        switch (routeLayerItem.subtype) {
            case DoorType.ElevatorDoor:
                if (restrictions.indexOf('locked') !== -1) { // Locked for all
                    routeLayerItem.icon = this.formatIcon(Icons.ElevatorDoorLocked, this.ICON_SIZE);
                } else if (restrictions.length > 0) { // Locked but open for some
                    routeLayerItem.icon = this.formatIcon(Icons.ElevatorDoorModified, this.ICON_SIZE);
                } else { // Open for all
                    routeLayerItem.icon = this.formatIcon(Icons.ElevatorDoor, this.ICON_SIZE);
                }
                break;
            case DoorType.ExternalDoor:
                if (restrictions.indexOf('locked') !== -1) { // Locked for all
                    routeLayerItem.icon = this.formatIcon(Icons.ExternalDoorClosed, this.ICON_SIZE);
                } else if (restrictions.length > 0) { // Locked but open for some
                    routeLayerItem.icon = this.formatIcon(Icons.ExternalDoorRestricted, this.ICON_SIZE);
                } else { // Open for all
                    routeLayerItem.icon = this.formatIcon(Icons.ExternalDoor, this.ICON_SIZE);
                }
                break;
            case DoorType.Hatchway:
                if (restrictions.indexOf('locked') !== -1) { // Locked for all
                    routeLayerItem.icon = this.formatIcon(Icons.HatchwayLocked, this.ICON_SIZE);
                } else if (restrictions.length > 0) { // Locked but open for some
                    routeLayerItem.icon = this.formatIcon(Icons.HatchwayModified, this.ICON_SIZE);
                } else { // Open for all
                    routeLayerItem.icon = this.formatIcon(Icons.Hatchway, this.ICON_SIZE);
                }
                break;
            default:
                if (restrictions.indexOf('locked') !== -1) { // Locked for all
                    routeLayerItem.icon = this.formatIcon(Icons.DoorLocked, this.ICON_SIZE);
                } else if (restrictions.length > 0) { // Locked but open for some
                    routeLayerItem.icon = this.formatIcon(Icons.DoorModified, this.ICON_SIZE);
                } else { // Open for all
                    routeLayerItem.icon = this.formatIcon(Icons.Door, this.ICON_SIZE);
                }
                break;
        }
    }

    /**
     * Sets parking-icons.
     *
     * @param {*} routeLayerItem
     * @memberof NetworkAccessComponent
     */
    private setEntryPointIcon(routeLayerItem: any): void {
        switch (routeLayerItem.subtype) {
            case EntryPointTypes.WalkingEntry:
                routeLayerItem.icon = this.formatIcon(Icons.WalkingEntry, this.ICON_SIZE);
                break;
            case EntryPointTypes.DrivingEntry:
                routeLayerItem.icon = this.formatIcon(Icons.Parking, this.ICON_SIZE);
                break;
            case EntryPointTypes.BicyclingEntry:
                routeLayerItem.icon = this.formatIcon(Icons.BikeParking, this.ICON_SIZE);
                break;
            case EntryPointTypes.TransitEntry:
                routeLayerItem.icon = this.formatIcon(Icons.TransitEntry, this.ICON_SIZE);
                break;
            default:
                routeLayerItem.icon = this.formatIcon(Icons.AnyEntry, this.ICON_SIZE);
                break;
        }
    }

    /**
     * Sets connector-icons.
     *
     * @param {*} routeLayerItem
     * @memberof NetworkAccessComponent
     */
    private setConnectorIcon(routeLayerItem: any): void {
        switch (routeLayerItem.subtype) {
            case ConnectorType.Stairs:
                routeLayerItem.icon = this.formatIcon(Icons.Stairs, this.ICON_SIZE);
                break;
            case ConnectorType.Elevator:
                routeLayerItem.icon = this.formatIcon(Icons.Elevator, this.ICON_SIZE);
                break;
            case ConnectorType.Escalator:
                routeLayerItem.icon = this.formatIcon(Icons.Escalator, this.ICON_SIZE);
                break;
            case ConnectorType.Ramp:
                routeLayerItem.icon = this.formatIcon(Icons.Ramp, this.ICON_SIZE);
                break;
            case ConnectorType.WheelchairRamp:
                routeLayerItem.icon = this.formatIcon(Icons.WheelchairRamp, this.ICON_SIZE);
                break;
            case ConnectorType.WheelchairLift:
                routeLayerItem.icon = this.formatIcon(Icons.WheelchairLift, this.ICON_SIZE);
                break;
            case ConnectorType.Ladder:
                routeLayerItem.icon = this.formatIcon(Icons.Ladder, this.ICON_SIZE);
                break;
            default:
                break;
        }
    }

    /**
     * Formats an icon to be shown on the map.
     *
     * @param {string} iconUrl
     * @param {number} size
     * @returns {*}
     * @memberof NetworkService
     */
    public formatIcon(iconUrl: string, size: number): any {
        return {
            url: iconUrl,
            anchor: new google.maps.Point((size / 2), (size / 2)),
            scaledSize: { width: size, height: size }
        };
    }

    /**
     * Creates a route element with the incoming parameters.
     *
     * @param {number} floorIndex
     * @param {GeoJSON.Point | GeoJSON.LineString | GeoJSON.Polygon} geometry
     * @param {RouteElementType} type
     * @returns {RouteElement}
     * @memberof NetworkService
     */
    public createNewRouteElement(floorIndex: number, geometry: GeoJSON.Point | GeoJSON.LineString | GeoJSON.Polygon, type: RouteElementType): RouteElement {
        return {
            solutionId: this.selectedSolution?.id,
            graphId: this.selectedVenue?.graphId,
            venueId: this.selectedVenue?.name,
            radius: this.getDefaultRadiusInMetersBasedOnType(type),
            geometry,
            type: type,
            floorIndex,
            restrictions: [],
            waitTime: 0,
            subtype: this.getDefaultSubtypeBasedOnType(type),
            lastModifiedBy: this.user.getCurrentUser().userName
        };
    }

    /**
     * Returns the default radius value in meters based on the type.
     *
     * @param {RouteElementType} type
     * @returns {number}
     * @memberof NetworkService
     */
    private getDefaultRadiusInMetersBasedOnType(type: RouteElementType): number {
        switch (type) {
            case RouteElementType.EntryPoint:
                return 10;
            case RouteElementType.Connector:
            case RouteElementType.Barrier:
                return .75;
            default:
                return null;
        }
    }

    /**
     * Returns the default subtype based on the type.
     *
     * @param {RouteElementType} type
     * @returns {ConnectorType | DoorType | EntryPointTypes}
     * @memberof NetworkService
     */
    private getDefaultSubtypeBasedOnType(type: RouteElementType): ConnectorType | DoorType | EntryPointTypes {
        switch (type) {
            case RouteElementType.Connector:
                return ConnectorType.Stairs;
            case RouteElementType.Door:
                return DoorType.Door;
            case RouteElementType.EntryPoint:
                return EntryPointTypes.AnyEntry;
        }
    }

    /**
     * Create new route elements.
     *
     * @param {RouteElement} routeElements
     */
    public saveRouteElements(routeElements: RouteElement[]): any {
        this.spinner.show();

        let savingSuccessful: boolean = true;
        let errorMessage: string;
        const observables = routeElements.map(routeElement =>
            this.createRouteLayerItem(routeElement)
                .pipe(
                    tap((id) => {
                        const newRouteElement: RouteElement = routeElement;
                        newRouteElement.id = id;
                        this.routeElements.push(newRouteElement);
                        this.routeElementsSubject.next(this.routeElements);
                        this.routeNetworkChangedSubject.next();
                    }),
                    catchError((error) => {
                        errorMessage = error;
                        savingSuccessful = false;
                        return of(error);
                    })
                )
        );

        // When all observables are executed we either show one successful/error message.
        forkJoin(observables)
            .pipe(
                finalize(() => {
                    if (savingSuccessful) {
                        this.notificationService.showSuccess('Route elements created!');
                    } else {
                        this.notificationService.showError(errorMessage);
                    }
                    this.spinner.hide();
                })
            )
            .subscribe(() => { });
    }

    /**
     * Creates a new route element.
     *
     * @param {RouteElement} routeElement
     * @returns {Observable<RouteElement>}
     */
    public createRouteElement(routeElement: RouteElement): Observable<RouteElement> {
        return this.createRouteLayerItem(routeElement)
            .pipe(
                map((id) => {
                    const newRouteElement: RouteElement = routeElement;
                    newRouteElement.id = id;
                    const currentDate = new Date();
                    newRouteElement.lastModified = currentDate.toISOString();
                    this.routeElements.push(newRouteElement);
                    this.routeElementsSubject.next(this.routeElements);
                    this.routeNetworkChangedSubject.next();
                    return newRouteElement;
                })
            );
    }

    /**
     * Updates a route element.
     *
     * @param {RouteElement} routeElement
     * @returns {Observable<void>}
     */
    public updateRouteElement(routeElement: RouteElement): Observable<void> {
        return this.updateRouteLayerItem(routeElement)
            .pipe(
                tap(() => {
                    const routeElementIndex = this.routeElements.findIndex((element => element.id === routeElement.id));
                    this.routeElements[routeElementIndex] = routeElement;
                    this.routeElementsSubject.next(this.routeElements);
                    this.routeNetworkChangedSubject.next();
                }));
    }

    /**
     * Deletes route element.
     *
     * @param {RouteElement} routeElement
     * @memberof NetworkService
     */
    public deleteRouteElement(routeElement: RouteElement): void {
        this.spinner.show();

        this.deleteRouteLayerItem(routeElement.id)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(
                () => {
                    this.routeElements = this.routeElements.filter(element => element.id !== routeElement.id);
                    this.selectRouteElement(null);
                    this.routeElementsSubject.next(this.routeElements);
                    this.notificationService.showSuccess('Deleted successfully');
                    this.routeNetworkChangedSubject.next();
                },
                (err) => this.notificationService.showError(err)
            );
    }

    /**
     * Returns a copy of the incoming route element without an id.
     *
     * @param {RouteElement} routeElement
     * @returns {RouteElement}
     */
    public duplicateRouteElement(routeElement: RouteElement): RouteElement {
        let routeElementCopy: RouteElement;

        if (routeElement) {
            routeElementCopy = primitiveClone(routeElement);
            routeElementCopy.id = null;
            routeElementCopy.lastModified = null;
            routeElementCopy.createdAt = null;
        }

        return routeElementCopy;
    }

    /**
     * Returns the default door width depending on the current unit system.
     *
     * @returns {number}
     */
    public static get DEFAULT_DOOR_WIDTH(): number {
        const defaultDoorWidth = {
            [UnitSystem.Imperial]: 36, // inches
            [UnitSystem.Metric]: 90    // cm
        };

        return defaultDoorWidth[UserAgentService.UNIT_SYSTEM];
    }
}

