import { Component } from '@angular/core';
import { Solution } from '../solutions/solution.model';
import { SolutionService } from '../services/solution.service';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'solution-settings',
    templateUrl: './solution-settings.component.html',
    styleUrls: ['./solution-settings.component.scss']
})
export class SolutionSettingsComponent {

    public isOccupantModule: boolean = false;

    constructor(
        private solutionService: SolutionService,
    ) {
        this.solutionService.getCurrentSolution()
            .pipe(
                tap((solution) => this.isOccupantModule = Solution.hasModule(solution, 'occupants'))
            ).subscribe();
    }
}