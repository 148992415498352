<section class="aliases" [formGroup]="occupantAliasesForm">
    <div class="aliases__row aliases__row--align-end">
        <label class="mi-label">
            Search aliases
            <info-tooltip class="tooltip" text="Use the input-field to add new aliases."></info-tooltip>
            <input #aliasInput class="mi-input mi-input--medium" formControlName="aliasInput" type="text"
                placeholder="Search aliases (enter to add)" />
        </label>
        <button (click)="addAlias()" class="mi-button mi-button--base mi-button--medium"
            [disabled]="!occupantAliasesForm.valid || aliasInput.value.length === 0">
            Add
        </button>
    </div>
    <small role="alert" class="mi-form__error" *ngIf="occupantAliasesForm.get('aliasInput').hasError('valueAlreadyExists')">
        Entered alias is already added
    </small>

    <div class="aliases__column">
        <p class="aliases-sub-heading">Occupant aliases:</p>
        <div class="chips-list" aria-label="Alias">
            <mat-basic-chip *ngFor="let alias of aliases; let index = index" (removed)="removeAlias(index)">
                {{alias}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-basic-chip>
        </div>
    </div>

    <div class="aliases__column">
        <div>
            <p class="aliases__sub-heading">
                Occupant template and categories aliases:
                <info-tooltip class="tooltip" text="Aliases inherited from  the Occupant Template and Occupant Category cannot be removed."></info-tooltip>
            </p>
            <small role="alert" class="mi-form__warning">
                Some of these aliases might be overridden when running the Occupant assignment algorithm.
            </small>
        </div>

        <div class="chips-list" aria-label="Alias">
            <mat-basic-chip *ngFor="let nonDeletableAlias of nonDeletableAliases; let index = index" disabled>
                {{nonDeletableAlias}}
            </mat-basic-chip>
            <mat-basic-chip *ngFor="let futureAlias of futureAliases; let index = index" disabled class="mat-basic-chip--dark">
                {{futureAlias}}
            </mat-basic-chip>
        </div>
    </div>
</section>