<div class="header">
    <header>Occupant Assignment Algorithm (OAA) </header>
    <hr />
    <div class="header__description">
        <p>Running the Occupant Assignment Algorithm will make changes to your Locations:</p>

        <ul class="header__description-list">
            <li>
                Merge Aliases on the individual Occupant with those from the Occupant Template and Occupant Categories.
            </li>
            <li>
                The settings on the Occupant that is set to inherit from its Occupant Template (the locks) will get
                updated to
                the value on the Occupant Template.
            </li>
            <li>
                It will add a couple of necessary Custom Properties to the Occupant, incl. the SIC and NAICS industry
                codes.
            </li>
            <li>
                Create any missing Location Types based on the Occupant Categories, and assign that Location Type to the
                Occupant based on the selected Occupant Template.
            </li>
            <li>
                Name and Description on Occupant is added or updated.
            </li>
        </ul>

        <p>
            Run the OAA when you have made changes to the Occupant Templates to make sure they are reflected in the map.
            <br>
            <b>Tip: Run it after editing chunks of Occupants or Occupant Templates, and not after each individual
                one.</b>
        </p>
        
        <p>The OAA is going through the whole Solution, so expect a little loading time.</p>
    </div>
</div>

<button class="mi-button mi-button--base" (click)="onOAAClick()">Run OAA</button>