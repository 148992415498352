import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MapSidebar } from '../../map-sidebar/map-sidebar.interface';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { OccupantTemplate } from '../../../services/OccupantServices/occupantTemplate.model';
import { Occupant } from '../../../services/OccupantServices/Occupant';
import { SolutionService } from '../../../services/solution.service';
import { Solution } from '../../../solutions/solution.model';
import { getFormControl, getKeypathValue } from '../../../shared/form-helper';
import { Observable, Subject, Subscription, from, of } from 'rxjs';
import { VenueService } from '../../../venues/venue.service';
import { Venue } from '../../../venues/venue.model';
import { OccupantService } from '../../../services/OccupantServices/occupant.service';
import { NotificationService } from '../../../services/notification.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { OccupantCategory } from '../../../services/OccupantServices/OccupantCategory';
import { OccupantTemplateService } from '../../../services/OccupantServices/occupant-template.service';
import { OccupantCategoryService } from '../../../services/OccupantServices/occupant-category.service';
import { BaseMapAdapter } from '../../../MapAdapter/BaseMapAdapter';
import isEqual from 'fast-deep-equal';
import { LocationType } from '../../../location-types/location-type.model';
import { Translation } from '../../../shared/interfaces/translation.model';
import { ExtendedLocation } from '../../../locations/location.service';
import { BuildingService } from '../../../buildings/building.service';
import { StandardOpeningHours } from '../../../shared/standard-opening-hours/standardOpeningHours.model';

interface FormControlState {
    canInherit: boolean;
    disabled: boolean;
    keyPath: string;
    value: string;
}

@Component({
    selector: 'occupant-details-editor',
    templateUrl: './occupant-details-editor.component.html',
    styleUrls: ['./occupant-details-editor.component.scss'],
})
export class OccupantDetailsEditorComponent implements MapSidebar, OnInit, OnDestroy {
    /**
     * Setter for setting the location to be edited.
     */
    @Input()
    set data(input: { occupantData: { occupant: Occupant, occupantTemplate: OccupantTemplate }, location: ExtendedLocation }) {
        this._emptyOccupantTemplate = OccupantTemplate.createOccupantTemplate({ defaultLanguage: this._currentSolution.defaultLanguage, availableLanguages: this._currentSolution.availableLanguages });
        this._occupant = structuredClone(input.occupantData.occupant) ?? Occupant.createOccupant({ solution: this._currentSolution });

        if (!this._occupant.businessHours) {
            this._occupant.businessHours = { standardOpeningHours: new StandardOpeningHours() };
        }

        this._currentOccupant = this._occupant;
        this._occupantTemplate = structuredClone(input.occupantData.occupantTemplate) ?? this._emptyOccupantTemplate;
        this.templateTranslations = structuredClone(this._occupantTemplate.translations);
        this._currentOccupantTemplate = this._occupantTemplate;
        this.location = structuredClone(input.location);
        this.buildingService.setCurrentFloor(this.location?.floor);

        this.occupantForm.reset(this._occupant);
        this.occupantLogo = getFormControl(this.occupantForm, 'logoURI').value;
        this.setLockedState();

        this._initialFormControlStates = this.getFormControlsInitialState(this._occupantFormKeyPaths);
        this._originalFormState = structuredClone(this.occupantForm.value);

        this.getOccupantCategory(this._occupantTemplate?.occupantCategoryId).subscribe((occupantCategory) => {
            this._currentOccupantCategory = occupantCategory;
            this.filterAliases(this._currentOccupantCategory?.aliases);
            this.filterAliases(this._currentOccupantTemplate?.aliases);
        });
    }

    @Input() mapAdapter: BaseMapAdapter;

    @Output()
    public closed: EventEmitter<void> = new EventEmitter();

    @Output()
    public typeChanged: EventEmitter<LocationType> = new EventEmitter();

    @Output()
    public createOccupantWithNewLocation: EventEmitter<Occupant> = new EventEmitter();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private solutionService: SolutionService,
        private venueService: VenueService,
        private buildingService: BuildingService,
        private occupantService: OccupantService,
        private notificationService: NotificationService,
        private occupantTemplateService: OccupantTemplateService,
        private occupantCategoryService: OccupantCategoryService
    ) {
        this.solutionService.getCurrentSolution().subscribe(
            (solution) => {
                this._currentSolution = solution;
            });

        this.venueService.getSelectedVenue().subscribe(
            (venue) => {
                this._currentVenue = venue;
            });
    }

    public location: ExtendedLocation;
    public discardChangesSubject: Subject<any> = new Subject<any>();
    public nonDeletableAliases: Set<string> = new Set();
    public futureAliases: Set<string> = new Set();
    public occupantLogo: string = null;
    public occupantForm = this.formBuilder.group({
        address: [],
        aliases: [[], []],
        isAnchor: [null, []],
        isOperating: [null, []],
        businessHours: this.formBuilder.group({
            standardOpeningHours: []
        }),
        contactInformation: [],
        logoURI: [null, []],
        occupantTemplateId: [null, [Validators.required]],
        translations: [],
        correlationId: []
    });

    private _currentOccupant: Occupant;
    private _currentOccupantCategory: OccupantCategory;
    private _currentOccupantTemplate: OccupantTemplate;
    private _currentSolution: Solution;
    private _currentVenue: Venue;
    private _dirtyStateChange: Subject<boolean> = new Subject();
    private _emptyOccupantTemplate: OccupantTemplate;
    private _formSavedSubject: Subject<{ location?: ExtendedLocation, occupantData?: { occupant: Occupant, occupantTemplate: OccupantTemplate } }> = new Subject<{}>();
    private _inheritableFormControls: string[] = ['translations', 'logoURI', 'businessHours.standardOpeningHours', 'address', 'contactInformation'];
    private _initialFormControlStates;
    private _isFormDirty: boolean = false;
    private _occupant: Occupant;
    private _occupantFormKeyPaths = Object.keys(this.occupantForm.controls);
    private _occupantTemplate: OccupantTemplate;
    private _originalFormState: any;
    private _subscriptions: Subscription = new Subscription();

    /**
     * Getter for the occupant.
     *
     * @returns {Occupant}
     */
    public get occupant(): Occupant {
        return this._occupant;
    }

    /**
     * Getter for the occupant template.
     *
     * @returns {OccupantTemplate}
     */
    public get occupantTemplate(): OccupantTemplate {
        return this._currentOccupantTemplate;
    }

    /**
     * Getter for the form's dirty state.
     *
     * @returns {boolean}
     */
    public get isOccupantFormDirty(): boolean {
        return this._isFormDirty;
    }

    /**
     * Occupant form dirty state getter.
     *
     * @readonly
     * @type {Subject<boolean>}
     */
    public get dirtyStateChange(): Subject<boolean> {
        return this._dirtyStateChange;
    }

    /**
     * Occupant form save getter.
     *
     * @readonly
     * @type {Subject<{location?: ExtendedLocation, occupantData?: { occupant: Occupant, occupantTemplate: OccupantTemplate }}>}
     */
    public get formSavedSubject(): Subject<{ location?: ExtendedLocation, occupantData?: { occupant: Occupant, occupantTemplate: OccupantTemplate } }> {
        return this._formSavedSubject;
    }

    /**
     * Form control getter.
     */
    public getFormControl = getFormControl;

    public templateTranslations: Translation[];

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this._isFormDirty = this._occupant.id ? false : true;
        this._dirtyStateChange.next(this._isFormDirty);

        this.occupantForm.get('occupantTemplateId').valueChanges.subscribe((occupantTemplateId) => {
            if (occupantTemplateId !== this._originalFormState.occupantTemplateId) {
                this.occupantForm.get('occupantTemplateId').markAsDirty();
            } else {
                this.occupantForm.get('occupantTemplateId').markAsPristine();
            }
        });

        const occupantTemplateChangeSubscription = this.occupantForm.get('occupantTemplateId').valueChanges.pipe(
            filter((occupantTemplateId) => occupantTemplateId !== this._currentOccupantTemplate?.id),
            switchMap((occupantTemplateId) => this.getOccupantTemplateAndCategory(occupantTemplateId)),
            tap(({ occupantTemplate, occupantCategory }) => {
                this._currentOccupantTemplate = occupantTemplate;
                this.templateTranslations = structuredClone(this._currentOccupantTemplate.translations);

                Object.keys(this.occupantForm.controls).forEach((key) => {
                    const formControl = getFormControl(this.occupantForm, key);

                    if (formControl.disabled) {
                        formControl.setValue(getKeypathValue(this._currentOccupantTemplate, key), { emitEvent: false });

                        if (key === 'logoURI') {
                            this.occupantLogo = formControl.value;
                        }
                    }
                });

                this._currentOccupantCategory = occupantCategory;

                this.nonDeletableAliases = new Set();
                this.futureAliases = new Set();
                this.filterAliases(this._currentOccupantCategory?.aliases);
                this.filterAliases(this._currentOccupantTemplate?.aliases);

                this.occupantTemplateService.getLocationTypeByOccupantTemplateId(this._currentOccupantTemplate.id)
                    .subscribe((locationType) => {
                        this.typeChanged.emit(locationType);
                    });

            })
        ).subscribe();

        const occupantFormSubscription = this.occupantForm.valueChanges
            .subscribe(() => {
                if (this._initialFormControlStates) {
                    this._isFormDirty = this.isFormDirty();
                    this._dirtyStateChange.next(this._isFormDirty);
                }
            });

        this._subscriptions
            .add(occupantTemplateChangeSubscription)
            .add(occupantFormSubscription);
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    /**
     * Creates a list of initial form control states.
     *
     * @param {string[]} keyPaths
     * @returns {FormControlState[]}
     */
    private getFormControlsInitialState(keyPaths: string[]): FormControlState[] {
        const initialStates: FormControlState[] = [];

        for (const keyPath of keyPaths) {
            let keyPathToUse = keyPath;
            let formControlToUse = getFormControl(this.occupantForm, keyPath);
            const canInherit = this._inheritableFormControls?.includes(keyPath) ? true : false;

            if (!formControlToUse) {
                continue;
            }

            if (keyPath === 'businessHours') {
                Object.keys((formControlToUse as FormGroup).controls).forEach((businessHoursKey) => {
                    keyPathToUse = `${keyPath}.${businessHoursKey}`;
                    formControlToUse = getFormControl(this.occupantForm, `${keyPath}.${businessHoursKey}`);
                });
            }

            initialStates.push({
                keyPath: keyPathToUse,
                value: formControlToUse.disabled ? null : structuredClone(formControlToUse.value),
                disabled: formControlToUse.disabled,
                canInherit
            });
        }

        return initialStates;
    }

    /**
     * Check if the form has changed.
     *
     * @returns {boolean}
     */
    hasFormChanged(): boolean {
        const formEntries = Object.entries(this.occupantForm.value as Occupant);
        const originalFormEntries = Object.entries(this._originalFormState);

        if (formEntries.length !== originalFormEntries.length) {
            return true;
        }

        for (const [key, value] of formEntries) {
            const originalValue = this._originalFormState[key];
            if (key === 'aliases') {
                if (!isEqual(value, originalValue)) {
                    return true;
                }
                continue;
            }

            if (key === 'businessHours') {
                if (!StandardOpeningHours.equals(value.standardOpeningHours, originalValue.standardOpeningHours)) {
                    return true;
                }
                continue;
            }

            if (key === 'translations') {
                if (JSON.stringify(value) !== JSON.stringify(originalValue)) {
                    return true;
                }
                continue;
            }

            if (!isEqual(value, originalValue)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if any of the form controls are dirty.
     *
     * @returns {boolean}
     */
    private isFormDirty(): boolean {
        return this.occupantForm.dirty && this.hasFormChanged();
    }

    /**
     * Get occupant template and category.
     *
     * @param {string} occupantTemplateId
     * @returns {Observable<{ occupantTemplate: OccupantTemplate, occupantCategory: OccupantCategory }>}
     */
    private getOccupantTemplateAndCategory(occupantTemplateId: string): Observable<{ occupantTemplate: OccupantTemplate, occupantCategory: OccupantCategory }> {
        return this.getOccupantTemplate(occupantTemplateId).pipe(
            switchMap((occupantTemplate) =>
                this.getOccupantCategory(occupantTemplate?.occupantCategoryId).pipe(
                    map((occupantCategory) => ({ occupantTemplate, occupantCategory }))
                )
            )
        );
    }

    /**
     * Get occupant template.
     *
     * @param {string} occupantTemplateId
     * @returns {Observable<OccupantTemplate>}
     */
    private getOccupantTemplate(occupantTemplateId: string): Observable<OccupantTemplate> {
        return occupantTemplateId
            ? from(this.occupantTemplateService.getOccupantTemplate(occupantTemplateId))
            : of(this._emptyOccupantTemplate);
    }

    /**
     * Get occupant category.
     *
     * @param {string} occupantCategoryId
     * @returns {Observable<OccupantCategory>}
     */
    private getOccupantCategory(occupantCategoryId: string): Observable<OccupantCategory> {
        return occupantCategoryId
            ? this.occupantCategoryService.getOccupantCategoryById(occupantCategoryId)
            : of(null);
    }

    /**
     * Sets the locked state of the form controls and pre-fill them with the inherited values.
     */
    private setLockedState(): void {
        Object.keys(this.occupantForm.controls).forEach((key) => {
            const formControl = getFormControl(this.occupantForm, key);
            if (key === 'businessHours') {
                // Because the businessHours are a nested form group (only including standardOpeningHours for now, but could expand later), we need to handle them separately.
                Object.keys((formControl as FormGroup).controls).forEach((businessHoursKey) => {
                    const businessHoursFormControl = getFormControl(this.occupantForm, `${key}.${businessHoursKey}`);
                    this.setFormControlState(businessHoursFormControl, `${key}.${businessHoursKey}`);
                });
            } else {
                this.setFormControlState(formControl, key);
                if (key === 'logoURI') {
                    this.occupantLogo = formControl.value;
                }
            }
        });
    }

    /**
     * Sets the locked state of the given form control and pre-fills it with the inherited value.
     *
     * @param {FormControl | FormGroup} formControl
     * @param {string} key
     */
    private setFormControlState(formControl: FormControl | FormGroup, key: string): void {
        if (key === 'occupantTemplateId') {
            return;
        }

        if ((this._occupant && formControl.value === null && key !== 'aliases' && this._inheritableFormControls.includes(key))
            || (!this._occupant?.id && this._inheritableFormControls.includes(key))) {
            formControl.patchValue(getKeypathValue(this._currentOccupantTemplate, key), { emitEvent: false });
            formControl.disable({ emitEvent: false });
        } else {
            formControl.enable({ emitEvent: false });
        }
    }

    /**
     * Saves the occupant.
     *
     * @returns {Observable<{ occupantSaved: boolean, updatedOccupant?: Occupant }>}
     */
    private saveOccupant(): Observable<{ occupantSaved: boolean, updatedOccupant?: Occupant }> {
        const disabledFormControls = Object.entries(this.occupantForm.controls).filter(([, value]) => value.disabled);
        const updatedOccupant = {
            ...this._occupant,
            ...this.occupantForm.value
        };


        updatedOccupant.businessHours.standardOpeningHours = this.occupantForm.value.businessHours?.standardOpeningHours ? new StandardOpeningHours(updatedOccupant.businessHours.standardOpeningHours) : null;

        if (updatedOccupant.aliases?.length === 0) {
            updatedOccupant.aliases = null;
        }

        // Set properties to null if their corresponding form controls are disabled.
        for (const [key, value] of disabledFormControls) {
            if (value instanceof FormGroup) {
                Object.keys(value.controls).forEach((subKey) => {
                    updatedOccupant[key][subKey] = null;
                });
            } else {
                updatedOccupant[key] = null;
            }
        }

        updatedOccupant.locationId ??= this.location.id;
        updatedOccupant.solutionId ??= this._currentSolution.id;
        updatedOccupant.venueId ??= this._currentVenue.id;

        this._currentOccupant = updatedOccupant;

        if (!updatedOccupant.locationId) {
            return of({ occupantSaved: false, updatedOccupant });
        }

        if (this._occupant?.id) {
            return this.occupantService.updateOccupant(updatedOccupant).pipe(map(() => ({ occupantSaved: true })));
        }

        return this.occupantService.createOccupant(updatedOccupant).pipe(map(() => ({ occupantSaved: true })));
    }

    /**
     * After saving the occupant shows a success message and emits events.
     */
    private afterSave(): void {
        this.notificationService.showSuccess('Occupant saved!');
        this._isFormDirty = false;
        this._dirtyStateChange.next(false);
        this._formSavedSubject.next({ occupantData: { occupant: this._currentOccupant, occupantTemplate: this._currentOccupantTemplate } });
    }

    /**
     * Filters the given aliases into the non-deletable aliases and the future aliases.
     *
     * @param {string[]} aliases
     */
    private filterAliases(aliases: string[]): void {
        for (const alias of aliases || []) {
            if (this.location?.aliases.includes(alias)) {
                this.nonDeletableAliases.add(alias);
            } else {
                this.futureAliases.add(alias);
            }
        }
    }

    /**
     * Resets the form's locked state to the original state.
     */
    private resetFormLockedState(): void {
        const originalFormLockedState = structuredClone(this._initialFormControlStates);

        this._occupantFormKeyPaths.forEach((keyPath: string) => {
            let keyPathToCheck = keyPath;
            let formControlToCheck = getFormControl(this.occupantForm, keyPathToCheck);

            if (keyPathToCheck === 'businessHours') {
                Object.keys((formControlToCheck as FormGroup).controls).forEach((businessHoursKey) => {
                    keyPathToCheck = `${keyPath}.${businessHoursKey}`;
                    formControlToCheck = getFormControl(this.occupantForm, keyPathToCheck);
                });
            }

            if (formControlToCheck.disabled !== originalFormLockedState.find(state => state.keyPath === keyPathToCheck).disabled) {
                this.toggleInheritance(keyPathToCheck);
            }
        });
    }

    /**
     * Resets the Location Details form.
     *
     * @private
     */
    private _resetForm(): void {
        if (this._occupant) {
            const originalFormValue = structuredClone(this._originalFormState);
            this.discardChangesSubject.next(originalFormValue);
            this.occupantForm.reset(originalFormValue);
            this.resetFormLockedState();
            this.occupantForm.markAsPristine();
            this._isFormDirty = false;
        }
    }

    /**
     * Toggles the inheritance of the given key path.
     *
     * @param {string} keyPath
     * @param {MouseEvent} event
     */
    public toggleInheritance(keyPath: string, event?: MouseEvent): void {
        event?.stopPropagation();
        const formControl = getFormControl(this.occupantForm, keyPath);

        if (formControl.disabled) {
            formControl.enable();
        } else {
            formControl.patchValue(getKeypathValue(this._currentOccupantTemplate, keyPath));
            formControl.disable();

            if (keyPath === 'logoURI') {
                this.occupantLogo = getKeypathValue(this._currentOccupantTemplate, keyPath);
            }
        }
    }

    /**
     * Called when the user selects a new logo.
     *
     * @param {string} logoURI
     */
    public onLogoUriChange(logoURI: string): void {
        const logoURIFormControl = getFormControl(this.occupantForm, 'logoURI');
        const initialFormControlState = this._initialFormControlStates.find(state => state.keyPath === 'logoURI');

        (logoURI === initialFormControlState.value && logoURIFormControl.disabled === initialFormControlState.disabled)
            ? logoURIFormControl.markAsPristine()
            : logoURIFormControl.markAsDirty();

        // If the logoURI is not null, we want to assign the value.
        // If the logoURI is null, we check if the form control is disabled. If it is, we want to assign null (inherit), otherwise empty string (no logo).
        const newValue = logoURI !== null ?
            logoURI
            : (logoURI === null && logoURIFormControl.disabled) ?
                null
                : '';

        logoURIFormControl.patchValue(newValue);
    }

    /**
     * Prompt the user to confirm before discarding.
     *
     * @private
     * @returns {boolean}
     * @memberof LocationDetailsComponent
     */
    public confirmDiscard(): boolean {
        // eslint-disable-next-line no-alert
        return (this._occupant?.id !== '' && !this._isFormDirty) || confirm('Your unsaved changes will be lost! Would you like to continue?');
    }

    /**
     * Emits the close event to close the editor.
     *
     * @returns {boolean}
     */
    public close(): boolean {
        this.closed.emit();
        return true;
    }

    /**
     * Discards the changes.
     */
    public onDiscard(): void {
        if (!(this._occupant.id > '')) {
            this.close();
            return;
        }

        if (this.confirmDiscard()) {
            this._resetForm();
            this._dirtyStateChange.next(false);
            return;
        }
    }

    /**
     * Saves the occupant.
     */
    public onSave(): void {
        this.saveOccupant().subscribe(() => {
            this.afterSave();
        });
    }

    /**
     * Saves the occupant and closes the editor.
     */
    public onSaveAndClose(): void {
        this.saveOccupant().subscribe((status) => {
            if (status.occupantSaved) {
                this.afterSave();
                this.close();
            } else {
                this.createOccupantWithNewLocation.emit(status.updatedOccupant);
            }
        });
    }
}