import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MapUIService {
    private visibleComponentsSubject: BehaviorSubject<MapUIComponents> = new BehaviorSubject(MapUIComponents.Default);

    constructor() {
    }

    /**
     * Observable for visible UI components.
     *
     * @readonly
     * @type {Observable<MapUIComponents>}
     * @memberof MapUIService
     */
    get visibleComponents$(): Observable<MapUIComponents> {
        return this.visibleComponentsSubject.asObservable();
    }

    /**
     * Visible UI components.
     *
     * @readonly
     * @type {MapUIComponents}
     */
    get shown(): MapUIComponents {
        return this.visibleComponentsSubject.value;
    }

    /**
     * Sets which UI elements is visible.
     *
     * @param {MapUIComponents} components
     * @memberof MapUIService
     */
    show(components: MapUIComponents): void {
        this.visibleComponentsSubject.next(components);
    }
}

export enum MapUIComponents {
    None = 0,
    FloorSelector = 1 << 0,
    FilterBar = 1 << 1,
    MapSidebar = 1 << 2,
    MapToolbar = 1 << 3,
    ZoomControls = MapUIComponents.MapToolbar | 1 << 4,
    MapsIndoorsTools = MapUIComponents.ZoomControls | 1 << 5,
    LocationDetailsEditor = MapUIComponents.MapSidebar | MapUIComponents.MapsIndoorsTools,
    OccupantDetailsEditor = MapUIComponents.MapSidebar | MapUIComponents.MapsIndoorsTools,
    RouteElementDetailsEditor = MapUIComponents.MapSidebar | MapUIComponents.MapsIndoorsTools,
    GraphListEditor = MapUIComponents.MapSidebar | MapUIComponents.ZoomControls,
    Default = MapUIComponents.FilterBar | MapUIComponents.MapsIndoorsTools,
}
