<div class="header">
    <header>Building Highlight</header>
    <hr />
    <div class="description">
        The Building Highlight Display Rule lets you control the color, 
        width and opacity of the line around the building that is selected
        when you pan around your map.
    </div>
</div>
<div class="message message--warning">
    Your app code needs to use Display Rules, not hardcoded overrides, for your
    style changes here to apply.
</div>
<a class="documentation"
    href="https://docs.mapsindoors.com/content/map/map-styling/display-rules"
    target="_blank"
    >Read more about Display Rules in our documentation.</a
>

<form class="form" [formGroup]="buildingHighlightDisplayRuleForm">
    <navigatable-section header="Polygon" formGroupName="polygon" documentationLink="https://docs.mapsindoors.com/content/map/map-styling/display-rules/#polygon">
        <!-- Polygon visibility -->
        <div class="setting">
            <div class="setting__label">
                <label class="mi-label" for="polygon-visible">Visibility</label>
                <info-tooltip class="tooltip"
                    text="Controls whether the Polygon information is visible on the map or not."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-visible" class="mi-toggle" type="checkbox" formControlName="visible" />
            </div>
        </div>

        <!-- Polygon zoom from -->
        <div class="setting">
            <div class="setting__label">
                <label class="mi-label" for="polygon-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Polygon is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-zoom-from" class="mi-input" type="number" formControlName="zoomFrom" [max]="maxZoomLevel" [min]="minZoomLevel">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(buildingHighlightDisplayRuleForm, 'polygon.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(buildingHighlightDisplayRuleForm, 'polygon.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(buildingHighlightDisplayRuleForm, 'polygon.zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(buildingHighlightDisplayRuleForm, 'polygon.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Polygon zoom to -->
        <div class="setting">
            <div class="setting__label">
                <label class="mi-label" for="polygon-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Polygon is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <zoom-to-input inputId="icon-zoom-to" formControlName="zoomTo" [max]="maxZoomLevel" [min]="minZoomLevel"></zoom-to-input>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(buildingHighlightDisplayRuleForm, 'polygon.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(buildingHighlightDisplayRuleForm, 'polygon.zoomTo').hasError('min')">
                    "Zoom to" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(buildingHighlightDisplayRuleForm, 'polygon.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(buildingHighlightDisplayRuleForm, 'polygon.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Polygon stroke color -->
        <div class="setting">
            <div class="setting__label">
                <label class="mi-label" for="polygon-stroke-color">Stroke color</label>
                <info-tooltip class="tooltip" text="Controls the stroke color of the "></info-tooltip>
            </div>

            <div class="setting__control">
                <color-picker-input formControlName="strokeColor"></color-picker-input>
            </div>
        </div>

        <!-- Polygon stroke width -->
        <div class="setting">
            <div class="setting__label">
                <label class="mi-label" for="polygon-stroke-width">Stroke width</label>
                <info-tooltip class="tooltip" text="Controls the stroke width of the "></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="px"
                    [ngClass]="{'disabled' :getFormControl(buildingHighlightDisplayRuleForm, 'polygon.strokeWidth').disabled }">
                    <input id="polygon-stroke-width" class="mi-input" type="number" formControlName="strokeWidth">
                </div>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(buildingHighlightDisplayRuleForm, 'polygon.strokeWidth').hasError('min') || getFormControl(buildingHighlightDisplayRuleForm, 'polygon.strokeWidth').hasError('pattern') || getFormControl(buildingHighlightDisplayRuleForm, 'polygon.strokeWidth').hasError('required')">
                    Stroke width must be above 0. No decimal numbers.
                </small>
            </div>
        </div>

        <!-- Polygon stroke opacity -->
        <div class="setting">
            <div class="setting__label">
                <label class="mi-label" for="polygon-stroke-opacity">Stroke opacity</label>
                <info-tooltip class="tooltip" text="Controls the stroke opacity of the "></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-stroke-opacity" class="mi-input" type="number" step="0.1"
                    formControlName="strokeOpacity">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(buildingHighlightDisplayRuleForm, 'polygon.strokeOpacity').hasError('min') || getFormControl(buildingHighlightDisplayRuleForm, 'polygon.strokeOpacity').hasError('max') || getFormControl(buildingHighlightDisplayRuleForm, 'polygon.strokeOpacity').hasError('required')">
                    Stroke opacity must be a number between 0 and 1.
                </small>
            </div>
        </div>
    </navigatable-section>
</form>

<panel-footer *ngIf="isBuildingHighlightDisplayRuleFormDirty" text="You have unsaved changes.">
    <button type="button" class="mi-button mi-button--link" (click)="onDiscardChanges()">Discard</button>
    <button type="button" class="mi-button mi-button--primary" [disabled]="buildingHighlightDisplayRuleForm.invalid"
        (click)="onSubmit()">Save and close</button>
</panel-footer>