<div class="dialog-header" fxLayout="row" fxLayoutAlign="start center">
    <h3>Sync Manager</h3>
    <div fxFlex></div>
    <mat-icon mat-dialog-close class="close-icon">close</mat-icon>
</div>

<div class="dialog-content" *ngIf="!isSynced; else successTemplate">
    <div class="top-content" fxLayout="column" *ngIf="data?.icon">
        <div class="checkered-image-background" fxLayout="row" fxLayoutAlign="center center" fxFlex="noshrink">
            <img class="icon-img" appLazyLoad [src]="data?.icon?.url" [title]="'Icon Url: ' + data?.icon?.url">
        </div>
        <p class="info-text">{{data?.icon?.filename}}</p>
        <p class="info-text">Choose the Solutions you want to sync this image to.</p>
        <p class="info-text">Existing images with the same filename and extension will be overriden.</p>
    </div>
    <div class="top-content" *ngIf="data?.locationType">
        <p class="info-text">You are about to sync the Type "{{data.locationType.displayName}}" to the following
            Solutions.</p>
        <p class="info-text">Existing Types with the same key will be overriden.</p>
    </div>
    <div class="top-content" *ngIf="data?.category">
        <p class="info-text">You are about to sync the Category "{{data.category.displayName}}" to the following
            Solutions.</p>
        <p class="info-text">Existing Categories with the same key will be overriden.</p>
    </div>
    <mat-divider></mat-divider>
    <div class="bottom-content">
        <a (click)="toggleSolutions()" rel="noopener">{{ isAllSolutionsSelected() ? 'Select none': 'Select all' }}</a>
        <div class="solution-checkbox" *ngFor="let solution of data?.solutions">
            <mat-checkbox (click)="$event.stopPropagation()" disableRipple="true"
                          (change)="$event ? solutionSelectionModel.toggle(solution) : null"
                          [checked]="solutionSelectionModel.isSelected(solution)" color="primary">
                {{solution.name}}
            </mat-checkbox>
        </div>
    </div>
</div>

<div class="dialog-footer" fxLayout="row" fxLayoutAlign="start center">
    <button type="button" class="mi-button mi-button--primary" *ngIf="!isSynced; else doneButtonTemplate"
            (click)="synchronize()"
            [disabled]="solutionSelectionModel.selected.length <= 0">
        Synchronize
    </button>
</div>

<ng-template #doneButtonTemplate>
    <button type="button" class="mi-button mi-button--primary" mat-dialog-close>Done</button>
</ng-template>

<ng-template #successTemplate>
    <div class="dialog-content" fxLayout="column" fxLayoutAlign="start center">
        <mat-icon class="checkmark" svgIcon="checkmark" aria-hidden="false" aria-label="Checkmark icon"></mat-icon>
        <span>The synchronisation is done.</span>
    </div>
</ng-template>