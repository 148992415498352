import midtSpacing from '@mapsindoors/midt/tokens/spacing.json';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../services/notification.service';
import { OccupantCategory } from '../services/OccupantServices/OccupantCategory';
import { OccupantCategoryService } from '../services/OccupantServices/occupant-category.service';
import { OccupantTemplateEditorComponent } from './occupant-template-editor/occupant-template-editor.component';
import { OccupantTemplateService } from '../services/OccupantServices/occupant-template.service';
import { Solution } from '../solutions/solution.model';
import { SolutionService } from '../services/solution.service';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { FormatDatePipe } from '../shared/date-pipe/format-date.pipe';
import { SimplifiedOccupantTemplate, SimplifiedOccupantTemplateExtendedVersion } from '../services/OccupantServices/simplifiedOccupantTemplate.model';
import { primitiveClone } from '../shared/object-helper';

@Component({
    selector: 'app-venues',
    templateUrl: './occupant-templates-list.component.html',
    styleUrls: ['./occupant-templates-list.component.scss']
})
export class OccupantTemplatesListComponent implements OnInit, OnDestroy {
    public filteredOccupantTemplates: SimplifiedOccupantTemplateExtendedVersion[] = [];
    public occupantTemplatesToShow: SimplifiedOccupantTemplateExtendedVersion[] = [];
    public selectedOccupantTemplates: Set<SimplifiedOccupantTemplateExtendedVersion> = new Set();
    public maxRows: number = 100;
    public totalCount: number;
    public isSearchQuery: boolean = false;

    /**
     * Active filters observable.
     *
     * @readonly
     * @type {Observable<string>}
     */
    public get activeSearchQuery$(): Observable<string> {
        return this.activeSearchQuerySubject;
    }

    private activeSearchQuerySubject: BehaviorSubject<string> = new BehaviorSubject(null);
    private currentSolution: Solution;
    private dialogConfig: MatDialogConfig = {
        width: '768px',
        height: `calc(100vh - ${midtSpacing.spacing['xxxx-large']})`,
        maxHeight: '95vh',
        disableClose: true,
        hasBackdrop: true,
        role: 'dialog',
        ariaLabel: 'Occupant Templates Editor',
        closeOnNavigation: false,
        panelClass: 'details-dialog'
    };
    private occupantCategories: OccupantCategory[] = [];
    private occupantTemplates: SimplifiedOccupantTemplateExtendedVersion[] = [];
    private subscription: Subscription = new Subscription();
    private isEditorOpen: boolean = false;
    private initialTotalCount: number;

    constructor(
        private matDialog: MatDialog,
        private notificationService: NotificationService,
        private occupantCategoryService: OccupantCategoryService,
        private occupantTemplatesService: OccupantTemplateService,
        private solutionService: SolutionService,
        private spinner: NgxSpinnerService,
        private formatDatePipe: FormatDatePipe
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        // We need to make sure that the different spinner don't overlap, so we need to call hide on all of them.
        this.spinner.hide();
        this.spinner.show('occupant-template-spinner');

        // Get totalCount value. Using the lowest possible parameters for getOccupantTemplatesPaginated function.
        this.occupantTemplatesService.getOccupantTemplatesPaginated(0, 1).subscribe((occupantTemplates) => {
            this.initialTotalCount = primitiveClone(occupantTemplates.totalCount);
            this.totalCount = occupantTemplates.totalCount;
        });

        this.subscription
            .add(
                this.solutionService.getCurrentSolution()
                    .pipe(
                        // Every time the solution changes, we need to subscribe the occupant categories (differ from solution to solution).
                        switchMap((solution: Solution) => {
                            this.currentSolution = solution;
                            return this.occupantCategoryService.occupantCategories$;
                        }),
                        // Every time the occupant categories change, we need to subscribe the occupant templates.
                        switchMap((occupantCategories) => {
                            this.occupantCategories = occupantCategories;
                            return this.occupantTemplatesService.simplifiedOccupantTemplates$;
                        }),
                        // We assign the occupant templates to the filteredOccupantTemplates and we also format the data for the view by creating the addition properties.
                        map((occupantTemplates: SimplifiedOccupantTemplateExtendedVersion[]) => {
                            const occupantTemplatesList = occupantTemplates;
                            this.occupantTemplates = occupantTemplatesList.map((occupantTemplate) => {
                                // The category name in the CATEGORY PARENT 1 > CATEGORY PARENT 2 > CATEGORY format.
                                occupantTemplate.occupantCategoryName = this.occupantCategories.find((occupantCategory) => occupantCategory.id === occupantTemplate.occupantCategoryId)?.getDisplayName(this.currentSolution.defaultLanguage);
                                occupantTemplate.lastModified = this.formatDatePipe.transform(occupantTemplate.lastModified);
                                this.spinner.hide('occupant-template-spinner');
                                return occupantTemplate;
                            });
                        }),
                        // We subscribe to the activeSearchQuery$ and we filter the occupant templates based on the search query.
                        switchMap(() => this.activeSearchQuery$),
                        tap((searchQuery) => {
                            if (searchQuery > '') {
                                searchQuery = searchQuery?.toLowerCase();
                                this.handleSearchQuery(searchQuery);
                            } else {
                                this.showAllOccupantTemplates();
                            }
                        })
                    ).subscribe());
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    /**
     * Clears the selected occupant templates.
     */
    private clearSelectedOccupantTemplates(): void {
        this.selectedOccupantTemplates.clear();
    }

    /**
     * Handles search query, if present.
     *
     * @param {string} searchQuery - Search query for filter input.
     */
    private handleSearchQuery(searchQuery: string): void {
        this.spinner.show('occupant-template-spinner');
        this.isSearchQuery = true;
        this.occupantTemplatesService.getOccupantTemplatesPaginated(0, 100, searchQuery)
            .pipe(finalize(() => this.spinner.hide('occupant-template-spinner')))
            .subscribe(({ items }) => {
                this.filteredOccupantTemplates = items.map(template => new SimplifiedOccupantTemplate(template)) as SimplifiedOccupantTemplateExtendedVersion[];
                this.totalCount = 1;
            });
    }

    /**
     * When there is not search query in filter input, reset amount of occupant templates to initial total count.
     */
    private showAllOccupantTemplates(): void {
        this.isSearchQuery = false;
        this.filteredOccupantTemplates = this.occupantTemplates;
        this.totalCount = this.initialTotalCount;
    }

    /**
     * Opens the occupant template editor.
     *
     * @param {CustomEvent} event
     * @returns {Promise<void>}
     */
    public async openOccupantTemplateEditor(event?: CustomEvent): Promise<void> {
        if (this.isEditorOpen) {
            return;
        }

        const selectedOccupantTemplateId = event?.detail.id;


        let options;
        if (event) {
            const occupantTemplate = await this.occupantTemplatesService.getOccupantTemplate(selectedOccupantTemplateId);
            options = { ...this.dialogConfig, data: { occupantTemplate } };
        } else {
            options = this.dialogConfig;
        }

        const dialogRef = this.matDialog.open(OccupantTemplateEditorComponent, options);
        dialogRef.afterClosed()
            .subscribe({
                next: (updatedOccupantTemplate) => {
                    if (updatedOccupantTemplate && updatedOccupantTemplate.operation === 'update') {
                        this.spinner.show();

                        if (selectedOccupantTemplateId) {
                            // Update an existing occupant template.
                            this.occupantTemplatesService.updateOccupantTemplate(updatedOccupantTemplate.updatedOccupantTemplate)
                                .subscribe();
                        } else {
                            // Create a new occupant template.
                            this.occupantTemplatesService.createOccupantTemplate(updatedOccupantTemplate.updatedOccupantTemplate)
                                .subscribe();
                        }
                    }
                },
                error: (error) => {
                    this.notificationService.showError(error);
                },
                complete: () => {
                    this.spinner.hide();
                    this.isEditorOpen = false;
                }
            });

        this.isEditorOpen = true;
    }

    /**
     * Deletes the selected occupant templates.
     */
    public deleteSelectedOccupantTemplates(): void {
        let occupantTemplatesToBeDeleted = [...this.selectedOccupantTemplates];

        const occupantTemplatesWithOccupants = occupantTemplatesToBeDeleted.filter((occupantTemplate) => occupantTemplate.occupantReferenceCount > 0);
        if (occupantTemplatesWithOccupants.length > 0) {
            let message: string;
            // include the names in the message
            message = 'The following Occupant Template(s) cannot be deleted because they are assigned to one or more Occupants:\n\n';
            occupantTemplatesWithOccupants.forEach((occupantTemplate) => {
                message += `${occupantTemplate.name}\n`;
            });
            // eslint-disable-next-line no-alert
            alert(message);
        }

        occupantTemplatesToBeDeleted = occupantTemplatesToBeDeleted.filter((occupantTemplate) => occupantTemplate.occupantReferenceCount === 0);

        // eslint-disable-next-line no-alert
        if (occupantTemplatesToBeDeleted.length > 0 && confirm(`Do you want to delete ${occupantTemplatesToBeDeleted.length} Occupant Template${occupantTemplatesToBeDeleted.length > 1 ? 's' : ''}?`)) {
            this.spinner.show();

            this.occupantTemplatesService.deleteOccupantTemplates(occupantTemplatesToBeDeleted)
                .pipe(finalize(() => this.spinner.hide()))
                .subscribe(() => {
                    this.clearSelectedOccupantTemplates();
                }, (error) => {
                    this.notificationService.showError(error);
                });
        }
    }

    /**
     * Calls next on the activeSearchQuerySubject with the current search query.
     *
     * @param {string} searchQuery
     */
    public onSearchQueryChange(searchQuery: string): void {
        this.activeSearchQuerySubject.next(searchQuery);
    }

    /**
     * Sets the current page.
     *
     * @param {number} pageNumber
     */
    public loadNewOccupantTemplates(pageNumber: number): void {
        const skip = (pageNumber - 1) * this.maxRows;
        const take = this.maxRows;

        // Early exit if search query is present. Search query only supports 1 page.
        if (this.isSearchQuery) {
            return;
        }

        this.spinner.show('occupant-template-spinner');

        this.occupantTemplatesService.getOccupantTemplatesPaginated(skip, take)
            .pipe(
                finalize(() => {
                    this.spinner.hide('occupant-template-spinner');
                    this.clearSelectedOccupantTemplates();
                }),
            )
            .subscribe((occupantTemplates: { items: SimplifiedOccupantTemplate[] }) => {
                this.filteredOccupantTemplates = occupantTemplates.items.map(occupantTemplate => new SimplifiedOccupantTemplate(occupantTemplate)) as SimplifiedOccupantTemplateExtendedVersion[];
            }, (error) => {
                this.notificationService.showError(error);
            });
    }
}