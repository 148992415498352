<div fxFlexFill fxLayout="column" class="page-content">
	<mat-toolbar class="mat-elevation-z2 page-toolbar secondary-toolbar">
		<div>
			<h2 class="toolbar-title">Buildings</h2>
		</div>
	</mat-toolbar>
	<mat-progress-bar color="accent" mode="indeterminate" *ngIf="isProgressBarVisible"></mat-progress-bar>
	<ngx-spinner name="building-spinner" [fullScreen]="false" type="ball-pulse"></ngx-spinner>

	<table class="std-table" *ngIf="buildings" style="overflow: auto;">
		<tr class="table-header sticky">
			<th></th>
			<th class="sortable" (click)="sortTable('displayName')" [class.active]="currentSortBy === 'displayName'">
				Display Name
				<span class="table-arrow">
					<mat-icon [ngClass]="{'reverse-180': sortBy === '-displayName'}">keyboard_arrow_down</mat-icon>
				</span>
			</th>
			<th class="sortable" (click)="sortTable('administrativeId')"
				[class.active]="currentSortBy === 'administrativeId'">
				Administrative ID
				<span class="table-arrow">
					<mat-icon [ngClass]="{'reverse-180': sortBy === '-administrativeId'}">keyboard_arrow_down</mat-icon>
				</span>
			</th>
			<th>Internal ID</th>
			<th>Address</th>
		</tr>
		<tr *ngFor="let building of buildings | orderBy:sortBy:sortReverse" [class.highlight]="selectedRow == building.id">
			<td style="padding-right: 0px; width: 40px;">
				<button type="button" (click)="editBuilding(building)" mat-icon-button>
					<mat-icon>edit</mat-icon>
				</button>
			</td>
			<td>{{building.displayName}}</td>
			<td>{{building.administrativeId}}</td>
			<td>{{building.id}}</td>
			<td>{{building.address}}</td>
		</tr>
	</table>

	<!-- Sidebar -->
	<div fxLayout="column" class="list-sidebar mat-elevation-z8" *ngIf="isSideBarOpen">
		<mat-toolbar class="secondary-toolbar">
			<div fxFlex style="position: relative;">
				<button type="submit" [disabled]="!buildingForm.valid" (click)="saveBuilding()" color="primary"
					mat-raised-button>
					<span>Save and close</span>
				</button>
			</div>
			<div fxFlex="<grow>">
			</div>
			<div fxFlex>
				<button (click)="closeSidebar()" mat-icon-button>
					<mat-icon>close</mat-icon>
				</button>
			</div>
		</mat-toolbar>

		<div style="overflow: auto;" fxFlex class="page-content">

				<form #buildingForm="ngForm" fxLayout="column" class="section">

						<div fxLayout="column">
							<mat-tab-group color="accent" class="langs">
								<mat-tab *ngFor="let lang of currentBuilding.buildingInfo | orderByLanguage:defaultLanguage" [label]="lang.language">
									<div class="column">
										<mat-form-field fxFlex>
											<input name="name-{{lang.language}}" placeholder="Building name ({{lang.language}})"
												[required]="lang.language === defaultLanguage" matInput [(ngModel)]="lang.name" />
										</mat-form-field>
									</div>
								</mat-tab>
							</mat-tab-group>
							<mat-form-field class="address-spacing">
								<input name="address" placeholder="Building address" matInput [(ngModel)]="currentBuilding.address" />
							</mat-form-field>
						</div>

						<app-status-bit [geoObjects]="[currentBuilding]"></app-status-bit>
						<!-- External ID -->
						<div class="column">
							<div fxLayout="row" class="sub-section-header">
								<span class="documentation-reference">
									<h3>External ID</h3>
									<a class="documentation-reference__link" href="https://docs.mapsindoors.com/content/various/external-id/" target="_blank">Help</a>
								</span>
							</div>
							<div fxLayout="column">
								<mat-form-field>
									<input name="externalId" [(ngModel)]="currentBuilding.externalId" placeholder="External ID" matInput />
								</mat-form-field>
							</div>
						</div>

						<div *ngIf="currentBuilding.floors" class="column">
							<div class="sub-section-header">
								<h3>Floors</h3>
							</div>
							<div style="margin: -16px;">
                                <table class="std-table">
                                    <tr>
                                        <th>Searchable</th>
                                        <th>Index</th>
                                        <th *ngFor="let lang of currentBuilding.buildingInfo | orderByLanguage:defaultLanguage">Name ({{lang.language}})</th>
                                    </tr>
                                    <tr *ngFor="let floor of currentBuilding.floors">
                                        <app-status-bit [geoObjects]="[floor]" [simpleView]="true"></app-status-bit>
                                        <td>{{floor.floorIndex}}</td>
                                        <td *ngFor="let info of floor.floorInfo | orderByLanguage:defaultLanguage">
                                            <mat-form-field class="floor-name-field">
                                                <input matInput name="floor-{{floor.floorIndex}}-{{info.language}}"
                                                    placeholder="Name ({{info.language}})"
                                                    [required]="info.language === defaultLanguage"
                                                    [(ngModel)]="info.name" />
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </table>
							</div>
						</div>

						<!-- Advanced Settings -->
						<div *ngIf="showAdvancedSettings">
							<div class="column">
								<div class="sub-section-header">
									<h3>Default floor</h3>
								</div>
								<mat-form-field class="spacing--top">
									<mat-label>Default building floor</mat-label>
									<mat-select name="defaultFloor"
                                        [value]="currentBuilding.defaultFloor !== null && currentBuilding.defaultFloor !== undefined ? currentBuilding.defaultFloor : 'inheritFromVenue'"
										(selectionChange)="setDefaultFloor($event.value)">
										<mat-option [value]="'inheritFromVenue'">Inherit from venue</mat-option>
										<mat-option *ngFor="let floor of currentBuilding.floors" [value]="floor.floorIndex">{{floor.floorIndex}}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<!-- Building Photo -->
							<div class="column">
								<div fxLayout="row" class="sub-section-header">
									<h3>Building Photo</h3>
								</div>
								<image-upload
									[imageSrc]="currentBuilding.imageUrl"
									(imageUrlChanged)="onImageUrlChange($event)"
								></image-upload>
							</div>
							<!-- Custom Properties -->
							<div class="column">
								<div fxLayout="row" class="sub-section-header">
									<span class="documentation-reference">
										<h3>Custom Properties</h3>
										<a class="documentation-reference__link" href="https://docs.mapsindoors.com/content/data/customisable-data/custom-properties/" target="_blank">Help</a>
									</span>
								</div>
								<app-custom-properties [defaultLanguageCode]="currentSolution.defaultLanguage"
									[translations]="currentBuilding.buildingInfo"></app-custom-properties>
							</div>
						</div>

						<!-- Advanced Toggle -->
						<div class="column">
							<button type="button" mat-raised-button (click)="toggleAdvancedSettings()">
								{{showAdvancedSettings ? 'Hide advanced' : 'Show advanced'}}
							</button>
						</div>

				</form>

		</div>
	</div>

</div>
