import { MultiPolygon, Polygon } from 'geojson';

import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';

abstract class ObstacleViewModelProperties implements ViewModelProperties {
    originalId: string;
    originalType: MapsIndoorsData;
    clickable: boolean;
    fillColor: string;
    fillOpacity: number;
    strokeOpacity: number;
    sortKey: number;
    featureClass: FeatureClass;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a ObstacleViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @returns {ObstacleViewModelProperties}
     * @memberof ObstacleViewModelProperties
     */
    static async create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData): Promise<ObstacleViewModelProperties> {
        return await Promise.resolve({
            originalId: id,
            originalType,
            clickable: false,
            fillColor: midt['tailwind-colors'].red[500].value,
            fillOpacity: 0.2,
            strokeOpacity: 0,
            sortKey: SortKey.OBSTACLE + sortKey,
            featureClass: FeatureClass.POLYGON,
            zoomRange: [15, 999]
        });
    }
}

export class ObstacleViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    readonly geometry: Polygon | MultiPolygon;
    readonly properties: ObstacleViewModelProperties;

    private constructor(id: string, geometry: Polygon | MultiPolygon, properties: ObstacleViewModelProperties) {
        this.id = `OBSTACLE:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory to create a ObstacleViewModel from an id, a geometry, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {Polygon | MultiPolygon} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @returns {ObstacleViewModel}
     * @memberof ObstacleViewModel
     */
    static async create(id: string, geometry: Polygon | MultiPolygon, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData): Promise<ObstacleViewModel> {
        const properties = await ObstacleViewModelProperties.create(id, index, displayRule, originalType);
        const viewModel = new ObstacleViewModel(id, geometry as (Polygon | MultiPolygon), properties);

        return viewModel;
    }
}