import { Translation } from '../shared/interfaces/translation.model';
import { LabelTypeFormat } from '../display-rule-details/label-format';

export enum LocationType {
    Area = 'area',
    POI = 'poi',
    Room = 'room'
}

export interface Location {
    id: string;
    locationType: LocationType;
    class?: string;
    mapElement?: string;
    syncId?: string;
    externalId?: string;
    aliases?: string[];
    path?: string;
    pathData: PathData;
    type: string;
    imageURL?: string;
    geometry: Geometry;
    anchor?: Anchor;
    displayRule?: DisplayRule;
    categories?: string[];
    translations: Translation[];
    published?: string;
    activeFrom?: string;
    activeTo?: string;
    streetViewConfig?: StreetViewConfig;
    status?: number;
    building?: string;
    solutionId: string;
    restrictions?: string[];
    locationSettings?: LocationSettings;
    lastModified?: string;
    lastModifiedBy?: string;
    createdAt?: string;
    createdBy?: string;
    polygon?: google.maps.Polygon;
    area?: number;
}

export interface PathData {
    venue: string;
    building?: string;
    floor?: number;
    room?: string;
    /** Upcoming properties. */
    hasVenue?: boolean;
    hasBuilding?: boolean;
    hasFloor?: boolean;
    hasRoom?: boolean;
    isEmpty?: boolean;
    isOutSide?: boolean;
}

export interface Anchor {
    coordinates: number[];
    type: string;
}

export interface PolygonDisplayRule {
    visible?: boolean;
    zoomFrom?: number;
    zoomTo?: number;
    strokeWidth?: number;
    strokeColor?: string;
    strokeOpacity?: number;
    fillColor?: string;
    fillOpacity?: number;
}

export interface DisplayRule {
    icon?: string;
    clickable?: boolean;
    imageScale?: number;
    imageSize?: {
        width: number;
        height: number;
    };
    label?: string;
    visible?: boolean;
    model2D?: {
        visible?: boolean,
        model?: string,
        widthMeters?: number,
        heightMeters?: number,
        zoomTo?: number,
        zoomFrom?: number,
        bearing?: number
    }
    zoomFrom?: number;
    zoomTo?: number;
    labelZoomFrom?: number;
    labelZoomTo?: number;
    labelMaxWidth?: number;
    iconVisible?: boolean;
    labelVisible?: boolean;
    labelType?: LabelTypeFormat,
    labelStyle?: {
        textSize?: number,
        textColor?: string,
        textOpacity?: number,
        haloColor?: string,
        haloWidth?: number,
        haloBlur?: number,
        bearing?: number,
        graphic?: {
            backgroundImage?: string,
            stretchX?: [number, number][],
            stretchY?: [number, number][],
            content?: [number, number, number, number]
        }
    }
    polygon?: PolygonDisplayRule;
    iconLabelSettings?: IconLabelSettings;
    walls?: {
        visible?: boolean,
        zoomFrom?: number,
        zoomTo?: number,
        color?: string,
        height?: number
    },
    extrusion?: {
        visible?: boolean,
        zoomFrom?: number,
        zoomTo?: number,
        color?: string,
        height?: number
    },
    model3D?: {
        visible?: boolean,
        zoomTo?: number,
        zoomFrom?: number,
        model?: string,
        rotationX?: number,
        rotationY?: number,
        rotationZ?: number,
        scale?: number
    }
}

export interface IconLabelSettings {
    value: IconLabelVisibility,
    viewValue: string
}

export interface IconLabelVisibility {
    iconVisible: boolean,
    labelVisible: boolean
}

export interface Geometry {
    bbox: number[];
    coordinates: any[];
    type: string;
}

export interface StreetViewConfig {
    panoramaId: string;
    povHeading: number;
    povPitch: number;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface LocationSettings {
    selectable?: boolean;
    obstacle?: boolean;
}