import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { Feature, Geometry, Point, Position } from 'geojson';
import { Observable, from, fromEvent, merge } from 'rxjs';
import { GeodataEditor } from '../GeodataEditor';
import { GoogleMapsAdapter } from '../../MapAdapter/Google/GoogleMapsAdapter';
import { distinctUntilChanged, filter, map, mapTo, mergeAll, mergeMap, share, startWith, switchMap, takeUntil, auditTime, windowToggle } from 'rxjs/operators';
import { MouseButton } from '../../shared/enums/MouseButton';

const STROKE_COLOR = midt['tailwind-colors'].blue[500].value;
const STROKE_WIDTH = 2;

export class GoogleMapsGeodataEditor extends GeodataEditor {
    #editorLayer: google.maps.Data = new google.maps.Data();
    #editorHandlesLayer: google.maps.Data = new google.maps.Data();
    #handlesOnMap: Map<number, GeoJSON.Feature<GeoJSON.Point>> = new Map();
    #featuresOnMap: Map<number, GeoJSON.Feature> = new Map();

    /**
     * Observable for map mouse move event.
     *
     * @private
     * @type {Observable<Point>}
     */
    readonly #mapMouseMove$ = new Observable<Point>((subscriber) => {
        this.adapter.getMap().then(map => {
            const mapsEventListener = google.maps.event.addListener(map, 'mousemove', (event: google.maps.MapMouseEvent): void => {
                subscriber.next({ type: 'Point', coordinates: [event.latLng.lng(), event.latLng.lat()] });
                event.stop();
            });
            subscriber.add(() => {
                mapsEventListener.remove();
            });
        });
    });

    /**
     * Observable for click on geometry handles.
     *
     * @private
     * @type {Observable<number>}
     */
    readonly #handlesClick$ = this.#clickOn<google.maps.Data.MouseEvent>(this.#editorHandlesLayer, MouseButton.Main)
        .pipe(
            map(({ feature }) => (feature.getId() ?? feature.getProperty('index')) as number)
        );

    /**
     * Observable for right click on geometry handles.
     *
     * @private
     * @type {Observable<number>}
     */
    readonly #handleRightClick$ = this.#clickOn<google.maps.Data.MouseEvent>(this.#editorHandlesLayer, MouseButton.Secondary)
        .pipe(
            map(({ feature }) => (feature.getId() ?? feature.getProperty('index')) as number)
        );

    /**
     * Observable for drag event on geometry handles.
     *
     * @private
     * @type {Observable<{ index: number, position: number[] }>}
     */
    readonly #handlesDrag$ = this.#mouseDown<google.maps.Data.MouseEvent>(this.#editorHandlesLayer, MouseButton.Main, true)
        .pipe(
            map(({ feature }) => feature.getId() ?? feature.getProperty('index')),
            switchMap((index: number) => this.mouseMove$.pipe(
                takeUntil(this.#mouseUp(this.#editorHandlesLayer)),
                map((point: GeoJSON.Point) => ({ index, position: point.coordinates })))
            ));

    public constructor(protected readonly adapter: GoogleMapsAdapter) {
        super(adapter);
        adapter.getMap().then(map => {
            this.#setupEditorLayers(map);
        });
    }

    /**
     * Sets up the editor layers on the map.
     *
     * @param {google.maps.Map} map - The map to set up the layers on.
     */
    #setupEditorLayers(map: google.maps.Map): void {
        this.#editorHandlesLayer.setMap(map);
        this.#editorLayer.setMap(map);

        this.#editorHandlesLayer.set('id', 'editor-handles');
        this.#editorHandlesLayer.setStyle(this.#setEditorHandlesStyle.bind(this));
        this.#editorLayer.set('id', 'editor-layer');
        this.#editorLayer.setStyle(this.#setEditorLayerStyle.bind(this));
    }

    /**
     * Sets the style for the editor handles.
     *
     * @param {google.maps.Data.Feature} feature - The feature to set the style for.
     * @returns {google.maps.Data.StyleOptions}
     */
    #setEditorHandlesStyle(feature: google.maps.Data.Feature): google.maps.Data.StyleOptions {
        const properties = this.#handlesOnMap.get(feature.getId() as number)?.properties ?? {};

        return {
            clickable: properties?.clickable,
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: properties.circleRadius ?? 4,
                fillColor: midt['tailwind-colors'].white.value,
                fillOpacity: properties.opacity ?? 1,
                strokeWeight: properties.strokeWidth ?? 1,
                strokeColor: properties.strokeColor ?? STROKE_COLOR,
                strokeOpacity: properties.opacity ?? 1
            },
            cursor: properties?.cursor || this.adapter.getMapMouseCursor(),
            zIndex: properties?.sortKey ?? 2e5,
        };
    }

    /**
     * Sets the style for the editor layer.
     *
     * @param {google.maps.Data.Feature} feature - The feature to set the style for.
     * @returns {google.maps.Data.StyleOptions}
     */
    #setEditorLayerStyle(feature: google.maps.Data.Feature): google.maps.Data.StyleOptions {
        const properties = this.#featuresOnMap.get(feature.getId() as number)?.properties ?? {};
        return {
            clickable: properties?.clickable ?? true,
            strokeColor: properties.strokeColor ?? STROKE_COLOR,
            strokeWeight: properties.strokeWidth ?? STROKE_WIDTH,
            fillOpacity: 0,
            zIndex: 1e5,
            cursor: properties?.cursor || this.adapter.getMapMouseCursor(),
        };
    }

    /**
     * Handles the mouse down event on the target.
     *
     * @template T - The type of the event.
     * @param {google.maps.Data} target - The target to listen for the event.
     * @param {MouseButton} [mouseButton=MouseButton.Main] - The mouse button to listen for.
     * @param {boolean} [preventDefault=false] - Whether to prevent the default behavior of the event.
     * @returns {Observable<T>}
     */
    #mouseDown<T extends google.maps.Data.MouseEvent | google.maps.MapMouseEvent>(
        target: google.maps.Map | google.maps.Data,
        mouseButton: MouseButton = MouseButton.Main,
        preventDefault: boolean = false
    ): Observable<T> {
        return new Observable((subscriber) => {
            const mouseDownListener = target.addListener('mousedown', (event: T) => {
                if ((event.domEvent as MouseEvent).button !== mouseButton) {
                    return;
                }

                if (preventDefault) {
                    event.stop();
                }
                subscriber.next(event);
            });

            subscriber.add(() => {
                mouseDownListener.remove();
            });
        });
    }

    /**
     * Handles the mouse up event on the target.
     *
     * @template T - The type of the event.
     * @param {google.maps.Map | google.maps.Data} target - The target to listen for the event.
     * @param {MouseButton} [mouseButton=MouseButton.Main] - The mouse button to listen for.
     * @param {boolean} [preventDefault=false] - Whether to prevent the default behavior of the event.
     * @returns {Observable<T>}
     */
    #mouseUp<T extends google.maps.Data.MouseEvent | google.maps.MapMouseEvent>(
        target: google.maps.Map | google.maps.Data,
        mouseButton: MouseButton = MouseButton.Main,
        preventDefault: boolean = false
    ): Observable<T> {
        return new Observable((subscriber) => {
            const mouseUpListener = target.addListener('mouseup', (event: T): void => {
                if ((event.domEvent as MouseEvent).button !== mouseButton) {
                    return;
                }

                if (preventDefault) {
                    event.stop();
                }
                subscriber.next(event);
            });

            subscriber.add(() => {
                mouseUpListener.remove();
            });
        });
    }

    /**
     * Observes the mouse hover event on the target.
     *
     * @param {google.maps.Map | google.maps.Data} target - The target to listen for the event.
     * @returns {Observable<GeoJSON.Feature>}
     */
    #mouseHover(target: google.maps.Map | google.maps.Data): Observable<GeoJSON.Feature> {
        return merge(this.#mouseOver(target), this.#mouseOut(target))
            .pipe(
                startWith(null as GeoJSON.Feature),
                distinctUntilChanged((a: GeoJSON.Feature, b: GeoJSON.Feature) => a?.id === b?.id),
            ) as Observable<GeoJSON.Feature>;
    }

    /**
     * Handles the mouse hover event on the target.
     *
     * @param {google.maps.Map | google.maps.Data} target - The target to listen for the event.
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    #mouseOver(target: google.maps.Map | google.maps.Data): Observable<GeoJSON.Feature> {
        return new Observable<GeoJSON.Feature>((subscriber) => {
            const mouseOverListener = target.addListener('mouseover', (event: google.maps.Data.MouseEvent) => {
                event.feature.toGeoJson((feature: GeoJSON.Feature) => {
                    return subscriber.next(feature);
                });
                subscriber.add(() => mouseOverListener.remove());
            });
        }).pipe(share());
    }

    /**
     * For observing mouse out events.
     *
     * @param {google.maps.Data | google.maps.Map} target - The google maps data layer to attatch the event listner to.
     * @returns {Observable<void>}
     */
    #mouseOut(target: google.maps.Data | google.maps.Map): Observable<void> {
        return new Observable<void>((subscriber) => {
            const mouseOutListener = target.addListener('mouseout', () => {
                subscriber.next(null);
            });
            subscriber.add(() => mouseOutListener.remove());
        }).pipe(share());
    }

    /**
     * Handles the click event on the target.
     *
     * @template T - The type of the event.
     * @param {google.maps.Map | google.maps.Data} target - The target to listen for the event.
     * @returns {Observable<T>}
     */
    #clickOn<T extends google.maps.Data.MouseEvent | google.maps.MapMouseEvent>(
        target: google.maps.Map | google.maps.Data,
        mouseButton: MouseButton = MouseButton.Main
    ): Observable<T> {
        return this.#mouseDown<T>(target, mouseButton, false)
            .pipe(switchMap((event) => this.#mouseUp<T>(target, mouseButton)
                .pipe(
                    takeUntil(fromEvent(document, 'mousemove')),
                    mapTo(event)
                )
            ));
    }

    /**
     * Observable for map click event.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     */
    public get mapClick$(): Observable<Point> {
        return from(this.adapter.getMap()).pipe(
            switchMap(googleMap => this.#clickOn(googleMap)),
            map(event => ({ type: 'Point', coordinates: [event.latLng.lng(), event.latLng.lat()] }))
        );
    }

    /**
     * Observable for mouse move event.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     */
    public get mouseMove$(): Observable<Point> {
        return this.#mapMouseMove$.pipe(auditTime(16), share());
    }

    /**
     * Observable for mouse hover event.
     *
     * @readonly
     * @type {Observable<GeoJSON.Feature>}
     */
    public get mouseHover$(): Observable<GeoJSON.Feature> {
        const handleHover$ = this.#mouseHover(this.#editorHandlesLayer);
        const featureHover$ = this.#mouseHover(this.#editorLayer).pipe(windowToggle(handleHover$.pipe(filter(handle => handle === null)), () => handleHover$.pipe(filter(handle => handle !== null))), mergeAll());

        return merge(featureHover$, handleHover$).pipe(share());
    }

    /**
     * Observable for click on geometry handles.
     *
     * @readonly
     * @type {Observable<number>}
     */
    public get handleClick$(): Observable<number> {
        return this.#handlesClick$.pipe(share());
    }

    /**
     * Observable for right click on geometry handles.
     *
     * @readonly
     * @type {Observable<number>}
     */
    get handleRightClick$(): Observable<number> {
        return this.#handleRightClick$.pipe(share());
    }

    /**
     * Observable for drag event on geometry handles.
     *
     * @readonly
     * @type {Observable<{ index: number, position: number[] }>}
     */
    public get handleDrag$(): Observable<{ index: number; position: Position; }> {
        return this.#handlesDrag$.pipe(share());
    }

    /**
     * Observable for geometry click event.
     *
     * @readonly
     * @type {Observable<GeoJSON.Feature>}
     */
    public get geometryClick$(): Observable<Feature<Geometry, { [name: string]: any; }>> {
        return this.#clickOn(this.#editorLayer, MouseButton.Main).pipe(
            mergeMap((event: google.maps.Data.MouseEvent) =>
                from(new Promise<GeoJSON.Feature>(resolve => event.feature.toGeoJson(resolve)))
            )
        );
    }

    /**
     * Renders handles for the given geometry.
     *
     * @param {GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon} geometry - The geometry to render handles for.
     * @returns {void}
     */
    public renderHandlesFor(geometry: GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon): void {
        const handles: GeoJSON.Feature<GeoJSON.Point>[] = convertToPoints(geometry).map((geometry, index) => ({ id: index, type: 'Feature', geometry, properties: { index } }));
        if (geometry.type === 'Polygon') {
            handles.pop();
        }
        this.renderAsHandles(handles);
    }

    /**
     * Renders the given features as handles on the map.
     *
     * @param {GeoJSON.Feature<GeoJSON.Point>[]} features - The features to render as handles.
     * @returns {void}
     */
    renderAsHandles(features: GeoJSON.Feature<GeoJSON.Point>[]): void {
        this.#handlesOnMap = new Map(features.map((feature, index) => [(feature.id ?? index) as number, feature]));

        this.#editorHandlesLayer.forEach(feature => {
            if (!this.#handlesOnMap.has(feature.getId() as number)) {
                this.#editorHandlesLayer.remove(feature);
            }
        });

        this.#handlesOnMap.forEach(handle => {
            const feature = this.#editorHandlesLayer.getFeatureById(handle.id);
            if (feature) {
                feature.setGeometry(toGeometry(handle.geometry));
            } else {
                this.#editorHandlesLayer.addGeoJson(handle);
            }
        });

        this.#editorHandlesLayer.setStyle(this.#setEditorHandlesStyle.bind(this));
    }

    /**
     * Clears the handles.
     *
     * @returns {void}
     */
    public clearHandles(): void {
        this.#editorHandlesLayer.forEach(feature => this.#editorHandlesLayer.remove(feature));
    }

    /**
     * Render a feature on the map.
     *
     * @param {GeoJSON.Feature[]} features - The feature to render.
     * @returns {void}
     */
    public render(features: GeoJSON.Feature[]): void {
        this.#featuresOnMap = new Map<number, Feature<Geometry, { [name: string]: any; }>>(features.map((feature, index) => [(feature.id ?? index) as number, feature]));

        this.#editorLayer.forEach(feature => {
            if (!this.#featuresOnMap.has(feature.getId() as number)) {
                this.#editorLayer.remove(feature);
            }
        });

        features.forEach((feature) => {
            const featureOnMap = this.#editorLayer.getFeatureById(feature.id);
            if (featureOnMap) {
                featureOnMap.setGeometry(toGeometry(feature.geometry as GeoJSON.Polygon | GeoJSON.LineString));
            } else {
                this.#editorLayer.addGeoJson(feature);
            }
        });

        this.#editorLayer.setStyle(this.#setEditorLayerStyle.bind(this));
    }

    /**
     * Clears the map.
     *
     * @returns {void}
     */
    public clear(): void {
        this.#editorLayer.forEach(feature => this.#editorLayer.remove(feature));
        this.clearHandles();
    }
}

/**
 * Converts the given geometry to points.
 *
 * @param {GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon} geometry - The geometry to convert.
 * @returns {GeoJSON.Point[]}
 */
function convertToPoints(geometry: GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon): GeoJSON.Point[] {
    switch (geometry.type) {
        case 'MultiPoint':
        case 'LineString':
            return geometry.coordinates.map((coordinates) => ({ type: 'Point', coordinates }));
        case 'Polygon':
            return geometry.coordinates[0].map((coordinates) => ({ type: 'Point', coordinates }));
    }
}

/**
 * Convert a GeoJSON.Geometry to a google.maps.Data.Geometry.
 *
 * @private
 * @param {GeoJSON.Point | GeoJSON.MultiPoint | GeoJSON.Polygon | GeoJSON.MultiPolygon} geometry
 * @returns {google.maps.Data.Geometry}
 * @memberof GoogleMapsGeodataEditorAdapter
 */
function toGeometry(geometry: GeoJSON.Point | GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon | GeoJSON.MultiPolygon): google.maps.Data.Geometry {
    switch (geometry.type) {
        case 'Point':
            return new google.maps.Data.Point({ lat: geometry.coordinates[1], lng: geometry.coordinates[0] });
        case 'LineString': {
            const elements = geometry.coordinates.map(coords => ({ lat: coords[1], lng: coords[0] }));
            return new google.maps.Data.LineString(elements);
        }
        case 'Polygon': {
            const arr = geometry.coordinates.map(ring => ring.map(coords => ({ lat: coords[1], lng: coords[0] })).slice(0, -1));
            return new google.maps.Data.Polygon(arr);
        }
    }
}