import { LocationType } from '../location-types/location-type.model';
import { Location, LocationType as TypeOfLocation } from '../locations/location.model';
import { SolutionConfig } from '../solution-settings/solution-settings.model';

/**
 * Evaluates if the location is an obstacle.
 *
 * @param {Location} location
 * @param {LocationType[]} locationTypes
 * @param {SolutionConfig} solutionConfig
 * @returns {boolean}
 */
export function isObstacle(location: Location, locationTypes: LocationType[], solutionConfig: SolutionConfig): boolean {
    // If the location is not an area, it cannot be an obstacle.
    if (location?.locationType !== TypeOfLocation.Area) return false;
    // If the location is an obstacle, return true.
    if (location?.locationSettings?.obstacle === true) return true;
    // If the location's obstacle setting is not null (set to false), return false.
    if (location?.locationSettings?.obstacle !== null) return false;

    // Find the location type that matches the location's type.
    const locationType = locationTypes?.find(type => type.administrativeId === location.type);
    // If the location type is not found, return false.
    if (!locationType) return false;

    const obstacle = locationType?.locationSettings?.obstacle ?? null;
    // If the location type's obstacle setting is true, return true.
    // OR if the location type's obstacle setting is null (set to true), AND the solution's obstacle setting is true, return true.
    return obstacle === true || (obstacle === null && solutionConfig?.locationSettings?.obstacle === true);
}