import { BaseMapAdapter, MapMouseCursor, MapOptions, MapPadding, MapType, calculateBounds } from '../BaseMapAdapter';
import GeoJSON, { Feature } from 'geojson';

import { Loader } from '@googlemaps/js-api-loader';
import { toPointGeometry } from '../../../utilities/Geodata';
import { FeatureClass } from '../../../viewmodels/FeatureClass';
import { GoogleMaps2DModel } from './GoogleMaps2DModel';
import { Model2DViewModelProperties } from '../../../viewmodels/Model2DViewModel/Model2DViewModel';
import isEqual from 'fast-deep-equal';

export class GoogleMapsAdapter extends BaseMapAdapter {
    #map: google.maps.Map;
    #mapType: MapType = MapType.ROADMAP;
    #featuresOnMap: Map<string | number, Feature>;

    /**
     * Holds the 2D Models on the map.
     *
     * @type {Map}
     */
    #model2ds: Map<string, GoogleMaps2DModel> = new Map<string, GoogleMaps2DModel>();

    /**
     * Loads the Google Maps API.
     *
     * @private
     * @returns {Promise<google>}
     * @memberof GoogleMapsAdapter
     */
    #loadGoogleMapsApi(): Promise<typeof google> {
        return new Loader({
            apiKey: null,
            client: 'gme-mapspeopleas',
            channel: 'MapsIndoorsCMS3',
            version: '3.55.11a',
            libraries: ['places', 'drawing', 'geometry']
        })
            .load();
    }

    /**
     * Get the style options for the given feature.
     *
     * @private
     * @param {google.maps.Data.Feature} feature
     * @returns {google.maps.Data.StyleOptions}
     * @memberof GoogleMapsAdapter
     */
    private getFeatureStyle(feature: google.maps.Data.Feature): google.maps.Data.StyleOptions {
        const viewModel = this.#featuresOnMap.get(feature.getId());
        if (viewModel) {
            return {
                icon: {
                    url: viewModel.properties.src,
                    anchor: viewModel.properties.anchor,
                    scaledSize: viewModel.properties.scaledSize,
                    labelOrigin: new google.maps.Point(20, 50)
                },
                fillColor: viewModel.properties.fillColor,
                fillOpacity: viewModel.properties.fillOpacity,
                strokeColor: viewModel.properties.strokeColor,
                strokeOpacity: viewModel.properties.strokeOpacity,
                strokeWeight: viewModel.properties.strokeWidth,
                clickable: this.isClickable && viewModel.properties.clickable,
                zIndex: viewModel.properties.sortKey,
            };
        }

        return null;
    }

    constructor(element: HTMLElement, mapOptions: MapOptions) {
        super(element, mapOptions);
        this.#featuresOnMap = new Map();

        this.#loadGoogleMapsApi().then(() => {
            this.#map = new google.maps.Map(element, { ...mapOptions, center: toLatLngLiteral(mapOptions.center) });
            this.#map.addListener('idle', () => this.emit('idle'));
            this.#map.addListener('center_changed', () => this.centerSubject.next(this.getCenter()));
            this.#map.addListener('zoom_changed', () => this.emit('zoom_changed'));
            this.#map.addListener('bounds_changed', () => this.boundsSubject.next(this.getBounds()));

            this.#map.data.addListener('click', event => {
                this.clickSubject.next(this.#featuresOnMap.get(event.feature.getId().replace('HOVER:', '')));
            });

            this.#map.data.addListener('mouseover', e => {
                const geometry = e.feature.getGeometry();
                switch (geometry.getType()) {
                    case 'Polygon': {
                        const viewModel = this.#featuresOnMap.get(e.feature.getId());

                        if (viewModel) {
                            this.hoverSubject.next({ locationId: e.feature.getProperty('originalId'), featureType: 'polygon' });
                        } else {
                            this.hoverSubject.next({ locationId: null, featureType: 'polygon' });
                        }
                        break;
                    }
                    case 'Point':
                        this.hoverSubject.next({ locationId: e.feature.getProperty('originalId'), featureType: 'point' });
                        break;
                }
            });

            this.#map.data.addListener('mouseout', e => {
                this.hoverSubject.next({ locationId: null, featureType: e.feature.getGeometry().getType().toLowerCase() });
            });

            this.isClickableSubject.subscribe(() => this.#map.data.setStyle(this.getFeatureStyle.bind(this)));
            this.emit('ready');
        });
    }

    /**
     * Sets the viewport to contain the given bounds.
     *
     * @param {GeoJSON.BBox} bbox - An array of numbers in [west, south, east, north] order.
     * @param {MapPadding} [padding]
     * @memberof GoogleMapsAdapter
     */
    public fitBounds(bbox: GeoJSON.BBox, padding?: MapPadding): void {
        this.#map.fitBounds(toLatLngBoundsLiteral(bbox), padding);
    }

    /**
     * Switching the cursor type with the incoming value.
     *
     * @param {MapMouseCursor} type
     * @memberof GoogleMapsAdapter
     */
    public setMapMouseCursor(type: MapMouseCursor): void {
        this.#map.setOptions({ draggableCursor: type });
    }

    /**
     * Returns the center of the map.
     *
     * @returns {GeoJSON.Point}
     * @memberof GoogleMapsAdapter
     */
    public getCenter(): GeoJSON.Point {
        return toGeoJSONPoint(this.#map.getCenter());
    }

    /**
     * Returns the map's geographical bounds.
     *
     * @returns {GeoJSON.BBox}
     * @memberof GoogleMapsAdapter
     */
    public getBounds(): GeoJSON.BBox {
        return toGeoJSONBBox(this.#map.getBounds());
    }

    /**
     * Returns the map instance.
     *
     * @abstract
     * @returns {google.maps.Map}
     * @memberof GoogleMapsAdapter
     */
    public getMap(): Promise<google.maps.Map> {
        return new Promise((resolve) => {
            if (this.#map && this.#map instanceof google.maps.Map) {
                return resolve(this.#map);
            }
            this.once('ready', () => resolve(this.#map));
        });
    }

    /**
     * Returns the zoom of the map.
     *
     * @returns {number}
     * @memberof GoogleMapsAdapter
     */
    public getZoom(): number {
        return this.#map?.getZoom();
    }

    /**
     * Pans the map to the specified location.
     *
     * @param {GeoJSON.Point} position
     * @memberof GoogleMapsAdapter
     */
    public panTo(position: GeoJSON.Point): void {
        this.#map.panTo(toLatLngLiteral(position));
    }

    /**
     * Pans the map and centers it on the given geometry. If a padding is provided the geometry is centered in the viewport defined by the padding.
     *
     * @param {GeoJSON.Geometry} geometry - The geometry to pan to.
     * @param {MapPadding} [padding] - The amount of padding in pixels to add to the map bounds.
     */
    public panToGeometry(geometry: GeoJSON.Geometry, padding?: MapPadding): void {
        if (geometry?.type === 'Point') {
            const resolution = 1 << this.#map.getZoom();

            const paddingTop = ((padding?.top ?? 0) / resolution);
            const paddingRight = ((padding?.right ?? 0) / resolution);
            const paddingBottom = ((padding?.bottom ?? 0) / resolution);
            const paddingLeft = ((padding?.left ?? 0) / resolution);


            const center = this.#map.getProjection().fromLatLngToPoint(this.#map.getCenter());
            const { x, y } = this.#map.getProjection().fromLatLngToPoint(toLatLngLiteral(geometry));
            const { x: right, y: top } = this.#map.getProjection().fromLatLngToPoint(this.#map.getBounds().getNorthEast());
            const { x: left, y: bottom } = this.#map.getProjection().fromLatLngToPoint(this.#map.getBounds().getSouthWest());

            const offset = {
                x: center.x - (((right - paddingRight) + (left + paddingLeft)) / 2),
                y: center.y - (((bottom - paddingBottom) + (top + paddingTop)) / 2)
            };

            const newMapCenter= this.#map.getProjection().fromPointToLatLng(new google.maps.Point(x + offset.x, y + offset.y)).toJSON();

            this.#map.panTo(newMapCenter);
        } else {
            const bbox = calculateBounds(geometry);
            const center = [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2];
            this.panToGeometry(toPointGeometry(center), padding);
        }
    }

    /**
     * Returns {x, y} representing pixel coordinates, relative to the map's container, that correspond to the specified GeoJSON Point.
     *
     * @returns {{ x: number, y: number }}
     * @memberof GoogleMapsAdapter
     */
    public project(position: GeoJSON.Point): { x: number; y: number; } {
        const latLng = toLatLngLiteral(position);
        return this.#map.getProjection().fromLatLngToPoint(latLng);
    }

    /**
     * Get the current mouse cursor type.
     *
     * @returns {MapMouseCursor}
     * @memberof GoogleMapsAdapter
     */
    public getMapMouseCursor(): MapMouseCursor {
        return this.#map.get('draggableCursor') as MapMouseCursor;
    }

    /**
     * Sets the map's geographical center.
     *
     * @param {GeoJSON.Point} position
     * @memberof GoogleMapsAdapter
     */
    public setCenter(position: GeoJSON.Point): void {
        const latLng = toLatLngLiteral(position);
        this.#map.setCenter(latLng);
    }

    /**
     * Sets the map type to be displayed.
     *
     * @param {MapType} mapType
     * @memberof GoogleMapsAdapter
     */
    public setMapType(mapType: MapType): void {
        if (mapType !== this.#mapType) {
            this.#mapType = mapType;
            switch (this.#mapType) {
                case MapType.HYBRID:
                    this.#map.setMapTypeId(google.maps.MapTypeId.HYBRID);
                    this.#map.setTilt(0);
                    break;
                case MapType.SATELLITE:
                    this.#map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
                    this.#map.setTilt(0);
                    break;
                case MapType.ROADMAP:
                default:
                    this.#map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
                    break;
            }
        }
    }

    /**
     * Gets the current map type.
     *
     * @returns {MapType}
     */
    getMapType(): MapType {
        return this.#mapType;
    }

    /**
     * Sets the maximum zoom level of the map.
     *
     * @param {number} level
     * @memberof GoogleMapsAdapter
     */
    setMaxZoom(level: number = 21): void {
        this.#map.setOptions({ maxZoom: level });
    }

    /**
     * Sets the overlay tile url.
     *
     * @param {string} tileUrl
     * @memberof GoogleMapsAdapter
     */
    public async setOverlayTileUrl(tileUrl: string): Promise<void> {
        const map = await this.getMap();
        if (map instanceof google.maps.Map && tileUrl > '') {
            const mapTypes = map.overlayMapTypes.getArray();
            const indexOfMapsIndoorsTiles = mapTypes.findIndex(mapType => mapType.name === 'MapsIndoorsTiles');
            const mapsIndoorsTiles = new google.maps.ImageMapType({
                getTileUrl: function (coord, zoom) {
                    return tileUrl.replace('{z}', zoom.toString()).replace('{x}', coord?.x.toString()).replace('{y}', coord?.y.toString());
                },
                tileSize: new google.maps.Size(256, 256),
                name: 'MapsIndoorsTiles'
            });

            if (indexOfMapsIndoorsTiles > -1) {
                google.maps.event.addListenerOnce(mapsIndoorsTiles, 'tilesloaded', () => {
                    map.overlayMapTypes.removeAt(indexOfMapsIndoorsTiles);
                });
            }
            map.overlayMapTypes.push(mapsIndoorsTiles);
        }
    }

    /**
     * Returns a GeoJSON Point representing geographical coordinates that correspond to the specified pixel coordinates.
     *
     * @param {{ x: number; y: number }} point
     * @returns {GeoJSON.Point}
     * @memberof GoogleMapsAdapter
     */
    public unproject(point: { x: number, y: number }): Promise<GeoJSON.Point> {
        return new Promise(resolve => {
            // We create an OverlayView and use the projection from that, since the map projection method fromPointToLatLng from the map is giving incomprehensible results.
            const overlayView = new google.maps.OverlayView;
            overlayView.setMap(this.#map);

            overlayView.onRemove = () => { };

            overlayView.onAdd = () => {
                const projection = overlayView.getProjection();

                const latLng = projection.fromContainerPixelToLatLng(new google.maps.Point(point.x, point.y));

                overlayView.setMap(null);

                resolve(toPointGeometry([latLng.lng(), latLng.lat()]));
            };
        });
    }

    /**
     * Sets the zoom of the map.
     *
     * @param {number} level
     * @memberof GoogleMapsAdapter
     */
    public setZoom(level: number): void {
        this.#map.setZoom(level);
    }

    /**
     * Sets the opacity value for the wall and extrusion layers (Mapbox only).
     */
    public setSettings3D(): void { }

    /**
     * Set the data displayed on the map.
     *
     * @param {GeoJSON.Feature[]} features
     * @memberof GoogleMapsAdapter
     */
    public setViewData(features: GeoJSON.Feature[]): void {
        const unsupportedFeatureClasses = [FeatureClass.WALL, FeatureClass.EXTRUSION, FeatureClass.MODEL3D, FeatureClass.TEXT_LABEL, FeatureClass.FLAT_LABEL];
        const is2dmodelsEnabled = this.isModuleEnabled('2dmodels');
        const featuresInView = features.reduce((featuresOnMap, feature) => {
            if (unsupportedFeatureClasses.includes(feature.properties.featureClass)) {
                return featuresOnMap;
            }
            return featuresOnMap.set(feature.id, feature);
        }, new Map());

        this.#map.data.forEach(feature => {
            const featureOnMap = Object.assign({}, this.#featuresOnMap.get(feature.getId()));
            const newFeature = Object.assign({}, features.find(featureInView => featureInView.id === feature.getId()));

            // To avoid unnecessary map updates, the sortKey is deleted. The features have already been sorted previously.
            delete featureOnMap?.properties?.sortKey;
            delete newFeature?.properties?.sortKey;

            if (!featuresInView.has(feature.getId())
                || !isEqual(featureOnMap, newFeature)) {
                this.#map.data.remove(feature);
            }
        });

        // Assigning the new features to #featuresOnMap is needed before the features are added to the map, because of the new feature poroperties.
        this.#featuresOnMap = featuresInView;

        for (const [id, model2d] of this.#model2ds) {
            if (!featuresInView.has(id)) {
                model2d.setMap(null);
                this.#model2ds.delete(id);
            }
        }

        for (const [id, feature] of featuresInView) {
            const featureOnMap = this.#map.data.getFeatureById(id);
            if (is2dmodelsEnabled && feature.properties.featureClass === FeatureClass.MODEL2D) {
                const model2D = this.#model2ds.get(feature.id.toString());
                if (model2D) {
                    model2D.setPosition(feature.geometry as GeoJSON.Point);
                    model2D.setStyle(feature.properties as Model2DViewModelProperties);
                } else {
                    const googleMaps2DModel = new GoogleMaps2DModel(feature as GeoJSON.Feature<GeoJSON.Point>, this.#map);
                    this.#model2ds.set(feature.id.toString(), googleMaps2DModel);
                }
                continue;
            }

            if (featureOnMap) {
                continue;
            }

            if (feature.properties.featureClass !== FeatureClass.MODEL2D) {
                this.#map.data.addGeoJson(feature);
            }
        }
    }

    /**
     * Resets the rotate and the tilt properties of the map.
     */
    public resetRotateAndTilt(): void { }
}

/**
 * Converts a GeoJSON.Point to a LatLngLiteral.
 *
 * @param {GeoJSON.Point} position
 * @returns {{ lat: number, lng: number }}
 * @memberof GoogleMapsAdapter
 */
function toLatLngLiteral(position: GeoJSON.Point): { lat: number, lng: number } {
    return position ? { lat: position?.coordinates[1], lng: position?.coordinates[0] } : null;
}

/**
 * Converts GeoJSON Bbox to a LatLngBoundsLiteral.
 *
 * @param {GeoJSON.BBox} bbox - An array of numbers in [west, south, east, north] order.
 * @returns {google.maps.LatLngBoundsLiteral}
 * @memberof GoogleMapsAdapter
 */
function toLatLngBoundsLiteral(bbox: GeoJSON.BBox): google.maps.LatLngBoundsLiteral {
    return { west: bbox[0], south: bbox[1], east: bbox[2], north: bbox[3] };
}

/**
 * Converts a google.maps.LatLng to a GeoJSON.Point.
 *
 * @param {google.maps.LatLng} point
 * @returns {GeoJSON.Point}
 * @memberof GoogleMapsAdapter
 */
function toGeoJSONPoint(point: google.maps.LatLng): GeoJSON.Point {
    return { type: 'Point', coordinates: [point.lng(), point.lat()] };
}

/**
 * Converts a google.maps.LatLngBounds to a GeoJSON Bbox.
 *
 * @param {google.maps.LatLngBounds} bbox
 * @returns {GeoJSON.BBox} - An array of numbers in [west, south, east, north] order.
 * @memberof GoogleMapsAdapter
 */
function toGeoJSONBBox(bbox: google.maps.LatLngBounds): GeoJSON.BBox {
    if (!bbox) {
        return null;
    }

    const latLngBoundsLiteral = bbox?.toJSON();
    return [latLngBoundsLiteral.west, latLngBoundsLiteral.south, latLngBoundsLiteral.east, latLngBoundsLiteral.north];
}
