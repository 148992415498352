<header class="top-bar">
    <nav class="nav-bar nav-bar--primary">
        <!-- Left menu -->
        <div class="menu">
            <mat-icon class="menu__logo" svgIcon="mapsindoors" aria-hidden="false" aria-label="Home icon"></mat-icon>

            <mi-combo-box class="menu__combobox" #solutionSelector items-order="asc" (change)="onSolutionChange($event)"></mi-combo-box>

            <a
                [ngClass]="{ 'menu__item': true }"
                [routerLink]="mapLink"
                routerLinkActive="menu__item--active">
                Map
            </a>

            <ng-container *ngFor="let menu of menus">
                <a
                    [routerLink]="menu.children[0]?.url"
                    *ngIf="menu.children[0]?.url && menu.id === 'solution-details'"
                    [ngClass]="{ 'menu__item': true, 'menu__item--active': activeMenu === menu.name }">
                    {{ menu.name }}
                </a>
                <a
                    [routerLink]="menu.children[0]?.url"
                    *ngIf="menu.children[0]?.url && menu.id === 'deployment'"
                    [ngClass]="{ 'menu__item': true, 'menu__item--active': activeMenu === menu.name }">
                    {{ menu.name }}
                </a>
                <a
                    *ngIf="menu.id === 'old-map'"
                    [ngClass]="{ 'menu__item': true, 'menu__item--active': activeMenu === menu.name }"
                    [routerLink]="menu.children[0]?.url || menu.url">
                    {{ menu.name }}
                </a>
            </ng-container>

            <a class="menu__item menu__item--link-external-webapp" target="_blank" rel="external" [href]="appLink" *ngIf="appLink" disableRipple>
                Web app
                <mat-icon class="web-app-icon" aria-hidden="true" aria-label="Web App Link">
                    open_in_new
                </mat-icon>
            </a>
        </div>

        <!-- Right menu -->
        <div class="menu">
            <button class="mi-button mi-button--dusk menu__media-library" (click)="openMediaLibrary()">
                <img src="../../assets/images/media-library.svg" alt="">
                <span>Media Library</span>
            </button>

            <ng-container *ngFor="let menu of menus">
                <a
                    [routerLink]="menu.children[0]?.url"
                    *ngIf="menu.children[0]?.url && menu.id === 'administration'"
                    [ngClass]="{ 'menu__item': true, 'menu__item--active': activeMenu === menu.name }">

                    <img src="../../assets/images/icon-gear.svg"/>
                </a>
            </ng-container>

            <button class="menu__item menu__user-profile" [matMenuTriggerFor]="userMenu">
                <img class="avatar" alt="User Profile Avatar"
                    [src]="profilePictureUrl"
                    (error)="onImgError($event)"/>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                    <button mat-menu-item [disableRipple]="true" (click)="newsEvent.emit()">
                        What's new
                    </button>

                    <a mat-menu-item [disableRipple]="true"
                        href="https://docs.mapsindoors.com/content/cms/" target="_blank" rel="external">
                        Docs
                        <mat-icon class="launch-icon">
                            launch
                        </mat-icon>
                    </a>

                    <a mat-menu-item [disableRipple]="true" routerLink='settings/'>Settings</a>

                    <mat-divider></mat-divider>

                    <button mat-menu-item [disableRipple]="true" (click)="logOut()">
                        Log out
                    </button>
                </mat-menu>
            </button>
        </div>
    </nav>

    <!-- Submenu -->
    <nav class="nav-bar nav-bar--secondary box-shadow" *ngIf="subMenu.length > 0">
        <div class="menu">
            <ng-container *ngFor="let menu of subMenu">
                <a
                    class="menu__item menu__item--secondary"
                    [routerLink]="menu.url"
                    routerLinkActive="menu__item--secondary-active">{{ menu.name }}
                </a>
            </ng-container>
        </div>
    </nav>

    <!-- Subsubmenu -->
    <nav class="nav-bar nav-bar--secondary box-shadow" *ngIf="subSubMenu.length > 0">
        <div class="menu">
            <ng-container *ngFor="let menu of subSubMenu">
                <a
                    class="menu__item menu__item--secondary"
                    [routerLink]="menu.url"
                    routerLinkActive="menu__item--secondary-active">{{ menu.name }}
                </a>
            </ng-container>
        </div>
    </nav>
</header>
