import { MapViewModel, MapViewModelFactory } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { RouteElement } from '../../map/route-element-details/route-element.model';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { GeoJSONGeometryType } from '../../shared/enums';
import { LineStringViewModel } from '../../../viewmodels/LineStringViewModel/LineStringViewModel';
import { BBox } from 'geojson';
import { bboxPolygon, feature as toFeature } from '@turf/turf';
import booleanIntersects from '@turf/boolean-intersects';
import { Floor } from '../../buildings/floor.model';
import { MapsIndoorsData } from '../../shared/enums/MapsIndoorsData';

export class AddMultipleDoorsMapViewModelFactory extends MapViewModelFactory<RouteElement> {
    constructor(private displayRuleService: DisplayRuleService) {
        super();
    }

    /**
     * Creates MapViewModels for the given multiple doors Route Elements. Each door is represented as a single linestring.
     *
     * @param {Location} routeElement
     * @param {number} zIndex
     * @returns {Promise<MapViewModel[]>}
     * @memberof RouteElementMapViewModelFactory
     */
    async create(routeElement: RouteElement, zIndex?: number): Promise<MapViewModel[]> {
        const viewModels: MapViewModel[] = [];
        const displayRule = await this.displayRuleService.getRouteElementDisplayRule(routeElement);

        if (!this.isVisible(displayRule))
            return viewModels;

        if (routeElement?.geometry?.type === GeoJSONGeometryType.LineString) {
            displayRule.polygon.visible = true;
            displayRule.polygon.strokeColor = '#EF6CCE';
            displayRule.polygon.strokeWidth = 10;
            displayRule.polygon.strokeOpacity = 1;
            displayRule.polygon.fillOpacity = 1;

            viewModels.push(LineStringViewModel.create(routeElement.id, routeElement.geometry as GeoJSON.LineString, displayRule, zIndex, MapsIndoorsData.RouteElement));
        }

        return viewModels;
    }

    /**
     * Checks if the given RouteElement intersects with the given bounds.
     *
     * @param {RouteElement} routeElement
     * @param {BBox} bounds
     * @returns {boolean}
     * @memberof RouteElementMapViewModelFactory
     */
    intersectsWithBounds(routeElement: RouteElement, bounds: BBox): boolean {
        const boundsAsPolygon = bboxPolygon(bounds);
        return booleanIntersects(boundsAsPolygon, toFeature(routeElement.geometry));
    }


    /**
     * Checks if the floorIndex for the given RouteElement equals the given floorIndex.
     *
     * @param {RouteElement} routeElement
     * @param {floor} floor
     * @returns {boolean}
     * @memberof RouteElementMapViewModelFactory
     */
    floorEquals(routeElement: RouteElement, floor: Floor): boolean {
        return routeElement?.floorIndex === floor?.floorIndex;
    }

}