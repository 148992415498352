import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'navigatable-section',
    templateUrl: './navigatable-section.component.html',
    styleUrls: ['./navigatable-section.component.scss']
})
export class NavigatableSectionComponent {
    @Input() header: string;
    @Input() documentationLink: string;
    @Input() isLockable: boolean;
    @Input() locked: boolean;
    @Output() lockedChange: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    /**
     * Emit the lockedChange event.
     *
     * @param {Event} event
     */
    public onToggleChange(event: Event): void {
        this.lockedChange.emit(event);
    }
}