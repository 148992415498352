import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SolutionService } from './solution.service';
import { FormatService } from './format.service';
import { environment } from '../../environments/environment';
import { GraphData } from '../map/graph-data.model';

@Injectable({
    providedIn: 'root'
})
export class ImportService {
    private api = environment.APIEndpoint;

    constructor(
        private solutionService: SolutionService,
        private formatService: FormatService,
        private http: HttpClient
    ) { }

    /**
     * Download a file from the backend.
     *
     * @param {string} path - The path to the file on the backend.
     * @param {object} options - An object containing optional parameters.
     * @param {string[]} options.venueIds - An array of venue IDs. If not provided or empty, the 'venueIds' parameter will not be included in the request.
     * @param {string} [options.graphId=''] - The ID of the graph. Defaults to an empty string.
     * @param {string} [options.fileType='csv'] - The type of the file. Defaults to 'csv'.
     * @returns {Observable<any>} An Observable that will emit the downloaded file data.
     */
    public download(path: string, { venueIds = [], graphId = '', fileType = 'csv' }): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        let params = new HttpParams();

        if (venueIds.length > 0) {
            params = params.set('venueIds', venueIds.join(','));
        }

        if (fileType === 'osm') {
            params = params.set('onlyManuallyDrawnGraph', true);
        }

        return this.http.get(`${this.api}${solution.id}/api/${path}/${fileType}/${graphId}`, { responseType: 'arraybuffer', params });
    }

    /**
     * Upload a file to the backend.
     *
     * @param {string} path
     * @param {boolean} preview
     * @param {*} file
     * @param {string} [fileType='csv']
     * @param {string} [graphId='']
     * @returns {Observable<any>}
     * @memberof ImportService
     */
    public upload(path: string, preview: boolean, file: any, fileType = 'csv', graphId = ''): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        const headers = new HttpHeaders({ 'Content-Type': fileType === 'osm' ? 'application/osm' : 'text/csv' });
        return this.http.post(`${this.api}${solution.id}/api/${path}/${fileType}${fileType === 'osm' ? '/' + graphId : ''}?preview=${preview}`, file, { headers });
    }

    /**
     * Get an array of graph IDs.
     *
     * @returns {Observable<string[]>}
     * @memberof ImportService
     */
    public getGraphIds(): Observable<string[]> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get<string[]>(`${this.api}${solution.id}/api/graphs?graphIdOnly=true`);
    }


    /**
     * Create an API key for a solution.
     *
     * @param {string} solutionId - The ID of the solution.
     * @returns {Observable<any>} An Observable that will emit the API key creation response.
     * @memberof ImportService
     */
    createApiKey(solutionId: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'text/json' });
        return this.http.post(environment.APIEndpoint + solutionId + '/api/apikey/createsolutionkey/', true, { headers: headers });
    }

    /**
     * Clones a solution with the given ID and creates a new solution with the given name.
     * If venues are provided, only the specified venues will be included in the cloned solution.
     *
     * @param {string} solutionId - The ID of the solution to clone.
     * @param {string} newSolutionName - The name of the new solution to create.
     * @param {string[]} [venuesIds] - An optional array of venue IDs to include in the cloned solution.
     * @returns {Observable<object>}
     */
    cloneSolution(solutionId: string, newSolutionName: string, venuesIds?: string[]): Observable<object> {
        const headers = new HttpHeaders({ 'Content-Type': 'text/json' });

        let url = `${environment.APIEndpoint}api/solutions/clone/${solutionId}?clonedSolutionName=${encodeURIComponent(newSolutionName)}`;
        if (venuesIds?.length > 0) {
            url = `${url}&venueIds=${encodeURIComponent(venuesIds.join(','))}`;
        }

        return this.http.post(url, true, { headers: headers });
    }

    /**
     * Update the location cache for a solution.
     *
     * @param {string} solutionId - The ID of the solution.
     * @returns {Observable<any>} An Observable that will emit the update location cache response.
     * @memberof ImportService
     */
    updateLocationCache(solutionId: string): Observable<any> {
        return this.http.get(environment.clientApi + 'api/locations/updatecache?solutionid=' + solutionId);
    }

    /**
     * Delete a graph.
     *
     * @param {string} graphId - The ID of the graph to delete.
     * @returns {Observable<any>} An Observable that will emit the delete graph response.
     * @memberof ImportService
     */
    deleteGraph(graphId): Observable<any> {
        const lmDate = new Date('December 31, 2031, 23:15:30 GMT+11:00');
        const lastModified = this.formatService.utcDate(lmDate);
        const graphsUrl = this.getGraphsURL();
        const headers = new HttpHeaders({ 'If-Modified-Since': lastModified });
        return this.http.delete(graphsUrl + graphId, { headers: headers });
    }

    /**
     * Get graphs data for the current solution.
     *
     * @returns {Observable<GraphData[]>}
     * @memberof ImportService
     */
    public getSolutionGraphs(): Observable<GraphData[]> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get<GraphData[]>(environment.APIEndpoint + solution.id + '/api/graphs');
    }

    /**
     * Save the graph data for the current solution.
     *
     * @param {*} graph
     * @returns {Observable<any>}
     * @memberof ImportService
     */
    public saveSolutionGraph(graph): Observable<any> {
        const graphsUrl = this.getGraphsURL();
        const requestOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(graphsUrl, graph, { headers: requestOptions });
    }

    /**
     * Fetches a specific node from a graph.
     *
     * @param {string} venue - The ID of the graph.
     * @param {string} id - The ID of the node.
     * @returns {Observable<any>} An Observable that will emit the node data.
     */
    getNode(venue, id): Observable<any> {
        const graphUrl = this.getGraphsURL();

        return this.http.get(graphUrl + 'node?graphId=' + venue + '&nodeId=' + id);
    }

    /**
     * Updates a node in a graph.
     *
     * @param {string} venue - The ID of the graph.
     * @param {any} node - The node data to update.
     * @returns {Observable<any>} An Observable that will emit the response from the server.
     */
    saveNode(venue, node): Observable<any> {
        const graphUrl = this.getGraphsURL();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(graphUrl + 'node?graphId=' + venue, node, { headers: headers });
    }

    /**
     * Constructs and returns the URL for the graphs API.
     *
     * @returns {string} The URL for the graphs API.
     */
    getGraphsURL(): string {
        const solution = this.solutionService.getStaticSolution();
        const url = environment.APIEndpoint + solution.id + '/api/graphs/';
        return url;
    }
}

