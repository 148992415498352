import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AddDoorComponent } from '../subcomponents/add-door/add-door.component';
import { AddMultipleDoorsComponent } from '../subcomponents/add-multiple-doors/add-multiple-doors.component';
import { ChipsListComponent } from './filters-bar/chips-list/chips-list.component';
import { CombineLocationsComponent } from '../subcomponents/combine-locations/combine-locations.component';
import { DrawingService } from '../services/drawing.service';
import { FiltersBarComponent } from './filters-bar/filters-bar.component';
import { GraphDataService } from './graph-data.service';
import { GraphListComponent } from './graph-list/graph-list.component';
import { LocationsBulkEditComponent } from './locations-bulk-edit/locations-bulk-edit.component';
import { LocationsListComponent } from './locations-list/locations-list.component';
import { LocationsMapService } from './locations-map/locations-map.service';
import { LocationsModule } from '../locations/location.module';
import { MapComponent } from './map.component';
import { MapToolbarComponent } from './map-toolbar/map-toolbar.component';
import { NetworkMapService } from './network-map.service';
import { RouteElementDetailsComponent } from './route-element-details/route-element-details.component';
import { RouteElementSubtypeComponent } from './route-element-details/route-element-subtype/route-element-subtype.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SplitLocationComponent } from '../subcomponents/split-location/split-location.component';

@NgModule({
    declarations: [
        MapComponent,
        FiltersBarComponent,
        LocationsListComponent,
        SplitLocationComponent,
        CombineLocationsComponent,
        ChipsListComponent,
        LocationsBulkEditComponent,
        AddDoorComponent,
        RouteElementDetailsComponent,
        MapToolbarComponent,
        RouteElementSubtypeComponent,
        GraphListComponent,
        AddMultipleDoorsComponent
    ],
    exports: [
        FiltersBarComponent,
        LocationsListComponent,
        LocationsBulkEditComponent
    ],
    imports: [
        SharedModule,
        LocationsModule,
        RouterModule,
    ],
    providers: [
        LocationsMapService,
        NetworkMapService,
        DrawingService,
        GraphDataService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MapModule { }
