<form [formGroup]="contactForm" class="contact-form">
    <label class="mi-label">
        Website
        <input formControlName="webAddress" class="mi-input" type="text" placeholder="Enter Website" />
        <small role="alert" class="mi-form__error" *ngIf="!contactForm.controls.webAddress.valid && !contactForm.disabled">
            Must be an absolute URL.
        </small>
    </label>

    <label class="mi-label">
        Email
        <input formControlName="email" class="mi-input" type="text" placeholder="Enter Email" />
        <small role="alert" class="mi-form__error" *ngIf="!contactForm.controls.email.valid && !contactForm.disabled">
            Must be a valid email address.
        </small>
    </label>

    <label class="mi-label">
        Phone Number
        <input formControlName="phoneNumber" class="mi-input" type="text" placeholder="Enter Phone Number" />
        <small role="alert" class="mi-form__error" *ngIf="!contactForm.controls.phoneNumber.valid && !contactForm.disabled">
            Must be a valid phone number.
        </small>
    </label>
</form>