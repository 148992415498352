import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
    @Input() itemsPerPage: number;

    /**
     * The total number of items.
     */
    @Input() set totalItems(totalItems: number) {
        // Round the number up.
        this.totalPages = Math.ceil(totalItems / this.itemsPerPage);

        if (this.currentPage !== 1 && this.currentPage > this.totalPages) {
            this.activatePage(this.totalPages);
        } else {
            this.pageChanged.emit(this.currentPage);
        }
    }

    @Output() pageChanged: EventEmitter<number> = new EventEmitter();

    public currentPage: number = 1;
    public totalPages: number = 0;
    public previousPageDisabled: boolean = false;
    public nextPageDisabled: boolean = false;

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.pageChanged.subscribe((page: number) => {
            this.previousPageDisabled = page <= 1;
            this.nextPageDisabled = page >= this.totalPages;
        });
    }

    /**
     * Changes the current page to the specified page.
     *
     * @param {number} page
     */
    private activatePage(page: number): void {
        this.currentPage = page;
        this.pageChanged.emit(this.currentPage);
    }

    /**
     * Increases the current page by 1.
     */
    public previousPage(): void {
        if (this.currentPage === 1) {
            return;
        }

        this.activatePage(this.currentPage - 1);
    }

    /**
     * Decreases the current page by 1.
     */
    public nextPage(): void {
        if (this.currentPage === this.totalPages) {
            return;
        }

        this.activatePage(this.currentPage + 1);
    }

    /**
     * Changes the current page to the specified page.
     *
     * @param {Event} event
     */
    public onInputChange(event: Event): void {
        const page = parseInt((event.target as HTMLInputElement).value);
        this.activatePage(page);
    }
}