<button type="button"
        aria-label="Cancel"
        class="mi-button mi-button--small mi-button--base"
        [disabled]="status === EditorStatus.Saving"
        (click)="onCancel()">Cancel</button>
<button type="button"
        aria-label="Save"
        class="mi-button mi-button--small mi-button--primary"
        [disabled]="status === EditorStatus.Saving"
        (click)="onSave()">Save</button>
<button type="button"
        aria-label="Save and close"
        class="mi-button mi-button--small mi-button--primary"
        [disabled]="status === EditorStatus.Saving"
        (click)="onSave(true)">Save and close</button>
<span class="map-toolbar__divider"></span>
<button type="button"
        aria-label="Draw"
        class="map-toolbar__control"
        [ngClass]="{'map-toolbar__control--active': mode === EditorMode.DRAW}"
        (click)="setMode(EditorMode.DRAW)">
    <span class="tooltip">Draw mode</span>
    <img src="/assets/icons/toolbar/pencil.svg" alt="Draw mode">
</button>
<button type="button"
        aria-label="Select"
        class="map-toolbar__control"
        [ngClass]="{'map-toolbar__control--active': mode === EditorMode.EDIT}"
        (click)="setMode(EditorMode.EDIT)">
    <span class="tooltip">Edit mode</span>
    <img src="/assets/icons/toolbar/arrow.svg" alt="Edit mode">
</button>
<span class="map-toolbar__divider"></span>
<button type="button"
        aria-label="Undo"
        class="map-toolbar__control"
        [disabled]="status === EditorStatus.Saving || !canUndo"
        (click)="undo()">
    <span class="tooltip">Undo</span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.79 3.77C15.9098 2.44949 13.703 1.6709 11.4105 1.51924C9.1179 1.36757 6.82774 1.84867 4.79 2.91C4.74297 2.93504 4.68895 2.94372 4.63644 2.93466C4.58393 2.92561 4.53593 2.89934 4.5 2.86L1.94 0.27C1.86779 0.202054 1.77797 0.155726 1.68075 0.136281C1.58352 0.116836 1.48279 0.125054 1.39 0.16C1.29945 0.198584 1.222 0.262545 1.16699 0.344168C1.11199 0.425791 1.08177 0.521588 1.08 0.62V7.32C1.08256 7.45181 1.13606 7.5775 1.22928 7.67072C1.3225 7.76394 1.4482 7.81744 1.58 7.82H8.28C8.37871 7.81951 8.47507 7.78981 8.55693 7.73464C8.63879 7.67947 8.70249 7.60131 8.74 7.51C8.77156 7.42326 8.77798 7.32937 8.75852 7.23914C8.73906 7.14892 8.69451 7.06602 8.63 7L6.78 5.11C6.75085 5.08231 6.72985 5.04717 6.71927 5.00838C6.7087 4.9696 6.70895 4.92865 6.72 4.89C6.72658 4.84931 6.74397 4.81113 6.77036 4.77947C6.79675 4.7478 6.83116 4.72381 6.87 4.71C8.41288 4.0869 10.0875 3.86109 11.7403 4.05328C13.3931 4.24547 14.9713 4.8495 16.33 5.81C17.5872 6.70453 18.6138 7.88492 19.3254 9.25396C20.037 10.623 20.4132 12.1415 20.423 13.6844C20.4327 15.2273 20.0758 16.7505 19.3816 18.1284C18.6874 19.5063 17.6757 20.6996 16.43 21.61C16.2971 21.707 16.1847 21.8293 16.0992 21.9698C16.0137 22.1103 15.9568 22.2664 15.9317 22.429C15.9066 22.5915 15.9139 22.7575 15.9531 22.9172C15.9922 23.077 16.0626 23.2275 16.16 23.36C16.2737 23.5189 16.4238 23.6481 16.5979 23.7368C16.7719 23.8256 16.9647 23.8713 17.16 23.87C17.4225 23.8686 17.6779 23.7847 17.89 23.63C19.4554 22.486 20.7268 20.9867 21.5998 19.2556C22.4729 17.5244 22.9225 15.6107 22.9117 13.6719C22.901 11.7331 22.4302 9.82448 21.5381 8.10307C20.6459 6.38166 19.3579 4.8966 17.78 3.77H17.79Z"
              fill="black" />
    </svg>
</button>
<button type="button"
        aria-label="Redo"
        class="map-toolbar__control"
        [disabled]="status === EditorStatus.Saving || !canRedo"
        (click)="redo()">
    <span class="tooltip">Redo</span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.61 0.16C22.5176 0.122576 22.4163 0.113051 22.3185 0.132601C22.2208 0.152151 22.1309 0.199919 22.06 0.27L19.47 2.86C19.4341 2.89934 19.3861 2.92561 19.3336 2.93466C19.2811 2.94371 19.227 2.93504 19.18 2.91C17.1423 1.84867 14.8521 1.36757 12.5595 1.51923C10.267 1.6709 8.06017 2.44948 6.18 3.77C4.60379 4.89899 3.31806 6.38599 2.42855 8.10873C1.53903 9.83146 1.07113 11.7407 1.06332 13.6796C1.05551 15.6184 1.50802 17.5313 2.38362 19.2612C3.25923 20.991 4.53293 22.4883 6.1 23.63C6.3121 23.7847 6.5675 23.8686 6.83 23.87C7.02534 23.8713 7.21812 23.8256 7.39214 23.7368C7.56616 23.6481 7.71632 23.5189 7.83 23.36C7.92744 23.2275 7.99777 23.077 8.03694 22.9172C8.07612 22.7575 8.08337 22.5915 8.05829 22.4289C8.03321 22.2664 7.97628 22.1103 7.89077 21.9698C7.80526 21.8293 7.69286 21.707 7.56 21.61C6.31425 20.6996 5.30261 19.5063 4.60839 18.1284C3.91418 16.7505 3.55725 15.2273 3.56702 13.6844C3.57678 12.1415 3.95296 10.623 4.66456 9.25396C5.37616 7.88492 6.40283 6.70452 7.66 5.81C9.02103 4.85454 10.5989 4.25358 12.2506 4.06152C13.9024 3.86945 15.576 4.09233 17.12 4.71C17.1592 4.72488 17.1942 4.74901 17.2221 4.78036C17.25 4.8117 17.2698 4.84932 17.28 4.89C17.2869 4.9296 17.2841 4.97027 17.2719 5.00856C17.2597 5.04686 17.2385 5.08166 17.21 5.11L15.37 7C15.2976 7.0694 15.2482 7.15935 15.2285 7.2577C15.2088 7.35606 15.2198 7.45809 15.26 7.55C15.2975 7.64131 15.3612 7.71947 15.4431 7.77464C15.5249 7.82981 15.6213 7.85951 15.72 7.86H22.42C22.5518 7.85744 22.6775 7.80394 22.7707 7.71072C22.8639 7.6175 22.9174 7.49181 22.92 7.36V0.62C22.9182 0.521588 22.888 0.425791 22.833 0.344168C22.778 0.262545 22.7006 0.198583 22.61 0.16Z"
              fill="black" />
    </svg>
</button>
<span class="map-toolbar__divider"></span>
<ng-container *ngIf="mode === EditorMode.EDIT">
    <button type="button"
            aria-label="Delete Selected"
            class="map-toolbar__control"
            [disabled]="status === EditorStatus.Saving || !hasSelection"
            (click)="onDeleteButtonClick()">
        <span class="tooltip">Delete</span>
        <img src="/assets/images/trash-bin_20x20.svg#black" alt="Delete Selected">
    </button>
    <span class="map-toolbar__divider"></span>
</ng-container>
