<div class="header">
    <header>3D Settings</header>
    <hr />
    <div class="header__description">
        Control the opacity of Walls and Room Extrusions. The rendering on the
        Map works best if either one is set to 1.
    </div>
</div>
<form [formGroup]="settings3DForm" class="body">
        <label class="mi-label">
            Extrusion opacity
            <input
                formControlName="extrusionOpacity"
                type="number"
                class="mi-input"
                min="0"
                max="1"
                step="0.1"
            />
        </label>
        <div class="body__errors">
            <small role="alert" class="mi-form__error"
            *ngIf="getFormControl(settings3DForm, 'extrusionOpacity').hasError('min')
                || getFormControl(settings3DForm, 'extrusionOpacity').hasError('max')
                || getFormControl(settings3DForm, 'extrusionOpacity').hasError('required')">
                Extrusion opacity must be a number betweeen 0 and 1.
            </small>
        </div>
        <label class="mi-label">
            Wall opacity
            <input
                formControlName="wallOpacity"
                type="number"
                class="mi-input"
                min="0"
                max="1"
                step="0.1"
            />
        </label>
        <div class="body__errors">
            <small role="alert" class="mi-form__error"
            *ngIf="getFormControl(settings3DForm, 'wallOpacity').hasError('min')
                || getFormControl(settings3DForm, 'wallOpacity').hasError('max')
                || getFormControl(settings3DForm, 'wallOpacity').hasError('required')">
                Wall opacity must be a number betweeen 0 and 1.
            </small>
        </div>
</form>

<panel-footer *ngIf="settings3DForm.dirty" text="You have unsaved changes.">
    <button type="button" class="mi-button mi-button--link" (click)="onDiscard()">Discard</button>
    <button type="button" class="mi-button mi-button--primary" [disabled]="settings3DForm.invalid" (click)="onSubmit()">Save and close</button>
</panel-footer>
