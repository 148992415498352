import { DisplayRule } from '../../app/locations/location.model';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';
import ImageCache from '../../app/adapter/MapViewState/ImageCache';

export abstract class Model2DViewModelProperties implements ViewModelProperties {
    bearing: number;
    featureClass: FeatureClass;
    fillOpacity: number;
    heightMeters: number;
    icon: HTMLImageElement;
    src: string;
    originalId: string;
    originalType: MapsIndoorsData;
    sortKey: number;
    strokeOpacity: number;
    strokeWidth: number;
    widthMeters: number;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a Model2DViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {DisplayRule} displayRule
     * @param {number} sortKey
     * @returns {Promise<Model2DViewModelProperties>}
     */
    static async create(id: string, displayRule: DisplayRule, sortKey: number, geometry: GeoJSON.Point): Promise<Model2DViewModelProperties> {
        const icon = await ImageCache.get(displayRule.model2D.model);
        const modelScale = (displayRule.model2D.heightMeters / (icon.naturalHeight * (Math.cos(geometry.coordinates[1] * Math.PI / 180.0) * 0.019)));

        return await Promise.resolve({
            bearing: displayRule.model2D.bearing,
            featureClass: FeatureClass.MODEL2D,
            fillOpacity: 0,
            heightMeters: displayRule.model2D.heightMeters,
            icon: icon,
            src: icon.src,
            originalId: id,
            originalType: MapsIndoorsData.Location,
            sortKey: SortKey.MODEL2D + sortKey,
            strokeOpacity: 0,
            strokeWidth: 0,
            widthMeters: displayRule.model2D.widthMeters,
            modelScale: modelScale,
            zoomRange: [displayRule?.model2D.zoomFrom, displayRule?.model2D.zoomTo]
        });
    }
}

export class Model2DViewModel implements MapViewModel {
    public readonly id: string;
    public readonly type = 'Feature';
    public geometry: GeoJSON.Point;
    public readonly properties: Model2DViewModelProperties;

    private constructor(id: string, geometry: GeoJSON.Point, properties: Model2DViewModelProperties) {
        this.id = `MODEL2D:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory function to create a Model2DViewModel.
     *
     * @static
     * @param {string} id
     * @param {GeoJSON.Point} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @returns {Model2DViewModel}
     * @memberof Model2DViewModel
     */
    public static async create(id: string, geometry: GeoJSON.Point, displayRule: DisplayRule, index: number): Promise<Model2DViewModel> {
        const properties = await Model2DViewModelProperties.create(id, displayRule, index, geometry);
        const viewModel = new Model2DViewModel(id, geometry, properties);

        return viewModel;
    }
}