<form class="address-form" [formGroup]="addressForm">
    <div class="address-form__line">
        <label class="mi-label">
            Street Name 1
            <input formControlName="street1" class="mi-input" type="text" placeholder="Enter Street name" />
        </label>

        <label class="mi-label">
            Street Name 2
            <input formControlName="street2" class="mi-input" type="text" placeholder="Enter Street name" />
        </label>
    </div>

    <div class="address-form__line">
        <label class="mi-label">
            City
            <input formControlName="city" class="mi-input" type="text" placeholder="Enter City" />
        </label>

        <label class="mi-label">
            Postal Code
            <input formControlName="postalCode" class="mi-input" type="text" placeholder="Enter Postal Code" />
        </label>
    </div>

    <div class="address-form__line">
        <label class="mi-label">
            Region
            <input formControlName="region" class="mi-input" type="text" placeholder="Enter Region" />
        </label>

        <label class="mi-label">
            Country
            <input formControlName="country" class="mi-input" type="text" placeholder="Enter Country" />
        </label>
    </div>
</form>