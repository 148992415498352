import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { RegexPatterns } from '../../enums';

@Component({
    selector: 'color-picker-input',
    templateUrl: './color-picker-input.component.html',
    styleUrls: ['./color-picker-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ColorPickerInputComponent),
            multi: true,
        }
    ]
})
export class ColorPickerInputComponent implements OnInit, Validator {
    @Input() isLocked: boolean;

    constructor() { }

    public onChange: (value: string | null) => void = () => { };
    public onTouch: () => void = () => { };

    public colorPickerFormControl = new UntypedFormControl('#000000', [
        Validators.required,
        Validators.maxLength(7),
        Validators.pattern(RegexPatterns.HexColor)]
    );

    /**
     * Getter for the color-picker input disabled attribute.
     *
     * @readonly
     * @type {('' | null)}
     */
    public get colorPickerInputDisabled(): '' | null {
        return this.isLocked ? '' : null;
    }

    /**
     * NgOnint.
     */
    ngOnInit(): void {
        this.colorPickerFormControl.valueChanges.subscribe((newVal) => {
            this.onChange(newVal);
        });
    }

    /**
     * WriteValue.
     *
     * @param {any} value
     */
    writeValue(value: any): void {
        this.colorPickerFormControl.patchValue(value, { emitEvent: false });
    }

    /**
     * RegisterOnChange.
     *
     * @param {Function} fn
     */
    registerOnChange(fn: (value: string | null) => void): void {
        this.onChange = fn;
    }

    /**
     * RegisterOnTouched.
     *
     * @param {Function} fn
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Method that performs synchronous validation against the provided control.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return this.colorPickerFormControl.invalid ? { invalid: true } : null;
    }

    /**
     * SetDisabledState.
     *
     * @param {boolean} isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.colorPickerFormControl.disable() : this.colorPickerFormControl.enable();
    }
}