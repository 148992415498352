<form [formGroup]="displayRuleEditorForm">
    <navigatable-section header="General">
        <div rightAlignedHeader>
            <save-button *ngIf="showSaveToTypeButton && areFormControlsEnabled(displayRuleSections.general)" (click)="onSaveToType(displayRuleSections.general)" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
        </div>
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'visible').disabled"
            (click)="unlockSetting($event, 'visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl(displayRuleEditorForm, 'visible').disabled"
                (toggled)="toggleInheritance('visible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="visible">Visibility</label>
                <info-tooltip class="tooltip"
                    text="Controls whether all Locations of this Type are visible on the map or not. If set to Off, the Icon, Label, Polygon, and 3D information are not visible.">
                </info-tooltip>
            </div>
            <div class="setting__control">
                <input id="visible" class="mi-toggle" type="checkbox" formControlName="visible" />
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'visible').enabled" (click)="onSaveToType(['visible'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>
    </navigatable-section>

    <div class="message message--warning" role="note" *ngIf="!isMainDisplayRule">
        Your app code needs to use Display Rules, not hardcoded overrides, for your style changes here to apply.<br /><a
            href="https://docs.mapsindoors.com/content/map/map-styling/display-rules/" target="_blank">Read more about
            Display Rules in our docs</a>.
    </div>

    <navigatable-section header="Icon"
        documentationLink="https://docs.mapsindoors.com/content/map/map-styling/display-rules/#icon">
        <div rightAlignedHeader>
            <save-button *ngIf="showSaveToTypeButton && areFormControlsEnabled(displayRuleSections.icon)" (click)="onSaveToType(displayRuleSections.icon)" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
        </div>
        <!-- Icon visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'iconVisible').disabled"
            (click)="unlockSetting($event, 'iconVisible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'iconVisible').disabled" (toggled)="toggleInheritance('iconVisible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="icon-visible">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the Icon is visible on the map or not.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="icon-visible" class="mi-toggle" type="checkbox" formControlName="iconVisible" />
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'iconVisible').enabled" (click)="onSaveToType(['iconVisible'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Icon zoom from -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'zoomFrom').disabled"
            (click)="unlockSetting($event, 'zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl(displayRuleEditorForm, 'zoomFrom').disabled"
                (toggled)="toggleInheritance('zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="icon-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Icon is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="icon-zoom-from" class="mi-input" type="number" formControlName="zoomFrom" [max]="maxZoomLevel" [min]="minZoomLevel" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'zoomFrom').enabled" (click)="onSaveToType(['zoomFrom'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Icon zoom to -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'zoomTo').disabled"
            (click)="unlockSetting($event, 'zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl(displayRuleEditorForm, 'zoomTo').disabled"
                (toggled)="toggleInheritance('zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="icon-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Icon is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <zoom-to-input inputId="icon-zoom-to" [isLocked]="getFormControl(displayRuleEditorForm, 'zoomTo').disabled" formControlName="zoomTo" [max]="maxZoomLevel" [min]="minZoomLevel"></zoom-to-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'zoomTo').enabled" (click)="onSaveToType(['zoomTo'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'zoomTo').hasError('min')">
                    "Zoom to" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Media Library for Icon selection -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'icon').disabled"
            (click)="unlockSetting($event, 'icon')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl(displayRuleEditorForm, 'icon').disabled"
                (toggled)="toggleInheritance('icon', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Icon</label>
                <info-tooltip class="tooltip" text="Controls which Icon is shown on the map."></info-tooltip>
            </div>

            <div class="setting__control setting__control--icon-manager">
                <img [src]="getFormControl(displayRuleEditorForm, 'icon').value" />
                <button type="button" [disabled]="getFormControl(displayRuleEditorForm, 'icon').disabled"
                    class="mi-button mi-button--small mi-button--base" (click)="openMediaLibraryForIcon()">Open Media
                    Library</button>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'icon').enabled" (click)="onSaveToType(['icon', 'imageSize.width', 'imageSize.height', 'imageScale'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="Label"
        documentationLink="https://docs.mapsindoors.com/content/map/map-styling/display-rules/#label">
        <div rightAlignedHeader>
            <save-button *ngIf="showSaveToTypeButton && areFormControlsEnabled(displayRuleSections.label)" (click)="onSaveToType(displayRuleSections.label)" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
        </div>
        <!-- Label visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelVisible').disabled"
            (click)="unlockSetting($event, 'labelVisible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelVisible').disabled"
                (toggled)="toggleInheritance('labelVisible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="label-visible">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the Label is visible on the Map or not.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="label-visible" class="mi-toggle" type="checkbox" formControlName="labelVisible" />
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelVisible').enabled" (click)="onSaveToType(['labelVisible'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Label zoom from -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelZoomFrom').disabled"
            (click)="unlockSetting($event, 'labelZoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelZoomFrom').disabled"
                (toggled)="toggleInheritance('labelZoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="label-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Label is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="label-zoom-from" class="mi-input" type="number" formControlName="labelZoomFrom" [max]="maxZoomLevel" [min]="minZoomLevel" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelZoomFrom').enabled" (click)="onSaveToType(['labelZoomFrom'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'labelZoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'labelZoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'labelZoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'labelZoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Label zoom to -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelZoomTo').disabled"
            (click)="unlockSetting($event, 'labelZoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelZoomTo').disabled" (toggled)="toggleInheritance('labelZoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="label-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Label is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <zoom-to-input inputId="label-zoom-to" [isLocked]="getFormControl(displayRuleEditorForm, 'labelZoomTo').disabled" formControlName="labelZoomTo" [max]="maxZoomLevel" [min]="minZoomLevel"></zoom-to-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelZoomTo').enabled" (click)="onSaveToType(['labelZoomTo'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>            
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'labelZoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'labelZoomTo').hasError('min')">
                    "Zoom to" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'labelZoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'labelZoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Label name -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'label').disabled"
            (click)="unlockSetting($event, 'label')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl(displayRuleEditorForm, 'label').disabled"
                (toggled)="toggleInheritance('label', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Template</label>
                <info-tooltip class="tooltip" text="Controls the information the Label should contain."></info-tooltip>
            </div>

            <div class="setting__control">
                <!-- TODO: Make sure that disabled is needed here. -->
                <mi-dropdown #labelNameDropdown [disabled]="getFormControl(displayRuleEditorForm, 'label').disabled"
                    (change)="onLabelFormatDropdownChange($event)"></mi-dropdown>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'label').enabled" (click)="onSaveToType(['label'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Label max width -->
        <div class="setting label-max-width" lockedDisplayRuleHandler
            [isLocked]="getFormControl(displayRuleEditorForm, 'labelMaxWidth').disabled" (click)="unlockSetting($event, 'labelMaxWidth')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelMaxWidth').disabled" (toggled)="toggleLabelMaxWidthInheritance($event);">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="label-max-width-visible">Max width</label>
                <info-tooltip class="tooltip" text="Controls how wide a Label can be before forcing a line-break.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <div class="toggle-container">
                    <input id="label-max-width-visible" type="checkbox" class="mi-toggle"
                        [disabled]="getFormControl(displayRuleEditorForm, 'labelMaxWidth').disabled" [checked]="labelMaxWidthVisible" #numberInputs
                        (change)="toggleLabelMaxWidthVisible()" />
                    <span>Specify maximum Label width.</span>
                    <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelMaxWidth').enabled" (click)="onSaveToType(['labelMaxWidth'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
                </div>

                <input *ngIf="labelMaxWidthVisible" class="mi-input" type="number" min="1"
                    [value]="getFormControl(displayRuleEditorForm, 'labelMaxWidth').value" (change)="setlabelMaxWidthValue($event)">
                <input #labelMaxWidthVisibleInput type="hidden" formControlName="labelMaxWidth">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'labelMaxWidth').hasError('min') ||  getFormControl(displayRuleEditorForm, 'labelMaxWidth').hasError('pattern')">
                    Label maximum width must be above 0. No decimal numbers.
                </small>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="Label Style" formGroupName="labelStyle" *ngIf="isMapboxEnabled" documentationLink="https://docs.mapsindoors.com/products/cms/display-rules#label-style">
        <div rightAlignedHeader>
            <save-button *ngIf="showSaveToTypeButton && areFormControlsEnabled(displayRuleSections.labelStyle)" (click)="onSaveToType(displayRuleSections.labelStyle)" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
        </div>
        <!-- Label type -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelType').disabled"
            (click)="unlockSetting($event, 'labelType')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl(displayRuleEditorForm, 'labelType').disabled"
                (toggled)="toggleInheritance('labelType', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Label Type</label>
                <info-tooltip class="tooltip" text="Controls the label type."></info-tooltip>
            </div>

            <div class="setting__control">
                <mi-dropdown #labelTypeDropdown [disabled]="getFormControl(displayRuleEditorForm, 'labelType').disabled"
                    (change)="onLabelTypeFormatDropdownChange($event)"></mi-dropdown>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelType').enabled" (click)="onSaveToType(['labelType'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Label text size -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelStyle.textSize').disabled"
            (click)="unlockSetting($event, 'labelStyle.textSize')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelStyle.textSize').disabled"
                (toggled)="toggleInheritance('labelStyle.textSize', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Text Size</label>
                <info-tooltip class="tooltip" text="Controls the text label font size."></info-tooltip>
            </div>

            <div class="setting__control">
                <input class="mi-input" type="number" formControlName="textSize" min="0" max="255" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelStyle.textSize').enabled" (click)="onSaveToType(['labelStyle.textSize'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'labelStyle.textSize').hasError('required')">
                    "Text Size" cannot be empty.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'labelStyle.textSize').hasError('min')">
                    "Text Size" value must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'labelStyle.textSize').hasError('max')">
                    "Text Size" value cannot be larger than 255.
                </small>
            </div>
        </div>

        <!-- Label text color -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelStyle.textColor').disabled"
            (click)="unlockSetting($event, 'labelStyle.textColor')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelStyle.textColor').disabled"
                (toggled)="toggleInheritance('labelStyle.textColor', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Text Color</label>
                <info-tooltip class="tooltip" text="Controls the label text color."></info-tooltip>
            </div>

            <div class="setting__control">
                <color-picker-input [isLocked]="getFormControl(displayRuleEditorForm, 'labelStyle.textColor').disabled"
                    formControlName="textColor"></color-picker-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelStyle.textColor').enabled" (click)="onSaveToType(['labelStyle.textColor'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'labelStyle.textColor').hasError('pattern')">
                    Text color must be represented in hex value.
                </small>
            </div>
        </div>

        <!-- Label text opacity -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelStyle.textOpacity').disabled"
            (click)="unlockSetting($event, 'labelStyle.textOpacity')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelStyle.textOpacity').disabled"
                (toggled)="toggleInheritance('labelStyle.textOpacity', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Text Opacity</label>
                <info-tooltip class="tooltip" text="Controls the label text opacity."></info-tooltip>
            </div>

            <div class="setting__control">
                <input class="mi-input" type="number" step="0.1" formControlName="textOpacity" min="0" max="1" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelStyle.textOpacity').enabled" (click)="onSaveToType(['labelStyle.textOpacity'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'labelStyle.textOpacity').hasError('min') || getFormControl(displayRuleEditorForm, 'labelStyle.textOpacity').hasError('max') || getFormControl(displayRuleEditorForm, 'labelStyle.textOpacity').hasError('required')">
                    Label text opacity must be a number between 0 and 1.
                </small>
            </div>
        </div>

        <!-- Label halo color -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelStyle.haloColor').disabled"
            (click)="unlockSetting($event, 'labelStyle.haloColor')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelStyle.haloColor').disabled"
                (toggled)="toggleInheritance('labelStyle.haloColor', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Halo Color</label>
                <info-tooltip class="tooltip" text="Controls the label halo color."></info-tooltip>
            </div>

            <div class="setting__control">
                <color-picker-input [isLocked]="getFormControl(displayRuleEditorForm, 'labelStyle.haloColor').disabled"
                    formControlName="haloColor"></color-picker-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelStyle.haloColor').enabled" (click)="onSaveToType(['labelStyle.haloColor'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'labelStyle.haloColor').hasError('pattern')">
                    Halo color must be represented in hex value.
                </small>
            </div>
        </div>

        <!-- Label halo width -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelStyle.haloWidth').disabled"
            (click)="unlockSetting($event, 'labelStyle.haloWidth')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelStyle.haloWidth').disabled"
                (toggled)="toggleInheritance('labelStyle.haloWidth', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Halo Width</label>
                <info-tooltip class="tooltip" text="Controls the label halo width."></info-tooltip>
            </div>

            <div class="setting__control">
                <input class="mi-input" type="number" step="1" formControlName="haloWidth" min="0" max="64" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelStyle.haloWidth').enabled" (click)="onSaveToType(['labelStyle.haloWidth'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'labelStyle.haloWidth').hasError('min') || getFormControl(displayRuleEditorForm, 'labelStyle.haloWidth').hasError('max') || getFormControl(displayRuleEditorForm, 'labelStyle.haloWidth').hasError('required')">
                    Halo width must be a number between 0 and 64.
                </small>
            </div>
        </div>

        <!-- Label halo blur -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelStyle.haloBlur').disabled"
            (click)="unlockSetting($event, 'labelStyle.haloBlur')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelStyle.haloBlur').disabled"
                (toggled)="toggleInheritance('labelStyle.haloBlur', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Halo Blur</label>
                <info-tooltip class="tooltip" text="Controls the label halo blur."></info-tooltip>
            </div>

            <div class="setting__control">
                <input class="mi-input" type="number" step="1" formControlName="haloBlur" min="0" max="64" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelStyle.haloBlur').enabled" (click)="onSaveToType(['labelStyle.haloBlur'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'labelStyle.haloBlur').hasError('min') || getFormControl(displayRuleEditorForm, 'labelStyle.haloBlur').hasError('max') || getFormControl(displayRuleEditorForm, 'labelStyle.haloWidth').hasError('required')">
                    Halo blur must be a number between 0 and 64.
                </small>
            </div>
        </div>

        <!-- Flat Label Bearing -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'labelStyle.bearing').disabled"
            (click)="unlockSetting($event, 'labelStyle.bearing')" *ngIf="!isFloatingLabelEnabled && !isGraphicLabelEnabled">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelStyle.bearing').disabled"
                (toggled)="toggleInheritance('labelStyle.bearing', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="flatLabel-bearing">Rotation</label>
                <info-tooltip class="tooltip" text="Controls the flat label rotation value (bearing)."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="°"
                    [ngClass]="{'disabled' :getFormControl(displayRuleEditorForm, 'labelStyle.bearing').disabled }">
                    <input id="flatLabel-bearing" class="mi-input" type="number" step="any" min="0" max="360"
                        formControlName="bearing" #numberInputs>
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelStyle.bearing').enabled" (click)="onSaveToType(['labelStyle.bearing'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'labelStyle.bearing').hasError('min') || getFormControl(displayRuleEditorForm, 'labelStyle.bearing').hasError('isBiggerThanLimit') || getFormControl(displayRuleEditorForm, 'labelStyle.bearing').hasError('required')">
                    Rotation value (bearing) must be between 0 and 360.
                </small>
            </div>
        </div>

        <!-- Graphic Label media Library -->
        <div class="setting" lockedDisplayRuleHandler formGroupName="graphic" *ngIf="isGraphicLabelEnabled"
            [isLocked]="getFormControl(displayRuleEditorForm, 'labelStyle.graphic.backgroundImage').disabled" (click)="unlockSetting($event, 'labelStyle.graphic.backgroundImage')">
            <lock-button class="setting__lock setting__lock--full-height" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'labelStyle.graphic.backgroundImage').disabled"
                (toggled)="toggleInheritance('labelStyle.graphic.backgroundImage', $event)">
            </lock-button>
            <div class="setting__label setting__label--baseline">
                <label class="mi-label">Graphic Label</label>
                <info-tooltip class="tooltip" text="Controls which graphic label is used.">
                </info-tooltip>
            </div>

            <div *ngIf="getFormControl(displayRuleEditorForm, 'labelStyle.graphic.backgroundImage').value" class="setting__control setting__control--flex">
                <div class="graphicLabel">
                    <div class="graphicLabel__preview">
                        <img [src]="graphicLabelPreview?.src" class="graphicLabel__preview-label" #graphicLabel_preview />
                        <p class="graphicLabel__name">{{ graphicLabelPreview?.name }}</p>
                    </div>
                    <input type="hidden" formControlName="backgroundImage">

                    <div class="graphicLabel__buttons">
                        <button [disabled]="getFormControl(displayRuleEditorForm, 'labelStyle.graphic.backgroundImage').disabled"
                            (click)="openMediaLibraryForGraphicLabel()" class="mi-button mi-button--base">
                            Change
                        </button>
                        <button *ngIf="isMainDisplayRule" class="mi-button mi-button--remove" (click)="removeGraphicLabel()">
                            Remove
                        </button>
                    </div>
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'labelStyle.graphic.backgroundImage').enabled" (click)="onSaveToType(['labelStyle.graphic.backgroundImage'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__control" *ngIf="!getFormControl(displayRuleEditorForm, 'labelStyle.graphic.backgroundImage').value">
                <button type="button" [disabled]="getFormControl(displayRuleEditorForm, 'labelStyle.graphic.backgroundImage').disabled" (click)="openMediaLibraryForGraphicLabel()" class="mi-button mi-button--base">
                    Add Graphic Label
                </button>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="Polygon" formGroupName="polygon" *ngIf="isGeometrySettingsVisible"
        documentationLink="https://docs.mapsindoors.com/content/map/map-styling/display-rules/#polygon">
        <div rightAlignedHeader>
            <save-button *ngIf="showSaveToTypeButton && areFormControlsEnabled(displayRuleSections.polygon)" (click)="onSaveToType(displayRuleSections.polygon)" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
        </div>
        <!-- Polygon visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.visible').disabled"
            (click)="unlockSetting($event, 'polygon.visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'polygon.visible').disabled"
                (toggled)="toggleInheritance('polygon.visible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-visible">Visibility</label>
                <info-tooltip class="tooltip"
                    text="Controls whether the Polygon information is visible on the map or not."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-visible" class="mi-toggle" type="checkbox" formControlName="visible" />
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'polygon.visible').enabled" (click)="onSaveToType(['polygon.visible'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Polygon zoom from -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.zoomFrom').disabled"
            (click)="unlockSetting($event, 'polygon.zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'polygon.zoomFrom').disabled"
                (toggled)="toggleInheritance('polygon.zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Polygon is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-zoom-from" class="mi-input" type="number" formControlName="zoomFrom" [max]="maxZoomLevel" [min]="minZoomLevel" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'polygon.zoomFrom').enabled" (click)="onSaveToType(['polygon.zoomFrom'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'polygon.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'polygon.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'polygon.zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'polygon.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Polygon zoom to -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.zoomTo').disabled"
            (click)="unlockSetting($event, 'polygon.zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'polygon.zoomTo').disabled"
                (toggled)="toggleInheritance('polygon.zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Polygon is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <zoom-to-input inputId="polygon-zoom-to" [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.zoomTo').disabled" formControlName="zoomTo" [max]="maxZoomLevel" [min]="minZoomLevel"></zoom-to-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'polygon.zoomTo').enabled" (click)="onSaveToType(['polygon.zoomTo'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'polygon.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'polygon.zoomTo').hasError('min')">
                    "Zoom to" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'polygon.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'polygon.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Polygon stroke color -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.strokeColor').disabled"
            (click)="unlockSetting($event, 'polygon.strokeColor')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'polygon.strokeColor').disabled"
                (toggled)="toggleInheritance('polygon.strokeColor', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-stroke-color">Stroke color</label>
                <info-tooltip class="tooltip" text="Controls the stroke color of the Polygon."></info-tooltip>
            </div>

            <div class="setting__control">
                <color-picker-input [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.strokeColor').disabled" formControlName="strokeColor"></color-picker-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'polygon.strokeColor').enabled" (click)="onSaveToType(['polygon.strokeColor'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Polygon stroke width -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.strokeWidth').disabled"
            (click)="unlockSetting($event, 'polygon.strokeWidth')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'polygon.strokeWidth').disabled"
                (toggled)="toggleInheritance('polygon.strokeWidth', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-stroke-width">Stroke width</label>
                <info-tooltip class="tooltip" text="Controls the stroke width of the Polygon."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="px"
                    [ngClass]="{'disabled' :getFormControl(displayRuleEditorForm, 'polygon.strokeWidth').disabled }">
                    <input id="polygon-stroke-width" class="mi-input" type="number" min="0" formControlName="strokeWidth" #numberInputs>
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'polygon.strokeWidth').enabled" (click)="onSaveToType(['polygon.strokeWidth'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'polygon.strokeWidth').hasError('min') || getFormControl(displayRuleEditorForm, 'polygon.strokeWidth').hasError('pattern') || getFormControl(displayRuleEditorForm, 'polygon.strokeWidth').hasError('required')">
                    Stroke width must be 0 or above. No decimal numbers.
                </small>
            </div>
        </div>

        <!-- Polygon stroke opacity -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.strokeOpacity').disabled"
            (click)="unlockSetting($event, 'polygon.strokeOpacity')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'polygon.strokeOpacity').disabled"
                (toggled)="toggleInheritance('polygon.strokeOpacity', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-stroke-opacity">Stroke opacity</label>
                <info-tooltip class="tooltip" text="Controls the stroke opacity of the Polygon."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-stroke-opacity" class="mi-input" type="number" min="0" max="1" step="0.1"
                    formControlName="strokeOpacity" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'polygon.strokeOpacity').enabled" (click)="onSaveToType(['polygon.strokeOpacity'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'polygon.strokeOpacity').hasError('min') || getFormControl(displayRuleEditorForm, 'polygon.strokeOpacity').hasError('max') || getFormControl(displayRuleEditorForm, 'polygon.strokeOpacity').hasError('required')">
                    Stroke opacity must be a number between 0 and 1.
                </small>
            </div>
        </div>

        <!-- Polygon fill color -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.fillColor').disabled"
            (click)="unlockSetting($event, 'polygon.fillColor')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'polygon.fillColor').disabled"
                (toggled)="toggleInheritance('polygon.fillColor', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-fill-color">Fill color</label>
                <info-tooltip class="tooltip" text="Controls fill opacity of the Polygon."></info-tooltip>
            </div>

            <div class="setting__control">
                <color-picker-input [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.fillColor').disabled" formControlName="fillColor"></color-picker-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'polygon.fillColor').enabled" (click)="onSaveToType(['polygon.fillColor'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Polygon fill opacity -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'polygon.fillOpacity').disabled"
            (click)="unlockSetting($event, 'polygon.fillOpacity')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'polygon.fillOpacity').disabled"
                (toggled)="toggleInheritance('polygon.fillOpacity', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-fill-opacity">Fill opacity</label>
                <info-tooltip class="tooltip" text="Controls the fill opacity of the Polygon."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-fill-opacity" class="mi-input" type="number" min="0" max="1" step="0.1"
                    formControlName="fillOpacity" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'polygon.fillOpacity').enabled" (click)="onSaveToType(['polygon.fillOpacity'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'polygon.fillOpacity').hasError('min') || getFormControl(displayRuleEditorForm, 'polygon.fillOpacity').hasError('max') || getFormControl(displayRuleEditorForm, 'polygon.fillOpacity').hasError('required')">
                    Fill opacity must be between 0 and 1.
                </small>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="2D Model" *ngIf="model2DModuleEnabled" formGroupName="model2D">
        <div rightAlignedHeader>
            <save-button *ngIf="showSaveToTypeButton && areFormControlsEnabled(displayRuleSections.model2d)" (click)="onSaveToType(displayRuleSections.model2d)" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
        </div>
        <!-- 2D Model visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'model2D.visible').disabled"
            (click)="unlockSetting($event, 'model2D.visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl(displayRuleEditorForm, 'model2D.visible').disabled"
                (toggled)="toggleInheritance('model2D.visible', $event)">
            </lock-button>
            <div class="setting__label">
                <label class="mi-label" for="model2D-visible">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the 2D Model is visible on the map or not.">
                </info-tooltip>
            </div>
            <div class="setting__control">
                <input id="model2D-visible" class="mi-toggle" type="checkbox" formControlName="visible" />
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model2D.visible').enabled" (click)="onSaveToType(['model2D.visible'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- 2D Model Zoom From -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'model2D.zoomFrom').disabled"
            (click)="unlockSetting($event, 'model2D.zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model2D.zoomFrom').disabled"
                (toggled)="toggleInheritance('model2D.zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model2d-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the 2D Model is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="model2d-zoom-from" class="mi-input" type="number" formControlName="zoomFrom" [max]="maxZoomLevel" [min]="minZoomLevel" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model2D.zoomFrom').enabled" (click)="onSaveToType(['model2D.zoomFrom'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'model2D.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model2D.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model2D.zoomTo').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'model2D.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- 2D Model Zoom To -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'model2D.zoomTo').disabled"
            (click)="unlockSetting($event, 'model2D.zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model2D.zoomTo').disabled"
                (toggled)="toggleInheritance('model2D.zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model2d-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the 2D Model is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <zoom-to-input inputId="model2d-zoom-to" [isLocked]="getFormControl(displayRuleEditorForm, 'model2D.zoomTo').disabled" formControlName="zoomTo" [max]="maxZoomLevel" [min]="minZoomLevel"></zoom-to-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model2D.zoomTo').enabled" (click)="onSaveToType(['model2D.zoomTo'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'model2D.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model2D.zoomTo').hasError('min')">
                    "Zoom to" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model2D.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'model2D.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- 2D Model Media Library -->
        <div class="setting" lockedDisplayRuleHandler
            [isLocked]="getFormControl(displayRuleEditorForm, 'model2D.model').disabled" (click)="unlockSetting($event, 'model2D.model')">
            <lock-button class="setting__lock setting__lock--full-height" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model2D.model').disabled"
                (toggled)="toggleInheritance('model2D.model', $event)">
            </lock-button>
            <div class="setting__label setting__label--baseline">
                <label class="mi-label">2D Model</label>
                <info-tooltip class="tooltip" text="Controls which image is used as 2D Model on the map.">
                </info-tooltip>
            </div>

            <div *ngIf="getFormControl(displayRuleEditorForm, 'model2D.model').value" class="setting__control setting__control--flex">
                <div class="model2d">
                    <div class="model2d__preview">
                        <ngx-spinner name="model2d-spinner" [fullScreen]="false" type="ball-pulse"></ngx-spinner>
                        <img responsiveImageStyle class="model2d__preview-image" #model2d__preview [src]="model2DPreview?.src">
                        <p class="model2d__name">{{ model2DPreview?.name }}</p>
                    </div>
                    <input type="hidden" formControlName="model">

                    <div class="model2d__buttons">
                        <button [disabled]="getFormControl(displayRuleEditorForm, 'model2D.model').disabled"
                            (click)="openMediaLibraryFor2DModel()" class="mi-button mi-button--base">
                            Change
                        </button>
                        <button *ngIf="isMainDisplayRule" class="mi-button mi-button--remove" (click)="removeModel2D()">
                            Remove
                        </button>
                        <button *ngIf="isFit2DModelEnabled" class="mi-button mi-button--base" (click)="fitModel2D()">
                            Fit to Location
                        </button>
                    </div>

                    <div class="setting__group setting__group--top-spacing setting__group--align-items-end">
                        <div class="setting__control setting__control--direction-column">
                            <div class="setting__label setting__label--small-bottom-spacing">
                                <label class="mi-label mi-label--small" for="model2D-width">Width</label>
                                <info-tooltip class="tooltip tooltip--no-margin" text="Controls the width of the 2D model. Editing the width will change the height accordingly as well."></info-tooltip>
                            </div>
                            <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="m" [ngClass]="{disabled: getFormControl(displayRuleEditorForm, 'model2D.widthMeters').disabled}">
                                <input id="model2D-width" class="mi-input" type="number" step="0.01" formControlName="widthMeters" #numberInputs/>
                            </div>
                        </div>
                        <img class="setting__icon" src="/assets/images/link.svg" />
                        <div class="setting__control setting__control--direction-column">
                            <div class="setting__label setting__label--small-bottom-spacing">
                                <label class="mi-label mi-label--small" for="model2D-height">Height</label>
                                <info-tooltip class="tooltip tooltip--no-margin" text="Controls the height of the 2D model. Editing the height will change the width accordingly as well."></info-tooltip>
                            </div>
                            <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="m" [ngClass]="{disabled: getFormControl(displayRuleEditorForm, 'model2D.heightMeters').disabled}">
                                <input id="model2D-height" class="mi-input" type="number" step="0.01" formControlName="heightMeters" #numberInputs/>
                            </div>
                        </div>

                        <button [disabled]="model2DResetSizeDisabled()" (click)="reset2DModelOriginalSize()" class="mi-button mi-button--base">
                            Reset size
                        </button>
                    </div>
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model2D.model').enabled" (click)="onSaveToType(['model2D.model', 'model2D.widthMeters', 'model2D.heightMeters'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__control" *ngIf="!getFormControl(displayRuleEditorForm, 'model2D.model').value">
                <button type="button" [disabled]="getFormControl(displayRuleEditorForm, 'model2D.model').disabled" (click)="openMediaLibraryFor2DModel()" class="mi-button mi-button--base model2d__button">
                    Add 2D model
                </button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model2D.widthMeters').hasError('min') || getFormControl(displayRuleEditorForm, 'model2D.widthMeters').hasError('required')">
                    2D Model Width must be above 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model2D.heightMeters').hasError('min') || getFormControl(displayRuleEditorForm, 'model2D.heightMeters').hasError('pattern') || getFormControl(displayRuleEditorForm, 'model2D.heightMeters').hasError('required')">
                    2D Model Height must be above 0.
                </small>
            </div>
        </div>

        <!-- 2D Model Bearing (rotation) -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'model2D.bearing').disabled"
            (click)="unlockSetting($event, 'model2D.bearing')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model2D.bearing').disabled"
                (toggled)="toggleInheritance('model2D.bearing', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="2DModel-bearing">Rotation</label>
                <info-tooltip class="tooltip" text="Controls the 2D Model rotation value (bearing)."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="°"
                    [ngClass]="{'disabled' :getFormControl(displayRuleEditorForm, 'model2D.bearing').disabled }">
                    <input id="2DModel-bearing" class="mi-input" type="number" step="any" min="0" max="359" formControlName="bearing" #numberInputs>
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model2D.bearing').enabled" (click)="onSaveToType(['model2D.bearing'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'model2D.bearing').hasError('min') || getFormControl(displayRuleEditorForm, 'model2D.bearing').hasError('isBiggerThanLimit') || getFormControl(displayRuleEditorForm, 'model2D.bearing').hasError('required')">
                    2D model rotation value (bearing) must be between 0 and 359.
                </small>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="3D Walls" formGroupName="walls"
        *ngIf="isGeometrySettingsVisible && wallsModuleEnabled && is3DWallsSectionVisible">
        <div rightAlignedHeader>
            <save-button *ngIf="showSaveToTypeButton && areFormControlsEnabled(displayRuleSections.walls)" (click)="onSaveToType(displayRuleSections.walls)" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
        </div>
        <!-- Walls visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'walls.visible').disabled"
            (click)="unlockSetting($event, 'walls.visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'walls.visible').disabled"
                (toggled)="toggleInheritance('walls.visible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="walls-visible">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the 3D Walls are visible on the map or not.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="walls-visible" class="mi-toggle" type="checkbox" formControlName="visible" />
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'walls.visible').enabled" (click)="onSaveToType(['walls.visible'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Walls zoom from -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'walls.zoomFrom').disabled"
            (click)="unlockSetting($event, 'walls.zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'walls.zoomFrom').disabled"
                (toggled)="toggleInheritance('walls.zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="walls-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Walls is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="walls-zoom-from" class="mi-input" type="number" formControlName="zoomFrom" [max]="maxZoomLevel" [min]="minZoomLevel" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'walls.zoomFrom').enabled" (click)="onSaveToType(['walls.zoomFrom'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'walls.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'walls.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'walls.zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'walls.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Walls zoom to -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'walls.zoomTo').disabled"
            (click)="unlockSetting($event, 'walls.zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'walls.zoomTo').disabled"
                (toggled)="toggleInheritance('walls.zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="walls-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Walls are visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <zoom-to-input inputId="walls-zoom-to" [isLocked]="getFormControl(displayRuleEditorForm, 'walls.zoomTo').disabled" formControlName="zoomTo" [max]="maxZoomLevel" [min]="minZoomLevel"></zoom-to-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'walls.zoomTo').enabled" (click)="onSaveToType(['walls.zoomTo'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'walls.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'walls.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'walls.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Walls color -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'walls.color').disabled"
            (click)="unlockSetting($event, 'walls.color')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'walls.color').disabled" (toggled)="toggleInheritance('walls.color', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="walls-color">Walls color</label>
                <info-tooltip class="tooltip" text="Controls the color of the 3D Walls."></info-tooltip>
            </div>

            <div class="setting__control">
                <color-picker-input [isLocked]="getFormControl(displayRuleEditorForm, 'walls.color').disabled" formControlName="color"></color-picker-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'walls.color').enabled" (click)="onSaveToType(['walls.color'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Wall height -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'walls.height').disabled"
            (click)="unlockSetting($event, 'walls.height')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'walls.height').disabled"
                (toggled)="toggleInheritance('walls.height', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="walls-height">Wall height</label>
                <info-tooltip class="tooltip" text="Controls the height of the 3D Walls (measured in meters).">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="m"
                    [ngClass]="{'disabled' :getFormControl(displayRuleEditorForm, 'walls.height').disabled }">
                    <input id="walls-height" class="setting__control mi-input" type="number" step="0.1" min="0"
                        formControlName="height" #numberInputs>
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'walls.height').enabled" (click)="onSaveToType(['walls.height'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'walls.height').hasError('min') || getFormControl(displayRuleEditorForm, 'walls.height').hasError('required')">
                    Wall height must be 0 or above.
                </small>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="3D Room Extrusion" formGroupName="extrusion"
        *ngIf="isGeometrySettingsVisible && extrusionsModuleEnabled">
        <div rightAlignedHeader>
            <save-button *ngIf="showSaveToTypeButton && areFormControlsEnabled(displayRuleSections.extrusion)" (click)="onSaveToType(displayRuleSections.extrusion)" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
        </div>
        <!-- Extrusion visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'extrusion.visible').disabled"
            (click)="unlockSetting($event, 'extrusion.visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'extrusion.visible').disabled"
                (toggled)="toggleInheritance('extrusion.visible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="extrusion-visible">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the Room is extruded on the Map or not.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="extrusion-visible" class="mi-toggle" type="checkbox" formControlName="visible" />
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'extrusion.visible').enabled" (click)="onSaveToType(['extrusion.visible'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Extrusion zoom from -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'extrusion.zoomFrom').disabled"
            (click)="unlockSetting($event, 'extrusion.zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'extrusion.zoomFrom').disabled"
                (toggled)="toggleInheritance('extrusion.zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="extrusion-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Extrusion is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="extrusion-zoom-from" class="mi-input" type="number" formControlName="zoomFrom" [max]="maxZoomLevel" [min]="minZoomLevel" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'extrusion.zoomFrom').enabled" (click)="onSaveToType(['extrusion.zoomFrom'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'extrusion.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'extrusion.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'extrusion.zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'extrusion.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Extrusion zoom to -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'extrusion.zoomTo').disabled"
            (click)="unlockSetting($event, 'extrusion.zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'extrusion.zoomTo').disabled"
                (toggled)="toggleInheritance('extrusion.zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="extrusion-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the extrusion is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <zoom-to-input inputId="extrusion-zoom-to" [isLocked]="getFormControl(displayRuleEditorForm, 'extrusion.zoomTo').disabled" formControlName="zoomTo" [max]="maxZoomLevel" [min]="minZoomLevel"></zoom-to-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'extrusion.zoomTo').enabled" (click)="onSaveToType(['extrusion.zoomTo'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'extrusion.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'extrusion.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'extrusion.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Extrusion color -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'extrusion.color').disabled"
            (click)="unlockSetting($event, 'extrusion.color')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'extrusion.color').disabled"
                (toggled)="toggleInheritance('extrusion.color', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="extrusion-color">Extrusion color</label>
                <info-tooltip class="tooltip" text="Controls color of the 3D Room extrusion."></info-tooltip>
            </div>

            <div class="setting__control">
                <color-picker-input [isLocked]="getFormControl(displayRuleEditorForm, 'extrusion.color').disabled" formControlName="color"></color-picker-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'extrusion.color').enabled" (click)="onSaveToType(['extrusion.color'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- Extrusion height -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'extrusion.height').disabled"
            (click)="unlockSetting($event, 'extrusion.height')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'extrusion.height').disabled"
                (toggled)="toggleInheritance('extrusion.height', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="extrusion-height">Extrusion height</label>
                <info-tooltip class="tooltip" text="Controls height of the 3D Room extrusion (measured in meters).">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="m"
                    [ngClass]="{'disabled' :getFormControl(displayRuleEditorForm, 'extrusion.height').disabled }">
                    <input id="extrusion-height" class="mi-input" type="number" step="0.1" min="0" formControlName="height" #numberInputs>
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'extrusion.height').enabled" (click)="onSaveToType(['extrusion.height'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'extrusion.height').hasError('min') || getFormControl(displayRuleEditorForm, 'extrusion.height').hasError('required')">
                    Extrusion height must be 0 or above.
                </small>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="3D Model" *ngIf="model3DModuleEnabled" formGroupName="model3D">
        <div rightAlignedHeader>
            <save-button *ngIf="showSaveToTypeButton && areFormControlsEnabled(displayRuleSections.model3d)" (click)="onSaveToType(displayRuleSections.model3d)" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
        </div>
        <!-- 3D Model visibility -->
        <div class="setting"
            lockedDisplayRuleHandler
            [isLocked]="getFormControl(displayRuleEditorForm, 'model3D.visible').disabled"
            (click)="unlockSetting($event, 'model3D.visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model3D.visible').disabled"
                (toggled)="toggleInheritance('model3D.visible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-visibility">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the 3D Model is visible on the map or not.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="model3D-visibility" class="mi-toggle" type="checkbox" formControlName="visible"/>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model3D.visible').enabled" (click)="onSaveToType(['model3D.visible'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>

        <!-- 3D Model Zoom From -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'model3D.zoomFrom').disabled"
            (click)="unlockSetting($event, 'model3D.zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model3D.zoomFrom').disabled"
                (toggled)="toggleInheritance('model3D.zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the 3D model is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="model3D-zoom-from" class="mi-input" type="number" formControlName="zoomFrom" [max]="maxZoomLevel" [min]="minZoomLevel" #numberInputs>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model3D.zoomFrom').enabled" (click)="onSaveToType(['model3D.zoomFrom'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'model3D.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than  0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'model3D.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- 3D Model Zoom To -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'model3D.zoomTo').disabled"
            (click)="unlockSetting($event, 'model3D.zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model3D.zoomTo').disabled"
                (toggled)="toggleInheritance('model3D.zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the 3D Model is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <zoom-to-input inputId="model3D-zoom-to" [isLocked]="getFormControl(displayRuleEditorForm, 'model3D.zoomTo').disabled" formControlName="zoomTo" [max]="maxZoomLevel" [min]="minZoomLevel"></zoom-to-input>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model3D.zoomTo').enabled" (click)="onSaveToType(['model3D.zoomTo'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'model3D.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl(displayRuleEditorForm, 'model3D.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- 3D Model Media Library -->
        <div class="setting" lockedDisplayRuleHandler
            [isLocked]="getFormControl(displayRuleEditorForm, 'model3D.model').disabled" (click)="unlockSetting($event, 'model3D.model')">
            <lock-button class="setting__lock setting__lock--full-height" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model3D.model').disabled"
                (toggled)="toggleInheritance('model3D.model', $event)">
            </lock-button>
            <div class="setting__label setting__label--baseline">
                <label class="mi-label">3D Model</label>
                <info-tooltip class="tooltip" text="Controls which image is used as 3D Model on the map.">
                </info-tooltip>
            </div>

            <div *ngIf="getFormControl(displayRuleEditorForm, 'model3D.model').value" class="setting__control setting__control--flex">
                <div class="model3d">
                    <div class="model3d__preview">
                        <img [src]="model3DPreview?.src" />
                        <p class="model3d__name">{{ model3DPreview?.name }}</p>
                    </div>
                    <input type="hidden" formControlName="model">

                    <div class="model3d__buttons">
                        <button [disabled]="getFormControl(displayRuleEditorForm, 'model3D.model').disabled"
                            (click)="openMediaLibraryFor3DModel()" class="mi-button mi-button--base">
                            Change
                        </button>
                        <button *ngIf="isMainDisplayRule" class="mi-button mi-button--remove" (click)="removeModel3D()">
                            Remove
                        </button>
                    </div>
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model3D.model').enabled" (click)="onSaveToType(['model3D.model'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__control" *ngIf="!getFormControl(displayRuleEditorForm, 'model3D.model').value">
                <button type="button" [disabled]="getFormControl(displayRuleEditorForm, 'model3D.model').disabled" (click)="openMediaLibraryFor3DModel()" class="mi-button mi-button--base">
                    Add 3D model
                </button>
            </div>
        </div>

        <!-- 3D Model x rotation -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'model3D.rotationX').disabled"
            (click)="unlockSetting($event, 'model3D.rotationX')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model3D.rotationX').disabled"
                (toggled)="toggleInheritance('model3D.rotationX', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-x-rotation">X-axis rotation</label>
                <info-tooltip class="tooltip" text="Controls the rotation of the 3D model on the x-axis."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="º">
                    <input id="model3D-x-rotation" class="mi-input" type="number" formControlName="rotationX" #numberInputs min="0">
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model3D.rotationX').enabled" (click)="onSaveToType(['model3D.rotationX'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.rotationX').hasError('required')">
                    "X-axis rotation" must be between 0 and 360.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.rotationX').hasError('min')">
                    "X-axis rotation" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.rotationX').hasError('max')">
                    "X-axis rotation" must be lower than 360.
                </small>
            </div>
        </div>

        <!-- 3D Model y rotation -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'model3D.rotationY').disabled"
            (click)="unlockSetting($event, 'model3D.rotationY')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model3D.rotationY').disabled"
                (toggled)="toggleInheritance('model3D.rotationY', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-y-rotation">Y-axis rotation</label>
                <info-tooltip class="tooltip" text="Controls the rotation of the 3D model on the y-axis."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="º">
                    <input id="model3D-x-rotation" class="mi-input" type="number" formControlName="rotationY" #numberInputs min="0">
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model3D.rotationY').enabled" (click)="onSaveToType(['model3D.rotationY'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.rotationY').hasError('required')">
                    "Y-axis rotation" must be between 0 and 360.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.rotationY').hasError('min')">
                    "Y-axis rotation" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.rotationY').hasError('max')">
                    "Y-axis rotation" must be lower than 360.
                </small>
            </div>
        </div>

        <!-- 3D Model z rotation -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'model3D.rotationZ').disabled"
            (click)="unlockSetting($event, 'model3D.rotationZ')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model3D.rotationZ').disabled"
                (toggled)="toggleInheritance('model3D.rotationZ', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-z-rotation">Z-axis rotation</label>
                <info-tooltip class="tooltip" text="Controls the rotation of the 3D model on the z-axis."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="º">
                    <input id="model3D-z-rotation" class="mi-input" type="number" formControlName="rotationZ" #numberInputs min="0">
                </div>
                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model3D.rotationZ').enabled" (click)="onSaveToType(['model3D.rotationZ'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.rotationZ').hasError('required')">
                    "Z-axis rotation" must be between 0 and 360.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.rotationZ').hasError('min')">
                    "Z-axis rotation" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.rotationZ').hasError('max')">
                    "Z-axis rotation" must be lower than 360.
                </small>
            </div>
        </div>

        <!-- 3D Model dimensions -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl(displayRuleEditorForm, 'model3D.scale').disabled"
            (click)="unlockSetting($event, 'model3D.scale')">
            <lock-button class="setting__lock setting__lock--full-height" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl(displayRuleEditorForm, 'model3D.scale').disabled"
                (toggled)="toggleInheritance('model3D.scale', $event)">
            </lock-button>

            <div class="setting__label setting__label--baseline">
                <label class="mi-label">Dimensions</label>
                <info-tooltip class="tooltip" text="Controls the size of the 3D model on the map."></info-tooltip>
            </div>

            <div class="setting__control setting__control--flex setting__control--direction-column">
                <div>
                    <div class="setting__group setting__group--bottom-spacing">
                        <div class="setting__control setting__control--direction-column">
                            <div class="setting__label setting__label--small-bottom-spacing">
                                <label class="mi-label mi-label--small" for="model3D-scale">Scale</label>
                                <info-tooltip class="tooltip tooltip--no-margin" text="Controls the scale of the 3D model. Can be used to make the model bigger or smaller on the map."></info-tooltip>
                            </div>
                            <div>
                                <input
                                    type="number"
                                    class="mi-input"
                                    max="1000"
                                    min="0"
                                    step="0.01"
                                    formControlName="scale"
                                    #numberInputs>
                            </div>
                        </div>
                    </div>
    
                    <div class="setting__errors">
                        <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.scale').hasError('required')">
                            "Scale" must be between 0 and 1000.
                        </small>
                        <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.scale').hasError('min')">
                            "Scale" must be larger than 0.
                        </small>
                        <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.scale').hasError('max')">
                            "Scale" must be lower than 1000.
                        </small>
                        <small role="alert" class="mi-form__error" *ngIf="getFormControl(displayRuleEditorForm, 'model3D.scale').hasError('hasMoreDecimalsThanLimit')">
                            "Scale" cannot have more than 10 decimal places.
                        </small>
                    </div>
    
                    <div class="setting__group setting__group--bottom-spacing setting__group--align-items-end">
                        <div class="setting__control setting__control--direction-column">
                            <div class="setting__label setting__label--small-bottom-spacing">
                                <label class="mi-label mi-label--small" for="model3D-width">Width</label>
                                <info-tooltip class="tooltip tooltip--no-margin" text="Controls the width of the 3D model. Editing the width will change the height and scale accordingly as well."></info-tooltip>
                            </div>
                            <div
                                data-units="m"
                                class="mi-input__wrapper mi-input__wrapper--with-units"
                                [ngClass]="{'disabled' :getFormControl(displayRuleEditorForm, 'model3D.widthMeters').disabled }">
                                <input
                                    id="model3D-width"
                                    class="mi-input"
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    formControlName="widthMeters"
                                    #numberInputs/>
                            </div>
                        </div>
    
                        <img class="setting__icon" src="/assets/images/link.svg" />
    
                        <div class="setting__control setting__control--direction-column">
                            <div class="setting__label setting__label--small-bottom-spacing">
                                <label class="mi-label mi-label--small" for="model3D-height">Height</label>
                                <info-tooltip class="tooltip tooltip--no-margin" text="Controls the height of the 3D model. Editing the height will change the width and scale accordingly as well."></info-tooltip>
                            </div>
                            <div
                                data-units="m"
                                class="mi-input__wrapper mi-input__wrapper--with-units"
                                [ngClass]="{'disabled' :getFormControl(displayRuleEditorForm, 'model3D.heightMeters').disabled }">
                                <input
                                    id="model3D-height"
                                    class="mi-input"
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    formControlName="heightMeters"
                                    #numberInputs/>
                            </div>
                        </div>
                    </div>
                </div>

                <save-button *ngIf="showSaveToTypeButton && getFormControl(displayRuleEditorForm, 'model3D.scale').enabled" (click)="onSaveToType(['model3D.scale', 'model3D.widthMeters', 'model3D.heightMeters'])" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
            </div>
        </div>
    </navigatable-section>
</form>
<panel-footer *ngIf="isDisplayRuleFormDirty" text="You have unsaved changes.">
    <button type="button" class="mi-button mi-button--link" (click)="onDiscardChanges()">Discard</button>
    <button type="button" class="mi-button mi-button--primary" [disabled]="!isDisplayRuleFormValid"
        (click)="onSubmit()">Save and close</button>
</panel-footer>