import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';

import { Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Building } from '../../buildings/building.model';
import { BuildingService } from '../../buildings/building.service';

@Component({
    selector: 'app-building-selector',
    templateUrl: './building-selector.component.html',
    styleUrls: ['./building-selector.component.scss']
})
export class BuildingSelectorComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() buildings: Building[];
    @Output() selectedBuildingEvent = new EventEmitter<Building>();

    public buildingInputControl = new UntypedFormControl();
    public buildingSearchQuery: string;
    public buildingSearchKeys = ['displayName', 'address'];
    public buildingSearchOptions = {
        caseSensitive: false,
        sort: true
    };

    constructor(private buildingService: BuildingService) { }

    /** NgOnInit. */
    ngOnInit(): void {
        this.subscriptions.add((this.buildingService.getCurrentBuilding() as Observable<Building>)
            .subscribe(building => this.buildingInputControl.setValue(building)));

        this.subscriptions.add(this.buildingInputControl.valueChanges
            .pipe(debounceTime(100), distinctUntilChanged())
            .subscribe(value => {
                this.buildingSearchQuery = value.displayName;
            }));
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Function that returns the building's property to be displayed in the autocomplete element.
     *
     * @param {Building} [building]
     * @returns {(string | undefined)}
     * @memberof BuildingSelectorComponent
     */
    public displayProperty(building?: Building): string | undefined {
        return building ? building.displayName : undefined;
    }

    /**
     * Open/Close autocomplete panel.
     *
     * @param {MatAutocompleteTrigger} trigger
     * @memberof BuildingSelectorComponent
     */
    public togglePanel(trigger: MatAutocompleteTrigger): void {
        if (trigger.panelOpen) {
            trigger.closePanel();
        } else {
            trigger._onChange('');
            trigger.openPanel();
        }
    }

    /**
     * Emit the selected building as the current building.
     *
     * @param {Building} building
     * @memberof BuildingSelectorComponent
     */
    public onOptionSelected(building: Building): void {
        this.selectedBuildingEvent.emit(building);
        document.getElementById('building-selector-input').blur();
    }
}
