<ngx-spinner name="occupant-template-spinner" type="ball-pulse" bdColor="rgba(51, 51, 51, 0.3)"></ngx-spinner>

<mat-toolbar class="header mat-elevation-z2">
    <h2>Occupant Templates</h2>

    <button
            type="button"
            class="mi-button mi-button--primary"
            (click)="openOccupantTemplateEditor()">
        New Occupant Template
    </button>

    <button
            type="button"
            class="mi-button mi-button--outline occupant-template__button occupant-template__button--delete"
            (click)="deleteSelectedOccupantTemplates()"
            *ngIf="selectedOccupantTemplates.size > 0">
        {{ selectedOccupantTemplates.size <= 1 ? 'Delete Occupant Template' : 'Delete Occupant Templates' }}
           </button>
            <search-box
                class="search"
                (activeSearchQuery)="onSearchQueryChange($event)"
                placeholder="Search by Name or Category">
            </search-box>
</mat-toolbar>

<mi-data-table
               selectable
               [selected]="selectedOccupantTemplates"
               class="table"
               [maxRows]="maxRows"
               [rows]="filteredOccupantTemplates"
               [attr.empty-page-header]="isSearchQuery ? 'No Occupant Template found' : ''">
    <mi-column (clicked)="openOccupantTemplateEditor($event)" align-content="center" width="48px">
        <img src="/assets/images/edit_20px.svg" alt="Edit Occupant Template 1" />
    </mi-column>
    <mi-column label="Display Name" binding="name" sort="asc" width="30%"></mi-column>
    <mi-column label="Category" binding="occupantCategoryName" sort="asc"></mi-column>
    <mi-column label="Occupants" binding="occupantReferenceCount" sort="asc" width="10%"></mi-column>
    <mi-column label="Last modified" binding="lastModified" sort="asc" width="20%"></mi-column>
</mi-data-table>

<pagination
            class="pagination"
            [itemsPerPage]="maxRows"
            [totalItems]="totalCount"
            (pageChanged)="loadNewOccupantTemplates($event)">
</pagination>