<div class="color-picker">
    <input 
        class="mi-input color-picker__text" 
        type="text" 
        maxlength="7"
        [formControl]="colorPickerFormControl" 
        [value]="colorPickerFormControl.value"
        [attr.disabled]="colorPickerInputDisabled"/>
    <input 
        type="color" 
        [formControl]="colorPickerFormControl"  
        [value]="colorPickerFormControl.value"
        [attr.disabled]="colorPickerInputDisabled"/>
</div>