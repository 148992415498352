<span class="header documentation-reference">
    <lock-button *ngIf="isLockable" class="setting__lock" [locked]="locked"
        (toggled)="onToggleChange($event)">
    </lock-button>
    <h3 class="header__title" [ngClass]="{ 'header__title--without-padding': isLockable === true }">{{header}}</h3>
    <a class="documentation-reference__link" *ngIf="documentationLink" href="{{documentationLink}}" target="_blank">Help</a>
    <div class="header__right-section">
        <ng-content select="[rightAlignedHeader]"></ng-content>
    </div>
</span>

<hr>
<div class="content">
    <ng-content></ng-content>
</div>