import { Injectable } from '@angular/core';

/**
 * Menu data with nested structure.
 * Each node has a name and an optional list of children.
 */
export interface MenuNode {
    name: string;
    icon?: string;
    id?: string;
    url?: string;
    children?: MenuNode[];
}

const MENU_TREE_DATA: MenuNode[] = [
    {
        name: 'Map',
        id: 'map',
        url: '/map',
        children: []
    },
    {
        name: 'Solution Details',
        icon: '',
        id: 'solution-details',
        children: [
            { name: 'Types', url: '/types' },
            { name: 'Categories', url: '/categories' },
            { name: 'Occupant Templates', url: '/occupant-templates' },
            { name: 'Buildings', url: '/buildings' },
            { name: 'Venues', url: '/venues' },
            {
                name: 'Solution Settings', url: '/solution-settings',
                children: [
                    { name: 'solution-settings', url: '/solution-settings/main-display-rule' },
                    { name: 'building-highlight', url: '/solution-settings/building-highlight' },
                    { name: 'map-behavior', url: '/solution-settings/map-behavior' },
                    { name: 'three-d-settings', url: '/solution-settings/3d-settings' }
                ]
            },
            {
                name: 'App Settings',
                url: '/app-settings',
                id: 'app-settings',
                children: [
                    { name: 'App Configuration', url: '/app-settings/app-config' },
                    { name: 'API Keys', url: '/app-settings/api-keys' },
                    { name: 'Booking Provider', url: '/app-settings/booking' },
                    { name: 'Position Provider', url: '/app-settings/position' },
                    { name: 'Webex', url: '/app-settings/webex' }
                ]
            }
        ]
    },
    {
        name: 'Deployment',
        icon: 'settings_applications',
        id: 'deployment',
        children: [
            { name: 'Venue', url: '/deployment/venue' },
            { name: 'App Config', url: '/deployment/config' },
            { name: 'Import/Export', url: '/deployment/import-export' },
            { name: 'Export Map', url: '/deployment/export' },
            { name: 'Solutions', url: '/deployment/solutions' },
            { name: 'Customers & Partners', url: '/deployment/customers' }
        ]
    },
    {
        name: 'Old map',
        id: 'old-map',
        url: '/old-map',
        children: []
    },
    {
        name: 'Administration',
        icon: 'gear',
        id: 'administration',
        children: [
            { name: 'Users', url: '/users' },
            { name: 'Dashboard', url: '/dashboard' },
            { name: 'Logs', url: '/logs' }
        ]
    }
];

@Injectable({ providedIn: 'root' })
export class MenuService {

    /**
     * Get menu tree data.
     *
     * @returns {MenuNode[]}
     * @memberof MenuService
     */
    public getMenuTreeData(): MenuNode[] {
        return MENU_TREE_DATA;
    }
}
