<!-- Button: Toggle Route Network Visibility -->
<button type="button" aria-label="Toggle network visibility " class="map-toolbar__control"
        [ngClass]="{'map-toolbar__control--active': isRouteNetworkVisible}" *ngIf="isAdmin"
        (click)="toggleRouteNetworkVisibility()">
    <span class="tooltip">{{ isRouteNetworkVisible ? 'Hide' : 'Show'}} Network</span>
    <img src="/assets/images/route-network-tools/show-network.svg" alt="Toggle Route Network Visibility">
</button>

<!-- Button: Add Barrier Route Element -->
<button type="button" aria-label="Add Barrier Route Element" class="map-toolbar__control" *ngIf="isAdmin"
        (click)="setToolActive(Tools.AddBarrierComponent)">
    <span class="tooltip">Add Barrier Route Element</span>
    <img src="/assets/images/route-network-tools/add-barrier.svg" alt="Add Barrier">
</button>

<!-- Button: Add Door Route Element -->
<button type="button" aria-label="Add Door Route Element" class="map-toolbar__control" *ngIf="isAdmin"
    (click)="setToolActive(Tools.AddDoorComponent)">
    <span class="tooltip">Add Door Route Element</span>
    <img src="/assets/images/route-network-tools/add-door.svg" alt="Add Door">
</button>

<!-- Button: Add Entry Point -->
<button type="button" aria-label="Add Entry Point" class="map-toolbar__control" *ngIf="isOwner"
        (click)="setToolActive(Tools.AddEntryPointComponent)">
    <span class="tooltip">Add Entry Point</span>
    <img src="/assets/images/route-network-tools/add-entry-point.svg" alt="Add Entry Point">
</button>

<!-- Button: Add Floor Connector -->
<button type="button" aria-label="Add Floor Connector" class="map-toolbar__control" *ngIf="isOwner"
        (click)="setToolActive(Tools.AddFloorConnectorComponent)">
    <span class="tooltip">Add Floor Connector</span>
    <img src="/assets/images/route-network-tools/add-floor-connector.svg" alt="Add Floor Connector">
</button>

<!-- Button: Edit navigation network -->
<button type="button" aria-label="Edit navigation network" class="map-toolbar__control"
        *ngIf="isOwner"
        (click)="setToolActive(Tools.EditGraphComponent)">
    <span class="tooltip">Edit navigation network</span>
    <img src="/assets/images/route-network-tools/edit-network.svg" alt="Edit navigation network">
</button>

<!-- Button: Edit Graph Bounds -->
<button type="button" aria-label="Edit Graph Bounds" class="map-toolbar__control" *ngIf="isOwner"
        (click)="setToolActive(Tools.EditGraphBoundsComponent)">
    <span class="tooltip">Edit Graph Bounds</span>
    <img src="/assets/images/route-network-tools/edit-graph-bounds.svg" alt="Edit Graph Bounds">
</button>

<!-- Button: Show List of Graphs -->
<button type="button" aria-label="Show list of Graphs" class="map-toolbar__control" *ngIf="isOwner"
        (click)="openGraphListEditor()">
    <!-- Default icon -->
    <span class="tooltip">Show List of Graphs</span>
    <img src="/assets/images/route-network-tools/show-graph-list.svg" alt="Show list of Graphs" />
</button>

<!-- Button: Reload Route Network -->
<button type="button" aria-label="Reload Route Network" class="map-toolbar__control" *ngIf="isAdmin"
        (click)="reloadRouteNetwork()">
    <span class="tooltip">{{ newNetworkChanges ? 'New changes - ' : null }} Reload Route Network</span>
    <!-- Default icon -->
    <img *ngIf="!routeNetworkChanged" src="/assets/images/route-network-tools/reload-network.svg"
         alt="Reload Route Network" />

    <!-- New changes icon  -->
    <img *ngIf="routeNetworkChanged" src="/assets/images/route-network-tools/reload-network-badge.svg"
         alt="Reload Route Network" />
</button>

<!-- Button: Regenerate Route Network -->
<button type="button" aria-label="Regenerate Route Network" class="map-toolbar__control"
        *ngIf="isRegenerateButtonVisible"
        (click)="regenerateAllGraphData()">
    <span class="tooltip">Regenerate Route Network</span>
    <img src="/assets/images/route-network-tools/regenerate-network.svg" alt="Regenerate Route Network">
</button>