import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ExtendedLocation, LocationService } from '../locations/location.service';
import { DataService } from '../services/data.service';
import { Venue } from '../venues/venue.model';
import { VenueService } from '../venues/venue.service';
import { DerivedGeometry, DerivedGeometryType } from './DerivedGeometry';
import { BuildingService } from '../buildings/building.service';

@Injectable({ providedIn: 'root' })
export class DerivedGeometryService {
    private derivedGeometriesSubject = new ReplaySubject<DerivedGeometry[]>(1);
    private floorPlanSubject = new ReplaySubject<DerivedGeometry[]>(1);

    constructor(
        private buildingService: BuildingService,
        private locationService: LocationService,
        private venueService: VenueService,
        private dataService: DataService,
    ) {
        combineLatest([this.locationService.locations$, this.venueService.selectedVenue$])
            .pipe(
                catchError(() => of([])),
                switchMap(([locations, venue]: [ExtendedLocation[], Venue]) => this.fetchDerivedGeometries(venue)
                    .pipe(
                        map(derivedGeometries => derivedGeometries.reduce((arr, derivedGeometry) => {
                            const location = locations.find(location => derivedGeometry.geodataId === location.id);
                            if (location) {
                                derivedGeometry.floorIndex = location.pathData.floor;
                                arr.push(derivedGeometry);
                                location.wallGeometry = derivedGeometry.type === DerivedGeometryType.WALL ? derivedGeometry : undefined;
                            }
                            return arr;
                        }, []))
                    )
                )
            )
            .subscribe((derivedGeometries) => this.derivedGeometriesSubject.next(derivedGeometries));

        this.venueService.selectedVenue$.pipe(
            switchMap(venue => this.fetchFloorPlans(venue)),
            switchMap(floorPlans => this.buildingService.selectedFloor$
                .pipe(
                    filter(floor => !!floor),
                    map(floor => floorPlans.filter(floorPlan => floorPlan.floorIndex === floor.floorIndex))))
        ).subscribe((derivedGeometry) => this.floorPlanSubject.next(derivedGeometry));
    }

    /**
     * Fetch derived geometries for locations on the given venue.
     *
     * @private
     * @param {Venue} venue
     * @returns {Observable<DerivedGeometry[]>}
     * @memberof DerivedGeometryService
     */
    private fetchDerivedGeometries(venue: Venue): Observable<DerivedGeometry[]> {
        const options = { params: { venueId: venue.id } };
        const endpoint = `${venue.solutionId}/api/derivedgeometry`;
        return this.dataService.getItems<DerivedGeometry>(endpoint, options).pipe(map(derivedGeometries => derivedGeometries.map(derivedGeometry => DerivedGeometry.fromJSON(derivedGeometry))));
    }

    /**
     * Fetch floorplan for the given venue and floor index.
     *
     * @param {Venue} venue - The venue to fetch the floorplan for.
     * @returns {Observable<DerivedGeometry[]>}
     */
    private fetchFloorPlans(venue: Venue): Observable<DerivedGeometry[]> {
        const options = { params: { venueId: venue.id, geometryTypes: 'floorplan' } };
        const endpoint = `${venue.solutionId}/api/derivedgeometry`;
        return this.dataService.getItem<DerivedGeometry[]>(endpoint, options).pipe(map(derivedGeometries => derivedGeometries.map(derivedGeometry => DerivedGeometry.fromJSON(derivedGeometry))));
    }

    /**
     * Get derived geometries as an observable.
     *
     * @returns {Observable<DerivedGeometry[]>}
     */
    get derivedGeometries$(): Observable<DerivedGeometry[]> {
        return this.derivedGeometriesSubject.asObservable();
    }

    /**
     * Get floorplan as an observable.
     *
     * @returns {Observable<DerivedGeometry[]>}
     */
    get floorPlan$(): Observable<DerivedGeometry[]> {
        return this.floorPlanSubject.asObservable();
    }
}
