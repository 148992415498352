import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IMediaItem, MediaItem } from '../media-item/media-item.model';
import { MediaSyncComponent } from '../media-sync/media-sync.component';
import { SolutionService } from '../../services/solution.service';
import { MediaLibrarySource } from '../media.enum';

@Component({
    selector: 'media-items-list',
    templateUrl: './media-items-list.component.html',
    styleUrls: ['./media-items-list.component.scss']
})
export class MediaItemsListComponent implements OnInit {
    private _mediaItems: IMediaItem[] = [];
    /**
     * Setting the new used media item.
     */
    @Input()
    public set selectedItem(item: IMediaItem) {
        this._selectedItem = item;
        if (this._selectedItem) {
            this._selectedItem.selected = true;
            this.sendSelectedMedia();
        }
    }

    /**
     * Set the media items.
     *
     * @param {IMediaItem[]} items - The media items to set.
     */
    @Input()
    public set mediaItems(items: IMediaItem[]) {
        this._mediaItems = items;
        this.mediaItemsToShow = this._mediaItems?.slice(this.maxNumberOfMediaItemsPerPage * (this._currentPage - 1), this.maxNumberOfMediaItemsPerPage * this._currentPage);
    }

    /**
     * Setting the current page.
     */
    @Input()
    public set currentPage(page: number) {
        this._currentPage = page;

        if (this._currentPage < 1) {
            this._mediaItems = [];
        } else {
            // E.g. if the current page is 2 and the maxNumberOfMediaItemsPerPage is 100, the media items to show will be the media items from index 100 to 200.
            // this.mediaItemsToShow = this.mediaItems?.slice(100 * (2 - 1), 100 * 2);
            const sliceFrom = this.maxNumberOfMediaItemsPerPage * (this._currentPage - 1);
            const sliceTo = this.maxNumberOfMediaItemsPerPage * this._currentPage;
            this.mediaItemsToShow = this._mediaItems?.slice(sliceFrom, sliceTo);
        }
    }

    @Input() source: MediaLibrarySource;
    @Input() maxNumberOfMediaItemsPerPage: number;

    @Output() chosenMediaItem = new EventEmitter<IMediaItem>();
    @Output() filteredMediaItems = new EventEmitter<IMediaItem[]>();

    public isSyncButtonShown: boolean = true;
    public mediaItemsToShow: IMediaItem[] = [];

    private _currentPage: number = 1;
    private _selectedItem: IMediaItem;

    constructor(
        private solutionService: SolutionService,
        private dialog: MatDialog
    ) { }

    /**
     * Creates and updates the list of media items for rendering each media item component.
     */
    ngOnInit(): void {
        this.isSyncButtonShown = this.solutionService.getSyncableSolutions().length > 0;
    }

    /**
     * Emits an event and sends the selected item along - the media-library component is listening to it in order so send the media item along when the media libarry modal box is closed.
     */
    private sendSelectedMedia(): void {
        this.chosenMediaItem.emit(this._selectedItem);
    }

    /**
     * Opens sync manager.
     *
     * @param {MediaItem} mediaItem
     */
    public onOpenSync(mediaItem: MediaItem): void {
        this.dialog.open(MediaSyncComponent, {
            disableClose: false,
            role: 'dialog',
            autoFocus: false,
            ariaLabel: 'Sync Manager',
            panelClass: 'media-dialog',
            closeOnNavigation: true,
            data: { mediaItem }
        });
    }

    /**
     * When clicking on a media item, emit an event with the clicked media item.
     *
     * @param {IMediaItem} mediaItem
     */
    public onSelectMediaItem(mediaItem: IMediaItem): void {
        if (this.source === MediaLibrarySource.General) {
            return;
        }

        if (this._selectedItem) this._selectedItem.selected = false;
        if (this._selectedItem === mediaItem) {
            this._selectedItem = null;
        } else {
            this._selectedItem = mediaItem;
            this._selectedItem.selected = true;
        }
        this.sendSelectedMedia();
    }
}