import { Component, ElementRef, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { createDropdownItemElement } from '../../mi-dropdown/mi-dropdown';
import { OccupantCategoryService } from '../../../services/OccupantServices/occupant-category.service';
import { Subscription } from 'rxjs';
import { OccupantCategory } from '../../../services/OccupantServices/OccupantCategory';
import { SolutionService } from '../../../services/solution.service';
import { Solution } from '../../../solutions/solution.model';

@Component({
    selector: 'occupant-category-dropdown',
    templateUrl: './occupant-category-dropdown.component.html',
    styleUrls: ['./occupant-category-dropdown.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OccupantCategoryDropdownComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => OccupantCategoryDropdownComponent),
        multi: true,
    }]
})
export class OccupantCategoryDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    @ViewChild('occupantCategoryDropdown', { static: true }) occupantCategoryDropdownElement: ElementRef<HTMLMiDropdownElement>;

    constructor(
        private occupantCategoryService: OccupantCategoryService,
        private solutionService: SolutionService
    ) { }

    public onChange: (id: string | null) => void = () => { };
    public onTouch: () => void = () => { };
    public onValidatorChange: () => void = () => { };

    public isDropDownSelectionValid: boolean = true;

    private subscription: Subscription = new Subscription();
    private occupantCategoryId: string;
    private occupantCategories: OccupantCategory[];
    private dropdownItems: HTMLMiDropdownItemElement[];
    private selectedDropdownItem: HTMLMiDropdownItemElement;

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        const solution = this.solutionService.getStaticSolution();
        this.subscription.add(
            this.occupantCategoryService.occupantCategories$.subscribe((occupantCategories) => {
                this.occupantCategories = occupantCategories.sort((a, b) => (a?.getDisplayName(solution.defaultLanguage) || '').localeCompare(b?.getDisplayName(solution.defaultLanguage)));
                const defaultDropdownItem = createDropdownItemElement({ label: 'Select Category', value: null });
                this.dropdownItems = [].concat(defaultDropdownItem, this.createDropdownItems(this.occupantCategories, solution));

                if (this.occupantCategoryId) {
                    this.selectDropdownItem(this.occupantCategoryId);
                }

                this.occupantCategoryDropdownElement.nativeElement.items = this.dropdownItems;
            })
        );
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Creates the dropdown items.
     *
     * @param {OccupantCategory[]} occupantCategories
     * @returns {HTMLMiDropdownItemElement[]}
     */
    private createDropdownItems(occupantCategories: OccupantCategory[], solution: Solution): HTMLMiDropdownItemElement[] {
        const dropdownItems = new Array(occupantCategories.length);

        for (const [index, category] of occupantCategories.entries()) {
            dropdownItems[index] = createDropdownItemElement({ label: category.getDisplayName(solution.defaultLanguage), value: category.id });
        }

        return dropdownItems;
    }

    /**
     * Sets the selected dropdown item.
     *
     * @param {string} occupantCategoryId
     */
    private selectDropdownItem(occupantCategoryId: string): void {
        if (occupantCategoryId) {
            this.selectedDropdownItem = this.dropdownItems.find(dropdownItem => dropdownItem.value === occupantCategoryId);
            this.selectedDropdownItem.selected = true;
            this.onChange(occupantCategoryId);
        }
    }

    /**
     * On occupant category dropdown change.
     *
     * @param {CustomEvent} event
     */
    public onOccupantCategoryDropdownChange(event: CustomEvent): void {
        const newValue = event.detail[0].value;
        this.selectedDropdownItem = this.dropdownItems.find(dropdownItem => dropdownItem.value === newValue);
        this.onChange(newValue);
    }

    /**
     * WriteValue.
     *
     * @param {string} value
     */
    writeValue(value: string): void {
        this.occupantCategoryId = value;
        if (this.occupantCategories?.length > 0) {
            this.selectDropdownItem(this.occupantCategoryId);
        }
    }

    /**
     * RegisterOnChange.
     *
     * @param {(id: string) => void} fn
     */
    registerOnChange(fn: (id: string) => void): void {
        this.onChange = fn;
    }

    /**
     * RegisterOnTouched.
     *
     * @param {() => void} fn
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Validate.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        this.isDropDownSelectionValid = !!this.selectedDropdownItem && this.selectedDropdownItem?.value !== null;
        return this.isDropDownSelectionValid ? {} : { invalid: true };
    }
}