import { BBox, MultiPolygon, Polygon } from 'geojson';
import booleanIntersects from '@turf/boolean-intersects';
import { bboxPolygon, feature as toFeature } from '@turf/turf';
import { MapViewModel, MapViewModelFactory } from '../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { DisplayRuleService } from '../services/DisplayRuleService/DisplayRuleService';
import { MapsIndoorsData } from '../shared/enums/MapsIndoorsData';
import { Floor } from '../buildings/floor.model';
import { DerivedGeometry, DerivedGeometryType } from './DerivedGeometry';
import { WallViewModel } from '../../viewmodels/WallViewModel/WallViewModel';
import { ExtrusionViewModel } from '../../viewmodels/ExtrusionViewModel/ExtrusionViewModel';
import { FloorPlanViewModel } from '../../viewmodels/FloorPlanViewModel/FloorPlanViewModel';
import { isPolygonOrMultiPolygon } from '../../utilities/Geodata';

export class DerivedGeometryViewModelFactory extends MapViewModelFactory<DerivedGeometry> {
    constructor(private displayRuleService: DisplayRuleService) {
        super();
    }

    /**
     * Creates MapViewModels for the given location.
     *
     * @param {DerivedGeometry} derivedGeometry
     * @param {number} sortKey
     * @returns {MapViewModel[]}
     * @memberof DerivedGeometryViewModelFactory
     */
    async create(derivedGeometry: DerivedGeometry, sortKey: number): Promise<MapViewModel[]> {
        // Only create view models for polygons and multipolygons.
        if (!isPolygonOrMultiPolygon(derivedGeometry?.geometry)) return Promise.resolve([]);

        /**
         * The display rule for the derived geometry.
         * If the derived geometry type is not a floorplan, it is fetched from the display rule service.
         * Otherwise, it is set to { visible: true }.
         */
        const displayRule = derivedGeometry.type !== DerivedGeometryType.FLOORPLAN
            ? await this.displayRuleService.getDisplayRule(derivedGeometry.geodataId)
            : { visible: true };

        if (!this.isVisible(displayRule))
            return Promise.resolve([]);

        const viewModels: Promise<MapViewModel>[] = [];

        switch (derivedGeometry.type) {
            case DerivedGeometryType.WALL:
                if (displayRule?.walls?.visible) {
                    viewModels.push(WallViewModel.create(derivedGeometry.geodataId, derivedGeometry.geometry as (Polygon | MultiPolygon), displayRule, sortKey, MapsIndoorsData.Location));
                }
                break;
            case DerivedGeometryType.EXTRUSION:
                if (displayRule?.extrusion?.visible) {
                    viewModels.push(ExtrusionViewModel.create(derivedGeometry.geodataId, derivedGeometry.geometry as (Polygon | MultiPolygon), displayRule, sortKey, MapsIndoorsData.Location));
                }
                break;
            case DerivedGeometryType.FLOORPLAN:
                viewModels.push(FloorPlanViewModel.create(derivedGeometry.geodataId, derivedGeometry.geometry as (Polygon | MultiPolygon), displayRule, sortKey, MapsIndoorsData.FloorPlan));
                break;
        }

        return Promise.all(viewModels);
    }

    /**
     * Checks if the given DerivedGeometry intersects with the given bounds.
     *
     * @param {DerivedGeometry} derivedGeometry
     * @param {BBox} bounds
     * @returns {boolean}
     * @memberof DerivedGeometryViewModelFactory
     */
    intersectsWithBounds(derivedGeometry: DerivedGeometry, bounds: BBox): boolean {
        const boundsAsPolygon = bboxPolygon(bounds);
        return booleanIntersects(boundsAsPolygon, toFeature(derivedGeometry.geometry));
    }

    /**
     * Checks if the floorIndex for the given DerivedGeometry equals the given floorIndex.
     *
     * @param {DerivedGeometry} derivedGeometry
     * @param {floor} floor
     * @returns {boolean}
     * @memberof DerivedGeometryViewModelFactory
     */
    floorEquals(derivedGeometry: DerivedGeometry, floor: Floor): boolean {
        return derivedGeometry.floorIndex === floor?.floorIndex;
    }
}