import { MultiPolygon, Point, Polygon } from 'geojson';
import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';
import { ExtendedLocation } from '../../app/locations/location.service';
import { LabelTypeFormat } from '../../app/display-rule-details/label-format';

abstract class MapboxLabelViewModelProperties implements ViewModelProperties {
    originalId: string;
    originalType: MapsIndoorsData;
    clickable: boolean;
    labelVisible;
    labelType: string;
    textField: string;
    textSize: number;
    labelMaxWidth: number;
    textColor: string;
    imageId: string;
    textOpacity: number;
    haloColor: string;
    haloWidth: number;
    haloBlur: number;
    labelBearing: number;
    labelOffset: number[];
    sortKey: number;
    featureClass: FeatureClass;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a MapboxLabelViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @param {MapsIndoorsData} originalType
     * @param {ExtendedLocation} location
     * @returns {MapboxLabelViewModelProperties}
     * @memberof MapboxLabelViewModelProperties
     */
    static async create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData, location: ExtendedLocation): Promise<MapboxLabelViewModelProperties> {
        let featureClass: FeatureClass;
        switch (displayRule.labelType) {
            case LabelTypeFormat.TextLabel:
                featureClass = FeatureClass.TEXT_LABEL;
                break;
            case LabelTypeFormat.FlatLabel:
                featureClass = FeatureClass.FLAT_LABEL;
                break;
            case LabelTypeFormat.GraphicLabel:
            default:
                featureClass = FeatureClass.GRAPHIC_LABEL;
                break;
        }

        return await Promise.resolve({
            originalId: id,
            originalType,
            clickable: displayRule?.clickable,
            labelVisible: displayRule.labelVisible === true ? 'visible' : 'none',
            labelType: displayRule?.labelType,
            imageId: displayRule.labelStyle?.graphic?.backgroundImage,
            textField: renderTemplate(displayRule.label, location),
            textSize: displayRule?.labelStyle.textSize,
            textColor: displayRule?.labelStyle.textColor,
            textOpacity: displayRule?.labelStyle.textOpacity,
            haloBlur: displayRule?.labelStyle.haloBlur,
            haloColor: displayRule?.labelStyle.haloColor,
            haloWidth: displayRule?.labelStyle.haloWidth,
            labelBearing: displayRule?.labelStyle.bearing,
            labelMaxWidth: displayRule?.labelMaxWidth,
            labelOffset: [0 ,2],
            sortKey: SortKey.LABEL + sortKey,
            featureClass: featureClass,
            zoomRange: [displayRule?.polygon.zoomFrom, displayRule?.polygon.zoomTo]
        });
    }
}


export class MapboxLabelViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    readonly geometry: Point;
    readonly properties: MapboxLabelViewModelProperties;

    private constructor(id: string, geometry: Point, properties: MapboxLabelViewModelProperties) {
        this.id = `LABEL:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory to create a LabelViewModel from an id, a geometry, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {Polygon | MultiPolygon} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @param {MapsIndoorsData} originalType
     * @param {ExtendedLocation} location
     * @returns {MapboxLabelViewModel}
     * @memberof LabelViewModel
     */
    static async create(id: string, geometry: Point, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData, location: ExtendedLocation): Promise<MapboxLabelViewModel> {
        const properties = await MapboxLabelViewModelProperties.create(id, index, displayRule, originalType, location);
        const viewModel = new MapboxLabelViewModel(id, geometry, properties);

        return viewModel;
    }
}

/**
 * What to render.
 *
 * @param {string} template
 * @param {ExtendedLocation} location
 * @param {number} maxLength
 * @returns {any}
 */
function renderTemplate(template: string, location: ExtendedLocation, maxLength?: number): any {
    //Regex for template placeholders.
    const regex = /{{(.*?)}}/g;
    let arr: string[];
    let result = template;
    //Identify all template placeholders and replace them with the value from the corresponding property on the location. E.g. {{externalId}} will be replaced with the location's externalId.
    while ((arr = regex.exec(template)) !== null) {
        switch (arr[1].toLowerCase()) {
            case 'externalid':
                result = result.replace(arr[0], location.externalId ?? '');
                break;
            case 'name':
                result = result.replace(arr[0], location.name ?? '');
                break;
            default:
                result = result.replace(arr[0], '');
                break;
        }
    }

    if (result && maxLength > 0 && result.length > maxLength) {
        result = result.slice(0, maxLength).trim() + '…';
    }

    return result;
}
