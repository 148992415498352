import { distinctUntilChanged, filter, map, mapTo, startWith } from 'rxjs/operators';
import { Observable, fromEvent, merge } from 'rxjs';

/**
 * Converts a key identifier to its corresponding character.
 * This is useful for handling special cases where the key value isn't obvious.
 * For example, the spacebar's key value is ' ' and the code is 'Space'.
 *
 * @param {string} key - The key identifier to convert.
 * @returns {string} The corresponding character for the key.
 */
function keyToCharacter(key: string): string {
    switch (key) {
        case 'Space':
            return ' ';
        default:
            return key;
    }
}

/**
 * Observes key press events.
 *
 * @param {string} key - The key to observe.
 * @returns {Observable<KeyboardEvent>} The observable.
 */
export function fromKeyPress(key: KeyboardEvent['key']): Observable<KeyboardEvent> {
    key = keyToCharacter(key);
    return merge(
        fromKeyDown(key),
        fromKeyUp(key).pipe(mapTo(null))
    ).pipe(distinctUntilChanged((x, y) => x?.key === y?.key));
}

/**
 * Observes key down events.
 *
 * @param {string} key - The key to observe.
 * @returns {Observable<KeyboardEvent>} The observable.
 */
export function fromKeyDown(key: KeyboardEvent['key']): Observable<KeyboardEvent> {
    return fromEvent(window, 'keydown')
        .pipe(filter((event: KeyboardEvent) => event.key === key));
}

/**
 * Observes key up events.
 *
 * @param {string} key - The key to observe.
 * @returns {Observable<KeyboardEvent>} The observable.
 */
export function fromKeyUp(key: KeyboardEvent['key']): Observable<KeyboardEvent> {
    return fromEvent(window, 'keyup')
        .pipe(filter((event: KeyboardEvent) => event.key === key));
}

/**
 * Observes whether a key is pressed.
 *
 * @param {KeyboardEvent['key']} key - The key to observe.
 * @returns {Observable<boolean>} The observable.
 */
export function isKeyPressed(key: KeyboardEvent['key']): Observable<boolean> {
    return fromKeyPress(key).pipe(
        map(e => !!e),
        startWith(false)
    );
}
