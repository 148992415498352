import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { CorrelationId, OccupantService } from '../../../services/OccupantServices/occupant.service';
import { Subscription } from 'rxjs';
import { createDropdownItemElement } from '../../../shared/mi-dropdown/mi-dropdown';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';
import { Location } from '../../../locations/location.model';

@Component({
    selector: 'correlation-id-input',
    templateUrl: './correlation-id-input.component.html',
    styleUrls: ['./correlation-id-input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CorrelationIdInputComponent),
        multi: true
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => CorrelationIdInputComponent),
        multi: true,
    }]
})
export class CorrelationIdInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    @ViewChild('correlationIdDropdown', { static: true }) correlationIdDropdownElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('correlationIdInput', { static: true }) correlationIdInputElement: ElementRef<HTMLInputElement>;

    @Input() location: Location;
    @Input() occupantCorrelationId: string;

    constructor(
        private occupantService: OccupantService
    ) { }

    public onChange: (correlationId: string) => void = () => { };
    public onTouch: () => void = () => { };

    private subscription = new Subscription();
    private correlationIds: CorrelationId[] = [];
    private dropdownItems: HTMLMiDropdownItemElement[] = [];
    private selectedDropdownItem: HTMLMiDropdownItemElement;
    private correlationId: string;

    public correlationIdFormControl: UntypedFormControl = new UntypedFormControl(null);

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.subscription
            .add(
                this.occupantService.correlationIdList$.subscribe((correlationIds) => {
                    this.correlationIds = correlationIds.sort((a, b) => a.correlationId?.localeCompare(b.correlationId));
                    const defaultDropdownItem = createDropdownItemElement({ label: 'Select', value: null });
                    this.dropdownItems = [].concat(defaultDropdownItem, this.createDropdownItems(this.correlationIds));

                    if (this.correlationIds) {
                        this.selectDropdownItem();
                    }

                    this.correlationIdDropdownElement.nativeElement.items = this.dropdownItems;
                })
            )
            .add(
                this.correlationIdFormControl.valueChanges.subscribe((value) => {
                    this.onChange(value);
                })
            );
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Creates the dropdown items.
     *
     * @param {CorrelationId[]} correlationIds
     * @returns {HTMLMiDropdownItemElement[]}
     */
    private createDropdownItems(correlationIds: CorrelationId[]): HTMLMiDropdownItemElement[] {
        const dropdownItems = [];
        for (const correlationId of correlationIds) {
            if (correlationId.correlationId) {
                dropdownItems.push(createDropdownItemElement({
                    label: correlationId.correlationId,
                    value: correlationId.correlationId,
                    disabled: correlationId.correlationId === this.occupantCorrelationId ?
                        false // Enable the dropdown item if the correlationId is the same as the original correlationId.
                        : ((correlationId.floorIds.includes(this.location.pathData.floor) && correlationId.buildingId === this.location.pathData.building) // Disable if the building and the floor are the same for the current location and a correlationId.
                            || (correlationId.buildingId !== this.location.pathData.building)) // Disable if the building is different for the current location and a correlationId.
                }));
            }
        }

        return dropdownItems;
    }

    /**
     * Sets the selected dropdown item.
     */
    private selectDropdownItem(): void {
        if (this.dropdownItems?.length > 0) {
            if (this.selectedDropdownItem) {
                this.selectedDropdownItem.selected = false;
            }

            this.selectedDropdownItem = this.dropdownItems.find(dropdownItem => dropdownItem?.value === this.correlationId);

            if (this.selectedDropdownItem) {
                this.selectedDropdownItem.selected = true;
                this.correlationIdDropdownElement.nativeElement.items = [...this.dropdownItems];
                this.onChange(this.correlationId);
            }
        }
    }

    /**
     * Reset dropdown items.
     */
    private resetDropdownItems(): void {
        this.selectedDropdownItem.selected = false;
        this.selectedDropdownItem = this.dropdownItems.find(dropdownItem => dropdownItem?.value === null);
        this.selectedDropdownItem.selected = true;
        this.correlationIdDropdownElement.nativeElement.items = [...this.dropdownItems];
    }

    /**
     * Remove correlationId.
     */
    public onRemoveCorrelationId(): void {
        this.correlationId = null;
        this.correlationIdInputElement.nativeElement.value = null;
        this.correlationIdFormControl.setValue(this.correlationId);
        this.resetDropdownItems();
    }

    /**
     * On correlationId dropdown change.
     *
     * @param {CustomEvent} event
     */
    public onCorrelationIdDropdownChange(event: CustomEvent): void {
        this.correlationId = event.detail[0].value;
        this.correlationIdInputElement.nativeElement.value = null;
        this.selectedDropdownItem = this.dropdownItems.find(dropdownItem => dropdownItem?.value === this.correlationId);
        this.correlationIdFormControl.setValue(this.correlationId);
    }

    /**
     * On correlationId input change.
     *
     * @param {CustomEvent} event
     */
    public onCorrelationIdInputChange(event: CustomEvent): void {
        const originalInput = (event.target as HTMLInputElement).value;
        const input = originalInput.trim();

        if (input) {
            this.correlationId = input;
            this.correlationIdFormControl.setValue(this.correlationId, { emitEvent: false });
            this.resetDropdownItems();
        }

        // In case the input only consists of whitespace.
        if (originalInput.length !== 0 && input === '') {
            this.correlationIdFormControl.setErrors({ isEmpty: true });
        }

        // In case the input exists in the dropdown list.
        if (this.dropdownItems.find(dropdownItem => dropdownItem?.value === input)) {
            this.correlationIdFormControl.setErrors({ isDropDownItem: true });
        }

        // In case the input is empty.
        if (originalInput.length === 0) {
            this.correlationIdFormControl.setErrors({ isEmpty: false, isDropDownItem: false });
        }

        this.onChange(this.correlationId);
    }

    /**
     * WriteValue.
     *
     * @param {string} value
     */
    writeValue(value: string): void {
        this.correlationId = value;
        this.selectDropdownItem();
    }

    /**
     * RegisterOnChange.
     *
     * @param {(occupantTemplateId: string) => void} fn
     */
    registerOnChange(fn: (occupantTemplateId: string) => void): void {
        this.onChange = fn;
    }

    /**
     * RegisterOnTouched.
     *
     * @param {() => void} fn
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Validate.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return (this.correlationIdFormControl.hasError('isEmpty') || this.correlationIdFormControl.hasError('isDropDownItem'))
            ? { invalid: true }
            : {};
    }
}