import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
    selector: 'selectable-input',
    templateUrl: './selectable-input.component.html',
    styleUrls: ['./selectable-input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectableInputComponent),
        multi: true,
    }]
})
export class SelectableInputComponent implements ControlValueAccessor {
    public selectableFormControl = new FormControl(null, [Validators.required]);

    public onChange: (selectable: boolean | null) => void = () => { };
    public onTouch: () => void = () => { };

    constructor() {
        this.selectableFormControl.valueChanges.subscribe((selectable) => this.onChange(selectable));
    }

    /**
     * Writes a new value to the element.
     *
     * @memberof SelectableFormComponent
     */
    writeValue(value: boolean | null): void {
        this.selectableFormControl.setValue(value, { emitEvent: false });
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     *
     * @memberof SelectableFormComponent
     */
    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     *
     * @memberof SelectableFormComponent
     */
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }


    /**
     * Called by the forms API when the control status changes to or from 'DISABLED'.
     *
     * @param {boolean} isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.selectableFormControl.disable() : this.selectableFormControl.enable();
    }
}