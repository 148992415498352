import { Translation } from '../../shared/interfaces/translation.model';

export class OccupantCategory {
    readonly aliases: string[];
    readonly createdAt: string;
    readonly createdBy: string;
    readonly id: string;
    readonly lastModified: string;
    readonly lastModifiedBy: string;
    readonly naicsCodes: number[];
    readonly parentId: string;
    readonly sicCodes: number[];
    readonly solutionId: string;
    readonly isVacant: boolean;
    readonly occupantCategoryReferenceCount: number;
    readonly occupantTemplateReferenceCount: number;
    readonly translations: Translation[];

    public parentCategory?: OccupantCategory;

    constructor(
        occupantCategory,
    ) {
        Object.assign(this, occupantCategory);
    }

    /**
     * Get display name.
     *
     * @param {string} language
     * @returns {string}
     */
    public getDisplayName(language: string): string {
        const languageTranslation = this.translations.find(translation => translation.language === language);
        return languageTranslation.name;
    }
}