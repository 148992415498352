import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';
import { OccupantAssignmentAlgorithmService } from '../../services/OccupantServices/occupant-assignment-algorithm.service';

@Component({
    selector: 'occupant-assignment-algorithm',
    templateUrl: './occupant-assignment-algorithm.component.html',
    styleUrls: ['./occupant-assignment-algorithm.component.scss']
})
export class OccupantAssignmentAlgorithmComponent {
    constructor(
        private notificationService: NotificationService,
        private occupantAssignmentAlgorithmService: OccupantAssignmentAlgorithmService,
        private spinner: NgxSpinnerService
    ) { }

    /**
     * On Occupant Assignment Algorithm Click.
     */
    public onOAAClick(): void {
        this.spinner.show();
        this.occupantAssignmentAlgorithmService.runOccupantAssignmentAlgorithm().subscribe(() => {
            this.notificationService.showSuccess('Occupant Assignment Algorithm has been run successfully.');
            this.spinner.hide();
        });
    }
}