import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DisplayRule } from '@mapsindoors/typescript-interfaces';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { NotificationService } from '../../../app/services/notification.service';
import { SolutionService } from '../../../app/services/solution.service';
import { stayAtCurrentUrl } from '../solution-settings-shared-functions.component';
import isEqual from 'fast-deep-equal';
import { SolutionConfig } from '../solution-settings.model';
import { primitiveClone } from '../../shared/object-helper';
import { NetworkService } from '../../network-access/network.service';
import { UserService } from '../../../app/services/user.service';

@Component({
    selector: 'map-behavior',
    templateUrl: './map-behavior.component.html',
    styleUrls: ['./map-behavior.component.scss']
})

export class MapBehaviorComponent implements OnInit, OnDestroy {

    public clusteringEnabled: boolean;
    public _mainDisplayRule: [DisplayRule, DisplayRule?] = [null];
    public updateData;
    public mapBehaviorForm = this.formBuilder.group({
        enableClustering: ['', [Validators.required]],
        collisionHandling: ['', [Validators.required]],
        selectable: [],
        obstacle: []
    });
    public isOwnerOrAdmin: boolean = false;

    private _solutionConfig;
    private _originalFormState;
    private subscriptions = new Subscription();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private networkService: NetworkService,
        private userService: UserService
    ) {
        this.userService.getCurrentUserObservable().subscribe(() => {
            this.isOwnerOrAdmin = this.userService.hasOwnerPrivileges() && this.userService.hasAdminPrivileges();
        });
    }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.spinner.show();
        this.subscriptions
            .add(
                this.solutionService.solutionConfig$
                    .pipe(tap(() => this.spinner.hide()))
                    .subscribe(solutionConfig => {
                        this._solutionConfig = primitiveClone(solutionConfig);
                        this._originalFormState = {
                            enableClustering: this._solutionConfig?.enableClustering,
                            collisionHandling: this._solutionConfig?.collisionHandling,
                            selectable: this._solutionConfig?.locationSettings?.selectable,
                            obstacle: this._solutionConfig?.locationSettings?.obstacle
                        };

                        this._mainDisplayRule = [this._solutionConfig?.mainDisplayRule] as [DisplayRule, DisplayRule?];
                        this.setMapBehaviorValues(solutionConfig);
                    }))
            .add(
                this.router.events
                    .subscribe(() => {
                        if (this.mapBehaviorForm.dirty) {
                            this.onDiscard();
                        }
                    }))
            .add(
                this.mapBehaviorForm.valueChanges
                    .pipe(debounceTime(300))
                    .subscribe(formState => {
                        // compare the original state and the current state to see if they are the same.
                        !isEqual(this._originalFormState, formState)
                            ? this.mapBehaviorForm.markAsDirty()
                            : this.mapBehaviorForm.markAsPristine();
                    })
            );
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Get solutionConfig values.
     *
     * @returns {SolutionConfig}
     */
    public get solutionConfig(): SolutionConfig {
        return this._solutionConfig;
    }

    /**
     * Get Main Display Rule values.
     *
     * @returns {[DisplayRule, DisplayRule?]}
     */
    public get displayRules(): [DisplayRule, DisplayRule?] {
        return this._mainDisplayRule;
    }

    /**
     * Patches values to map behavior form.
     */
    private setMapBehaviorValues(solutionConfig: SolutionConfig): void {
        this.mapBehaviorForm.patchValue({
            enableClustering: solutionConfig?.enableClustering,
            collisionHandling: solutionConfig?.collisionHandling,
            selectable: solutionConfig?.locationSettings?.selectable,
            obstacle: solutionConfig?.locationSettings?.obstacle
        });
    }

    /**
     * When submitting accept and save changes made in Map Behavior section.
     */
    public onSubmit(): void {
        const mapBehaviorValues = {
            ...this._solutionConfig,
            enableClustering: this.mapBehaviorForm.controls['enableClustering'].value,
            collisionHandling: this.mapBehaviorForm.controls['collisionHandling'].value,
            locationSettings: {
                selectable: this.mapBehaviorForm.controls['selectable'].value,
                obstacle: this.mapBehaviorForm.controls['obstacle'].value ?? false
            }
        };

        this.spinner.show();
        this.solutionService.updateSolutionConfig(mapBehaviorValues)
            .pipe(tap(() => this.spinner.hide()))
            .subscribe(() => {
                // If the map behavior's obstacle setting has changed, the network needs to be reloaded.
                // A yellow badge will be added on top of the Reload Route Network button in the toolbar.
                if (mapBehaviorValues?.locationSettings?.obstacle !== this._originalFormState?.locationSettings?.obstacle) {
                    this.networkService.updateRouteElements();
                }
                this.notificationService.showSuccess('Changes saved successfully!');
            }, () => {
                this.notificationService.showError('Something went wrong. Please try again.');
            });
    }

    /**
     * When discarding changes in Map Behavior section we can discard them and revert changes or cancel action and still edit the form.
     */
    public onDiscard(): void {
        // eslint-disable-next-line no-alert
        const dialogResponse = confirm(
            'You will lose your changes if you continue without saving. Do you want to continue?'
        );
        if (!dialogResponse) {
            stayAtCurrentUrl(this.router);
            return;
        }

        this.setMapBehaviorValues(this._solutionConfig);
        this.mapBehaviorForm.markAsPristine();
    }
}