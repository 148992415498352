import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ObservableStore } from '@codewithdan/observable-store';
import { Observable, of } from 'rxjs';
import { concatMap, map, filter, switchMap } from 'rxjs/operators';

import { SolutionService } from '../services/solution.service';
import { ExternalApis, ExternalApiKeys } from '../shared/enums';
import { Category } from './category.model';
import { DataService } from '../services/data.service';
import { StoreState } from '../store/store-state';
import { Solution } from '../solutions/solution.model';

@Injectable({ providedIn: 'root' })
export class CategoryService extends ObservableStore<StoreState> {

    constructor(
        private dataService: DataService,
        private solutionService: SolutionService,
        private http: HttpClient
    ) {
        super({
            stateSliceSelector: state => ({ categories: state?.categories })
        });

        this.solutionService.getCurrentSolution()
            .pipe(switchMap(solution => this.fetchCategories(solution)))
            .subscribe();
    }

    /**
     * Get categories as an observable.
     *
     * @returns {Observable<Category[]>}
     * @memberof CategoryService
     */
    public get categories(): Observable<Category[]> {
        return this.stateChanged
            .pipe(
                filter(store => !!store?.categories),
                map(store => store.categories)
            );
    }

    /**
     * Get categories from the store.
     *
     * @returns {Category[]}
     * @memberof CategoryService
     */
    public getCategoriesFromStore(): Category[] {
        return this.getStateSliceProperty<Category[]>('categories');
    }

    /**
     * Get the category endpoint.
     *
     * @private
     * @returns {string}
     * @memberof CategoryService
     */
    private getCategoryEndpoint(solution?: Solution): string {
        solution = solution ?? this.solutionService.getStaticSolution();
        return solution?.id + '/api/categories';
    }

    /**
     * Fetch categories from the remote backend.
     *
     * @returns {Observable<Category[]>}
     * @memberof CategoryService
     */
    private fetchCategories(solution?: Solution): Observable<Category[]> {
        const endpoint = this.getCategoryEndpoint(solution);
        const options = {
            params: { details: true }
        };
        return this.dataService.getItems<Category>(endpoint, options)
            .pipe(map(categories => {
                categories = this.formatCategories(categories);
                this.setState({ categories }, CategoryStoreActions.GetCategories);
                return categories;
            }));
    }

    /**
     * Get all categories.
     *
     * @param {boolean} [remote=false] - If true, get them from the remote backend.
     * @returns {Observable<Category[]>}
     * @memberof CategoryService
     */
    public getCategories(remote = false): Observable<Category[]> {
        const categories = this.getStateSliceProperty<Category[]>('categories');
        if (!categories || remote) {
            return this.fetchCategories();
        }

        return of(categories);
    }

    /**
     * Delete a category.
     *
     * @param {Category} category
     * @returns {Observable<any>}
     * @memberof CategoryService
     */
    public deleteCategory(category: Category): Observable<any> {
        const endpoint = `${this.getCategoryEndpoint()}/${encodeURIComponent(category.key)}`;
        return this.dataService.deleteItem(endpoint)
            .pipe(
                map(() => {
                    this.setState({
                        categories: this.getStateSliceProperty<Category[]>('categories').filter(_category => _category.id !== category.id)
                    }, CategoryStoreActions.RemoveCategory);
                    return;
                }));
    }

    /**
     * Create a category.
     *
     * @param {Category} category
     * @returns {Observable<any>}
     * @memberof CategoryService
     */
    public createCategory(category: Category): Observable<any> {
        const endpoint = this.getCategoryEndpoint();
        return this.dataService.createItem<Category>(endpoint, category)
            .pipe(concatMap(() => this.fetchCategories()));
    }

    /**
     * Update a category.
     *
     * @param {Category} category
     * @returns {Observable<any>}
     * @memberof CategoryService
     */
    public updateCategory(category: Category): Observable<any> {
        const endpoint = this.getCategoryEndpoint();
        return this.dataService.updateItem<Category>(endpoint, category)
            .pipe(concatMap(() => this.fetchCategories()));
    }

    /**
     * Translates using google translate api.
     *
     * @param {*} query
     * @param {*} lang
     * @param {*} source
     * @returns {Observable<any>}
     * @memberof CategoryService
     */
    public googleTranslate(query, lang, source?): Observable<any> {
        return this.http.get(`${ExternalApis.GoogleTranslate}?target=${lang}&q=${query}&key=${ExternalApiKeys.GoogleGeneralKey}${source ? `&source=${source}` : ''}`).pipe(map((response: any) => ({translations: response.data.translations, language: lang})));
    }

    /**
     * Format category objects.
     *
     * @private
     * @param {Category[]} categories
     * @returns {Category[]}
     * @memberof CategoryService
     */
    private formatCategories(categories: Category[]): Category[] {
        const solution = this.solutionService.getStaticSolution();
        return categories?.map(category => {
            for (const translation of category.translations) {
                if (translation.language === solution?.defaultLanguage) {
                    category.displayName = translation.name;
                }
            }
            return category;
        }).sort((categoryA, categoryB) => categoryA.displayName.trimStart().toLowerCase().localeCompare(categoryB.displayName.trimStart().toLowerCase())) || [];
    }
}


export enum CategoryStoreActions {
    RemoveCategory = 'REMOVE_CATEGORY',
    GetCategories = 'GET_CATEGORIES',
    ClearCategories = 'CLEAR_CATEGORIES'
}
