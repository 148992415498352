import { combineLatest, fromEvent, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, share, switchMap, tap } from 'rxjs/operators';

import {
    bboxPolygon, bearing as calculateBearing, distance as calculateDistance,
    midpoint as createMidpoint, point as createPoint,
    LineString, length, lineString
} from '@turf/turf';

import * as turf from '@turf/turf';

import { DisplayRule, LocationType } from '../locations/location.model';
import { RouteElement, RouteElementType } from '../map/route-element-details/route-element.model';
import { BaseMapAdapter, MapMouseCursor } from '../MapAdapter/BaseMapAdapter';
import { DisplayRuleService } from '../services/DisplayRuleService/DisplayRuleService';
import { GeodataEditorViewState } from './GeodataEditorViewState';
import SnapTool from './SnapTool/SnapTool';
import { LocationEditorOperation } from './GeodataEditorOperation/LocationEditorOperation';
import { RouteElementEditorOperation } from './GeodataEditorOperation/RouteElementEditorOperation';
import { LocationService } from '../locations/location.service';
import { ExtendedLocation } from '../locations/location.service';
import { findPolygonsIntersectingWithGeometry, getNearestPointOnLineToPolygon, getPolygonSubsection, shiftPolygonStartEndAwayFromDoorPoint } from '../shared/geometry-helper';
import { NetworkService } from '../network-access/network.service';
import { convertCentimetersToMeters, convertInchesToMeters } from '../shared/conversion-helper';
import { UserAgentService } from '../services/user-agent.service';
import { UnitSystem } from '../shared/enums';
import { FeatureClass } from '../../viewmodels/FeatureClass';
import { GraphData } from '../map/graph-data.model';
import { GraphBoundsEditorOperation } from './GeodataEditorOperation/GraphBoundsEditorOperation';
import { Feature } from 'geojson';
import { PointViewModel } from '../../viewmodels/PointViewModel/PointViewModel';
import { SortKey } from '../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { MapsIndoorsData } from '../shared/enums/MapsIndoorsData';
import { LineStringViewModel } from '../../viewmodels/LineStringViewModel/LineStringViewModel';

export enum DrawingMode {
    FreeHand,
    Rectangular
}

export abstract class GeodataEditor {
    protected readonly editorViewState: GeodataEditorViewState;
    protected abstract setViewData(features: GeoJSON.Feature[]): void

    /**
     * For observing mouse up events.
     *
     * @private
     * @returns {Observable<Event>}
     * @memberof GeodataEditor
     */
    protected get mouseUp$(): Observable<Event> {
        return fromEvent(document, 'mouseup');
    }

    constructor(
        public readonly adapter: BaseMapAdapter,
        protected displayRuleService: DisplayRuleService,
        protected locationService: LocationService,
    ) {
        this.editorViewState = new GeodataEditorViewState(this.adapter, this.displayRuleService);
        this.editorViewState.changes.pipe(
            // Filtering 2D and 3D viewModels if they should be rendered.
            switchMap(features => combineLatest([this.adapter.model2DVisible$, this.adapter.model3DVisible$])
                .pipe(
                    map(([show2D, show3D]) => features.filter(feature => {
                        if (!show2D && feature.properties.featureClass === FeatureClass.MODEL2D ||
                            !show3D && feature.properties.featureClass === FeatureClass.MODEL3D ||
                            !show3D && feature.properties.featureClass === FeatureClass.WALL ||
                            !show3D && feature.properties.featureClass === FeatureClass.EXTRUSION) {
                            return false;
                        }
                        return true;
                    }))
                )
            )).subscribe(this.setViewData.bind(this));
    }

    protected isPolygonHandlesVisible: boolean = false;
    public abstract get mouseMove$(): Observable<GeoJSON.Point>;
    public abstract get mouseClick$(): Observable<GeoJSON.Point>;
    public abstract get mouseMoveUntilMouseUp$(): Observable<GeoJSON.Point>;
    public abstract get mouseDownOnPointLayer$(): Observable<GeoJSON.Point>;
    public abstract get mouseDownOnPolygonLayer$(): Observable<GeoJSON.Point>;
    public abstract get mouseDownOnMidpointsLayer$(): Observable<{ position: GeoJSON.Point, insertAfter: number }>;
    public abstract get mouseDownOnHandlesLayer$(): Observable<{ position: GeoJSON.Point, index: number }>;
    public abstract get mouseContextmenuOnHandlesLayer$(): Observable<{ position: GeoJSON.Point, index: number }>;
    public abstract get mouseDownOnLineString$(): Observable<GeoJSON.Point>;
    public abstract mouseOverPointLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void>;
    public abstract mouseOverPolygonLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void>;
    public abstract mouseOverLocation$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void>;
    public abstract mouseOverHandlesLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void>;
    public abstract mouseOverMidpointsLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void>;
    public abstract showPolygonHandles(visible: boolean): void;
    public abstract showExtrusion(visible: boolean): void;
    public abstract disablePitch(disabled: boolean): void;

    /**
     * Makes a route element editable on the map.
     *
     * @param {RouteElement} routeElement
     * @returns {RouteElementEditorOperation}
     */
    public editRouteElement(routeElement: RouteElement): RouteElementEditorOperation {
        this.displayRuleService.overrideDisplayRule(routeElement.id, { visible: false });

        let currentShiftState: boolean = false;

        const changesSubject: Subject<{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }> = new Subject();

        const isShiftKeyPressed = this.isShiftKeyPressed().subscribe(isPressed => currentShiftState = isPressed);

        const onComplete = (): void => {
            this.editorViewState.clear();
            this.displayRuleService.revertDisplayRule(routeElement.id);
            mouseDownOnPointLayer.unsubscribe();
            mouseDownOnLineString.unsubscribe();
            isShiftKeyPressed.unsubscribe();
        };

        const onRotate = null;
        const { multiLineString, polygons } = this.getRoomGeometries(routeElement.floorIndex);

        const routeElementEditorOperation = new RouteElementEditorOperation(routeElement, changesSubject, this.editorViewState, multiLineString, onComplete, onRotate);

        if (routeElement.type === RouteElementType.Door) {
            if (routeElement.geometry.type === 'Point') {
                const defaultDoorWidth = UserAgentService.UNIT_SYSTEM === UnitSystem.Imperial ?
                    convertInchesToMeters(NetworkService.DEFAULT_DOOR_WIDTH) :
                    convertCentimetersToMeters(NetworkService.DEFAULT_DOOR_WIDTH);

                const nearestPolygon = SnapTool.getNearestPolygon(polygons, routeElement.geometry);
                routeElement.geometry = getPolygonSubsection(nearestPolygon, routeElement.geometry, false, defaultDoorWidth);
            }
            const doorLineString = lineString((routeElement.geometry as LineString).coordinates);
            const doorWidth = length(doorLineString, { units: 'meters' });
            routeElementEditorOperation.doorWidth = doorWidth;
        }

        const mouseDownOnPointLayer = this.mouseDownOnPointLayer$
            .pipe(
                tap(() => this.adapter.setMapMouseCursor(MapMouseCursor.Move)),
                switchMap(() => this.mouseMoveUntilMouseUp$
                    .pipe(
                        finalize(() => this.adapter.setMapMouseCursor(MapMouseCursor.Default))
                    )))
            .subscribe((anchor => {
                const geometry = this.editorViewState.getGeometry(`POINT:${routeElement.id}`);
                this.editorViewState.setGeometries([
                    [`POINT:${routeElement.id}`, anchor],
                    [`HIGHLIGHT:${routeElement.id}`, geometry ?? anchor]
                ]);
                changesSubject.next({ geometry: anchor, anchor });
            }));

        const mouseDownOnLineString = this.mouseDownOnLineString$
            .pipe(
                tap(() => this.adapter.setMapMouseCursor(MapMouseCursor.Move)),
                switchMap((previousPoint) =>
                    this.mouseMoveUntilMouseUp$.pipe(
                        map((currentPoint) => ({ previousPoint, currentPoint })),
                        tap(({ currentPoint }) => previousPoint = currentPoint),
                        finalize(() => this.adapter.setMapMouseCursor(MapMouseCursor.Default))
                    ))
            ).subscribe(({ currentPoint }) => {
                // The polygon the current point is intersecting with.
                const intersectingPolygon = SnapTool.getNearestPolygon(polygons, currentPoint);
                const newLineString = getPolygonSubsection(intersectingPolygon, currentPoint, currentShiftState, routeElementEditorOperation.doorWidth);
                const midpointOfnewLineString = SnapTool.calculateStartEndOrMidPointOfLineString(newLineString, currentPoint);

                this.editorViewState.setGeometries([
                    [`LINE:${routeElement.id}`, newLineString]
                ]);

                changesSubject.next({ geometry: newLineString, anchor: midpointOfnewLineString });
            });

        this.editorViewState.setRouteElement(routeElement);

        return routeElementEditorOperation;
    }

    /**
     * Makes a location editable on the map.
     *
     * @param {ExtendedLocation} location
     * @returns {LocationEditorOperation}
     */
    public editLocation(location: ExtendedLocation): LocationEditorOperation {
        const changesSubject: Subject<{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }> = new Subject();
        const id = location.id;

        const onComplete = (): void => {
            this.displayRuleService.revertDisplayRule(location.id);
            this.editorViewState.clear();
            this.adapter.off('zoom_changed', onZoom);
        };

        const onZoom = (): void => {
            const geometry: GeoJSON.Polygon = this.editorViewState.getGeometry(`POLYGON:${id}`) as GeoJSON.Polygon;

            if (!geometry) {
                return;
            }

            if (location?.locationSettings?.obstacle === true) {
                this.editorViewState.setGeometries([
                    [`AREAASOBSTACLE:${id}`, geometry],
                    [`HIGHLIGHT:${id}`, geometry]
                ]);
            } else {
                this.editorViewState.setGeometry(`HIGHLIGHT:${id}`, geometry);
            }

        };

        this.displayRuleService.overrideDisplayRule(location.id, { visible: false });
        this.adapter.on('zoom_changed', onZoom);
        return new LocationEditorOperation(location, changesSubject, this.editorViewState, this, onComplete);
    }

    /**
     * Makes graph bounds editable on the map.
     *
     * @param {GraphData} graphData
     * @returns {GraphBoundsEditorOperation}
     */
    public editGraphBounds(graphData: GraphData): GraphBoundsEditorOperation {
        const changesSubject: Subject<{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }> = new Subject();

        const onComplete = (): void => {
            this.editorViewState.clear();
        };
        return new GraphBoundsEditorOperation(graphData, changesSubject, this.editorViewState, this, onComplete);
    }

    /**
     * Returns a GeoJSON.Point when the map is clicked.
     *
     * @returns {Promise<GeoJSON.Point>}
     */
    abstract drawPoint(): Observable<GeoJSON.Point>;

    /**
     * Initialize freehand drawing mode.
     *
     * @protected
     * @returns {Observable<GeoJSON.Polygon>}
     */
    protected abstract drawAreaFreehand(): Observable<GeoJSON.Polygon>;

    /**
     * Initialize rectangular drawing mode.
     *
     * @protected
     * @returns {Observable<GeoJSON.Polygon>}
     */
    protected abstract drawAreaRectangular(): Observable<GeoJSON.Polygon>;

    /**
     * Enables the creation of a door.
     *
     * @param {RouteElement} door
     * @param {number} doorWidth
     * @returns {Observable<GeoJSON.Point>}
     */
    public placeDoor(door: RouteElement, doorWidth: number): RouteElementEditorOperation {
        let currentShiftState: boolean = false;
        let newLineString: LineString;
        let centerOfnewLineString: GeoJSON.Point;

        const changesSubject: Subject<{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }> = new Subject();
        const { multiLineString, polygons } = this.getRoomGeometries(door.floorIndex);

        this.adapter.setMapMouseCursor(MapMouseCursor.Crosshair);
        this.adapter.isClickable = false;

        const isShiftKeyPressed = this.isShiftKeyPressed().subscribe(isPressed => currentShiftState = isPressed);

        const mouseClick = this.mouseClick$
            .pipe(finalize(() => onComplete()))
            .subscribe(() => {
                changesSubject.next({ geometry: newLineString, anchor: centerOfnewLineString });
            });

        const mouseMove = this.mouseMove$
            .subscribe(currentPoint => {
                // The polygon the current point is intersecting with.
                const intersectingPolygon = SnapTool.getNearestPolygon(polygons, currentPoint);
                newLineString = getPolygonSubsection(intersectingPolygon, currentPoint, currentShiftState, doorWidth);
                centerOfnewLineString = SnapTool.calculateStartEndOrMidPointOfLineString(newLineString, currentPoint);

                door.geometry = newLineString;

                this.editorViewState.setRouteElement(door);
            });

        const onComplete = (): void => {
            this.editorViewState.clear();
            this.displayRuleService.revertDisplayRule(door.id);
            this.adapter.isClickable = true;
            this.adapter.setMapMouseCursor(MapMouseCursor.Default);
            mouseMove.unsubscribe();
            mouseClick.unsubscribe();
            isShiftKeyPressed.unsubscribe();
        };

        const onRotate = null;

        const routeElementEditorOperation = new RouteElementEditorOperation(door, changesSubject, this.editorViewState, multiLineString, onComplete, onRotate);

        return routeElementEditorOperation;
    }

    /**
     * Retruns the routeElement's floor's rooms as a list of polygons and as a multiLineString.
     *
     * @param {number} floorIndex
     * @returns {{ multiLineString: GeoJSON.MultiLineString, polygons: GeoJSON.Polygon[] }}
     */
    private getRoomGeometries(floorIndex: number): { multiLineString: GeoJSON.MultiLineString, polygons: GeoJSON.Polygon[] } {
        let multiLineString: GeoJSON.MultiLineString;
        const polygons: GeoJSON.Polygon[] = [];

        this.locationService.getLocations().subscribe((locations) => {
            const roomsOnCurrentFloor = locations.filter(location => location.pathData.floor === floorIndex && location.locationType === LocationType.Room);

            const roomsOnCurrentFloorCoordinates = roomsOnCurrentFloor.reduce((coordinates, room) => {
                polygons.push(room.geometry as GeoJSON.Polygon);
                return coordinates.concat(room.geometry.coordinates);
            }, []);
            multiLineString = turf.multiLineString(roomsOnCurrentFloorCoordinates)?.geometry;

        });

        return { multiLineString, polygons };
    }

    /**
     * With given two coordinates, draw linestring.
     *
     * @param {{ floorIndex?: number }} floorIndex
     * @returns {Observable<GeoJSON.LineString>}
     */
    public drawLineString({ floorIndex }: { floorIndex?: number }): Observable<GeoJSON.LineString> {
        return new Observable((subscriber) => {
            let intersectingPolygon: GeoJSON.Polygon;
            let nearestPointOnLineToMouseCursor: GeoJSON.Point;
            let polygonsThatDoorCanExistIn: GeoJSON.Polygon[] = [];
            let currentShiftState: boolean = false;
            let numberOfClicks = [];
            let visualLineString;

            let startPoint;

            this.adapter.setMapMouseCursor(MapMouseCursor.Crosshair);
            this.adapter.isClickable = false;

            const { polygons } = this.getRoomGeometries(floorIndex);
            this.isShiftKeyPressed().subscribe(isPressed => currentShiftState = isPressed);

            // Display rule for visual representation of linestring start and end points.
            const displayRule = {
                clickable: false,
                icon: '/assets/images/routelayer/circleFilled.svg',
                imageSize: { width: 16, height: 16 }
            };

            // Visual representation of a linestring.
            const lineStringDisplayRule = {
                clickable: false,
                visible: true,
                polygon: {
                    visible: true,
                    strokeColor: '#EF6CCE', //midtColors.color.pink.base.value
                    strokeWidth: 10,
                    strokeOpacity: 1,
                    fillOpacity: 1
                }
            };

            const mouseMove = this.mouseMove$
                .subscribe(async currentPoint => {
                    const viewModels = [];
                    let lineString: LineStringViewModel;

                    // Decides to which polygons linestring can snap to.
                    if (numberOfClicks.length % 2 !== 0) {
                        intersectingPolygon = SnapTool.getNearestPolygon(polygonsThatDoorCanExistIn, currentPoint);
                    } else {
                        intersectingPolygon = SnapTool.getNearestPolygon(polygons, currentPoint);
                    }

                    // Closes point on polygon subsection to mouse pointer.
                    nearestPointOnLineToMouseCursor = getNearestPointOnLineToPolygon(intersectingPolygon, currentPoint, currentShiftState);

                    // If we get a first click convert polygon to a linestring and create linestring view model that snaps to this linestring (Polygon before).
                    if (numberOfClicks.length > 0) {
                        const convertToLine = turf.polygonToLine(intersectingPolygon) as Feature<GeoJSON.LineString>;
                        const shiftedLocationLineString = shiftPolygonStartEndAwayFromDoorPoint(convertToLine, startPoint.geometry.coordinates);

                        visualLineString = turf.lineSlice(startPoint.geometry.coordinates, nearestPointOnLineToMouseCursor.coordinates, shiftedLocationLineString);

                        // Visual representation of linestring when creating one.
                        lineString = LineStringViewModel.create('visualLineString', visualLineString.geometry, lineStringDisplayRule, SortKey.LINESTRING + 1e7, MapsIndoorsData.RouteElement);
                        viewModels.push(lineString);
                    }

                    if (startPoint) {
                        // Visual representation of the linestring start point (blue dot).
                        const startPointViewModel = await this.createPointViewModel('lineStringStartPoint', startPoint.geometry, displayRule);
                        viewModels.push(startPointViewModel);
                    }

                    // Visual representation of the linestring end point (blue dot).
                    const endPointViewModel = await this.createPointViewModel('lineStringEndPoint', nearestPointOnLineToMouseCursor, displayRule);
                    viewModels.push(endPointViewModel);

                    this.setViewData(viewModels);
                });

            const mouseClick = this.mouseClick$
                .subscribe((value) => {
                    // Buffer the point so if it is clicked at the intersection of couple of polygons, we are able to snap to all of them.
                    if (value !== undefined) {
                        const point = turf.point(nearestPointOnLineToMouseCursor.coordinates);
                        const bufferedPoint = turf.buffer(point, 5, { units: 'centimeters' });

                        // From all polygons, find those that clicked point intersects with.
                        polygonsThatDoorCanExistIn = findPolygonsIntersectingWithGeometry(polygons, bufferedPoint);

                        startPoint ??= point;
                    }

                    numberOfClicks.push(value);

                    // When linestring is created notify observer and clear view states.
                    if (numberOfClicks.length % 2 === 0) {
                        subscriber.next(visualLineString);
                        numberOfClicks = [];
                        mouseMove.unsubscribe();
                        mouseClick.unsubscribe();
                        this.editorViewState.clear();
                        return subscriber.complete();
                    }
                });

            // Teardown logic.
            subscriber.add(() => {
                mouseMove.unsubscribe();
                mouseClick.unsubscribe();
                this.editorViewState.clear();
            });
        });
    }

    /**
     * Creates Point View Model based on passed parameters.
     *
     * @param {string} id
     * @param {GeoJSON.Point} geometry
     * @param {DisplayRule} displayRules
     * @returns {Promise<PointViewModel>}
     */
    private async createPointViewModel(id: string, geometry: GeoJSON.Point, displayRules: DisplayRule): Promise<PointViewModel> {
        const pointViewModel = await PointViewModel.create(id, geometry, displayRules, SortKey.POINT + 1e7, MapsIndoorsData.Unknown);
        return pointViewModel;
    }

    /**
     * Enables the creation of an area given a drawing mode.
     *
     * @param {DrawingMode} mode - Drawing mode.
     * @returns {Observable<GeoJSON.Polygon>}
     */
    public drawArea(mode: DrawingMode): Observable<GeoJSON.Polygon> {
        switch (mode) {
            case DrawingMode.FreeHand: {
                this.adapter.setMapMouseCursor(MapMouseCursor.Crosshair);
                this.adapter.isClickable = false;
                return this.drawAreaFreehand()
                    .pipe(finalize(() => {
                        this.adapter.isClickable = true;
                        this.adapter.setMapMouseCursor(MapMouseCursor.Default);
                    }));
            }
            case DrawingMode.Rectangular:
                this.adapter.isClickable = false;
                this.adapter.setMapMouseCursor(MapMouseCursor.Crosshair);
                return this.drawAreaRectangular()
                    .pipe(finalize(() => {
                        this.adapter.isClickable = true;
                        this.adapter.setMapMouseCursor(MapMouseCursor.Default);
                    }));
        }
    }

    /**
     * Convert the outer ring of a polygon to points.
     *
     * @protected
     * @static
     * @param {GeoJSON.Polygon} polygon
     * @returns {GeoJSON.Feature<GeoJSON.Point>[]}
     * @memberof GeodataEditor
     */
    protected static convertToPoints(polygon: GeoJSON.Polygon): GeoJSON.Feature<GeoJSON.Point>[] {
        const outerRing = polygon?.coordinates[0] ?? [];
        return outerRing.slice(0, -1).map((position, index) => createPoint(position, { index }, { id: `HANDLE:${index}` }));
    }

    /**
     * Calculate the midpoints for all line segments in a polygon.
     *
     * @protected
     * @static
     * @param {GeoJSON.Polygon} polygon
     * @returns {GeoJSON.Feature<GeoJSON.Point>[]}
     */
    protected static calculateMidpoints(polygon: GeoJSON.Polygon): GeoJSON.Feature<GeoJSON.Point>[] {
        const midpoints: GeoJSON.Feature<GeoJSON.Point>[] = [];
        const outerRing = polygon?.coordinates[0] ?? [];
        for (let i = 1; i < outerRing.length; i++) {
            const p1 = outerRing[i - 1];
            const p2 = outerRing[i];
            const midpoint = createMidpoint(p1, p2);
            midpoint.properties.insertAfter = i;
            midpoint.id = `MIDPOINT:${i}`;
            midpoints.push(midpoint);
        }

        return midpoints;
    }

    /**
     * Calculate the offset between two points (bearing and distance).
     *
     * @protected
     * @static
     * @param {GeoJSON.Position} p0
     * @param {GeoJSON.Position} p1
     * @returns {{ distance: number, bearing: number }}
     */
    protected static calculateOffset(p0: GeoJSON.Position, p1: GeoJSON.Position): { distance: number, bearing: number } {

        const bearing = calculateBearing(p0, p1);
        const distance = calculateDistance(p0, p1);

        return { distance, bearing };
    }

    /**
     * Create a rectangular polygon given two points.
     *
     * @static
     * @param {GeoJSON.Point} p0
     * @param {GeoJSON.Point} p1
     * @returns {GeoJSON.Polygon}
     * @memberof GeodataEditor
     */
    public static createEnvelope(p0: GeoJSON.Point, p1: GeoJSON.Point): GeoJSON.Polygon {
        const south = Math.min(p0.coordinates[1], p1.coordinates[1]);
        const west = Math.min(p0.coordinates[0], p1.coordinates[0]);
        const north = Math.max(p0.coordinates[1], p1.coordinates[1]);
        const east = Math.max(p0.coordinates[0], p1.coordinates[0]);
        return bboxPolygon([west, south, east, north])?.geometry;
    }

    /**
     * For observing when the given key is pressed.
     *
     * @param {string} key
     * @returns {Observable<boolean>}
     */
    private isKeyPressed(key: string): Observable<boolean> {
        // Observables for all keydown and keyup events
        const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown');
        const keyUp$ = fromEvent<KeyboardEvent>(document, 'keyup');

        // All KeyboardEvents - emitted only when KeyboardEvent changes (key or type)
        const keyEvents$ = merge(keyDown$, keyUp$).pipe(
            distinctUntilChanged((previousKeyEvent, currentKeyEvent) => previousKeyEvent.code === currentKeyEvent.code && previousKeyEvent.type === currentKeyEvent.type),
            share()
        );

        // Create KeyboardEvent Observable for specified KeyCode
        const createKeyPressStream = (charCode: string): any => keyEvents$.pipe(filter((event) => event.code === charCode.valueOf()));

        // Emits true if key is beingpressed down and false if not.
        return createKeyPressStream(key)
            .pipe(
                map((keyboardEvent: KeyboardEvent) => {
                    return keyboardEvent.type === 'keydown' ? true : false;
                })
            );
    }

    /**
     * Returns an Observable for listening on the Shift keys' state.
     *
     * @returns {Observable<boolean>}
     */
    private isShiftKeyPressed(): Observable<boolean> {
        const keyShiftLeft = this.isKeyPressed('ShiftLeft');
        const keyShiftRight = this.isKeyPressed('ShiftRight');

        return merge(keyShiftLeft, keyShiftRight);
    }
}
