import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { OpeningHours } from '../../../services/OccupantServices/occupant.model';

@Component({
    selector: 'daily-opening-hours-form',
    templateUrl: './daily-opening-hours-form.component.html',
    styleUrls: ['./daily-opening-hours-form.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DailyOpeningHoursFormComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => DailyOpeningHoursFormComponent),
        multi: true,
    }]
})
export class DailyOpeningHoursFormComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() day: string;

    public onChange: (value: OpeningHours | null) => void = () => { };
    public onTouch: () => void = () => { };

    public startTimeEqualsEndTime: boolean = false;
    public dailyOpeningHoursFormGroup: FormGroup = this.formBuilder.group({
        startTime: [null],
        endTime: [null],
        closedAllDay: [false, []]
    });

    constructor(
        private formBuilder: FormBuilder
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.dailyOpeningHoursFormGroup.controls['closedAllDay'].valueChanges.subscribe((closedValue) => {
            const timeControls = [
                this.dailyOpeningHoursFormGroup.controls['startTime'] as FormControl,
                this.dailyOpeningHoursFormGroup.controls['endTime'] as FormControl
            ];

            if (closedValue === true || this.dailyOpeningHoursFormGroup.disabled) {
                this.disableFormControls(timeControls);
            } else {
                this.enableFormControls(timeControls);
            }
        });

        this.dailyOpeningHoursFormGroup.valueChanges
            .subscribe(() => {
                const startTimeControl = this.dailyOpeningHoursFormGroup.controls['startTime'];
                const endTimeControl = this.dailyOpeningHoursFormGroup.controls['endTime'];
                // Check if the start and end time are equal and not null.
                this.startTimeEqualsEndTime = (startTimeControl.value === endTimeControl.value) && (startTimeControl.value !== null || endTimeControl.value !== null);

                // Disabled formControls are ignored by the valueChanges observable. Therefore, we need to use the rawValue.
                const openingHours: OpeningHours = this.dailyOpeningHoursFormGroup.getRawValue();

                // Need to ensure that we do not save empty strings to the database.
                Object.keys(openingHours).forEach((key) => {
                    openingHours[key] = openingHours[key] === '' ? null : openingHours[key];
                });

                this.onChange(openingHours);
            });
    }

    /**
     * Enables the form controls.
     *
     * @param {FormControl[]} formControls
     */
    private enableFormControls(formControls: FormControl[]): void {
        formControls.forEach((formControl) => {
            if (formControl.disabled) {
                formControl.enable({ emitEvent: false });
            }
        });
    }

    /**
     * Disables the form controls.
     *
     * @param {FormControl[]} formControls
     */
    private disableFormControls(formControls: FormControl[]): void {
        formControls.forEach((formControl) => {
            if (!formControl.disabled) {
                formControl.disable({ emitEvent: false });
            }
        });
    }

    /**
     * WriteValue.
     *
     * @param {OpeningHours} openingHours
     */
    writeValue(openingHours: OpeningHours): void {
        if (!openingHours) {
            this.dailyOpeningHoursFormGroup.reset({ startTime: null, endTime: null, closedAllDay: false });
            return;
        }
        this.dailyOpeningHoursFormGroup.reset(openingHours);
    }

    /**
     * RegisterOnChange.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * RegisterOnTouched.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * SetDisabledState.
     *
     * @param {boolean} isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.dailyOpeningHoursFormGroup.disable() : this.dailyOpeningHoursFormGroup.enable();
    }

    /**
     * Validates the form.
     *
     * @returns {ValidationErrors | null}
     */
    validate(): ValidationErrors | null {
        // Return the validation result from the form group
        return this.dailyOpeningHoursFormGroup.valid ? null : { invalid: true };
    }
}