import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { Observable, fromEvent, merge } from 'rxjs';
import { GeodataEditor } from '../GeodataEditor';
import { MapboxAdapter } from '../../MapAdapter/Mapbox/MapboxAdapter';
import { EventData, MapLayerMouseEvent, MapMouseEvent, MapboxEvent, PointLike } from 'mapbox-gl';
import { FeatureClass } from '../../../viewmodels/FeatureClass';
import { auditTime, distinctUntilChanged, filter, map, mapTo, share, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { MouseButton } from '../../shared/enums/MouseButton';

const STROKE_COLOR = midt['tailwind-colors'].blue[500].value;
const STROKE_WIDTH = 2;

const EDITOR_HANDLES_SOURCE = 'EDITOR_HANDLES_SOURCE';
const EDITOR_HANDLES_LAYER = 'EDITOR_HANDLES_LAYER';
const EDITOR_DATA_SOURCE = 'EDITOR_DATA_SOURCE';
const EDITOR_LINE_LAYER = 'EDITOR_LINE_LAYER';

export class MapboxGeodataEditor extends GeodataEditor {

    #map: mapboxgl.Map;

    readonly #mouseMove$: Observable<MapMouseEvent> = new Observable<MapMouseEvent>((subscriber) => {
        const mouseMoveHandler = (mapMouseEvent: MapMouseEvent): void => subscriber.next(mapMouseEvent);
        this.adapter.getMap().then(map => {
            map.on('mousemove', mouseMoveHandler);
            subscriber.add(() => map.off('mousemove', mouseMoveHandler));
        });
    }).pipe(auditTime(16), share());

    readonly #mapDragStart$: Observable<MapboxEvent<MouseEvent | TouchEvent> & EventData> = new Observable<MapboxEvent<MouseEvent | TouchEvent> & EventData>((subscriber) => {
        this.adapter.getMap().then(map => {
            map.once('dragstart', (event) => subscriber.next(event));
        });
    });

    readonly #mouseUp$: Observable<MouseEvent> = fromEvent<MouseEvent>(document, 'mouseup');

    readonly #mouseDown$: Observable<MapLayerMouseEvent> = new Observable<MapLayerMouseEvent>((subscriber) => {
        const mouseDownHandler = (event: MapLayerMouseEvent): void => {
            //Create a box around the click point to query for features.
            const box = [[event.point.x + 2, event.point.y + 2], [event.point.x - 2, event.point.y - 2]] as [PointLike, PointLike];
            event.features = this.#map.queryRenderedFeatures(box, { layers: [EDITOR_HANDLES_LAYER, EDITOR_LINE_LAYER], filter: ['!=', ['get', 'clickable'], false] }) ?? [];
            subscriber.next(event);
        };

        this.adapter.getMap().then(map => {
            map.on('mousedown', mouseDownHandler);
            subscriber.add(() => map.off('mousedown', mouseDownHandler));
        });
    });

    /**
     * Observable for map click event.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     */
    public readonly mapClick$: Observable<GeoJSON.Point> = this.#mouseDown$
        .pipe(
            filter(event => event.originalEvent.button === MouseButton.Main && event.features.length === 0),
            switchMap(mapMouseEvent => fromEvent<MouseEvent>(document, 'mouseup')
                .pipe(
                    take(1),
                    takeWhile(mouseUp => !mouseUp.defaultPrevented),
                    takeUntil(merge(this.#mapDragStart$, this.#mouseMove$)),
                    tap(mouseUp => mouseUp.preventDefault()),
                    mapTo({ type: 'Point', coordinates: mapMouseEvent.lngLat.toArray() } as GeoJSON.Point),
                )
            ),
            share()
        );

    /**
     * Observable for mouse move event.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     */
    public readonly mouseMove$: Observable<GeoJSON.Point> = this.#mouseMove$.pipe(map(event => ({ type: 'Point', coordinates: event.lngLat.toArray() } as GeoJSON.Point)), share());

    /**
     * Observable for drag event on geometry handles.
     *
     * @readonly
     * @type {Observable<{ index: number, position: number[] }>}
     */
    public readonly handleDrag$: Observable<{ index: number, position: number[] }> = this.#mouseDown$
        .pipe(
            filter(event => event.originalEvent.button === MouseButton.Main &&
                event.features.length > 0 &&
                event.features[0].layer.id === EDITOR_HANDLES_LAYER),
            tap(event => event.preventDefault()),
            map(({ features }) => (features[0].id ?? features[0].properties.index) as number),
            switchMap((index) => this.#mouseMove$.pipe(
                takeUntil(this.#mouseUp$),
                map((event) => ({ index, position: event.lngLat.toArray() })))
            ),
            share()
        );

    /**
     * Observable for click on geometry handles.
     *
     * @readonly
     * @type {Observable<number>}
     */
    public readonly handleClick$: Observable<number> = this.#clickOnLayer(EDITOR_HANDLES_LAYER, MouseButton.Main)
        .pipe(map(feature => (feature.id ?? feature.properties.index) as number), share());

    /**
     * Observable for right click on geometry handles.
     *
     * @readonly
     * @type {Observable<number>}
     */
    public readonly handleRightClick$: Observable<number> = this.#clickOnLayer(EDITOR_HANDLES_LAYER, MouseButton.Secondary)
        .pipe(map(feature => (feature.id ?? feature.properties.index) as number), share());

    /**
     * Observable for mouse hover event.
     *
     * @readonly
     * @type {Observable<GeoJSON.Feature>}
     */
    public readonly mouseHover$: Observable<GeoJSON.Feature> = this.#mouseMove$
        .pipe(
            map(event => {
                const box: [PointLike, PointLike] = [[event.point.x + 2, event.point.y + 2], [event.point.x - 2, event.point.y - 2]];
                const layers = [EDITOR_HANDLES_LAYER, EDITOR_LINE_LAYER];
                return this.#map.queryRenderedFeatures(box, { layers })[0] as GeoJSON.Feature;
            }),
            distinctUntilChanged((a, b) => a?.id === b?.id),
            share()
        );

    /**
     * Observable for geometry click event.
     *
     * @readonly
     * @type {Observable<GeoJSON.Feature>}
     */
    public readonly geometryClick$: Observable<GeoJSON.Feature> = this.#clickOnLayer(EDITOR_LINE_LAYER);

    /**
     * Observable for geometry hover event.
     *
     * @readonly
     * @type {Observable<GeoJSON.Feature>}
     */
    public readonly geometryHover$: Observable<GeoJSON.Feature> = merge(this.#mouseEnter([EDITOR_LINE_LAYER]), this.#mouseLeave([EDITOR_LINE_LAYER]))
        .pipe(map(event => event?.features?.[0]), share());

    public constructor(protected readonly adapter: MapboxAdapter) {
        super(adapter);
        adapter.getMap().then(mapbox => {
            this.#map = mapbox;

            this.#map.on('style.load', this.#setupEditorLayers.bind(this));

            this.#setupEditorLayers(this.#map);
        });

    }

    /**
     * Setup the editor layers on the map.
     *
     * @param {mapboxgl.Map} map - The map object.
     */
    #setupEditorLayers(map: mapboxgl.Map): void {
        map.addSource(EDITOR_HANDLES_SOURCE, {
            type: 'geojson',

            data: { type: 'FeatureCollection', features: [] }
        });

        map.addSource(EDITOR_DATA_SOURCE, {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: [] }
        });

        //This layer is for outlining polygons.
        map.addLayer({
            'id': EDITOR_LINE_LAYER,
            'type': 'line',
            'source': EDITOR_DATA_SOURCE,
            'filter': ['any',
                ['==', '$type', 'LineString'],
                ['==', '$type', 'Polygon'],
                ['==', 'featureClass', FeatureClass.LINESTRING],
                ['==', 'featureClass', FeatureClass.POLYGON]
            ],
            'layout': {
                'line-sort-key': ['get', 'sortKey']
            },
            'paint': {
                'line-width': ['number', ['get', 'strokeWidth'], STROKE_WIDTH],
                'line-color': ['to-color', ['get', 'strokeColor'], STROKE_COLOR],
                'line-opacity': ['number', ['get', 'strokeOpacity'], 1],
            }
        });

        map.addLayer({
            'id': EDITOR_HANDLES_LAYER,
            'type': 'circle',
            'source': EDITOR_HANDLES_SOURCE,
            'filter': ['==', '$type', 'Point'],
            'layout': {
                'circle-sort-key': ['get', 'sortKey']
            },
            'paint': {
                'circle-color': ['to-color', ['get', 'fillColor'], midt['tailwind-colors'].white.value],
                'circle-radius': ['number', ['get', 'circleRadius'], 4],
                'circle-stroke-color': ['to-color', ['get', 'strokeColor'], STROKE_COLOR],
                'circle-stroke-width': ['number', ['get', 'strokeWidth'], 1],
                'circle-opacity': ['number', ['get', 'opacity'], 1],
            },
        });
    }

    /**
     * Click on a layer and return the corresponding feature.
     *
     * @param {string} layerId - The ID of the layer.
     * @param {MouseButton} mouseButton - The mouse button to listen for (default: MouseButton.Main).
     * @returns {Observable<GeoJSON.Feature>} - An observable that emits the clicked feature.
     */
    #clickOnLayer(layerId: string, mouseButton: MouseButton = MouseButton.Main): Observable<GeoJSON.Feature> {
        return this.#mouseDown$.pipe(
            filter(event => event.originalEvent.button === mouseButton && event.features.length > 0),
            switchMap(event => fromEvent<MouseEvent>(document, 'mouseup')
                .pipe(
                    take(1),
                    takeUntil(this.#mouseMove$),
                    takeWhile(mouseUp => !mouseUp.defaultPrevented),
                    map(mouseUp => {
                        const clicked = event.features[0];
                        if (clicked?.layer?.id === layerId) {
                            mouseUp.preventDefault();
                            return clicked;
                        }

                        return;

                    }),
                    filter(feature => feature !== undefined)
                )
            )
        );
    }

    /**
     * For observing mouse over events.
     *
     * @private
     * @param {string[]} layers - The layer name.
     * @returns {Observable<MapMouseEvent>}
     */
    #mouseEnter(layers: string[]): Observable<MapLayerMouseEvent> {
        return new Observable((subscriber) => {
            const onMouseEnter = (e: MapLayerMouseEvent): void => {
                const features = e.features ?? [];
                if (e.defaultPrevented) {
                    return;
                }

                if (features?.length === 0) {
                    return;
                }

                e.preventDefault();
                subscriber.next(e);
            };
            this.adapter.getMap().then(map => {
                subscriber.add(() => map.off('mouseenter', onMouseEnter));
                map.on('mouseenter', layers, onMouseEnter);
            });
        });
    }

    /**
     * For observing mouse leave events.
     *
     * @private
     * @param {string[]} layers - The layer name.
     * @returns {Observable<MapLayerMouseEvent>}
     */
    #mouseLeave(layers: string[]): Observable<MapLayerMouseEvent> {
        return new Observable((subscriber) => {
            const onMouseLeave = (e: MapLayerMouseEvent): void => {
                if (e.defaultPrevented) {
                    return;
                }

                e.preventDefault();
                subscriber.next(e);
            };
            this.adapter.getMap().then(map => {
                subscriber.add(() => map.off('mouseleave', onMouseLeave));
                map.on('mouseleave', layers, onMouseLeave);
            });
        });
    }

    /**
     * Render handles for a given geometry.
     *
     * @param {GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon} geometry - The geometry to render handles for.
     * @returns {void}
     */
    public renderHandlesFor(geometry: GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon): void {
        const handlesSource = this.#map.getSource(EDITOR_HANDLES_SOURCE) as mapboxgl.GeoJSONSource;
        const handles: GeoJSON.Feature<GeoJSON.Point, { index: number, [key: string]: unknown }>[] = convertToPoints(geometry).map((geometry, index) => {
            return { type: 'Feature', geometry, properties: { index } };
        });

        if (geometry.type === 'Polygon') {
            handles.pop();
        }

        handlesSource.setData({ type: 'FeatureCollection', features: handles });
    }

    /**
     * Render editor handles.
     *
     * @param {GeoJSON.Feature<GeoJSON.Point>[]} features - The features to render handles for.
     * @returns {void}
     */
    public renderAsHandles(features: GeoJSON.Feature<GeoJSON.Point>[]): void {
        const handlesSource = this.#map.getSource(EDITOR_HANDLES_SOURCE) as mapboxgl.GeoJSONSource;
        handlesSource.setData({
            type: 'FeatureCollection', features: features.map((feature, index) => {
                return { ...feature, properties: { ...feature.properties, index } };
            })
        });
    }

    /**
     * Clear the handles from the map.
     *
     * @returns {void}
     */
    public clearHandles(): void {
        const handlesSource = this.#map.getSource(EDITOR_HANDLES_SOURCE) as mapboxgl.GeoJSONSource;
        handlesSource.setData({ type: 'FeatureCollection', features: [] });
    }

    /**
     * Render features on the map.
     *
     * @param {GeoJSON.Feature[]} features - The feature to render.
     * @returns {void}
     */
    public render(features: GeoJSON.Feature[]): void {
        const dataSource = this.#map.getSource(EDITOR_DATA_SOURCE) as mapboxgl.GeoJSONSource;
        dataSource.setData({ type: 'FeatureCollection', features });
    }

    /**
     * Clear the features from the map.
     *
     * @returns {void}
     */
    public clear(): void {
        const dataSource = this.#map.getSource(EDITOR_DATA_SOURCE) as mapboxgl.GeoJSONSource;
        dataSource.setData({ type: 'FeatureCollection', features: [] });
        this.clearHandles();
    }
}

/**
 * Convert a MultiPoint, LineString or Polygon geometry to a collection of Point geometries.
 *
 * @param {GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon} geometry - The geometry to convert.
 * @returns {GeoJSON.Point[]} - The collection of Point geometries.
 */
function convertToPoints(geometry: GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon): GeoJSON.Point[] {
    switch (geometry.type) {
        case 'MultiPoint':
        case 'LineString':
            return geometry.coordinates.map((coordinates) => ({ type: 'Point', coordinates }));
        case 'Polygon':
            return geometry.coordinates[0].map((coordinates) => ({ type: 'Point', coordinates }));
    }
}