export enum MapStateType {
    Default,
    Edit,
    Create
}

export enum DialogType {
    create = 'CREATE',
    edit = 'EDIT',
    delete = 'DELETE'
}

export enum Accessibility {
    OpenForAll,
    OpenForSome,
    Closed
}

/**
 * Icons' url.
 *
 * @export
 * @enum {string}
 */
export enum Icons {
    AnyEntry = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/entrance.png',
    BikeParking = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/bike-park.png',
    Default = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/misc/default-marker.png',
    Door = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/door@2x.png',
    DoorLocked = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/door-locked@2x.png',
    DoorModified = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/door-modified@2x.png',
    Elevator = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/building-icons/elevator.png',
    ElevatorDoor = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/elevator-door@2x.png',
    ElevatorDoorLocked = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/elevator-door-locked@2x.png',
    ElevatorDoorModified = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/elevator-door-modified@2x.png',
    Escalator = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/building-icons/escalator.png',
    ExternalDoor = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/externaldoor.png',
    ExternalDoorClosed = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/external-door-closed@2x.png',
    ExternalDoorRestricted = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/external-door-restricted@2x.png',
    Hatchway = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/hatchway@2x.png',
    HatchwayLocked = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/hatchwayClosed@2x.png',
    HatchwayModified = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/hatchwayRestricted@2x.png',
    Highlight = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/move-select.png',
    InterBuildingDoor = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/inter-building.png',
    Ladder = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/ladder.png',
    Opening = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/opening@2x.png',
    Parking = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/parking.png',
    Ramp = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/ramp.png',
    RouteModifier = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/route-modifier.png',
    Stairs = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/building-icons/stairs.png',
    TransitEntry = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/building-icons/bus-stop.png',
    WalkingEntry = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/walkingentry.png',
    WheelchairLift = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/wheelchairlift.png',
    WheelchairRamp = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/wheelchairramp.png',
}

export enum ExternalApis {
    GoogleTranslate = 'https://translation.googleapis.com/language/translate/v2',
    GoogleStreetView = 'https://maps.googleapis.com/maps/api/streetview'
}

export enum ExternalApiKeys {
    GoogleGeneralKey = 'AIzaSyAfEnWA1TSDqp1jpzYkgF-r65c4MkA5ZZw'
}

export enum ProviderType {
    GoogleCalendar = 'google',
    Office365 = 'office365'
}

export enum RegexPatterns {
    Guid = '^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$',
    NoWhiteSpace = '^\\S+$',
    NumericalNoDecimals = '^-?[0-9]+$',
    //Cannot be empty. Can only contain letters, numbers, hyphens, underscores, and spaces.
    OnlyLettersNumbersHyphensUnderscoresSpaces = '[a-zA-Z0-9\\s\\-\\_]*[a-zA-Z0-9\\s\\-\\_]+',
    Email = '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_\\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?',
    UriWithTrailingSlash = '(http|ftp|https):\\/\\/[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?\\/',
    HexColor = '^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$'
}

export enum UnitSystem {
    Imperial = 'imperial',
    Metric = 'metric'
}

export enum NotificationType {
    Info = 'info',
    Warning = 'warning',
    Success = 'success',
    Error = 'error',
    None = 'none'
}

export enum Status {
    None,
    Active = 1 << 0,      // 01 -- the bitshift is unnecessary, but done for consistency
    Searchable = 1 << 1,  // 10
    All = ~(~0 << 2)      // 11
}

export enum GeoJSONGeometryType {
    Point = 'Point',
    MultiPoint = 'MultiPoint',
    LineString = 'LineString',
    MultiLineString = 'MultiLineString',
    Polygon = 'Polygon',
    MultiPolygon = 'MultiPolygon',
    GeometryCollection = 'GeometryCollection'
}

export enum DrawingMode { // corresponds to google.maps.drawing.OverlayType
    Polygon = 'polygon',
    Rectangle = 'rectangle'
}
