import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';

abstract class HighlightViewModelProperties implements ViewModelProperties {
    clickable: boolean;
    featureClass: FeatureClass;
    fillOpacity: number;
    iconVisible: boolean;
    originalId: string;
    originalType: MapsIndoorsData;
    pixelOffset: number;
    sortKey: number;
    strokeColor: string;
    strokeOpacity: number;
    strokeWidth: number;
    visible: boolean;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a HighlightViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @returns {Promise<HighlightViewModelProperties>}
     */
    static create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData): HighlightViewModelProperties {
        return {
            clickable: false,
            featureClass: FeatureClass.HIGHLIGHT,
            fillOpacity: 0,
            iconVisible: false,
            originalId: id,
            originalType,
            pixelOffset: (displayRule?.polygon?.strokeWidth ?? 0) / 2,
            sortKey: SortKey.POINT + sortKey,
            strokeColor: '#EF6CCE',
            strokeOpacity: 1,
            strokeWidth: 2,
            visible: true,
            zoomRange: [-Infinity, Infinity],
        };
    }
}

export class HighlightViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.LineString;
    readonly properties: HighlightViewModelProperties;

    private constructor(id: string, geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.LineString, properties: HighlightViewModelProperties) {
        this.id = `HIGHLIGHT:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory function to create a HighlightViewModel from an id, a point, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {GeoJSON.Point} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @returns {HighlightViewModel}
     */
    static async create(id: string, geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.LineString, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData): Promise<HighlightViewModel> {
        const properties = HighlightViewModelProperties.create(id, index, displayRule, originalType);
        return await new HighlightViewModel(id, geometry, properties);
    }
}