import { BaseMapAdapter } from '../MapAdapter/BaseMapAdapter';
import { GoogleMapsAdapter } from '../MapAdapter/Google/GoogleMapsAdapter';
import { MapboxAdapter } from '../MapAdapter/Mapbox/MapboxAdapter';
import { GoogleMapsGeodataEditor } from './GeodataEditorAdapter/GoogleMapsGeodataEditor';
import { MapboxGeodataEditor } from './GeodataEditorAdapter/MapboxGeodataEditor';
import { GeodataEditor } from './GeodataEditor';


export class GeodataEditorFactory {
    private static instances = new WeakMap();
    /**
     * Create an instance of the GeoDataEditor.
     *
     * @static
     * @param {BaseMapAdapter} mapAdapter
     * @returns {GeodataEditor}
     */
    static create(mapAdapter: BaseMapAdapter): GeodataEditor {
        if (this.instances.has(mapAdapter)) {
            return this.instances.get(mapAdapter);
        } else {
            let geodataEditor;
            switch (mapAdapter?.constructor) {
                case GoogleMapsAdapter:
                    geodataEditor = new GoogleMapsGeodataEditor(mapAdapter as GoogleMapsAdapter);
                    break;
                case MapboxAdapter:
                    geodataEditor = new MapboxGeodataEditor(mapAdapter as MapboxAdapter);
                    break;
                default:
                    return;
            }

            this.instances.set(mapAdapter, geodataEditor);
            return geodataEditor;
        }
    }
}