<div class="filters">
    <!-- Sort -->
    <mi-dropdown
        [label]="sortLabel"
        [items]="sortDropDownItems"
        (change)="onSortChange($event)"
        *ngIf="!isComponentShown">
    </mi-dropdown>

    <!-- File Type -->
    <mi-dropdown
        [label]="fileTypeLabel"
        [items]="fileTypeDropDownItems"
        multiple
        (change)="onFileTypeChange($event)"
        *ngIf="!isComponentShown">
    </mi-dropdown>

    <!-- Filter -->
    <mi-dropdown
        [label]="categoryLabel"
        [items]="mediaCategoryDropDownItems"
        multiple
        items-order="asc"
        (change)="onFilterChange($event)"
        *ngIf="!isComponentShown">
    </mi-dropdown>

    <!-- Search -->
    <div class="search">
        <div class="search__container">
            <input
                #searchInput
                type="search"
                aria-label="Text"
                class="mi-input search__input"
                tabindex="0"
                placeholder="Text"
                (keydown)="onKeyDown($event)"
                (input)="onSearchChange($event)">
            <span class="search__shadow"></span>
            <span class="media__tooltip media__tooltip--right">Search for file name</span>
        </div>
    </div>
</div>
