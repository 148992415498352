import { OperatorFunction, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMediaItem } from '../media-library/media-item/media-item.model';
import { MediaFilterOptions } from '../media-library/media-filter-bar/media-filter-options.model';
import { FuzzySearchPipe } from '../shared/fuzzy-search/fuzzy-search.pipe';
import { MediaSortBy, MediaFileType, MediaCategory, MediaFileDimension } from '../media-library/media.enum';
import { isNullOrUndefined } from '../../utilities/Object';

const fuzzy = new FuzzySearchPipe();

/**
 * Transformation operator to filter locations.
 *
 * @param {MediaFilterOptions} filterOptions
 * @returns {OperatorFunction<IMediaItem[], IMediaItem[]>}
 */
export function filterMediaItems(filterOptions: MediaFilterOptions): OperatorFunction<IMediaItem[], IMediaItem[]> {
    filterOptions = filterOptions ?? {};
    return pipe(
        filterMediaItemsBySort(filterOptions.sortBy),
        filterMediaItemsByFilter(filterOptions.mediaCategory),
        filterMediaItemsByFileType(filterOptions.fileTypes),
        filterMediaItemsByQuery(filterOptions.searchQuery),
        filterMediaItemsByDimensions(filterOptions.fileDimensions)
    );
}

/**
 * Sorts media items.
 *
 * @param {MediaSortBy[]} sortBy
 * @returns {OperatorFunction<IMediaItem[], IMediaItem[]>}
 */
function filterMediaItemsBySort(sortBy: MediaSortBy[]): OperatorFunction<IMediaItem[], IMediaItem[]> {
    return map((mediaItems: IMediaItem[]) => {
        if (isNullOrUndefined(mediaItems)) {
            return [];
        }

        if (sortBy?.length > 0) {
            switch (sortBy[0]) {
                case MediaSortBy.Recent:
                    return mediaItems.sort((previousMediaItem: IMediaItem, nextMediaItem: IMediaItem) => {
                        return nextMediaItem.lastModified.localeCompare(previousMediaItem.lastModified);
                    });
                case MediaSortBy.Oldest:
                    return mediaItems.sort((previousMediaItem: IMediaItem, nextMediaItem: IMediaItem) => {
                        return previousMediaItem.lastModified.localeCompare(nextMediaItem.lastModified);
                    });
                case MediaSortBy.AZ:
                    return mediaItems.sort((previousMediaItem: IMediaItem, nextMediaItem: IMediaItem) => {
                        return previousMediaItem.name.localeCompare(nextMediaItem.name);
                    });
                case MediaSortBy.ZA:
                    return mediaItems.sort((previousMediaItem: IMediaItem, nextMediaItem: IMediaItem) => {
                        return nextMediaItem.name.localeCompare(previousMediaItem.name);
                    });
            }
        }
        return mediaItems;
    });
}

/**
 * Filters the media items based on selected file types.
 *
 * @param {MediaFileType[]} fileTypes
 * @returns {OperatorFunction<IMediaItem[], IMediaItem[]>}
 */
function filterMediaItemsByFileType(fileTypes: MediaFileType[]): OperatorFunction<IMediaItem[], IMediaItem[]> {
    return map((mediaItems: IMediaItem[]) => {
        if (fileTypes?.length > 0) {
            return mediaItems.filter(
                (mediaItem: IMediaItem) => {
                    return (<any>Object).values(fileTypes).includes(mediaItem.type);
                });
        }
        return mediaItems;
    });
}

/**
 * Filters the media items based on width and height.
 *
 * @param {MediaFileDimension[]} fileDimensions
 * @returns {OperatorFunction<IMediaItem[], IMediaItem[]>}
 */
function filterMediaItemsByDimensions(fileDimensions: MediaFileDimension[]): OperatorFunction<IMediaItem[], IMediaItem[]> {
    return map((mediaItems: IMediaItem[]) => {
        if (fileDimensions?.length > 0) {
            return mediaItems.filter(
                (mediaItem: IMediaItem) => {
                    return mediaItem.height === fileDimensions[0] && mediaItem.width === fileDimensions[1];
                });
        }
        return mediaItems;
    });
}

/**
 * Filters the media items by filter.
 *
 * @param {MediaCategory[]} filters
 * @returns {OperatorFunction<IMediaItem[], IMediaItem[]>}
 */
function filterMediaItemsByFilter(filters: MediaCategory[]): OperatorFunction<IMediaItem[], IMediaItem[]> {
    return map((mediaItems: IMediaItem[]) => {
        if (filters?.length > 0) {
            return mediaItems.filter(
                (mediaItem: IMediaItem) => {
                    return (<any>Object).values(filters).includes(mediaItem.category);
                });
        }
        return mediaItems;
    });
}

/**
 * Filters the media items based on the user's search query.
 *
 * @param {string} query
 * @returns {OperatorFunction<IMediaItem[], IMediaItem[]>}
 */
function filterMediaItemsByQuery(query: string): OperatorFunction<IMediaItem[], IMediaItem[]> {
    return map((mediaItems: IMediaItem[]) => {
        // Constructing a new array where the name property in each object will include a '.' and the media's type
        const mediaItemNames = mediaItems.map(mediaItem => {
            return { name: mediaItem.name + '.' + mediaItem.type };
        });

        // Applying the filter to the new array.
        const filteredNames = fuzzy.transform(mediaItemNames, query, ['name'])
            .map(filteredName => filteredName.name.substring(0, filteredName.name.lastIndexOf('.')));

        // Filtering the original array.
        return mediaItems.filter(mediaItem => filteredNames.includes(mediaItem.name));
    });
}