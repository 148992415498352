import { Component, Input } from '@angular/core';

@Component({
    selector: 'save-button',
    templateUrl: './save-button.component.html',
    styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent {
    @Input() isLocked?: boolean;
    @Input() toolTipMessage?: string;
}