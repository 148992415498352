<panel-header (cancelEvent)="close()" header="Location Details" class="panel__header">
    <panel-header-tabs *ngIf="isOccupantModuleEnabled">
        <panel-header-tab *ngFor="let tab of tabs"
            [title]="tab.title"
            [id]="tab.id"
            [hasToggle]="tab.hasToggle"
            [isActive]="tab.isActive"
            [isDisabled]="tab.isDisabled"
            [isToggleChecked]="tab.isToggleChecked"
            (tabClicked)="onTabClicked($event)"
            (toggleChanged)="onToggleChanged($event)">
        </panel-header-tab>
    </panel-header-tabs>

    <button type="button"
        *ngIf="location?.id"
        class="button button--display-rule-editor"
        (click)="openDisplayRuleDetails($event,location)">
        <span class="tooltip">{{ locationForm?.dirty ? 'You have unsaved changes, save or discard before proceeding.' : 'Open the Display Rule editor'}}</span>
    </button>
    <button type="button"
        class="button button--duplicate"
        id="duplicate"
        *ngIf="canLocationBeDuplicated"
        (click)="onDuplicate()">
        <span class="tooltip">{{ locationForm?.dirty ? 'You have unsaved changes, save or discard before proceeding.' : 'Duplicate Location (Ctrl+D)'}}</span>
    </button>
    <button type="button"
        class="button button--delete"
        *ngIf="canLocationBeDeleted"
        (click)="onDelete()">
        <span class="tooltip">Delete Location</span>
    </button>
</panel-header>

<ng-container #container></ng-container>