import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'panel-header',
    templateUrl: './panel-header.component.html',
    styleUrls: ['./panel-header.component.scss']
})
export class PanelHeaderComponent {
    @Input() header: string = '';
    @Output() cancelEvent = new EventEmitter<void>();
}