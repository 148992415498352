import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FilterCategoriesBySelectedPipe, FilterCategoriesPipe } from '../pipes/filter-categories';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NumericalSortPipe, OrderBy } from '../pipes/orderby';

import { AddressFormComponent } from './form-components/address-form/address-form.component';
import { AliasesComponent } from '../aliases/aliases.component';
import { AliasesListComponent } from './form-components/aliases-list/aliases-list.component';
import { BuildingSelectorComponent } from './building-selector/building-selector.component';
import { CategorySelectComponent } from '../category-select/category-select.component';
import { ColorPickerInputComponent } from './form-components/color-picker-input/color-picker-input.component';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from './form-components/contact-form/contact-form.component';
import { CorrelationIdInputComponent } from './form-components/correlation-id-input/correlation-id-input.component';
import { CustomPropertiesComponent } from './custom-properties/custom-properties.component';
import { DailyOpeningHoursFormComponent } from './form-components/daily-opening-hours-form/daily-opening-hours-form.component';
import { DisplayRuleDetailsEditorComponent } from './display-rule-details-editor/display-rule-details-editor.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { FilterCustomersPipe } from '../customers/filter-customers';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuzzySearchPipe } from './fuzzy-search/fuzzy-search.pipe';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { LazyLoadDirective } from './directives/lazy-load.directive';
import { LockButtonComponent } from '../display-rule-details/lock-button/lock-button.component';
import { LockedDisplayRuleDirective } from '../display-rule-details/locked-display-rule-handler.directive';
import { MaterialElevationDirective } from './directives/material-elevation.directive';
import { MaterialModule } from '../material.module';
import { NavigatableContainerComponent } from './navigatable-container/navigatable-container.component';
import { NavigatableSectionComponent } from './navigatable-container/navigatable-section/navigatable-section.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OccupantAliasesListComponent } from './form-components/occupant-aliases-list/occupant-aliases-list.component';
import { OccupantCategoryDropdownComponent } from './form-components/occupant-category-dropdown/occupant-category-dropdown.component';
import { OccupantKindDropdownComponent } from './form-components/occupant-type-dropdown/occupant-type-dropdown.component';
import { OccupantTemplateDropdownComponent } from './form-components/occupant-template-dropdown/occupant-template-dropdown.component';
import { OrderByLanguage } from '../pipes/order-by-language.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { PanelFooterComponent } from './panel-footer/panel-footer.component';
import { PanelHeaderComponent } from './panel-header/panel-header.component';
import { PanelHeaderTabComponent } from './panel-header-tab/panel-header-tab.component';
import { PanelHeaderTabsComponent } from './panel-header-tabs/panel-header-tabs.component';
import { ResponsiveImageStyleDirective } from './directives/responsive-image-style.directive';
import { RouterModule } from '@angular/router';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SelectableInputComponent } from './form-components/selectable-input/selectable-input.component';
import { SetFocusDirective } from './directives/setFocus.directive';
import { StandardOpeningHoursFormComponent } from './form-components/standard-opening-hours-form/standard-opening-hours-form.component';
import { StatusBitComponent } from './status-bit/status-bit.component';
import { StreetViewComponent } from '../subcomponents/street-view/street-view.component';
import { SyncManagerComponent } from './sync-manager/sync-manager.component';
import { SyncManagerService } from './sync-manager/sync-manager.service';
import { TilesManagerComponent } from '../tiles-manager/tiles-manager.component';
import { ToggleSearchableComponent } from './toggle-searchable/toggle-searchable.component';
import { TranslationsFormComponent } from './form-components/translations-form/translations-form.component';
import { UserRolesAccessibilityComponent } from './user-roles-accessibility/user-roles-accessibility.component';
import { RestrictionsInputComponent } from './form-components/restrictions-input/restrictions-input.component';
import { VenuesSelectListComponent } from '../venues/venues-select-list/venues-select-list.component';
import { ZoomToInputComponent } from './form-components/zoom-to-input/zoom-to-input.component';
import { FormatDatePipe } from './date-pipe/format-date.pipe';
import { ObstacleInputComponent } from './form-components/obstacle-input/obstacle-input.component';
import { SaveButtonComponent } from './save-button/save-button.component';

/**
 * A module that is shared across several modules.
 *
 * @export
 * @class SharedModule
 */
@NgModule({
    declarations: [
        AddressFormComponent,
        AliasesComponent,
        AliasesListComponent,
        BuildingSelectorComponent,
        CategorySelectComponent,
        ColorPickerInputComponent,
        ContactFormComponent,
        CustomPropertiesComponent,
        CustomPropertiesComponent,
        CorrelationIdInputComponent,
        DailyOpeningHoursFormComponent,
        DisplayRuleDetailsEditorComponent,
        DragDropDirective,
        FilterCategoriesBySelectedPipe,
        FilterCategoriesPipe,
        FilterCustomersPipe,
        FuzzySearchPipe,
        ImageUploadComponent,
        ImageUploadComponent,
        InfoTooltipComponent,
        LazyLoadDirective,
        LazyLoadDirective,
        LockButtonComponent,
        LockedDisplayRuleDirective,
        MaterialElevationDirective,
        MaterialElevationDirective,
        NavigatableContainerComponent,
        NavigatableSectionComponent,
        NumericalSortPipe,
        OccupantAliasesListComponent,
        OccupantCategoryDropdownComponent,
        OccupantKindDropdownComponent,
        OccupantTemplateDropdownComponent,
        OrderBy,
        OrderByLanguage,
        ObstacleInputComponent,
        PaginationComponent,
        PanelFooterComponent,
        PanelHeaderComponent,
        PanelHeaderTabComponent,
        PanelHeaderTabsComponent,
        ResponsiveImageStyleDirective,
        SearchBoxComponent,
        SelectableInputComponent,
        SetFocusDirective,
        StandardOpeningHoursFormComponent,
        StatusBitComponent,
        StreetViewComponent,
        SyncManagerComponent,
        TilesManagerComponent,
        ToggleSearchableComponent,
        TranslationsFormComponent,
        UserRolesAccessibilityComponent,
        RestrictionsInputComponent,
        VenuesSelectListComponent,
        ZoomToInputComponent,
        FormatDatePipe,
        SaveButtonComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        MaterialModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    exports: [
        AddressFormComponent,
        AliasesComponent,
        AliasesListComponent,
        BuildingSelectorComponent,
        CategorySelectComponent,
        ColorPickerInputComponent,
        CommonModule,
        ContactFormComponent,
        CustomPropertiesComponent,
        CustomPropertiesComponent,
        CorrelationIdInputComponent,
        DailyOpeningHoursFormComponent,
        DisplayRuleDetailsEditorComponent,
        DragDropDirective,
        FilterCategoriesBySelectedPipe,
        FilterCategoriesPipe,
        FilterCustomersPipe,
        FlexLayoutModule,
        FormsModule,
        FuzzySearchPipe,
        ImageUploadComponent,
        ImageUploadComponent,
        InfoTooltipComponent,
        LockButtonComponent,
        LockedDisplayRuleDirective,
        MaterialModule,
        NavigatableContainerComponent,
        NavigatableSectionComponent,
        NgxSpinnerModule,
        NgxSpinnerModule,
        NumericalSortPipe,
        OccupantAliasesListComponent,
        OccupantCategoryDropdownComponent,
        OccupantKindDropdownComponent,
        OccupantTemplateDropdownComponent,
        OrderBy,
        OrderByLanguage,
        ObstacleInputComponent,
        PaginationComponent,
        PanelFooterComponent,
        PanelHeaderComponent,
        PanelHeaderTabComponent,
        PanelHeaderTabsComponent,
        ReactiveFormsModule,
        ResponsiveImageStyleDirective,
        SearchBoxComponent,
        SelectableInputComponent,
        SetFocusDirective,
        StandardOpeningHoursFormComponent,
        StatusBitComponent,
        StreetViewComponent,
        SyncManagerComponent,
        TilesManagerComponent,
        ToggleSearchableComponent,
        TranslationsFormComponent,
        UserRolesAccessibilityComponent,
        RestrictionsInputComponent,
        VenuesSelectListComponent,
        ZoomToInputComponent,
        FormatDatePipe,
        SaveButtonComponent
    ],
    providers: [
        OrderBy,
        OrderByLanguage,
        SyncManagerService,
        FormatDatePipe,
        FuzzySearchPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }