import { Component, OnDestroy } from '@angular/core';
import { SolutionService } from '../../services/solution.service';
import { NotificationService } from '../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DisplayRule } from '@mapsindoors/typescript-interfaces';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'main-display-rule',
    templateUrl: './main-display-rule.component.html',
    styleUrls: ['./main-display-rule.component.scss']
})

export class MainDisplayRuleComponent implements OnDestroy {

    private subscriptions = new Subscription();
    private _solutionConfig;
    private updateData;

    public _mainDisplayRule: [DisplayRule, DisplayRule?] = [null];

    /**
     * Set additional information to be displayed in the panel-header component.
     */
    public header: string;

    /**
     * Toggle visibility of geometry related display rule settings.
     */
    public isGeometrySettingsVisible: boolean = true;

    /**
     * Property that defines if 3D Walls section is visible.
     */
    public is3DWallsSectionVisible: boolean = true;

    constructor(
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
    ) {
        this.spinner.show();
        const solutionConfig = this.solutionService.solutionConfig$
            .pipe(tap(() => this.spinner.hide()))
            .subscribe(solutionConfig => {
                this._solutionConfig = solutionConfig;
                this._mainDisplayRule = [solutionConfig.mainDisplayRule] as [DisplayRule, DisplayRule?];
                this.updateData = this._solutionConfig;
            });

        this.subscriptions.add(solutionConfig);
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Get solutionConfig values.
     *
     * @returns {void}
     */
    public get solutionConfig(): void {
        return this._solutionConfig;
    }

    /**
     * Get Main Display Rule values.
     *
     * @returns {[DisplayRule, DisplayRule?]}
     */
    public get displayRules(): [DisplayRule, DisplayRule?] {
        return this._mainDisplayRule;
    }

    /**
     * When submitting accept and save changes made in Main Display Rule.
     *
     * @param {Event} event
     * @returns {void}
     */
    public onSubmit(event: Event): void {
        this.spinner.show();
        this.updateData.mainDisplayRule = event;

        // In order to save data to Main Display Rules, we need to nullify those values. As soon as we have graphic labels for those, we can remove this code.
        this.updateData.buildingHighlightDisplayRule.labelStyle.graphic = null;
        this.updateData.stateDisplayRules.hover.labelStyle.graphic = null;
        this.updateData.stateDisplayRules.hoverHighlight.labelStyle.graphic = null;
        this.updateData.stateDisplayRules.hoverSelection.labelStyle.graphic = null;

        this.solutionService.updateSolutionConfig(this.updateData)
            .pipe(tap(() => this.spinner.hide()))
            .subscribe(() => {
                this.spinner.show();
                this.notificationService.showSuccess('Changes saved successfuly!');
                this.spinner.hide();
            }, () => {
                this.notificationService.showError('Something went wrong. Please try again.');
            });
    }
}