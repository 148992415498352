import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SolutionService } from '../services/solution.service';
import { Solution } from '../solutions/solution.model';

@Injectable()
export class OccupantsGuard implements CanActivate {

    constructor(
        private solutionService: SolutionService,
        private router: Router
    ) {}

    /**
     * Determines whether Occupant-related routes can be activated.
     *
     * @returns {(Observable<boolean | UrlTree> | boolean | UrlTree)}
     */
    canActivate(): Observable<boolean | UrlTree> | boolean | UrlTree {
        return this.solutionService.getCurrentSolution()
            .pipe(
                take(1),
                map(solution => {
                    if (solution.modules && Solution.hasModule(solution, 'occupants')) {
                        return true;
                    }
                    /* Redirect with UrlTree to prevent race conditions in some cases where multiple guards redirect.
                    With UrlTree, the highest priority guard wins.*/
                    return this.router.createUrlTree([environment.startPage]);
                })
            );
    }
}
