import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '../notification.service';
import { Observable, of } from 'rxjs';
import { SolutionService } from '../solution.service';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const API_ENDPOINT = environment.APIEndpoint;

@Injectable({ providedIn: 'root' })
export class OccupantAssignmentAlgorithmService {
    private currentSolutionId: string;

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService,
        private solutionService: SolutionService
    ) {
        this.solutionService.getCurrentSolution().pipe(
            tap(solution => this.currentSolutionId = solution.id)
        ).subscribe();
    }

    /**
     * Run Occupant Assignment Algorithm.
     *
     * @returns {Observable<void>}
     */
    public runOccupantAssignmentAlgorithm(): Observable<void> {
        return this.http.put<void>(`${API_ENDPOINT}${this.currentSolutionId}/api/occupant/assignment/solution`, null).pipe(
            catchError((err: Error) => {
                this.notificationService.showError(err);
                return of(null);
            })
        );
    }
}