import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, concatMap, delay, distinctUntilChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { BuildingService } from '../../buildings/building.service';
import { FloorOutlineMapViewModelFacory } from '../../buildings/FloorOutlineMapViewModelFactory/FloorOutlineMapViewModelFacory';
import { DerivedGeometry } from '../../derived-geometry/DerivedGeometry';
import { DerivedGeometryService } from '../../derived-geometry/DerivedGeometry.service';
import { DerivedGeometryViewModelFactory } from '../../derived-geometry/DerivedGeometryViewModelFactory';
import { Location } from '../../locations/location.model';
import { LocationService } from '../../locations/location.service';
import { LocationMapViewModelFactory } from '../../locations/LocationMapViewModelFactory/LocationMapViewModelFactory';
import { FiltersBarComponent } from '../../map/filters-bar/filters-bar.component';
import { BaseMapAdapter, MapAdapterType, MapModule, MapMouseCursor, MapOptions, MapType } from '../../MapAdapter/BaseMapAdapter';
import { GoogleMapsAdapter } from '../../MapAdapter/Google/GoogleMapsAdapter';
import { MapboxAdapter } from '../../MapAdapter/Mapbox/MapboxAdapter';
import { NetworkService } from '../../network-access/network.service';
import { filterLocations } from '../../operators/filterLocations';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { SolutionService } from '../../services/solution.service';
import { MapsIndoorsData } from '../../shared/enums/MapsIndoorsData';
import { VenueService } from '../../venues/venue.service';
import { MapAdapterMediator } from '../map-adapter.mediator';
import { MapUIComponents, MapUIService } from '../map-ui-service/map-ui.service';
import { RouteElementDetailsEditorComponent } from '../route-element-details-editor/route-element-details-editor.component';
import { MapSidebarService } from '../map-sidebar/map-sidebar.service';
import { RouteElement } from '../../map/route-element-details/route-element.model';
import { MapToolbarService } from '../map-toolbar/map-toolbar.service';
import { FilterOptions } from '../../map/filters-bar/filter-options.model';
import { Building, OUTDOOR_BUILDING } from '../../buildings/building.model';
import { Venue } from '../../venues/venue.model';
import { getCollectionBounds } from '../../shared/geometry-helper';
import { OutsideAreaMapViewModelFactory } from '../../buildings/BuildingsOutsideAreaHighlightMapViewModelFactory/OutsideAreaMapViewModelFactory';
import { getTileStyleFolderName } from '../MapViewState';
import { HoveredLocationViewModelFactory } from '../../locations/HoveredLocationViewModelFactory/HoveredLocationViewModelFactory';
import { Floor } from '../../buildings/floor.model';
import { FloorService } from '../../services/floor.service';
import { Solution } from '../../solutions/solution.model';
import { ActivatedRoute, Params } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { RouteNetworkMapViewModelFactory } from '../../network-access/RouteNetworkMapViewModelFactory/RouteNetworkMapViewModelFactory';
import { RouteElementMapViewModelFactory } from '../../network-access/RouteElementMapViewModelFactory/RouteElementMapViewModelFactory';
import { NgxSpinnerService } from 'ngx-spinner';
import { isNullOrUndefined } from '../../../utilities/Object';
import { EditorContainerComponent } from '../editor-container/editor-container.component';
import { TypesService } from '../../services/types.service';
import { LocationType } from '../../location-types/location-type.model';
import { SolutionConfig } from '../../solution-settings/solution-settings.model';
import { AreaAsObstacleMapViewModelFactory } from '../../network-access/AreaAsObstacleMapViewModelFactory/AreaAsObstacleMapViewModelFactory';
import { OccupantService } from '../../services/OccupantServices/occupant.service';
import { isObstacle } from '../../../app/locations/location-helper';

@Component({
    selector: 'map-adapter',
    templateUrl: './map-adapter.component.html',
    styleUrls: ['./map-adapter.component.scss'],
    providers: [MapAdapterMediator, MapSidebarService, MapToolbarService]
})
export class MapAdapterComponent implements OnInit {
    @ViewChild('map', { static: true, read: ElementRef })
    private mapElement: ElementRef;
    @ViewChild(FiltersBarComponent, { static: true })
    private filterBar!: FiltersBarComponent;

    #selectedFloor: Observable<Floor>;
    #selectedLocations: Set<Location> = new Set();
    #filteredLocationSubject: BehaviorSubject<Location[]> = new BehaviorSubject([]);
    #areasAsObstaclesLocations$: BehaviorSubject<Location[]> = new BehaviorSubject([]);
    #locationTypesList: LocationType[] = [];
    #solutionConfig: SolutionConfig;
    #filteredDerivedGeometries: Observable<DerivedGeometry[]>;
    #filteredOutsideArea: Observable<{ venue: Venue, buildings: Building[] }>;
    #hoveredFeatures: { polygon?: string, point?: string } = {};
    #hoveredLocation: Observable<Location> = new Subject();
    #routeElements$: Observable<RouteElement[]>;
    #routeNetwork$: Observable<GeoJSON.Feature[]>;
    #solution: Solution;

    private mapAdapter: BaseMapAdapter;
    private activeFilters: FilterOptions = {};
    private venue: Venue;
    private selectedBuildings: Building[] = [];

    public isFilterBarVisible: boolean = true;
    public isMapToolbarVisible: boolean = true;
    public isMapSidebarVisible: boolean = false;
    public isListViewOpen: boolean = false;
    public isBulkEditorOpen: boolean = false;
    public isRouteElementDetailsEditorVisible: boolean = false;


    /**
     * Can the bulk editor be opened.
     *
     * @public
     * @readonly
     * @type {boolean}
     */
    public get canOpenBulkEditor(): boolean {
        return !this.isBulkEditorOpen && this.selectedLocations.size > 0;
    }

    /**
     * Selected Locations.
     *
     * @readonly
     * @type {Set<Location>}
     * @memberof MapAdapterComponent
     */
    public get selectedLocations(): Set<Location> {
        return this.#selectedLocations;
    }

    /**
     * Filtered Locations.
     *
     * @readonly
     * @type {Observable<Location[]>}
     * @memberof MapAdapterComponent
     */
    public get filteredLocations$(): Observable<Location[]> {
        return this.#filteredLocationSubject.asObservable();
    }

    constructor(
        private activeRoute: ActivatedRoute,
        private solutionService: SolutionService,
        private buildingService: BuildingService,
        private venueService: VenueService,
        private displayRuleService: DisplayRuleService,
        private locationService: LocationService,
        private networkService: NetworkService,
        private mapUIService: MapUIService,
        private mapAdapterMediator: MapAdapterMediator,
        private derivedGeometryService: DerivedGeometryService,
        private mapSidebar: MapSidebarService,
        private mapToolbar: MapToolbarService,
        private floorService: FloorService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        private typesService: TypesService,
        private occupantService: OccupantService,
        public readonly mapAdapterType: MapAdapterType = MapAdapterType.GoogleMapsAdapter,
        @Inject('mapModules') private readonly mapModules: MapModule[] = []
    ) {
        this.mapUIService.visibleComponents$
            .pipe(delay(0))
            .subscribe(components => {
                this.isFilterBarVisible = (components & MapUIComponents.FilterBar) === MapUIComponents.FilterBar;
                this.isMapToolbarVisible = (components & MapUIComponents.MapToolbar) === MapUIComponents.MapToolbar;
                this.isMapSidebarVisible = (components & MapUIComponents.MapSidebar) === MapUIComponents.MapSidebar;
            });

        this.#filteredDerivedGeometries = this.#filteredLocationSubject.asObservable()
            .pipe(switchMap(locations => this.derivedGeometryService.derivedGeometries$
                .pipe(map(derivedGeometries => derivedGeometries.reduce((derivedGeometries, derivedGeometry) => {
                    if (locations.find(location => derivedGeometry.geodataId === location.id)) {
                        derivedGeometries.push(derivedGeometry);
                    }
                    return derivedGeometries;
                }, []))
                )));

        this.solutionService.selectedSolution$.subscribe(solution => this.#solution = solution);
    }

    /**
     * Create a new MapAdapter instance.
     *
     * @private
     * @param {MapOptions} mapOptions
     * @returns {BaseMapAdapter}
     * @memberof MapAdapterComponent
     */
    private createMapAdapter(mapOptions: MapOptions): BaseMapAdapter {
        switch (this.mapAdapterType) {
            case MapAdapterType.GoogleMapsAdapter:
                return new GoogleMapsAdapter(this.mapElement.nativeElement, mapOptions);
            case MapAdapterType.MapboxAdapter:
                return new MapboxAdapter(this.mapElement.nativeElement, mapOptions);
        }
    }

    /**
     * Angular OnInit.
     *
     * @memberof MapAdapterComponent
     */
    ngOnInit(): void {
        this.floorService.disableFloorSelector(false);
        this.filterBar.activeFilters$
            .pipe(
                tap(activeFilters => this.activeFilters = activeFilters),
                switchMap((filter) => {
                    return this.typesService.getTypes().pipe(
                        tap(types => this.#locationTypesList = types),
                        concatMap(() => this.solutionService.solutionConfig$.pipe(tap(solutionConfig => this.#solutionConfig = solutionConfig))),
                        concatMap(() => this.locationService.locations$),
                        tap((locations: Location[]) => {
                            const selectedIds = new Set(Array.from(this.selectedLocations.values()).map(location => location.id));
                            this.#selectedLocations = new Set(locations.filter(location => selectedIds.has(location.id)));
                        }),
                        filterLocations(filter, this.#locationTypesList, this.#solutionConfig)
                    );
                })
            )
            .subscribe(locations => {
                this.#filteredLocationSubject.next(locations);

                const areasAsObstacles = locations.filter(location => isObstacle(location, this.#locationTypesList, this.#solutionConfig));
                this.#areasAsObstaclesLocations$.next(areasAsObstacles);

                // Returns the selected buildings that are filtered.
                const selectedBuildings = this.activeFilters?.buildings?.length > 0 ? [...this.activeFilters.buildings] : [];
                this.selectedBuildings = selectedBuildings;

                // Boolean that checks if outside area is checked. Returns true or false.
                const outdoorAreaHighlightSelected = selectedBuildings.some(building => building.id === OUTDOOR_BUILDING.id);

                if (selectedBuildings.length > 0 && outdoorAreaHighlightSelected === false) {
                    const buildingBounds = getCollectionBounds(selectedBuildings.map(buidling => buidling.geometry.bbox));
                    this.mapAdapter.fitBounds(buildingBounds as GeoJSON.BBox);
                } else if (outdoorAreaHighlightSelected === true) {
                    this.mapAdapter.fitGeometry(this.venue.geometry as GeoJSON.Geometry);
                }
            });

        this.#filteredOutsideArea = this.filterBar.activeFilters$
            .pipe(
                switchMap((activeFilter) => {
                    if (activeFilter?.buildings?.some(building => building.id === OUTDOOR_BUILDING.id)) {
                        return this.venueService.selectedVenue$
                            .pipe(
                                withLatestFrom(this.buildingService.buildings$),
                                map(([venue, buildings]) => ({ venue, buildings }))
                            );
                    } else {
                        return of(undefined);
                    }
                }));

        this.#selectedFloor = combineLatest([this.buildingService.selectedFloor$, this.filterBar.activeFilters$])
            .pipe(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                filter(([floor, filter]) => !filter.buildings?.every(building => building.id === OUTDOOR_BUILDING.id)),
                map(([floor]) => floor)
            );

        const mapOptions: MapOptions = {
            zoom: 17,
            maxZoom: 22,
            minZoom: 15,
            disableDefaultUI: true,
            styles: [
                { 'featureType': 'poi', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] },
                { 'featureType': 'transit', 'stylers': [{ 'visibility': 'off' }] }
            ],
            modules: this.mapModules
        };

        this.mapAdapter = this.createMapAdapter(mapOptions);
        this.mapAdapterMediator.setMapAdapter(this.mapAdapter);

        this.mapAdapter.routeNetworkVisibility$.subscribe(isVisible => {
            if (isVisible) {
                this.#routeElements$ = this.networkService.routeElements$;
                this.#routeNetwork$ = combineLatest([this.venueService.selectedVenue$, this.buildingService.selectedFloor$])
                    .pipe(
                        filter(([venue, floor]) => { return (!isNullOrUndefined(venue) && !isNullOrUndefined(floor)); }),
                        //Only load the route network if the floor index is different from the previous one.
                        distinctUntilChanged(([,previousFloor], [,currentFloor]) => previousFloor?.floorIndex === currentFloor?.floorIndex),
                        tap(() => this.spinner.show()),
                        switchMap(([venue, floor]) => this.networkService.loadRouteNetwork(venue.graphId, floor.floorIndex)),
                        catchError((error) => {
                            this.spinner.hide();
                            this.mapAdapter.setVisibility('RouteNetwork', false);
                            this.notificationService.showError(error);
                            this.mapAdapter.isRouteNetworkLoaded = false;
                            return of(error);
                        }),
                        tap(() => {
                            this.spinner.hide();
                            this.mapAdapter.isRouteNetworkLoaded = true;
                        })
                    );

                this.mapAdapter.viewState.addDataSource(this.#routeNetwork$, new RouteNetworkMapViewModelFactory());
                this.mapAdapter.viewState.addDataSource(this.#routeElements$, new RouteElementMapViewModelFactory(this.displayRuleService));
                this.mapAdapter.viewState.addDataSource(this.#areasAsObstaclesLocations$, new AreaAsObstacleMapViewModelFactory(this.mapAdapter, this.displayRuleService));
            } else {
                this.mapAdapter.viewState.removeDataSource(this.#routeElements$);
                this.mapAdapter.viewState.removeDataSource(this.#routeNetwork$);
                this.mapAdapter.viewState.removeDataSource(this.#areasAsObstaclesLocations$);
            }
        });

        this.mapAdapter.once('ready', () => {
            this.solutionService.selectedSolution$.subscribe(solution => {
                const maxZoom = Solution.getMaxZoomLevel(solution);
                this.mapAdapter.setMaxZoom(maxZoom);
            });

            this.#hoveredLocation = this.mapAdapter.hover$.pipe(
                map(({ locationId, featureType }) => {
                    if (!this.mapAdapter.isHoverable) {
                        this.#hoveredFeatures = {};
                        return null;
                    }
                    this.mapAdapter.setMapMouseCursor(locationId ? MapMouseCursor.Pointer : MapMouseCursor.Default);

                    locationId = locationId?.replace('HOVER:', '');
                    const originalHoveredId = this.#hoveredFeatures[featureType];

                    // If the hover event comes with an empty Location ID, set the hovered feature of that type to nothing.
                    // Also depending on if the location was already hovered or not (other geometry types), return accordingly.
                    //
                    // Else return the location ID if it was not already hovered.
                    if (!locationId) {
                        this.#hoveredFeatures[featureType] = null;
                        if (originalHoveredId) {
                            const alreadyHovered = Object.keys(this.#hoveredFeatures).some(hoveredKey => {
                                return hoveredKey !== featureType && this.#hoveredFeatures[hoveredKey] === originalHoveredId;
                            });

                            if (!alreadyHovered) {
                                const anotherTypeHovered = Object.keys(this.#hoveredFeatures).find(hoveredKey => this.#hoveredFeatures[hoveredKey]);
                                if (anotherTypeHovered) {
                                    // In the case that there is a hovered of the other type, send that location
                                    return this.#hoveredFeatures[anotherTypeHovered];
                                } else {
                                    // Nothing is hovered anymore
                                    return null;
                                }
                            }
                        }
                    } else {
                        this.#hoveredFeatures[featureType] = locationId;
                        if (originalHoveredId !== locationId) {
                            const alreadyHovered = Object.keys(this.#hoveredFeatures).some(hoveredKey => {
                                return hoveredKey !== featureType && this.#hoveredFeatures[hoveredKey] === locationId;
                            });

                            if (!alreadyHovered) {
                                return locationId;
                            }
                        }
                    }
                }),
                filter(locationId => locationId !== undefined),
                map(locationId => this.locationService.getLocation(locationId))
            );

            this.solutionService.solutionConfig$.subscribe(solutionConfig => {
                const settings3D: { extrusionOpacity: number, wallOpacity: number } = solutionConfig.settings3D;
                this.mapAdapter.setSettings3D(settings3D);
            });

            this.mapAdapter.viewState.registerFloorObservable(this.buildingService.selectedFloor$);
            this.mapAdapter.viewState.addDataSource(this.filteredLocations$, new LocationMapViewModelFactory(this.displayRuleService));
            this.mapAdapter.viewState.addDataSource(this.#filteredOutsideArea, new OutsideAreaMapViewModelFactory(this.displayRuleService));
            this.mapAdapter.viewState.addDataSource(this.#hoveredLocation, new HoveredLocationViewModelFactory(this.displayRuleService, this.mapAdapter));
            this.mapAdapter.viewState.addDataSource(this.#selectedFloor, new FloorOutlineMapViewModelFacory(this.displayRuleService, this.solutionService));
            this.mapAdapter.viewState.addDataSource(this.#filteredDerivedGeometries, new DerivedGeometryViewModelFactory(this.displayRuleService));

            if (Solution.hasModule(this.#solution, 'floorplan')) {
                this.mapAdapter.viewState.addDataSource(this.derivedGeometryService.floorPlan$, new DerivedGeometryViewModelFactory(this.displayRuleService));
            }

            this.buildingService.buildings$
                .pipe(
                    // If there are buildings filtered, show only those buildings, otherwise show all buildings.
                    switchMap(buildings => this.filterBar.activeFilters$
                        .pipe(map(filters => filters.buildings?.length > 0 ? filters.buildings : buildings))),
                    // When moving around the map, highlight those buildings that are overlaping with map middle point or are the nearest to that point.
                    switchMap(buildings => this.mapAdapter.center$
                        .pipe(map(center => this.buildingService.getNearestBuilding(buildings, center)))
                    ),
                    filter(() => !this.isMapSidebarVisible)
                ).subscribe((building) => {
                    // If there is only one selected building, choose it as current building. Otherwise, select the one closest to the middle of the viewport.
                    if (this.selectedBuildings.length === 1) {
                        this.buildingService.setCurrentBuilding(this.selectedBuildings[0]);
                    } else {
                        this.buildingService.setCurrentBuilding(building);
                    }
                });

            this.buildingService.selectedFloor$
                .pipe(
                    distinctUntilChanged((prev, curr) => prev?.floorIndex === curr?.floorIndex && prev?.solutionId === curr?.solutionId),
                    switchMap(floor => this.venueService.selectedVenue$
                        .pipe(map(venue => ({ venue, floor })))
                    )
                ).subscribe(({ venue, floor }) => {
                    this.venue = venue;
                    const tileStyleFolderName = getTileStyleFolderName(venue);

                    if (venue.tilesUrl) {
                        const tilesUrl = venue.tilesUrl
                            .replace('{style}', tileStyleFolderName || '')
                            .replace('{floor}', floor?.floorIndex.toString() ?? venue.defaultFloor);
                        this.mapAdapter.setOverlayTileUrl(tilesUrl);
                    }
                });

            this.venueService.selectedVenue$
                .subscribe(venue => this.mapAdapter.fitGeometry(venue.geometry as GeoJSON.Geometry));

            this.mapAdapter.click$.subscribe(feature => {
                switch (feature?.properties?.originalType) {
                    case MapsIndoorsData.Location: {
                        const location = this.locationService.getLocation(feature.properties.originalId);
                        this.editLocation(location);
                        break;
                    }
                    case MapsIndoorsData.RouteElement: {
                        const routeElement = this.networkService.getRouteElement(feature.properties.originalId);
                        this.editRouteElement(routeElement);
                        break;
                    }
                }
            });

            //Handler RouteElements deep links.
            combineLatest([this.activeRoute.queryParams, this.buildingService.selectedBuilding$]) //We need to await the selection of a building before editing the route element.
                .pipe(
                    filter(([params, selectedBuilding]) => Object.keys(params).includes('routeElementId') && selectedBuilding !== null),
                    switchMap(([params]) => this.networkService.routeElements$.pipe(map(routeElements => [params, routeElements] as [Params, RouteElement[]]))),
                    map(([{ routeElementId }, routeElements]) => routeElements.find(routeElement => routeElement.id === routeElementId)),
                    take(1),
                )
                .subscribe(routeElement => {
                    if (!routeElement) return;
                    this.editRouteElement(routeElement);
                    this.mapAdapter.setVisibility('RouteNetwork', true);
                });

            //Handler Locations deep links.
            combineLatest([this.activeRoute.queryParams, this.locationService.locations$])
                .pipe(
                    filter(([params, locations]) => Object.keys(params).includes('locationId') && locations.length > 0),
                    take(1),
                    map(([{ locationId }]) => this.locationService.getLocation(locationId))
                )
                .subscribe(location => {
                    if (!location) return;
                    this.editLocation(location);
                });
        });
    }

    /**
     * Get the map zoom level.
     *
     * @returns {number}
     * @memberof MapAdapterComponent
     */
    getZoom(): number {
        return this.mapAdapter.getZoom();
    }

    /**
     * Set the map zoom level.
     *
     * @param {number} level
     * @memberof MapAdapterComponent
     */
    setZoom(level: number): void {
        this.mapAdapter.setZoom(level);
    }

    /**
     * Event handler for when the map-sidebar is closed.
     *
     * @memberof MapAdapterComponent
     */
    public onMapSidebarClosed(): void {
        this.mapUIService.show(MapUIComponents.Default);
    }

    /**
     * Toggle visibility of list view.
     *
     * @memberof MapAdapterComponent
     */
    public toggleListView(): void {
        this.isListViewOpen = !this.isListViewOpen;
        this.mapUIService.show(this.isListViewOpen ? MapUIComponents.FilterBar : MapUIComponents.Default);
    }

    /**
     * Sets the map type to be displayed.
     *
     * @public
     * @param {MapType} mapType
     */
    public setMapType(mapType: MapType): void {
        this.mapAdapter.setMapType(mapType);
    }

    /**
     * Gets the current map type.
     *
     * @public
     * @returns {MapType}
     */
    public getMapType(): MapType {
        return this.mapAdapter.getMapType();
    }

    /**
     * Sets the visibility for 2D or 3D layers.
     *
     * @public
     * @param {('2D' | '3D')} type
     * @param {boolean} visibility
     */
    public setVisibility(type: '2D' | '3D', visibility: boolean): void {
        this.mapAdapter.setVisibility(type, visibility);
    }

    /**
     * Get the state of visibility for 2D or 3D layers.
     *
     * @public
     * @param {('2D' | '3D')} type
     * @returns {boolean}
     */
    public getVisibility(type: '2D' | '3D'): boolean {
        return this.mapAdapter.getVisibility(type);
    }

    /**
     * Resets the rotation and the tilt properties of the map.
     */
    public resetRotateAndTilt(): void {
        this.mapAdapter.resetRotateAndTilt();
    }

    /**
     * Open the bulk editor.
     *
     * @memberof MapAdapterComponent
     */
    public onBulkEditorOpen(): void {
        this.isBulkEditorOpen = !this.isBulkEditorOpen;
    }

    /**
     * Bulk editor close event handler.
     *
     * @public
     */
    public onBulkEditorClose(): void {
        this.isBulkEditorOpen = false;
    }

    /**
     * Bulk locations update event handler.
     *
     * @public
     */
    public onBulkLocationsUpdate(): void {
        this.isBulkEditorOpen = false;
    }

    /**
     * Edit the selected Location.
     *
     * @param {Location} location
     * @memberof MapAdapterComponent
     */
    public editLocation(location: Location): void {
        const { componentInstance } = this.mapSidebar.open(EditorContainerComponent);
        if (componentInstance) {
            componentInstance.data = location;
            this.mapAdapter.isHoverable = false;
            this.locationService.selectLocation(location);
            componentInstance.closed.subscribe(() => {
                this.mapAdapter.isHoverable = true;
                this.locationService.selectLocation(null);
            });
        }
    }

    /**
     * Edit the selected route element.
     *
     * @param {RouteElement} routeElement
     * @memberof MapAdapterComponent
     */
    public editRouteElement(routeElement: RouteElement): void {
        const { componentInstance } = this.mapSidebar.open(RouteElementDetailsEditorComponent);
        if (componentInstance) {
            componentInstance.data = routeElement;
        }
    }
}