<div fxFlexFill fxLayout="column">
	<mat-toolbar class="mat-elevation-z2 page-toolbar">
		<button [disabled]="fileLoad" type="button" mat-raised-button (click)="getLog()" color="primary">Download log</button>
	</mat-toolbar>
	<mat-progress-bar mode="query" color="accent" *ngIf="fileLoad"></mat-progress-bar>

	<div fxFlex="<grow>" class="page-content" >
		<div class="section" fxLayout="row">
			<div fxFlex class="column" *ngIf="!fileLoad">
				<h2>Log filters</h2>
				<h3>Date interval</h3>
				<p>Use the date fields to select another interval. Logs will be deleted after 6 months.</p>
				<p *ngIf="false">Contact MapsPeople for log entries recorded more than 2 years ago</p>
				<div>
					<div>
						<mat-form-field>
							<input name="from" type="datetime-local" [(ngModel)]="logFrom" matInput placeholder="From"
							/>
						</mat-form-field>
						<mat-form-field style="margin-left: 16px;">
							<input name="to" type="datetime-local" [(ngModel)]="logTo" matInput placeholder="To"
							/>
						</mat-form-field>
					</div>
				</div>
				<div style="margin-top: 32px;">
					<h3>IDs</h3>
					<p>Enter any number of object IDs, separated by commas.</p>
					<div>
						<mat-form-field style="width: 320px;">
							<input matInput name="logusers" [(ngModel)]="logIds" placeholder="550c274006a86461a..." />
						</mat-form-field>
					</div>
				</div>
				<div style="margin-top: 32px;">
					<h3>Users</h3>
					<p>Enter any number of users, separated by commas.</p>
					<div>
						<mat-form-field style="width: 320px;">
							<input matInput name="logusers" [(ngModel)]="logUsers" placeholder="user1@company.com, user2@co..." />
						</mat-form-field>
					</div>
				</div>
				<div *ngIf="logTypes" style="margin-top: 32px;">
					<h3>Data types</h3>
					<p>Limit the log-entries by type</p>
					<div *ngFor="let type of logTypes">
						<mat-checkbox [(ngModel)]="type.selected">{{type.name | titlecase}}</mat-checkbox>
					</div>
				</div>
				<p style="margin-top: 32px;">Note: downloading log files may take a while.</p>
			</div>
			<div fxFlex class="column" *ngIf="fileLoad">
				<h2>Getting your file ready</h2>
				<p>Generating and downloading log-file - this may take a while</p>
				<p>You can continue using the CMS while you wait but do not close this window.</p>
			</div>
		</div>
	</div>
</div>