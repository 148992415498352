import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';

import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../services/notification.service';
import { MIModule, ModuleAccessLevel, SolutionService } from '../services/solution.service';
import { Customer } from './customer.model';
import { CustomerService } from './customer.service';

@Component({
    selector: 'customer',
    templateUrl: './customers.component.html'
})
export class CustomersComponent implements OnInit {
    public customers: Customer[];
    public currentCustomer: Customer;
    public selectedRow: string = ''; // Highlight row when selected
    public sortBy: string = 'name';
    public currentSortBy: string = 'name';
    public sortReverse: boolean = false;

    public searchQuery: string = '';
    public enableDelete: string = '';
    public customerModules: MIModule[] = [];
    public customerName = new UntypedFormControl('', [Validators.required, Validators.minLength(3)]);

    constructor(
        private solutionService: SolutionService,
        private customerService: CustomerService,
        private spinner: NgxSpinnerService,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.customerModules = this.solutionService.getModules()
            .filter(module => module.accessLevel.includes(ModuleAccessLevel.Customer));
        this.getCustomers();
    }

    /**
     * Fetch the list of customers.
     *
     * @memberof CustomerComponent
     */
    private getCustomers(): void {
        this.spinner.show();
        this.customerService.getCustomers()
            .subscribe(
                customers => {
                    this.customers = customers;
                    this.spinner.hide();
                },
                err => {
                    this.notificationService.showError(err);
                    this.spinner.hide();
                });
    }

    /**
     * Fetch a single customer.
     *
     * @param {Customer} customer
     * @memberof CustomerComponent
     */
    public getCustomer(customer: Customer): void {
        this.spinner.show('customer-spinner');
        this.customerService.getCustomer(customer.id)
            .subscribe(
                customer => {
                    this.currentCustomer = this.setSidebarValues(customer);
                    this.selectedRow = customer.id; // Highlight selected row
                    this.spinner.hide('customer-spinner');
                },
                err => {
                    this.notificationService.showError(err);
                    this.spinner.hide('customer-spinner');
                });
    }

    /**
     * Sets the values in the sidebar to the ones from the customer.
     *
     * @private
     * @param {Customer} customer
     * @returns {Customer}
     * @memberof CustomerComponent
     */
    private setSidebarValues(customer: Customer): Customer {
        if (customer) {
            this.customerName.setValue(customer.name);
            this.customerModules.forEach(module => {
                module.selected = customer.modules ? customer.modules.includes(module.value) : module.selected;
            });
        }
        return customer;
    }

    /**
     * Sort table by.
     *
     * @param {string} sortBy
     * @memberof CustomerComponent
     */
    public sortTable(sortBy: string): void {
        if (this.sortBy === sortBy) {
            this.sortBy = '-' + sortBy;
            this.sortReverse = !this.sortReverse;
        } else if (this.sortBy === '-' + sortBy) {
            this.sortBy = sortBy;
            this.sortReverse = !this.sortReverse;
        } else {
            this.sortBy = sortBy;
            this.currentSortBy = sortBy;
            this.sortReverse = false;
        }
    }

    /**
     * Create a new customer object.
     *
     * @memberof CustomerComponent
     */
    public createCustomer(): void {
        const customer = {
            id: null,
            name: '',
            logConfig: { eventLoggingEnabled: true }
        };

        this.currentCustomer = this.setSidebarValues(customer);
    }

    /**
     * If the customer's ID is undefined then create, else update the customer.
     *
     * @param {Customer} customer
     * @memberof CustomerComponent
     */
    public saveCustomer(customer: Customer): void {
        customer.name = this.customerName.value;
        customer.modules = this.customerModules.filter(module => module.selected).map(module => module.value);
        this.spinner.show('customer-spinner');

        if (!customer.id) {
            this.customerService.newCustomer(customer).subscribe(
                customerId => {
                    this.closeSidebar();
                    customer.id = customerId;
                    this.customers.push(customer);
                    this.notificationService.showSuccess('Customer created');
                    this.spinner.hide('customer-spinner');
                },
                err => {
                    this.notificationService.showError(err);
                    this.spinner.hide('customer-spinner');
                }
            );
        } else {
            this.customerService.updateCustomer(customer).subscribe(
                () => {
                    this.closeSidebar();
                    // Update the customers table
                    this.customers = this.customers.map(_customer => {
                        if (_customer.id === customer.id)
                            _customer = customer;

                        return _customer;
                    });
                    this.notificationService.showSuccess('Customer updated');
                    this.spinner.hide('customer-spinner');
                },
                (err) => {
                    this.notificationService.showError(err);
                    this.spinner.hide('customer-spinner');
                }
            );
        }
    }

    /**
     * Deletes a customer.
     *
     * @param {Customer} customer
     * @memberof CustomerComponent
     */
    public deleteCustomer(customer: Customer): void {
        this.customerService.deleteCustomer(customer).subscribe(
            () => {
                this.customers = this.customers.filter(_customer => _customer.id !== customer.id);
                this.notificationService.showSuccess('Customer deleted');
                this.closeSidebar();
            },
            err => {
                this.notificationService.showError(err);
                this.spinner.hide('customer-spinner');
            }
        );
        this.enableDelete = '';
    }

    /**
     * Closes the sidebar.
     *
     * @memberof CustomerComponent
     */
    public closeSidebar(): void {
        this.currentCustomer = null;
        this.selectedRow = '';
        this.enableDelete = '';
    }

    /**
     * Filters the customer table.
     *
     * @param {string} val
     * @returns
     * @memberof CustomerComponent
     */
    public filterCustomers(val: string): Customer[] {
        return val ? this.customers.filter((s) => new RegExp(val, 'gi').test(s.name)) : this.customers;
    }

    /**
     * Set sdk event logging.
     *
     * @param {MatSlideToggleChange} event
     * @memberof CustomersComponent
     */
    public onSdkLogsToggle(event: MatSlideToggleChange): void {
        if (!this.currentCustomer.logConfig) {
            this.currentCustomer.logConfig = {
                eventLoggingEnabled: event.checked
            };
        } else {
            this.currentCustomer.logConfig.eventLoggingEnabled = event.checked;
        }
    }
}
