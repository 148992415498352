<div class="header">
    <header>Map Behavior</header>
    <hr />
    <div class="description">
        Here you find settings that will have an impact on how the different elements on the Map behave.
    </div>
</div>

<form [formGroup]="mapBehaviorForm" class="body">
    <section class="body__setting-section">
        <h2 class="body__sub-header">Marker behavior</h2>
        <hr />
        <div>
            <div class="description body__sub-description">
                Control how overlapping markers should behave.
            </div>
            <label class="body__label mi-label">
                <input class="mi-toggle" type="checkbox" [checked]="clusteringEnabled"
                    formControlName="enableClustering" />
                <span>Enable clustering</span>
            </label>

            <div class="sub-section">
                <div class="sub-section__title">Collision Handling</div>
                <div class="sub-section__form">
                    <label class="mi-label mi-label--radio">
                        <input type="radio" class="mi-input" formControlName="collisionHandling"
                            name="collisionHandling" [value]="0" />
                        Do nothing
                    </label>
                    <label class="mi-label mi-label--radio">
                        <input type="radio" class="mi-input" formControlName="collisionHandling"
                            name="collisionHandling" [value]="1">
                        Remove labels first
                    </label>
                    <label class="mi-label mi-label--radio">
                        <input type="radio" class="mi-input" formControlName="collisionHandling"
                            name="collisionHandling" [value]="2" />
                        Remove icons first
                    </label>
                </div>
            </div>
        </div>
    </section>

    <section class="body__setting-section">
        <h2 class="body__sub-header">Selectable</h2>
        <hr />
        <div>
            <div class="description body__sub-description">
                Selectable setting is used to control whether it's possible to click on a Location on the Map or not.
                <info-tooltip
                    text="The 'Not Selectable' option does not affect the visibility in the search-result list. This behavior is changed in the Location Details in the Search section. Tip, use bulk edit for this."></info-tooltip>
            </div>
            <selectable-input formControlName="selectable"></selectable-input>
        </div>
    </section>

    <section class="body__setting-section" *ngIf="isOwnerOrAdmin">
        <h2 class="body__sub-header">Obstacle</h2>
        <hr />
        <div>
            <div class="description body__sub-description">
                Select the Area as an Obstacle setting if Areas should have an effect on the routing.
            </div>
            <obstacle-input formControlName="obstacle"></obstacle-input>
        </div>
    </section>
</form>

<panel-footer *ngIf="mapBehaviorForm.dirty" text="You have unsaved changes." class="footer">
    <button type="button" class="mi-button mi-button--link" (click)="onDiscard()">Discard</button>
    <button type="button" class="mi-button mi-button--primary" (click)="onSubmit()">Save and close</button>
</panel-footer>