import { areOpeningHoursEqual, OpeningHours } from '../../services/OccupantServices/occupant.model';

export class StandardOpeningHours {
    // TODO: change this to closedAllDay: boolean = false; when BE is done. Right same start+end time are not accepted by BE but will be changed.
    monday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    tuesday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    wednesday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    thursday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    friday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    saturday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    sunday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };

    constructor(standardOpeningHours?: StandardOpeningHours) {
        Object.assign(this, standardOpeningHours);
    }

    /**
     * Converts the object to JSON.
     *
     * @returns {unknown}
     */
    toJSON(): unknown {
        return Object.entries(this).reduce((standardOpeningHours, [day, openingHours]) => {
            if (openingHours?.closedAllDay) {
                standardOpeningHours[day] = null;
            } else {
                standardOpeningHours[day] = openingHours;
            }

            return standardOpeningHours;
        }, {});

    }

    /**
     * Creates a StandardOpeningHours object. Returns null if every property is empty.
     *
     * @param {StandardOpeningHours} standardOpeningHours
     * @returns {StandardOpeningHours}
     */
    static createStandardOpeningHours(standardOpeningHours?: StandardOpeningHours): StandardOpeningHours {
        if (Object.values(standardOpeningHours ?? {}).every((value) => value === null || value === '')) {
            return null;
        } else {
            return new StandardOpeningHours(standardOpeningHours);
        }
    }

    /**
     * Compares two StandardOpeningHours objects.
     * Returns true if they are equal, false otherwise.
     *
     * @param {StandardOpeningHours} a
     * @param {StandardOpeningHours} b
     * @returns {boolean}
     */
    static equals(a: StandardOpeningHours, b: StandardOpeningHours): boolean {
        if (a === b) {
            return true;
        }

        const entriesA = Object.entries(a ?? {});
        const entriesB = Object.entries(b ?? {});

        if (entriesA.length !== entriesB.length) {
            return false;
        }

        return entriesA.every(([day, openingHours]) => areOpeningHoursEqual(openingHours, b[day]));
    }
}