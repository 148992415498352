<div class="zoom-to">
    <div class="zoom-to__input" [ngClass]="{'max-zoom' :maxZoomChecked}">
        <input
            [attr.id]="inputId"
            #zoomToInput
            class="mi-input"
            type="number"
            [attr.min]="min"
            [attr.max]="max"
            [attr.disabled]="zoomInputDisabled"
            [formControl]="zoomToFormControl"
            (keydown)="onKeyDown($event)"/>
    </div>

    <label class="setting__checkbox-control">
        <input
            #maxZoomInput
            type="checkbox"
            [(ngModel)]="maxZoomChecked"
            (change)="onMaxZoomChange()" />
        Max zoom
    </label>
</div>
