import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { StandardOpeningHours } from '../../standard-opening-hours/standardOpeningHours.model';

@Component({
    selector: 'standard-opening-hours-form',
    templateUrl: './standard-opening-hours-form.component.html',
    styleUrls: ['./standard-opening-hours-form.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => StandardOpeningHoursFormComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => StandardOpeningHoursFormComponent),
        multi: true,
    }]
})
export class StandardOpeningHoursFormComponent implements OnInit, ControlValueAccessor, Validator {
    /**
     * Enable or disable the form depending on the locked state.
     */
    @Input() isLocked: boolean = false;

    public onChange: (value: StandardOpeningHours | null) => void = () => { };
    public onTouch: () => void = () => { };

    public standardOpeningHoursForm: FormGroup = this.formBuilder.group({
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: []
    });

    constructor(
        private formBuilder: FormBuilder
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.standardOpeningHoursForm.valueChanges.subscribe((standardOpeningHours: StandardOpeningHours) => {
            this.onChange(standardOpeningHours);
        });
    }

    /**
     * WriteValue.
     *
     * @param {StandardOpeningHours} standardOpeningHours
     */
    writeValue(standardOpeningHours: StandardOpeningHours): void {
        if (!standardOpeningHours) {
            this.standardOpeningHoursForm.reset({ monday: null, tuesday: null, wednesday: null, thursday: null, friday: null, saturday: null, sunday: null });
            return;
        }

        this.standardOpeningHoursForm.reset(standardOpeningHours);
    }

    /**
     * RegisterOnChange.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnChange(fn: (standardOpeningHours: StandardOpeningHours) => void): void {
        this.onChange = (standardOpeningHours: StandardOpeningHours) => {
            const finalStandardOpeningHours = this.standardOpeningHoursForm.disabled ? null : standardOpeningHours;
            fn(finalStandardOpeningHours);
        };
    }

    /**
     * RegisterOnTouched.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * SetDisabledState.
     *
     * @param {boolean} isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.standardOpeningHoursForm.disable() : this.standardOpeningHoursForm.enable();
    }

    /**
     * Validates the form.
     *
     * @returns {ValidationErrors | null}
     */
    validate(): ValidationErrors | null {
        // Return the validation result from the form group
        return this.standardOpeningHoursForm.valid ? null : { invalid: true };
    }
}