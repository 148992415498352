import { ComponentType } from '@angular/cdk/portal';
import { Component, ComponentRef, ElementRef, EventEmitter, HostListener, Injector, Output, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MapUIComponents, MapUIService } from '../map-ui-service/map-ui.service';
import { MapSidebar, MapSidebarRef } from './map-sidebar.interface';
import { MapSidebarService } from './map-sidebar.service';

@Component({
    selector: 'map-sidebar',
    templateUrl: './map-sidebar.component.html',
    styleUrls: ['./map-sidebar.component.scss'],
    //Encapsulation is set to `None` to allow for the child components to inherit styling from the parent. See https://angular.io/api/core/Component#encapsulation.
    encapsulation: ViewEncapsulation.None
})
export class MapSidebarComponent {
    @ViewChild('container', { read: ViewContainerRef, static: true })
    private container!: ViewContainerRef;
    private componentsStack: Array<ComponentRef<MapSidebar>> = [];

    constructor(
        private mapSidebarService: MapSidebarService,
        private hostElement: ElementRef<HTMLElement>,
        private parentInjector: Injector,
        private mapUIService: MapUIService,
    ) {
        this.mapSidebarService.asObservable()
            .subscribe(({ component, componentRef, stack }) => this.open(component, componentRef, stack));
    }

    @Output()
    public closed = new EventEmitter();

    /**
     * Creates a new instance of the given component and opens up the sidebar.
     *
     * @template T
     * @param {ComponentType<T>} component
     * @memberof MapSidebarComponent
     */
    public open<T>(component: ComponentType<MapSidebar>, ref: MapSidebarRef<T>, stack: boolean = false): void {
        if (!stack) {
            for (let i = this.componentsStack.length - 1; i >= 0; i--) {
                const componentRef = this.componentsStack[i];
                if (!componentRef?.instance.close()) {
                    return;
                }
            }
        }

        const injector = Injector.create({ providers: [{ provide: MapSidebarRef, useValue: ref }], parent: this.parentInjector });
        const componentRef = this.container.createComponent<MapSidebar>(component, { injector });

        ref.componentInstance = componentRef.instance as unknown as T;
        componentRef.instance.closed.subscribe(() => {
            this.#close(componentRef);
        });
        this.componentsStack.push(componentRef);
        this.componentsStack.length > 1 ? this.hostElement.nativeElement.classList.add('stack') : this.hostElement.nativeElement.classList.remove('stack');

        //This is to ensure that the filter bar is hidden when the sidebar is shown.
        this.mapUIService.show(this.mapUIService.shown & ~MapUIComponents.FilterBar);
    }

    /**
     * Removes the component from view and destroys it.
     *
     * @template T
     * @param {ComponentRef<MapSidebar>} componentRef
     * @memberof MapSidebarComponent
     */
    #close(componentRef: ComponentRef<MapSidebar>): void {
        const element: HTMLElement = componentRef.location.nativeElement;
        element.classList.add('closing');
        element.onanimationend = ({ target }) => {
            if (target === element) {
                componentRef.hostView.destroy();
                componentRef.destroy();
            }
        };

        const indexOfComponent = this.componentsStack.indexOf(componentRef);
        this.componentsStack.splice(indexOfComponent, 1);
        this.componentsStack.length > 1 ? this.hostElement.nativeElement.classList.add('stack') : this.hostElement.nativeElement.classList.remove('stack');
        if (this.componentsStack.length === 0) {
            this.closed.emit();
        }
    }

    /**
     * Closing the component when the Escape key id pressed.
     *
     * @param {KeyboardEvent} event
     */
    @HostListener('document:keydown.escape', ['$event'])
    onEscapeHandler(event: KeyboardEvent): void {
        if (this.componentsStack.length > 0) {
            event.preventDefault();
            event.stopImmediatePropagation();
            const topComponent = this.componentsStack[this.componentsStack.length - 1];
            topComponent?.instance?.close();
        }
    }
}