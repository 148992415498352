import { LocationType } from '../../location-types/location-type.model';

/**
 * Create and get a mi-dropdown-item element.
 *
 * @param {{ label: string; value: string, selected: boolean, title: string, disabled: boolean }} dropdownOptions
 * @returns {HTMLMiDropdownItemElement}
 */
export function createDropdownItemElement(dropdownOptions: {label: string, value: string, selected?: boolean, excludeFromAll?: boolean, title?: string, disabled?: boolean}): HTMLMiDropdownItemElement {
    const miDropdownItemElement = document.createElement('mi-dropdown-item') as HTMLMiDropdownItemElement;
    miDropdownItemElement.text = dropdownOptions.label;
    miDropdownItemElement.value = dropdownOptions.value;
    miDropdownItemElement.selected = dropdownOptions.selected ?? false;
    miDropdownItemElement.excludefromall = dropdownOptions.excludeFromAll ?? false;
    miDropdownItemElement.disabled = dropdownOptions.disabled ?? false;

    if (dropdownOptions.title) {
        miDropdownItemElement.setAttribute('title', dropdownOptions.title);
    }

    return miDropdownItemElement;
}

/**
 * Creates mi-dropdown-items from an array of LocationTypes.
 *
 * @param {LocationType} locationType
 * @param  {string} displayIcon
 * @returns {HTMLMiDropdownItemElement}
 */
export function createLocationTypesDropdownItem(locationType: LocationType, displayIcon?: string): HTMLMiDropdownItemElement {
    const miDropDownItemElement = createDropdownItemElement({label: locationType.displayName, value: locationType.id});
    miDropDownItemElement.innerHTML = displayIcon ? `<img src="${displayIcon}" height="24" />${locationType.displayName}` : locationType.displayName;
    return miDropDownItemElement;
}
