import '@mapsindoors/components';
import { Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BreakpointObserver } from '@angular/cdk/layout';

import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';

import { NotificationService } from '../../services/notification.service';
import { SolutionService } from '../../services/solution.service';
import { VenueService } from '../../venues/venue.service';

import { FilterOptions } from './filter-options.model';
import { Category } from '../../categories/category.model';
import { Venue } from '../../venues/venue.model';
import { Building, OUTDOOR_BUILDING } from '../../buildings/building.model';
import { Floor } from '../../buildings/floor.model';
import { LocationType } from '../../location-types/location-type.model';
import { createDropdownItemElement, createLocationTypesDropdownItem } from '../../shared/mi-dropdown/mi-dropdown';
import { AppUserRole } from '../../app-settings/config/app-user-roles/app-user-role.model';
import { BuildingService } from '../../buildings/building.service';
import { AppUserRolesService } from '../../app-settings/config/app-user-roles/app-user-roles.service';
import { CategoryService } from '../../categories/category.service';
import { TypesService } from '../../services/types.service';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { DisplayOption } from '../../shared/enums/DisplayOption.enum';

@Component({
    selector: 'filters-bar',
    templateUrl: './filters-bar.component.html',
    styleUrls: ['./filters-bar.component.scss']
})
export class FiltersBarComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('venueSelector', { static: true }) venueSelectorElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('buildingsFilter', { static: true }) buildingsFilterElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('searchFilter', { static: true }) searchFilterElement: ElementRef<HTMLInputElement>;
    @ViewChild('searchClearButton', { static: true }) searchClearButtonElement: ElementRef<HTMLButtonElement>;
    @ViewChild('floorFilter', { static: true }) floorFilterElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('displayOptionsFilter', { static: true }) displayOptionsFilterElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('categoriesFilter', { static: true }) categoriesFilterElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('locationTypesFilter', { static: true }) locationTypesFilterElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('appUserRolesFilter', { static: true }) appUserRolesFilterElement: ElementRef<HTMLMiDropdownElement>;

    @Input() isListViewOpen = false;
    @Input() canOpenBulkEdit = false;

    @Output() filterChange = new EventEmitter<any>();
    @Output() listViewtoggle = new EventEmitter<void>();
    @Output() openBulkEdit = new EventEmitter<void>();

    private subscriptions = new Subscription();
    private activeFilters: FilterOptions = {};
    private activeFiltersSubject = new BehaviorSubject(this.activeFilters);
    private venues: Venue[] = [];
    private _buildings: Building[] = [];
    private _floors: Floor[] = [];
    private _categories: Category[] = [];
    private _locationTypes: LocationType[] = [];
    private _appUserRoles: AppUserRole[] = [];
    public displayOptions: Array<[key: string, type: string]> = [
        ['Area (Normal)', DisplayOption.Area],
        ['Area (Obstacle)', DisplayOption.Obstacle],
        ['POI', DisplayOption.POI],
        ['Room', DisplayOption.Room],
        ['No Locations', DisplayOption.None]
    ];
    public dropdownFilterElements: HTMLMiDropdownElement[] = [];
    public showChipsList = false;
    public svg_expand_more = this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/images/expand_more_white.svg');

    public isClearButtonVisible = false;
    public isFilterElementExpanded = false;
    public isVenueSelectorDisabled = false;
    public isBuildingSelectorDisabled = false;

    /**
     * Active filters observable.
     *
     * @readonly
     * @type {Observable<FilterOptions>}
     * @memberof FiltersBarComponent
     */
    public get activeFilters$(): Observable<FilterOptions> {
        return this.activeFiltersSubject;
    }

    constructor(
        private notificationService: NotificationService,
        private venueService: VenueService,
        private buildingService: BuildingService,
        private appUserRolesService: AppUserRolesService,
        private categoryService: CategoryService,
        private typesService: TypesService,
        private solutionService: SolutionService,
        private domSanitizer: DomSanitizer,
        private breakpointObserver: BreakpointObserver,
        private displayRuleService: DisplayRuleService,
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        const solutionServiceSubscription = this.solutionService.getCurrentSolution().subscribe(() => {
            this.searchFilterElement.nativeElement.value = '';
        });

        const appUserRolesSubscription = this.appUserRolesService.appUserRoles$.subscribe(appUserRoles => {
            this._appUserRoles = appUserRoles;
            this.appUserRolesFilterElement.nativeElement.items = appUserRoles.map(role => createDropdownItemElement({ label: role.displayName, value: role.id }));
        });

        const typesSubscription = this.typesService.types.subscribe(async locationTypes => {
            const dropdownItems = new Array(locationTypes.length);
            this._locationTypes = locationTypes;

            for (const [index, locationType] of this._locationTypes.entries()) {
                const displayRule = await this.displayRuleService.getDisplayRule(locationType.id);
                dropdownItems[index] = createLocationTypesDropdownItem(locationType, displayRule.icon);
            }

            this.locationTypesFilterElement.nativeElement.items = dropdownItems;
        });


        const categoriesSubscription = this.categoryService.categories.subscribe(categories => {
            this._categories = categories;
            this.categoriesFilterElement.nativeElement.items = categories.map(category => createDropdownItemElement({ label: category.displayName, value: category.id }));
        });

        const buildingsSubscription = this.buildingService.buildings$.subscribe(buildings => {
            //  Get ascending order
            buildings.sort((buildingA, buildingB) => buildingA.displayName.trimStart().toLowerCase().localeCompare(buildingB.displayName.trimStart().toLowerCase()));
            this._buildings = [OUTDOOR_BUILDING, ...buildings];

            this.buildingsFilterElement.nativeElement.items = this._buildings?.map(building => createDropdownItemElement({ label: building.displayName, value: building.id }));

            this.isBuildingSelectorDisabled = this.buildingsFilterElement.nativeElement.items.length === 1;
        });

        this.subscriptions
            // Venue subscription.
            .add(this.venueService.venues$
                .pipe(
                    switchMap((venues) => {
                        this.venues = venues;
                        this.deselectAllFilters();
                        return this.venueService.selectedVenue$;
                    })
                ).subscribe(currentVenue => {
                    const venueDropDownItemElements = this.venues?.map(venue => {
                        const selected = venue.id === currentVenue.id;
                        return createDropdownItemElement({ label: venue.displayName, value: venue.id, selected });
                    });
                    this.venueSelectorElement.nativeElement.items = venueDropDownItemElements;
                    this.isVenueSelectorDisabled = this.venueSelectorElement.nativeElement.items.length === 1;
                }))
            // Search query subscription.
            .add(fromEvent<KeyboardEvent>(this.searchFilterElement.nativeElement, 'input')
                .pipe(
                    tap(() => {
                        this.isClearButtonVisible = this.searchFilterElement.nativeElement.value.length > 0;
                    }),
                    debounceTime(400),
                    map(event => (<HTMLInputElement>event.target).value),
                    distinctUntilChanged()
                ).subscribe(term => {
                    this.activeFilters.searchQuery = term;
                    this.emitActiveFilters();
                }))
            .add(solutionServiceSubscription)
            .add(typesSubscription)
            .add(appUserRolesSubscription)
            .add(categoriesSubscription)
            .add(buildingsSubscription);

        this.displayOptionsFilterElement.nativeElement.items = this.displayOptions.map(([key, type]) => {
            return createDropdownItemElement({ label: key, value: type, excludeFromAll: type === DisplayOption.None });
        });

        this.dropdownFilterElements = [
            this.buildingsFilterElement.nativeElement,
            this.floorFilterElement.nativeElement,
            this.displayOptionsFilterElement.nativeElement,
            this.categoriesFilterElement.nativeElement,
            this.locationTypesFilterElement.nativeElement,
            this.appUserRolesFilterElement.nativeElement
        ];
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();

        this.searchFilterElement.nativeElement.onfocus = null;
        this.searchFilterElement.nativeElement.onblur = null;
        this.searchClearButtonElement.nativeElement.onfocus = null;
        this.searchClearButtonElement.nativeElement.onblur = null;
    }

    /**
     * NgAfterViewInit.
     */
    ngAfterViewInit(): void {
        // Makes sure that the clear button is always visible when it is tabbed to.
        this.searchClearButtonElement.nativeElement.onfocus = () => {
            this.isClearButtonVisible = true;
        };

        this.breakpointObserver.observe('(min-width: 1800px)').subscribe(result => {
            if (result.matches) {
                this.viewportExceedsBreakpoint();
            } else {
                this.viewportSubceedsBreakpoint();
            }
        });
    }

    /**
     * Sets the visible state of the clear button in the search field when the search field is expanded.
     */
    private viewportExceedsBreakpoint(): void {
        // Show the clear button when the window is resized to above 1800px and the search field is populated.
        if (this.searchFilterElement.nativeElement.value !== '') {
            this.isClearButtonVisible = true;
        }

        // Show the clear button when the search field loses focus while it is populated.
        this.searchFilterElement.nativeElement.onblur = () => {
            if (this.searchFilterElement.nativeElement.value !== '') {
                this.isClearButtonVisible = true;
            }
        };

        // Show the clear button when the clear button loses focus while the search field is populated.
        this.searchClearButtonElement.nativeElement.onblur = () => {
            if (this.searchFilterElement.nativeElement.value !== '') {
                this.isClearButtonVisible = true;
            }
        };
    }

    /**
     * Sets the visible state of the clear button in the search field when the search field is collapsed.
     */
    private viewportSubceedsBreakpoint(): void {
        // Makes sure that the clear button is not hidden when the window is resized to below 1800px,
        // and while the search field is populated.
        if (document.activeElement !== this.searchFilterElement.nativeElement) {
            this.isClearButtonVisible = false;
        }

        // Show clear button when the search field gains focus and it is populated.
        this.searchFilterElement.nativeElement.onfocus = () => {
            if (this.searchFilterElement.nativeElement.value !== '') {
                this.isClearButtonVisible = true;
            }
        };

        // Hide clear button when the search field loses focus.
        this.searchFilterElement.nativeElement.onblur = () => {
            this.isClearButtonVisible = false;
        };

        // Hide clear button when it loses focus.
        this.searchClearButtonElement.nativeElement.onblur = () => {
            this.isClearButtonVisible = false;
        };
    }

    /**
     * Set all filters as deselected.
     *
     * @private
     * @memberof FiltersBarComponent
     */
    private deselectAllFilters(): void {
        this.dropdownFilterElements.forEach(dropdownElement => {
            dropdownElement.items?.forEach(element => element.selected = false);

            // Trigger change event programmatically
            const changeEvent = new CustomEvent('change', { detail: [] });
            dropdownElement.dispatchEvent(changeEvent);
        });
    }

    /**
     * Deselect floor filters.
     *
     * @private
     * @memberof FiltersBarComponent
     */
    private deselectFloorFilters(): void {
        if (this.activeFilters?.floors) {
            this.notificationService.showInfo('The floors-filter has been cleared');
        }

        const floorFilterElement = this.floorFilterElement.nativeElement;
        floorFilterElement.items = [];

        // Trigger change event programmatically for chips list to update
        const event = new CustomEvent('change', { detail: [] });
        floorFilterElement.dispatchEvent(event);
    }

    /**
     * Deselect building filters.
     *
     * @private
     * @memberof FiltersBarComponent
     */
    private deselectBuildingFilters(): void {
        if (!this.activeFilters?.buildings) {
            return;
        }

        this.notificationService.showInfo('The buildings filter has been cleared');

        const buildingsFilterElement = this.buildingsFilterElement.nativeElement;
        buildingsFilterElement.items = [];

        // Trigger change event programmatically for chips list to update
        const event = new CustomEvent('change', { detail: [] });
        buildingsFilterElement.dispatchEvent(event);
    }

    /**
     * Update selected venue on the map.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof FiltersBarComponent
     */
    public onVenueChange({ detail }: CustomEvent): void {
        this.venueService.getSelectedVenue().pipe(take(1)).subscribe(currentVenue => {
            const venueId: string = (detail as HTMLMiDropdownItemElement[])?.map(item => item.value).toString();
            if (venueId !== currentVenue.id) {
                this.buildingsFilterElement.nativeElement.items = [];
                const selectedVenue: Venue = this.venues.find(venue => venue.id === venueId);
                this.deselectBuildingFilters();
                this.deselectFloorFilters();

                this.venueService.setSelectedVenue(selectedVenue);
            }
        });

        this.isBuildingSelectorDisabled = this.buildingsFilterElement.nativeElement.items.length === 1;
    }

    /**
     * Update active filters with selected buildings.
     * Update floors filter.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof FiltersBarComponent
     */
    public onBuildingChange({ detail }: CustomEvent): void {
        const selectedBuildingIds = (detail as HTMLMiDropdownItemElement[])?.map(item => item.value);
        const selectedBuildings = this._buildings.filter(building => selectedBuildingIds.includes(building.id));
        this.activeFilters.buildings = selectedBuildings;
        this.emitActiveFilters();

        // Populate floor dropdown when a single building is selected
        if (selectedBuildings.length === 1 && selectedBuildings[0].floors?.length > 0) {
            const buildingFloors = selectedBuildings[0].floors;
            this._floors = buildingFloors;

            // Create floor filter dropdown options
            this.floorFilterElement.nativeElement.items = this.sortFloorIndex(buildingFloors).map(floor => {
                return createDropdownItemElement({ label: floor.displayName, value: floor.id, title: `Floor index: ${floor.floorIndex.toString()}` });
            });
        } else {
            this.deselectFloorFilters();
        }
    }

    /**
     * Update active filters with selected floors.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof FiltersBarComponent
     */
    public onFloorsChange({ detail }: CustomEvent): void {
        const floorIds = (detail as HTMLMiDropdownItemElement[]).map(item => item.value);
        this.activeFilters.floors = this._floors.filter(floor => floorIds.includes(floor.id));
        this.emitActiveFilters();
    }

    /**
     * Update active filters with selected display options.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof FiltersBarComponent
     */
    public onDisplayOptionsChange({ detail }: CustomEvent): void {
        this.activeFilters.displayOptions = (detail as HTMLMiDropdownItemElement[]).map(item => item.value?.toLowerCase());
        this.emitActiveFilters();
    }

    /**
     * Update active filters with selected categories.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof FiltersBarComponent
     */
    public onCategoriesChange({ detail }: CustomEvent): void {
        const categoryIds = (detail as HTMLMiDropdownItemElement[]).map(item => item.value);
        this.activeFilters.categories = this._categories.filter(category => categoryIds.includes(category.id));

        this.emitActiveFilters();
    }

    /**
     * Update active filters with selected location types.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof FiltersBarComponent
     */
    public onLocationTypesChange({ detail }: CustomEvent): void {
        const locationTypeAdministrativeIds = (detail as HTMLMiDropdownItemElement[]).map(item => item.value);
        this.activeFilters.locationTypes = this._locationTypes.filter(locationType => locationTypeAdministrativeIds.includes(locationType.id));

        this.emitActiveFilters();
    }

    /**
     * Update active filters with selected app user roles.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof FiltersBarComponent
     */
    public onAppUserRolesChange({ detail }: CustomEvent): void {
        const roleIds: string[] = (detail as HTMLMiDropdownItemElement[]).map(item => item.value);
        this.activeFilters.appUserRoles = this._appUserRoles.filter(role => roleIds.includes(role.id));
        this.emitActiveFilters();
    }

    /**
     * Emit active filters or null when none is set.
     *
     * @private
     * @memberof FiltersBarComponent
     */
    private emitActiveFilters(): void {
        // Delete empty object properties.
        for (const key in this.activeFilters) {
            if (this.activeFilters[key].length <= 0) {
                delete this.activeFilters[key];
            }
        }

        // Toggle visibility of chips list
        const activeFiltersCopy = { ...this.activeFilters };
        delete activeFiltersCopy['searchQuery']; // The Search query is not part of the chips list and should therefor not trigger the visibility of it.
        this.showChipsList = Object.keys(activeFiltersCopy).length > 0;

        const emitValue = Object.keys(this.activeFilters).length > 0 ? this.activeFilters : null;
        this.filterChange.emit(emitValue);
        this.activeFiltersSubject.next(this.activeFilters);
    }

    /**
     * Performs bubble sort on floors to reorder elements based on floorIndex from smallest to largest.
     *
     * @param {Floor[]} floors
     * @returns {Floor[]}
     */
    private sortFloorIndex(floors: Floor[]): Floor[] {
        if (floors.length >= 2) {
            floors = floors.sort((first, next) => {
                return (first.floorIndex < next.floorIndex) ? 1 : -1;
            });
        }

        return floors;
    }

    /**
     * Clear search input field.
     *
     * @memberof FiltersBarComponent
     */
    public onSearchFilterClear(): void {
        this.searchFilterElement.nativeElement.focus();
        this.searchFilterElement.nativeElement.value = '';
        this.searchFilterElement.nativeElement.dispatchEvent(new Event('input'));

        delete this.activeFilters['searchQuery'];
        this.isClearButtonVisible = false;

        this.emitActiveFilters();
    }

    /**
     * Shows/hides the clear button depending on the input.
     *
     * @param {Event} event
     */
    public onSearchInputEntered(event: Event): void {
        if ((event.target as HTMLInputElement).value !== '') {
            this.isClearButtonVisible = true;
        } else {
            this.isClearButtonVisible = false;
        }
    }
}
