import isEqual from 'fast-deep-equal';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';
import { OccupantTemplate } from '../../services/OccupantServices/occupantTemplate.model';
import { OccupantTemplateService } from '../../services/OccupantServices/occupant-template.service';
import { Solution } from '../../solutions/solution.model';
import { SolutionService } from '../../services/solution.service';
import { finalize } from 'rxjs/operators';
import { primitiveClone } from '../../shared/object-helper';

@Component({
    selector: 'occupant-template-editor',
    templateUrl: './occupant-template-editor.component.html',
    styleUrls: ['./occupant-template-editor.component.scss']
})
export class OccupantTemplateEditorComponent implements OnInit {
    public occupantTemplateForm: FormGroup = this.formBuilder.group({
        occupantCategoryId: [null, [Validators.required]],
        businessHours: this.formBuilder.group({
            standardOpeningHours: []
        }),
        address: [],
        contactInformation: [],
        occupantType: [null, [Validators.required]],
        aliases: [],
        logoURI: [null, []],
        translations: []
    });

    private currentSolution: Solution;
    private originalOccupantTemplate;
    private originalOccupantTemplateFormValue;

    constructor(
        private dialogRef: MatDialogRef<OccupantTemplateEditorComponent>,
        private formBuilder: FormBuilder,
        private notificationService: NotificationService,
        private occupantTemplateService: OccupantTemplateService,
        private solutionService: SolutionService,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: {
            occupantTemplate: OccupantTemplate
        }
    ) {
    }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.solutionService.getCurrentSolution().subscribe((solution) => {
            this.currentSolution = solution;
        });

        if (this.data?.occupantTemplate) {
            this.originalOccupantTemplate = this.data.occupantTemplate;
        } else {
            this.originalOccupantTemplate = OccupantTemplate.createOccupantTemplate({});
            this.originalOccupantTemplate.translations = this.occupantTemplateService.createTranslationsArray(this.currentSolution);
        }
        this.occupantTemplateForm.patchValue(this.originalOccupantTemplate);
        this.originalOccupantTemplateFormValue = primitiveClone(this.occupantTemplateForm.value);

        this.occupantTemplateForm.valueChanges.subscribe((newValue) => {
            isEqual(this.originalOccupantTemplateFormValue, newValue) ? this.occupantTemplateForm.markAsPristine() : this.occupantTemplateForm.markAsDirty();
        });
    }

    /**
     * Alerts the user of unsaved changes.
     *
     * @private
     * @returns {boolean}
     * @memberof LocationDetailsComponent
     */
    private alertForUnsavedChanges(): boolean {
        if (this.occupantTemplateForm.dirty) {
            // eslint-disable-next-line no-alert
            return !confirm('Your unsaved changes will be lost! Would you like to continue?');
        }
    }

    /**
     * Called when the user selects a new logo.
     *
     * @param {string} logoUrl
     */
    public onLogoURIChange(logoUrl: string): void {
        this.occupantTemplateForm.controls.logoURI.setValue(logoUrl);
    }

    /**
     * Close modal.
     */
    public onCloseDialog(operation: string, updatedOccupantTemplate?: any): void {
        if (operation !== 'update' && this.alertForUnsavedChanges()) {
            return;
        }

        updatedOccupantTemplate = { updatedOccupantTemplate, operation: operation };
        this.dialogRef.close(updatedOccupantTemplate);
    }

    /**
     * Delete occupant template.
     */
    public onDeleteOccupantTemplate(): void {
        if (this.originalOccupantTemplate.occupantReferenceCount > 0) {
            // eslint-disable-next-line no-alert
            alert('This Occupant Template is assigned to one or more Occupants. Please assign a different Occupant Template or delete the Occupants referencing this template before deleting it.');
            return;
        }

        // eslint-disable-next-line no-alert
        if (confirm('Are you sure you want to delete this Occupant Template?')) {
            this.spinner.show();

            this.occupantTemplateService.deleteOccupantTemplates([this.originalOccupantTemplate])
                .pipe(
                    finalize(() => this.spinner.hide())
                )
                .subscribe(() => {
                    this.onCloseDialog('delete');
                }, (error) => {
                    this.notificationService.showError(error);
                });
        }
    }

    /**
     * Create/update occupant template.
     */
    public onSubmit(): void {
        const newOccupantTemplate = OccupantTemplate.createOccupantTemplate({ occupantTemplate: this.occupantTemplateForm.getRawValue() });

        if (newOccupantTemplate.aliases?.length === 0) {
            newOccupantTemplate.aliases = null;
        }

        newOccupantTemplate.id = this.originalOccupantTemplate?.id;
        newOccupantTemplate.solutionId = this.currentSolution.id;
        newOccupantTemplate.occupantReferenceCount = this.originalOccupantTemplate?.occupantReferenceCount ?? 0; // TODO: Remove 0 when the BE is updated. Right now, it is not possible to save null for ref.Count, that's why we need to set it to 0.

        this.onCloseDialog('update', newOccupantTemplate);
    }

    /**
     * Resets the form and set the values.
     */
    public onDiscard(): void {
        if (this.originalOccupantTemplate.id > '') {
            if (!this.alertForUnsavedChanges()) {
                this.occupantTemplateForm.reset(this.originalOccupantTemplate);
                this.occupantTemplateForm.markAsPristine();
            }
        } else {
            this.onCloseDialog('discard');
        }
    }
}