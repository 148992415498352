import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Subject, Subscription } from 'rxjs';

import { MediaLibraryComponent } from '../../media-library/media-library.component';
import { NotificationService } from '../../services/notification.service';
import { MediaLibrarySource } from '../../media-library/media.enum';

enum ImageStatus {
    Loading,
    NoImage,
    HasImage
}

@Component({
    selector: 'image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit, OnDestroy {
    /**
     * Enable or disable the form depending on the locked state.
     */
    @Input() public isLocked: boolean;

    @Input() reset: Subject<any>;

    /**
     * Set the image's url.
     *
     * @memberof LocationImageUploadComponent
     */
    @Input() set imageSrc(imageUrl: string) {
        this.setImageSrc(imageUrl);
    }

    @Output() imageUrlChanged = new EventEmitter<string>();

    /**
     * Read-only property.
     *
     * @returns {string}
     */
    public get imageSrc(): string {
        return this._imageSrc;
    }

    public imageStatus = ImageStatus;
    public currentImageStatus: ImageStatus = ImageStatus.Loading;
    public image: HTMLImageElement;

    private _imageSrc = '';
    private subscriptions = new Subscription();

    constructor(
        private notificationService: NotificationService,
        private matDialog: MatDialog,
    ) { }

    /**
     * Angular OnInit lifecycle hook.
     */
    ngOnInit(): void {
        if (this.reset) {
            this.subscriptions.add(
                this.reset.subscribe(formValue => {
                    this.setImageSrc(formValue.imageURL);
                }));
        }
    }

    /**
     * Angular OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Opens the Media Library and sets the new values for the formControls.
     *
     * @returns {void}
     */
    public openMediaLibrary(): void {
        const mediaLibraryModalConfig: MatDialogConfig = {
            width: '90vw',
            minWidth: '1024px',     // smallest screen size supported by CMS
            maxWidth: '1700px',     // the number is a subject to change after UX has tested properly
            height: '85vh',         // the number is a subject to change after UX has tested properly
            maxHeight: '928px',     // the number is a subject to change after UX has tested properly
            minHeight: '550px',     // the number is a subject to change after UX has tested properly
            role: 'dialog',
            panelClass: 'details-dialog',
            disableClose: true,
            closeOnNavigation: false,
        };
        const dialog = this.matDialog.open(MediaLibraryComponent, { ...mediaLibraryModalConfig, data: { url: this._imageSrc, source: MediaLibrarySource.Model2D } });

        dialog.afterClosed().subscribe(media => {
            if (!media) return;

            this.setImageSrc(media.selectedMedia?.url);
            this.imageUrlChanged.emit(media.selectedMedia?.url);
        });
    }

    /**
     * Set image source.
     *
     * @private
     * @param {string} imageUrl
     * @returns {void}
     * @memberof LocationImageUploadComponent
     */
    private setImageSrc(imageUrl: string): void {
        if (!imageUrl || imageUrl <= '') {
            this.image = null;
            this._imageSrc = '';
            this.currentImageStatus = ImageStatus.NoImage;
            return;
        }

        this._imageSrc = imageUrl;
        this.loadImageFromUrl(imageUrl);
    }

    /**
     * Helper function to load an image from a URL.
     *
     * @private
     * @param {string} imageUrl
     * @returns {Promise<void>}
     * @memberof LocationImageUploadComponent
     */
    private async loadImageFromUrl(imageUrl: string): Promise<void> {
        this.currentImageStatus = ImageStatus.Loading;
        try {
            const image = await this.loadImage(imageUrl);
            this.image = image;
            this.currentImageStatus = ImageStatus.HasImage;
            return Promise.resolve();
        } catch (err) {
            this.image = null;
            this.notificationService.showError('The image url could not be loaded');
            // eslint-disable-next-line no-console
            console.log(err);
            this.currentImageStatus = ImageStatus.NoImage;
            return Promise.reject();
        }
    }

    /**
     * OnChange handler for the image url input field.
     *
     * @param {Event} event
     * @memberof LocationImageUploadComponent
     * @returns {void}
     */
    public urlChangeHandler(event: Event): void {
        const target = event.target as HTMLInputElement;
        if (!target.value) {
            return this.deleteImage();
        } else if (target.value !== this.image?.src) {
            this.loadImageFromUrl(target.value)
                .then(() => this.imageUrlChanged.emit(target.value))
                .catch(() => this.deleteImage());

            return;
        }
    }

    /**
     * Helper function to remove the preview image.
     *
     * @memberof LocationImageUploadComponent
     */
    public deleteImage(): void {
        this.image = null;
        this._imageSrc = '';
        this.currentImageStatus = ImageStatus.NoImage;
        this.imageUrlChanged.emit(null);
    }

    /**
     * Set imageSrc when the input field changes.
     * This makes sure that the input is empty when the image url is not validated.
     *
     * @param {Event} input
     */
    public onInputChanged(input: Event): void {
        this._imageSrc = (input.target as HTMLInputElement).value;
    }

    /**
     * Helper function to load image.
     *
     * @private
     * @param {string} src
     * @returns {HTMLImageElement}
     * @memberof LocationImageUploadComponent
     */
    private loadImage(src: string): Promise<HTMLImageElement> {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => resolve(image));
            image.addEventListener('error', () => reject(null));
            image.src = src;
        });
    }
}