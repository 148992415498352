import { Address } from '../../shared/address/address.model';
import { Translation } from '../../shared/interfaces/translation.model';
import { StandardOpeningHours } from '../../shared/standard-opening-hours/standardOpeningHours.model';
import { ContactInformation } from '../../shared/contactInformation/contactInformation.model';
import { Solution } from '../../solutions/solution.model';

export class Occupant {
    address: Address = new Address();
    aliases: string[] = [];
    businessHours: { standardOpeningHours: StandardOpeningHours } = {
        standardOpeningHours: new StandardOpeningHours()
    };
    contactInformation: ContactInformation = new ContactInformation();
    correlationId?: string = null;
    createdAt?: string = null;
    createdBy?: string = null;
    id?: string = null;
    isAnchor: boolean = false;
    isOperating: boolean = true;
    lastModified?: string = null;
    lastModifiedBy?: string = null;
    lastVerifiedAt?: string = null;
    locationId?: string = null;
    logoURI: string = '';
    occupantTemplateId?: string = null;
    solutionId?: string = null;
    translations: Translation[] = [];
    venueId: string = null;

    private constructor(data?: any) {
        Object.assign(this, data);
        if (!this.businessHours?.standardOpeningHours) {
            this.businessHours.standardOpeningHours = new StandardOpeningHours();
        }
    }

    /**
     * Creates an occupant.
     *
     * @param {{ occupant?: Occupant, solution: Solution }} occupant
     * @returns {Occupant}
     */
    static createOccupant({ occupant, solution }: { occupant?: Occupant, solution: Solution }): Occupant {
        const newOccupant = new Occupant(occupant);

        if (newOccupant?.translations.length === 0) {
            const translations = Solution.createTranslations(solution);
            newOccupant.translations = translations;
        }

        return newOccupant;
    }
}