export interface SimplifiedOccupantTemplateExtendedVersion extends SimplifiedOccupantTemplate {
    occupantCategoryName: string;
    lastModifiedDate: string;
}

// SimplifiedOccupantTemplate is used to represent a simplified version of an occupant template.
// This structure of occupant templates is the one used to get occupant templates from the BE.
export class SimplifiedOccupantTemplate {
    id: string = null;
    lastModified: string = null;
    name: string = null;
    occupantCategoryId: string = null;
    occupantReferenceCount: number = null;

    constructor(data?: any) {
        Object.assign(this, data);
    }
}