import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SolutionService } from './solution.service';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class LogService {
    private api = environment.APIEndpoint;

    constructor(
        private solutionService: SolutionService,
        private http: HttpClient
    ) { }

    public getLogCSV(from, to, ids, users, types): Observable<any> {
        let parameters = '';

        if (from) {
            const year = from.getFullYear();
            let month = from.getMonth();
            month = (month + 1).toString();
            if (month.length < 2) {
                month = '0' + month;
            }
            let day = from.getDate().toString();
            if (day.length < 2) {
                day = '0' + day;
            }
            let hour = from.getHours().toString();
            if (hour.length < 2) {
                hour = '0' + hour;
            }
            let minutes = from.getMinutes().toString();
            if (minutes.length < 2) {
                minutes = '0' + minutes;
            }
            let seconds = from.getSeconds().toString();
            if (seconds.length < 2) {
                seconds = '0' + seconds;
            }
            parameters = parameters + '&from=' + year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
        }
        if (to) {
            const toYear = to.getFullYear();
            let toMonth = to.getMonth();
            toMonth = (toMonth + 1).toString();
            if (toMonth.length < 2) {
                toMonth = '0' + toMonth;
            }
            let toDay = to.getDate().toString();
            if (toDay.length < 2) {
                toDay = '0' + toDay;
            }
            let toHour = to.getHours().toString();
            if (toHour.length < 2) {
                toHour = '0' + toHour;
            }
            let toMinutes = to.getMinutes().toString();
            if (toMinutes.length < 2) {
                toMinutes = '0' + toMinutes;
            }
            let toSeconds = to.getSeconds().toString();
            if (toSeconds.length < 2) {
                toSeconds = '0' + toSeconds;
            }
            parameters = parameters + '&to=' + toYear + '-' + toMonth + '-' + toDay + ' ' + toHour + ':' + toMinutes + ':' + toSeconds;
        }
        if (types && types.length > 0) {
            parameters = parameters + '&types=' + types;
        }
        if (users && users.length > 0) {
            parameters = parameters + '&users=' + users;
        }
        if (ids && ids.length > 0) {
            parameters = parameters + '&ids=' + ids;
        }

        parameters = parameters.replace('&', '?');

        const logUrl = this.getLogURL();

        return this.http.get(logUrl + parameters);
    }

    public getObjectDiff(id): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        const url = this.api + solution.id + '/api/logs/diff?id=' + id;

        return this.http.get<any>(url)
            .pipe(map((log: any[]) => {
                return log.sort((entryA, entryB) => {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(entryB.lastModified).valueOf() - new Date(entryA.lastModified).valueOf();
                });
            }));
    }

    public getLogTypes(): Observable<any> {
        const url = this.api + 'api/logs/objectTypes';

        return this.http.get(url);
    }

    private getLogURL(): string {
        const solution = this.solutionService.getStaticSolution();
        const url = this.api + solution.id + '/api/logs/csv';
        return url;
    }
}
