<button (click)="onTabClicked($event, id)" 
    [disabled]="isDisabled" 
    [ngClass]="{'panel-header__tab--active': isActive}"
    class="panel-header__tab">
    <input *ngIf="hasToggle" 
        (click)="onToggleChange($event, id)"
        [checked]="isToggleChecked"
        [disabled]="isDisabled"
        class="mi-toggle" 
        type="checkbox" />
    {{ title }}
</button>