import { Component, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { GeodataEditor, GeodataEditorFactory } from '../../../../../GeodataEditor/GeodataEditor.factory';
import { BaseMapAdapter, MapMouseCursor } from '../../../../../MapAdapter/BaseMapAdapter';
import { Floor } from '../../../../../buildings/floor.model';
import { DisplayRuleService } from '../../../../../services/DisplayRuleService/DisplayRuleService';
import { MapAdapterMediator } from '../../../../map-adapter.mediator';
import { NetworkService } from '../../../../../network-access/network.service';
import { BuildingService } from '../../../../../buildings/building.service';
import { LocationService } from '../../../../../locations/location.service';
import { RouteElement, RouteElementType } from '../../../../../map/route-element-details/route-element.model';
import { RouteElementEditorOperation } from '../../../../../GeodataEditor/GeodataEditorOperation/RouteElementEditorOperation';
import { FloorService } from '../../../../../services/floor.service';
import { ElementSubType } from '../../../../../map/network-map.service';
import { VenueService } from '../../../../../venues/venue.service';
import { Venue } from '../../../../../venues/venue.model';
import { AddMultipleDoorsMapViewModelFactory } from '../../../../../network-access/AddMultipleDoorsMapViewModelFactory/AddMultipleDoorsMapViewModelFactory';

@Component({
    selector: 'add-multiples-doors-component',
    templateUrl: './add-multiple-doors.component.html',
    styleUrls: ['./add-multiple-doors.component.scss']
})

export class AddMultipleDoorsComponent implements OnInit, OnDestroy {
    private _destroySubject: Subject<void> = new Subject();
    private _geoDataEditor: GeodataEditor;
    private _mapAdapter: BaseMapAdapter;
    private _subscriptions: Subscription = new Subscription();
    private _editorOperation: RouteElementEditorOperation;
    private _currentVenue: Venue;

    private floor: Floor;
    private doorsAsLineStrings: RouteElement[] = [];
    private addingDoorsMode: boolean;
    private doorsDataSource$: BehaviorSubject<RouteElement[]> = new BehaviorSubject([]);
    private newDoorsValue;

    public doorTypeValue: string; // View model for door type select field
    public doorTypes: ElementSubType[] = [];
    public doorsToBeCreated = [];

    @Output() public readonly destroy = this._destroySubject.asObservable();

    constructor(
        private displayRuleService: DisplayRuleService,
        private mapAdapterMediator: MapAdapterMediator,
        private networkService: NetworkService,
        private buildingService: BuildingService,
        private locationService: LocationService,
        private floorService: FloorService,
        private venueService: VenueService
    ) {
        this.venueService.selectedVenue$.subscribe(venue => this._currentVenue === venue);
    }

    /** NgOnInit. */
    ngOnInit(): void {
        this.networkService.getMultipleDoorsCreation().subscribe((value) => {
            this.addingDoorsMode = value;
        });

        if (this.addingDoorsMode === false) {
            this.doorTypes = this.networkService.getSubtypes(RouteElementType.Door);

            // Preselect the door type dropdown to standard Door
            this.doorTypeValue = this.doorTypes.find(doorType => doorType.value.toLowerCase() === 'door').key.toString();

            const floorSubscription = this.buildingService.selectedFloor$.subscribe((floor) => {
                this.floor = floor;
            });

            this.floorService.disableFloorSelector(true);

            this._mapAdapter = this.mapAdapterMediator?.getMapAdapter();
            this._mapAdapter.isHoverable = false;
            this._geoDataEditor = GeodataEditorFactory.create(this._mapAdapter, this.displayRuleService, this.locationService);
            this._mapAdapter.setMapMouseCursor(MapMouseCursor.Crosshair);

            const DEFAULT_DOOR_WIDTH_IN_METERS = 0.9;

            const door = this.networkService.createNewRouteElement(this.floor.floorIndex, { type: 'Point', coordinates: [0, 0] }, RouteElementType.Door);

            this._editorOperation = this._geoDataEditor?.placeDoor(door, DEFAULT_DOOR_WIDTH_IN_METERS);
            this._editorOperation.changes
                .subscribe((value) => {
                    this.newDoorsValue = value;

                    // Array of doors that will be created when clicking Save button.
                    this.doorsToBeCreated.push(value);

                    this.createMultipleDoors(value);

                    this.doorsDataSource$.next(this.doorsAsLineStrings);
                });

            this._mapAdapter.viewState.addDataSource(this.doorsDataSource$, new AddMultipleDoorsMapViewModelFactory(this.displayRuleService));

            this._subscriptions
                .add(floorSubscription);

            this.networkService.setMultipleDoorsCreation(true);
        }
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.floorService.disableFloorSelector(false);
        this._editorOperation?.complete();
        this._mapAdapter.setMapMouseCursor(MapMouseCursor.Default);
        this._mapAdapter.isHoverable = true;
        this._subscriptions.unsubscribe();
        this._destroySubject.complete();
        this.networkService.setMultipleDoorsCreation(false);
    }

    /**
     * Resets the cursor to default, stops the event-listener on the map and hides the current toolbar.
     */
    public onCancel(): void {
        if (this.doorsToBeCreated.length > 0) {
            // eslint-disable-next-line no-alert
            if (confirm('Canceling without saving will discard any changes you have made.')) {
                this._mapAdapter.viewState.removeDataSource(this.doorsDataSource$);
                this._destroySubject.next();
            } else {
                return;
            }
        }
        this._destroySubject.next();
    }

    /**
     * Creates multiple doors and keeps them in the array.
     *
     * @returns {RouteElement[]}
     */
    private createMultipleDoors(doorValue: any): RouteElement[] {
        const routeElement = this.networkService.createNewRouteElement(this.floor.floorIndex, doorValue, RouteElementType.Door);
        routeElement.id = (this.doorsAsLineStrings.length + 1).toLocaleString();
        routeElement.radius = 0.5;
        routeElement.geometry = { type: 'LineString', coordinates: doorValue.geometry.coordinates };
        routeElement.subtype = parseInt(this.doorTypeValue);

        this.doorsAsLineStrings.push(routeElement);

        return this.doorsAsLineStrings;
    }

    /**
     * Saves multiple doors.
     */
    public saveMultipleDoors(): void {
        // Removing ID property from each of the to-be-created doors.
        const routeElementsArray = this.createMultipleDoors(this.newDoorsValue);
        for (let i = 0; i < routeElementsArray.length; i++) {
            delete routeElementsArray[i].id;
        }

        this.networkService.saveRouteElements(routeElementsArray);

        this._mapAdapter.viewState.removeDataSource(this.doorsDataSource$);
        this._destroySubject.next();
    }

    /**
     * Undo last door creation.
     */
    public undo(): void {
        // We need to pop both arrays: for creating doors and for displaying them in the source.
        this.doorsAsLineStrings.pop();
        this.doorsToBeCreated.pop();
        this.doorsDataSource$.next(this.doorsAsLineStrings);
    }

    /**
     * Cancels the add multiple doors operation.
     *
     * @param {KeyboardEvent} event
     */
    @HostListener('document:keydown.escape', ['$event'])
    private onEscapeHandler(event: KeyboardEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.onCancel();
    }
}