import { Component, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Floor } from '../../../../../buildings/floor.model';
import { GeodataEditor, GeodataEditorFactory } from '../../../../../GeodataEditor/GeodataEditor.factory';
import { RouteElementType } from '../../../../../map/route-element-details/route-element.model';
import { BaseMapAdapter, MapMouseCursor } from '../../../../../MapAdapter/BaseMapAdapter';
import { NetworkService } from '../../../../../network-access/network.service';
import { DisplayRuleService } from '../../../../../services/DisplayRuleService/DisplayRuleService';
import { MapAdapterMediator } from '../../../../map-adapter.mediator';
import { BuildingService } from '../../../../../buildings/building.service';
import { MapSidebarService } from '../../../../map-sidebar/map-sidebar.service';
import { RouteElementDetailsEditorComponent } from '../../../../route-element-details-editor/route-element-details-editor.component';
import { LocationService } from '../../../../../locations/location.service';

@Component({
    selector: 'add-floor-connector',
    templateUrl: './add-floor-connector.component.html',
    styleUrls: ['./add-floor-connector.component.scss']
})

export class AddFloorConnectorComponent implements OnInit, OnDestroy {
    private _destroySubject: Subject<void> = new Subject();
    private _geoDataEditor: GeodataEditor;
    private _mapAdapter: BaseMapAdapter;
    private _subscriptions: Subscription = new Subscription();
    private floor: Floor;

    @Output() public readonly destroy = this._destroySubject.asObservable();

    constructor(
        private displayRuleService: DisplayRuleService,
        private mapAdapterMediator: MapAdapterMediator,
        private networkService: NetworkService,
        private buildingService: BuildingService,
        private mapSidebar: MapSidebarService,
        private locationService: LocationService
    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        const floorSubscription = this.buildingService.selectedFloor$.subscribe((floor) => {
            this.floor = floor;
        });

        this._mapAdapter = this.mapAdapterMediator?.getMapAdapter();
        this._geoDataEditor = GeodataEditorFactory.create(this._mapAdapter, this.displayRuleService, this.locationService);
        this._mapAdapter.isHoverable = false;
        this._mapAdapter.setMapMouseCursor(MapMouseCursor.Crosshair);

        const addFloorConnectorSubscription = this._geoDataEditor.drawPoint()
            .pipe(take(1))
            .subscribe((point) => {
                const floorConnectorElement = this.networkService.createNewRouteElement(this.floor.floorIndex, point, RouteElementType.Connector);

                const { componentInstance } = this.mapSidebar.open(RouteElementDetailsEditorComponent);
                if (componentInstance) {
                    componentInstance.data = floorConnectorElement;
                }

                this._destroySubject.next();
            });

        this._subscriptions
            .add(floorSubscription)
            .add(addFloorConnectorSubscription);
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this._mapAdapter.isHoverable = true;
        this._mapAdapter.setMapMouseCursor(MapMouseCursor.Default);
        this._subscriptions.unsubscribe();
        this._destroySubject.complete();
    }

    /**
     * Resets the cursor to defualt, stops the event-listener on the map and hides the current toolbar..
     */
    public onCancel(): void {
        // eslint-disable-next-line no-alert
        if (confirm('Canceling without saving will discard any changes you have made.')) {
            this._destroySubject.next();
        }
    }

    /**
     * Cancels the add floor connector operation.
     *
     * @param {KeyboardEvent} event
     */
    @HostListener('document:keydown.escape', ['$event'])
    private onEscapeHandler(event: KeyboardEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.onCancel();
    }
}
