<panel-header (cancelEvent)="close()" [header]="routeElementType[routeElement?.type]">
    <button type="button"
        [disabled]="form.dirty"
        class="button button--duplicate"
        *ngIf="canRouteElementBeDuplicated"
        (click)="onDuplicate()">
        <span class="tooltip">{{ form?.dirty ? 'You have unsaved changes, save or discard before proceeding.' : 'Duplicate Floor Connector (Ctrl+D)'}}</span>
    </button>
    <button *ngIf="routeElement?.id" type="button" class="button button--delete" (click)="onDelete()">
    </button>
</panel-header>

<form [formGroup]="form" class="form" (ngSubmit)="onSave()" >
    <section (window:keydown)="handleHotkeys($event)">
        <!-- Subtype -->
        <article
            *ngIf="routeElement?.type === routeElementType.Connector || routeElement?.type === routeElementType.Door || routeElement?.type === routeElementType.EntryPoint">
            <h3>Type</h3>
            <route-element-subtype [routeElement]="routeElement" formControlName="subtype"></route-element-subtype>
        </article>

        <!-- Element Value (Floor connectors) -->
        <article *ngIf="routeElement?.type === routeElementType.Connector">
            <h3>Element Value *</h3>
            <label class="mi-label">
                <input type="number" class="mi-input" placeholder="Enter value" formControlName="elementValue">
            </label>
        </article>

        <!-- Manage Access -->
        <article>
            <h3>Restrictions</h3>
            <restrictions-input formControlName="restrictions"></restrictions-input>
        </article>

        <hr>

        <!-- Advanced -->
        <article class="advanced">
            <label
                *ngIf="routeElement?.geometry?.type === geoJSONGeometryType.LineString"
                class="mi-label">
                Width ({{ unitSystem === 'imperial' ? 'inches' : 'cm' }})
                <input type="number" class="mi-input" placeholder="Enter width" formControlName="width" />
                <small role="alert" class="mi-form__error" *ngIf="form.controls['width'].hasError('invalidGeometry')">
                    Too wide. Try to decrease the width.
                </small>
            </label>

            <label class="mi-label">
                Delay (seconds)
                <input type="number" class="mi-input" placeholder="Enter Seconds" formControlName="waitTime">
            </label>

            <label class="mi-label">
                Radius (meters)
                <input type="number" class="mi-input" placeholder="Enter Meters" formControlName="radius">
            </label>

            <label class="mi-label">
                Floor Index *
                <input type="number" class="mi-input" placeholder="Enter Floor Index" formControlName="floorIndex">
            </label>

            <label class="mi-label">
                One way direction (bearing)
                <input type="number" class="mi-input" placeholder="Enter Bearing" formControlName="onewayDirection">
            </label>
        </article>

        <hr>

        <!-- Details -->
        <article class="details">
            <ul>
                <li><strong>Id:</strong> {{ routeElement?.id }}</li>
                <li *ngIf="routeElement"><strong>Latitude:</strong> {{ getAnchorPoint()[1] }}</li>
                <li *ngIf="routeElement"><strong>Longitude:</strong> {{ getAnchorPoint()[0] }}</li>
                <li><strong>Last modified by:</strong> {{ routeElement?.lastModifiedBy }}</li>
                <li><strong>Last modified:</strong> {{ routeElement?.lastModified }}</li>
            </ul>
        </article>
    </section>

    <panel-footer *ngIf="form.dirty" class="footer" text="You have unsaved changes.">
        <button type="button" class="mi-button mi-button--link" [disabled]="isDiscardButtonDisabled" (click)="onDiscard()">Discard</button>
        <button type="submit" class="mi-button mi-button--primary" [disabled]="!form.valid">Save</button>
    </panel-footer>
</form>