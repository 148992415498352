<ng-container *ngIf="languages" [formGroup]="translationsForm">
    <mi-tabs>
        <ng-container *ngFor="let language of languages; index as i;">
            <mi-tab
                [label]="language === selectedSolution.defaultLanguage || allTranslationsAreRequired ? language + '*' : language"
                [tabFor]="'tab-panel-' + language">
            </mi-tab>
            <mi-tab-panel [id]="'tab-panel-' + language" [formGroupName]="language">
                <section class="section">
                    <label class="mi-label">
                        <div class="mi-label__text">
                            <input class="setting__lock" type="checkbox" formControlName="inheritName" *ngIf="translationsForm.get(language).get('inheritName')" />
                            {{language === selectedSolution.defaultLanguage ? 'Name (Required)' : 'Name'}}
                        </div>
                        <textarea class="mi-textarea" formControlName="name" rows="2" placeholder="Name">
                        </textarea>
                        <small role="alert" class="mi-form__error"
                            *ngIf="translationsForm.get(language).get('name').hasError('required') || translationsForm.get(language).get('name').hasError('isTextEmpty')">
                            Name is required
                        </small>
                    </label>
    
                    <label class="mi-label">
                        <div class="mi-label__text">
                            <input type="checkbox" class="setting__lock" formControlName="inheritDescription" *ngIf="translationsForm.get(language).get('inheritDescription')" />
                            Description
                        </div>
                        <textarea class="mi-textarea" formControlName="description" rows="2" placeholder="Description">
                        </textarea>
                    </label>
                </section>
            
                <button *ngIf="languages.size > 1 && allTranslationsAreRequired" type="button" class="mi-button mi-button--base mi-button--top-medium-margin"
                    (click)="onTranslate(language)"> Translate all
                </button>
            </mi-tab-panel>
        </ng-container>
    </mi-tabs>
</ng-container>