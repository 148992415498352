import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';
import { MediaCategory, MediaFileType } from '../media.enum';
import { MediaLibraryService } from '../media-library.service';
import { MediaItem } from './media-item.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MediaUpload3DComponent } from '../media-upload-3d/media-upload-3d.component';
import { removeBase64Prefix } from '../media-library.shared';
import { MediaFileUploadRequestBody } from '../media-request-upload.model';

@Component({
    selector: 'media-item',
    templateUrl: './media-item.component.html',
    styleUrls: ['./media-item.component.scss']
})
export class MediaItemComponent implements OnInit, AfterContentInit, OnDestroy {
    private _isSyncButtonShown: boolean;
    private imageContainerObserver: IntersectionObserver | undefined;
    private mediaContainerSubject = new Subject<{
        entry: IntersectionObserverEntry;
        observer: IntersectionObserver;
    }>();
    private mediaUpload3DModalConfig: MatDialogConfig = {
        width: '90vw',
        minWidth: '1024px', // smallest screen size supported by CMS
        maxWidth: '1700px', // the number is a subject to change after UX has tested properly
        height: '95vh',     // the number is a subject to change after UX has tested properly
        maxHeight: '928px', // the number is a subject to change after UX has tested properly
        minHeight: '550px', // the number is a subject to change after UX has tested properly
        role: 'dialog',
        panelClass: 'details-dialog',
        disableClose: true
    };

    /**
     * Returns _isSyncButtonShown.
     *
     * @returns {boolean}
     */
    public get isSyncButtonShown(): boolean {
        return this._isSyncButtonShown;
    }
    public categories = MediaCategory;
    public deleteButtonDisabled: boolean = false;
    public changePreviewButtonDisabled: boolean = false;
    public mediaFileTypes = MediaFileType;

    /**
     * Setting displaySyncButton.
     */
    @Input() set isSyncButtonShown(isSyncButtonShown: boolean) {
        this._isSyncButtonShown = isSyncButtonShown && (this.mediaItem.category === this.categories.Image || this.mediaItem.category === this.categories.Model3D);
    }
    @Input() mediaItem: MediaItem;
    @Input() debounceTime = 0; // Specification that this much time should pass (before running a function).
    @Input() threshold = 1; // Specification that (we want to run something when) a 100% of the target is visible.

    @Output() syncButtonClicked = new EventEmitter<MediaItem>();
    @Output() mediaItemSelected = new EventEmitter<MediaItem>();

    constructor(
        private element: ElementRef,
        private mediaLibraryService: MediaLibraryService,
        private notificationService: NotificationService,
        private matDialog: MatDialog
    ) { }

    /**
     * Creates the observer instance.
     */
    ngOnInit(): void {
        this.createImageContainerObserver();

        this.mediaLibraryService.isServiceBusy$
            .subscribe((isBusy) => {
                this.deleteButtonDisabled = isBusy;
            });
    }

    /**
     * When loaded, start observing the element.
     */
    ngAfterContentInit(): void {
        this.startObservingElements();
    }

    /**
     * Stops observing the element when the component is being destroyed.
     */
    ngOnDestroy(): void {
        if (this.imageContainerObserver) {
            this.imageContainerObserver.disconnect();
            this.imageContainerObserver = undefined;
        }

        this.mediaContainerSubject.next();
        this.mediaContainerSubject.complete();
    }

    /**
     * Creating a new observer for the entry, when the entry will intersect (be in view) it will call next.
     */
    private createImageContainerObserver(): void {
        this.imageContainerObserver = new IntersectionObserver((entries, observer) => {
            // entries - is the list received by the callback includes one entry for each target which reported a change in its intersection status - we expect it to contain exzctly 1 element.
            // observer - the observer that is observing the changes. We need it to be able to call the unobserve() function on it later.
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.mediaContainerSubject.next({ entry, observer });
                }
            });
        });
    }

    /**
     * Starts observing the target element.
     */
    private startObservingElements(): void {
        if (!this.imageContainerObserver) return;

        // The callback in the 'createImageContainerObserver' function will be executed now for the first time.
        // It waits until we assign a target to our observer (even if the target is currently not visible).
        this.imageContainerObserver.observe(this.element.nativeElement);

        // Is run when the function is intersecting.
        this.mediaContainerSubject
            .pipe(
                delay(this.debounceTime),
                filter(Boolean)
            )
            .subscribe(
                ({ entry, observer }: any) => {
                    const target = entry.target as HTMLElement;
                    this.loadImagePreview();
                    observer.unobserve(target);
                },
                () => {
                    this.notificationService.showError('Something went wrong. Please try again.');
                }
            );
    }

    /**
     * Loads the preview image for the entry.
     */
    private loadImagePreview(): void {
        if (this.mediaItem.category === MediaCategory.MIIcon || this.mediaItem.category === MediaCategory.MILabel) return;
        this.mediaItem.preview = this.mediaLibraryService.getMediaPreviewById(this.mediaItem.id);
    }

    /**
     * Changes the preview image of the media library.
     *
     * @param {File} file
     * @param {string} previewSrc
     */
    private changePreview(file: Blob, previewSrc: string): void {
        const previewSrcWithoutBase64Prefix = removeBase64Prefix(previewSrc);
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
            // The Backend expects the data to be without the Data-URL declaration (E.g.: data:image/jpeg;base64)
            const data = removeBase64Prefix(reader.result as string);
            const requestBody: MediaFileUploadRequestBody = {
                id: this.mediaItem.id,
                name: this.mediaItem.name,
                type: this.mediaItem.type,
                data: data,
                preview: previewSrcWithoutBase64Prefix,
                labels: []
            };

            this.mediaLibraryService.updateMediaItem(requestBody)
                .subscribe(() => {
                    this.mediaItem.preview = previewSrc;
                });
        };
    }

    /**
     * Returns an alert message to display based on the references object.
     *
     * @param {{ appConfigCategoryKeys: string[], buildingIds: string[], categoryIds: string[], isUsedBySolutionConfig: boolean, locationIds: string[], locationTypeIds: string[], referencesCount: number, venueIds: string[] }} references
     * @returns {string}
     */
    private createAlertMessage(references: { appConfigCategoryKeys: string[], buildingIds: string[], categoryIds: string[], isUsedBySolutionConfig: boolean, locationIds: string[], locationTypeIds: string[], referencesCount: number, venueIds: string[] }): string {
        let message: string = `The selected file is used in ${references.referencesCount} places. Please change the file where it’s used before deleting.\nFind the places it’s used with the following IDs:`;

        if (references.isUsedBySolutionConfig) {
            message += '\nMain display Rules';
        }

        if (references.appConfigCategoryKeys.length > 0) {
            message += '\nApp Config Category Keys: ';
            references.appConfigCategoryKeys.forEach((id: string) => message += `\n- ${id}`);
        }

        if (references.buildingIds.length > 0) {
            message += '\nBuilding IDs: ';
            references.buildingIds.forEach((id: string) => message += `\n- ${id}`);
        }

        if (references.categoryIds.length > 0) {
            message += '\nCategory IDs: ';
            references.categoryIds.forEach((id: string) => message += `\n- ${id}`);
        }

        if (references.locationIds.length > 0) {
            message += '\nLocation IDs: ';
            references.locationIds.forEach((id: string) => message += `\n- ${id}`);
        }

        if (references.locationTypeIds.length > 0) {
            message += '\nType IDs: ';
            references.locationTypeIds.forEach((id: string) => message += `\n- ${id}`);
        }

        if (references.venueIds.length > 0) {
            message += '\nVenue IDs: ';
            references.venueIds.forEach((id: string) => message += `\n- ${id}`);
        }

        return message;
    }

    /**
     * Deletes the media item.
     *
     * @param {PointerEvent} event
     */
    public onDelete(event: PointerEvent): void {
        event.stopPropagation();

        this.mediaLibraryService.getMediaItemDetails(this.mediaItem.id)
            .subscribe(media => {
                if (media?.references.referencesCount > 0 || media?.references.isUsedBySolutionConfig) {
                    // eslint-disable-next-line no-alert
                    alert(this.createAlertMessage(media?.references));
                    return;
                } else {
                    // eslint-disable-next-line no-alert
                    if (!confirm('Are you sure you want to delete the file?')) {
                        return;
                    }
                }
                this.mediaLibraryService.deleteMedia(this.mediaItem.id).subscribe();
            });
    }

    /**
     * Opens the 3D preview modal and updates the preview image of the 3D model.
     *
     * @param {PointerEvent} event
     */
    public onChangePreview(event: PointerEvent): void {
        event.stopPropagation();

        this.changePreviewButtonDisabled = true;
        this.mediaLibraryService.getMediaItem(`${this.mediaItem.name}.${this.mediaItem.type}`)
            .subscribe((result) => {
                const mediaFile = new File([result], `${this.mediaItem.name}.${this.mediaItem.type}`);
                const dialog = this.matDialog.open(MediaUpload3DComponent, { ...this.mediaUpload3DModalConfig, data: { file: mediaFile, mediaId: this.mediaItem.id } });
                dialog.afterClosed()
                    .pipe(
                        tap(() => this.changePreviewButtonDisabled = false)
                    )
                    .subscribe((response) => {
                        if (!response) return;

                        this.changePreview(result, response?.previewSrc);
                    });
            });
    }

    /**
     * Emits the syncButtonClicked event with the clicked media item.
     *
     * @param {PointerEvent} event
     * @param {MediaItem} mediaItem
     */
    public onSyncDialog(event: PointerEvent, mediaItem: MediaItem): void {
        event.stopPropagation();
        this.syncButtonClicked.emit(mediaItem);
    }

    /**
     * Emits an event with the clicked media item.
     *
     * @param {MediaItem} mediaItem
     */
    public onSelect(mediaItem: MediaItem): void {
        this.mediaItemSelected.emit(mediaItem);
    }

    /**
     * Copies the URL to the clipboard.
     *
     * @param {string} url
     */
    public onCopyUrl(url: string): void {
        navigator.clipboard.writeText(url);
    }
}