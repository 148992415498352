import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';

abstract class AreaAsObstacleViewModelProperties implements ViewModelProperties {
    clickable: boolean;
    featureClass: FeatureClass;
    fillOpacity: number;
    iconVisible: boolean;
    originalId: string;
    originalType: MapsIndoorsData;
    pixelOffset: number;
    sortKey: number;
    strokeColor: string;
    strokeOpacity: number;
    strokeWidth: number;
    visible: boolean;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a AreaAsObstacleViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @param {MapsIndoorsData} originalType
     * @returns {Promise<AreaAsObstacleViewModelProperties>}
     */
    static create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData): AreaAsObstacleViewModelProperties {
        return {
            clickable: false,
            featureClass: FeatureClass.AREAASOBSTACLE,
            fillOpacity: 0,
            iconVisible: false,
            originalId: id,
            originalType,
            pixelOffset: (displayRule?.polygon?.strokeWidth ?? 0) / 2,
            sortKey: SortKey.OBSTACLE + sortKey,
            strokeColor: '#FDBA74',
            strokeOpacity: 1,
            strokeWidth: 3,
            visible: true,
            zoomRange: [-Infinity, Infinity],
        };
    }
}

export class AreaAsObstacleViewModel implements MapViewModel {
    geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.LineString;
    readonly id: string;
    readonly type = 'Feature';
    readonly properties: AreaAsObstacleViewModelProperties;

    private constructor(id: string, geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.LineString, properties: AreaAsObstacleViewModelProperties) {
        this.id = `AREAASOBSTACLE:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory function to create a AreaAsObstacleViewModel from an id, a point, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {GeoJSON.Point | GeoJSON.Polygon | GeoJSON.LineString} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @param {MapsIndoorsData} originalType
     * @returns {AreaAsObstacleViewModel}
     */
    static async create(id: string, geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.LineString, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData): Promise<AreaAsObstacleViewModel> {
        const properties = AreaAsObstacleViewModelProperties.create(id, index, displayRule, originalType);
        return await new AreaAsObstacleViewModel(id, geometry, properties);
    }
}