import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { AlertDialogComponent } from '../../../shared/alert-dialog/alert-dialog.component';
import { AppUserRole } from './app-user-role.model';
import { AppUserRoleDialogComponent } from './app-user-role-dialog/app-user-role-dialog.component';
import { AppUserRolesService } from './app-user-roles.service';
import { DialogType } from '../../../shared/enums';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotificationService } from '../../../services/notification.service';
import { Solution } from '../../../solutions/solution.model';
import { SolutionService } from '../../../services/solution.service';
import { Subscription } from 'rxjs';
import { primitiveClone } from '../../../shared/object-helper';

@Component({
    selector: 'app-user-roles',
    templateUrl: './app-user-roles.component.html',
    styleUrls: ['./app-user-roles.component.scss']
})
export class AppUserRolesComponent implements OnInit, OnDestroy {
    private subscribtions = new Subscription();
    private currentSolution: Solution;
    /**
     * Reference to the table.
     *
     * @type {MatTable<AppUserRole>}
     * @memberof AppUserRolesComponent
     */
    @ViewChild('table') table: MatTable<AppUserRole>;
    /**
     * Event emitter to show or hide the progress bar.
     *
     * @memberof AppUserRolesComponent
     */
    @Output() progressBarVisible = new EventEmitter<boolean>(true);
    /**
     * The table's datasource.
     *
     * @memberof AppUserRolesComponent
     */
    public appUserRoleTableDataSource = new MatTableDataSource<AppUserRole>();

    /**
     * Table's columns that will be displayed.
     */
    public columnsToDisplay = ['edit', 'displayName'];

    constructor(
        private appUserRolesService: AppUserRolesService,
        private notificationService: NotificationService,
        private dialog: MatDialog,
        private solutionService: SolutionService) { }

    /**
     * Init lifecycle hook. Called after the constructor.
     *
     * @memberof AppUserRolesComponent
     */
    ngOnInit(): void {
        this.subscribtions.add(this.subscribeSelectedSolution());
        this.subscribtions.add(this.subscribeAppUserRoles());
    }

    ngOnDestroy(): void {
        this.subscribtions.unsubscribe();
    }

    /**
     * Subscribe to the AppUserRoles observable.
     *
     * @returns {Subscription}
     */
    private subscribeAppUserRoles(): Subscription {
        this.progressBarVisible.emit(true);
        return this.appUserRolesService.appUserRoles$.subscribe(
            appUserRoles => {
                this.progressBarVisible.emit(false);
                this.appUserRoleTableDataSource.data = appUserRoles.slice();
            },
            err => {
                this.notificationService.showError(err);
                this.progressBarVisible.emit(false);
            }
        );
    }

    /**
     * Subscribe to the selected Solution observable.
     *
     * @returns {Subscription}
     */
    private subscribeSelectedSolution(): Subscription {
        return this.solutionService.selectedSolution$.subscribe(solution => this.currentSolution = solution);
    }

    /**
     * Click event opens the dialog for creating or editing the App User Roles.
     *
     * @param {AppUserRole} userRole - The role to be edited.
     * @memberof AppUserRolesComponent
     */
    public onOpenAppUserRoleDialog(userRole?: AppUserRole): void {
        const appUserRole = userRole ? userRole : { names: [] };
        const languages = this.currentSolution.availableLanguages.slice();

        if (userRole === null || userRole === undefined) { //checks for empty or undefined role. If true then we create a new role.
            languages.forEach(lang => appUserRole.names.push({ name: '', language: lang }));
        }

        const dialogRef = this.dialog.open(AppUserRoleDialogComponent, {
            width: '300px',
            maxHeight: 800,
            disableClose: false,
            role: 'dialog',
            autoFocus: false,
            data: {
                appUserRole: primitiveClone(appUserRole),
                dialogType: userRole ? DialogType.edit : DialogType.create,
                defaultLanguage: this.currentSolution.defaultLanguage
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                switch (result.dialogType) {
                    case DialogType.create:
                        this.createAppUserRole(result.appUserRole);
                        break;
                    case DialogType.edit:
                        this.updateAppUserRole(result.appUserRole);
                        break;
                    case DialogType.delete:
                        this.openDeleteAlertDialog(result.appUserRole);
                        break;
                    default:
                        break;
                }
            }
        });
    }

    /**
     * Creates an app user role by calling the create method from them service.
     *
     * @param appUserRole - The role to be created.
     */
    public createAppUserRole(appUserRole: AppUserRole): void {
        this.progressBarVisible.emit(true);
        this.appUserRolesService.createAppUserRole(appUserRole).subscribe(
            () => {
                this.progressBarVisible.emit(false);
                this.notificationService.showSuccess('Created!');
            },
            err => {
                this.notificationService.showError(err);
                this.progressBarVisible.emit(false);
            }
        );
    }

    /**
     * Updates an app user role by calling the update method from them service.
     *
     * @param appUserRole - The role to be updated.
     */
    public updateAppUserRole(appUserRole: AppUserRole): void {
        this.progressBarVisible.emit(true);
        this.appUserRolesService.updateAppUserRole(appUserRole).subscribe(
            () => {
                this.progressBarVisible.emit(false);
                const role = this.appUserRoleTableDataSource.data.find(item => item.id === appUserRole.id);
                role.names = appUserRole.names;
                this.notificationService.showSuccess('Updated!');
            },
            err => {
                this.notificationService.showError(err);
                this.progressBarVisible.emit(false);
            }
        );
    }

    /**
     * Deletes an app user role by calling the delete method from them service.
     *
     * @param appUserRole - The role to be deleted.
     */
    public deleteAppUserRole(appUserRole: AppUserRole): void {
        this.progressBarVisible.emit(true);
        this.appUserRolesService.deleteAppUserRole(appUserRole).subscribe(
            () => {
                this.appUserRoleTableDataSource.data = this.appUserRoleTableDataSource.data.filter(role => role.id !== appUserRole.id);
                this.table.renderRows();
                this.notificationService.showSuccess('Deleted!');
                this.progressBarVisible.emit(false);
            },
            err => {
                this.notificationService.showError(err);
                this.progressBarVisible.emit(false);
            }
        );
    }

    /**
     * Opens an alert dialog when deleting a role.
     *
     * @param {AppUserRole} role - The role to be deleted.
     * @memberof AppUserRolesComponent
     */
    openDeleteAlertDialog(role: AppUserRole): void {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '350px',
            disableClose: true,
            role: 'alertdialog',
            data: { title: `Would you like to delete ${role.names[0].name} ?`, content: '', options: role }
        });

        dialogRef.afterClosed().subscribe(role => {
            if (role)
                this.deleteAppUserRole(role);
        });
    }
}
