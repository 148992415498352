import { Component, Provider, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { createDropdownItemElement } from '../../shared/mi-dropdown/mi-dropdown';
import { CategoryService } from '../../categories/category.service';

const CATEGORY_DROPDOWN_CONTROL_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LocationCategoriesComponent),
    multi: true,
};

@Component({
    selector: 'location-categories',
    templateUrl: './location-categories.component.html',
    styleUrls: ['./location-categories.component.scss'],
    providers: [CATEGORY_DROPDOWN_CONTROL_VALUE_ACCESSOR]
})
export class LocationCategoriesComponent implements ControlValueAccessor {
    @ViewChild('categoriesDropdown') categoriesDropdownElement: ElementRef<HTMLMiDropdownElement>;
    private onTouched!: Function;
    private onChanged!: Function;
    public categoriesDropdownItems: HTMLMiDropdownItemElement[] = [];
    public selectedCategories: HTMLMiDropdownItemElement[] = [];

    constructor(private categoryService: CategoryService) { }

    /**
     * OnChange handler.
     *
     * @memberof LocationCategoriesComponent
     */
    public onSelectionChange({ detail }: CustomEvent): void {
        this.selectedCategories = detail;
        const categoryKeys = this.selectedCategories?.map(item => item.value);
        this.onChanged(categoryKeys);
        this.onTouched();
    }

    /**
     * Remove from from list.
     *
     * @param {string} value - Index of category to remove.
     * @memberof LocationCategoriesComponent
     */
    public onRemoveChip(value: string): void {
        this.selectedCategories = this.selectedCategories.filter(item => item.value !== value);
        const categoryKeys = this.selectedCategories?.map(item => item.value);
        const item = this.categoriesDropdownItems.find(item => item.value === value);
        if (item) {
            item.selected = false;
        }

        this.onChanged(categoryKeys);
        this.onTouched();
    }

    /**
     * Writes a new value to the element.
     *
     * This method is called by the forms API to write to the view when programmatic
     * changes from model to view are requested.
     *
     * @param {string[]} categoryKeys - The new value for the element.
     */
    writeValue(categoryKeys: string[]): void {
        this.selectedCategories = [];
        const categories = this.categoryService.getCategoriesFromStore();
        this.categoriesDropdownItems = categories?.map(
            category => {
                const selected = categoryKeys?.includes(category.key) || false;
                const item = createDropdownItemElement({ label: category.displayName, value: category.key, selected });
                selected && this.selectedCategories.push(item);
                return item;
            }) || [];
    }

    /**
     * Registers a callback function that is called when the control's value
     * changes in the UI.
     *
     * This method is called by the forms API on initialization to update the form
     * model when values propagate from the view to the model.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization
     * to update the form model on blur.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Set disabled state.
     */
    setDisabledState(): void {
        if (this.categoriesDropdownElement?.nativeElement) {
            this.categoriesDropdownElement.nativeElement.disabled = this.categoriesDropdownItems?.length < 1;
        }
    }
}
