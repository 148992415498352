import { BuildingHighlightComponent } from './building-highlight/building-highlight.component';
import { MainDisplayRuleComponent } from './main-display-rule/main-display-rule.component';
import { MapBehaviorComponent } from './map-behavior/map-behavior.component';
import { NgModule } from '@angular/core';
import { OccupantAssignmentAlgorithmComponent } from './occupant-assignment-algorithm/occupant-assignment-algorithm.component';
import { OccupantsGuard } from '../guards/occupants.guard';
import { Routes, RouterModule } from '@angular/router';
import { SolutionSettingsComponent } from './solution-settings.component';
import { ThreeDSettingsComponent } from './3d-settings/settings-3d.component';

const routes: Routes = [
    {
        path: '',
        component: SolutionSettingsComponent,
        children: [
            {
                path: 'main-display-rule',
                component: MainDisplayRuleComponent
            },
            {
                path: 'map-behavior',
                component: MapBehaviorComponent
            },
            {
                path: 'building-highlight',
                component: BuildingHighlightComponent
            },
            {
                path: '3d-settings',
                component: ThreeDSettingsComponent
            },
            {
                path: 'occupant-assignment-algorithm',
                component: OccupantAssignmentAlgorithmComponent,
                canActivate: [OccupantsGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SolutionSettingsRoutingModule {}