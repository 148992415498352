import { BaseMapAdapter, MapMouseCursor } from '../MapAdapter/BaseMapAdapter';
import { filter, finalize, map, reduce, repeat, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BuildingService } from '../buildings/building.service';
import { euclideanDistance, euclideanNearestPointOnLine } from '../../utilities/Math';
import { Floor } from '../buildings/floor.model';
import { GeodataEditor } from './GeodataEditor';
import { GeodataEditorFactory } from './GeodataEditorFactory';
import { computeIntersection, getMetersPerPixelAt, toLngLat, toMercator, isPointsEqual, fixedAngleLine } from '../shared/geometry-helper';
import { Graph, GraphEdge, GraphNode, RouteContext } from '../services/GraphService/Graph';
import { Observable, Subscription, combineLatest, fromEvent, merge } from 'rxjs';
import { Position, booleanContains, lineIntersect, lineString, rhumbBearing, point as toPoint } from '@turf/turf';
import { throttle } from 'throttle-debounce';
import { fromKeyDown, fromKeyPress, fromKeyUp } from '../../utilities/rxjs';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { UndoRedoAction, UndoRedoManager } from '../../utilities/UndoRedoManager/UndoRedoManager';

const SELECTED_STROKE_COLOR = midt['tailwind-colors'].pink[600].value;

export enum GraphEditorMode {
    EDIT,
    DRAW
}

export class GraphEditor {
    #floorsUnion: GeoJSON.MultiPolygon;
    #isDragging = false;
    #graph: Graph;
    #mode: GraphEditorMode;
    #subscription: Subscription;
    #floors: Floor[];
    #floorName: string;
    #floorIndex: number;
    #geodataEditor: GeodataEditor;
    #selection = new Set<GraphNode | GraphEdge>();
    #referenceEdge: GraphEdge;
    #hovered: GraphNode | GraphEdge;
    #floorChangeSubscription: Subscription;
    #shadowHandle: GeoJSON.Feature<GeoJSON.Point> | undefined;
    #contextSwitch: GeoJSON.Feature<GeoJSON.Point> | undefined;
    #previewEdge: GeoJSON.Feature<GeoJSON.LineString> | undefined;
    #keysPressed = new Set();
    #bearing: number;
    #history: UndoRedoManager = new UndoRedoManager();

    constructor(
        private readonly mapAdapter: BaseMapAdapter,
        private readonly buildingService: BuildingService
    ) {
        this.#geodataEditor = GeodataEditorFactory.create(this.mapAdapter);
        this.mode = GraphEditorMode.DRAW;

        this.#floorChangeSubscription = this.buildingService.selectedFloor$.subscribe(floor => {
            this.#floorIndex = floor?.floorIndex;
            this.#floorName = floor?.displayName;

            if (Number.isFinite(this.#floorIndex)) {
                this.#renderGraph();
            }
        });

        this.buildingService.selectedFloor$
            .pipe(
                switchMap(selectedFloor => this.buildingService.buildings$.pipe(map(buildings => ({ selectedFloor, buildings })))),
                map(({ selectedFloor, buildings }) => buildings
                    .flatMap(building => building.floors.filter(floor => floor.floorIndex === selectedFloor?.floorIndex)))
            )
            .subscribe(floors => {
                this.#floors = floors;
                this.#floorsUnion = floors.reduce((floorsUnion, floor) => {
                    if (floor.geometry.type === 'Polygon') {
                        floorsUnion.coordinates.push(floor.geometry.coordinates);
                    } else if (floor.geometry.type === 'MultiPolygon') {
                        floorsUnion.coordinates.push(floor.geometry.coordinates.flat());
                    }
                    return floorsUnion;
                }, { type: 'MultiPolygon', coordinates: [] });
            });
    }

    /**
     * Sets the mode of the graph editor.
     */
    public set mode(mode: GraphEditorMode) {
        if (mode === this.#mode) {
            return;
        }

        this.#subscription?.unsubscribe();
        switch (mode) {
            case GraphEditorMode.EDIT:
                this.#mode = GraphEditorMode.EDIT;
                this.mapAdapter.setMapMouseCursor(MapMouseCursor.Grab);
                this.#subscription = this.#initSelectMode();
                break;
            case GraphEditorMode.DRAW:
                this.#mode = GraphEditorMode.DRAW;
                this.#selection = new Set<GraphNode | GraphEdge>();
                this.mapAdapter.setMapMouseCursor(MapMouseCursor.Crosshair);
                this.#subscription = this.#initDrawingMode();
                break;
        }
    }

    /**
     * Returns the current active mode of the graph editor.
     *
     * @returns {GraphEditorMode} The mode.
     */
    public get mode(): GraphEditorMode {
        return this.#mode;
    }

    /**
     * Checks if there are any selected nodes or edges.
     *
     * @returns {boolean} True if there are selected elements, false otherwise.
     */
    public hasSelection(): boolean {
        return this.#selection.size > 0;
    }

    /**
     * Deletes the selected nodes and edges.
     */
    public deleteSelected(): void {
        const steps: UndoRedoAction<any>[] = [];

        for (const selected of this.#selection) {
            if (Graph.isGraphEdge(selected)) {
                this.#graph.removeEdge(selected);
                steps.push({
                    args: selected,
                    undo: (edge) => this.#graph.addEdge(edge),
                    redo: (edge) => this.#graph.removeEdge(edge)
                });
            } else {
                const connectedEdges = this.#graph.getConnectedEdges(selected);
                this.#graph.removeNode(selected);
                steps.push({
                    args: { node: selected, edges: connectedEdges },
                    undo: ({ node, edges }) => {
                        this.#graph.addNode(node);
                        for (const edge of edges) {
                            this.#graph.addEdge(edge);
                        }
                    },
                    redo: ({ node }) => this.#graph.removeNode(node)
                });
            }
        }

        this.#selection.clear();
        this.#renderGraph();
        this.#history.push({
            undo: () => steps.forEach(step => step.undo(step.args)),
            redo: () => steps.forEach(step => step.redo(step.args))
        });
    }

    /**
     * Initializes the drawing mode.
     *
     * @returns {Subscription} The subscription.
     */
    #initDrawingMode(): Subscription {
        let intersections;
        const subscription = new Subscription();

        const mouseMove$ = this.#geodataEditor.mouseMove$;
        const escape$ = fromKeyUp('Escape')
            .pipe(tap(() => {
                this.#referenceEdge = undefined;
                intersections = undefined;
            }));

        const eKeyPressSubscribtion = fromKeyPress('e')
            .subscribe((event) => {
                event && this.#previewEdge ? this.#keysPressed.add('e') : this.#keysPressed.delete('e');
                this.#renderGraph();
            });

        const pKeyPressSubscribtion = fromKeyPress('p')
            .subscribe((event) => {
                event && this.#previewEdge ? this.#keysPressed.add('p') : this.#keysPressed.delete('p');
                this.#renderGraph();
            });

        const lKeyPressSubscribtion = fromKeyUp('l')
            .subscribe(() => {
                this.#bearing = null;
                this.#renderGraph();
            });

        const undoShortcutSubscription = fromKeyDown('z')
            .pipe(filter(event => event?.ctrlKey || event?.metaKey))
            .subscribe((event) => {
                event.preventDefault();
                this.undo();
                return false;
            });

        const redoShortcutSubscription = fromKeyDown('y')
            .pipe(filter(event => event?.ctrlKey || event?.metaKey))
            .subscribe((event) => {
                event.preventDefault();
                this.redo();
                return false;
            });

        const edgeHoverSubscription = this.#geodataEditor.mouseHover$
            .pipe(
                filter(() => this.#keysPressed.has('e') || this.#keysPressed.has('p')),
                map((feature) => this.#graph.getEdge(feature?.id as number)),
            )
            .subscribe((edge) => {
                this.#hovered = edge;
                this.#renderGraph();
            });

        const edgeClickSubscription = this.#geodataEditor.geometryClick$.pipe(
            filter(() => this.#keysPressed.has('e') || this.#keysPressed.has('p')),
            map((feature) => this.#graph.getEdge(feature?.id as number)),
        ).subscribe((edge) => {
            if (!edge) {
                return;
            }

            this.#bearing = rhumbBearing(edge.from.geometry.coordinates, edge.to.geometry.coordinates);
            this.#bearing = this.#keysPressed.has('e') ? this.#bearing + 90 : this.#bearing;
            this.#referenceEdge = edge;

            //If the preview edge is defined, and the bearing is a number, calculate the intersections between the preview edge and the graph edges.
            if (this.#previewEdge && isFinite(this.#bearing)) {
                const edges = this.#graph?.getEdges(this.#floorIndex);
                intersections = edges?.reduce((intersections, edge) => {
                    if (isPointsEqual(edge.from.geometry.coordinates, this.#previewEdge.geometry.coordinates[0]) || isPointsEqual(edge.to.geometry.coordinates, this.#previewEdge.geometry.coordinates[0])) {
                        return intersections;
                    }

                    const intersection = computeIntersection(this.#previewEdge.geometry.coordinates[0], this.#bearing, [edge.from.geometry.coordinates, edge.to.geometry.coordinates]);
                    if (intersection && intersection) {
                        intersections.push(toPoint(intersection, { edge }));
                    }
                    return intersections;
                }, []);
            }
        });

        const handlesClick$ = this.#geodataEditor.handleClick$.pipe(
            filter(() => this.#keysPressed.size === 0 && (!this.#previewEdge || (lineIntersect(this.#floorsUnion, this.#previewEdge)?.features ?? []).length < 2)),
            map(id => {
                if (this.#shadowHandle?.properties?.edgeId) {
                    return this.#shadowHandle.geometry;
                } else {
                    return this.#graph.getNode(id)?.geometry;
                }
            }),
            share()
        );

        const mapClick$ = this.#geodataEditor.mapClick$.pipe(
            filter(() => this.#keysPressed.size === 0 && (!this.#previewEdge || (lineIntersect(this.#floorsUnion, this.#previewEdge)?.features ?? []).length < 2)),
            map((point: GeoJSON.Point) => {
                if (this.#shadowHandle?.properties?.edgeId)
                    return this.#shadowHandle.geometry;

                if (isFinite(this.#bearing) && this.#previewEdge)
                    return { type: 'Point', coordinates: this.#previewEdge.geometry.coordinates[1] } as GeoJSON.Point;

                return point;
            }),
            share()
        );

        const drawGraphSubscription = merge(
            this.#geodataEditor.handleClick$.pipe(map(id => this.#graph.getNode(id))),
            mapClick$
        )
            .pipe(
                takeUntil(escape$),
                filter(() => this.#keysPressed.size === 0 && (!this.#previewEdge || (lineIntersect(this.#floorsUnion, this.#previewEdge)?.features ?? []).length < 2)),
                reduce((from, to) => {
                    const steps: UndoRedoAction<any>[] = [];
                    /*
                        1. If the shadow handle is present, then the edge must be split, at the location of the shadow handle.
                            1.1 If the from is a node then the edge must be split at the location of the shadow handle, and an edge must be created between the from and the new node.
                        2. If to is a point, and from is null, then a node must be created at the location of to.
                        3. If to is a node, and from is null, to must be returned.
                        4. If to is a point, and from is a node, then a node must be created at the location of to, and an edge must be created between from and the new node.
                        5. If to is a node, and from is a point, then an edge must be created between from and to.

                        An edge can only intersect a building polygon once, if more that one intersection is found, the edge isn't valid.
                        If an edge intersects with a building polygon, the edge must be split at the intersection point.
                    */

                    if (this.#shadowHandle?.properties?.edgeId) {
                        const edge = this.#graph.getEdge(this.#shadowHandle.properties.edgeId);
                        to = this.#graph.createNode(this.#shadowHandle.geometry, this.#floorIndex, this.#floorName);

                        steps.push({ undo: (node) => this.#graph.removeNode(node), redo: (node) => this.#graph.addNode(node), args: to });

                        this.#graph.splitEdge(this.#shadowHandle.properties.edgeId, to as GraphNode);
                        steps.push({
                            args: { edge, node: to },
                            undo: ({ edge }) => this.#graph.addEdge(edge),
                            redo: ({ edge, node }) => this.#graph.splitEdge(edge.id, node)
                        });
                    } else if (to?.type === 'Point') {
                        to = this.#graph.createNode(to as GeoJSON.Point, this.#floorIndex, this.#floorName);
                        steps.push({ undo: (node) => this.#graph.removeNode(node), redo: (node) => this.#graph.addNode(node), args: to });
                    }

                    if (from) {
                        const fromContext = this.#getContext(from?.geometry);
                        const toContext = this.#getContext((to as GraphNode)?.geometry);
                        if (fromContext !== toContext) {
                            const fromHighway = fromContext === RouteContext.INSIDE_BUILING ? 'footway' : 'residential';
                            const toHighway = toContext === RouteContext.INSIDE_BUILING ? 'footway' : 'residential';
                            const contextSwitch = this.#graph.createNode(this.#contextSwitch.geometry, this.#floorIndex, this.#floorName);
                            const edges = [
                                this.#graph.createEdge(from, contextSwitch, { highway: fromHighway, context: fromContext, distance: 0, waittime: 0 }),
                                this.#graph.createEdge(contextSwitch, to as GraphNode, { highway: toHighway, context: toContext, distance: 0, waittime: 0 })
                            ];
                            steps.push({
                                undo: ({ contextSwitch }) => this.#graph.removeNode(contextSwitch),
                                redo: ({ edges, contextSwitch }) => {
                                    this.#graph.addNode(contextSwitch);
                                    edges.forEach((edge: GraphEdge) => this.#graph.addEdge(edge));
                                },
                                args: { contextSwitch, edges }
                            });
                        } else {
                            const highway = fromContext === RouteContext.INSIDE_BUILING ? 'footway' : 'residential';
                            const edge = this.#graph.createEdge(from, to as GraphNode, { highway, context: fromContext, distance: 0, waittime: 0 });
                            steps.push({ undo: (edge) => this.#graph.removeEdge(edge), redo: (edge) => this.#graph.addEdge(edge), args: edge });
                        }
                    }

                    this.#referenceEdge = undefined;
                    this.#geodataEditor.render(this.#graph.getEdges(this.#floorIndex).map(edge => edge.toLineString()));

                    if (steps.length > 0) {
                        this.#history.push({ undo: () => steps.forEach(step => step.undo(step.args)), redo: () => steps.forEach(step => step.redo(step.args)) });
                    }

                    return to;
                }, null),
                repeat()
            ).subscribe();

        //Subscribe to the mouseMove and mouseHover observables.
        const mouseMoveSubscription = combineLatest([this.#geodataEditor.mouseMove$, this.#geodataEditor.mouseHover$])
            .pipe(
                filter(() => !this.#isDragging && this.#keysPressed.size === 0)
            ).subscribe(([point, feature]) => {
                this.#hovered = this.#graph?.getNode(feature?.id as number);

                if (Number.isFinite(this.#bearing)) {
                    this.#hovered = null;
                }

                //Check if the mouse is hovering a node.
                if (this.#hovered) {
                    //Reset the shadow handle and rerender the graph.
                    this.#shadowHandle = undefined;
                    this.#renderHandles();
                    return;
                }
                const edges = this.#graph?.getEdges(this.#floorIndex) ?? [];
                let nearest: { distance: number, edge: GraphEdge, pointOnEdge: Position } = { distance: Infinity, edge: undefined, pointOnEdge: undefined };
                if (intersections && this.#previewEdge) {
                    nearest = intersections.reduce((nearest, intersection) => {
                        const [x0, y0] = toMercator(this.#previewEdge.geometry.coordinates[1]);
                        const [x1, y1] = toMercator(intersection.geometry.coordinates);
                        const distanceToIntersection = euclideanDistance([x0, y0], [x1, y1]) / getMetersPerPixelAt(this.mapAdapter.getZoom());

                        if (distanceToIntersection < nearest.distance) {
                            nearest = { distance: distanceToIntersection, edge: intersection.properties.edge, pointOnEdge: intersection.geometry.coordinates };
                        }

                        return nearest;

                    }, nearest);
                } else {
                    const [x0, y0] = toMercator(point.coordinates);
                    //Find the nearest edge to the mouses position.
                    nearest = edges?.reduce((nearest, edge) => {
                        const [x1, y1] = toMercator(edge.from.geometry.coordinates);
                        const [x2, y2] = toMercator(edge.to.geometry.coordinates);
                        const [x3, y3] = euclideanNearestPointOnLine([x0, y0], [[x1, y1], [x2, y2]]);
                        //Calculate the distance between the edge and mouse in pixels.
                        const distanceToEdge = euclideanDistance([x0, y0], [x3, y3]) / getMetersPerPixelAt(this.mapAdapter.getZoom());

                        if (distanceToEdge < nearest.distance) {
                            const pointOnEdge = toLngLat([x3, y3]);
                            nearest = { distance: distanceToEdge, edge, pointOnEdge };
                        }

                        return nearest;
                    }, nearest);
                }
                //Check if the distance is less than or equal to 16 pixels.
                if (nearest.distance <= 16) {
                    //If true, create the shadow handle.
                    this.#shadowHandle = toPoint(nearest.pointOnEdge as Position, { sortKey: -1, opacity: .7, edgeId: nearest.edge.id }, { id: -2 });
                } else {
                    //Else reset the shadow handle to undefined.
                    this.#shadowHandle = undefined;
                }

                this.#renderHandles();
            });

        const previewEdgeSubscription = this.previewEdge(handlesClick$, mapClick$, mouseMove$, escape$);

        const handlesDragSubscription = this.#geodataEditor.handleDrag$
            .pipe(
                takeUntil(fromEvent(document, 'mouseup')),
                reduce(({ from }, { index, position }) => {
                    const node: GraphNode = this.#graph.getNode(index);

                    if (!node) {
                        return { index: null, from: null, to: null };
                    }

                    this.#isDragging = true;

                    from ??= node?.geometry.coordinates;

                    this.mapAdapter.setMapMouseCursor(MapMouseCursor.Move);
                    this.#graph.getNode(index).geometry.coordinates = position;
                    this.#renderGraph();

                    return { index, from, to: position };

                }, { from: null, index: null, to: null }),
                finalize(() => {
                    if (this.#isDragging) {
                        this.mapAdapter.setMapMouseCursor(MapMouseCursor.Crosshair);
                        this.#isDragging = false;
                    }
                }),
                repeat()
            )
            .subscribe(({ index, from, to }) => {
                const node = this.#graph.getNode(index);

                if (!node) {
                    return;
                }

                this.#history.push<{ node: GraphNode, from: [number, number], to: [number, number] }>({
                    undo: ({ node, from }) => { node.geometry.coordinates = from; },
                    redo: ({ node, to }) => { node.geometry.coordinates = to; },
                    args: { node, from, to }
                });
            });

        const handlesRightClickSubscription = this.#geodataEditor.handleRightClick$.subscribe(index => {
            const node: GraphNode = this.#graph.getNode(index);

            if (node) {
                this.#removeNode(node);
                this.#renderGraph();
            }
        });

        subscription
            .add(drawGraphSubscription)
            .add(handlesDragSubscription)
            .add(handlesRightClickSubscription)
            .add(edgeHoverSubscription)
            .add(edgeClickSubscription)
            .add(previewEdgeSubscription)
            .add(mouseMoveSubscription)
            .add(eKeyPressSubscribtion)
            .add(pKeyPressSubscribtion)
            .add(lKeyPressSubscribtion)
            .add(undoShortcutSubscription)
            .add(redoShortcutSubscription);

        this.#renderGraph();

        return subscription;
    }

    /**
     * Renders a preview edge between the selected node and the mouse position.
     *
     * @param {Observable<GraphNode>} handlesClick$ - Observable for click on geometry handles.
     * @param {Observable<GeoJSON.Point>} mapClick$ - Observable for click on map.
     * @param {Observable<GeoJSON.Point>} mouseMove$ - Observable for mouse move.
     * @param {Observable<KeyboardEvent>} escape$ - Observable for escape key press.
     * @returns {Subscription} The subscription.
     */
    private previewEdge(handlesClick$: Observable<GeoJSON.Point>, mapClick$: Observable<GeoJSON.Point>, mouseMove$: Observable<GeoJSON.Point>, escape$: Observable<KeyboardEvent>): Subscription {
        return merge(handlesClick$, mapClick$)
            .pipe(
                switchMap((from) => mouseMove$.pipe(
                    takeUntil(escape$),
                    filter(() => this.#keysPressed.size === 0),
                    map(to => [from, to] as [GeoJSON.Point, GeoJSON.Point]),
                    finalize(() => {
                        this.#previewEdge = null;
                        this.#contextSwitch = null;
                        this.#bearing = null;
                        this.#renderGraph();
                    }),
                ))
            )
            .subscribe(([from, to]) => {
                let previewEdge = lineString([from.coordinates, to.coordinates], { clickable: false } as { [key: string]: unknown }, { id: -1 });

                if (this.#bearing) {
                    previewEdge = fixedAngleLine(from.coordinates, to.coordinates, this.#bearing, { clickable: false } as { [key: string]: unknown }, { id: -1 });
                }

                const intersections = lineIntersect(this.#floorsUnion, previewEdge)?.features ?? [];

                this.mapAdapter.setMapMouseCursor(MapMouseCursor.Crosshair);
                this.#contextSwitch = null;

                if (intersections.length === 1) {
                    this.#contextSwitch = toPoint(intersections[0].geometry.coordinates as Position, { sortKey: -1, opacity: .7, clickable: false }, { id: -3 });
                } else if (intersections.length > 1) {
                    this.mapAdapter.setMapMouseCursor(MapMouseCursor.NotAllowed);
                    previewEdge.properties = { strokeColor: SELECTED_STROKE_COLOR };
                }

                this.#previewEdge = previewEdge;
                this.#renderGraph();
            });
    }

    /**
     * Sets the graph to edit.
     *
     * @param {Graph} graph - The graph to edit.
     */
    public setGraph(graph?: Graph): void {
        this.#graph = new Graph(graph?.serialize() ?? { nodeData: [], edgeData: [] });
        this.#geodataEditor.clear();
        this.#history.clear();
        this.#selection.clear();
        this.#renderGraph();
    }

    /**
     * Returns the graph.
     *
     * @returns {Graph} The graph.
     */
    public getGraph(): Graph {
        return this.#graph;
    }

    /**
     * Clears the graph editor.
     */
    public clear(): void {
        this.#floorChangeSubscription.unsubscribe();
        this.#subscription?.unsubscribe();
        this.#geodataEditor?.clear();
        this.#selection.clear();
        this.#history.clear();
        this.mapAdapter.setMapMouseCursor(MapMouseCursor.Default);
    }

    /**
     * Undoes the last action.
     */
    public undo(): void {
        this.#history.undo();
        this.#renderGraph();
    }

    /**
     * Returns whether the graph editor can undo.
     *
     * @returns {boolean} True if the graph editor can undo, false otherwise.
     */
    public get canUndo(): boolean {
        return this.#history.position >= 0;
    }

    /**
     * Redoes the last undone action.
     */
    public redo(): void {
        this.#history.redo();
        this.#renderGraph();
    }

    /**
     * Returns whether the graph editor can redo.
     *
     * @returns {boolean} True if the graph editor can redo, false otherwise.
     */
    public get canRedo(): boolean {
        return this.#history.position < this.#history.length - 1;
    }

    /**
     * Renders the graph edges and handles in the geodata editor.
     */
    #renderGraph = throttle(16.66, () => {
        if (Number.isNaN(this.#floorIndex))
            return;

        if (!this.#graph)
            return;

        const edges = this.#graph.getEdges(this.#floorIndex).map(edge => {
            const feature = edge.toLineString();
            if (this.#selection.has(edge)) {
                feature.properties = { ...feature.properties, strokeColor: SELECTED_STROKE_COLOR, strokeWidth: 2 };
            }
            if (this.#hovered === edge) {
                feature.properties = { ...feature.properties, strokeWidth: 4 };
            }
            if (this.#referenceEdge === edge) {
                feature.properties = { ...feature.properties, strokeColor: SELECTED_STROKE_COLOR };
            }
            return feature;
        });

        if (this.#previewEdge && this.#keysPressed.size === 0) {
            const previewEdge = lineString([...this.#previewEdge.geometry.coordinates], this.#previewEdge.properties, this.#previewEdge);
            if ((this.#hovered as GraphNode)?.geometry?.type === 'Point') {
                previewEdge.geometry.coordinates[1] = (this.#hovered as GraphNode)?.geometry.coordinates;
            } else if (this.#shadowHandle) {
                previewEdge.geometry.coordinates[1] = this.#shadowHandle.geometry.coordinates;
            }

            edges.push(previewEdge);
        }
        this.#geodataEditor.render(edges);

        this.#renderHandles();
    });

    #renderHandles = throttle(16.66, () => {
        const handles = this.#graph?.getNodes(this.#floorIndex).map((node, index) => {
            const feature: GeoJSON.Feature<GeoJSON.Point> = node.toFeature();
            feature.properties.clickable = true;
            feature.properties.sortKey = index;

            if (this.#selection.has(node)) {
                feature.properties = { ...feature.properties, strokeColor: SELECTED_STROKE_COLOR };
            }
            if (this.#hovered === node) {
                feature.properties = { ...feature.properties, circleRadius: 5, strokeWidth: 2 };
            }
            return feature as GeoJSON.Feature<GeoJSON.Point>;
        }) ?? [];

        if (this.#shadowHandle) {
            handles.push(this.#shadowHandle);
        }

        if (this.#contextSwitch) {
            handles.push(this.#contextSwitch);
        }

        this.#geodataEditor.renderAsHandles(handles);
    });



    /**
     * Initializes the select mode.
     *
     * @returns {Subscription} The subscription.
     */
    #initSelectMode(): Subscription {
        const nodeClickSubscription = this.#geodataEditor.handleClick$.subscribe(id => {
            const node = this.#graph.getNode(id);
            this.#selection.has(node) ? this.#selection.delete(node) : this.#selection.add(node);
            this.#renderGraph();
        });

        const edgeClickSubscribton = this.#geodataEditor.geometryClick$.subscribe(feature => {
            const edge = this.#graph.getEdge(feature.id as number);
            this.#selection.has(edge) ? this.#selection.delete(edge) : this.#selection.add(edge);
            this.#renderGraph();
        });

        const hoverSubscription =
            this.#geodataEditor.mouseHover$.subscribe((feature: GeoJSON.Feature) => {
                if (feature) {
                    this.#hovered = this.#graph.getNode(feature?.id as number) ?? this.#graph.getEdge(feature?.id as number);
                    this.mapAdapter.setMapMouseCursor(MapMouseCursor.Pointer);
                } else {
                    this.#hovered = undefined;
                    this.mapAdapter.setMapMouseCursor(MapMouseCursor.Default);
                }

                this.#renderGraph();
            });

        const escapePressSubscription = fromEvent(document, 'keydown')
            .pipe(filter((event: KeyboardEvent) => event.key === 'Escape'))
            .subscribe(() => {
                this.#selection.clear();
                this.#renderGraph();
            });

        const deletePressSubscription = fromEvent(document, 'keydown')
            .pipe(filter((event: KeyboardEvent) => event.key === 'Delete'))
            .subscribe(this.deleteSelected.bind(this));

        const subscription = new Subscription();
        subscription.add(hoverSubscription);
        subscription.add(edgeClickSubscribton);
        subscription.add(nodeClickSubscription);
        subscription.add(escapePressSubscription);
        subscription.add(deletePressSubscription);
        return subscription;
    }

    /**
     * Returns the context of the given point.
     *
     * @param {GeoJSON.Point} point - The position of the node.
     * @returns {RouteContext} The context.
     */
    #getContext(point: GeoJSON.Point): RouteContext {
        const floor = getIntersectionFloor(point, this.#floors);
        return floor ? RouteContext.INSIDE_BUILING : RouteContext.OUTSIDE_ON_VENUE;
    }

    /**
     * Removes a node from the graph.
     *
     * @param {GraphNode} node - The node to remove.
     */
    #removeNode(node: GraphNode): void {
        const connectedEdges = this.#graph.getConnectedEdges(node);
        this.#graph.removeNode(node);
        this.#history.push<{ node: GraphNode, edges: GraphEdge[] }>({
            undo: (args) => {
                this.#graph.addNode(args.node);
                for (const edge of args.edges) {
                    this.#graph.createEdge(edge.from, edge.to, edge.properties);
                }
            },
            redo: (args) => {
                this.#graph.removeNode(args.node);
            },
            args: { node, edges: connectedEdges }
        });
    }
}

/**
 * Returns the floor that contains the given point.
 *
 * @param {GeoJSON.Point} point - The point.
 * @param {Floor[]} floors - The floors.
 * @returns {Floor} The floor.
 */
function getIntersectionFloor(point: GeoJSON.Point, floors: Floor[]): Floor {
    return floors.find(floor => booleanContains(floor.geometry as GeoJSON.Polygon | GeoJSON.MultiPolygon, point));
}
