import { Subject, Subscription, merge } from 'rxjs';
import { DisplayRule, Geometry, Location, LocationType } from '../../locations/location.model';
import { primitiveClone } from '../../shared/object-helper';
import { GeodataEditorViewState } from '../GeodataEditorViewState';
import { GeodataEditorOperation } from './GeodataEditorOperation';
import { ExtendedLocation } from '../../locations/location.service';
import { GeodataEditor } from '../GeodataEditor';
import { EditorMode } from '../../adapter/map-toolbar/tools/location-details/location-details-toolbar.component';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { calculateOffset } from '../../shared/geometry-helper';
import { transformRotate, transformTranslate } from '@turf/turf';
import SnapTool from '../SnapTool/SnapTool';
import { MapMouseCursor } from '../../MapAdapter/BaseMapAdapter';

export class LocationEditorOperation extends GeodataEditorOperation {
    private _snapshots: Array<{ geometry: GeoJSON.Geometry, anchor: GeoJSON.Point }> = [];
    private _position: number = 0;
    private _currentDisplayRule: DisplayRule;

    /**
     * Stores a snapshot of the Location geometry in the snapshots array.
     */
    #takeSnapshot(): void {
        if (this._position < this._snapshots.length - 1) {
            this._snapshots = this._snapshots.slice(0, this._position + 1);
        }
        this._snapshots.push({ geometry: this._location.geometry as GeoJSON.Geometry, anchor: this._location.anchor as GeoJSON.Point });
        this._position += 1;
    }

    /**
     * Changes the Location geometry.
     *
     * @param {number} index
     */
    #applySnapshotFrom(index: number): void {
        if (index >= 0 && index < this._snapshots.length) {
            this._position = index;
            const { id } = this._location;
            const { geometry, anchor } = this._snapshots[this._position];

            const geometries: [string, GeoJSON.Geometry][] = [
                [`POINT:${id}`, anchor],
                [`LABEL:${id}`, anchor],
                [`POLYGON:${id}`, geometry],
                [`MODEL3D:${id}`, anchor],
                [`MODEL2D:${id}`, anchor],
                [`HIGHLIGHT:${id}`, geometry ?? anchor],
                [`AREAASOBSTACLE:${id}`, geometry],
                [`EXTRUSION:${id}`, geometry]
            ];

            this._editorViewState.setGeometries(geometries);
            this._changesSubject.next({ geometry, anchor });
        }
    }

    /**
     * Redo changes.
     *
     * @public
     */
    public redo(): void {
        this.#applySnapshotFrom(this._position + 1);
    }

    /**
     * Undo changes.
     *
     * @public
     */
    public undo(): void {
        this.#applySnapshotFrom(this._position - 1);
    }

    #subscription: Subscription = new Subscription();
    #activeMode: EditorMode;
    #modes: EditorMode;
    private _location: ExtendedLocation;
    constructor(
        private _originalLocation: ExtendedLocation,
        protected _changesSubject: Subject<{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }>,
        protected _editorViewState: GeodataEditorViewState,
        protected _geodataEditor: GeodataEditor,
        protected onComplete: () => void
    ) {
        super(_changesSubject, _editorViewState, onComplete);
        this._location = primitiveClone(this._originalLocation);
        this._snapshots = [{ geometry: this._location.geometry as GeoJSON.Geometry, anchor: this._location.anchor as GeoJSON.Point }];

        _changesSubject.subscribe(({ geometry, anchor }) => {
            this._location.geometry = geometry as Geometry;
            this._location.anchor = anchor;
        });
        this._editorViewState.setLocation(this._location);

        this.setModes(this._location.locationType);
    }

    private _currentRotation: number = 0;

    /**
     * Set the location to be (non)editable.
     *
     * @param {boolean} editable
     */
    public changeEditableState(editable: boolean): void {
        if (!editable) {
            this.#modes = null;
            this.mode = null;
        } else {
            this.setModes(this._location.locationType);
        }
    }

    /**
     * Redraw features to refresh the styling.
     */
    public redraw(displayRule?: DisplayRule): void {
        this._currentDisplayRule = displayRule;
        this?._editorViewState.setLocation(this._location, displayRule);
    }

    /**
     * Reset all changes.
     */
    public reset(): void {
        this._location = primitiveClone(this._originalLocation);
        this._snapshots = [{ geometry: this._location.geometry as GeoJSON.Geometry, anchor: this._location.anchor as GeoJSON.Point }];
        this?._editorViewState.setLocation(this._location);

        this._changesSubject.next({ geometry: this._location.geometry as GeoJSON.Geometry, anchor: this._location.anchor as GeoJSON.Point });
    }

    /**
     * End the operation.
     */
    public complete(): void {
        if (!this._changesSubject.isStopped) {
            this._geodataEditor.showExtrusion(true);
            this._geodataEditor.disablePitch(false);
            this._changesSubject.complete();
            this.#subscription.unsubscribe();
            this?.onComplete();
        }
    }

    /**
     * Rotate location.
     *
     * @param {number} degrees
     */
    public rotate(degrees: number): void {
        const id = this._location.id;
        const rotationDelta = degrees - this._currentRotation;
        this._currentRotation = degrees;

        const polygon = this._editorViewState.getGeometry(`POLYGON:${id}`) as GeoJSON.Polygon;

        if (!polygon || !Number.isInteger(rotationDelta)) return;

        const anchor = this._editorViewState.getGeometry(`POINT:${id}`) as GeoJSON.Point;
        const rotatedPolygon = transformRotate(polygon, rotationDelta, { pivot: anchor });
        const geometries: [string, GeoJSON.Geometry][] = [
            [`POLYGON:${id}`, rotatedPolygon],
            [`EXTRUSION:${id}`, rotatedPolygon],
            [`HIGHLIGHT:${id}`, rotatedPolygon],
            [`AREAASOBSTACLE:${id}`, rotatedPolygon]
        ];

        this._editorViewState.setGeometries(geometries);
        this._editorViewState.rotate([`MODEL3D:${id}`, `MODEL2D:${id}`], rotationDelta);
        this._changesSubject.next({ geometry: rotatedPolygon, anchor: anchor });
    }

    /**
     * Toggle obstacle view model.
     *
     * @param {boolean} obstacle
     */
    public showAsObstacle(obstacle: boolean): void {
        if (!this._location.id || this._location.locationSettings) {
            this._location.locationSettings.obstacle = obstacle;
            this.redraw();
        }
    }

    /**
     * Modes supported for this operation.
     *
     * @public
     * @readonly
     * @type {EditorMode}
     */
    public get modes(): EditorMode {
        return this.#modes;
    }

    /**
     * Set editor mode.
     */
    public set mode(mode: EditorMode) {
        if (this.#activeMode !== mode) {
            this.#activeMode = mode;
            this.#subscription.unsubscribe();
            switch (mode) {
                case EditorMode.MoveAnchor:
                    this.#subscription = this.moveAnchor();
                    this.#subscription.add(this.mouseOverPointLayer());
                    this._geodataEditor.showExtrusion(true);
                    this._geodataEditor.disablePitch(false);
                    break;
                case EditorMode.Move:
                    this.#subscription = this.move();
                    this.#subscription.add(this.mouseOverLocation());
                    this._geodataEditor.showExtrusion(true);
                    this._geodataEditor.disablePitch(false);
                    break;
                case EditorMode.Edit:
                    this.#subscription = this.modify();
                    this.#subscription.add(this.mouseOverHandlesLayers());
                    this.#subscription.add(this.mouseOverMidpointsLayers());
                    this._geodataEditor.showExtrusion(false);
                    this._geodataEditor.disablePitch(true);
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Get current editor mode.
     *
     * @returns {EditorMode}
     */
    public get mode(): EditorMode {
        return this.#activeMode;
    }

    /**
     * For changing the mouse cursor when hovering the point.
     *
     * @returns {Subscription}
     */
    private mouseOverPointLayer(): Subscription {
        return this._geodataEditor.mouseOverPointLayer$(MapMouseCursor.Move).subscribe();
    }

    /**
     * For changing the mouse cursor when hovering the point or the polygon.
     *
     * @returns {Subscription}
     */
    private mouseOverLocation(): Subscription {
        return this._geodataEditor.mouseOverLocation$(MapMouseCursor.Move).subscribe();
    }

    /**
     * For changing the mouse cursor when hovering handles.
     *
     * @returns {Subscription}
     */
    private mouseOverHandlesLayers(): Subscription {
        return this._geodataEditor.mouseOverHandlesLayer$(MapMouseCursor.Pointer).subscribe();
    }

    /**
     * For changing the mouse cursor when hovering midpoints.
     *
     * @returns {Subscription}
     */
    private mouseOverMidpointsLayers(): Subscription {
        return this._geodataEditor.mouseOverMidpointsLayer$(MapMouseCursor.Pointer).subscribe();
    }

    /**
     * Set the the modes for editing the location based on the location's type.
     *
     * @param {LocationType} locationType
     */
    private setModes(locationType: LocationType): void {
        switch (locationType) {
            case LocationType.Room:
                this.#modes = EditorMode.MoveAnchor;
                this.mode = EditorMode.MoveAnchor;
                break;
            case LocationType.Area:
                this.#modes = EditorMode.Edit | EditorMode.Move | EditorMode.MoveAnchor;
                this.mode = EditorMode.Move;
                break;
            case LocationType.POI:
            default:
                this.#modes = EditorMode.Move;
                this.mode = EditorMode.Move;
                break;
        }
    }

    /**
     * Initialize the move operation.
     *
     * @private
     * @returns {Subscription}
     */
    private move(): Subscription {
        const id = this._location.id;
        return merge(this._geodataEditor.mouseDownOnPolygonLayer$, this._geodataEditor.mouseDownOnPointLayer$)
            .pipe(
                filter(() => this._location.locationType !== LocationType.Room),
                switchMap((previousPoint) =>
                    this._geodataEditor.mouseMoveUntilMouseUp$.pipe(
                        map((currentPoint) => ({ previousPoint, currentPoint })),
                        tap(({ currentPoint }) => previousPoint = currentPoint),
                        finalize(() => this.#takeSnapshot()))
                )
            ).subscribe(({ previousPoint, currentPoint }) => {
                const anchor: GeoJSON.Point = this._editorViewState.getGeometry(`POINT:${id}`) as GeoJSON.Point;
                const geometry: GeoJSON.Polygon = this._editorViewState.getGeometry(`POLYGON:${id}`) as GeoJSON.Polygon;
                const { distance, bearing } = calculateOffset(previousPoint.coordinates, currentPoint.coordinates);

                transformTranslate(anchor, distance, bearing, { mutate: true });
                if (geometry) {
                    transformTranslate(geometry, distance, bearing, { mutate: true });
                }

                const geometries: [string, GeoJSON.Geometry][] = [
                    [`POINT:${id}`, anchor],
                    [`LABEL:${id}`, anchor],
                    [`POLYGON:${id}`, geometry],
                    [`MODEL3D:${id}`, anchor],
                    [`MODEL2D:${id}`, anchor],
                    [`EXTRUSION:${id}`, geometry],
                    [`HIGHLIGHT:${id}`, geometry ?? anchor],
                    [`AREAASOBSTACLE:${id}`, geometry]
                ];

                this._editorViewState.setGeometries(geometries);
                this._changesSubject.next({ geometry: geometry ?? anchor, anchor: anchor });
            });
    }

    /**
     * Initialize the move anchor operation.
     *
     * @private
     * @returns {Subscription}
     */
    private moveAnchor(): Subscription {
        const id = this._location.id;
        return this._geodataEditor.mouseDownOnPointLayer$
            .pipe(
                switchMap(() => this._geodataEditor.mouseMoveUntilMouseUp$
                    .pipe(finalize(() => this.#takeSnapshot()))
                )
            )
            .subscribe((anchor => {
                let geometry: GeoJSON.Geometry;
                if (this._location.geometry.type === 'Polygon') {
                    geometry = this._editorViewState.getGeometry(`POLYGON:${id}`) ?? this._location.geometry as GeoJSON.Polygon;
                    anchor = SnapTool.contain(anchor, geometry as GeoJSON.Polygon);
                } else {
                    geometry = anchor;
                }

                const geometries: [string, GeoJSON.Geometry][] = [
                    [`POINT:${id}`, anchor],
                    [`LABEL:${id}`, anchor],
                    [`MODEL3D:${id}`, anchor],
                    [`MODEL2D:${id}`, anchor],
                    [`HIGHLIGHT:${id}`, geometry],
                    [`AREAASOBSTACLE:${id}`, geometry]
                ];

                this._editorViewState.setGeometries(geometries);
                this._changesSubject.next({ geometry, anchor });
            }));
    }

    /**
     * Initialize the modify operation.
     *
     * @private
     * @returns {Subscription}
     */
    private modify(): Subscription {
        const id = this._location.id;
        this._geodataEditor.showPolygonHandles(true);
        const mouseDownOnMidpointsLayer = this._geodataEditor.mouseDownOnMidpointsLayer$
            .pipe(
                tap(({ position, insertAfter }) => {
                    const polygon = this._editorViewState.getGeometry(`POLYGON:${id}`) as GeoJSON.Polygon;
                    polygon.coordinates[0].splice(insertAfter, 0, position.coordinates);
                    const geometries: [string, GeoJSON.Geometry][] = [
                        [`POLYGON:${id}`, polygon],
                        [`HIGHLIGHT:${id}`, polygon],
                        [`AREAASOBSTACLE:${id}`, polygon]
                    ];

                    this._editorViewState.setGeometries(geometries);
                }),
                switchMap(({ insertAfter: index }) =>
                    this._geodataEditor.mouseMoveUntilMouseUp$.pipe(
                        tap((position) => this.updatePolygonGeometry(this._location, index, position)),
                        finalize(() => {
                            const { geometry, anchor } = this.ensureAnchorIsInsidePolygon(this._location);
                            this._changesSubject.next({ geometry, anchor });
                            this.#takeSnapshot();
                        })
                    ))
            );

        const mouseDownOnHandlesLayer = this._geodataEditor.mouseDownOnHandlesLayer$
            .pipe(switchMap(({ index }) =>
                this._geodataEditor.mouseMoveUntilMouseUp$.pipe(
                    tap((position) => this.updatePolygonGeometry(this._location, index, position)),
                    finalize(() => {
                        const { geometry, anchor } = this.ensureAnchorIsInsidePolygon(this._location);
                        this._changesSubject.next({ geometry, anchor });
                        this.#takeSnapshot();
                    })
                )
            ));

        const mouseContextmenuOnHandlesLayer = this._geodataEditor.mouseContextmenuOnHandlesLayer$
            .pipe(tap(({ index }) => {
                const geometry = this._editorViewState.getGeometry(`POLYGON:${id}`) as GeoJSON.Polygon;
                if (geometry.coordinates[0].length > 4) {
                    geometry.coordinates[0].splice(index, 1);

                    // The first and the last point in a polygon is the same, so if the index is 0 we also need to update the last point in the polygon.
                    if (index === 0) {
                        geometry.coordinates[0].splice(-1, 1, geometry.coordinates[0][0]);
                    }

                    const geometries: [string, GeoJSON.Geometry][] = [
                        [`POLYGON:${id}`, geometry],
                        [`HIGHLIGHT:${id}`, geometry],
                        [`AREAASOBSTACLE:${id}`, geometry]
                    ];

                    this._editorViewState.setGeometries(geometries);
                    this._changesSubject.next(this.ensureAnchorIsInsidePolygon(this._location));
                    this.#takeSnapshot();
                }
            }));

        this.redraw(this._currentDisplayRule);

        return merge(mouseDownOnHandlesLayer, mouseDownOnMidpointsLayer, mouseContextmenuOnHandlesLayer)
            .pipe(
                finalize(() => {
                    this._geodataEditor.showPolygonHandles(false);
                })
            ).subscribe();
    }

    /**
     * Updates the position of the vertex at the given index.
     *
     * @private
     * @param {Location} location
     * @param {number} index
     * @param {GeoJSON.Point} position
     * @memberof GeodataEditor
     */
    private updatePolygonGeometry(location: Location, index: number, position: GeoJSON.Point): void {
        const polygon: GeoJSON.Polygon = this._editorViewState.getGeometry(`POLYGON:${location.id}`) as GeoJSON.Polygon;
        polygon.coordinates[0][index] = position.coordinates;

        // The first and the last point in a polygon is the same, so if the index is 0 we also need to update the last point in the polygon.
        if (index === 0) {
            polygon.coordinates[0].splice(-1, 1, position.coordinates);
        }

        const geometries: [string, GeoJSON.Geometry][] = [
            [`POLYGON:${location.id}`, polygon],
            [`EXTRUSION:${location.id}`, polygon],
            [`HIGHLIGHT:${location.id}`, polygon],
            [`AREAASOBSTACLE:${location.id}`, polygon]
        ];

        this._editorViewState.setGeometries(geometries);
    }

    /**
     * Ensures the anchor is placed inside the polygon.
     *
     * @private
     * @param {Location} location
     * @returns {{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }}
     * @memberof GeodataEditor
     */
    private ensureAnchorIsInsidePolygon(location: Location): { geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point } {
        const polygon = this._editorViewState.getGeometry(`POLYGON:${location.id}`) as GeoJSON.Polygon;
        let anchor: GeoJSON.Point = this._editorViewState.getGeometry(`POINT:${location.id}`) as GeoJSON.Point;
        anchor = SnapTool.ensureAnchorIsInsidePolygon(anchor, polygon);

        this._editorViewState.setGeometries([
            [`POINT:${location.id}`, anchor],
            [`LABEL:${location.id}`, anchor],
            [`MODEL3D:${location.id}`, anchor],
            [`MODEL2D:${location.id}`, anchor]
        ]);

        return { geometry: polygon ?? anchor, anchor };
    }
}
