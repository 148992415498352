import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * ZoomTo validator.
 *
 * @exports
 * @param {string} zoomFromControlPath - Relative path to the linked zoomFrom FormControl.
 * @param {number} maxZoomLevel - The maximum allowd zoom level.
 * @returns {ValidatorFn}
 */
export function zoomToValidator(zoomFromControlPath: string): ValidatorFn {
    return (zoomToControl: AbstractControl): ValidationErrors | null => {
        const zoomFromControl = zoomToControl?.parent?.get(zoomFromControlPath);
        const zoomFromValue = zoomFromControl?.value;
        const zoomToValue = zoomToControl?.value;
        if (zoomToValue === 999) {
            const errors = { ...zoomFromControl?.errors };
            delete errors?.aboveThreshold;
            zoomFromControl?.setErrors(Object.keys(errors).length > 0 ? errors : null);
            return null;
        } else if (zoomToValue !== null && zoomToValue <= zoomFromValue) {
            zoomFromControl?.setErrors({ ...zoomFromControl?.errors, aboveThreshold: true });
            return { belowThreshold: true };
        } else {
            const errors = { ...zoomFromControl?.errors };
            delete errors?.aboveThreshold;
            zoomFromControl?.setErrors(Object.keys(errors).length > 0 ? errors : null);
            return null;
        }
    };
}

/**
 * ZoomFrom validator.
 *
 * @exports
 * @param {string} zoomToControlPath - Relative path to the linked zoomTo FormControl.
 * @param {number} maxZoomLevel - The maximum allowd zoom level.
 * @returns {ValidatorFn}
 */
export function zoomFromValidator(zoomToControlPath: string): ValidatorFn {
    return (zoomFromControl: AbstractControl): ValidationErrors | null => {
        const zoomFromValue = zoomFromControl?.value;
        const zoomToControl = zoomFromControl?.parent?.get(zoomToControlPath);
        const zoomToValue = zoomToControl?.value;

        if (zoomFromValue !== null && zoomFromValue >= zoomToValue && zoomToValue !== 999) {
            zoomToControl?.setErrors({ ...zoomToControl?.errors, belowThreshold: true });
            return { aboveThreshold: true };
        } else {
            const errors = { ...zoomToControl?.errors };
            delete errors?.belowThreshold;
            zoomToControl?.setErrors(Object.keys(errors).length > 0 ? errors : null);
            return null;
        }
    };
}