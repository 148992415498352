<div mat-dialog-title>
    What's new
    <div fxFlex></div>
    <mat-icon mat-dialog-close class="close-icon">close</mat-icon>
</div>
<mat-divider class="top-divider"></mat-divider>

<div mat-dialog-content fxLayout="column">
    <div>
        <h1>MapsIndoors News Q4 2023</h1>
        <p>Are you up to date about the latest MapsIndoors releases? We’re excited to bring you the most recent improvements from the world of MapsIndoors.

            This round of MapsIndoors News includes:
        </p>

        <ul>
            <li>New and improved developer documentation to make the implementation of new features even easier.</li>
            <li>Introduction of three new states of map interaction for a more user-friendly experience.</li>
            <li>Performance improvements for faster response times worldwide.</li>
        </ul>

        <p>
            Get all the details and a sneak-peek into what’s getting ready for release soon in the full MapsIndoors News.
        </p>
        <p>
            <b><a
                   href="https://www.mapspeople.com/resources/product-news/q4"
                   target="_blank">Take me to MapsIndoors News</a>
            </b>
        </p>
    </div>
</div>