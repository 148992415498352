import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotificationService } from '../services/notification.service';
import { MediaLibraryService } from './media-library.service';
import { IMediaItem } from './media-item/media-item.model';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { getNameFromUrl } from './media-library.shared';
import { MediaCategory, MediaFileDimension, MediaFileType, MediaLibrarySource, MediaSortBy } from './media.enum';
import { MediaFilterOptions } from './media-filter-bar/media-filter-options.model';
import { filterMediaItems } from '../operators/filterMediaItems';
import { switchMap, tap } from 'rxjs/operators';
import { isNullOrUndefined } from '../../utilities/Object';

@Component({
    selector: 'media-library',
    templateUrl: './media-library.component.html',
    styleUrls: ['./media-library.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MediaLibraryComponent implements OnInit {
    public allMediaItems: IMediaItem[];
    public filteredMediaItems: IMediaItem[] = [];
    public source: MediaLibrarySource;
    public usedItemUrl: string;
    public usedItem: IMediaItem;
    public showFooter: boolean = false;
    public disableSelect: boolean = false;
    public isUploadEnabled: boolean = false;
    public defaultFilter: MediaFilterOptions;
    public showWarningMessage: boolean = false;
    public showWarningToAccept: boolean = false;
    public warningMessage: string;
    public maxNumberOfMediaItemsPerPage: number = 100;
    public currentPage: number = 1;
    public isComponentShown: boolean = false;

    private usedItemName: string;
    private selectedMedia: IMediaItem;
    private isSearchQueryEmpty: boolean;

    constructor(
        private dialogRef: MatDialogRef<MediaLibraryComponent>,
        private mediaLibraryService: MediaLibraryService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.source = this.data?.source;
        this.usedItemUrl = this.data?.url;

        switch (this.source) {
            case MediaLibrarySource.Model3D:
                this.defaultFilter = { sortBy: [MediaSortBy.Recent], fileTypes: [MediaFileType.GLB] };
                break;
            case MediaLibrarySource.Model2D:
                this.defaultFilter = { sortBy: [MediaSortBy.Recent], fileTypes: [MediaFileType.JPG, MediaFileType.PNG, MediaFileType.SVG] };
                break;
            case MediaLibrarySource.Icon:
                this.defaultFilter = { sortBy: [MediaSortBy.Recent], fileTypes: [MediaFileType.SVG, MediaFileType.PNG] };
                break;
            case MediaLibrarySource.GraphicLabel:
                this.defaultFilter = { sortBy: [MediaSortBy.Recent], mediaCategory: [MediaCategory.MILabel], fileTypes: [MediaFileType.PNG], fileDimensions: [MediaFileDimension.Height, MediaFileDimension.Width] };
                this.isComponentShown = true;
                break;
            default:
                this.defaultFilter = { sortBy: [MediaSortBy.Recent] };
                break;
        }
    }

    /**
     * Requests the list of media items from the backend and sets the currently used item.
     * When the used selects a new media item, it updates the selected media item property and checks if the parameters of that media item is acceptable.
     */
    ngOnInit(): void {
        this.dialogRef.keydownEvents().subscribe(event => this.onEscapeHandler(event));

        this.mediaLibraryService.activeFilters$
            .pipe(
                tap(filter => this.isSearchQueryEmpty = filter.searchQuery ? false : true),
                switchMap(filter => this.mediaLibraryService.mediaItems$.pipe(filterMediaItems(filter)))
            )
            .subscribe((filteredMediaItems) => {
                this.filteredMediaItems = filteredMediaItems;
            });

        this.mediaLibraryService.isServiceBusy$
            .subscribe(state => {
                this.isUploadEnabled = state;
                if (this.isUploadEnabled) {
                    this.spinner.show('media-spinner');
                } else {
                    this.spinner.hide('media-spinner');
                }
            });

        this.mediaLibraryService.getMediaItems()
            .subscribe(
                (mediaItems) => {
                    this.allMediaItems = mediaItems;

                    // Some urls have an appended set of random numbers to them which needs to be removed from the url.
                    if (this.usedItemUrl) {
                        const url = new URL(this.usedItemUrl);
                        url.search = '';
                        this.usedItemUrl = url?.toString();
                        this.usedItemName = decodeURIComponent(getNameFromUrl(this.usedItemUrl));
                        this.usedItem = this.findUsedItem();

                        if (!this.usedItem) {
                            this.notificationService.showInfo('Something went wrong. No matching media was found.');
                        }
                    }
                },
                () => {
                    this.notificationService.showError('Something went wrong. Please try again.');
                }
            );

        this.mediaLibraryService.setActiveFilter(this.defaultFilter);
    }

    /**
     * Returns the media item from the media item's list.
     * If the url refers to the item being a MapsIndoors icon, it checks for the url.
     * If the url refers to the item being an uploaded media, it checks for the name.
     *
     * @returns {IMediaItem}
     */
    private findUsedItem(): IMediaItem {
        if (!this.usedItemUrl) return;

        if (this.usedItemUrl?.startsWith(environment.iconsBaseUrl)) {
            return this.allMediaItems.find(item => `${item.preview}` === this.usedItemUrl);
        } else {
            return this.allMediaItems.find(item => `${item.name}` === this.usedItemName);
        }
    }

    /**
     * Shows the accept input next to the warning message.
     */
    private displayWarningAcceptInput(): void {
        const input = document.getElementById('checkWarning') as HTMLInputElement;
        if (input) input.checked = false;
        this.showWarningToAccept = true;
    }

    /**
     * Shows a warning message.
     *
     * @param {string} message
     */
    private dispalyWarningMessage(message: string): void {
        this.warningMessage = message;
        this.showWarningMessage = true;
    }

    /**
     * Setting the selected media and taking care of the sate of the footer.
     *
     * @param {IMediaItem} media
     */
    public setSelectedMedia(media: IMediaItem): void {
        if (!isNullOrUndefined(media) && media === this.usedItem) {
            return;
        }

        if (!media && this.usedItemUrl) {
            this.showFooter = true;
            this.disableSelect = true;
            this.showWarningMessage = false;
            this.showWarningToAccept = false;
            return;
        }

        if (media?.category === MediaCategory.MIIcon || this.usedItemUrl?.startsWith(environment.iconsBaseUrl)) {
            this.showFooter = this.usedItemUrl === media?.preview ? false : true;
        } else {
            this.showFooter = this.usedItemName === media?.name ? false : true;
        }

        // Users are not allowed to set other than files with png/jpg/jpeg/svg type for a 2D model/Icon
        if ((this.source === MediaLibrarySource.Icon || this.source === MediaLibrarySource.Model2D) && media?.type === MediaFileType.GLB) {
            this.dispalyWarningMessage('Please select a file of the file type .svg, .png, or .jpg/jpeg.');
            this.disableSelect = true;
            return;
        }

        // Users are not allowed to set other than files with glb/gltf type for a 3D model
        if (this.source === MediaLibrarySource.Model3D && media?.type !== MediaFileType.GLB) {
            this.dispalyWarningMessage('Please select a file of the file type .glb.');
            this.disableSelect = true;
            return;
        }

        // Users are not allowed to set other media items than media items that are 400x400px and are PNG type.
        if (this.source === MediaLibrarySource.GraphicLabel && media?.type !== MediaFileType.PNG) {
            this.dispalyWarningMessage('Please select a Graphic Labels that is 400x400.');
            this.disableSelect = true;
            return;
        }

        this.disableSelect = false;
        this.showWarningMessage = false;
        this.showWarningToAccept = false;

        this.selectedMedia = media;

        if (this.source === MediaLibrarySource.Icon && (media?.sizeBytes > 150000 || media?.width > 128 || media?.height > 64)) {
            this.dispalyWarningMessage('The selected file is large, which will affect performance. Do you wish to proceed?');
            this.disableSelect = true;
            this.displayWarningAcceptInput();
            return;
        }
    }

    /**
     * Sets the filtered media items.
     *
     * @param {IMediaItem[]} mediaItems
     */
    public onFilteredMediasChange(mediaItems: IMediaItem[]): void {
        this.filteredMediaItems = mediaItems;
    }

    /**
     * When the user intercats with the warning message, enable/disable the Select button.
     *
     * @param {Event} event
     */
    public onWarningAccepted(event: Event): void {
        this.disableSelect = !(event.target as HTMLInputElement).checked;
    }

    /**
     * Closing Media Library with Select button and passing selected item to Display Rules.
     */
    public onSelect(): void {
        this.dialogRef.close({ selectedMedia: this.selectedMedia });
    }

    /**
     * Closes the modal box.
     */
    public closeModal(): void {
        this.dialogRef.close();
    }

    /**
     * Checks status of Media Library input search field. Based on its status we can clear it or get ask if we wanna save changes.
     */
    public onEscapeHandler(event: KeyboardEvent): void {
        this.dialogRef.disableClose = true;

        if (event.key === 'Escape' && this.isSearchQueryEmpty) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.onDiscardChanges();
        }
    }

    /**
     * Closing Media Library when user discards changes.
     */
    public onDiscardChanges(): void {
        if (this.showFooter) {
            // eslint-disable-next-line no-alert
            const dialogResponse = confirm('Are you sure you want to close the Media Library without saving your changes?');
            if (!dialogResponse) return;
        }
        this.dialogRef.close();
    }

    /**
     * Sets the current page number that will load new medias.
     *
     * @param {number} page
     */
    public loadNewMedias(page: number): void {
        this.currentPage = page;
    }
}