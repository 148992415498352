import { Anchor, DisplayRule, LatLng, Location, PathData, StreetViewConfig } from '../location.model';
import { Translation, CustomProperty } from '../../shared/interfaces/translation.model';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ExtendedLocation, LocationService } from '../location.service';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { ViewState, ViewStateService } from '../../map/view-state.service';
import { concatMap, debounceTime, distinctUntilChanged, filter, finalize, map, tap } from 'rxjs/operators';
import { maximumDate, minimumDate } from '../../shared/directives/date-interval.directive';
import { mergeObjects, primitiveClone } from '../../shared/object-helper';
import { AppUserRole } from '../../app-settings/config/app-user-roles/app-user-role.model';
import { AppUserRolesService } from '../../app-settings/config/app-user-roles/app-user-roles.service';
import { Building, OUTDOOR_BUILDING } from '../../buildings/building.model';
import { BuildingService } from '../../buildings/building.service';
import { Customer } from '../../customers/customer.model';
import { CustomerService } from '../../customers/customer.service';
import { DisplayRuleDetailsComponent } from '../../display-rule-details/display-rule-details.component';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { DrawingService } from '../../services/drawing.service';
import { Floor } from '../../buildings/floor.model';
import { GraphSetup } from '../../shared/enums/GraphSetup.enum';
import { LocationsMapService } from '../../map/locations-map/locations-map.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';
import { RadioGroupText } from '../../shared/user-roles-accessibility/user-roles-accessibility.component';
import { GeoJSONGeometryType, RegexPatterns } from '../../shared/enums';
import { Solution } from '../../solutions/solution.model';
import { UserService } from '../../services/user.service';
import { Venue } from '../../venues/venue.model';
import { VenueService } from '../../venues/venue.service';
import { createDropdownItemElement } from '../../shared/mi-dropdown/mi-dropdown';
import { formatToShortDate } from '../../shared/datetime-helper';
import { getGeometryFromGooglePolygon } from '../../shared/geometry-helper';
import isEqual from 'fast-deep-equal';

@Component({
    selector: 'app-location-details',
    templateUrl: './location-details.component.html',
    styleUrls: ['./location-details.component.scss'],
})
export class LocationDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('buildingsDropdown', { static: true }) buildingsDropdownElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('floorsDropdown', { static: true }) floorsDropdownElement: ElementRef<HTMLMiDropdownElement>;

    @Output() deleteLocation = new EventEmitter<void>();
    @Output() openSplitLocation = new EventEmitter<void>();
    @Output() startAddDoorFlow = new EventEmitter<Location>();
    @Output() startAddMultipleDoorsFlow = new EventEmitter<Location>();
    @Output() openCombineLocations = new EventEmitter<void>();

    @Input() currentSolution: Solution;
    appUserRoles: AppUserRole[] = [];

    private currentVenue: Venue;

    /**
     * Set the input location to be viewed.
     *
     * @memberof LocationDetailsComponent
     */
    @Input() set location(location: ExtendedLocation) {
        if (location) {
            this._location = JSON.parse(JSON.stringify(location));
            this.originalAnchor = { ...this._location.anchor };

            this.outsideBuilding.floors[0].floorIndex = this._location.pathData.floor;
            this.outsideBuilding.floors[0].displayName = this._location.pathData.floor.toString();
            this.buildings = [this.outsideBuilding, ...this.buildingService.getBuildingsFromStore()];
            this.setBuildingsDropdownItems(this.buildings, this._location.pathData);
            this.typeName = location?.type;
            this.setLocationFormValues(this._location);
        }
    }

    /**
     * Gets location.
     *
     * @readonly
     * @type {ExtendedLocation}
     * @memberof LocationDetailsComponent
     */
    get location(): ExtendedLocation {
        return this._location;
    }

    /**
     * Determines if the location can be deleted.
     *
     * @readonly
     * @type {boolean}
     * @memberof LocationDetailsComponent
     */
    get isLocationDeletable(): boolean {
        const locationHasId = this._location.id > '';
        const locationTypeIsNotRoom = this._location?.locationType?.toLowerCase() !== 'room';
        return locationHasId && locationTypeIsNotRoom;
    }

    /**
     * Determines if the Location can be split and combined.
     *
     * @readonly
     * @type {boolean}
     * @memberof LocationDetailsComponent
     */
    get isLocationSplitCombinable(): boolean {
        return this.isSplitCombineEnabled === true
            && this._location.locationType.toLowerCase() === 'room'
            && this._location.type.toLowerCase() !== 'corridor';
    }

    /**
     * Determines if the Location can be duplicated.
     *
     * @readonly
     * @type {boolean}
     * @memberof LocationDetailsComponent
     */
    get isLocationDuplicatable(): boolean {
        return this._location.id && this._location.locationType !== 'room';
    }

    /**
     * Determines whether the add-door button should be shown.
     *
     * @readonly
     * @type {boolean}
     * @memberof LocationDetailsComponent
     */
    get isAddDoorVisible(): boolean {
        return this.isAdmin && this._location.locationType.toLowerCase() === 'room' && this.currentVenue?.graphSetup === GraphSetup.Automatic;
    }

    private createdLocationSubject$ = new Subject<Location>();
    @Output() createdlocation$ = this.createdLocationSubject$.asObservable();
    public currentAreaAngleControl = new UntypedFormControl('', [Validators.min(0), Validators.max(359)]);
    @Output() changeAngle$ = this.currentAreaAngleControl.valueChanges
        .pipe(
            filter(angle => !!angle),
            filter(() => this.currentAreaAngleControl.valid),
            filter(() => this._location?.locationType.toLowerCase() === 'area'),
            distinctUntilChanged()
        );

    private subscriptions = new Subscription();
    private _location: ExtendedLocation = null;
    private outsideBuilding: Building = {
        ...OUTDOOR_BUILDING,
        floors: [{
            floorIndex: 0,
            geometry: null,
            pathData: null,
            floorInfo: null,
            solutionId: null,
            id: null,
            displayName: '0'
        }]
    };

    private originalAnchor: Anchor;
    private originalFormState;
    private saveLocationHandler;

    public readonly MIN_DATE = '1999-01-01';
    public readonly MAX_DATE = '2099-12-31';
    public buildings: Building[] = [];
    public currentLocationIconSizeOnMap = '';
    public radioGroupText: RadioGroupText = {
        all: 'Open for all',
        some: 'Open for specific App User Roles',
        none: 'Closed for all'
    };
    public isSplitCombineEnabled = false;
    public isAdmin = false;
    public isOwner = false;
    public locationLog = [];
    public isLocationLogLoading = false;
    public locationForm = this.formBuilder.group({
        activeFrom: ['', [minimumDate(this.MIN_DATE), maximumDate(this.MAX_DATE)]],
        activeTo: ['', [minimumDate(this.MIN_DATE), maximumDate(this.MAX_DATE)]],
        aliases: [[]],
        anchor: [],
        categories: [[]],
        customProperties: [],
        externalId: [''],
        imageURL: [],
        pathData: this.formBuilder.group({
            building: [],
            floor: []
        }),
        restrictions: [[]],
        status: [1],
        streetViewConfig: this.formBuilder.group({
            panoramaId: [],
            povHeading: [],
            povPitch: []
        }),
        type: ['', [Validators.required]],
        translations: [],
        angle: [
            { value: 0, disabled: true },
            [Validators.min(0), Validators.max(359), Validators.pattern(RegexPatterns.NumericalNoDecimals)]
        ],

    });

    public discardChangesSubject: Subject<any> = new Subject<any>();
    public typeName: string;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private viewStateService: ViewStateService,
        private userService: UserService,
        private locationService: LocationService,
        private locationsMapService: LocationsMapService,
        private spinner: NgxSpinnerService,
        private notificationService: NotificationService,
        private customerService: CustomerService,
        private drawingService: DrawingService,
        private buildingService: BuildingService,
        private matDialog: MatDialog,
        private displayRuleService: DisplayRuleService,
        private appUserRolesService: AppUserRolesService,
        private venueService: VenueService
    ) { }

    /**
     * OnInit.
     */
    ngOnInit(): void {
        const customer = this.customerService.getCurrentCustomer(true) as Customer;
        this.isSplitCombineEnabled = customer?.modules?.includes('splitandcombine');

        this.isAdmin = this.userService.hasAdminPrivileges();
        this.isOwner = this.userService.hasOwnerPrivileges();

        const appUserRolesSubscription = this.appUserRolesService.appUserRoles$.subscribe(roles => this.appUserRoles = roles);

        this.subscriptions
            .add(this.locationForm.get('type').valueChanges.subscribe(value => {
                this.typeName = value;
            }))
            .add(this.locationForm.valueChanges
                .pipe(debounceTime(300))
                .subscribe(formState => {
                    // compare the original state and the current state to see if they are the same.
                    !isEqual(this.originalFormState, formState)
                        ? this.locationForm.markAsDirty()
                        : this.locationForm.markAsPristine();
                    // notify observers of the location details form's status
                    this.locationService.isLocationDetailsFormDirty$.next(this.locationForm.dirty);
                }))
            .add(this.locationForm.get('angle').valueChanges
                .pipe(filter(() => this.locationForm.get('angle').valid))
                .subscribe(angle => {
                    this.locationsMapService.rotateArea(this._location, angle);
                }))

            // subscribe to marker drag
            .add(this.locationsMapService.markerDrag$.subscribe(latLng => this.onMarkerDrag(latLng)))
            // subscribe to polygon dragend
            .add(this.locationsMapService.polygonDragEnd$.subscribe(polygon => this.onPolygonDragEnd(polygon)))
            .add(this.venueService.getSelectedVenue()
                .subscribe(venue => this.currentVenue = venue))
            .add(appUserRolesSubscription);

        this.saveLocationHandler = {
            next: () => {
                this.locationService.selectLocation(null);
                this.viewStateService.setViewStateObservable(ViewState.Default);
            },
            error: error => this.notificationService.showError(error)
        };
    }

    /**
     * OnDestroy.
     */
    ngOnDestroy(): void {
        this.locationService.isLocationDetailsFormDirty$.next(false);
        this.subscriptions.unsubscribe();
    }

    /**
     * Set the location form's values.
     *
     * @private
     * @param {ExtendedLocation} location
     * @memberof LocationDetailsComponent
     */
    private setLocationFormValues(location: ExtendedLocation): void {
        const customProperties = location.translations.map(({ language, fields }) => ({ language, fields }));

        this.locationForm.patchValue({
            activeFrom: formatToShortDate(location?.activeFrom),
            activeTo: formatToShortDate(location?.activeTo),
            aliases: location?.aliases ?? [],
            anchor: location?.anchor,
            imageURL: location?.imageURL ?? null,
            categories: location?.categories,
            externalId: location?.externalId || '',
            pathData: {
                building: location?.pathData?.building,
                floor: location?.pathData?.floor
            },
            restrictions: location?.restrictions || null,
            status: location?.status,
            streetViewConfig: location?.streetViewConfig ? location.streetViewConfig : {
                panoramaId: null,
                povHeading: null,
                povPitch: null
            },
            type: location?.type,
            translations: location?.translations,
            customProperties: customProperties
        });

        if (this._location.locationType.toLowerCase() === 'area') {
            this.locationForm.get('angle').enable();
        } else {
            this.locationForm.get('angle').disable();
        }

        this.originalFormState = primitiveClone(this.locationForm.value);
    }

    /**
     * Initializes the buildings dropdown with items.
     *
     * @private
     * @param {Building[]} buildings
     * @param {PathData} locationPathData
     * @memberof LocationDetailsComponent
     */
    private setBuildingsDropdownItems(buildings: Building[], locationPathData: PathData): void {
        this.buildingsDropdownElement.nativeElement.items = buildings?.map(building => {
            const selected = building.administrativeId.toLowerCase() === locationPathData?.building?.toLowerCase();
            const miDropdownItemElement = createDropdownItemElement({ label: building.displayName, value: building.administrativeId, selected });
            selected && this.setFloorsDropdownItems(building.floors, locationPathData?.floor);
            return miDropdownItemElement;
        });
    }

    /**
     * Initializes the floors dropdown with items.
     *
     * @private
     * @param {Floor[]} floors
     * @param {number} floorIndex
     * @memberof LocationDetailsComponent
     */
    private setFloorsDropdownItems(floors: Floor[], floorIndex: number): void {
        this.floorsDropdownElement.nativeElement.items = floors?.sort((a, b) => a.floorIndex < b.floorIndex ? 1 : -1)
            .map(floor => createDropdownItemElement({ label: floor.displayName, value: floor.floorIndex.toString(), selected: floor.floorIndex === floorIndex }));
    }

    /**
     * On change handler that sets the value of the imageURL formcontrol.
     *
     * @param {string} imageUrl
     * @memberof LocationDetailsComponent
     */
    public onImageUrlChange(imageUrl: string): void {
        const imageUrlControl = this.locationForm.get('imageURL');
        imageUrlControl?.setValue(imageUrl);
    }

    /**
     * On change handler that updates the value of streetViewConfig formcontrol.
     *
     * @param {*} panorama
     * @memberof LocationDetailsComponent
     */
    public onUpdatePanorama(panorama: StreetViewConfig): void {
        const streetViewConfigControl = this.locationForm.get('streetViewConfig');
        streetViewConfigControl?.markAsDirty();
        streetViewConfigControl?.setValue(panorama || {
            panoramaId: null,
            povHeading: null,
            povPitch: null
        });
    }

    /**
     * On change handler that sets the value of the aliases formcontrol.
     *
     * @param {string[]} aliases
     * @memberof LocationDetailsComponent
     */
    public onAliasesListChange(aliases: string[]): void {
        if (aliases) {
            const aliasesControl = this.locationForm.get('aliases');
            aliasesControl?.markAsDirty();
            aliasesControl?.setValue(aliases);
        }
    }

    /**
     * On change handler that sets the value ot the building formcontrol.
     *
     * @param {CustomEvent} detail
     * @memberof LocationDetailsComponent
     */
    public onBuildingsDropdownChange({ detail }: CustomEvent): void {
        const administrativeId = (detail as HTMLMiDropdownItemElement[])?.map(item => item.value).toString();
        const buildingControl = this.locationForm.get('pathData.building');
        buildingControl?.markAsDirty();
        buildingControl?.setValue(administrativeId);

        const building = this.buildings.find(building => building.administrativeId.toLowerCase() === administrativeId.toLowerCase());
        this.setFloorsDropdownItems(building?.floors, this._location?.pathData?.floor);
    }

    /**
     * On change handler that sets the value of the floor formcontrol.
     *
     * @param {CustomEvent} detail
     * @memberof LocationDetailsComponent
     */
    public onFloorsDropdownChange({ detail }: CustomEvent): void {
        const floorIndex = (detail as HTMLMiDropdownItemElement[])?.map(item => item.value).toString();
        const floorControl = this.locationForm.get('pathData.floor');
        floorControl?.markAsDirty();
        floorControl?.setValue(+ floorIndex);
    }

    /**
     * On submit form handler that formats location properties before sending it over the wire.
     *
     * @memberof LocationDetailsComponent
     */
    public onSubmitForm(): void {
        if (!this.locationForm.valid) {
            return;
        }

        const translations = this.locationForm.value.translations as Translation[];
        const customProperties = this.locationForm.value.customProperties as [{ language: string, fields: { [key: string]: CustomProperty } }];

        for (const translation of translations) {
            const language = translation.language;
            translation.fields = customProperties?.find(fields => fields.language === language)?.fields;
        }

        let location = mergeObjects(this._location, this.locationForm.value) as ExtendedLocation;
        location = this.formatBeforeSave(location);

        this.spinner.show();

        if (location.id) {
            this.updateLocation(location).subscribe(this.saveLocationHandler);
            return;
        }

        this.createLocation(location).subscribe(this.saveLocationHandler);
    }

    /**
     * Resets the form and set the values.
     *
     * @memberof LocationDetailsComponent
     */
    public onDiscard(): void {
        const originalFormValue = primitiveClone(this.originalFormState);
        this.locationForm.reset(originalFormValue);
        this.discardChangesSubject.next(originalFormValue);

        // reset the Aliases control
        this._location.aliases = originalFormValue.aliases;

        // reset the Restrictions control
        this._location.restrictions = originalFormValue.restrictions;

        // reset Buildings & Floors dropdown control
        this.setBuildingsDropdownItems(this.buildings, this._location.pathData);

        // reset Custom Properties
        this._location.translations = originalFormValue.translations;

        // reset the location on the map
        if (this._location?.id) {
            const hasMoved = this._location.anchor?.coordinates[0] !== this.originalAnchor.coordinates[0]
                || this._location.anchor?.coordinates[1] !== this.originalAnchor.coordinates[1];
            this._location.anchor = this.originalAnchor;
            hasMoved && this.locationsMapService.resetCurrentLocation();
        }
    }

    /**
     * Close location details.
     */
    public close(): void {
        if (this.locationService.isLocationDetailsFormClosable === false) {
            return;
        }

        if (this.alertForUnsavedChanges()) {
            return;
        }

        this.locationService.isLocationDetailsFormDirty$.next(false);

        // Only reset if it is not a newly created area.
        if (this._location?.id) {
            this.locationsMapService.resetCurrentLocation();
        }
        this.viewStateService.setViewStateObservable(ViewState.Default);
        this.locationService.selectLocation(null);
    }

    /**
     * Format the Location's properties before saving it.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {ExtendedLocation}
     * @memberof LocationsComponent
     */
    private formatBeforeSave(location: ExtendedLocation): ExtendedLocation {
        // Format activeFrom and activeTo dates back to ISO format with Date.toISOString();
        if (location?.activeFrom > '') {
            location.activeFrom = new Date(location.activeFrom)?.toISOString() || location.activeFrom;
        }

        if (location?.activeTo > '') {
            location.activeTo = new Date(location.activeTo)?.toISOString() || location.activeTo;
        }

        // Nullify the streetViewConfig if no panoramaId is set.
        if (!location.streetViewConfig?.panoramaId) {
            location.streetViewConfig = null;
        }

        // The backend expects the deprecated path string to be null
        location.path = null;

        // update geometry
        if (['Polygon', 'MultiPolygon'].includes(location?.geometry?.type)) {
            const polygon = this.drawingService.getSelectedShapeValue();
            if (polygon) {
                location.geometry = getGeometryFromGooglePolygon(polygon);
            }
        }

        return location;
    }

    /**
     * On marker drag handler that updates the Location's coordinates when it is drag around the map.
     *
     * @private
     * @param {LatLng} coordinates
     * @memberof LocationDetailsComponent
     */
    private onMarkerDrag(coordinates: LatLng): void {
        this._location.anchor.coordinates = [coordinates.lng, coordinates.lat];

        if (this._location.locationType.toLowerCase() === 'poi') {
            this._location.geometry.coordinates = [coordinates.lng, coordinates.lat];
            const building = this.locationService.getLocationBuilding(this._location, this.buildings) || this.outsideBuilding;
            this._location.pathData.building = building.administrativeId;
            this._location.buildingName = building.displayName;
            this._location.floor = building.floors.find(floor => floor.floorIndex === this._location.pathData.floor);
        }

        this.updateBuildingControlValue();
    }

    /**
     * Updates an area's coordinates when the user is done dragging it around the map.
     *
     * @private
     * @param {*} polygon - { center, geometry, buildingAdministrativeId }.
     * @memberof LocationDetailsComponent
     */
    private onPolygonDragEnd({ center, geometry, buildingAdministrativeId }): void {
        this._location.anchor.coordinates = [center.lng, center.lat];
        this._location.geometry = geometry;
        this._location.pathData.building = buildingAdministrativeId || this.outsideBuilding.administrativeId;

        this.updateBuildingControlValue();
    }

    /**
     * Updates the building formcontrol value.
     *
     * @private
     * @memberof LocationDetailsComponent
     */
    private updateBuildingControlValue(): void {
        this.setBuildingsDropdownItems(this.buildings, this._location.pathData);
        const adminId = this.buildingsDropdownElement.nativeElement.selected[0]?.value;
        const buildingControl = this.locationForm.get('pathData.building');
        buildingControl?.setValue(adminId);
    }

    /**
     * Creates a location.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {Observable<void>}
     * @memberof LocationDetailsComponent
     */
    private createLocation(location: ExtendedLocation): Observable<void> {
        return this.locationService.createLocation(location)
            .pipe(
                finalize(() => this.spinner.hide()),
                map(createdLocation => {
                    this.createdLocationSubject$.next(createdLocation);
                    this.notificationService.showSuccess('Location created successfully!');
                    return;
                })
            );
    }

    /**
     * Updates the location.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {Observable<void>}
     * @memberof LocationDetailsComponent
     */
    private updateLocation(location: ExtendedLocation): Observable<void> {
        return this.locationService.updateLocation(location)
            .pipe(
                finalize(() => this.spinner.hide()),
                map(() => this.notificationService.showSuccess('Location updated successfully!'))
            );
    }

    /**
     * React on keyboard presses.
     *
     * @param {KeyboardEvent} event
     * @memberof LocationDetailsComponent
     */
    public handleHotkeys(event: KeyboardEvent): void {
        if (
            ['input', 'textarea', 'mi-dropdown'].includes((event.target as HTMLElement).tagName.toLowerCase()) === false
            && event.key.toLowerCase() === 'd'
            && this.location.locationType.toLocaleLowerCase() === 'room'
            && this.isOwner
        ) {
            this.startAddMultipleDoorsFlow.emit(this.location);
        }

        if (event.key === 'Escape') {
            this.close();
        }
    }

    /**
     * Show a info notification.
     *
     * @param {string} message
     * @memberof LocationDetailsComponent
     */
    public showInfoNotification(message: string): void {
        this.notificationService.showInfo(message, false);
    }

    /**
     * Starts the add door flow.
     *
     * @param {ExtendedLocation} location
     * @returns {void}
     * @memberof LocationDetailsComponent
     */
    public addDoor(location: ExtendedLocation): void {
        if (this.alertForUnsavedChanges()) {
            return;
        }

        this.startAddDoorFlow.emit(location);
    }

    /**
     * Starts the split flow.
     *
     * @returns {void}
     * @memberof LocationDetailsComponent
     */
    public split(): void {
        if (this.alertForUnsavedChanges()) {
            return;
        }

        this.openSplitLocation.emit();
    }

    /**
     * Starts the combine flow.
     *
     * @returns {void}
     * @memberof LocationDetailsComponent
     */
    public combine(): void {
        if (this.alertForUnsavedChanges()) {
            return;
        }

        this.openCombineLocations.emit();
    }

    /**
     * Duplicate location.
     *
     * @param {ExtendedLocation} location
     * @memberof LocationsComponent
     */
    public duplicateLocation(location: ExtendedLocation): void {
        if (this.alertForUnsavedChanges()) {
            return;
        }

        const locationCopy = this.locationService.duplicateLocation(location);
        const locationDuplicate = this.locationService.offsetLocationPosition(locationCopy);

        if (locationDuplicate) {
            this.spinner.show();
            this.locationService.createLocation(locationDuplicate)
                .pipe(
                    finalize(() => this.spinner.hide()),
                    map(createdLocation => {
                        this.createdLocationSubject$.next(createdLocation);
                        this.notificationService.showSuccess('Location duplicated successfully!');
                        return;
                    })
                )
                .subscribe(this.saveLocationHandler);
        }
    }

    /**
     * Open DisplayRuleDetails component in a dialog.
     * Update display rule for location on save.
     *
     * @param {ExtendedLocation} location
     * @memberof LocationDetailsComponent
     */
    public async openDisplayRuleDetails(location: ExtendedLocation): Promise<void> {
        const inheritedDisplayRule = await this.displayRuleService.getDisplayRule(location.type);
        const dialogConfig: MatDialogConfig = {
            width: '768px',
            maxHeight: '95vh',
            disableClose: true,
            hasBackdrop: true,
            role: 'dialog',
            ariaLabel: 'Display Rule dialog',
            closeOnNavigation: false,
            panelClass: 'details-dialog'
        };

        const dialogRef = this.matDialog.open(DisplayRuleDetailsComponent, dialogConfig);
        this.locationService.isLocationDetailsFormClosable = false;
        dialogRef.componentInstance.displayRules = [location.displayRule, inheritedDisplayRule];
        dialogRef.componentInstance.header = location.name;
        dialogRef.componentInstance.isGeometrySettingsVisible = location.geometry.type === GeoJSONGeometryType.Polygon;

        dialogRef.afterClosed()
            .pipe(
                tap(() => {
                    this.locationService.isLocationDetailsFormClosable = true;
                }),
                filter((updatedDisplayRule: DisplayRule) => !!updatedDisplayRule),
                concatMap(updatedDisplayRule => {
                    this._location.displayRule = updatedDisplayRule;
                    this.spinner.show();
                    return this.locationService.updateLocation(this._location)
                        .pipe(finalize(() => this.spinner.hide()));
                })
            ).subscribe(() => {
                this.locationService.selectLocation(this._location);
                this.notificationService.showSuccess('Location\'s Display Rule updated successfully.');
            }, error => this.notificationService.showError(error));
    }

    /**
     * Alerts the user of unsaved changes.
     *
     * @private
     * @returns {boolean}
     * @memberof LocationDetailsComponent
     */
    private alertForUnsavedChanges(): boolean {
        if (this.locationForm.dirty) {
            // eslint-disable-next-line no-alert
            return !confirm('Your unsaved changes will be lost! Would you like to continue?');
        }

        return false;
    }
}
