import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment';
import { MediaCategory } from './media.enum';
import { IMediaItem } from './media-item/media-item.model';

@Injectable({ providedIn: 'root' })
export class MapsIndoorsIcons {
    private iconUrl: string = environment.iconsBaseUrl;

    /**
     * List of MapsIndoors icons.
     *
     * @returns {IMediaItem[]}
     */
    public getIcons(): IMediaItem[] {
        return [
            {
                id: 'mi-icon-accessible-toilet',
                category: MediaCategory.MIIcon,
                name: 'Accessible Toilet',
                preview: this.iconUrl + 'building-icons/accessible-toilet.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-atm',
                category: MediaCategory.MIIcon,
                name: 'ATM',
                preview: this.iconUrl + 'building-icons/atm.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-bar',
                category: MediaCategory.MIIcon,
                name: 'Bar',
                preview: this.iconUrl + 'building-icons/bar.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-bike-parking-2',
                category: MediaCategory.MIIcon,
                name: 'Bike Parking 2',
                preview: this.iconUrl + 'building-icons/bike-parking-variant-2.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-bike-parking-1',
                category: MediaCategory.MIIcon,
                name: 'Bike Parking 1',
                preview: this.iconUrl + 'building-icons/bike-parking-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-bike-parking',
                category: MediaCategory.MIIcon,
                name: 'Bike Parking',
                preview: this.iconUrl + 'building-icons/bike-parking.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-bus-stop',
                category: MediaCategory.MIIcon,
                name: 'Bus Stop',
                preview: this.iconUrl + 'building-icons/bus-stop.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-cafe',
                category: MediaCategory.MIIcon,
                name: 'Cafe',
                preview: this.iconUrl + 'building-icons/cafe.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-charging-station',
                category: MediaCategory.MIIcon,
                name: 'Charging station',
                preview: this.iconUrl + 'building-icons/charging-station.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-cleaning',
                category: MediaCategory.MIIcon,
                name: 'Cleaning',
                preview: this.iconUrl + 'building-icons/cleaning-cupboard.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-disabled-parking',
                category: MediaCategory.MIIcon,
                name: 'Disabled Parking',
                preview: this.iconUrl + 'building-icons/disabled-parking.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-elevator',
                category: MediaCategory.MIIcon,
                name: 'Elevator',
                preview: this.iconUrl + 'building-icons/elevator.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-entrance-variant1',
                category: MediaCategory.MIIcon,
                name: 'Entrance',
                preview: this.iconUrl + 'building-icons/entrance-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-entrance',
                category: MediaCategory.MIIcon,
                name: 'Entrance',
                preview: this.iconUrl + 'building-icons/entrance.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-escalator',
                category: MediaCategory.MIIcon,
                name: 'Escalator',
                preview: this.iconUrl + 'building-icons/escalator.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-emergency-exit-variant2',
                category: MediaCategory.MIIcon,
                name: 'Emergency Exit',
                preview: this.iconUrl + 'building-icons/exit-variant-2.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-exit',
                category: MediaCategory.MIIcon,
                name: 'Exit',
                preview: this.iconUrl + 'building-icons/exit-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-emergency-emergency-exit',
                category: MediaCategory.MIIcon,
                name: 'Emergency Exit',
                preview: this.iconUrl + 'building-icons/exit.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-toilet-women',
                category: MediaCategory.MIIcon,
                name: 'Toilet, women',
                preview: this.iconUrl + 'building-icons/female-toilet.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-fire-extingusher',
                category: MediaCategory.MIIcon,
                name: 'Fire extingusiher',
                preview: this.iconUrl + 'building-icons/fire-extinguisher.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-first-aid-kit',
                category: MediaCategory.MIIcon,
                name: 'First aid kit',
                preview: this.iconUrl + 'building-icons/first-aid-kit.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-first-aid',
                category: MediaCategory.MIIcon,
                name: 'First aid',
                preview: this.iconUrl + 'building-icons/first-aid.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-garbage',
                category: MediaCategory.MIIcon,
                name: 'Garbage',
                preview: this.iconUrl + 'building-icons/garbage.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-goods',
                category: MediaCategory.MIIcon,
                name: 'Goods',
                preview: this.iconUrl + 'building-icons/goods-receipt.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-gym',
                category: MediaCategory.MIIcon,
                name: 'Gym',
                preview: this.iconUrl + 'building-icons/gym.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-hall',
                category: MediaCategory.MIIcon,
                name: 'Hall',
                preview: this.iconUrl + 'building-icons/hall.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-information',
                category: MediaCategory.MIIcon,
                name: 'Information',
                preview: this.iconUrl + 'building-icons/information.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-kitchen',
                category: MediaCategory.MIIcon,
                name: 'Kitchen',
                preview: this.iconUrl + 'building-icons/kitchen.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-letter-box',
                category: MediaCategory.MIIcon,
                name: 'Letter box',
                preview: this.iconUrl + 'building-icons/letter-boxes.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-lockers',
                category: MediaCategory.MIIcon,
                name: 'Lockers',
                preview: this.iconUrl + 'building-icons/lockers.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-luggae-disposal',
                category: MediaCategory.MIIcon,
                name: 'Luggage disposal',
                preview: this.iconUrl + 'building-icons/luggage-disposal.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-main-door',
                category: MediaCategory.MIIcon,
                name: 'Main door',
                preview: this.iconUrl + 'building-icons/main-door.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-maintenance',
                category: MediaCategory.MIIcon,
                name: 'Maintenance',
                preview: this.iconUrl + 'building-icons/maintenance.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-toilet-men',
                category: MediaCategory.MIIcon,
                name: 'Toilet, men',
                preview: this.iconUrl + 'building-icons/male-toilet.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-metro',
                category: MediaCategory.MIIcon,
                name: 'Metro',
                preview: this.iconUrl + 'building-icons/metro-station.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-mothers-room',
                category: MediaCategory.MIIcon,
                name: 'Mothers room',
                preview: this.iconUrl + 'building-icons/mothers-room.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-office-variant1',
                category: MediaCategory.MIIcon,
                name: 'Office',
                preview: this.iconUrl + 'building-icons/office-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-office',
                category: MediaCategory.MIIcon,
                name: 'Office',
                preview: this.iconUrl + 'building-icons/office.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-park-variant1',
                category: MediaCategory.MIIcon,
                name: 'Park',
                preview: this.iconUrl + 'building-icons/park-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-park',
                category: MediaCategory.MIIcon,
                name: 'Park',
                preview: this.iconUrl + 'building-icons/park.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-parking',
                category: MediaCategory.MIIcon,
                name: 'Parking',
                preview: this.iconUrl + 'building-icons/parking.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-printer',
                category: MediaCategory.MIIcon,
                name: 'Printer',
                preview: this.iconUrl + 'building-icons/printer.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-reception-variant1',
                category: MediaCategory.MIIcon,
                name: 'Reception',
                preview: this.iconUrl + 'building-icons/reception-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-reception',
                category: MediaCategory.MIIcon,
                name: 'Reception',
                preview: this.iconUrl + 'building-icons/reception.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-recreational-variant1',
                category: MediaCategory.MIIcon,
                name: 'Recreational',
                preview: this.iconUrl + 'building-icons/recreational-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-recreational',
                category: MediaCategory.MIIcon,
                name: 'Recreational',
                preview: this.iconUrl + 'building-icons/recreational.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-relax',
                category: MediaCategory.MIIcon,
                name: 'Relax',
                preview: this.iconUrl + 'building-icons/relax-area.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-restaurant',
                category: MediaCategory.MIIcon,
                name: 'Restaurant',
                preview: this.iconUrl + 'building-icons/restaurant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-unknown',
                category: MediaCategory.MIIcon,
                name: 'Unknown',
                preview: this.iconUrl + 'building-icons/room-unknown.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-shower',
                category: MediaCategory.MIIcon,
                name: 'Shower',
                preview: this.iconUrl + 'building-icons/shower.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-smoking',
                category: MediaCategory.MIIcon,
                name: 'Smoking',
                preview: this.iconUrl + 'building-icons/smoking-area.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-sports-area',
                category: MediaCategory.MIIcon,
                name: 'Sports',
                preview: this.iconUrl + 'building-icons/sports-area.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-stairs',
                category: MediaCategory.MIIcon,
                name: 'Stairs',
                preview: this.iconUrl + 'building-icons/stairs.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-stop',
                category: MediaCategory.MIIcon,
                name: 'Stop',
                preview: this.iconUrl + 'building-icons/stop.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-storage',
                category: MediaCategory.MIIcon,
                name: 'Storage',
                preview: this.iconUrl + 'building-icons/storage.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-toilet',
                category: MediaCategory.MIIcon,
                name: 'Toilet',
                preview: this.iconUrl + 'building-icons/unisex-toilet.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-video-chat',
                category: MediaCategory.MIIcon,
                name: 'Video chat',
                preview: this.iconUrl + 'building-icons/video-chat-room.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-wardrobe',
                category: MediaCategory.MIIcon,
                name: 'Wardrobe',
                preview: this.iconUrl + 'building-icons/wardrobe.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-wifi',
                category: MediaCategory.MIIcon,
                name: 'Wifi',
                preview: this.iconUrl + 'building-icons/wifi-hotspot.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-arrival',
                category: MediaCategory.MIIcon,
                name: 'Arrival',
                preview: this.iconUrl + 'airports/arrival.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-business-center-variant1',
                category: MediaCategory.MIIcon,
                name: 'Business Center',
                preview: this.iconUrl + 'airports/business-center.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-check-in',
                category: MediaCategory.MIIcon,
                name: 'Check-in',
                preview: this.iconUrl + 'airports/check-in.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-child-care',
                category: MediaCategory.MIIcon,
                name: 'Child care',
                preview: this.iconUrl + 'airports/child-care.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-departure',
                category: MediaCategory.MIIcon,
                name: 'Departure',
                preview: this.iconUrl + 'airports/departure.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-gas-station',
                category: MediaCategory.MIIcon,
                name: 'Gas station',
                preview: this.iconUrl + 'airports/gas-station.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-gate',
                category: MediaCategory.MIIcon,
                name: 'Gate',
                preview: this.iconUrl + 'airports/gate.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-hotel',
                category: MediaCategory.MIIcon,
                name: 'Hotel',
                preview: this.iconUrl + 'airports/hotel.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-immigrations',
                category: MediaCategory.MIIcon,
                name: 'Immigrations',
                preview: this.iconUrl + 'airports/immigrations.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-moving-walkway',
                category: MediaCategory.MIIcon,
                name: 'Moving walkway',
                preview: this.iconUrl + 'airports/moving-walkway.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-passport-control-variant1',
                category: MediaCategory.MIIcon,
                name: 'Passport control',
                preview: this.iconUrl + 'airports/passport-control-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-passport-control',
                category: MediaCategory.MIIcon,
                name: 'Passport control',
                preview: this.iconUrl + 'airports/passport-control.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-security',
                category: MediaCategory.MIIcon,
                name: 'Security',
                preview: this.iconUrl + 'airports/security.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-transfer',
                category: MediaCategory.MIIcon,
                name: 'Transfer',
                preview: this.iconUrl + 'airports/transfer.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-business-center',
                category: MediaCategory.MIIcon,
                name: 'Business Center',
                preview: this.iconUrl + 'conferences/business-center.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-conference-booth',
                category: MediaCategory.MIIcon,
                name: 'Conference Booth',
                preview: this.iconUrl + 'conferences/conference-booth.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-conference-programm',
                category: MediaCategory.MIIcon,
                name: 'Conference Programme',
                preview: this.iconUrl + 'conferences/conference-programme.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-hotel',
                category: MediaCategory.MIIcon,
                name: 'Hotel',
                preview: this.iconUrl + 'conferences/hotel.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-luggage-disposal-variant1',
                category: MediaCategory.MIIcon,
                name: 'Luggage Disposal',
                preview: this.iconUrl + 'conferences/luggage-disposal.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-ambulatory',
                category: MediaCategory.MIIcon,
                name: 'Ambulatory',
                preview: this.iconUrl + 'hospitals/ambulatory.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-birth-department',
                category: MediaCategory.MIIcon,
                name: 'Birth department',
                preview: this.iconUrl + 'hospitals/birth-department.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-blood-bank',
                category: MediaCategory.MIIcon,
                name: 'Blood bank',
                preview: this.iconUrl + 'hospitals/blood-bank.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-cardiology',
                category: MediaCategory.MIIcon,
                name: 'Cardiology',
                preview: this.iconUrl + 'hospitals/cardiology.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-common-circulation',
                category: MediaCategory.MIIcon,
                name: 'Common circulation',
                preview: this.iconUrl + 'hospitals/common-circulation.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-doctor',
                category: MediaCategory.MIIcon,
                name: 'Doctor',
                preview: this.iconUrl + 'hospitals/doctor.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-drug-cupboard',
                category: MediaCategory.MIIcon,
                name: 'Drug cupboard',
                preview: this.iconUrl + 'hospitals/drug-cupboard.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-ear-surgical',
                category: MediaCategory.MIIcon,
                name: 'Ear surgical',
                preview: this.iconUrl + 'hospitals/ear-surgical.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-emergency-room',
                category: MediaCategory.MIIcon,
                name: 'Emergency room',
                preview: this.iconUrl + 'hospitals/emergency-room.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-eye-surgical',
                category: MediaCategory.MIIcon,
                name: 'Eye surgical',
                preview: this.iconUrl + 'hospitals/eye-surgical.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-game-zone',
                category: MediaCategory.MIIcon,
                name: 'Game zone',
                preview: this.iconUrl + 'hospitals/game-zone.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-gastroentero-logia',
                category: MediaCategory.MIIcon,
                name: 'Gastroentero logia',
                preview: this.iconUrl + 'hospitals/gastroentero-logia.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-gynaecology',
                category: MediaCategory.MIIcon,
                name: 'Gynaecology',
                preview: this.iconUrl + 'hospitals/gynaecology.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-heart',
                category: MediaCategory.MIIcon,
                name: 'Heart',
                preview: this.iconUrl + 'hospitals/heart.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-hospital-room',
                category: MediaCategory.MIIcon,
                name: 'Hospital room',
                preview: this.iconUrl + 'hospitals/hospital-room.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-laboratory',
                category: MediaCategory.MIIcon,
                name: 'Laboratory',
                preview: this.iconUrl + 'hospitals/laboratory.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-lung-department',
                category: MediaCategory.MIIcon,
                name: 'Lung department',
                preview: this.iconUrl + 'hospitals/lung-department.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-neurology',
                category: MediaCategory.MIIcon,
                name: 'Neurology',
                preview: this.iconUrl + 'hospitals/neurology.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-nose-surgical',
                category: MediaCategory.MIIcon,
                name: 'Nose surgical',
                preview: this.iconUrl + 'hospitals/nose-surgical.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-o-r-l-variant1',
                category: MediaCategory.MIIcon,
                name: 'O R L',
                preview: this.iconUrl + 'hospitals/o-r-l-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-o-r-l',
                category: MediaCategory.MIIcon,
                name: 'O R L',
                preview: this.iconUrl + 'hospitals/o-r-l.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-stomatology',
                category: MediaCategory.MIIcon,
                name: 'Stomatology',
                preview: this.iconUrl + 'hospitals/stomatology.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-syringe',
                category: MediaCategory.MIIcon,
                name: 'Syringe',
                preview: this.iconUrl + 'hospitals/syringe.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-xray',
                category: MediaCategory.MIIcon,
                name: 'Xray',
                preview: this.iconUrl + 'hospitals/xray-imaging.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-cinema',
                category: MediaCategory.MIIcon,
                name: 'Cinema',
                preview: this.iconUrl + 'shopping/cinema.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-gas-station',
                category: MediaCategory.MIIcon,
                name: 'Gas station',
                preview: this.iconUrl + 'shopping/gas-station.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-hair-dresser',
                category: MediaCategory.MIIcon,
                name: 'Hair dresser',
                preview: this.iconUrl + 'shopping/hair-dresser.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-hotel-variant1',
                category: MediaCategory.MIIcon,
                name: 'Hotel',
                preview: this.iconUrl + 'shopping/hotel.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-map-kiosk',
                category: MediaCategory.MIIcon,
                name: 'Map kiosk',
                preview: this.iconUrl + 'shopping/map-kiosk.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-shopping-cart',
                category: MediaCategory.MIIcon,
                name: 'Shopping cart',
                preview: this.iconUrl + 'shopping/shopping-cart.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-academic-office',
                category: MediaCategory.MIIcon,
                name: 'Academic office',
                preview: this.iconUrl + 'universities/academic-office.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-administrator',
                category: MediaCategory.MIIcon,
                name: 'Administrator',
                preview: this.iconUrl + 'universities/admin-office.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-arts-class',
                category: MediaCategory.MIIcon,
                name: 'Arts class',
                preview: this.iconUrl + 'universities/arts-class.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-av-room',
                category: MediaCategory.MIIcon,
                name: 'AV room',
                preview: this.iconUrl + 'universities/av-room.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-communications',
                category: MediaCategory.MIIcon,
                name: 'Communications',
                preview: this.iconUrl + 'universities/communications.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-eye-wash',
                category: MediaCategory.MIIcon,
                name: 'Eye wash',
                preview: this.iconUrl + 'universities/eye-wash.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-group-room',
                category: MediaCategory.MIIcon,
                name: 'Group room',
                preview: this.iconUrl + 'universities/group-room.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-laboratory-variant2',
                category: MediaCategory.MIIcon,
                name: 'Laboratory',
                preview: this.iconUrl + 'universities/laboratory.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-audirotium',
                category: MediaCategory.MIIcon,
                name: 'Auditorium',
                preview: this.iconUrl + 'universities/lecture-hall-auditorium.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-library',
                category: MediaCategory.MIIcon,
                name: 'Library',
                preview: this.iconUrl + 'universities/library.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-meeting-room',
                category: MediaCategory.MIIcon,
                name: 'Meeting room',
                preview: this.iconUrl + 'universities/meeting-room.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-network',
                category: MediaCategory.MIIcon,
                name: 'Network',
                preview: this.iconUrl + 'universities/network.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-pc-lab',
                category: MediaCategory.MIIcon,
                name: 'PC lab',
                preview: this.iconUrl + 'universities/pc-lab.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-prize-room',
                category: MediaCategory.MIIcon,
                name: 'Prize room',
                preview: this.iconUrl + 'universities/prize-room.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-radio-room',
                category: MediaCategory.MIIcon,
                name: 'Radio room',
                preview: this.iconUrl + 'universities/radio-room.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-reception-variant1',
                category: MediaCategory.MIIcon,
                name: 'Reception',
                preview: this.iconUrl + 'universities/reception-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-reception',
                category: MediaCategory.MIIcon,
                name: 'Reception',
                preview: this.iconUrl + 'universities/reception.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-service',
                category: MediaCategory.MIIcon,
                name: 'Service',
                preview: this.iconUrl + 'universities/service-support.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-shelter',
                category: MediaCategory.MIIcon,
                name: 'Shelter',
                preview: this.iconUrl + 'universities/shelter.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-sports',
                category: MediaCategory.MIIcon,
                name: 'Sports',
                preview: this.iconUrl + 'universities/sports-area.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-study-room',
                category: MediaCategory.MIIcon,
                name: 'Study room',
                preview: this.iconUrl + 'universities/study-room.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-study-zone',
                category: MediaCategory.MIIcon,
                name: 'Study zone',
                preview: this.iconUrl + 'universities/study-zone.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-swimming-pool-variant1',
                category: MediaCategory.MIIcon,
                name: 'Swimming pool',
                preview: this.iconUrl + 'universities/swimming-pool-variant.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-swimming-pool',
                category: MediaCategory.MIIcon,
                name: 'Swimming pool',
                preview: this.iconUrl + 'universities/swimming-pool.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-warning',
                category: MediaCategory.MIIcon,
                name: 'Warning',
                preview: this.iconUrl + 'universities/warning.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            },
            {
                id: 'mi-icon-default-marker',
                category: MediaCategory.MIIcon,
                name: 'Default marker',
                preview: this.iconUrl + 'misc/default-marker.png',
                type: 'png',
                width: 60, // We display 60 because the actual size we save it in is 20. To make users understand that the icons (if not SVG) will be 3 times smaller on the map. The actual size of the icons are 40x40px.
                height: 60,
                lastModified: '1970-01-01T00:00:00.000Z'
            }
        ];
    }
}