import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { ImportExportComponent } from './import-export.component';
import { ImportExportDialogComponent } from './import-export-dialog.component';

@NgModule({
    declarations: [
        ImportExportComponent,
        ImportExportDialogComponent,
    ],
    imports: [
        SharedModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ImportExportModule { }