import { BehaviorSubject, Observable, Subject, from } from 'rxjs';
import {
    ConnectorType,
    DoorType,
    EntryPointTypes,
    RouteElement,
    RouteElementType,
    RouteNetwork
} from './route-element-details/route-element.model';
import { Geometry, GeometryObject, Position } from '@turf/turf';
import { GeoJSONGeometryType, Icons } from '../shared/enums';
import { Injectable, OnDestroy } from '@angular/core';
import { ViewState, ViewStateService } from './view-state.service';
import { finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { getCenterPosition, getLatLngFromPosition } from '../shared/geometry-helper';

import { BuildingService } from '../buildings/building.service';
import { Floor } from '../buildings/floor.model';
import { LatLng } from '../locations/location.model';
import { Feature, LineString } from 'geojson';
import { LocationService } from '../locations/location.service';
import { MapService } from '../services/map.service';
import { NetworkService } from '../network-access/network.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../services/notification.service';
import { RouteElementGeometryService } from '../services/route-element-geometry.service';
import { SolutionService } from '../services/solution.service';
import { UserService } from '../services/user.service';
import { Venue } from '../venues/venue.model';
import { environment } from '../../environments/environment';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { primitiveClone } from '../shared/object-helper';
import { VenueService } from '../venues/venue.service';

export interface ElementSubType {
    key: number;
    value: string;
}

interface GraphGeoJson {
    edges: Feature;
    lockedEdges: Feature;
    unreachableEdges: Feature;
    unreachablenodes: Feature;
}

@Injectable()
export class NetworkMapService implements OnDestroy {
    private networkGraphPolylines: google.maps.Polyline[] = [];
    private unreachableNodeMarkers: google.maps.Marker[] = [];
    private editMarker: google.maps.Marker;
    private originalMarker: google.maps.Marker;
    private newMarker: google.maps.Marker;
    private routeElements: RouteElement[] = [];
    private currentRouteElementSubject$ = new BehaviorSubject<RouteElement>(null);
    private currentRouteElementOriginalPosition: LatLng;
    private currentRouteElementOriginalGeometry: GeometryObject;
    private currentVenue: Venue;
    private newRouteElementClickListener: google.maps.MapsEventListener;
    private networkGraph$: Observable<any>;
    private networkGraphUpdatedSubject = new Subject<void>();

    private isLocationDetailsFormDirty = false;
    private isRouteElementDetailsEditorDirty = false;

    constructor(
        private viewStateService: ViewStateService,
        private userService: UserService,
        private mapService: MapService,
        private routeElementGeometryService: RouteElementGeometryService,
        private networkService: NetworkService,
        private buildingService: BuildingService,
        private notificationService: NotificationService,
        private solutionService: SolutionService,
        private locationService: LocationService,
        private spinner: NgxSpinnerService,
        private venueService: VenueService
    ) {
        this.locationService.isLocationDetailsFormDirty$.subscribe(isDirty => {
            this.isLocationDetailsFormDirty = isDirty;
        });

        this.locationService.isRouteElementDetailsEditorDirty$.subscribe(isDirty => {
            this.isRouteElementDetailsEditorDirty = isDirty;
        });

        this.venueService.selectedVenue$.subscribe(venue => this.currentVenue = venue);
    }

    /**
     * Angular lifecycle hook.
     */
    ngOnDestroy(): void {
        this.locationService.isLocationDetailsFormDirty$.unsubscribe();
        this.locationService.isRouteElementDetailsEditorDirty$.unsubscribe();
    }

    /**
     * Get the current route element.
     *
     * @returns {Observable<RouteElement>}
     * @memberof NetworkService
     */
    public getCurrentRouteElement(): Observable<RouteElement> {
        return this.currentRouteElementSubject$.asObservable();
    }
    /**
     * Set current route element.
     *
     * @param {RouteElement} routeElement
     * @memberof NetworkService
     */
    public setCurrentRouteElement(routeElement: RouteElement): void {
        this.currentRouteElementSubject$.next(routeElement);
    }

    /**
     * Get route elements updated observable.
     * Used for triggering the "network graph updated" indicator in the toolbar.
     *
     * @returns {Observable<void>}
     * @memberof NetworkMapService
     */
    public getRouteElementsUpdatedSubject(): Observable<void> {
        return this.networkGraphUpdatedSubject.asObservable();
    }

    /**
     * Get subtypes of route element type.
     *
     * @param {number} elementType
     * @returns {ElementSubType[]}
     * @memberof NetworkMapService
     */
    public getSubtypes(elementType: number): ElementSubType[] {
        const subtypes: ElementSubType[] = [];

        if (elementType === RouteElementType.Connector) {
            for (const i in ConnectorType) {
                if (typeof ConnectorType[i] === 'number') {
                    subtypes.push({ key: +ConnectorType[i], value: i });
                }
            }
        } else if (elementType === RouteElementType.Door) {
            for (const i in DoorType) {
                if (typeof DoorType[i] === 'number') {
                    subtypes.push({ key: +DoorType[i], value: i });
                }
            }
        } else if (elementType === RouteElementType.EntryPoint) {
            subtypes.push(...[
                { key: EntryPointTypes.AnyEntry, value: 'Any Entry Point' },
                { key: 0, value: 'Specific Entry Points' }
            ]);
        }

        return subtypes;
    }

    /**
     * Show/hide the network graph and route elements.
     *
     * @param {boolean} visible
     * @param {number} floorIndex
     * @memberof NetworkMapService
     */
    public setNetworkElementsVisibility(visible: boolean, floorIndex: number): void {
        this.networkGraphPolylines?.forEach(polyline => polyline.setVisible(visible));
        this.unreachableNodeMarkers?.forEach(marker => marker.setVisible(visible));
        this.setRouteElementsVisibility(visible, floorIndex);
    }

    /**
     * Fetch the graph from the BE.
     *
     * @private
     * @param {string} graphId
     * @param {number} floorIndex
     * @param {boolean} visible
     * @returns {Observable<any>}
     * @memberof NetworkMapService
     */
    public getNetworkGraph(graphId: string, floorIndex: number, visible: boolean): Observable<any> {
        this.spinner.show();
        return this.networkGraph$ = this.networkService.loadGraph(graphId, floorIndex)
            .pipe(
                tap(
                    (geoJson: RouteNetwork) => {
                        this.drawGraph(geoJson, visible);
                        this.spinner.hide();
                    }
                )
            );
    }

    /**
     * Draws the network graph on the map.
     *
     * @param {RouteNetwork} geoJson
     * @param {boolean} visible
     */
    private drawGraph(geoJson: RouteNetwork, visible: boolean): void {
        const graphGeoJson = this.formatGraphGeoJson(geoJson);

        this.drawEdges(graphGeoJson, visible);

        // Check if user is owner and draw unreachable nodes if any
        if (graphGeoJson.unreachablenodes && this.userService.hasOwnerPrivileges()) {
            this.drawUnreachableNodes(graphGeoJson.unreachablenodes, visible);
        }
    }

    /**
     * Fetch the graph network, route layer & app user roles.
     *
     * @returns {Observable<any>}
     */
    public regenerateAllGraphData(): Observable<any> {
        this.notificationService.showInfo('Removing all generated graph data');
        return this.networkService.regenerateGraphData(this.currentVenue.graphId)
            .pipe(
                finalize(
                    () => {
                        this.notificationService.showSuccess('Old generated data cleared. Creating new data', false, 6);
                    }
                )
            );
    }

    /**
     * Draw network edges.
     *
     * @private
     * @param {GraphGeoJson} geoJson
     * @param {boolean} visible
     * @memberof NetworkMapService
     */
    private drawEdges(geoJson: GraphGeoJson, visible: boolean): void {
        // remove previous edges from the map
        this.networkGraphPolylines?.forEach(edge => this.mapService.removeFromMap(edge));

        const geometry = geoJson?.edges?.geometry as Geometry;
        const coordinates = geometry?.coordinates as Position[][];
        this.networkGraphPolylines = coordinates?.map((coords, index) => {
            const polyline = this.mapService.drawPolyline(coords.map(coordinate => ([coordinate[0], coordinate[1]])));
            polyline.setOptions({
                clickable: false,
                strokeColor: midt['tailwind-colors'].blue[500].value,
                strokeOpacity: 0.4,
                strokeWeight: 3,
                visible
            });

            const edgeId = geoJson.edges.properties?.edgeIndexes[index];
            // if the edge is unreachable, set color accordingly
            geoJson.unreachableEdges?.properties?.edgeIndexes?.includes(edgeId) && polyline.set('strokeColor', midt['tailwind-colors'].gray[500].value);
            // if the edge is locked, set color accordingly
            geoJson.lockedEdges?.properties?.edgeIndexes?.includes(edgeId) && polyline.set('strokeColor', midt['tailwind-colors'].red[550].value);

            return polyline;
        });
    }

    /**
     * Draw unreachable nodes as red dots.
     *
     * @private
     * @param {Feature} nodes
     * @param {boolean} visible
     * @memberof NetworkMapService
     */
    private drawUnreachableNodes(nodes: Feature, visible: boolean): void {
        // remove nodes from the map
        this.unreachableNodeMarkers?.forEach(node => this.mapService.removeFromMap(node));

        const geometry = nodes?.geometry as Geometry;
        const coordinates = geometry?.coordinates as Position[];
        this.unreachableNodeMarkers = coordinates?.map(position => {
            const latLng = getLatLngFromPosition(position);
            const icon = this.networkService.formatIcon(`${environment.iconsBaseUrl}isolatednode.png`, 10);
            const marker = this.mapService.drawMarker(latLng, icon);
            marker.setVisible(visible);
            marker.setClickable(false);
            return marker;
        });
    }

    /**
     * Formate the graph's geojson.
     *
     * @private
     * @param {RouteNetwork} geoJson
     * @returns {GraphGeoJson}
     * @memberof NetworkMapService
     */
    private formatGraphGeoJson(geoJson: RouteNetwork): GraphGeoJson {
        const graphGeoJson: GraphGeoJson = {
            edges: null,
            lockedEdges: null,
            unreachableEdges: null,
            unreachablenodes: null,
        };

        geoJson?.features?.forEach(feature => {
            if (feature.id === 'edges') {
                graphGeoJson.edges = feature;
            } else if (feature.id === 'lockedEdges') {
                graphGeoJson.lockedEdges = feature;
            } else if (feature.id === 'unreachableEdgeIdList') {
                graphGeoJson.unreachableEdges = feature;
            } else if (feature.id === 'unreachablenodes') {
                graphGeoJson.unreachablenodes = feature;
            }
        });

        return graphGeoJson;
    }

    /**
     * Show/hide route elements.
     *
     * @param {boolean} visible
     * @param {number} floorIndex
     * @memberof NetworkMapService
     */
    public setRouteElementsVisibility(visible: boolean, floorIndex: number): void {
        this.routeElements?.forEach(element => {
            this.mapService.mapObjects.get(element.id)?.set('visible', visible && floorIndex === element.floorIndex);
        });
    }

    /**
     * Draw all route elements.
     *
     * @param {RouteElement[]} routeElements
     * @param {boolean} visible
     * @memberof NetworkMapService
     */
    public drawRouteElements(routeElements: RouteElement[], visible: boolean): void {
        this.routeElements = routeElements;
        const currentFloor$: Observable<Floor> = this.buildingService.getCurrentFloor() as Observable<Floor>;
        currentFloor$.pipe(take(1))
            .subscribe(currentFloor => {
                this.routeElements?.forEach(routeElement => {
                    this.drawRouteElement(routeElement, currentFloor.floorIndex, visible);
                });
            });
    }

    /**
     * Draw a route element.
     *
     * @private
     * @param {RouteElement} routeElement
     * @param {number} floorIndex
     * @param {boolean} visible
     * @memberof NetworkMapService
     */
    private drawRouteElement(routeElement: RouteElement, floorIndex: number, visible: boolean): void {
        if ([GeoJSONGeometryType.LineString, GeoJSONGeometryType.Point].includes((routeElement.geometry.type as GeoJSONGeometryType))) {
            this.drawRouteElementAsMarker(routeElement, floorIndex, visible);
        } else if (routeElement.geometry.type === GeoJSONGeometryType.Polygon) {
            this.drawRouteElementAsPolygon(routeElement, floorIndex, visible);
        }
    }

    /**
     * Draw a route element as a marker.
     *
     * @param {RouteElement} routeElement
     * @param {number} floorIndex
     * @param {boolean} visible
     * @memberof NetworkMapService
     */
    private drawRouteElementAsMarker(routeElement: RouteElement, floorIndex: number, visible: boolean): void {
        this.networkService.setRouteLayerIcon(routeElement);

        const latLng: LatLng = getLatLngFromPosition(getCenterPosition(routeElement.geometry));

        if (!latLng) return;

        const marker = this.mapService.drawMarker(latLng, routeElement.icon);
        marker.setVisible(visible && floorIndex === routeElement.floorIndex);
        this.mapService.mapObjects.set(routeElement.id, marker);
        this.addRouteElementClickEvent(marker, routeElement);
    }

    /**
     * Draw a route element as a polygon.
     *
     * @param {RouteElement} routeElement
     * @param {number} floorIndex
     * @param {boolean} visible
     * @memberof NetworkMapService
     */
    private drawRouteElementAsPolygon(routeElement: RouteElement, floorIndex: number, visible: boolean): void {
        const polygon = this.mapService.drawPolygon(routeElement.geometry as any);
        polygon.setOptions({
            clickable: false,
            // Assuming polygon route elements are obstacles
            fillColor: midt['tailwind-colors'].red[500].value,
            fillOpacity: 0.2,
            strokeWeight: 0
        });
        polygon.setVisible(visible && floorIndex === routeElement.floorIndex);
        this.mapService.mapObjects.set(routeElement.id, polygon);
    }

    /**
     * Add click event to route element.
     *
     * @private
     * @param {google.maps.Marker} marker
     * @param {RouteElement} routeElement
     * @memberof NetworkMapService
     */
    private addRouteElementClickEvent(marker: google.maps.Marker, routeElement: RouteElement): void {
        this.mapService.addEventListener('click', () => {
            if (this.isLocationDetailsFormDirty || this.isRouteElementDetailsEditorDirty) {
                this.notificationService.showWarning('You have unsaved changes!');
            } else {
                if (this.originalMarker) {
                    this.originalMarker.setMap(this.mapService.getMap());
                }

                if (routeElement.geometry.type === GeoJSONGeometryType.Point) {
                    this.editPointRouteElement(marker, routeElement);
                } else {
                    this.editLineStringRouteElement(marker, routeElement);
                }
                this.setCurrentRouteElement(routeElement);
                this.mapService.getMap()?.panTo(getLatLngFromPosition(getCenterPosition(routeElement.geometry)));
                this.viewStateService.setViewStateObservable(ViewState.Update);
            }
        }, marker);
    }

    /**
     * Make Point route element draggable.
     *
     * @private
     * @param {google.maps.Marker} marker
     * @param {RouteElement} routeElement
     * @memberof NetworkMapService
     */
    private editPointRouteElement(marker: google.maps.Marker, routeElement: RouteElement): void {
        this.mapService.removeFromMap(this.editMarker);
        this.removeNewMarker();

        this.currentRouteElementOriginalPosition = getLatLngFromPosition(getCenterPosition(routeElement.geometry));

        const icon = this.networkService.formatIcon(Icons.Highlight, 32);
        this.editMarker = this.mapService.drawMarker(this.currentRouteElementOriginalPosition, icon);
        this.editMarker.setOptions({
            draggable: true,
            zIndex: 9999,
            cursor: 'move',
            position: this.currentRouteElementOriginalPosition
        });

        this.mapService.addEventListener('drag', (event) => {
            routeElement.geometry.coordinates = [event.latLng.lng(), event.latLng.lat()];
            marker.setPosition(event.latLng);
        }, this.editMarker);
    }

    /**
     * Render and make LineString route element draggable.
     *
     * @private
     * @param {google.maps.Marker} marker
     * @param {RouteElement} routeElement
     * @memberof NetworkMapService
     */
    private editLineStringRouteElement(marker: google.maps.Marker, routeElement: RouteElement): void {
        // Temporarily hide the marker that depicts the route element position so it does not stand in the way of the draggable door.
        this.originalMarker = marker;
        this.originalMarker.setMap(null);

        this.currentRouteElementOriginalGeometry = primitiveClone(routeElement.geometry);

        this.routeElementGeometryService.editDoor(routeElement.geometry, routeElement.venueId, routeElement.floorIndex);
    }

    /**
     * Remove the edit marker and its event listeners.
     *
     * @memberof NetworkMapService
     */
    public cancelRouteElementEditMode(): void {
        this.mapService.removeFromMap(this.editMarker);
        this.routeElementGeometryService.cancelOperation();
        if (this.originalMarker) {
            this.originalMarker.setMap(this.mapService.getMap());
        }

        // Move the element back to its original position.
        const element = this.currentRouteElementSubject$.value;
        if (element?.geometry.type === GeoJSONGeometryType.Point) {
            element.geometry.coordinates = [this.currentRouteElementOriginalPosition?.lng, this.currentRouteElementOriginalPosition?.lat];
            const marker = this.mapService.mapObjects.get(element.id) as google.maps.Marker;
            marker?.setPosition(this.currentRouteElementOriginalPosition);
        } else if (element?.geometry.type as GeoJSONGeometryType === GeoJSONGeometryType.LineString) {
            (element.geometry as GeometryObject) = this.currentRouteElementOriginalGeometry;
        }
    }

    /**
     * Deletes route element.
     *
     * @param {RouteElement} routeElement
     * @memberof NetworkMapService
     */
    public deleteRouteElement(routeElement: RouteElement): void {
        this.spinner.show();
        this.routeElementGeometryService.cancelOperation();
        this.networkService.deleteRouteLayerItem(routeElement.id)
            .pipe(finalize(() => this.spinner.hide())
            ).subscribe(
                () => {
                    this.routeElements = this.routeElements.filter(element => element.id !== routeElement.id);
                    const marker = this.mapService.mapObjects.get(routeElement.id) as google.maps.Marker;
                    this.mapService.removeFromMap(marker, routeElement.id);
                    this.mapService.removeFromMap(this.editMarker);
                    this.currentRouteElementSubject$.next(null);
                    this.networkGraphUpdatedSubject.next();
                    this.notificationService.showSuccess('Deleted successfully');
                },
                err => this.notificationService.showError(err));

        this.viewStateService.setViewStateObservable(ViewState.Default);
    }

    /**
     * Remove new route element marker.
     * Set map-state to default.
     *
     * @memberof NetworkMapService
     */
    public removeNewMarker(): void {
        this.mapService.removeFromMap(this.newMarker);
    }

    /**
     * Creates a new route element object with default value.
     *
     * @private
     * @param {Venue} venue
     * @param {number} floorIndex
     * @returns {RouteElement}
     * @memberof NetworkMapService
     */
    private createNewRouteElementObject(venue: Venue, floorIndex: number): RouteElement {
        const solution = this.solutionService.getStaticSolution();
        return {
            solutionId: solution?.id,
            graphId: venue?.graphId,
            venueId: venue?.name,
            floorIndex,
            type: null,
            restrictions: [],
            waitTime: 0,
            geometry: {
                coordinates: [],
                type: 'Point'
            }
        };
    }

    /**
     * Creates a new door.
     *
     * @param {Feature<LineString>} lineString - A LineString representation of the door.
     * @param {Venue} venue
     * @param {number} floorIndex
     * @memberof NetworkMapService
     */
    public createDoor(lineString: Feature<LineString>, venue: Venue, floorIndex: number): any {
        const element = this.createDoorRouteElement(lineString, venue, floorIndex);
        this.createNewFixedRouteElementMarker(element);
    }

    /**
     * Creates a new route element for door.
     *
     * @param {Feature<LineString>} lineString - A LineString representation of the door.
     * @param {Venue} venue
     * @param {number} floorIndex
     * @memberof NetworkMapService
     * @returns {RouteElement}
     */
    public createDoorRouteElement(lineString: Feature<LineString>, venue: Venue, floorIndex: number): RouteElement {
        const element = this.createNewRouteElementObject(venue, floorIndex);
        element.type = RouteElementType.Door;
        element.subtype = DoorType.Door;
        element.radius = .50;
        element.geometry = lineString.geometry;

        return element;
    }

    /**
     * Create route element of type Barrier.
     *
     * @param {Venue} venue
     * @param {number} floorIndex
     * @memberof NetworkMapService
     */
    public createBarrierRouteElement(venue: Venue, floorIndex: number): void {
        this.mapService.getMap()?.setOptions({ draggableCursor: 'crosshair' });

        const element = this.createNewRouteElementObject(venue, floorIndex);
        element.type = RouteElementType.Barrier;
        element.radius = .75;

        this.createNewRouteElementMarker(element);
    }

    /**
     * Creates a new entrypoint.
     *
     * @param {Venue} venue
     * @param {number} floorIndex
     * @memberof NetworkMapService
     */
    public createEntryPoint(venue: Venue, floorIndex: number): void {
        this.mapService.getMap()?.setOptions({ draggableCursor: 'crosshair' });

        const element = this.createNewRouteElementObject(venue, floorIndex);
        element.type = RouteElementType.EntryPoint;
        element.subtype = EntryPointTypes.AnyEntry;
        element.radius = 10;

        this.createNewRouteElementMarker(element);
    }

    /**
     * Creates a new connector.
     *
     * @param {Venue} venue
     * @param {number} floorIndex
     * @memberof NetworkMapService
     */
    public createFloorConnector(venue: Venue, floorIndex: number): void {
        this.mapService.getMap()?.setOptions({ draggableCursor: 'crosshair' });

        const element = this.createNewRouteElementObject(venue, floorIndex);
        element.type = RouteElementType.Connector;
        element.subtype = ConnectorType.Stairs;
        element.radius = .75;

        this.createNewRouteElementMarker(element);
    }

    /**
     * Creates a new, non-draggable marker to highlight the position of the new route element.
     *
     * @private
     * @param {RouteElement} routeElement
     * @memberof NetworkMapService
     */
    private createNewFixedRouteElementMarker(routeElement: RouteElement): void {
        this.mapService.setMapObjectsClickability(false);

        const position: LatLng = getLatLngFromPosition(getCenterPosition(routeElement.geometry));
        this.newMarker = this.mapService.drawMarker(position, this.mapService.createSymbol);
        this.newMarker.setOptions({ draggable: false, zIndex: 9999 });

        // open route element details view
        this.currentRouteElementSubject$.next(routeElement);

        this.mapService.setMapObjectsClickability(true);
    }

    /**
     * Creates a new, draggable marker to highlight the position of the new route element.
     *
     * @private
     * @param {RouteElement} routeElement
     * @memberof NetworkMapService
     */
    private createNewRouteElementMarker(routeElement: RouteElement): void {
        this.viewStateService.setViewStateObservable(ViewState.Create);
        this.mapService.setMapObjectsClickability(false);

        this.newRouteElementClickListener = this.mapService.addEventListener('click', (event) => {
            this.mapService.getMap()?.setOptions({ draggableCursor: '' });

            const position: LatLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
            routeElement.geometry.coordinates = [position.lng, position.lat];
            this.newMarker = this.mapService.drawMarker(position, this.mapService.createSymbol);
            this.newMarker.setOptions({ draggable: true, zIndex: 9999, cursor: 'move' });

            // attach dragend event
            this.mapService.addEventListener('dragend', (_event) => {
                routeElement.geometry.coordinates = [_event.latLng.lng(), _event.latLng.lat()];
            }, this.newMarker);

            // open route element details view
            this.currentRouteElementSubject$.next(routeElement);

            this.viewStateService.setViewStateObservable(ViewState.Update);
            this.mapService.setMapObjectsClickability(true);

            this.newRouteElementClickListener.remove();
        });
    }

    /**
     * Cancel creation of new route element.
     *
     * @memberof NetworkMapService
     */
    public cancelCreateNewRouteElement(): void {
        this.mapService.getMap()?.setOptions({ draggableCursor: '' });

        this.removeNewMarker();
        this.mapService.setMapObjectsClickability(true);
        this.newRouteElementClickListener.remove();
    }

    /**
     * Saves the route element into the backend.
     *
     * @param {RouteElement} routeElement
     * @memberof NetworkMapService
     */
    public saveRouteElement(routeElement: RouteElement): void {
        routeElement?.id ? this.updateRouteElement(routeElement) : this.createRouteElement(routeElement);
        this.routeElementGeometryService.cancelOperation();
        delete this.originalMarker;
    }

    /**
     * Create new route elements in the backend.
     *
     * @param {RouteElement[]} routeElements - Array of Route Elements to create.
     * @memberof NetworkMapService
     */
    public createRouteElements(routeElements: RouteElement[]): void {
        const addRouteElementToMap = (id: string, routeElement: RouteElement): void => {
            routeElement.id = id;
            this.routeElements.push(routeElement);
            this.drawRouteElement(routeElement, routeElement.floorIndex, true);
        };

        this.spinner.show();

        from(routeElements)
            .pipe(
                mergeMap(routeElement => this.networkService.createRouteLayerItem(routeElement).pipe(map((id) => { return { id: id, routeElement }; })))
            )
            .subscribe(
                (value) => addRouteElementToMap(value.id, value.routeElement),
                (err) => this.notificationService.showError(err.message),
                () => {
                    this.spinner.hide();
                    this.notificationService.showSuccess('Route elements created');
                    this.networkGraphUpdatedSubject.next();
                    this.viewStateService.setViewStateObservable(ViewState.Default);
                }
            );
    }

    /**
     * Creates the new route element in the backend.
     *
     * @private
     * @param {RouteElement} routeElement
     * @memberof NetworkMapService
     */
    private createRouteElement(routeElement: RouteElement): void {
        const addRouteElementToMap = (id: string): void => {
            routeElement.id = id;
            this.routeElements.push(routeElement);
            this.drawRouteElement(routeElement, routeElement.floorIndex, true);
        };

        this.spinner.show();
        this.networkService.createRouteLayerItem(routeElement)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe((id: string) => {
                addRouteElementToMap(id);
                this.removeNewMarker();
                this.notificationService.showSuccess('Route element created');
                // close the details view
                this.currentRouteElementSubject$.next(null);
                this.networkGraphUpdatedSubject.next();
                this.viewStateService.setViewStateObservable(ViewState.Default);
            }, err => this.notificationService.showError(err));
    }

    /**
     * Updates the route element in the backend.
     *
     * @private
     * @param {RouteElement} routeElement
     * @memberof NetworkMapService
     */
    private updateRouteElement(routeElement: RouteElement): void {
        const updateRouteElementMarker = (): void => {
            const marker = this.mapService.mapObjects.get(routeElement.id) as google.maps.Marker;
            this.mapService.removeFromMap(marker, routeElement.id);
            this.drawRouteElement(routeElement, routeElement.floorIndex, true);
        };

        this.spinner.show();
        this.networkService.updateRouteLayerItem(routeElement)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(() => {
                this.viewStateService.setViewStateObservable(ViewState.Default);
                this.networkGraphUpdatedSubject.next();

                updateRouteElementMarker();
                this.mapService.removeFromMap(this.editMarker);
                this.notificationService.showSuccess('Route element updated');
                // close the details view
                this.currentRouteElementSubject$.next(null);
                // show a toast notification
            }, err => this.notificationService.showError(err));
    }
}
