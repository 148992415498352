export enum FeatureClass {
    EXTRUSION,
    HIGHLIGHT,
    LABEL,
    TEXT_LABEL,
    FLAT_LABEL,
    GRAPHIC_LABEL,
    LINESTRING,
    NETWORK,
    MODEL2D,
    MODEL3D,
    POINT,
    POLYGON,
    WALL,
    FLOOR_PLAN,
    AREAASOBSTACLE
}
