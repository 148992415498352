<select class="mi-select" [(ngModel)]="doorTypeValue" name="door-type">
    <option *ngFor="let type of doorTypes" [value]="type.key">{{ type.value }}</option>
</select>

<span class="map-toolbar__divider"></span>

<button type="button" class="mi-button mi-button--small mi-button--base" (click)="onCancel()">Cancel</button>
<button type="button" class="mi-button mi-button--small mi-button--primary" aria-label="Save Multiple Doors"
    (click)="saveMultipleDoors()" [disabled]="doorsToBeCreated.length === 0">
    <span class=" tooltip">Save Multiple Doors</span>
</button>
<button (click)="undo()" type="button" class="mi-button mi-button--small mi-button--base"
    [disabled]="doorsToBeCreated.length === 0">
    Undo last click
</button>

<span class="map-toolbar__divider"></span>