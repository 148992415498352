import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'obstacle-input',
    templateUrl: './obstacle-input.component.html',
    styleUrls: ['./obstacle-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ObstacleInputComponent),
            multi: true
        }
    ]
})
export class ObstacleInputComponent implements ControlValueAccessor, OnInit {

    constructor() { }

    public obstacleFormControl = new FormControl();

    /**
     * NgOnInt.
     */
    ngOnInit(): void {
        this.obstacleFormControl.valueChanges.subscribe((value) => {
            this.onChange(value);
        });
    }

    onChange: any = () => { };
    onTouch: any = () => { };

    /**
     * Writes a new value to the input.
     *
     * @param {boolean} value
     */
    writeValue(value: boolean): void {
        this.obstacleFormControl.patchValue(value, { emitEvent: false });
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     *
     * @param {Function} fn
     */
    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     *
     * @param {Function} fn
     */
    registerOnTouched(fn: Function): void {
        this.onTouch = fn;
    }

    /**
     * SetDisabledState.
     *
     * @param {boolean} isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.obstacleFormControl.disable() : this.obstacleFormControl.enable();
    }
}
