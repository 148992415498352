<form [formGroup]="dailyOpeningHoursFormGroup" class="opening-hours">
    <p>{{ day }}</p>
    <div class="opening-hours__input opening-hours__input--time">
        <input formControlName="startTime" type="time" class="mi-input">
        -
        <input formControlName="endTime" type="time" class="mi-input">
    </div>
    <label class="opening-hours__input opening-hours__input--closed">
        <input formControlName="closedAllDay" type="checkbox" class="mi-input">
        Closed
    </label>
</form>
<small role="alert" class="mi-form__error" *ngIf="startTimeEqualsEndTime">
    Opening and closing times cannot be the same.
</small>