<locations-list [ngClass]="{'expand': isListViewOpen, 'hidden': !isFilterBarVisible}">
    <filters-bar
        [isListViewOpen]="isListViewOpen"
        [canOpenBulkEdit]="canOpenBulkEditor"
        (listViewtoggle)="toggleListView()"
        (openBulkEdit)="onBulkEditorOpen()">
    </filters-bar>

    <mi-data-table
        id="locations-table"
        selectable
        max-rows="1000"
        [rows]="filteredLocations$ | async"
        [selected]="selectedLocations"
        empty-page-header="No results found"
        empty-page-subheader="Adjust your filter or search to find new results."
        [ngClass]="{'hidden': !isListViewOpen}">
        <mi-column (clicked)="editLocation($event.detail)" align-content="center" width="48px">
            <img src="/assets/images/show-on-map.svg" title="Show on map" style="cursor: pointer;" />
        </mi-column>
        <mi-column label="Name" binding="name" sortable width="25%"></mi-column>
        <mi-column label="Icon" fit-content align-content="center" width="48px"><img src="{icon}" height="24px" /></mi-column>
        <mi-column label="Type" binding="locationTypeName" sortable width="25%"></mi-column>
        <mi-column label="External ID" binding="externalId" sortable monospace width="25%"></mi-column>
        <mi-column label="Floor" binding="floorName" sortable fit-content></mi-column>
        <mi-column label="Building" binding="buildingName" sortable width="25%"></mi-column>
        <mi-column label="Location ID" binding="id" sortable monospace width="25%"></mi-column>
        <mi-column label="Last modified" binding="lastModifiedDate" sortable="date" sort="desc" width="256px"></mi-column>
    </mi-data-table>
</locations-list>

<app-locations-bulk-edit
    *ngIf="isBulkEditorOpen"
    [locations]="selectedLocations"
    (closeBulkEditor)="onBulkEditorClose()"
    (bulkLocationsUpdate)="onBulkLocationsUpdate()">
</app-locations-bulk-edit>

<div #map id="map"></div>
<div class="map__controls">
    <map-sidebar [ngClass]="{'hidden': !isMapSidebarVisible}" (closed)="onMapSidebarClosed()">
    </map-sidebar>
    <map-toolbar [ngClass]="{'hidden': !isMapToolbarVisible}">
    </map-toolbar>
    <floor-selector></floor-selector>
</div>