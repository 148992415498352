import { StandardOpeningHours } from '../../shared/standard-opening-hours/standardOpeningHours.model';
import { Translation } from '../../shared/interfaces/translation.model';
import { OccupantType } from './occupant.model';
import { Address } from '../../shared/address/address.model';
import { ContactInformation } from '../../shared/contactInformation/contactInformation.model';

export interface ExtendedOccupantTemplate extends OccupantTemplate {
    displayName: string;
    occupantCategoryName: string;
    lastModifiedDate: string;
}

export class OccupantTemplate {
    address: Address = new Address();
    aliases: string[] = null;
    businessHours: { standardOpeningHours: StandardOpeningHours } = {
        standardOpeningHours: new StandardOpeningHours()
    };
    contactInformation: ContactInformation = new ContactInformation();
    createdAt?: string = null;
    createdBy?: string = null;
    id?: string = null;
    lastModified?: string = null;
    lastModifiedBy?: string = null;
    logoURI: string = null;
    occupantCategoryId: string = null;
    occupantReferenceCount: number = null;
    occupantType: OccupantType = null;
    solutionId?: string = null;
    translations: Translation[] = [];

    private constructor(data?: any) {
        Object.assign(this, data);
    }

    /**
     * Creates an occupant template.
     *
     * @param {{ occupantTemplate?: OccupantTemplate, defaultLanguage?: string, availableLanguages?: string[] }} occupantTemplateInformation
     * @returns {OccupantTemplate}
     */
    static createOccupantTemplate(occupantTemplateInformation: { occupantTemplate?: OccupantTemplate, defaultLanguage?: string, availableLanguages?: string[] }): OccupantTemplate {
        const newOccupantTemplate = new OccupantTemplate(occupantTemplateInformation?.occupantTemplate);

        if (occupantTemplateInformation?.defaultLanguage && Array.isArray(occupantTemplateInformation?.availableLanguages) && newOccupantTemplate?.translations.length === 0) {
            const translations: Translation[] = [];

            // Creating a translation for each available language.
            occupantTemplateInformation?.availableLanguages?.forEach((language: string) => {
                translations.push({
                    description: null,
                    language: language,
                    name: null,
                    fields: null
                });
            });

            // Sorting the translations array so the default language is first in the list.
            translations.sort((a: Translation, b: Translation) => {
                if (a.language === occupantTemplateInformation?.defaultLanguage) {
                    return -1;
                }
                if (b.language === occupantTemplateInformation?.defaultLanguage) {
                    return 1;
                }
                return 0;
            });

            newOccupantTemplate.translations = translations;
        }

        return newOccupantTemplate;
    }

}