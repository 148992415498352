<section [formGroup]="restrictionsForm" [attr.disabled]="restrictionsForm.disabled || null">
    <label class="mi-label mi-label--radio">
        <input type="radio" class="mi-input" formControlName="accessibilityType"
               [value]="accessibilityTypes.OpenForAll"
               (change)="onRestrictionsChange()">
        Open for all
    </label>
    <label class="mi-label mi-label--radio">
        <input type="radio" class="mi-input" formControlName="accessibilityType"
               [value]="accessibilityTypes.OpenForSome"
               (change)="onRestrictionsChange()">
        Open for specific App User Roles
        <info-tooltip class="tooltip" text="At least one option must be selected"></info-tooltip>
    </label>

    <!-- App User Roles -->
    <div formArrayName="restrictions"
         *ngIf="restrictionsForm.get('accessibilityType').value == accessibilityTypes.OpenForSome">
        <label *ngFor="let restrictionControl of restrictionsFormArray.controls; index as i"
               class="mi-label mi-label--checkbox role"
            >
            <input type="checkbox"
                   class="mi-input"
                   [formControl]="restrictionsFormArray.controls[i]"
                   (change)="onRestrictionsChange()">
            {{appUserRoles[i].displayName}}
        </label>

        <!-- No App User Roles is available -->
        <p *ngIf="restrictionsFormArray.controls.length <= 0">
            No <a [routerLink]="['/app-settings']" rel="noopener">App User Roles</a> have been set up.
            <a [routerLink]="['/app-settings']" rel="noopener">App User Roles</a> can be used to determine what
            users can see and access on the map.
        </p>
    </div>

    <label class="mi-label mi-label--radio">
        <input type="radio" class="mi-input" formControlName="accessibilityType"
               [value]="accessibilityTypes.Closed"
               (change)="onRestrictionsChange()">
        Closed for all
    </label>
</section>