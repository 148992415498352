export enum LabelFormat {
    LocationName = '{{name}}',
    ExternalId = '{{externalId}}',
    ExternalIdAndLocName = '{{externalId}} {{name}}',
    LocationNameAndExternalId = '{{name}} {{externalId}}',
}

export enum LabelTypeFormat {
    TextLabel = 'FLOATING',
    FlatLabel = 'FLAT',
    GraphicLabel = 'GRAPHIC'
}

export const labelFormats: { value: LabelFormat; viewValue: string; }[] = [
    {
        value: LabelFormat.LocationName,
        viewValue: 'Location name'
    },
    {
        value: LabelFormat.ExternalId,
        viewValue: 'External Id'
    },
    {
        value: LabelFormat.ExternalIdAndLocName,
        viewValue: 'External Id & Location Name'
    },
    {
        value: LabelFormat.LocationNameAndExternalId,
        viewValue: 'Location name & External Id'
    }
];

export const labelTypeFormats: { value: LabelTypeFormat; viewValue: string; }[] = [
    {
        value: LabelTypeFormat.TextLabel,
        viewValue: 'Floating Label'
    },
    {
        value: LabelTypeFormat.FlatLabel,
        viewValue: 'Flat Label'
    },
    {
        value: LabelTypeFormat.GraphicLabel,
        viewValue: 'Graphic Label'
    }
];