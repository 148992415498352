import { Component, ComponentRef, Injector, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { MapAdapterType, MapModule } from '../../MapAdapter/BaseMapAdapter';
import { SolutionService } from '../../services/solution.service';
import { MapAdapterComponent } from '../map-adapter/map-adapter.component';


@Component({
    selector: 'map-container',
    templateUrl: './map-container.component.html'
})
export class MapContainerComponent implements OnInit, OnDestroy {
    @ViewChild('container', { read: ViewContainerRef, static: true })
    private container!: ViewContainerRef;
    private componentRef!: ComponentRef<MapAdapterComponent>;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private parentInjector: Injector,
        private solutionService: SolutionService,
    ) { }

    /**
     * Angular OnInit lifecycle hook.
     */
    ngOnInit(): void {
        const selectedSolutionSubscription = this.solutionService.selectedSolution$
            .subscribe(solution => {
                const modules: MapModule[] = (solution?.modules?.map(module => module.toLowerCase()) ?? []) as MapModule[];
                const nextMapAdapterType = modules.includes('mapbox') ? MapAdapterType.MapboxAdapter : MapAdapterType.GoogleMapsAdapter;

                this.componentRef?.destroy();
                this.componentRef = this.initMapAdapter(nextMapAdapterType, modules);
            });
        this.subscriptions.add(selectedSolutionSubscription);
    }

    /**
     * Angular OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


    /**
     * Creates a new MapAdapterComponent.
     *
     * @private
     * @param {MapAdapterType} mapAdapterType
     * @returns {ComponentRef<MapAdapterComponent>}
     */
    private initMapAdapter(mapAdapterType: MapAdapterType, modules: MapModule[]): ComponentRef<MapAdapterComponent> {
        const injector = Injector.create({
            providers: [
                { provide: MapAdapterType, useValue: mapAdapterType },
                { provide: 'mapModules', useValue: modules }
            ], parent: this.parentInjector
        });
        return this.container.createComponent<MapAdapterComponent>(MapAdapterComponent, { injector });
    }
}