<section class="file-upload" *ngIf="currentImageStatus === imageStatus.NoImage">
    Select a photo from the Media Library
    <div>
        <button type="button" class="mi-button mi-button--base mi-button--icon-left" (click)="openMediaLibrary()" [disabled]="isLocked">
            <img src="/assets/images/photo_library_24px.svg" width="16px" />
            Open Media Library
        </button>
    </div>
</section>

<section class="image-preview" *ngIf="currentImageStatus === imageStatus.HasImage">
    <img [src]="image.src" />
    <button class="mi-button mi-button--delete mi-button--icon image-preview__delete" (click)="deleteImage()" [disabled]="isLocked">
        <img src="/assets/images/trash-bin_20x20.svg#white"/>
    </button>
</section>

<section class="image-loading" *ngIf="currentImageStatus === imageStatus.Loading">
    <mi-spinner></mi-spinner>
</section>

<details>
    <summary>Show more</summary>
    <section class="section">
        <label class="mi-label">
            Image URL
            <span class="mi-input-container">
                <input class="mi-input" type="url"
                    (blur)="urlChangeHandler($event)"
                    [ngModel]="image?.src"
                    [value]="imageSrc"
                    [disabled]="isLocked"
                    (input)="onInputChanged($event)" />
                <button
                    type="button"
                    class="mi-button mi-button--clear"
                    aria-label="Clear"
                    (click)="deleteImage()"
                    [disabled]="isLocked">
                    <img src="/assets/images/clear-input-16x16.svg">
                </button>
            </span>
        </label>
        <span *ngIf="image">Dimensions: {{image?.naturalWidth}} x {{image?.naturalHeight}} px </span>
    </section>
</details>