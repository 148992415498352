import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { concatMap, distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';
import { VenueService } from '../../venues/venue.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImportService } from '../../services/import.service';
import { NotificationService } from '../../services/notification.service';
import { Venue } from '../../venues/venue.model';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { MapSidebar } from '../map-sidebar/map-sidebar.interface';
import { MapUIComponents, MapUIService } from '../map-ui-service/map-ui.service';
import { NetworkService } from '../../network-access/network.service';
import { BuildingService } from '../../buildings/building.service';
import { MapAdapterMediator } from '../map-adapter.mediator';
import { RouteNetworkMapViewModelFactory } from '../../network-access/RouteNetworkMapViewModelFactory/RouteNetworkMapViewModelFactory';
import { BaseMapAdapter } from '../../MapAdapter/BaseMapAdapter';
import { Floor } from '../../buildings/floor.model';

@Component({
    selector: 'graph-list',
    templateUrl: './graph-list.component.html',
    styleUrls: ['./graph-list.component.scss']
})

export class GraphListComponent implements OnInit, OnDestroy, MapSidebar {

    @Output() public readonly closed: EventEmitter<void> = new EventEmitter();

    public venue!: Venue;
    public graphIds: string[] = [];

    private mapAdapter: BaseMapAdapter;
    private subscriptions = new Subscription();
    private routeNetwork$: Observable<GeoJSON.Feature[]>;
    private graphIdSubject: Subject<string> = new Subject<string>();

    constructor(
        private spinner: NgxSpinnerService,
        private venueService: VenueService,
        private mapUIService: MapUIService,
        private importService: ImportService,
        private networkService: NetworkService,
        private buildingService: BuildingService,
        private mapAdapterMediator: MapAdapterMediator,
        private notificationService: NotificationService) { }

    /** NgOnInit. */
    ngOnInit(): void {
        this.mapUIService.show(MapUIComponents.GraphListEditor);
        this.mapAdapter = this.mapAdapterMediator?.getMapAdapter();

        this.subscriptions.add(this.venueService.getSelectedVenue()
            .subscribe(venue => this.venue = venue));

        const solutionGraphs = this.networkService.getSolutionGraphsIds();
        solutionGraphs.subscribe((graphIds) => this.graphIds = graphIds);

        this.routeNetwork$ = combineLatest([this.graphIdSubject, this.buildingService.selectedFloor$])
            .pipe(
                distinctUntilChanged((previous: [string, Floor], current: [string, Floor]) =>
                    previous?.[0] === current?.[0] &&
                    previous?.[1].floorIndex === current[1]?.floorIndex),
                switchMap(([graphId, floor]) => this.networkService.loadRouteNetwork(graphId, floor.floorIndex)));

        this.mapAdapter.viewState.addDataSource(this.routeNetwork$, new RouteNetworkMapViewModelFactory());
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.mapAdapter.viewState.removeDataSource(this.routeNetwork$);
        this.subscriptions.unsubscribe();
    }

    /**
     * Set the venue graph.
     *
     * @param {string} graphId
     * @memberof GraphListComponent
     */
    public setVenueGraph(graphId: string): void {
        this.venue.graphId = graphId;
        this.spinner.show();
        this.venueService.updateVenue(this.venue)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(
                () => {
                    this.notificationService.showSuccess('Venue graph updated');
                    this.venueService.setVenueEvent('updated');
                }, err => this.notificationService.showError(err)
            );
    }

    /**
     * Delete graph.
     *
     * @param {string} graphId
     * @memberof GraphListComponent
     */
    public deleteGraph(graphId: string): void {
        // eslint-disable-next-line no-alert
        if (!confirm(`Would you like to delete ${graphId}?`)) return;

        this.spinner.show();
        this.importService.deleteGraph(graphId)
            .pipe(
                concatMap(() => this.importService.getGraphIds()),
                finalize(() => this.spinner.hide()),
            ).subscribe(graphIds => this.graphIds = graphIds);
    }

    /**
     * Turns on the Route Network.
     *
     * @param {string} graphId
     */
    public loadCurrentVenueGraph(graphId: string): void {
        this.graphIdSubject.next(graphId);
    }

    /**
     * Closes the graph list editor.
     *
     * @returns {boolean}
     * @memberof GraphListEditorComponent
     */
    public close(): boolean {
        this.closed.emit();
        return true;
    }
}