<panel-header header="Media Library" (cancelEvent)="closeModal()"></panel-header>

<div class="media">
    <ngx-spinner name="media-spinner" [fullScreen]="false" type="ball-pulse"></ngx-spinner>

    <div class="media-navbar">
        <div class="media-navbar__upload">
            <media-upload [isUploadEnabled]="isUploadEnabled" *ngIf="!isComponentShown"></media-upload>
            <div *ngIf="isComponentShown">
                The Graphic Label feature is in preview. Please share your feedback on <a href="mailto:product@mapspeople.com">product@mapspeople.com</a>
            </div>
        </div>
        <div class="media-navbar__menu">
            <span class="documentation-reference">
                <a
                    class="documentation-reference__link documentation-reference__link--right"
                    href="https://docs.mapsindoors.com/content/cms/media-library/"
                    target="_blank">
                    Help
                </a>
            </span>
            <media-filter-bar [defaultFilter]="defaultFilter" [isComponentShown]="isComponentShown"></media-filter-bar>
        </div>
    </div>

    <div class="media__items">
        <media-items-list
            *ngIf="allMediaItems"
            [mediaItems]="filteredMediaItems"
            [source]="source"
            [selectedItem]="usedItem"
            [maxNumberOfMediaItemsPerPage]="maxNumberOfMediaItemsPerPage"
            [currentPage]="currentPage"
            (chosenMediaItem)="setSelectedMedia($event)"
            (filteredMediaItems)="onFilteredMediasChange($event)">
        </media-items-list>
    </div>
</div>

<pagination
    class="pagination"
    [ngClass]="{'pagination--higher' :showFooter }"
    [itemsPerPage]="maxNumberOfMediaItemsPerPage"
    [totalItems]="filteredMediaItems?.length"
    (pageChanged)="loadNewMedias($event)">
</pagination>

<panel-footer *ngIf="showFooter" class="footer">
    <div class="footer__buttons">
        <button
            type="button"
            class="mi-button mi-button--base"
            (click)="onDiscardChanges()">
            Cancel
        </button>
        <p class="warning" *ngIf="showWarningMessage">
            {{ warningMessage }}
            <input
                *ngIf="showWarningToAccept"
                class="warning__checkbox"
                (change)="onWarningAccepted($event)"
                id="checkWarning"
                type="checkbox">
        </p>
        <button
            type="button"
            class="mi-button mi-button--primary"
            (click)="onSelect()"
            [disabled]="disableSelect">
            Select
        </button>
    </div>
</panel-footer>