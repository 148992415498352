import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RegexPatterns } from '../../shared/enums';
import { Subscription } from 'rxjs';
import { SolutionService } from '../../services/solution.service';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { getKeyPaths, mergeObjects, primitiveClone, removeEmptyValues } from '../../shared/object-helper';
import { NotificationService } from '../../services/notification.service';
import { SolutionConfig } from '../solution-settings.model';
import { Solution } from '../../solutions/solution.model';
import { zoomFromValidator, zoomToValidator } from '../../shared/threshold.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import { getFormControl } from '../../shared/form-helper';

interface FormControlState {
    keyPath: string;
    value: string;
    disabled: boolean;
}

@Component({
    selector: 'building-highlight',
    templateUrl: './building-highlight.component.html',
    styleUrls: ['./building-highlight.component.scss']
})
export class BuildingHighlightComponent implements OnInit, OnDestroy {
    constructor(
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService
    ) {
        this.selectedSolutionSubscription = this.solutionService.selectedSolution$.subscribe((solution) => {
            this.maxZoomLevel = Solution.getMaxZoomLevel(solution);
        });

        this.defaultBuildingHighlightDisplayRule = DisplayRuleService.BUILDING_HIGHLIGHT_DISPLAY_RULE;
    }

    private selectedSolutionSubscription: Subscription;
    private initialBuildingHighlightDisplayRule;
    private defaultBuildingHighlightDisplayRule;
    private updateData: SolutionConfig;
    private initialFormControlStates: FormControlState[] = [];
    private buildingHighlightDisplayRuleKeyPaths: string[];

    public isBuildingHighlightDisplayRuleFormDirty: boolean = false;
    public maxZoomLevel: number = 22;
    public minZoomLevel: number = 1;
    public buildingHighlightDisplayRuleForm = this.formBuilder.group({
        polygon: this.formBuilder.group({
            visible: ['', [Validators.required]],
            zoomFrom: ['', [Validators.required, zoomFromValidator('zoomTo')]],
            zoomTo: ['', [Validators.required, zoomToValidator('zoomFrom')]],
            strokeWidth: ['', [Validators.required, Validators.min(0), Validators.pattern(RegexPatterns.NumericalNoDecimals)]],
            strokeColor: [''],
            strokeOpacity: ['', [Validators.required, Validators.min(0), Validators.max(1)]]
        })
    });

    /**
     * Form control getter.
     */
    public getFormControl = getFormControl;

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.spinner.show();
        this.solutionService.solutionConfig$
            .subscribe(solutionConfig => {
                this.updateData = solutionConfig;
                // The removeEmptyValues function makes sure that we won't have null/empty values anywhere to avoid storing unnecessary data.
                this.initialBuildingHighlightDisplayRule = removeEmptyValues(mergeObjects(
                    this.defaultBuildingHighlightDisplayRule,
                    removeEmptyValues(solutionConfig.buildingHighlightDisplayRule)
                ));

                this.buildingHighlightDisplayRuleForm.patchValue(this.initialBuildingHighlightDisplayRule, { emitEvent: false });
                this.spinner.hide();
            });

        this.buildingHighlightDisplayRuleKeyPaths = getKeyPaths(
            this.defaultBuildingHighlightDisplayRule
        ) as string[];

        this.initialFormControlStates = this.getFormControlsInitialState();

        this.buildingHighlightDisplayRuleForm.valueChanges.subscribe(() => {
            this.isBuildingHighlightDisplayRuleFormDirty = this.isFormDirty();
        });
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.selectedSolutionSubscription.unsubscribe();
    }

    /**
     * Get form controls initial states.
     *
     * @private
     * @returns {FormControlState[]}
     */
    private getFormControlsInitialState(): FormControlState[] {
        const initialStates: FormControlState[] = [];

        for (const keyPath of this.buildingHighlightDisplayRuleKeyPaths) {
            const formControl = this.getFormControl(this.buildingHighlightDisplayRuleForm, keyPath);
            if (formControl) {
                initialStates.push({
                    keyPath: keyPath,
                    value: formControl.value,
                    disabled: formControl.disabled
                });
            }
        }

        return initialStates;
    }

    /**
     * Check if any of the controls value or inheritance state has changed from the initial state.
     *
     * @private
     * @returns {boolean}
     */
    private isFormDirty(): boolean {
        const dirtyControls = this.buildingHighlightDisplayRuleKeyPaths
            .filter((keyPath) => this.getFormControl(this.buildingHighlightDisplayRuleForm, keyPath)?.dirty)
            .filter((keyPath) => {
                const formControl = this.getFormControl(this.buildingHighlightDisplayRuleForm, keyPath);
                const initialFormControlState = this.initialFormControlStates.find(state => state.keyPath === keyPath);
                return formControl.value !== initialFormControlState.value;
            });

        return dirtyControls.length > 0;
    }

    /**
     * Confirmation box when are discarding changes.
     *
     * @returns {boolean}
     */
    private confirmDiscardChanges(): boolean {
        // eslint-disable-next-line no-alert
        return confirm('You will lose your changes if you continue without saving. Do you want to continue?');
    }

    /**
     * When submitting save changes for the Solution Config.
     *
     * @returns {void}
     */
    public onSubmit(): void {
        const clonedFormValues = primitiveClone(this.buildingHighlightDisplayRuleForm.value);
        const clonedUpdateData = primitiveClone(this.updateData);
        clonedUpdateData.buildingHighlightDisplayRule = mergeObjects(
            clonedUpdateData.buildingHighlightDisplayRule,
            clonedFormValues
        ) as any;

        this.solutionService.updateSolutionConfig(clonedUpdateData)
            .subscribe(() => {
                this.notificationService.showSuccess('Changes saved successfuly!');
                this.initialBuildingHighlightDisplayRule = clonedUpdateData.buildingHighlightDisplayRule;
                this.initialFormControlStates = this.getFormControlsInitialState();
                this.buildingHighlightDisplayRuleForm.markAsPristine();
                this.isBuildingHighlightDisplayRuleFormDirty = false;
            }, () => {
                this.notificationService.showError('Something went wrong. Please try again.');
            });
    }

    /**
     * When discarding, revert all the changed made in the form.
     */
    public onDiscardChanges(): void {
        if (this.isBuildingHighlightDisplayRuleFormDirty && this.confirmDiscardChanges()) {
            this.buildingHighlightDisplayRuleForm.patchValue(this.initialBuildingHighlightDisplayRule, { emitEvent: false });
            this.buildingHighlightDisplayRuleForm.markAsPristine();
            this.isBuildingHighlightDisplayRuleFormDirty = false;
        }
    }
}