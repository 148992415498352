export interface DerivedGeometryData {
    geodataId: string;
    geodataType: string;
    type: DerivedGeometryType;
    geometry: GeoJSON.Geometry;
    floorIndex: number;
}

export class DerivedGeometry implements DerivedGeometryData {
    geodataId: string;
    geodataType: string;
    geometry: GeoJSON.Geometry;
    floorIndex: number;
    type: DerivedGeometryType;

    private constructor(args: DerivedGeometryData) {
        Object.assign(this, args);
    }

    /**
     * Create a DerivedGeometry from a JSON object.
     *
     * @static
     * @param {DerivedGeometryData} data
     * @returns {DerivedGeometry}
     * @memberof DerivedGeometry
     */
    static fromJSON(data: DerivedGeometryData): DerivedGeometry {
        return new DerivedGeometry(data);
    }
}


export enum DerivedGeometryType {
    WALL = 'wall',
    EXTRUSION = 'extrusion',
    FLOORPLAN = 'floorplan'
}
