<div fxLayout="column">
    <div fxLayout="row wrap">
        <mi-metric-card label="Venues" value="{{venues}}" error="{{venuesErr}}"
            tip="A Venue is a geographical area that contains one or more Buildings, such as a campus." spinner></mi-metric-card>
        <mi-metric-card label="Buildings" value="{{buildingsCount}}" error="{{buildingsErr}}"
            tip="A Building is part of a Venue and contains one or more Floors." spinner>
        </mi-metric-card>
        <mi-metric-card label="Floors" value="{{floorsCount}}" error="{{floorsErr}}"
            tip="A Floor is a level in a Building." spinner></mi-metric-card>
        <mi-metric-card label="Location types" value="{{locationTypesCount}}" error="{{locationTypesErr}}"
            tip="Location Types are classifications for Rooms and POIs, e.g. elevators, offices, and restrooms." spinner>
        </mi-metric-card>
        <mi-metric-card label="Categories" value="{{categoriesCount}}" error="{{categoriesErr}}"
            tip="Categories are used to group similar Rooms and POIs for easier searching in the app, e.g. meeting rooms and cafes." spinner>
        </mi-metric-card>
        <mi-metric-card label="POIs with alias" value="{{POIsWithAlias}}" error="{{POIsWithAliasErr}}"
            tip="Aliases are additional search terms for a POI. For example, an ATM POI may have a 'cash' and 'machine' alias." spinner>
        </mi-metric-card>
        <mi-metric-card label="POIs with category" value="{{POIsWithCategory}}" error="{{POIsWithCategoryErr}}"
            tip="Categories are used to group similar Rooms and POIs for easier searching in the app, e.g. meeting rooms and cafes." spinner>
        </mi-metric-card>
        <mi-metric-card label="Rooms with alias" value="{{roomsWithAlias}}" error="{{roomsWithAliasErr}}"
            tip="Aliases are additional search terms for a Room. For example, a lobby may have a 'reception' and 'entry' alias." spinner>
        </mi-metric-card>
        <mi-metric-card label="Rooms with category" value="{{roomsWithCategory}}" error="{{roomsWithCategoryErr}}"
            tip="Categories are used to group similar Rooms and POIs for easier searching in the app, e.g. meeting rooms and cafes." spinner>
        </mi-metric-card>
    </div>
    <div fxLayout="row wrap">
        <mi-tabs>
            <mi-tab label="Buildings" tab-for="buildings-panel"></mi-tab>
            <mi-tab label="Types" tab-for="types-panel"></mi-tab>
            <mi-tab label="Categories" tab-for="categories-panel"></mi-tab>
            <mi-tab-panel id="buildings-panel">
                <div class="filter-container">
                    <mi-dropdown multiple filterable label="Buildings" [items]="buildings_filters_buildings"
                        (change)="filterBuildingsTable()">
                    </mi-dropdown>
                    <div>
                        <span class="documentation-reference">
                            <a class="documentation-reference__link" href="https://docs.mapsindoors.com/content/cms/legacy/legacy-cms-dashboard/" target="_blank">Help</a>
                            <button type="button" class="mi-button mi-button--primary" (click)="exportBuildingsToCSV()">
                                Export CSV
                            </button>
                        </span>
                    </div>
                </div>
                <mi-data-table id="buildings-table" [rows]="buildingsViewData" max-rows="1000" style="z-index: 0">
                    <mi-column label="Solution name" binding="SolutionName" sortable></mi-column>
                    <mi-column label="Building address" binding="Address" sortable></mi-column>
                    <mi-column label="Floor name" binding="FloorName" sortable></mi-column>
                    <mi-column label="POIs with alias" binding="POIsWithAlias" sortable></mi-column>
                    <mi-column label="POIs with category" binding="POIsWithCategory" sortable></mi-column>
                    <mi-column label="Rooms with alias" binding="RoomsWithAlias" sortable></mi-column>
                    <mi-column label="Rooms with category" binding="RoomsWithCategory" sortable></mi-column>
                </mi-data-table>
            </mi-tab-panel>
            <mi-tab-panel id="types-panel">
                <div class="filter-container">
                    <div>
                        <mi-dropdown multiple filterable label="Buildings" [items]="types_filters_buildings"
                            (change)="filterTypesTable()">
                        </mi-dropdown>
                        <mi-dropdown multiple filterable label="Types" [items]="types_filters_types"
                            (change)="filterTypesTable()">
                        </mi-dropdown>
                    </div>
                    <button type="button" class="mi-button mi-button--primary" (click)="exportTypesToCSV()">
                        Export CSV
                    </button>
                </div>
                <mi-data-table id="types-table" [rows]="locationTypesViewData" max-rows="1000" style="z-index: 0">
                    <mi-column label="Type name" binding="TypeName" sortable></mi-column>
                    <mi-column label="Solution name" binding="SolutionName" sortable></mi-column>
                    <mi-column label="Building address" binding="Address" sortable></mi-column>
                    <mi-column label="Floor name" binding="FloorName" sortable></mi-column>
                    <mi-column label="POIs of type" binding="POIsOfType" sortable></mi-column>
                    <mi-column label="Rooms of type" binding="RoomsOfType" sortable></mi-column>
                </mi-data-table>
            </mi-tab-panel>
            <mi-tab-panel id="categories-panel">
                <div class="filter-container">
                    <div>
                        <mi-dropdown multiple filterable label="Buildings" [items]="categories_filters_buildings"
                            (change)="filterCategoriesTable()">
                        </mi-dropdown>
                        <mi-dropdown multiple filterable label="Categories" [items]="categories_filters_categories"
                            (change)="filterCategoriesTable()">
                        </mi-dropdown>
                    </div>
                    <button (click)="exportCategoriesToCSV()" class="mi-button mi-button--primary">
                        Export CSV
                    </button>
                </div>
                <mi-data-table id="categories-table" [rows]="categoriesViewData" max-rows="1000" style="z-index: 0">
                    <mi-column label="Category name" binding="CategoryName" sortable></mi-column>
                    <mi-column label="Solution name" binding="SolutionName" sortable></mi-column>
                    <mi-column label="Building address" binding="Address" sortable></mi-column>
                    <mi-column label="Floor name" binding="FloorName" sortable></mi-column>
                    <mi-column label="POIs of category" binding="POIsOfCategories" sortable></mi-column>
                    <mi-column label="Rooms of category" binding="RoomsOfCategories" sortable></mi-column>
                </mi-data-table>
            </mi-tab-panel>
        </mi-tabs>
    </div>
</div>
