import { DisplayRule, Location } from '../../locations/location.model';

import { Injectable } from '@angular/core';
import { LocationService } from '../../locations/location.service';
import { LocationType } from '../../location-types/location-type.model';
import { TypesService } from '../types.service';
import { merge } from '../../../utilities/Object';
import { SolutionService } from '../solution.service';
import { take } from 'rxjs/operators';
import { RouteElement } from '../../map/route-element-details/route-element.model';
import { RouteElementDisplayRuleFactory } from '../../network-access/RouteElementDisplayRuleFactory/RouteElementDisplayRuleFactory';
import { ImageSize } from '../../display-rule-details/image-size.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LabelTypeFormat } from '../../display-rule-details/label-format';

@Injectable({ providedIn: 'root' })
export class DisplayRuleService {
    #typeDisplayRules: Map<string, DisplayRule> = new Map();
    #locationDisplayRules: Map<string, DisplayRule> = new Map();
    #locationIdToTypeNameMap: Map<string, string> = new Map();
    #typeNameToTypeIdMap: Map<string, string> = new Map();
    #displayRuleOverrides: Map<string, DisplayRule> = new Map();
    #mainDisplayRule: DisplayRule;
    readonly #endpoint: string = environment.APIEndpoint;

    constructor(
        private locationService: LocationService,
        private typesService: TypesService,
        private solutionService: SolutionService,
        private http: HttpClient
    ) {
        this.locationService.locations$
            .subscribe(locations => {
                this.#locationDisplayRules = createDisplayRuleMap(locations);
                this.#locationIdToTypeNameMap = locations.reduce((map, location) => map.set(location?.id, location?.type), new Map());
            });

        this.typesService.types
            .subscribe(types => {
                this.#typeDisplayRules = createDisplayRuleMap(types);
                this.#typeNameToTypeIdMap = types.reduce((map, type) => map.set(type.administrativeId, type.id), new Map());
            });

        this.solutionService.solutionConfig$
            .subscribe(solutionConfig => {
                this.#mainDisplayRule = solutionConfig.mainDisplayRule;
            });
    }


    /**
     * Gets a display rule for a type.
     *
     * @private
     * @param {string} id - Type or Location id.
     * @returns {DisplayRule}
     */
    private getTypeDisplayRule(id: string): DisplayRule {
        if (this.#typeDisplayRules.has(id)) {
            return this.#typeDisplayRules.get(id);
        } else if (this.#typeNameToTypeIdMap.has(id)) {
            const typeId = this.#typeNameToTypeIdMap.get(id);
            return this.#typeDisplayRules.get(typeId);
        } else {
            const typeName = this.#locationIdToTypeNameMap.get(id);
            const typeId = this.#typeNameToTypeIdMap.get(typeName);
            return this.#typeDisplayRules.get(typeId);
        }
    }

    /**
     * Gets the main display rule.
     *
     * @private
     * @returns {Promise<DisplayRule>}
     * @memberof DisplayRuleService
     */
    private _getMainDisplayRule(): Promise<DisplayRule> {
        return new Promise(resolve => {
            if (this.#mainDisplayRule) {
                resolve(this.#mainDisplayRule);
            } else {
                this.solutionService.solutionConfig$
                    .pipe(take(1))
                    .subscribe(solutionConfig => resolve(solutionConfig.mainDisplayRule));
            }
        });
    }

    /**
     * Get a merged/compiled display rule.
     *
     * @param {string} id
     * @returns {DisplayRule}
     */
    async getDisplayRule(id?: string): Promise<DisplayRule> {
        const mainDisplayRule = await this._getMainDisplayRule();
        const typeDisplayRule = this.getTypeDisplayRule(id);
        const locationDisplayRule = this.#locationDisplayRules.get(id);
        const displayRules = [
            typeDisplayRule,
            locationDisplayRule,
            this.getDisplayRuleOverridesForLocationType(id),
            this.#displayRuleOverrides.get(id)
        ];
        let mergedDisplayRule = merge({}, mainDisplayRule, ...displayRules);

        return mergedDisplayRule;
    }

    /**
     * Returns the display rule with the new icon and its size.
     *
     * @param {DisplayRule} displayRule
     * @param {HTMLImageElement} icon
     * @returns {DisplayRule}
     */
    updateDisplayRuleIcon(displayRule: DisplayRule, icon: HTMLImageElement): DisplayRule {
        displayRule.icon = icon.src;
        displayRule.imageSize = {
            width: icon.naturalWidth,
            height: icon.naturalHeight
        };

        return displayRule;
    }

    /**
     * Temporary override DisplayRule.
     *
     * @param {string} id
     * @param {DisplayRule} displayRule
     */
    overrideDisplayRule(id: string, displayRule: DisplayRule): void {
        this.#displayRuleOverrides.set(id, displayRule);
    }

    /**
     * Get DisplayRule for a RouteElement.
     *
     * @param {RouteElement} input
     * @returns {DisplayRule}
     */
    async getRouteElementDisplayRule(input?: RouteElement): Promise<DisplayRule> {
        const mainDisplayRule = await this._getMainDisplayRule();
        const displayRules = [
            mainDisplayRule,
            RouteElementDisplayRuleFactory.create(input),
            this.#displayRuleOverrides.get(input?.id)
        ];

        return merge({}, ...displayRules);
    }

    /**
     * Revert the DisplayRule overrides.
     *
     * @param {string} id
     */
    revertDisplayRule(id: string): void {
        this.#displayRuleOverrides.delete(id);
    }

    /**
     * Gets the display rule overrides a locationType.
     *
     * @param {string} id - Location Id.
     * @returns {DisplayRule}
     */
    private getDisplayRuleOverridesForLocationType(id: string): DisplayRule {
        const location = this.locationService.getLocation(id);
        switch (location?.locationType?.toLowerCase()) {
            case 'poi':
                return { visible: true, iconVisible: true };
            case 'area':
                return { visible: true };
            case 'room':
                return { visible: true };
            default:
                return null;
        }
    }

    /**
     * Gets the display rule for the given location.
     *
     * @param {string | Location} input
     * @returns {DisplayRule}
     */
    getDisplayRuleForLocation(input?: string | Location): DisplayRule {
        const id: string = (input as Location)?.id ?? input as string;
        if (!id) {
            return;
        }
        return clone(this.#locationDisplayRules.get(id));
    }

    /**
     * Calculates the optimal size and bearing to fit inside the given polygon at the anchor while keeping the aspect ratio.
     *
     * @public
     * @async
     * @param {ImageSize} size
     * @param {GeoJSON.Polygon} polygon
     * @param {GeoJSON.Point} anchor
     * @returns {Promise<{ width: number, height: number, bearing: number }>}
     */
    public async fitToPolygon(size: ImageSize, polygon: GeoJSON.Polygon, anchor: GeoJSON.Point): Promise<{ width: number, height: number, bearing: number }> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return await this.http.post<{ width: number, height: number, bearing: number }>(`${this.#endpoint}api/fitrectinpolygon`, { ...size, polygon, anchor }, { headers: headers }).toPromise();
    }

    /**
     * The default display rule.
     *
     * @readonly
     * @returns {DisplayRule}
     * @static
     * @deprecated
     */
    static get MAIN_DISPLAY_RULE(): DisplayRule {
        return Object.freeze({
            visible: true,
            iconVisible: true,
            model2D: {
                visible: true,
                model: null,
                widthMeters: 0,
                heightMeters: 0,
                zoomTo: 22,
                zoomFrom: 16,
                bearing: 0
            },
            zoomFrom: 16.0,
            zoomTo: 22.0,
            icon: 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/misc/default-marker.png',
            imageSize: {
                width: 20.0,
                height: 20.0
            },
            imageScale: 1,
            labelVisible: true,
            label: '{{name}}',
            labelZoomFrom: 16.0,
            labelZoomTo: 22.0,
            labelMaxWidth: 0, // 0 means infinite
            labelType: LabelTypeFormat.TextLabel,
            labelStyle: {
                textSize: 12,
                textColor: '#000000',
                textOpacity: 0.9,
                haloColor: '#FFFFFF',
                haloWidth: 1,
                haloBlur: 1,
                bearing: 0,
                graphic: {
                    backgroundImage: null,
                    stretchX: null,
                    stretchY: null,
                    content: null
                }
            },
            polygon: {
                visible: false,
                zoomFrom: 18,
                zoomTo: 22,
                strokeWidth: 2.0,
                strokeColor: '#3071D9',
                strokeOpacity: 1.0,
                fillColor: '#3071D9',
                fillOpacity: 0.2
            },
            walls: {
                visible: true,
                zoomFrom: 16.0,
                zoomTo: 22.0,
                color: '#707a89',
                height: 2.0
            },
            extrusion: {
                visible: true,
                zoomFrom: 16.0,
                zoomTo: 22.0,
                color: '#aeb9cb',
                height: 2.25
            },
            model3D: {
                visible: true,
                zoomFrom: 16.0,
                zoomTo: 22.0,
                model: null,
                rotationX: 0,
                rotationY: 0,
                rotationZ: 0,
                scale: 1
            }
        });
    }

    /**
     * The default building highlight display rule.
     *
     * @readonly
     * @returns {any}
     * @static
     */
    static get BUILDING_HIGHLIGHT_DISPLAY_RULE(): any {
        return Object.freeze({
            polygon: {
                visible: true,
                zoomFrom: 15,
                zoomTo: 999,
                strokeWidth: 4.0,
                strokeColor: '#3B82F6', // Midt variable name is $color-blue-base.
                strokeOpacity: 1.0
            }
        });
    }
}

/**
 * Creates a clone of an object.
 *
 * @param {*} object
 * @returns {*}
 */
export function clone(object): any {
    return merge({}, object);
}

/**
 * Creates a Map of DisplayRules from Location or LocationType.
 *
 * @param {(Location[] | LocationType[])} input
 * @returns {Map<string, DisplayRule>}
 */
function createDisplayRuleMap(input: Array<Location | LocationType>): Map<string, DisplayRule> {
    return input.reduce((map: Map<string, DisplayRule>, object: Location | LocationType) => {
        if (object?.displayRule) {
            map.set(object.id, object.displayRule);
        }
        return map;
    }, new Map<string, DisplayRule>());
}

/**
 * Checks if the Location is active.
 *
 * @private
 * @param {Location} location
 * @returns {boolean}
 * @memberof LocationsMapService
 */
export function isLocationActive(location: Location): boolean {
    if (location?.activeTo) {
        const isActiveToValid = new Date() < new Date(location.activeTo);
        if (!isActiveToValid && location?.displayRule) {
            location.displayRule.zoomFrom = 15;
            location.displayRule.zoomTo = 22;
        }

        return isActiveToValid;
    }

    if (location?.activeFrom) {
        const isActiveFromValid = new Date() > new Date(location.activeFrom);
        if (!isActiveFromValid && location?.displayRule) {
            location.displayRule.zoomFrom = 15;
            location.displayRule.zoomTo = 22;
        }
        return isActiveFromValid;
    }

    return true;
}
