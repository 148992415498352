import { fromEvent, merge, Observable } from 'rxjs';
import { exhaustMap, filter, finalize, map, skipUntil, switchMap, takeUntil, tap } from 'rxjs/operators';

import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';

import { DisplayRule } from '../../locations/location.model';
import { GoogleMapsAdapter } from '../../MapAdapter/Google/GoogleMapsAdapter';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { GeodataEditor } from '../GeodataEditor';
import { SortKey } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { GoogleMaps2DModel } from '../../MapAdapter/Google/GoogleMaps2DModel';
import { FeatureClass } from '../../../viewmodels/FeatureClass';
import { Model2DViewModelProperties } from '../../../viewmodels/Model2DViewModel/Model2DViewModel';
import { LocationService } from '../../locations/location.service';
import { MouseButton } from '../../shared/enums/MouseButton';
import { MapMouseCursor } from '../../MapAdapter/BaseMapAdapter';
import { isKeyPressed } from '../../../utilities/rxjs';

const POLYGON_FILL_COLOR = midt['tailwind-colors'].blue[500].value;
const POLYGON_FILL_OPACITY = .5;
const STROKE_COLOR = midt['tailwind-colors'].blue[500].value;
const STROKE_WIDTH = 2;
const STROKE_OPACITY = 1;

export class GoogleMapsGeodataEditorAdapter extends GeodataEditor {
    private _mainDisplayRule: DisplayRule;
    private _featuresOnMap: Map<string | number, GeoJSON.Feature> = new Map();
    private _map: google.maps.Map;
    private _pointsLayer: google.maps.Data = new google.maps.Data();
    private _polygonsLayer: google.maps.Data = new google.maps.Data();
    private _handlesLayer: google.maps.Data = new google.maps.Data();
    private _midpointsLayer: google.maps.Data = new google.maps.Data();
    private _highlightLayer: google.maps.Data = new google.maps.Data();
    private _handlesStyle: google.maps.Data.StyleOptions = {
        icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 4,
            fillColor: midt['tailwind-colors'].white.value,
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: midt['tailwind-colors'].blue[500].value,
        },
        zIndex: SortKey.POINT + 1e7
    };
    private _model2Ds: Map<string, GoogleMaps2DModel> = new Map();

    constructor(
        public readonly adapter: GoogleMapsAdapter,
        protected displayRuleService: DisplayRuleService,
        protected locationService: LocationService
    ) {
        super(adapter, displayRuleService, locationService);

        this.adapter.getMap().then(async map => {
            this._map = map;
            this._mainDisplayRule = await this.displayRuleService.getDisplayRule();

            this._polygonsLayer.setValues({
                style: this.getFeatureStyle.bind(this),
                map: this._map
            });

            this._pointsLayer.setValues({
                style: this.getFeatureStyle.bind(this),
                map: this._map
            });

            this._handlesLayer.setValues({
                style: this._handlesStyle,
                map: this._map
            });

            this._midpointsLayer.setValues({
                style: {
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 3,
                        fillColor: midt['tailwind-colors'].white.value,
                        fillOpacity: .6,
                        strokeWeight: 1,
                        strokeColor: midt['tailwind-colors'].blue[500].value,
                        strokeOpacity: .6,
                    },
                    zIndex: SortKey.POINT + 1e7
                },
                map: this._map
            });

            // Layer for the highlight icon shown when editing Point geometries.
            this._highlightLayer.setValues({
                style: {
                    icon: {
                        url: 'assets/images/highlight_point.png',
                        scale: 4,
                        anchor: new google.maps.Point(17, 17)
                    },
                    fillOpacity: 0,
                    strokeColor: midt['tailwind-colors'].pink[400].value,
                    strokeOpacity: 1,
                    strokeWeight: 4,
                    draggable: false,
                    clickable: false,
                    zIndex: SortKey.POINT + 1e8
                },
                map: this._map
            });
        });
    }

    /**
     * Set the visibility of the polygon handles.
     *
     * @public
     * @param {boolean} visible
     */
    public showPolygonHandles(visible: boolean): void {
        this.isPolygonHandlesVisible = visible;
        this._handlesLayer?.setMap(visible ? this._map : null);
        this._midpointsLayer?.setMap(visible ? this._map : null);
    }

    /**
     * Set the visibility of the extrusion layer (For Mapbox only).
     *
     * @public
     */
    public showExtrusion(): void { }

    /**
     * Enable or disable pitching of the map (for Mapbox only).
     *
     * @public
     */
    public disablePitch(): void { }

    /**
     * For observing mouse move events, until a mouse up event is detected.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    public get mouseMoveUntilMouseUp$(): Observable<GeoJSON.Point> {
        return this.mouseMove$.pipe(
            tap(() => this.adapter.isClickable = false),
            takeUntil(this.mouseUp$),
            finalize(() => this.adapter.isClickable = true)
        );
    }

    /**
     * For observing mouse move events, until a mouse up event is detected.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    public get mouseMove$(): Observable<GeoJSON.Point> {
        return new Observable<GeoJSON.Point>(subscriber => {
            const mouseMoveHandler = async (e: MouseEvent): Promise<void> => {
                e.preventDefault();
                const rect = this._map.getDiv().getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                const point = await this.adapter.unproject({ x, y });

                subscriber.next(point);
            };

            this.adapter.getMap().then(map => {
                map.getDiv().addEventListener('mousemove', mouseMoveHandler);
            });

            subscriber.add(() => this._map.getDiv().removeEventListener('mousemove', mouseMoveHandler));
        });
    }

    /**
     * For observing mouse click events.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     */
    public get mouseClick$(): Observable<GeoJSON.Point> {
        return new Observable<GeoJSON.Point>(subscriber => {
            this.adapter.getMap().then(map => {
                const onClickListener = google.maps.event.addListener(map, 'click', ({ latLng }) => {
                    subscriber.next(this.#latLngToGeoJSONPoint(latLng));
                });
                subscriber.add(() => {
                    onClickListener.remove();
                });
            });
        });
    }

    /**
     * For observing mouse down events on the Linestrings.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     * @memberof GoogleMapsGeodataEditorAdapter
     * @returns {Observable}
     */
    public get mouseDownOnLineString$(): Observable<GeoJSON.Point> {
        return this.#mouseDown(this._polygonsLayer).pipe(
            tap(e => e.stop()),
            map(e => this.#latLngToGeoJSONPoint(e.latLng))
        );
    }

    /**
     * For observing mouse down events on the Points layer.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    public get mouseDownOnPointLayer$(): Observable<GeoJSON.Point> {
        return this.#mouseDown(this._pointsLayer)
            .pipe(
                tap(e => e.stop()),
                map(e => this.#latLngToGeoJSONPoint(e.latLng))
            );
    }

    /**
     * For observing mouse down events on the Polygon layer.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    public get mouseDownOnPolygonLayer$(): Observable<GeoJSON.Point> {
        return this.#mouseDown(this._polygonsLayer).pipe(
            tap(e => e.stop()),
            map(e => this.#latLngToGeoJSONPoint(e.latLng))
        );
    }

    /**
     * For observing mouse enter/off events on the Points layer.
     *
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    public mouseOverPointLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return this.mouseOverLayer(this._pointsLayer, hoverMouseCursorType);
    }

    /**
     * For observing mouse enter/off events on the Polygon layer.
     *
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    public mouseOverPolygonLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return this.mouseOverLayer(this._polygonsLayer, hoverMouseCursorType);
    }

    /**
     * For observing mouse enter/off events on the Point and Polygon layer.
     *
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    public mouseOverLocation$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return merge(
            this.mouseOverPointLayer$(hoverMouseCursorType),
            this.mouseOverPolygonLayer$(hoverMouseCursorType));
    }

    /**
     * For observing mouse enter/off events on the Handles layer.
     *
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    public mouseOverHandlesLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return this.mouseOverLayer(this._handlesLayer, hoverMouseCursorType);
    }

    /**
     * For observing mouse enter/off events on the Midpoints layer.
     *
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    public mouseOverMidpointsLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return this.mouseOverLayer(this._midpointsLayer, hoverMouseCursorType);
    }

    /**
     * Merging the mouseEnter and mouseLeave observers.
     * If provided, it changes the map cursor style of the layer when the cursor is on top of a feature.
     *
     * @param {google.maps.Data} layer
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    private mouseOverLayer(layer: google.maps.Data, hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return merge(this.#mouseOver(layer), this.#mouseOut(layer))
            .pipe(tap((feature: any) => {
                if (!hoverMouseCursorType) {
                    return;
                }

                feature ?
                    layer.overrideStyle(feature, { cursor: hoverMouseCursorType }) :
                    layer.revertStyle();
            }));
    }

    /**
     * For observing mouse down events on the Midpoints layer.
     *
     * @readonly
     * @type {Observable<{ position: GeoJSON.Point, insertAfter: number }>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    public get mouseDownOnMidpointsLayer$(): Observable<{ position: GeoJSON.Point, insertAfter: number }> {
        return this.#mouseDown(this._midpointsLayer)
            .pipe(
                tap(e => e.stop()),
                map(({ feature, latLng }) => ({
                    position: this.#latLngToGeoJSONPoint(latLng),
                    insertAfter: feature.getProperty('insertAfter') as number
                }))
            );
    }

    /**
     * For observing mouse down events on the Handles layer.
     *
     * @readonly
     * @type {Observable<{ position: GeoJSON.Point, index: number }>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    public get mouseDownOnHandlesLayer$(): Observable<{ position: GeoJSON.Point, index: number }> {
        return this.#mouseDown(this._handlesLayer)
            .pipe(
                filter(({ domEvent }) => (domEvent as MouseEvent).button === MouseButton.Main),
                tap(e => e.stop()),
                map(({ feature, latLng }) => ({
                    position: this.#latLngToGeoJSONPoint(latLng),
                    index: feature.getProperty('index') as number
                }))
            );
    }

    /**
     * For observing right/contextmenu click events on the Handles layer.
     *
     * @public
     * @readonly
     * @type {Observable<{ position: GeoJSON.Point; index: number; }>}
     */
    public get mouseContextmenuOnHandlesLayer$(): Observable<{ position: GeoJSON.Point; index: number; }> {
        return this.#mouseDown(this._handlesLayer)
            .pipe(
                filter(({ domEvent }) => (domEvent as MouseEvent).button === MouseButton.Secondary),
                tap(e => e.stop()),
                map(({ feature, latLng }) => ({
                    position: this.#latLngToGeoJSONPoint(latLng),
                    index: feature.getProperty('index') as number
                }))
            );
    }

    /**
     * For observing mouse up events.
     *
     * @private
     * @returns {Observable<Event>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    #mouseUp(): Observable<Event> {
        return fromEvent(document, 'mouseup');
    }

    /**
     * For observing mouse down events.
     *
     * @private
     * @param {google.maps.Data | google.maps.Map} target - The google maps data layer to attatch the event listner to.
     * @returns {Observable<google.maps.Data.MouseEvent>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    #mouseDown(target: google.maps.Data | google.maps.Map, preventDefault: boolean = false): Observable<google.maps.Data.MouseEvent> {
        return new Observable<google.maps.Data.MouseEvent>(subscriber => {
            const listener = target.addListener('mousedown', (e: google.maps.Data.MouseEvent) => {
                if (preventDefault) {
                    e.stop();
                }
                subscriber.next(e);
            });
            subscriber.add(() => listener.remove());
        });
    }

    /**
     * For observing mouse movements.
     *
     * @param {google.maps.Map} map
     * @returns {Observable<GeoJSON.Point>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    #mouseMove(map: google.maps.Map): Observable<GeoJSON.Point> {
        return new Observable(subscriber => {
            /**
             * Call next on subscriber with the pixel coordinates from a mouse event.
             *
             * @private
             * @param {MouseEvent} e
             */
            const emitCoordinates = async (e: MouseEvent): Promise<void> => {
                e.preventDefault();
                const rect = map.getDiv().getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                const point = await this.adapter.unproject({ x, y });

                subscriber.next(point);
            };

            map.getDiv().addEventListener('mousemove', emitCoordinates);

            subscriber.add(() => map.getDiv().removeEventListener('mousemove', emitCoordinates));
        });
    }


    /**
     * For observing mouse over events.
     *
     * @private
     * @param {google.maps.Data | google.maps.Map} target - The google maps data layer to attatch the event listner to.
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    #mouseOver(target: google.maps.Data | google.maps.Map): Observable<GeoJSON.Feature | void> {
        return new Observable((subscriber) => {
            target.addListener('mouseover', (event) => {
                subscriber.next(event.feature);
            });
        });
    }

    /**
     * For observing mouse out events.
     *
     * @private
     * @param {google.maps.Data | google.maps.Map} target - The google maps data layer to attatch the event listner to.
     * @returns {Observable<void>}
     */
    #mouseOut(target: google.maps.Data | google.maps.Map): Observable<void> {
        return new Observable((subscriber) => {
            target.addListener('mouseout', () => {
                subscriber.next();
            });
        });
    }

    /**
     * Renders handles for the polygon on the map.
     *
     * @private
     * @param {GeoJSON.Polygon} polygon
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    private renderPolygonHandles(polygon: GeoJSON.Polygon): void {
        const handles = this.isPolygonHandlesVisible ? GeodataEditor.convertToPoints(polygon) : [];

        handles.forEach(handle => {
            const feature = this._handlesLayer.getFeatureById(handle.id);
            const position = handle.geometry.coordinates;
            feature ? feature.setGeometry({ lat: position[1], lng: position[0] }) : this._handlesLayer.addGeoJson(handle);
        });

        this._handlesLayer.forEach(feature => {
            if (!handles.find(handle => handle.id === feature.getId())) {
                this._handlesLayer.remove(feature);
            }
        });
    }

    /**
     * Renders midpoints for the polygon on the map.
     *
     * @private
     * @param {GeoJSON.Polygon} polygon
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    private renderPolygonMidpoints(polygon: GeoJSON.Polygon): void {
        const midpoints = this.isPolygonHandlesVisible ? GeodataEditor.calculateMidpoints(polygon) : [];

        this._midpointsLayer.forEach(feature => {
            if (!midpoints.find(midpoint => midpoint.id === feature.getId())) {
                this._midpointsLayer.remove(feature);
            }
        });

        midpoints.forEach(midpoint => {
            const feature = this._midpointsLayer.getFeatureById(midpoint.id);
            const position = midpoint.geometry.coordinates;
            feature ? feature.setGeometry({ lat: position[1], lng: position[0] }) : this._midpointsLayer.addGeoJson(midpoint);
        });
    }

    /**
     * Returns a GeoJSON.Point when the map is clicked.
     *
     * @returns {Promise<GeoJSON.Point>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    drawPoint(): Observable<GeoJSON.Point> {
        return new Observable((subscriber) => {
            this.adapter.isClickable = false;
            this.adapter.getMap().then(map => {
                const onClickListener = google.maps.event.addListener(map, 'click', ({ latLng }) => {
                    subscriber.next(this.#latLngToGeoJSONPoint(latLng));
                });
                subscriber.add(() => {
                    onClickListener.remove();
                    this.adapter.isClickable = true;
                });
            });
        });
    }

    /**
     * Initialize freehand drawing mode.
     *
     * @protected
     * @returns {Observable<GeoJSON.Polygon>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    protected drawAreaFreehand(): Observable<GeoJSON.Polygon> {
        return new Observable<GeoJSON.Polygon>(subscriber => {
            const path = [];
            const feature = new google.maps.Data.Feature();
            const preview = new google.maps.Data.Feature();

            this.showPolygonHandles(true);
            this._handlesLayer.setStyle({ ...this._handlesStyle, draggable: false, clickable: false });
            this._polygonsLayer.setStyle({
                clickable: false,
                strokeColor: STROKE_COLOR,
                strokeWeight: STROKE_WIDTH,
                strokeOpacity: STROKE_OPACITY,
                fillColor: POLYGON_FILL_COLOR,
                fillOpacity: POLYGON_FILL_OPACITY,
                zIndex: SortKey.LINESTRING + 1e7
            });
            this._polygonsLayer.add(feature);
            this._polygonsLayer.add(preview);

            //Listen/subscribe to clicks on the map.
            const drawPointSubscription = this.drawPoint()
                .pipe(
                    tap(point => {
                        const index = path.length;
                        //Capture the point clicked and pushed on to the path array.
                        path.push(point.coordinates);
                        //Update the feature geometry.
                        feature.setGeometry(this.#toGeometry({ type: 'LineString', coordinates: path }));
                        //Create handles for each joint on the line.
                        const features = this._handlesLayer.addGeoJson({ type: 'Feature', geometry: point, properties: { index } });

                        //If this is the first point in the polygon make it clickable.
                        if (index === 0) {
                            this._handlesLayer.overrideStyle(features[0], { clickable: true });
                        }

                    }),
                    //Switch to listen for mouse movement, to show the "preview" of the next line segment.
                    switchMap(() => this.#mouseMove(this._map))
                ).subscribe(point => {
                    //Update the "preview" geometry.
                    if (path.length > 0) {
                        preview.setGeometry(this.#toGeometry({ type: 'LineString', coordinates: [path[path.length - 1], point.coordinates] }));
                    }
                });

            //Logic to close the polygon by clicking on the first point in the polygon.
            this._handlesLayer.addListener('click', (e: google.maps.Data.MouseEvent) => {
                const index = e.feature.getProperty('index') as number;
                if (index === 0 && path.length > 2) {
                    path.push(path[0]);
                    this.showPolygonHandles(false);
                    subscriber.next({ type: 'Polygon', coordinates: [path] });
                    subscriber.complete();
                }
            });

            //Teardown logic.
            subscriber.add(() => {
                this.showPolygonHandles(false);
                this._handlesLayer.forEach(google.maps.Data.prototype.remove.bind(this._handlesLayer));
                this._handlesLayer.setStyle(this._handlesStyle);

                this._polygonsLayer.forEach(google.maps.Data.prototype.remove.bind(this._polygonsLayer));
                this._polygonsLayer.setStyle(this.getFeatureStyle.bind(this));
            });

            subscriber.add(drawPointSubscription);
        });
    }

    /**
     * Initialize rectangular drawing mode.
     *
     * @protected
     * @returns {Observable<GeoJSON.Polygon>}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    protected drawAreaRectangular(): Observable<GeoJSON.Polygon> {
        return new Observable<GeoJSON.Polygon>(subscriber => {
            const preview = new google.maps.Data.Feature();
            let envelope: GeoJSON.Polygon;
            let isSpaceBarPressed = false;

            this.showPolygonHandles(true);
            this._polygonsLayer.add(preview);
            this._polygonsLayer.setStyle({
                clickable: false,
                strokeColor: STROKE_COLOR,
                strokeWeight: STROKE_WIDTH,
                strokeOpacity: STROKE_OPACITY,
                fillColor: POLYGON_FILL_COLOR,
                fillOpacity: POLYGON_FILL_OPACITY,
                zIndex: SortKey.LINESTRING + 1e7
            });

            //Subscribes to 'mousedown' events on the map.
            const onMouseDown = this.#mouseDown(this._map)
                .pipe(
                    tap(e => !isSpaceBarPressed && e.stop()),
                    filter(() => !isSpaceBarPressed),
                    map(({ latLng }) => this.#latLngToGeoJSONPoint(latLng)),
                    //Switch to listen for mouse movement until mouse up event is fired.
                    exhaustMap(p0 => this.#mouseMove(this._map)
                        .pipe(
                            filter(() => !isSpaceBarPressed),
                            takeUntil(this.#mouseUp().pipe(
                                tap((e) => {
                                    !isSpaceBarPressed && e.preventDefault();
                                    (document.activeElement as HTMLElement)?.blur();
                                }),
                                skipUntil(this.#mouseMove(this._map)),
                                filter(() => !isSpaceBarPressed))),
                            map(p1 => ({ p0, p1 })),
                            //Emit the final polygon on mouse up.
                            finalize(() => {
                                subscriber.next(envelope);
                                subscriber.complete();
                            })
                        )))
                .subscribe(({ p0, p1 }) => {
                    //Calculate the envelope that covers both points.
                    envelope = GeodataEditor.createEnvelope(p0, p1);
                    this.renderPolygonHandles(envelope);
                    //Update the geometry of the preview feature.
                    preview.setGeometry(this.#toGeometry(envelope));
                });

            //Teardown logic.
            subscriber.add(isKeyPressed('Space').subscribe((isPressed) => {
                isSpaceBarPressed = isPressed;
                isPressed ? this.adapter.setMapMouseCursor(MapMouseCursor.Default) : this.adapter.setMapMouseCursor(MapMouseCursor.Crosshair);
            }));

            subscriber.add(onMouseDown);
            subscriber.add(() => {
                this._polygonsLayer.forEach(google.maps.Data.prototype.remove.bind(this._polygonsLayer));
                this._polygonsLayer.setStyle(this.getFeatureStyle.bind(this));
                this.showPolygonHandles(false);
            });
        });
    }

    /**
     * Get the style options for the given feature.
     *
     * @private
     * @param {google.maps.Data.Feature} feature
     * @returns {google.maps.Data.StyleOptions}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    private getFeatureStyle(feature: google.maps.Data.Feature): google.maps.Data.StyleOptions {
        const viewModel = this._featuresOnMap.get(feature.getId());
        if (viewModel) {
            const iconUrl = viewModel.properties.src ?? 'assets/images/routelayer/location.defaultMarker.png';
            const iconAnchor = viewModel.properties.anchor;
            const iconSize = viewModel.properties.scaledSize;
            const fillColor = viewModel.properties.fillColor ?? POLYGON_FILL_COLOR;
            const fillOpacity = viewModel.properties.fillOpacity ?? POLYGON_FILL_OPACITY;
            const strokeColor = viewModel.properties.strokeColor ?? STROKE_COLOR;
            const strokeOpacity = viewModel.properties.strokeOpacity ?? STROKE_OPACITY;
            const strokeWeight = viewModel.properties.strokeWidth ?? STROKE_WIDTH;
            const clickable = viewModel.properties.clickable;
            const zIndex = viewModel.properties.sortKey;
            const cursor = MapMouseCursor.Grab;

            const icon = {
                url: iconUrl,
                anchor: iconAnchor,
                scaledSize: iconSize,
                labelOrigin: new google.maps.Point(20, 50)
            };

            return { icon, fillColor, fillOpacity, strokeColor, strokeOpacity, strokeWeight, clickable, zIndex, cursor };
        }

        return null;
    }

    /**
     * Set the data displayed on the map.
     *
     * @param {GeoJSON.Feature[]} features
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    protected setViewData(features: GeoJSON.Feature[]): void {
        this._featuresOnMap = new Map(features.map(feature => [feature.id, feature]));
        const polygon = features.find(feature => feature.properties.featureClass === FeatureClass.POLYGON) as GeoJSON.Feature<GeoJSON.Polygon>;

        this.renderPolygonHandles(polygon?.geometry);
        this.renderPolygonMidpoints(polygon?.geometry);

        this._pointsLayer.forEach(feature => {
            if (!this._featuresOnMap.has(feature.getId())) {
                this._pointsLayer.remove(feature);
            }
        });

        this._polygonsLayer.forEach(feature => {
            if (!this._featuresOnMap.has(feature.getId())) {
                this._polygonsLayer.remove(feature);
            }
        });

        this._highlightLayer.forEach(feature => {
            if (!this._featuresOnMap.has(feature.getId())) {
                this._highlightLayer.remove(feature);
            }
        });

        for (const [id, model2D] of this._model2Ds) {
            if (!this._featuresOnMap.has(id)) {
                model2D.setMap(null);
                this._model2Ds.delete(id);
            }
        }

        for (const feature of this._featuresOnMap.values()) {
            switch (feature.properties.featureClass) {
                case FeatureClass.POINT: {
                    const point = this._pointsLayer.getFeatureById(feature.id);
                    const geometry = feature.geometry as GeoJSON.Point;
                    if (point) {
                        point.setGeometry({ lat: geometry.coordinates[1], lng: geometry.coordinates[0] });
                    } else {
                        this._pointsLayer.addGeoJson(feature);
                    }
                    break;
                }
                case FeatureClass.LINESTRING:
                case FeatureClass.POLYGON: {
                    const polygon = this._polygonsLayer.getFeatureById(feature.id);
                    const geometry = feature.geometry as GeoJSON.Polygon | GeoJSON.LineString;
                    if (polygon) {
                        polygon.setGeometry(this.#toGeometry(geometry));
                    } else {
                        this._polygonsLayer.addGeoJson(feature);
                    }
                    break;
                }
                case FeatureClass.MODEL2D: {
                    const model2D = this._model2Ds.get(feature.id as string);
                    if (model2D) {
                        model2D.setPosition(feature.geometry as GeoJSON.Point);
                        model2D.setStyle(feature.properties as Model2DViewModelProperties);
                    } else {
                        const googleMaps2DModel = new GoogleMaps2DModel(feature as GeoJSON.Feature<GeoJSON.Point>, this._map);
                        this._model2Ds.set(feature.id as string, googleMaps2DModel);
                    }
                    break;
                }
                case FeatureClass.AREAASOBSTACLE:
                case FeatureClass.HIGHLIGHT: {
                    const highlight = this._highlightLayer.getFeatureById(feature.id);
                    if (highlight) {
                        highlight.setGeometry(this.#toGeometry(feature.geometry as GeoJSON.Point | GeoJSON.Polygon));
                    } else {
                        this._highlightLayer.addGeoJson(feature);
                    }
                    break;
                }
            }
        }

        this._pointsLayer.setStyle(this.getFeatureStyle.bind(this));
        this._polygonsLayer.setStyle(this.getFeatureStyle.bind(this));
    }

    /**
     * Convert a GeoJSON.Geometry to a google.maps.Data.Geometry.
     *
     * @private
     * @param {GeoJSON.Point | GeoJSON.MultiPoint | GeoJSON.Polygon | GeoJSON.MultiPolygon} geometry
     * @returns {google.maps.Data.Geometry}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    #toGeometry(geometry: GeoJSON.Point | GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon | GeoJSON.MultiPolygon): google.maps.Data.Geometry {
        switch (geometry.type) {
            case 'Point':
                return new google.maps.Data.Point({ lat: geometry.coordinates[1], lng: geometry.coordinates[0] });
            case 'LineString': {
                const elements = geometry.coordinates.map(coords => ({ lat: coords[1], lng: coords[0] }));
                return new google.maps.Data.LineString(elements);
            }
            case 'Polygon': {
                const arr = geometry.coordinates.map(ring => ring.map(coords => ({ lat: coords[1], lng: coords[0] })).slice(0, -1));
                return new google.maps.Data.Polygon(arr);
            }
        }
    }

    /**
     * Convert a google.maps.LatLng to a GeoJSON.Position.
     *
     * @private
     * @param {google.maps.LatLng} latLng
     * @returns {GeoJSON.Position}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    #latLngToArray(latLng: google.maps.LatLng): GeoJSON.Position {
        return [latLng.lng(), latLng.lat()];
    }

    /**
     * Convert a google.maps.LatLng to a GeoJSON.Point.
     *
     * @param {google.maps.LatLng} latLng
     * @returns {GeoJSON.Point}
     * @memberof GoogleMapsGeodataEditorAdapter
     */
    #latLngToGeoJSONPoint(latLng: google.maps.LatLng): GeoJSON.Point {
        return { type: 'Point', coordinates: this.#latLngToArray(latLng) };
    }
}