<form class="bulk-edit-form">
    <mat-toolbar class="top-toolbar">
        <button type="button" mat-raised-button color="primary" (click)="updateLocations()">
            Save and close
        </button>
        <h2>Edit {{locations.size}} location<span *ngIf="locations.size > 1">s</span></h2>
        <button type="button" mat-icon-button (click)="closeBulkEdit()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar>

    <div class="content">
        <section class="content__section">
            <div class="sub-content">
                <p>Selected properties will be set on the locations. Leave fields blank if you do not wish to change the property.</p>
                <p>Translated properties like name and description must be edited on each individual location.</p>
            </div>
        </section>

        <!-- Type -->
        <section class="content__section">
            <div class="content__section--header">
                <h3>Type</h3>
                <button type="button" mat-stroked-button *ngIf="selectedLocationType" (click)="selectedLocationType = null">
                    Clear selection
                </button>
            </div>
            <div class="sub-content">
                <button type="button" [matMenuTriggerFor]="typesMenu" mat-raised-button title="Select a Location Type">
                    <img class="button-image" [src]="selectedLocationType?.displayRule?.icon || ''" />
                    <span>{{selectedLocationType?.displayName || 'Select a Location Type'}}</span>
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
            </div>
            <mat-menu #typesMenu="matMenu">
                <div *ngFor="let type of locationTypes | orderBy:'displayName'">
                    <button mat-button type="button" (click)="selectedLocationType = type;">
                        <img class="button-image" [src]="type.displayRule?.icon || ''" />
                        {{type.displayName}}
                    </button>
                </div>
            </mat-menu>
        </section>

        <!-- Searchable -->
        <section class="content__section">
            <h3 class="content__section--header">Status</h3>
            <app-status-bit [geoObjects]="locations"></app-status-bit>
        </section>

        <!-- Categories -->
        <section class="content__section">
            <h3 class="content__section--header">Categories</h3>
            <div class="sub-content">
                <category-select [categories]="categories" 
                    [isMultipleLocations]="true"
                    (updatedLocationCategories)="bulkChanges.categories = $event">
                </category-select>
                <p><i>The selected locations might have categories already.</i></p>
                <p><i>Categories set here are added to the list, unless you remove all categories first.</i></p>
                <button (click)="bulkChanges.removeAllCategories = true" mat-raised-button 
                    [disabled]="bulkChanges.removeAllCategories">
                    <mat-icon>warning</mat-icon> Remove all categories
                </button>
            </div>
        </section>

        <!-- Search aliases -->
        <section class="content__section">
            <h3 class="content__section--header">Search aliases</h3>
            <div class="sub-content">
                <aliases (updatedAliasList)="bulkChanges.aliases = $event"></aliases>
                <p><i>The selected locations might have search aliases already.</i></p>
                <p><i>Aliases set here are added to the list, unless you remove all aliases first.</i></p>
                <button (click)="bulkChanges.removeAllAliases = true" mat-raised-button 
                    [disabled]="bulkChanges.removeAllAliases">
                    <mat-icon>warning</mat-icon> Remove all aliases
                </button>
            </div>
        </section>

        <!-- Active -->
        <section class="content__section">
            <div class="content__section--header">
                <h3>Active (display and searchable)</h3>
                <button type="button" mat-stroked-button *ngIf="bulkChanges.activeTo || bulkChanges.activeFrom" 
                    (click)="bulkChanges.activeFrom = null; bulkChanges.activeTo = null;">
                    Clear selection
                </button>
            </div>
            <div class="sub-content">           
                <mat-form-field>
                    <input [(ngModel)]="bulkChanges.activeFrom" name="activeFrom" matInput [matDatepicker]="fromPicker" placeholder="Active from" />
                    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #fromPicker></mat-datepicker>      
                <mat-form-field>
                    <input [(ngModel)]="bulkChanges.activeTo" name="activeTo" matInput [matDatepicker]="toPicker" placeholder="Active to">
                    <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #toPicker></mat-datepicker>       
            </div>
        </section>
    </div>
</form>