import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { createDropdownItemElement } from '../../shared/mi-dropdown/mi-dropdown';
import { MediaLibraryService } from '../media-library.service';
import { MediaCategory, MediaFileType, MediaSortBy } from '../media.enum';
import { MediaFilterOptions } from './media-filter-options.model';
import { extendStringWithNumberInParentheses } from '../../../utilities/string';

enum FilterNames {
    Sort = 'Sort',
    FileType = 'File Type',
    Category = 'Filter'
}

@Component({
    selector: 'media-filter-bar',
    templateUrl: './media-filter-bar.component.html',
    styleUrls: ['./media-filter-bar.component.scss']
})

export class MediaFilterBarComponent implements OnInit {
    @ViewChild('searchInput', { static: true }) searchInputElement: ElementRef<HTMLInputElement>;

    @Input() defaultFilter: MediaFilterOptions;
    @Input() isComponentShown: boolean;

    public sortDropDownItems: HTMLMiDropdownItemElement[] = [];
    public fileTypeDropDownItems: HTMLMiDropdownItemElement[] = [];
    public mediaCategoryDropDownItems: HTMLMiDropdownItemElement[] = [];
    public sortLabel: string = FilterNames.Sort;
    public fileTypeLabel: string = FilterNames.FileType;
    public categoryLabel: string = FilterNames.Category;

    private activeFilters: MediaFilterOptions = {};
    private readonly sortOptions: string[] = [MediaSortBy.Recent, MediaSortBy.Oldest, MediaSortBy.AZ, MediaSortBy.ZA];
    private readonly fileTypeOptions: string[] = [MediaFileType.SVG, MediaFileType.PNG, MediaFileType.JPG, MediaFileType.GLB];
    private readonly mediaCategoryOptions: string[] = [MediaCategory.Image, MediaCategory.MIIcon, MediaCategory.MILabel];

    constructor(
        private mediaLibraryService: MediaLibraryService
    ) { }

    /**
     * Fills all dropdown elements with options.
     */
    ngOnInit(): void {
        this.sortDropDownItems = this.fillInSortDropdown(this.sortOptions);
        this.fileTypeDropDownItems = this.fillInTypeDropdown(this.fileTypeOptions);
        this.mediaCategoryDropDownItems = this.fillInFiltersDropdown(this.mediaCategoryOptions);

        this.activeFilters = this.defaultFilter;

        this.fileTypeLabel = extendStringWithNumberInParentheses(FilterNames.FileType, this.activeFilters?.fileTypes?.length);
        this.categoryLabel = extendStringWithNumberInParentheses(FilterNames.Category, this.activeFilters?.mediaCategory?.length);
    }

    /**
     * Returns the list of sort options.
     *
     * @param {string[]} filterOptions
     * @returns {HTMLMiDropdownItemElement[]}
     */
    private fillInSortDropdown(filterOptions: string[]): HTMLMiDropdownItemElement[] {
        return filterOptions.map(filter => createDropdownItemElement({ label: filter, value: filter }));
    }

    /**
     * Returns the list of file type options.
     *
     * @param {string[]} filterOptions
     * @returns {HTMLMiDropdownItemElement[]}
     */
    private fillInTypeDropdown(filterOptions: string[]): HTMLMiDropdownItemElement[] {
        return filterOptions.map(
            (filter) => {
                const selected = (this.defaultFilter?.fileTypes as Array<string>)?.indexOf(filter) > -1 ? true : false;
                return createDropdownItemElement({ label: filter, value: filter, selected });
            }
        );
    }

    /**
     * Returns the list of filter options.
     *
     * @param {string[]} filterOptions
     * @returns {HTMLMiDropdownItemElement[]}
     */
    private fillInFiltersDropdown(filterOptions: string[]): HTMLMiDropdownItemElement[] {
        return filterOptions.map(
            (filter) => {
                const selected = (this.defaultFilter?.mediaCategory as Array<string>)?.indexOf(filter) > -1 ? true : false;
                return createDropdownItemElement({ label: filter, value: filter, selected });
            }
        );
    }

    /**
     * Update filters when user selects new Sorting option.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof MediaFilterBarComponent
     */
    public onSortChange({ detail }: CustomEvent): void {
        const values = (detail as HTMLMiDropdownItemElement[]).map(item => item.value);
        this.activeFilters.sortBy = values as any;
        this.mediaLibraryService.setActiveFilter(this.activeFilters);
    }

    /**
     * Update fileTypes when user selects new File types.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof MediaFilterBarComponent
     */
    public onFileTypeChange({ detail }: CustomEvent): void {
        const values = (detail as HTMLMiDropdownItemElement[]).map(item => item.value);
        this.activeFilters.fileTypes = values as any;
        this.mediaLibraryService.setActiveFilter(this.activeFilters);

        this.fileTypeLabel = extendStringWithNumberInParentheses(FilterNames.FileType, this.activeFilters?.fileTypes?.length);
    }

    /**
     * Update filters when user selects new Filter option.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof MediaFilterBarComponent
     */
    public onFilterChange({ detail }: CustomEvent): void {
        const values = (detail as HTMLMiDropdownItemElement[]).map(item => item.value);
        this.activeFilters.mediaCategory = values as any;
        this.mediaLibraryService.setActiveFilter(this.activeFilters);

        this.categoryLabel = extendStringWithNumberInParentheses(FilterNames.Category, this.activeFilters?.mediaCategory?.length);
    }

    /**
     * Update searchQuery when user inputs a new Query.
     *
     * @param {InputEvent} event
     */
    public onSearchChange(event: InputEvent): void {
        this.activeFilters.searchQuery = (event.target as HTMLInputElement).value;
        this.mediaLibraryService.setActiveFilter(this.activeFilters);
    }

    /**
     * When the 'Escape' key is pressed, it clears the search input field.
     */
    public onKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Escape' && this.activeFilters.searchQuery) {
            event.preventDefault();
            event.stopImmediatePropagation();

            this.activeFilters.searchQuery = '';
            this.searchInputElement.nativeElement.value = '';
            this.mediaLibraryService.setActiveFilter(this.activeFilters);
        }
    }
}
