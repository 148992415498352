<div class="map-toolbar">
    <div [ngClass]="{'hidden': !isMapsIndoorsToolsVisible}">
        <ng-container #mapToolsContainer></ng-container>
    </div>
    <button class="map-toolbar__control map-toolbar__control--satellite"
            [ngClass]="{'map-toolbar__control--active': mapType === MapType.HYBRID}"
            *ngIf="currentTool !== editGraphBoundsComponent || isToolbarDefault"
            (click)="toggleSatelliteView()">
            <span class="tooltip">{{ isRoadMapVisible ? 'Show Normal map' : 'Show Satellite map' }}</span></button>
    <button *ngIf="is2DToggleVisible"
            class="map-toolbar__control map-toolbar__control--2d map-toolbar__control--active"
            [ngClass]="{'map-toolbar__control--active': getVisibility('2D')}"
            (click)="toggleVisibility('2D')">
            <span class="tooltip">{{ is2DModelVisible ? 'Show 2D' : 'Hide 2D' }} models</span></button>
    <button *ngIf="is3DToggleVisible"
            class="map-toolbar__control map-toolbar__control--3d map-toolbar__control--active"
            [ngClass]="{'map-toolbar__control--active': getVisibility('3D')}"
            (click)="toggleVisibility('3D')">
            <span class="tooltip">{{ is3DModelVisible ? 'Show 3D' : 'Hide 3D' }} features</span></button>
    <span class="map-toolbar__divider"></span>
    <div class="zoom-controls">
        <button type="button" aria-label="Zoom out" class="map-toolbar__control" (click)="decreaseZoomLevel()">
            <span class="tooltip">Zoom out</span>
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                      d="M0.00155635 6.00002C0.0060888 5.70947 0.145825 5.43188 0.390991 5.22641C0.636157 5.02093 0.967369 4.90382 1.31406 4.90002L10.6891 4.90002C11.0357 4.90382 11.367 5.02094 11.6121 5.22641C11.8573 5.43188 11.997 5.70947 12.0016 6.00002C11.997 6.29058 11.8573 6.56817 11.6121 6.77364C11.367 6.97911 11.0357 7.09623 10.6891 7.10002L1.31406 7.10002C0.967369 7.09623 0.636157 6.97911 0.390991 6.77364C0.145825 6.56817 0.00608877 6.29058 0.00155635 6.00002Z"
                      fill="#1E2025" />
            </svg>
        </button>
        <span class="zoom-controls__level">{{ zoomLevel }}</span>
        <button type="button" aria-label="Zoom in" class="map-toolbar__control" (click)="increaseZoomLevel()">
            <span class="tooltip">Zoom in</span>
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                      d="M6.00158 12C5.71102 11.9955 5.43344 11.8557 5.22796 11.6106C5.02249 11.3654 4.90538 11.0342 4.90158 10.6875V1.3125C4.90538 0.965813 5.02249 0.6346 5.22796 0.389434C5.43344 0.144268 5.71102 0.00453244 6.00158 0C6.29214 0.00453244 6.56973 0.144268 6.7752 0.389434C6.98067 0.6346 7.09778 0.965813 7.10158 1.3125V10.6875C7.09778 11.0342 6.98067 11.3654 6.7752 11.6106C6.56973 11.8557 6.29214 11.9955 6.00158 12Z"
                      fill="#1E2025" />
                <path
                      d="M0.00155635 6.00002C0.0060888 5.70947 0.145825 5.43188 0.390991 5.22641C0.636157 5.02093 0.967369 4.90382 1.31406 4.90002L10.6891 4.90002C11.0357 4.90382 11.367 5.02094 11.6121 5.22641C11.8573 5.43188 11.997 5.70947 12.0016 6.00002C11.997 6.29058 11.8573 6.56817 11.6121 6.77364C11.367 6.97911 11.0357 7.09623 10.6891 7.10002L1.31406 7.10002C0.967369 7.09623 0.636157 6.97911 0.390991 6.77364C0.145825 6.56817 0.00608877 6.29058 0.00155635 6.00002Z"
                      fill="#1E2025" />
            </svg>
        </button>
    </div>
    <span *ngIf="mapAdapterType === MapAdapterType.MapboxAdapter" class="map-toolbar__divider"></span>
    <button *ngIf="mapAdapterType === MapAdapterType.MapboxAdapter"
            class="map-toolbar__control map-toolbar__control--reset-rotation-and-tilt-container"
            (click)="resetRotateAndTilt()">
            <span #resetRotateAndTiltButton></span>
            <span class="tooltip">Reset map rotation and tilt</span></button>
</div>