<form [formGroup]="tilesForm" (ngSubmit)="onSubmit()">
    <div class="dialog-header" fxLayout="row" fxLayoutAlign="start center">
        <h3>Tiles Manager</h3>
        <div fxFlex></div>
        <mat-icon mat-dialog-close class="close-icon">close</mat-icon>
    </div>

    <div class="dialog-content">
        <h4>Tiles URL</h4>
        <!-- Switch logic -->
        <mat-slide-toggle matTooltip="Adds/removes the mapsindoors prefix and suffix from the url."
            [checked]="useCustomUrl" (change)="onUseCustomUrlToggle()">
            Use custom domain
        </mat-slide-toggle>
        <div class="message message--info" role="note" *ngIf="useCustomUrl">
            The Tiles url needs to be a valid URL. It must include your domain name and the path where your tiles
            files are located.<br />
            For example: <i>https://mydomain.com/mytiles</i>
        </div>
        <div class="tiles-url">
            <div class="tiles-url__input" [ngClass]="{'tiles-url__input--with-prefix': !useCustomUrl}">
                <span *ngIf="!useCustomUrl">{{tilesUrlStart}}</span>
                <input class="mi-input" type="text" formControlName="urlPath" />
            </div>
            <small role="alert" class="mi-form__error" *ngIf="urlPath.hasError('required')">Path is required</small>
            <small role="alert" class="mi-form__error" *ngIf="urlPath.hasError('pattern') && !urlPath.hasError('required')">No whitespace
                allowed</small>
            <small role="alert" class="mi-form__error" *ngIf="urlPath.hasError('invalidUrl')">Invalid url. Must include 'https://', at least one '.' character and cannot end with a '/'.</small>
            <span class="tiles-url__info">Do not enter this last path: {{tilesUrlEnd}}</span>
            <span class="tiles-url__info">The full url is: {{ fullUrl }}</span>
        </div>

        <h4>Tile styles</h4>
        <div formArrayName="styles" fxLayout="column">
            <div class="style" fxLayout="row" [formGroupName]="i" fxFlex
                *ngFor="let style of styles.controls; let i=index">
                <mat-form-field>
                    <input matInput type="text" formControlName="displayName" placeholder="Style Name"
                        [maxlength]="maxLength" required />
                    <mat-hint align="end">{{style.value.displayName.length}} / {{maxLength}}</mat-hint>
                    <mat-error *ngIf="style.get('displayName').hasError('required')">Name is required</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input matInput type="text" formControlName="folder" placeholder="Tile resource folder"
                        [maxlength]="maxLength" required pattern="[^\s]+" />
                    <mat-hint align="end">{{style.value.folder.length}} / {{maxLength}}</mat-hint>
                    <mat-error *ngIf="style.get('folder').hasError('required')">Folder is required</mat-error>
                    <mat-error
                        *ngIf="style.get('folder').hasError('pattern') && !style.get('folder').hasError('required')">No
                        whitespace allowed</mat-error>
                </mat-form-field>
                <button type="button" title="Delete style" mat-icon-button *ngIf="styles.controls.length > 0"
                    (click)="styles.removeAt(i)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>

        <div class="new-style-btn">
            <button mat-raised-button type="button" (click)="addStyle()">New Style</button>
        </div>
    </div>

    <div class="dialog-footer" fxLayout="row" fxLayoutAlign="start center">
        <button type="submit" class="mi-button mi-button--primary" [disabled]="!tilesForm.valid">
            Save and close
        </button>
    </div>
</form>