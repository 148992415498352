import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { createDropdownItemElement } from '../../mi-dropdown/mi-dropdown';
import { from, Observable, of, Subscription } from 'rxjs';
import { OccupantTemplateService } from '../../../services/OccupantServices/occupant-template.service';
import { SimplifiedOccupantTemplate } from '../../../services/OccupantServices/simplifiedOccupantTemplate.model';
import { OccupantService } from '../../../services/OccupantServices/occupant.service';
import { LocationService } from '../../../locations/location.service';
import { switchMap, filter, map, tap } from 'rxjs/operators';
import { Location } from '../../../locations/location.model';
import { OccupantTemplate } from '../../../services/OccupantServices/occupantTemplate.model';
import { primitiveClone } from '../../object-helper';
import { isNullOrUndefined } from '../../../../utilities/Object';
import { debounce } from 'throttle-debounce';

const MAX_OCCUPANT_TEMPLATES = 100;
@Component({
    selector: 'occupant-template-dropdown',
    templateUrl: './occupant-template-dropdown.component.html',
    styleUrls: ['./occupant-template-dropdown.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OccupantTemplateDropdownComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => OccupantTemplateDropdownComponent),
        multi: true,
    }]
})
export class OccupantTemplateDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    @ViewChild('occupantTemplateDropdown', { static: true }) occupantTemplateDropdownElement: ElementRef<HTMLMiDropdownElement>;

    constructor(
        private occupantTemplateService: OccupantTemplateService,
        private occupantService: OccupantService,
        private locationService: LocationService
    ) { }

    public onChange: (occupantTemplate: string) => void = () => { };
    public onTouch: () => void = () => { };

    public isDropDownSelectionValid: boolean = true;

    private subscription: Subscription = new Subscription();
    private occupantTemplateId: string;
    private occupantTemplates: SimplifiedOccupantTemplate[];
    private dropdownItems: HTMLMiDropdownItemElement[];
    private selectedDropdownItem: HTMLMiDropdownItemElement;
    private dropdownSearchArray = [];
    private selectedSimplifiedOccupantTemplate: SimplifiedOccupantTemplate;

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.subscription.add(
            this.locationService.selectedLocation$
                .pipe(
                    // React on changing the location.
                    switchMap((location) => this.handleLocationChange(location)),
                    filter((result) => result !== null),
                    switchMap(({ occupantTemplate }) => this.occupantTemplateService.getSimplifiedOccupantTemplate(occupantTemplate.id)),
                )
                .subscribe((simplifiedOccupantTemplate) => {
                    if (simplifiedOccupantTemplate) {
                        this.dropdownItems = this.createDropdownItems([simplifiedOccupantTemplate]);
                        this.selectedSimplifiedOccupantTemplate = primitiveClone(simplifiedOccupantTemplate);

                        if (this.occupantTemplateId) {
                            this.selectDropdownItem();
                        }

                        this.occupantTemplateDropdownElement.nativeElement.items = this.dropdownItems;
                    }
                })
        );
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Handle location change. It is responsible for getting the current Occupant Template, e.g., switching locations.
     *
     * @param {Location} location - Location object.
     * @returns {Observable<null | { location: Location, res: OccupantTemplate }>}
     */
    private handleLocationChange(location: Location): Observable<null | { location: Location, occupantTemplate: OccupantTemplate }> {
        if (!location) {
            return of();
        }

        const occupant = this.occupantService.getOccupantByLocationId(location.id);

        if (!occupant) {
            return this.occupantTemplateService.getOccupantTemplatesPaginated(0, MAX_OCCUPANT_TEMPLATES)
                .pipe(
                    tap(({ items }) => this.updateDropdown(items)),
                    map(() => null)
                );
        } else {
            return from(this.occupantTemplateService.getOccupantTemplate(occupant.occupantTemplateId))
                .pipe(
                    map((occupantTemplate) => ({ location, occupantTemplate })),
                );
        }
    }

    /**
     * Update dropdown for a Location that does not have any Occupant.
     *
     * @param {SimplifiedOccupantTemplate} items
     */
    private updateDropdown(items: SimplifiedOccupantTemplate[]): void {
        this.occupantTemplates = items.sort((a, b) => a.name.localeCompare(b.name));
        const defaultDropdownItem = createDropdownItemElement({ label: 'Select Template', value: null });
        this.dropdownItems = [defaultDropdownItem, ...this.createDropdownItems(this.occupantTemplates)];

        if (this.occupantTemplateId) {
            this.selectDropdownItem();
        }

        this.occupantTemplateDropdownElement.nativeElement.items = this.dropdownItems;
    }

    /**
     * Creates the dropdown items.
     *
     * @param {SimplifiedOccupantTemplate[]} occupantTemplates
     * @returns {HTMLMiDropdownItemElement[]}
     */
    private createDropdownItems(occupantTemplates: SimplifiedOccupantTemplate[]): HTMLMiDropdownItemElement[] {
        const dropdownItems = [];

        for (const occupantTemplate of occupantTemplates) {
            dropdownItems.push(createDropdownItemElement({ label: occupantTemplate.name, value: occupantTemplate.id }));
        }

        return dropdownItems;
    }

    /**
     * Sets the selected dropdown item.
     */
    private selectDropdownItem(): void {
        if (this.dropdownItems?.length > 0) {
            this.selectedDropdownItem = this.dropdownItems.find(dropdownItem => dropdownItem.value === this.occupantTemplateId);

            if (this.selectedDropdownItem) {
                this.selectedDropdownItem.selected = true;
                this.onChange(this.occupantTemplateId);
            }
        }
    }

    /**
     * On occupant template dropdown change.
     *
     * @param {CustomEvent} event
     */
    public onOccupantTemplateDropdownChange(event: CustomEvent): void {
        this.occupantTemplateId = event.detail[0].value;
        this.selectedDropdownItem = this.dropdownItems.find(dropdownItem => dropdownItem.value === this.occupantTemplateId);
        this.onChange(this.occupantTemplateId);
        this.dropdownSearchArray = [];
    }

    /**
     * WriteValue.
     *
     * @param {string} value
     */
    writeValue(value: string): void {
        this.occupantTemplateId = value;

        //When creating a new occupant, the value is null, so we need to load some occupant templates, to fill the dropdown.
        if (value === null) {
            this.occupantTemplateService.getOccupantTemplatesPaginated(0, MAX_OCCUPANT_TEMPLATES).subscribe((occupantTemplates) => {
                this.updateDropdown(occupantTemplates?.items);
            });
        }

        if (this.occupantTemplates?.length > 0) {
            this.selectDropdownItem();
        }
    }

    /**
     * RegisterOnChange.
     *
     * @param {(occupantTemplateId: string) => void} fn
     */
    registerOnChange(fn: (occupantTemplateId: string) => void): void {
        this.onChange = fn;
    }

    /**
     * RegisterOnTouched.
     *
     * @param {() => void} fn
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Validate.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        this.isDropDownSelectionValid = !!this.selectedDropdownItem && this.selectedDropdownItem?.value !== null;
        return this.isDropDownSelectionValid ? {} : { invalid: true };
    }

    /**
     * When user clics outside of the dropdown. Clear the array.
     *
     * @param {any} event
     */
    @HostListener('document:click', ['$event'])
    handleClick(event: any): void {
        if (!this.occupantTemplateDropdownElement.nativeElement.contains(event.target)) {
            this.dropdownSearchArray = [];
            this.occupantTemplateDropdownElement.nativeElement.clearFilter();

            if (!isNullOrUndefined(this.selectedSimplifiedOccupantTemplate)) {
                this.occupantTemplateDropdownElement.nativeElement.items = this.createDropdownItems([this.selectedSimplifiedOccupantTemplate]);
            }
        }
    }

    /**
     * React on changes inside an input field. Based on that get Occupant Templates that are matching desired word.
     *
     * @param {any} event
     */
    public onInputChange(event: any): void {
        if (!this.dropdownSearchArray) {
            this.dropdownSearchArray = [];
        }

        const inputElementValue = event.target.shadowRoot.querySelector('input').value;

        if (event.data !== null) {
            this.dropdownSearchArray.push(event.data);
        } else {
            // Logic to listen for an individual click on the keyboard and creating a word out of it.
            if (this.dropdownSearchArray.length > 0) {
                let lastElement = this.dropdownSearchArray[this.dropdownSearchArray.length - 1];
                lastElement = lastElement.slice(0, -1);

                if (lastElement.length === 0) {
                    this.dropdownSearchArray.pop();
                } else {
                    this.dropdownSearchArray[this.dropdownSearchArray.length - 1] = lastElement;
                }
            }
        }

        // In case someone selects all and deletes at once, we need to make sure array is empty so new results can show up.
        if (event?.data === null && event?.inputType === 'deleteContentBackward' && inputElementValue === '') {
            this.dropdownSearchArray = [];
        }

        // Final result of typing on the keyboard - a word.
        const query = this.dropdownSearchArray.join('');

        // Filter all Occupant Templates based on a given word. Present result with maximum length of 200.
        // Create a dropdown from it.
        this.populateOccupantTemplateDropdown(query);

    }

    /**
     * Clear input field search.
     */
    public clear(): void {
        this.dropdownSearchArray = [];
    }

    private populateOccupantTemplateDropdown = debounce(200, (query: string) => {
        this.occupantTemplateService.getOccupantTemplatesPaginated(0, MAX_OCCUPANT_TEMPLATES, query).subscribe((occupantTemplates) => {
            this.occupantTemplates = occupantTemplates.items;
            this.dropdownItems = this.createDropdownItems(this.occupantTemplates);

            if (this.occupantTemplateId) {
                this.selectDropdownItem();
            }

            this.occupantTemplateDropdownElement.nativeElement.items = this.dropdownItems;
        });
    });
}