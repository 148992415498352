import { Component, EventEmitter, HostListener, Output, Type } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BuildingService } from '../../../../buildings/building.service';
import { NetworkService } from '../../../../network-access/network.service';
import { NotificationService } from '../../../../services/notification.service';
import { UserService } from '../../../../services/user.service';
import { VenueService } from '../../../../venues/venue.service';
import { MapAdapterMediator } from '../../../map-adapter.mediator';
import { MapToolbar } from '../../map-toolbar.interface';
import { AddBarrierComponent } from './add-barrier/add-barrier.component';
import { AddEntryPointComponent } from './add-entry-point/add-entry-point.component';
import { AddFloorConnectorComponent } from './add-floor-connector/add-floor-connector.component';
import { AddDoorComponent } from './add-door/add-door.component';
import { GraphSetup } from '../../../../shared/enums/GraphSetup.enum';
import { Venue } from '../../../../venues/venue.model';
import { Floor } from '../../../../buildings/floor.model';
import { EditGraphBoundsComponent } from './edit-graph-bounds/edit-graph-bounds.component';
import { MapSidebarService } from '../../../map-sidebar/map-sidebar.service';
import { GraphListComponent } from '../../../graph-list/graph-list.component';
import { AddMultipleDoorsComponent } from './add-multiple-doors/add-multiple-doors.component';
import { BaseMapAdapter } from '../../../../MapAdapter/BaseMapAdapter';
import { AddMultipleDoorsFreehandComponent } from './add-multiple-doors-freehand/add-multiple-doors-freehand.component';
import { EditGraphComponent } from './edit-graph/edit-graph.component';

@Component({
    selector: 'route-network-tools',
    templateUrl: './route-network-tools.component.html',
    styleUrls: ['./route-network-tools.component.scss']
})
export class RouteNetworkToolsComponent {
    #mapAdapter: BaseMapAdapter;

    private isRouteNetworkVisible: boolean = false;
    private currentVenue: Venue;
    private currentFloor: Floor;
    private graphIds: string[] = [];
    private showNotificationWhenNetworkIsLoaded: boolean = false;

    public routeNetworkChanged: boolean = false;
    public isOwner: boolean = false;
    public isAdmin: boolean = false;
    public isRegenerateButtonVisible: boolean = true;

    public readonly Tools = {
        EditGraphComponent,
        AddBarrierComponent,
        AddFloorConnectorComponent,
        AddEntryPointComponent,
        AddDoorComponent,
        EditGraphBoundsComponent,
        AddMultipleDoorsComponent,
        AddMultipleDoorsFreehandComponent
    };

    @Output() toolSelected = new EventEmitter<Type<MapToolbar>>();

    constructor(
        private venueService: VenueService,
        private buildingService: BuildingService,
        private networkService: NetworkService,
        private mapAdapterMediator: MapAdapterMediator,
        private userService: UserService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        private mapSidebar: MapSidebarService
    ) {
        this.#mapAdapter = this.mapAdapterMediator.getMapAdapter();

        this.#mapAdapter.routeNetworkVisibility$.subscribe(isVisible => this.isRouteNetworkVisible = isVisible);

        this.userService.getCurrentUserObservable().subscribe(() => {
            this.isOwner = this.userService.hasOwnerPrivileges();
            this.isAdmin = this.userService.hasAdminPrivileges();
        });

        this.venueService.selectedVenue$.subscribe((venue) => {
            this.currentVenue = venue;
            this.isRegenerateButtonVisible = this.isOwner && this.currentVenue.graphSetup === GraphSetup.Automatic;
        });

        this.buildingService.selectedFloor$.subscribe((floor) => {
            this.currentFloor = floor;
        });

        this.routeNetworkChanged = this.networkService.routeNetworkChanged;

        this.networkService.routeElementsUpdates$.subscribe(() => {
            this.routeNetworkChanged = true;
        });

        this.#mapAdapter.routeNetworkLoaded$.subscribe((value) => {
            if (this.showNotificationWhenNetworkIsLoaded === true && value === true) {
                this.notificationService.showSuccess('Route Network reloaded!');
                // Setting the internal property used for displaying the orange badge on to of the Reload route network button.
                this.routeNetworkChanged = false;
                this.showNotificationWhenNetworkIsLoaded = false;
                // Responsible for keeping the current state of the route network on the networkService.
                // If the network has been updated since the last time the user has seen it, the  route network should be reloaded.
                this.networkService.routeNetworkChanged = false;
            }
        });

        const solutionGraphs = this.networkService.getSolutionGraphsIds();
        solutionGraphs.subscribe((graphIds) => this.graphIds = graphIds);

        document.addEventListener('keydown', this.createDoorWithShortcut.bind(this));
    }

    /**
     * Sets the selected tool active.
     *
     * @param {Type<MapToolbar>} typeOfTool
     * @memberof DefaultMapToolsComponent
     */
    public setToolActive(typeOfTool: Type<MapToolbar>): void {
        this.toolSelected.emit(typeOfTool);
        switch (typeOfTool) {
            case EditGraphComponent:
                if (this.isRouteNetworkVisible) {
                    this.toggleRouteNetworkVisibility();
                }
                break;
            default:
                if (!this.isRouteNetworkVisible) {
                    this.toggleRouteNetworkVisibility();
                }
        }
    }

    /**
     * Toggle the visibility of the route network and -elements.
     *
     * @memberof MapToolbarComponent
     */
    public toggleRouteNetworkVisibility(): void {
        if (this.#mapAdapter) {
            this.isRouteNetworkVisible = !this.isRouteNetworkVisible;
            this.#mapAdapter.setVisibility('RouteNetwork', this.isRouteNetworkVisible);
        }

        this.showNotificationWhenNetworkIsLoaded = false;
    }

    /**
     * Refetches and redraws the network graph on the map.
     */
    public reloadRouteNetwork(): void {
        if (this.isRouteNetworkVisible) {
            this.toggleRouteNetworkVisibility();
        }

        this.#mapAdapter.setVisibility('RouteNetwork', true);
        this.showNotificationWhenNetworkIsLoaded = true;
    }

    /**
     * Requests the backend to drop all network graph data and generate new ones.
     *
     * @returns {Subscription}
     */
    public regenerateAllGraphData(): Subscription {
        if (this.isRouteNetworkVisible) {
            this.toggleRouteNetworkVisibility();
        }

        this.spinner.show();
        this.notificationService.showInfo('Removing all generated graph data');
        return this.networkService.regenerateGraphData(this.currentVenue.graphId)
            .pipe(
                finalize(() => {
                    this.notificationService.showSuccess('Old generated data cleared. Creating new data', false, 6);
                    this.toggleRouteNetworkVisibility();
                })
            ).subscribe();
    }

    /**
     * Opens Graph List Editor.
     */
    public openGraphListEditor(): any {
        if (this.graphIds.length > 0) {
            this.mapSidebar.open(GraphListComponent);
        } else {
            this.notificationService.showError('No graphs found in this solution!');
        }
    }

    /**
     * Create doors with the shortcut.
     *
     * @param {KeyboardEvent} event
     */
    @HostListener('window:keydown', ['$event'])
    public createDoorWithShortcut(event?: KeyboardEvent): void {
        const shiftAndCtrlPressed = event.shiftKey && event.ctrlKey;
        if (shiftAndCtrlPressed && event.key.toLowerCase() === 'd' || shiftAndCtrlPressed && event.key.toLowerCase() === 'f') {
            if (this.isCtrlShiftDPressed(event)) {
                let areDoorsCreated: boolean;
                const currentValue = this.networkService.getMultipleDoorsCreation().subscribe((areDoorsBeingCreated) => {
                    areDoorsCreated = areDoorsBeingCreated;
                });

                // On windows machines ctrl + shift + d opens bookmark in the browser. We should prevent that by setting preventDefault.
                event.preventDefault();

                if (areDoorsCreated === false) {
                    if (this.isAdmin) {
                        if (event.key.toLowerCase() === 'f') {
                            this.setToolActive(this.Tools.AddMultipleDoorsFreehandComponent);
                        } else {
                            this.setToolActive(this.Tools.AddMultipleDoorsComponent);
                        }
                        this.networkService.setMultipleDoorsCreation(!currentValue);
                    }
                }
            }
        }
    }

    /**
     * Returns true if Ctrl+Shift+D/Ctrl+Shift+F keys are pressed.
     *
     * @returns {boolean}
     */
    private isCtrlShiftDPressed(event: KeyboardEvent): boolean {
        return event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'd' || event.key.toLowerCase() === 'f';
    }
}