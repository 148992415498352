import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormArray, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { filter, map, tap } from 'rxjs/operators';

import { SolutionService } from '../../services/solution.service';
import { TypesService } from '../../services/types.service';
import { primitiveClone } from '../../shared/object-helper';
import { Solution } from '../../solutions/solution.model';
import { CustomProperty, Translation } from '../../shared/interfaces/translation.model';

@Component({
    selector: 'location-custom-properties',
    templateUrl: './location-custom-properties.component.html',
    styleUrls: ['./location-custom-properties.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LocationCustomPropertiesComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => LocationCustomPropertiesComponent),
        multi: true,
    }]
})
export class LocationCustomPropertiesComponent implements ControlValueAccessor, Validator {
    public formGroup: FormGroup = new FormGroup({});
    public languages: Set<string>;
    public translations: Translation[];
    public isDisabled: boolean = false;
    private currentTranslation: Translation[];
    private inheritedKeys: string[] = [];
    private locationKeys: string[];
    protected selectedSolution: Solution;

    public onChange: (value: Translation[] | null) => void = () => { };
    public onTouch: () => void;

    /**
     * Setting typeName that we can bind to in Location Details.
     */
    @Input()
    public set typeName(typeName: string) {
        this.typesService.types
            .pipe(
                map(types => types.find(type => type.administrativeId === typeName)),
                filter(type => !!type))
            .subscribe((type) => {
                this.inheritedKeys = type.fields?.map(field => field.text) ?? [];
                if (this.translations) {
                    this.buildCustomPropertiesForm(this.translations);
                }
            });
    }

    constructor(
        private solutionService: SolutionService,
        private formBuilder: FormBuilder,
        private typesService: TypesService
    ) {
        this.solutionService.selectedSolution$.subscribe(selectedSolution => this.selectedSolution = selectedSolution);
        this.formGroup.valueChanges.pipe(
            tap((formValues) => {
                const keys: string[] = formValues[this.defaultLanguage].map(field => field.key);
                for (const language of this.languages) {
                    for (let i = 0; i < keys.length; i++) {
                        //Update the key field for all languages.
                        const defaultKey = this.formGroup.get(`${this.defaultLanguage}.${i}.key`).value;
                        if (language !== this.defaultLanguage && this.formGroup.get(`${language}.${i}.key`).value !== defaultKey) {
                            this.formGroup.get(`${language}.${i}.key`).setValue(defaultKey, { emitEvent: false });
                        }

                        if (defaultKey > '') {
                            this.formGroup.get(`${language}.${i}.value`).enable({ emitEvent: false });
                        } else {
                            this.formGroup.get(`${language}.${i}.value`)?.disable({ emitEvent: false });
                        }
                    }
                }
            }))
            .subscribe(() => {
                const formValues = this.formGroup.getRawValue();
                const translations = primitiveClone(this.translations);
                const fieldsArray = Object.values<any>(this.formGroup.getRawValue()).flat();
                // An empty Array that will consist of keys that are repeating or value is empty. The procedure is necessary to not duplicate Custom Properties between Types and Locations.
                const keysToExclude = [];

                this.inheritedKeys.forEach(key => {
                    if (!this.locationKeys.includes(key) && !fieldsArray.some(field => (field?.key?.toLowerCase() === key?.toLowerCase() && field?.value > ''))) {
                        keysToExclude.push(key.toLowerCase());
                    }
                });

                for (const translation of translations) {
                    const language = translation.language;
                    const fields = formValues[language].reduce((fields, field) => {
                        if (keysToExclude.includes(field?.key.toLowerCase())) {
                            return fields;
                        }

                        fields[field.key] = { ...field };
                        delete fields[field.key].key;
                        return fields;
                    }, {});

                    translation.fields = fields;
                }

                this.onChange(translations);
                this.currentTranslation = translations;
            });
    }

    /**
     * Get the default language.
     *
     * @readonly
     * @returns {string}
     */
    public get defaultLanguage(): string {
        return this.selectedSolution.defaultLanguage;
    }

    /**
     * Indicates if generic value should have a warning message.
     *
     * @param {number} formArrayIndex
     * @returns {boolean}
     */
    public isGenericValueOverwritten(formArrayIndex: number): boolean {
        const value = this.formGroup.get(`${'generic'}.${formArrayIndex}.value`)?.value;
        const key = this.formGroup.get(`${'generic'}.${formArrayIndex}.key`)?.value;

        if (!value) return false;

        let isShown: boolean = false;
        this.languages.forEach(language => {
            const languageTranslation = this.currentTranslation.find(translation => translation.language === language);
            const languageKeyValue = languageTranslation?.fields[key]?.value;
            if (languageKeyValue) {
                isShown = true;
            }
        });

        return isShown;
    }

    /**
     * Indicates if language value should have a warning message.
     *
     * @param {string} language
     * @param {number} formArrayIndex
     * @returns {boolean}
     */
    public isValueOverwritten(language: string, formArrayIndex: number): boolean {
        const value = this.formGroup.get(`${language}.${formArrayIndex}.value`)?.value;
        const key = this.formGroup.get(`${language}.${formArrayIndex}.key`)?.value;

        if (!value) return false;

        let isShown: boolean = false;
        const languageTranslation = this.currentTranslation.find(translation => translation.language === 'generic');
        const languageKeyValue = languageTranslation?.fields[key]?.value;
        if (languageKeyValue) {
            isShown = true;
        }

        return isShown;
    }

    /**
     * Distinguish when to show warning boxes.
     *
     * @param {string} key
     * @param {string} value
     * @returns {boolean}
     */
    public isValueInheritedFromGeneric(key: string, value: string): boolean {
        if (value) return false;

        // If generic 'language' has value for the same Key in languages,
        // we will prompt the user that Key inherits the value from generic 'language'
        let isValueShown: boolean = false;
        const languageTranslation = this.currentTranslation.find(translation => translation.language === 'generic');
        const languageKeyValue = languageTranslation?.fields[key]?.value;
        if (languageKeyValue) {
            isValueShown = true;
        }

        return isValueShown;
    }

    /**
     * Builds the custom properties form.
     *
     * @private
     * @param {Translation[]} translations
     * @memberof LocationCustomPropertiesComponent
     */
    private buildCustomPropertiesForm(translations: Translation[]): void {
        const fieldKeys = new Set(
            translations
                .flatMap(translation => Object.keys(translation.fields))
                .filter(key => !this.inheritedKeys.some(inheritedKey => inheritedKey?.toLowerCase() === key?.toLowerCase()))
        );

        for (const translation of translations) {
            const language = translation.language;
            const inheritedFields = this.buildInheritedFieldsFormGroups(translation.fields);

            const fieldsFormArray = [...fieldKeys]
                .filter(key => !this.inheritedKeys.some(inheritedKey => inheritedKey?.toLowerCase() === key?.toLowerCase()))
                .reduce((formArray, key) => {
                    const field = translation.fields[key] ?? { value: '', type: 'text', text: '' };
                    const fieldGroup: FormGroup = this.formBuilder.group({
                        ...field,
                        key: [{ value: key, disabled: language !== this.defaultLanguage }, [Validators.required, unique(formArray, 'key')]]
                    });
                    formArray.push(fieldGroup);
                    return formArray;
                }, new UntypedFormArray([...inheritedFields]));

            this.formGroup.setControl(language, fieldsFormArray, { emitEvent: false });
        }
    }

    /**
     * Builds the generic properties form.
     *
     * @private
     * @param {Translation} translation
     * @memberof LocationCustomPropertiesComponent
     */
    private buildGenericPropertiesForm(translation: Translation): void {
        const fields = translation.fields;
        const inheritedFields = this.buildInheritedFieldsFormGroups(fields);

        const fieldsFormArray = [...Object.keys(fields)]
            .filter(key => !this.inheritedKeys.some(inheritedKey => inheritedKey?.toLowerCase() === key?.toLowerCase()))
            .reduce((formArray, key) => {
                const field = fields[key] ?? { value: '', type: 'text', text: '' };
                const fieldGroup: FormGroup = this.formBuilder.group({
                    ...field,
                    key: [key, [Validators.required, unique(formArray, 'key')]]
                });
                formArray.push(fieldGroup);
                return formArray;
            }, new UntypedFormArray([...inheritedFields]));

        this.formGroup.setControl('generic', fieldsFormArray, { emitEvent: false });
    }

    /**
     * Builds the form fields for properties inherited from the Location Type.
     *
     * @private
     * @param {Object<string, any>} properties
     * @returns {FormGroup[]}
     */
    private buildInheritedFieldsFormGroups(properties: { [key: string]: CustomProperty; }): FormGroup[] {
        const propertiesMap = new Map(Object.entries(properties).map(([key, field]) => ([key.toLowerCase(), field])));
        return this.inheritedKeys?.map(inheritedKey => {
            const property = propertiesMap.get(inheritedKey?.toLowerCase()) ?? { value: '', type: 'text', text: '' };
            const fieldGroup: FormGroup = this.formBuilder.group({
                ...property,
                key: [{ value: inheritedKey, disabled: true }],
            });
            return fieldGroup;
        });
    }

    /**
     * Adds a new translatable custom property.
     *
     * @memberof LocationCustomPropertiesComponent
     */
    public addProperty(): void {
        for (const language of this.languages) {
            const formArray = (this.formGroup.get(language) as UntypedFormArray);
            const fieldGroup: FormGroup = this.formBuilder.group({
                // eslint-disable-next-line
                key: [{ value: '', disabled: language !== this.defaultLanguage }, [Validators.required, unique(formArray, 'key')]],
                text: [''],
                type: ['text'],
                value: [{ value: '', disabled: true }]
            });
            formArray.push(fieldGroup, { emitEvent: false });
        }
        this.formGroup.updateValueAndValidity();
    }

    /**
     * Removes the translatable custom property at the given index.
     *
     * @param {number} index
     * @memberof LocationCustomPropertiesComponent
     */
    public removeProperty(index: number): void {
        for (const language of this.languages) {
            (this.formGroup.get(language) as UntypedFormArray).removeAt(index);
        }
    }

    /**
     * Adds a new generic custom property.
     *
     * @memberof LocationCustomPropertiesComponent
     */
    public addGenericProperty(): void {
        const formArray = (this.formGroup.get('generic') as UntypedFormArray);
        const fieldGroup: FormGroup = this.formBuilder.group({
            // eslint-disable-next-line
            key: [{ value: '', disabled: false }, [Validators.required, unique(formArray, 'key')]],
            text: [''],
            type: ['text'],
            value: [{ value: '', disabled: false }]
        });

        formArray.push(fieldGroup, { emitEvent: false });
        this.formGroup.updateValueAndValidity();
    }

    /**
     * Removes the generic custom property at the given index.
     *
     * @param {number} index
     * @memberof LocationCustomPropertiesComponent
     */
    public removeGenericProperty(index: number): void {
        (this.formGroup.get('generic') as UntypedFormArray).removeAt(index);
    }

    /**
     * Writes a new value to the element.
     *
     * This method is called by the forms API to write to the view when programmatic
     * changes from model to view are requested.
     *
     * @param {Translation[]} translations - The new value for the element.
     */
    writeValue(translations: Translation[]): void {
        if (Array.isArray(translations)) {
            this.locationKeys = Object.keys(translations.find(translation => translation.language === this.defaultLanguage)?.fields);
            this.translations = translations;
            this.currentTranslation = translations;
            this.languages = new Set(translations?.map(translation => translation.language));
            // Generic 'language' is not translatable, so it is removed from languages set
            this.languages.delete('generic');

            this.buildGenericPropertiesForm(translations.find(translation => translation.language === 'generic'));
            this.buildCustomPropertiesForm(translations.filter(translation => translation.language !== 'generic'));
        }
    }

    /**
     * Registers a callback function that is called when the control's value
     * changes in the UI.
     *
     * This method is called by the forms API on initialization to update the form
     * model when values propagate from the view to the model.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnChange(fn: (value: Translation[] | null) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization
     * to update the form model on blur.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Function that is called by the forms API when the control status changes to
     * or from 'DISABLED'. Depending on the status, it enables or disables the
     * appropriate DOM element.
     *
     * @param {boolean} isDisabled - The disabled status to set on the element.
     */
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    /**
     * Method that performs synchronous validation against the provided control.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return this.formGroup.invalid ? { invalid: true } : null;
    }
}

/**
 * Validates that the entered value is unique with in the given FormArray.
 *
 * @param {UntypedFormArray} formArray
 * @param {string} [formControlName]
 * @returns {ValidatorFn}
 */
function unique(formArray: UntypedFormArray, formControlName?: string): ValidatorFn {
    return (source: AbstractControl): ValidationErrors | null => {
        if (!source?.value) {
            return null;
        }
        const isUnique = !formArray?.controls?.some(control => control !== source?.parent && control?.get(formControlName)?.value?.toLowerCase() === source?.value?.toLowerCase());

        return isUnique ? null : { unique: true };
    };
}