import { MultiPolygon, Polygon } from 'geojson';

import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';

abstract class FloorPlanViewModelProperties implements ViewModelProperties {
    clickable: boolean;
    featureClass: FeatureClass;
    fillColor: string;
    fillOpacity: number;
    originalId: string;
    originalType: MapsIndoorsData;
    sortKey: number;
    strokeColor: string;
    strokeOpacity: number;
    strokeWidth: number;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a FloorPlanViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @returns {FloorPlanViewModelProperties}
     * @memberof FloorPlanViewModelProperties
     */
    static async create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData): Promise<FloorPlanViewModelProperties> {
        return await Promise.resolve({
            clickable: false,
            originalId: id,
            originalType,
            sortKey: SortKey.FLOOR_PLAN + sortKey,
            featureClass: FeatureClass.FLOOR_PLAN,
            strokeWidth: 1.0,
            strokeColor: '#6B7280', // MIDT grey-500
            strokeOpacity: 1.0,
            fillColor: '#FFFFFF',
            fillOpacity: 1.0,
            zoomRange: [15, 999],
        });
    }
}


export class FloorPlanViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    readonly geometry: Polygon | MultiPolygon;
    readonly properties: FloorPlanViewModelProperties;

    private constructor(id: string, geometry: Polygon | MultiPolygon, properties: FloorPlanViewModelProperties) {
        this.id = `FLOORPLAN:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory to create a FloorPlanViewModel from an id, a geometry, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {Polygon | MultiPolygon} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @returns {FloorPlanViewModel}
     * @memberof FloorPlanViewModel
     */
    static async create(id: string, geometry: Polygon | MultiPolygon, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData): Promise<FloorPlanViewModel> {
        const properties = await FloorPlanViewModelProperties.create(id, index, displayRule, originalType);
        const viewModel = new FloorPlanViewModel(id, geometry as (Polygon | MultiPolygon), properties);

        return viewModel;
    }
}