import { Point } from 'geojson';
import { GeoJSONGeometryType } from '../../app/shared/enums';
import { Geometry } from '../../app/locations/location.model';

/**
 * Creates a Point geometry from an array of numbers - [lng, lat].
 *
 * @param {number[]} coordinates
 * @returns {Point}
 */
export function toPointGeometry(coordinates: number[]): Point {
    return { type: 'Point', coordinates };
}

/**
 * Checks if a geometry is a Polygon.
 *
 * @param {Geometry | GeoJSON.Geometry} geometry
 * @returns {boolean}
 */
export function isPolygon(geometry: Geometry | GeoJSON.Geometry): boolean {
    return geometry?.type === GeoJSONGeometryType.Polygon;
}

/**
 * Checks if a geometry is a Point.
 *
 * @param {Geometry | GeoJSON.Geometry} geometry
 * @returns {boolean}
 */
export function isPoint(geometry: Geometry | GeoJSON.Geometry): boolean {
    return geometry?.type === GeoJSONGeometryType.Point;
}

/**
 * Checks if a geometry is a MultiPolygon.
 *
 * @param {Geometry | GeoJSON.Geometry} geometry
 * @returns {boolean}
 */
export function isMultiPolygon(geometry: Geometry | GeoJSON.Geometry): boolean {
    return geometry?.type === GeoJSONGeometryType.MultiPolygon;
}

/**
 * Checks if a geometry is either a Polygon or MultiPolygon.
 *
 * @param {Geometry | GeoJSON.Geometry} geometry
 * @returns {boolean}
 */
export function isPolygonOrMultiPolygon(geometry: Geometry | GeoJSON.Geometry): boolean {
    return isPolygon(geometry) || isMultiPolygon(geometry);
}