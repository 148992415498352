<section class="section">
    <div class="section__header">
        <h4 class="section__title">Correlation ID <info-tooltip class="tooltip" text="Locations with the same Correlation ID must be in the same Venue and Building, but on different Floors. Disabled options in the list are IDs already used on the current Occupant’s Floor. 
            It's recommended to assign the same Occupant Template to Occupants that share Correlation ID."></info-tooltip></h4>
        <button class="mi-button mi-button--outline" (click)="onRemoveCorrelationId()">Remove ID <div class="section__delete"></div></button>
    </div>
    <p>Add a Correlation ID to the Occupant if it's related to one or more Locations on different Floors. Use an
        existing ID or add a new.</p>

    <mi-dropdown #correlationIdDropdown filterable (change)="onCorrelationIdDropdownChange($event)"></mi-dropdown>

    <label class="mi-label">
        Add new Correlation ID
        <input #correlationIdInput type="text" class="mi-input mi-input--medium" maxlength="32" (input)="onCorrelationIdInputChange($event)">
        <small role="alert" class="mi-form__error" *ngIf="correlationIdFormControl.hasError('isDropDownItem')">
            Error: The Correlation ID already exists. Please select it in the list, or rename the new ID to something else.
        </small>
        <small role="alert" class="mi-form__error" *ngIf="correlationIdFormControl.hasError('isEmpty')">
            Error: The Correlation ID cannot exists of whitespace only.
        </small>
    </label>
</section>