import { Component, ViewChild, ElementRef, forwardRef, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';
import { noDuplicateValidator } from '../../directives/no-duplicate.directive';

@Component({
    selector: 'occupant-aliases-list',
    templateUrl: './occupant-aliases-list.component.html',
    styleUrls: ['./occupant-aliases-list.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OccupantAliasesListComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => OccupantAliasesListComponent),
        multi: true,
    }]
})
export class OccupantAliasesListComponent implements ControlValueAccessor, Validator {
    @Input() nonDeletableAliases: string[] = [];
    @Input() futureAliases: string[] = [];
    @ViewChild('aliasInput') aliasInputElement: ElementRef;

    public onChange: (aliases: string[]) => void = () => { };
    public onTouch: () => void = () => { };
    public isDisabled: boolean = false;

    public aliases: string[] = [];
    public occupantAliasesForm: FormGroup = this.formBuilder.group({
        aliasInput: ['']
    });

    constructor(private formBuilder: FormBuilder) {}

    /**
     * Submit aliasForm and add alias.
     *
     * @memberof AliasesListComponent
     */
    public addAlias(): void {
        if (!this.occupantAliasesForm.valid) {
            return;
        }

        this.aliases.push(this.occupantAliasesForm.get('aliasInput').value);
        this.occupantAliasesForm.reset();
        this.aliasInputElement.nativeElement.focus();

        this.onChange(this.aliases);
    }

    /**
     * Remove alias from list.
     *
     * @param {number} index - Index of alias to remove.
     * @memberof AliasesListComponent
     */
    public removeAlias(index: number): void {
        this.aliases?.splice(index, 1);
        this.onChange(this.aliases);
    }

    /**
     * Writes a new value to the element.
     *
     * @param {string[]} aliases
     * @memberof AliasesListComponent
     */
    writeValue(aliases: string[]): void {
        this.aliases = aliases ?? [];
        this.occupantAliasesForm.controls.aliasInput.setValidators([Validators.minLength(1), noDuplicateValidator(this.aliases)]);
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     *
     * @memberof AliasesListComponent
     */
    registerOnChange(fn: (aliases: string[]) => void): void {
        this.onChange = (aliases: string[]) => fn(aliases.length > 0 ? aliases : null);
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     *
     * @memberof AliasesListComponent
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Method that is being called when the control value changes.
     *
     * @memberof AliasesListComponent
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return this.occupantAliasesForm.invalid ? { invalid: true } : null;
    }
}