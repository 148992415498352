export interface Size {
    width: number,
    height: number
}

export class Dimension {
    width?: number = 0;
    height?: number = 0;

    constructor(dimension?: { width: number, height: number }) {
        Object.assign(this, dimension);
    }

    /**
     * Checks if the dimension's values are greater than the incoming dimension's values.
     *
     * @param {Dimension} comparingDimension
     * @returns {boolean}
     * @memberof Dimension
     */
    public isGreaterThan(comparingDimension: Dimension): boolean {
        return this.width > comparingDimension.width || this.height > comparingDimension.height;
    }
}