import { isNullOrUndefined } from '../../../utilities/Object';

export class NodeProperties {
    floorindex: number;
    barrier: string = '';
    boundary: number = 0;
    floorname: string;
    waittime: number = 0;
    [key: string]: unknown;

    /**
     * Creates a new instance of NodeProperties.
     *
     * @param {number} floorIndex - The floor index.
     */
    constructor(floorIndex: number, floorName?: string) {
        this.floorindex = floorIndex;
        this.floorname = floorName;
    }

    /**
     * Creates a NodeProperties object from node data and node mapping.
     *
     * @param {object} nodeData - The node data.
     * @param {object} nodeMapping - The node mapping.
     * @returns {NodeProperties} A new instance of NodeProperties.
     */
    static create(nodeData, nodeMapping): NodeProperties {
        const floorIndex = nodeData[2];
        const properties = new NodeProperties(floorIndex);

        return nodeData.slice(3).reduce((properties: NodeProperties, value, index) => {
            const property = nodeMapping.properties[index];
            properties[property] = (nodeMapping.values[index]?.length > 0 ? nodeMapping.values[index][value] : value) ?? 0;
            return properties;
        }, properties);
    }

    /**
     * Serializes the node properties to an array of numbers.
     *
     * @param {NodeProperties} properties - The node properties to serialize.
     * @param {any} nodeMapping - The node mapping.
     * @returns {number[]} The serialized node properties.
     */
    static serialize(properties: NodeProperties, nodeMapping: any): number[] {
        return nodeMapping.properties.map((property, index) => {
            if (isNullOrUndefined(properties[property])) {
                return 0;
            }

            if (property === 'boundary') {
                return properties[property];
            }

            const values = nodeMapping.values[index];

            if (values.length === 0) {
                return properties[property];
            }

            const valueIndex = values.indexOf(properties[property]);

            if (valueIndex === -1) {
                values.push(properties[property]);
                return values.length - 1;
            }

            return valueIndex;
        });
    }
}

export class Node implements GeoJSON.Feature<GeoJSON.Point> {
    type: 'Feature';
    readonly id: number;
    geometry: GeoJSON.Point;
    properties: NodeProperties;

    constructor(id: number, geometry: GeoJSON.Point, properties: NodeProperties) {
        this.id = id;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Converts the node to a GeoJSON Feature.
     *
     * @returns {GeoJSON.Feature<GeoJSON.Point>} The GeoJSON Feature.
     */
    toFeature(): GeoJSON.Feature<GeoJSON.Point> {
        return {
            id: this.id,
            type: 'Feature',
            geometry: this.geometry,
            properties: { ...this.properties }
        };
    }

    /**
     * Converts the node coordinates to a GeoJSON Point.
     *
     * @param {number[]} coordinates - The coordinates of the node.
     * @returns {GeoJSON.Point} The GeoJSON Point.
     */
    static #toPoint(coordinates: number[]): GeoJSON.Point {
        const lat = coordinates[0] / 1e7;
        const lng = coordinates[1] / 1e7;
        return {
            type: 'Point',
            coordinates: [lng, lat]
        };
    }

    /**
     * Creates a new node from the given data.
     *
     * @param {number} id - The id of the node.
     * @param {any} nodeData - The data of the node.
     * @returns {Node} The created node.
     */
    static create(id, nodeData: any, nodeMapping: any): Node {
        const point = this.#toPoint(nodeData as number[]);
        const properties = NodeProperties.create(nodeData, nodeMapping);
        return new Node(id, point, properties);
    }

    /**
     * Serializes the node to an array of numbers.
     *
     * @param {Node} node - The node to serialize.
     * @param {any} nodeMapping - The node mapping.
     * @returns {number[]} The serialized node.
     */
    static serialize(node: Node, nodeMapping: any): number[] {
        return [
            +(node.geometry.coordinates[1] * 1e7).toFixed(0),
            +(node.geometry.coordinates[0] * 1e7).toFixed(0),
            node.properties.floorindex,
            ...NodeProperties.serialize(node.properties, nodeMapping)
        ];
    }
}