<panel-header (cancelEvent)="onCloseDialog()" [header]="header">
    <save-button *ngIf="showPushToTypeButton" (click)="onPushToType()" toolTipMessage="Push change to location type {{location?.typeOfLocation?.displayName}}"></save-button>
</panel-header>
<navigatable-container>
    <display-rule-details-editor
        [displayRules]="displayRules"
        [isGeometrySettingsVisible]="isGeometrySettingsVisible"
        [is3DWallsSectionVisible]="is3DWallsSectionVisible"
        [geometries]="geometries"
        [location]="location"
        [pushToTypeButtonClicked]="pushToTypeButtonClicked"
        (formSubmit)="onFormSubmit($event)"
        (formDiscard)="onFormDiscard($event)"
        (pushDisplayRuleToType)="onPushDisplayRuleToType($event)"
        (areFromControlsEnabled)="isPushToTypeButtonShown($event)">
    </display-rule-details-editor>
</navigatable-container>
