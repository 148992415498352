<section class="filters">
    <!-- Venue Selector -->
    <mi-dropdown
        #venueSelector
        filterable
        class="filters__item filters__item--fill venue-selector"
        icon="../../../assets/images/earth.svg"
        items-order="asc"
        (change)="onVenueChange($event)"
        [disabled]="isVenueSelectorDisabled">
    </mi-dropdown>

    <!-- Buildings -->
    <mi-dropdown
        #buildingsFilter
        icon="../../../assets/images/building.svg"
        class="filters__item building-filter"
        multiple
        filterable
        label="Building"
        (change)="onBuildingChange($event)"
        [disabled]="isBuildingSelectorDisabled">
    </mi-dropdown>

    <!-- Floors -->
    <mi-dropdown
        #floorFilter
        class="filters__item"
        multiple
        filterable
        label="Floor"
        (change)="onFloorsChange($event)">
    </mi-dropdown>

    <!-- Display Options -->
    <mi-dropdown
        #displayOptionsFilter
        class="filters__item"
        multiple
        filterable
        items-order="asc"
        label="Display Options"
        (change)="onDisplayOptionsChange($event)">
    </mi-dropdown>

    <!-- Location Types -->
    <mi-dropdown
        #locationTypesFilter
        class="filters__item"
        multiple
        filterable
        items-order="asc"
        label="Types"
        (change)="onLocationTypesChange($event)">
    </mi-dropdown>

    <!-- Categories -->
    <mi-dropdown
        #categoriesFilter
        class="filters__item"
        multiple
        filterable
        items-order="asc"
        label="Categories"
        (change)="onCategoriesChange($event)">
    </mi-dropdown>

    <!-- App User Roles -->
    <mi-dropdown
        #appUserRolesFilter
        class="filters__item"
        multiple
        filterable
        items-order="asc"
        label="App User Roles"
        (change)="onAppUserRolesChange($event)">
    </mi-dropdown>

    <!-- Search -->
    <div class="filters__item search">
        <div class="search__container">
            <input #searchFilter
                type="search"
                aria-label="Search in Name, ID, Aliases or ExternalID"
                class="mi-input search__input"
                tabindex="0"
                placeholder="Search in Name, ID, Aliases or ExternalID"
                (input)="onSearchInputEntered($event)">
            <span class="search__shadow"></span>
            <button #searchClearButton
                tabindex="{{ isClearButtonVisible ? 0 : -1 }}"
                type="button"
                (click)="onSearchFilterClear()"
                [ngClass]="{ 'search__clear': true, 'search__clear--visible': isClearButtonVisible }"
                aria-label="Clear">
                <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                </svg>
            </button>
        </div>
    </div>

    <button 
        type="button" 
        class="mi-button mi-button--primary filters__item edit-location" 
        (click)="openBulkEdit.emit()" 
        [disabled]="!canOpenBulkEdit" 
        title="Edit location(s)">
        <span>Edit</span>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.71393 2.52199C8.68802 2.49585 8.65721 2.4751 8.62326 2.4609C8.58931 2.44671 8.5529 2.43936 8.5161 2.43928C8.47934 2.43929 8.44295 2.44655 8.409 2.46064C8.37505 2.47474 8.34422 2.4954 8.31827 2.52143L1.24314 9.70081V10.0213L4.00826 12.7865H4.26911L11.4757 5.68611C11.5279 5.63374 11.5572 5.5628 11.5572 5.48884C11.5572 5.41489 11.5279 5.34395 11.4757 5.29157L8.71393 2.52199Z" fill="#FCFCFC" />
            <path d="M1.65193 9.6829C1.61678 9.64787 1.57295 9.62283 1.52492 9.61036C1.47689 9.59789 1.42641 9.59843 1.37866 9.61193C1.33084 9.62534 1.28745 9.65126 1.25297 9.68701C1.2185 9.72276 1.19417 9.76706 1.18251 9.81534L0.340903 13.3232C0.329796 13.3696 0.330797 13.4182 0.343809 13.4642C0.356822 13.5102 0.381412 13.552 0.415228 13.5858C0.449262 13.6192 0.491177 13.6435 0.537091 13.6564C0.583004 13.6693 0.63143 13.6704 0.67788 13.6596L4.18289 12.8213C4.23123 12.8098 4.2756 12.7855 4.31144 12.7511C4.34728 12.7166 4.37328 12.6733 4.38678 12.6254C4.40027 12.5776 4.40077 12.5271 4.38821 12.479C4.37565 12.4309 4.3505 12.387 4.31534 12.3519L1.65193 9.6829Z" fill="#FCFCFC" />
            <path d="M13.2589 1.9285L12.0725 0.741539C11.8106 0.480138 11.4557 0.333328 11.0856 0.333328C10.7156 0.333328 10.3606 0.480138 10.0987 0.741539L9.30629 1.53341C9.25391 1.58581 9.22448 1.65687 9.22448 1.73096C9.22448 1.80505 9.25391 1.87611 9.30629 1.9285L12.0725 4.69418C12.1249 4.74656 12.196 4.77599 12.2701 4.77599C12.3442 4.77599 12.4152 4.74656 12.4676 4.69418L13.2589 3.90119C13.52 3.63931 13.6666 3.28462 13.6666 2.91485C13.6666 2.54508 13.52 2.19038 13.2589 1.9285Z" fill="#FCFCFC" />
        </svg>
    </button>

    <!-- List View -->
    <button type="button" class="mi-button mi-button--base mi-button--icon-right list-view" [ngClass]="{'active': isListViewOpen}" (click)="listViewtoggle.emit()" title="Toggle list view">
        <span>List View</span>
        <img [src]="svg_expand_more" type="image/svg+xml">
    </button>
</section>

<!-- Chips list -->
<chips-list *ngIf="showChipsList" [dropdownElements]="dropdownFilterElements"></chips-list>
