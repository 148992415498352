import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export const NEWS_VERSION = 17; // This version should be incremented (+1) when adding a new post in the news component

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<NewsComponent>,
    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        localStorage.setItem('newsVersion', NEWS_VERSION.toString());
    }

}
