// DO NOT CHANGE - it was agreed with the BE team that the order of OccupantType should be like this
export enum OccupantType {
    Occupant = 0,
    Amenity = 1,
    Carousel = 2,
    Gate = 3
}

export interface OpeningHours {
    closedAllDay: boolean,
    startTime: string,
    endTime: string
}

/**
 * Compares two OpeningHours objects.
 * Returns true if they are equal, false otherwise.
 *
 * @param {OpeningHours} a
 * @param {OpeningHours} b
 * @returns {boolean}
 */
export function areOpeningHoursEqual(a: OpeningHours, b: OpeningHours): boolean {
    if (a === b) {
        return true;
    }

    return a?.closedAllDay === b?.closedAllDay &&
        a?.startTime === b?.startTime &&
        a?.endTime === b?.endTime;
}

export interface Field {
    text: string,
    type: string,
    value: string
}