import { Component, OnDestroy, Output } from '@angular/core';
import { MapToolbar } from '../../map-toolbar.interface';
import { Subject } from 'rxjs';

@Component({
    selector: 'empty-toolbar',
    templateUrl: './empty-toolbar.component.html',
    styleUrls: ['./empty-toolbar.component.scss'],
})
export class EmptyToolbarComponent implements MapToolbar, OnDestroy {
    #destroySubject: Subject<void> = new Subject();

    @Output() public readonly destroy = this.#destroySubject.asObservable();

    /**
     * Angular OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this.#destroySubject.next();
        this.#destroySubject.complete();
    }
}