import { Component, ComponentRef, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { BaseMapAdapter, MapAdapterType, MapType } from '../../MapAdapter/BaseMapAdapter';
import { MapAdapterComponent } from '../map-adapter/map-adapter.component';
import { MapUIComponents, MapUIService } from '../map-ui-service/map-ui.service';
import { MapToolbar, MapToolbarRef } from './map-toolbar.interface';
import { DefaultMapToolsComponent } from './tools/default/default-map-tools.component';
import { MapToolbarService } from './map-toolbar.service';
import { ComponentType } from '@angular/cdk/portal';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { EditGraphBoundsComponent } from './tools/route-network-tools/edit-graph-bounds/edit-graph-bounds.component';
import { MapAdapterMediator } from '../map-adapter.mediator';

@Component({
    selector: 'map-toolbar',
    templateUrl: './map-toolbar.component.html',
    styleUrls: ['./map-toolbar.component.scss'],
    //Encapsulation is set to `None` to allow for the child components to inherit styling from the parent. See https://angular.io/api/core/Component#encapsulation.
    encapsulation: ViewEncapsulation.None
})
export class MapToolbarComponent implements OnInit, OnDestroy {
    @ViewChild('mapToolsContainer', { read: ViewContainerRef, static: true }) mapToolsContainer!: ViewContainerRef;
    @ViewChild('resetRotateAndTiltButton') resetRotateAndTiltButton: ElementRef<HTMLButtonElement>;
    #defaultToolbar: ComponentRef<DefaultMapToolsComponent>;
    #subscriptions: Subscription = new Subscription();

    public readonly MapType: typeof MapType = MapType;
    public readonly MapAdapterType: typeof MapAdapterType = MapAdapterType;

    public isMapsIndoorsToolsVisible: boolean = true;
    public is2DToggleVisible: boolean = false;
    public is2DModelVisible: boolean = false;
    public is3DToggleVisible: boolean = false;
    public is3DModelVisible: boolean = false;
    public isRoadMapVisible: boolean = false;
    public isToolbarDefault: boolean = true;
    public editGraphBoundsComponent = EditGraphBoundsComponent;
    public currentTool: ComponentType<MapToolbar>;

    constructor(
        private mapAdapterMediator: MapAdapterMediator,
        private mapAdapterComponent: MapAdapterComponent,
        private mapToolbarService: MapToolbarService,
        private mapUIService: MapUIService
    ) {
        const visibleComponentsSubscription = this.mapUIService.visibleComponents$
            .subscribe(components => {
                this.isMapsIndoorsToolsVisible = (components & MapUIComponents.MapsIndoorsTools) === MapUIComponents.MapsIndoorsTools;
                this.isToolbarDefault = (components & MapUIComponents.Default) === MapUIComponents.Default;
            });

        const mapToolbarServiceSubscription = this.mapToolbarService.asObservable()
            .subscribe(({ component, mapToolbarRef }) => {
                this.show(component, mapToolbarRef);
                this.currentTool = component;
            });

        this.#subscriptions
            .add(visibleComponentsSubscription)
            .add(mapToolbarServiceSubscription);
    }

    /**
     * Angular OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this.#subscriptions.unsubscribe();
        this.mapToolsContainer.clear();
    }

    /**
     * Function that swaps out content in the toolbar.
     *
     * @private
     * @param {ComponentType<MapToolbar>} component
     * @param {MapToolbarRef} mapToolbarRef
     */
    private show(component: ComponentType<MapToolbar>, mapToolbarRef: MapToolbarRef): void {
        const componentRef = this.mapToolsContainer.createComponent<MapToolbar>(component);
        mapToolbarRef.componentRef = componentRef;
        const index = this.mapToolsContainer.indexOf(this.#defaultToolbar.hostView);

        if (index > -1) {
            this.mapToolsContainer.detach(index);
        }

        (componentRef.instance as MapToolbar).destroy
            .pipe(finalize(() => {
                this.mapToolsContainer.insert(this.#defaultToolbar.hostView);
                this.mapUIService.show(MapUIComponents.Default);
            }))
            .subscribe(() => {
                componentRef.destroy();
            });
    }

    /**
     * Angular OnInit lifecycle hook.
     */
    ngOnInit(): void {
        this.#defaultToolbar = this.mapToolsContainer.createComponent(DefaultMapToolsComponent);

        const mapAdapter: BaseMapAdapter = this.mapAdapterMediator?.getMapAdapter();
        mapAdapter.rotation$
            .subscribe((rotation) => {
                this.resetRotateAndTiltButton.nativeElement.style.transform = `rotate(${-rotation}deg)`;
            });

        this.is2DToggleVisible = mapAdapter.isModuleEnabled('2dmodels');
        this.is3DToggleVisible = this.mapAdapterType === MapAdapterType.MapboxAdapter
            && (mapAdapter.isModuleEnabled('3dmodels')
                || mapAdapter.isModuleEnabled('3dextrusions')
                || mapAdapter.isModuleEnabled('3dwalls'));

    }

    /**
     * The current map zoom level.
     *
     * @readonly
     * @type {number}
     * @memberof MapToolbarComponent
     */
    public get zoomLevel(): number {
        return Math.floor(this.mapAdapterComponent.getZoom() || 0);
    }

    /**
     * The current type of map-adapter.
     *
     * @public
     * @readonly
     * @type {MapAdapterType}
     */
    public get mapAdapterType(): MapAdapterType {
        return this.mapAdapterComponent.mapAdapterType;
    }

    /**
     * The current map type. (Roadmap or Hybrid).
     *
     * @public
     * @readonly
     * @type {MapType}
     */
    public get mapType(): MapType {
        return this.mapAdapterComponent.getMapType();
    }

    /**
     * Gets the current state of visibility for 2D or 3D layers.
     *
     * @public
     * @param {('2D' | '3D')} type
     * @returns {boolean}
     */
    public getVisibility(type: '2D' | '3D'): boolean {
        return this.mapAdapterComponent.getVisibility(type);
    }

    /**
     * Increase zoom level of the map (zoom in).
     *
     * @memberof MapToolbarComponent
     */
    public increaseZoomLevel(): void {
        this.mapAdapterComponent.setZoom(this.zoomLevel + 1);
    }

    /**
     * Decrease zoom level of the map (zoom out).
     *
     * @memberof MapToolbarComponent
     */
    public decreaseZoomLevel(): void {
        this.mapAdapterComponent.setZoom(this.zoomLevel - 1);
    }

    /**
     * Toggle between hybrid and roadmap.
     *
     * @public
     */
    public toggleSatelliteView(): void {
        const currentMapType = this.mapAdapterComponent.getMapType();

        this.mapAdapterComponent.setMapType(currentMapType === MapType.HYBRID ? MapType.ROADMAP : MapType.HYBRID);

        if (currentMapType === MapType.HYBRID) {
            this.isRoadMapVisible = false;
        } else if (currentMapType === MapType.ROADMAP) {
            this.isRoadMapVisible = true;
        }
    }

    /**
     * Toggle the visibility of 2D or 3D layers.
     *
     * @public
     * @param {('2D' | '3D')} type
     */
    public toggleVisibility(type: '2D' | '3D'): void {
        const visibility = this.getVisibility(type);

        if (type === '2D' && visibility === true) {
            this.is2DModelVisible = true;
        } else if (type === '2D' && visibility === false) {
            this.is2DModelVisible = false;
        }

        if (type === '3D' && visibility === true) {
            this.is3DModelVisible = true;
        } else if (type === '3D' && visibility === false) {
            this.is3DModelVisible = false;
        }

        this.mapAdapterComponent.setVisibility(type, !visibility);
    }

    /**
     * Resets the rotation and the tilt properties of the map.
     */
    public resetRotateAndTilt(): void {
        this.mapAdapterComponent.resetRotateAndTilt();
    }
}