import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { Point } from 'geojson';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import ImageCache from '../../app/adapter/MapViewState/ImageCache';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';

abstract class PointViewModelProperties implements ViewModelProperties {
    anchor: { x: number, y: number };
    featureClass: FeatureClass;
    icon: HTMLImageElement;
    clickable: boolean;
    originalId: string;
    originalType: MapsIndoorsData;
    scale: number;
    scaledSize: { width: number, height: number };
    sortKey: number;
    src: string;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a PointViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @returns {Promise<PointViewModelProperties>}
     */
    static async create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData): Promise<PointViewModelProperties> {
        const icon = await ImageCache.get(displayRule.icon);
        const iconSize = getIconSize(displayRule);
        return {
            anchor: getIconAnchor(displayRule),
            featureClass: FeatureClass.POINT,
            icon: icon,
            originalId: id,
            originalType,
            scale: iconSize.width / icon.naturalWidth,
            scaledSize: getIconSize(displayRule),
            sortKey: SortKey.POINT + sortKey,
            src: icon.src,
            zoomRange: [displayRule.zoomFrom, displayRule.zoomTo],
            clickable: displayRule.clickable
        };
    }
}

export class PointViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    readonly geometry: Point;
    readonly properties: PointViewModelProperties;

    private constructor(id: string, geometry: Point, properties) {
        this.id = `POINT:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory function to create a PointViewModel from an id, a point, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {Point} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @returns {PointViewModel}
     */
    static async create(id: string, geometry: Point, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData): Promise<PointViewModel> {
        const properties = await PointViewModelProperties.create(id, index, displayRule, originalType);
        return new PointViewModel(id, geometry, properties);
    }

}

/**
 * Get the icon anchor point.
 *
 * @param {DisplayRule} displayRule
 * @returns {{ x: number, y: number }}
 */
function getIconAnchor(displayRule: DisplayRule): { x: number, y: number } {
    const size = getIconSize(displayRule);
    return { x: Math.round(size.width / 2), y: Math.round(size.height / 2) };
}

/**
 * Get the icon size.
 *
 * @param {DisplayRule} displayRule
 * @returns {{ width: number, height: number }}
 */
function getIconSize(displayRule: DisplayRule): { width: number, height: number } {
    const iconSize = displayRule.imageSize;
    if (iconSize && iconSize.width > 0 && iconSize.height > 0) {
        return iconSize;
    }

    return { width: 24, height: 24 };
}
