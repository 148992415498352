import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { Category } from './category.model';
import { CategoryService } from './category.service';
import { Customer } from '../customers/customer.model';
import { CustomerService } from '../customers/customer.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotificationService } from '../services/notification.service';
import { SolutionService } from '../services/solution.service';
import { SyncManagerComponent } from '../shared/sync-manager/sync-manager.component';
import { finalize } from 'rxjs/operators';
import { primitiveClone } from '../shared/object-helper';

@Component({
    selector: 'categories',
    templateUrl: './categories.component.html'
})
export class CategoriesComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    newCategoryString: string = '';
    pageLoad: boolean = false;

    currentSolution: any;

    categories: Category[];
    currentCategory: Category;
    showDeleteOverlay: boolean = false;
    firstCategory: boolean = false;
    creatingNewCategory: boolean = false;
    selectedRow: string = ''; // Highlight row when selected
    sortBy: string = 'displayName';
    currentSortBy: string = 'displayName';
    sortReverse: boolean = false;

    public autoTranslate = false;
    public defaultLanguage: string;
    /** Solutions to sync to. */
    public syncSolutions = []; //TODO: set solution type

    constructor(
        private categoryService: CategoryService,
        private solutionService: SolutionService,
        private customerService: CustomerService,
        private dialog: MatDialog,
        private notificationService: NotificationService
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.solutionService.getCurrentSolution()
            .subscribe(solution => {
                this.currentSolution = solution;
                this.closeSidebar();
                this.getCategories(true);
            }));

        this.subscribeToCustomerChange();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Gets categories.
     *
     * @param {boolean} [fromBackend]
     * @memberof CategoriesComponent
     * @private
     */
    private getCategories(fromBackend?: boolean): void {
        this.pageLoad = true;
        this.categoryService.getCategories(fromBackend)
            .pipe(finalize(() => this.pageLoad = false))
            .subscribe(
                categories => {
                    this.categories = categories;
                },
                err => {
                    this.notificationService.showError(err);
                }
            );
    }

    sortTable(sortBy): void {
        if (this.sortBy === sortBy) {
            this.sortBy = '-' + sortBy;
            this.sortReverse = !this.sortReverse;
        } else if (this.sortBy === '-' + sortBy) {
            this.sortBy = sortBy;
            this.sortReverse = !this.sortReverse;
        } else {
            this.sortBy = sortBy;
            this.currentSortBy = sortBy;
            this.sortReverse = false;
        }
    }

    newCategory(): void {
        const solution = this.solutionService.getStaticSolution();
        this.creatingNewCategory = true;
        const newCat: any = {
            solutionId: solution.id,
            canCancel: true,
            newCategory: true,
            translations: this.newLanguageCollection()
        };
        this.currentCategory = newCat;
    }

    newLanguageCollection(): void {
        const languageObject: any = [];
        for (const lang of this.currentSolution.availableLanguages) {
            languageObject.push({ language: lang, name: '' });
        }
        return languageObject;
    }

    suggestionsGoogleTranslate(query): void {
        const langs = this.currentCategory?.translations;
        if (query && query.length > 2 && this.autoTranslate && langs.length > 1) {
            for (const lang of langs) {
                if (lang.name === undefined || lang.name.length < 1 || lang.name === null) {
                    this.categoryService.googleTranslate(query, lang.language)
                        .subscribe(result => {
                            lang.name = result.translations[0].translatedText;
                        }
                        );
                }
            }
            window.setTimeout(() => {
                if (this.currentCategory) {
                    this.currentCategory.translations = langs;
                }
            }, 350);
        }
    }

    editCategory(category): void {
        const categoryCopy = primitiveClone(category);
        this.currentCategory = categoryCopy;

        this.selectedRow = category.id; // Highlight selected row
    }

    /**
     * Creates or updates a category.
     *
     * @memberof CategoriesComponent
     */
    public saveCategory(): void {
        this.pageLoad = true;
        if (!this.currentCategory.id) {
            this.categoryService.createCategory(this.currentCategory)
                .pipe(finalize(() => this.pageLoad = false))
                .subscribe(
                    () => {
                        this.categories = this.categoryService.getCategoriesFromStore();
                        this.notificationService.showSuccess('Created category');
                    },
                    err => {
                        this.notificationService.showError(err);
                    }
                );
        } else {
            this.categoryService.updateCategory(this.currentCategory)
                .pipe(finalize(() => this.pageLoad = false))
                .subscribe(
                    () => {
                        this.categories = this.categoryService.getCategoriesFromStore();
                        this.notificationService.showSuccess('Updated category');
                    },
                    err => {
                        this.notificationService.showError(err);
                    }
                );
        }
        this.closeSidebar();
    }

    deleteCategory(): void {
        this.pageLoad = true;
        this.categoryService.deleteCategory(this.currentCategory)
            .pipe(finalize(() => this.pageLoad = false))
            .subscribe(
                () => {
                    this.categories = this.categoryService.getCategoriesFromStore();
                    this.notificationService.showSuccess('Deleted category');
                },
                err => {
                    this.notificationService.showError(err);
                }
            );
        this.closeSidebar();
    }

    closeSidebar(): void {
        this.currentCategory = null;
        this.creatingNewCategory = false;
        this.showDeleteOverlay = false;
        this.selectedRow = '';
    }

    openDeleteOverlay(): void {
        this.showDeleteOverlay = true;
    }

    closeDeleteOverlay(): void {
        this.showDeleteOverlay = false;
    }

    /**
     * Subscribe to customer change.
     *
     * @private
     * @memberof CategoriesComponent
     */
    private subscribeToCustomerChange(): void {
        this.subscriptions.add((this.customerService.getCurrentCustomer() as Observable<Customer>)
            .subscribe(customer => {
                this.syncSolutions = this.solutionService.getActiveSolutions()
                    .filter(solution => {
                        return (solution.customerId === customer.id && solution.id !== this.solutionService.getStaticSolution().id);
                    });
            }));
    }

    /**
     * Opens sync manager.
     *
     * @memberof CategoriesComponent
     */
    public openSyncDialog(category: Category): void {
        this.dialog.open(SyncManagerComponent, {
            width: '640px',
            height: '580px',
            disableClose: false,
            role: 'dialog',
            autoFocus: false,
            ariaLabel: 'Sync Manager',
            panelClass: 'manager-dialog',
            closeOnNavigation: true,
            data: { category, solutions: this.syncSolutions }
        });
    }
}
