import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MediaUploadComponent } from '../media-upload/media-upload.component';

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Scene, Vector3, Color } from 'three';
import { MediaLibraryService } from '../media-library.service';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'media-upload-3d',
    templateUrl: './media-upload-3d.component.html',
    styleUrls: ['./media-upload-3d.component.scss']
})

export class MediaUpload3DComponent implements OnInit {
    @ViewChild('canvas3D', { static: true }) canvas3D: ElementRef<HTMLCanvasElement>;
    @ViewChild('canvas3DContainer', { static: true }) canvas3DContainer: ElementRef<HTMLCanvasElement>;
    @ViewChild('previewImage', { static: true }) previewImage: ElementRef<HTMLImageElement>;

    private sizes: { width: number, height: number };
    private model3dUrl: string = URL.createObjectURL(new Blob([this.data.file]));
    private renderer: THREE.WebGLRenderer;
    private camera: THREE.PerspectiveCamera;
    private scene: THREE.Scene = new THREE.Scene();
    private fileScene: Scene = new Scene();
    private objectVector: THREE.Vector3;
    private modelOrbitControls: OrbitControls;
    private originalScale: THREE.Vector3;

    public fileName: string = this.data.file.name;
    public isFormDirty: boolean = false;

    constructor(
        private mediaLibraryService: MediaLibraryService,
        private notificationService: NotificationService,
        private dialogRef: MatDialogRef<MediaUploadComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { file: File, mediaId?: string }
    ) { }

    /**
     * NgOnInit.
     *
     * @memberof MediaUpload3DComponent
     */
    ngOnInit(): void {
        const container = this.canvas3DContainer.nativeElement;
        this.sizes = { width: container.clientWidth, height: container.clientHeight };

        window.onresize = () => {
            this.sizes = { width: container.clientWidth, height: container.clientHeight };
            this.renderer.setSize(this.sizes.width, this.sizes.height);
            this.camera.aspect = this.sizes.width / this.sizes.height;
            this.render();
        };

        this.renderer = this.createRenderer();
        this.camera = this.createCamera();

        this.mediaLibraryService.load3D(this.model3dUrl)
            .then((file) => {
                this.fileScene.add(file.scene);

                // 3D model bounding box
                const objectBoundingBox = new THREE.Box3().setFromObject(this.fileScene);
                this.objectVector = objectBoundingBox.getSize(new THREE.Vector3());

                this.fitModelToContainer();

                // Orbit controls for moving the camera around a 3D object - zoom in/out and move the camera around
                this.modelOrbitControls = this.createOrbitControls();

                // Lights
                const hemisphereLight = this.createHemisphereLight(new Color('#B1B5CD'), new Color('#988F86'), 1.2, new Vector3(0, 10, 0));

                this.scene.add(this.camera);
                this.scene.add(hemisphereLight);
                this.scene.add(this.fileScene);

                this.render();
            })
            .catch(() => {
                this.notificationService.showError('3D model could not be loaded.');
                this.dialogRef.close();
            });
    }

    /**
     * Creates a renderer.
     *
     * @returns {THREE.WebGLRenderer}
     * @memberof MediaUpload3DComponent
     */
    private createRenderer(): THREE.WebGLRenderer {
        const renderer = new THREE.WebGLRenderer({ canvas: this.canvas3D.nativeElement, alpha: true, preserveDrawingBuffer: true });

        renderer.setSize(this.sizes.width, this.sizes.height); // Resizing the output canvas to the size of the container
        renderer.setPixelRatio(window.devicePixelRatio); // Prevents blurring
        renderer.outputEncoding = THREE.sRGBEncoding; // Needed to show colors differently.s

        return renderer;
    }

    /**
     * Creates a camera.
     *
     * @returns {THREE.PerspectiveCamera}
     * @memberof MediaUpload3DComponent
     */
    private createCamera(): THREE.PerspectiveCamera {
        // fov - the smaller its value, the smaller the camera angle becomes and the objects will appear larger
        // aspect - the ratio between the width and the height of the div element
        // near - the distance from the camera to the near clipping plane (objects closer than this will dissapear)
        // far - the distance from the camera to the far clipping plane (objects further than this will dissapear)
        const camera = new THREE.PerspectiveCamera(40, this.sizes.width / this.sizes.height, 2, 100); // fov, aspect, near, far --> defines the frustum
        return camera;
    }

    /**
     * Creates an orbit controls.
     * The controls have to orbit around the object that is uploaded, the target has to be the same as the 3D model's position.
     *
     * @returns {OrbitControls}
     * @memberof MediaUpload3DComponent
     */
    private createOrbitControls(): OrbitControls {
        const orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

        orbitControls.target = new THREE.Vector3(0, 0, -(this.objectVector.z + 3));
        orbitControls.zoomO = 0;

        orbitControls.update();
        orbitControls.saveState();

        const originalVerticalAngle = orbitControls.getPolarAngle();
        const originalHorizontalAngle = orbitControls.getAzimuthalAngle();
        const originalCameraDistance = orbitControls.getDistance();

        orbitControls.addEventListener('change', () => {
            if (!this.isFormDirty
                && (originalVerticalAngle !== orbitControls.getPolarAngle()
                    || originalHorizontalAngle !== orbitControls.getAzimuthalAngle()
                    || originalCameraDistance !== orbitControls.getDistance())) {
                this.isFormDirty = true;
            } else if (this.isFormDirty
                && originalVerticalAngle === orbitControls.getPolarAngle()
                && originalHorizontalAngle === orbitControls.getAzimuthalAngle()
                && originalCameraDistance === orbitControls.getDistance()) {
                this.isFormDirty = false;
            }

            this.render();
        });

        return orbitControls;
    }

    /**
     * Creates an HemisphereLight.
     *
     * @param {Color} color
     * @param {Color} groundColor
     * @param {number} intensity
     * @param {Vector3} position
     * @returns {THREE.HemisphereLight}
     * @memberof MediaUpload3DComponent
     */
    private createHemisphereLight(color: Color, groundColor: Color, intensity: number, position: Vector3): THREE.HemisphereLight {
        const hemisphereLight = new THREE.HemisphereLight(color, groundColor, intensity);
        hemisphereLight.position.set(position.x, position.y, position.z);

        return hemisphereLight;
    }

    /**
     * Resizes the uploaded model to fit inside the div container.
     *
     * @memberof MediaUpload3DComponent
     */
    private fitModelToContainer(): void {
        // Ratio between container box and the 3D model
        const baseVector = new THREE.Vector3(3, 3, 3);
        const ratio = baseVector.divide(this.objectVector);
        const smallestRatio = Math.min(ratio.x, ratio.y, ratio.z);

        this.originalScale = new Vector3(this.fileScene.scale.x * smallestRatio, this.fileScene.scale.y * smallestRatio, this.fileScene.scale.z * smallestRatio);

        // Scale the 3D model to fit the container bounding box
        this.fileScene.scale.set(this.originalScale.x, this.originalScale.y, this.originalScale.z);
        this.setPosition(this.fileScene, new Vector3(0, 0, -(this.objectVector.z + 3))); // number 3 -- original sized of the baseVector that is used to calculate the ratio
    }

    /**
     * Set an element's position.
     *
     * @param {Scene | THREE.PerspectiveCamera} element
     * @param {THREE.Vector3} position
     * @memberof MediaUpload3DComponent
     */
    private setPosition(element: Scene | THREE.PerspectiveCamera, position: THREE.Vector3): void {
        element.position.x = position.x;
        element.position.y = position.y;
        element.position.z = position.z;
    }

    /**
     * Renders the scene and camera.
     *
     * @memberof MediaUpload3DComponent
     */
    private render(): void {
        this.renderer.render(this.scene, this.camera);
    }

    /**
     * Closes the modal after a confirm message.
     *
     * @memberof MediaUpload3DComponent
     */
    public onClose(): void {
        let confirmMessage: string;
        if (!this.data?.mediaId) {
            confirmMessage = 'If you continue without saving, the current file will not be uploaded. Do you want to continue?';
        } else {
            confirmMessage = 'If you continue without saving, you will lose your changes. Do you want to continue?';
        }
        // eslint-disable-next-line no-alert
        if (confirm(confirmMessage)) {
            this.dialogRef.close();
        }
    }

    /**
     * Sets the new scale value.
     *
     * @param {boolean} increase - True if the scale should be increased, false otherwise.
     * @memberof MediaUpload3DComponent
     */
    public onScaleChange(increase: boolean): void {
        const scale = this.fileScene.scale.x;
        const newScale = increase ? scale + 0.1 : scale - 0.1;
        this.fileScene.scale.set(newScale, newScale, newScale);
        this.render();
    }

    /**
     * Resets the controls and object rotation+position+zoom to the original.
     *
     * @memberof MediaUpload3DComponent
     */
    public onResetAll(): void {
        this.modelOrbitControls.reset();
        this.fileScene.scale.set(this.originalScale.x, this.originalScale.y, this.originalScale.z);
    }

    /**
     * Resets all values after confirm message.
     *
     * @memberof MediaUpload3DComponent
     */
    public onDiscard(): void {
        // eslint-disable-next-line no-alert
        if (confirm('Discard all changes?')) {
            this.onResetAll();
        }
    }

    /**
     * Closes the modal and attaches the preview image's data to the response.
     *
     * @memberof MediaUpload3DComponent
     */
    public onSave(): void {
        const previewSize = {
            width: 300, // max width of a preview image allowed by the backend
            height: 150 // respecting 2:1 aspect ratio
        };
        const previewSrc = this.renderer.domElement.toDataURL();

        // Needed to ensure that the image source we save is not bigger than 300px. The current canvas's size cannot be predicted.
        const previewImage = new Image();
        previewImage.src = previewSrc;
        previewImage.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = previewSize.width;
            canvas.height = previewSize.height;
            const canvasContext = canvas.getContext('2d'); // two-dimensional rendering context
            canvasContext.drawImage(previewImage, 0, 0, previewSize.width, previewSize.height);
            const shrinkedImageSrc = canvas.toDataURL();
            this.dialogRef.close({ previewSrc: shrinkedImageSrc });
        };
    }

    /**
     * Calls the onClose() function when the user hits the Escape key.
     *
     * @memberof MediaUpload3DComponent
     */
    @HostListener('keydown.escape', ['$event'])
    public onEscapeHandler(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.onClose();
        }
    }
}