export class Address {
    city: string = '';
    street1: string = '';
    street2?: string = '';
    region: string = '';
    postalCode: string = '';
    country: string = '';

    constructor(address?: Address) {
        Object.assign(this, address);
    }

    static readonly EMPTY: Address = Object.freeze({
        street1: null,
        street2: null,
        city: null,
        region: null,
        postalCode: null,
        country: null
    });

    /**
     * Creates an Address object. Returns null if every property is empty.
     *
     * @param {Address} address
     * @returns {Address}
     */
    static createAddress(address?: Address): Address {
        if (address && Object.values(address).every((value) => value === null)) {
            return null;
        } else {
            return new Address(address);
        }
    }
}