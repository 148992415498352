
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LocationsModule } from '../locations/location.module';
import { MapModule } from '../map/map.module';
import { OccupantTemplatesModule } from '../occupant-templates/occupant-templates.module';
import { SharedModule } from '../shared/shared.module';
import { EditorContainerComponent } from './editor-container/editor-container.component';
import { LocationDetailsEditorComponent } from './editor-container/location-details-editor/location-details-editor.component';
import { OccupantDetailsEditorComponent } from './editor-container/occupant-details-editor/occupant-details-editor.component';
import { FloorSelectorComponent } from './floor-selector/floor-selector.component';
import { GraphListComponent } from './graph-list/graph-list.component';
import { MapAdapterComponent } from './map-adapter/map-adapter.component';
import { MapContainerComponent } from './map-container/map-container.component';
import { MapSidebarComponent } from './map-sidebar/map-sidebar.component';
import { MapToolbarComponent } from './map-toolbar/map-toolbar.component';
import { AddPOIToolbarComponent } from './map-toolbar/tools/add-poi/add-poi-toolbar.component';
import { CreateAreaComponent } from './map-toolbar/tools/create-area/create-area.component';
import { DefaultMapToolsComponent } from './map-toolbar/tools/default/default-map-tools.component';
import { EmptyToolbarComponent } from './map-toolbar/tools/empty/empty-toolbar.component';
import { LocationDetailsToolbarComponent } from './map-toolbar/tools/location-details/location-details-toolbar.component';
import { AddMultipleDoorsFreehandComponent } from './map-toolbar/tools/route-network-tools/add-multiple-doors-freehand/add-multiple-doors-freehand.component';
import { AddMultipleDoorsComponent } from './map-toolbar/tools/route-network-tools/add-multiple-doors/add-multiple-doors.component';
import { EditGraphComponent } from './map-toolbar/tools/route-network-tools/edit-graph/edit-graph.component';
import { RouteNetworkToolsComponent } from './map-toolbar/tools/route-network-tools/route-network-tools.component';
import { RouteElementDetailsEditorComponent } from './route-element-details-editor/route-element-details-editor.component';
import { RouteElementSubtypeComponent } from './route-element-details-editor/route-element-subtype/route-element-subtype.component';

@NgModule({
    declarations: [
        AddMultipleDoorsComponent,
        AddMultipleDoorsFreehandComponent,
        AddPOIToolbarComponent,
        CreateAreaComponent,
        DefaultMapToolsComponent,
        EditGraphComponent,
        EditorContainerComponent,
        FloorSelectorComponent,
        GraphListComponent,
        LocationDetailsEditorComponent,
        LocationDetailsToolbarComponent,
        MapAdapterComponent,
        MapContainerComponent,
        MapSidebarComponent,
        MapToolbarComponent,
        OccupantDetailsEditorComponent,
        EmptyToolbarComponent,
        RouteElementDetailsEditorComponent,
        RouteElementSubtypeComponent,
        RouteNetworkToolsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        LocationsModule,
        MapModule,
        OccupantTemplatesModule,
        ReactiveFormsModule,
        SharedModule,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class MapAdapterModule { }