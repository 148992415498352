import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, OperatorFunction, ReplaySubject, Subject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { DataService } from '../services/data.service';
import { GraphSetup } from '../shared/enums/GraphSetup.enum';
import { Injectable } from '@angular/core';
import { Solution } from '../solutions/solution.model';
import { SolutionService } from '../services/solution.service';
import { Venue } from './venue.model';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class VenueService {
    private api = environment.APIEndpoint;
    private selectedVenueSubject = new ReplaySubject<Venue>(1);
    private venuesSubject = new ReplaySubject<Venue[]>(1);
    private venueEvent = new Subject<string>();
    private currentSolution: Solution;
    private selectedVenue: Venue;
    private venues: Venue[];
    /**
     * Selected Venue observable.
     *
     * @readonly
     * @returns {Observable<Venue>}
     * @memberof VenueService
     */
    public get selectedVenue$(): Observable<Venue> {
        return this.selectedVenueSubject.asObservable();
    }

    /**
     * Venues observable.
     *
     * @readonly
     * @returns {Observable<Venue[]>}
     * @memberof VenueService
     */
    public get venues$(): Observable<Venue[]> {
        return this.venuesSubject.asObservable();
    }

    constructor(
        private solutionService: SolutionService,
        private dataService: DataService,
        private http: HttpClient
    ) {
        solutionService.getCurrentSolution()
            .pipe(
                tap((solution) => this.currentSolution = solution),
                switchMap((solution) =>
                    this.fetchVenues(solution)
                        .pipe(tap(venues => {
                            this.venues = venues;
                            this.venuesSubject.next(venues);
                        }))
                )
            ).subscribe();
    }

    /**
     * Fetch and format venues from the backend.
     *
     * @private
     * @param {Solution} solution
     * @returns {Observable<Venue[]>}
     * @memberof VenueService.
     */
    private fetchVenues(solution: Solution): Observable<Venue[]> {
        return this.dataService.getItems<Venue>(`${solution.id}/api/venues`, { params: { details: true } })
            .pipe(this.formatVenues(solution));
    }


    /**
     * Format venues OperatorFunction.
     *
     * @param {Solution} solution
     * @returns {OperatorFunction<Venue[], Venue[]>}
     * @memberof VenueService
     */
    private formatVenues(solution: Solution): OperatorFunction<Venue[], Venue[]> {
        return map((venues: Venue[]) => venues.map(venue => this.formatVenue(venue, solution.defaultLanguage)));
    }

    /**
     * Format a venue.
     *
     * @param {Venue} venue
     * @param {string} defaultLanguage
     * @returns {Venue}
     * @memberof VenueService
     */
    private formatVenue(venue, defaultLanguage): Venue {
        const venueInfo = venue.venueInfo?.find(info => info.language === defaultLanguage);
        //Set the displayName to the default translation or the first translation.
        venue.displayName = venueInfo.name ?? venue.venueInfo[0].name;


        // Setting graphSetup to Manual if stored venue.graphSetup is not Automatic or is missing/null
        if (venue.graphSetup !== GraphSetup.Automatic) {
            venue.graphSetup = GraphSetup.Manual;
        }

        return venue;
    }

    /**
     * Get venues from the store cache.
     *
     * @returns {Observable<Venue[]>}
     * @memberof VenueService
     */
    public getVenues(): Observable<Venue[]> {
        return this.venues$.pipe(take(1));
    }

    /**
     * Get the list of venues for a specific solution.
     *
     * @param {Solution} solution
     * @returns {Observable<Venue[]>}
     * @memberof VenueService
     */
    public getVenuesForSolution(solution: Solution): Observable<Venue[]> {
        return this.fetchVenues(solution);
    }

    /**
     * Update and save venue in store.
     *
     * @param {Venue} venue
     * @returns {*}  {(Observable<void>)}.
     * @memberof VenueService
     */
    public updateVenue(venue: Venue): Observable<void> {
        const solution = this.solutionService.getStaticSolution();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put<void>(this.api + solution.id + '/api/venues/', venue, { headers })
            .pipe(tap(() => {
                // Update venue in store
                const formattedVenue = this.formatVenue(venue, solution.defaultLanguage);
                const index = this.venues.findIndex(venue => venue.id === formattedVenue.id);
                this.venues.splice(index, 1, formattedVenue);

                // Publish update to subscribers
                if (formattedVenue.id === this.selectedVenue?.id) {
                    this.selectedVenueSubject.next(formattedVenue);
                }
            }));
    }

    public createVenue(venue): Observable<Venue> {
        const solution = this.solutionService.getStaticSolution();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<Venue>(this.api + solution.id + '/api/venues/', venue, { headers });
    }

    public setSelectedVenue(venue): void {
        if (this.selectedVenue?.id !== venue?.id) {
            this.selectedVenue = venue;
            localStorage.setItem('MI:VenueId', venue ? venue.id : '');
            this.selectedVenueSubject.next(venue);
        }
    }

    public getSelectedVenue(): Observable<Venue> {
        return this.selectedVenue$
            .pipe(
                filter(venue => !!venue),
                take(1)
            );
    }

    public setVenueEvent(event): void {
        this.venueEvent.next(event);
    }

    public getVenueEvent(): Observable<string> {
        return this.venueEvent.asObservable();
    }

}

export enum VenueStoreActions {
    AddVenue = 'ADD_VENUE',
    GetVenue = 'GET_VENUE',
    EditVenue = 'EDIT_VENUE',
    RemoveVenue = 'REMOVE_VENUE',
    GetVenues = 'GET_VENUES',
    UpdateCurrentVenue = 'UPDATE_CURRENT_VENUE',
}
