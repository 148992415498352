import { BaseMapAdapter, MapMouseCursor, MapOptions, MapPadding, MapType, calculateBounds } from '../BaseMapAdapter';
import mapboxgl, { RasterSource } from 'mapbox-gl';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import GeoJSON from 'geojson';
import { FeatureClass } from '../../../viewmodels/FeatureClass';
import { Mapbox3DLayer } from './Mapbox3DLayer';
import { Mapbox2DLayer } from './Mapbox2DLayer';

const MI_GEOJSON_SOURCE = 'MI_GEOJSON_SOURCE';
const MI_TILES_SOURCE = 'MI_TILES_SOURCE';

const MI_EXTRUSION_LAYER = 'MI_EXTRUSION_LAYER';
const MI_MODEL_2D_LAYER = 'MI_MODEL_2D_LAYER';
const MI_MODEL_3D_LAYER = 'MI_MODEL_3D_LAYER';
const MI_OUTLINE_LAYER = 'MI_OUTLINE_LAYER';
const MI_POINT_LAYER = 'MI_POINT_LAYER';
const MI_POLYGON_LAYER = 'MI_POLYGON_LAYER';
const MI_ROUTE_NETWORK_LAYER = 'MI_ROUTE_NETWORK_LAYER';
const MI_TILES_LAYER = 'MI_TILES_LAYER';
const MI_WALL_LAYER = 'MI_WALL_LAYER';
const MI_POLYGON_OBSTACLE_LAYER = 'MI_POLYGON_OBSTACLE_LAYER';

const MAIN_DISPLAY_RULE = DisplayRuleService.MAIN_DISPLAY_RULE;

export class MapboxAdapter extends BaseMapAdapter {
    #map: mapboxgl.Map;
    #mapType: MapType = MapType.ROADMAP;
    #tileUrl: string;
    #viewData: GeoJSON.Feature[] = [];
    #mapbox3DLayer: Mapbox3DLayer = new Mapbox3DLayer(MI_MODEL_3D_LAYER);
    #mapbox2DLayer: Mapbox2DLayer = new Mapbox2DLayer(MI_MODEL_2D_LAYER);
    #wallOpacity: number = 1;
    #extrusionOpacity: number = 1;

    constructor(element: HTMLElement, mapOptions: MapOptions) {
        super(element, mapOptions);
        mapboxgl.accessToken = 'pk.eyJ1IjoibWFwc3Blb3BsZSIsImEiOiJjbGNrNWZsM3YwYzdwM3ZwODd3bXhjMmU4In0.ZtFUUSvc-nk9xJD_dyuA5w';

        loadMapboxCSS().then(() => {
            new mapboxgl.Map({
                ...mapOptions,
                container: element,
                center: mapOptions?.center?.coordinates as [number, number],
                style: 'mapbox://styles/mapbox/streets-v11',
                boxZoom: false
            })
                .once('load', e => {
                    this.#map ??= e.target;
                    //This is necessary to ensure that the map fills the container element.
                    this.#map.resize();
                    this.#map.on('moveend', () => this.emit('idle'));
                    this.#map.on('zoomend', () => this.emit('zoom_changed'));
                    // To align with Mapsindoors zoom level putting number 14 will result with minZoom = 15
                    this.#map.setMinZoom(14);

                    this.#map.on('click', [MI_POINT_LAYER, MI_POLYGON_LAYER, MI_EXTRUSION_LAYER, MI_WALL_LAYER], event => {
                        if (this.isClickable) {
                            const feature = this.#map.queryRenderedFeatures(event.point)
                                .filter(feat => [FeatureClass.POINT, FeatureClass.POLYGON, FeatureClass.EXTRUSION].includes(feat?.properties?.featureClass)
                                    && !feat.properties?.originalId?.startsWith('HOVER:')
                                    && feat?.properties?.clickable !== false);

                            if (feature) {
                                // Clicks on the first feature that meets the requirements set in filter.
                                this.clickSubject.next(feature[0]);
                            }
                        }
                    });

                    this.#map.on('mousemove', MI_POINT_LAYER, e => {
                        const features = e.features.filter(feature => !feature.properties.originalId.startsWith('HOVER:'));
                        const feature = features[0]; // topmost feature

                        if (feature) {
                            this.hoverSubject.next({ locationId: feature.properties.originalId, featureType: 'point' });
                        } else {
                            this.hoverSubject.next({ locationId: null, featureType: 'point' });
                        }
                    });

                    this.#map.on('mouseleave', MI_POINT_LAYER, () => {
                        this.hoverSubject.next({ locationId: null, featureType: 'point' });
                    });

                    this.#map.on('mousemove', MI_POLYGON_LAYER, e => {
                        const features = e.features.filter(feature => !feature.properties.originalId.startsWith('HOVER:'));
                        const feature = features[0]; // topmost feature
                        if (feature) {
                            this.hoverSubject.next({ locationId: feature.properties.originalId, featureType: 'polygon' });
                        } else {
                            this.hoverSubject.next({ locationId: null, featureType: 'polygon' });
                        }
                    });

                    this.#map.on('mouseleave', MI_POLYGON_LAYER, () => {
                        this.hoverSubject.next({ locationId: null, featureType: 'polygon' });
                    });

                    this.#map.on('move', () => {
                        this.centerSubject.next(this.getCenter());
                        this.boundsSubject.next(this.getBounds());
                    });

                    this.#map.on('rotate', () => {
                        const bearing = this.#map.getBearing();
                        this.rotationSubject.next(bearing);
                    });

                    this.emit('ready');
                })
                .on('style.load', this.#onStyleLoadHandler.bind(this));
        });

    }

    /**
     * Handler for the Mapbox 'style.load' event.
     * Ensures that all MapsIndoors layers are added to the map.
     */
    #onStyleLoadHandler(e: mapboxgl.MapboxEvent): void {
        this.#map ??= e.target;
        this.#map.addSource(MI_GEOJSON_SOURCE, { type: 'geojson', data: { 'type': 'FeatureCollection', 'features': [] } });

        this.setOverlayTileUrl(this.#tileUrl);

        //This layer is for Polygon data.
        this.#map.addLayer({
            'filter': ['any',
                ['==', 'featureClass', FeatureClass.POLYGON],
                ['==', 'featureClass', FeatureClass.FLOOR_PLAN],
            ],
            'id': MI_POLYGON_LAYER,
            'type': 'fill',
            'source': MI_GEOJSON_SOURCE,
            'layout': {
                'fill-sort-key': ['number', ['get', 'sortKey'], Number.MAX_SAFE_INTEGER],
            },
            'paint': {
                'fill-color': ['to-color', ['get', 'fillColor'], MAIN_DISPLAY_RULE.polygon.fillColor],
                'fill-opacity': ['number', ['get', 'fillOpacity'], MAIN_DISPLAY_RULE.polygon.fillOpacity],
            }
        });

        //This layer is for outlining polygons.
        this.#map.addLayer({
            'filter': ['any',
                ['==', 'featureClass', FeatureClass.POLYGON],
                ['==', 'featureClass', FeatureClass.LINESTRING],
                ['==', 'featureClass', FeatureClass.FLOOR_PLAN],
            ],
            'id': MI_OUTLINE_LAYER,
            'type': 'line',
            'source': MI_GEOJSON_SOURCE,
            'layout': {
                'line-sort-key': ['number', ['get', 'sortKey'], Number.MAX_SAFE_INTEGER],
            },
            'paint': {
                'line-width': ['number', ['get', 'strokeWidth'], MAIN_DISPLAY_RULE.polygon.strokeWidth],
                'line-color': ['to-color', ['get', 'strokeColor'], MAIN_DISPLAY_RULE.polygon.strokeColor],
                'line-opacity': ['number', ['get', 'strokeOpacity'], MAIN_DISPLAY_RULE.polygon.strokeOpacity],
            }
        });

        this.#map.addLayer(this.#mapbox2DLayer);
        this.#map.setLayoutProperty(MI_MODEL_2D_LAYER, 'visibility', this.isModuleEnabled('2dmodels') ? 'visible' : 'none');

        this.#map.addLayer(this.#mapbox3DLayer);
        this.#map.setLayoutProperty(MI_MODEL_3D_LAYER, 'visibility', this.isModuleEnabled('3dmodels') ? 'visible' : 'none');

        //This layer is for walls and extrusions.
        this.#map.addLayer({
            'filter': ['any',
                ['==', 'featureClass', FeatureClass.WALL]
            ],
            'id': MI_WALL_LAYER,
            'type': 'fill-extrusion',
            'source': MI_GEOJSON_SOURCE,
            'layout': { 'visibility': this.isModuleEnabled('3dwalls') ? 'visible' : 'none' },
            'paint': {
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-opacity': this.#wallOpacity,
                'fill-extrusion-height': ['get', 'height']
            }
        });

        this.#map.addLayer({
            'filter': ['any',
                ['==', 'featureClass', FeatureClass.EXTRUSION]
            ],
            'id': MI_EXTRUSION_LAYER,
            'type': 'fill-extrusion',
            'source': MI_GEOJSON_SOURCE,
            'layout': { 'visibility': this.isModuleEnabled('3dextrusions') ? 'visible' : 'none' },
            'paint': {
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-opacity': this.#extrusionOpacity,
                'fill-extrusion-height': ['get', 'height']
            }
        });

        //This layer is for outlining route network.
        this.#map.addLayer({
            'filter': ['any',
                ['==', 'featureClass', FeatureClass.NETWORK]
            ],
            'id': MI_ROUTE_NETWORK_LAYER,
            'type': 'line',
            'source': MI_GEOJSON_SOURCE,
            'layout': {
                'line-sort-key': ['number', ['get', 'sortKey'], Number.MAX_SAFE_INTEGER],
            },
            'paint': {
                'line-width': ['number', ['get', 'strokeWidth'], MAIN_DISPLAY_RULE.polygon.strokeWidth],
                'line-color': ['to-color', ['get', 'strokeColor'], MAIN_DISPLAY_RULE.polygon.strokeColor],
                'line-opacity': ['number', ['get', 'strokeOpacity'], MAIN_DISPLAY_RULE.polygon.strokeOpacity],
            }
        });

        //This layer is for Point data.
        this.#map.addLayer({
            'filter': ['all',
                ['==', '$type', 'Point'],
                ['==', 'featureClass', FeatureClass.POINT]
            ],
            'id': MI_POINT_LAYER,
            'type': 'symbol',
            'source': MI_GEOJSON_SOURCE,
            'layout': {
                'icon-optional': true,
                'icon-image': ['get', 'src'],
                'icon-size': ['number', ['get', 'scale'], 1],
                'icon-allow-overlap': true,
                'symbol-placement': 'point',
                'symbol-sort-key': ['number', ['get', 'sortKey'], Number.MAX_SAFE_INTEGER],
                'text-padding': 0,
                'text-optional': true,
                'text-field': ['get', 'labelText'],
                'text-anchor': 'top',
                'text-font': ['literal', ['Open Sans Regular', 'Arial Unicode MS Regular']], // TODO: Offer a predefined set of font families, due to not being able to use a get expression (MISDKJS-426)
                'text-size': ['number', ['get', 'labelSize'], 12],
                //MapBox uses ems for text-max-width. To get the width as ems the label max-width is divided by the font size.
                'text-max-width': ['/', ['number', ['get', 'labelMaxWidth'], 10], ['number', ['get', 'labelSize'], 1]],
                'text-offset': ['coalesce', ['get', 'labelOffset'], ['literal', [0, 0]]], // text-translate works with pixels, but does not support data expressions, so we have to use text-offset, which takes em units
                'text-allow-overlap': false
            },
            'paint': {
                'text-color': ['to-color', ['get', 'labelColor'], 'hsl(217, 11%, 23%)'],
                'text-halo-color': ['to-color', ['get', 'labelShadowColor'], '#000'],
                'text-halo-width': ['number', ['get', 'labelShadowBlur'], 0],
                'text-halo-blur': ['case', ['>', ['get', 'labelShadowBlur'], 0], 1, 0]
            }
        });

        // Layer for the obstacle polygon shown when editing Polygon geometries.
        this.#map.addLayer({
            'id': MI_POLYGON_OBSTACLE_LAYER,
            'type': 'line',
            'source': MI_GEOJSON_SOURCE,
            'filter': ['all',
                ['==', 'featureClass', FeatureClass.AREAASOBSTACLE]
            ],
            'paint': {
                'line-width': ['number', 3],
                'line-color': ['to-color', '#FDBA74'],
                'line-opacity': ['number', 1],
            }
        });

        this.setViewData(this.#viewData);
    }

    /**
     * Sets the viewport to contain the given bounds.
     *
     * @param {GeoJSON.BBox} bounds
     * @param {MapPadding} [padding]
     * @memberof MapboxAdapter
     */
    fitBounds(bounds: GeoJSON.BBox, padding?: MapPadding): void {
        // The value of `1500` for the `maxDuration` property was assigned after testing what value resembles the Google Maps animation the best.
        this.#map.fitBounds(bounds as [number, number, number, number], { padding, bearing: this.#map.getBearing(), maxDuration: 1500 });
    }

    /**
     * Returns the center of the map.
     *
     * @returns {GeoJSON.Point}
     * @memberof MapboxAdapter
     */
    getCenter(): GeoJSON.Point {
        return { type: 'Point', coordinates: this.#map.getCenter().toArray() };
    }

    /**
     * Switching the cursor type with the incoming value.
     *
     * @param {MapMouseCursor} type
     * @memberof MapboxAdapter
     */
    public setMapMouseCursor(type: MapMouseCursor): void {
        const canvas = this.#map.getCanvasContainer();
        canvas.style.cursor = type;
    }

    /**
     * Get the current mouse cursor type.
     *
     * @returns {MapMouseCursor}
     * @memberof MapboxAdapter
     */
    public getMapMouseCursor(): MapMouseCursor {
        const canvas = this.#map.getCanvasContainer();
        return canvas.style.cursor as MapMouseCursor;
    }

    /**
     * Returns the map's geographical bounds.
     *
     * @returns {GeoJSON.BBox}
     * @memberof MapboxAdapter
     */
    getBounds(): GeoJSON.BBox {
        //[west, south, east, north]
        return this.#map.getBounds().toArray().flat() as GeoJSON.BBox;
    }

    /**
     * Returns the map instance.
     *
     * @returns {mapboxgl.Map}
     * @memberof MapboxAdapter
     */
    getMap(): Promise<mapboxgl.Map> {
        return new Promise((resolve) => {
            if (this.#map instanceof mapboxgl.Map) {
                return resolve(this.#map);
            }
            this.once('ready', () => resolve(this.#map));
        });
    }

    /**
     * Get the current map type.
     *
     * @returns {MapType}
     */
    getMapType(): MapType {
        return this.#mapType;
    }

    /**
     * Returns the zoom of the map.
     *
     * @returns {number}
     * @memberof MapboxAdapter
     */
    getZoom(): number {
        //To align Mapbox zoom levels with MapsIndoors 1 is added.
        return this.#map?.getZoom() + 1;
    }

    /**
     * Pans the map to the specified location.
     *
     * @param {GeoJSON.Point} position
     * @memberof MapboxAdapter
     */
    panTo(position: GeoJSON.Point): void {
        this.#map.panTo(position.coordinates as [number, number]);
    }

    /**
     * Pans the map to the given geometry.
     *
     * @param {GeoJSON.Geometry} geometry - The geometry to pan to.
     * @param {MapPadding} [padding] - The amount of padding in pixels to add to the map bounds.
     */
    panToGeometry(geometry: GeoJSON.Geometry, padding?: MapPadding): void {
        this.#map.fitBounds(calculateBounds(geometry) as [number, number, number, number], { padding, bearing: this.#map.getBearing(), maxZoom: this.#map.getZoom(), maxDuration: 1500 });
    }
    /**
     * Returns {x, y} representing pixel coordinates, relative to the map's container, that correspond to the specified GeoJSON Point.
     *
     * @returns {{ x: number, y: number }}
     * @memberof MapboxAdapter
     */
    project(position: GeoJSON.Point): { x: number; y: number; } {
        return this.#map.project(position.coordinates as [number, number]);
    }

    /**
     * Sets the map's geographical center.
     *
     * @param {GeoJSON.Point} position
     * @memberof MapboxAdapter
     */
    setCenter(position: GeoJSON.Point): void {
        this.#map.setCenter(position.coordinates as [number, number]);
    }


    /**
     * Sets the map type to be displayed.
     *
     * @param {MapType} mapType
     * @memberof MapboxAdapter
     */
    setMapType(mapType: MapType): void {
        if (mapType !== this.#mapType) {
            this.#mapType = mapType;
            switch (this.#mapType) {
                case MapType.HYBRID:
                    this.#map.setStyle('mapbox://styles/mapbox/satellite-streets-v12');
                    break;
                case MapType.SATELLITE:
                    this.#map.setStyle('mapbox://styles/mapbox/satellite-v9');
                    break;
                case MapType.ROADMAP:
                default:
                    this.#map.setStyle('mapbox://styles/mapbox/streets-v11');
                    break;
            }
        }
    }

    /**
     * Sets the maximum zoom level of the map.
     *
     * @param {number} level
     * @memberof MapboxAdapter
     */
    async setMaxZoom(level: number): Promise<void> {
        await this.getMap();
        this.#map.setMaxZoom(offsetMaxZoom(level));
    }

    /**
     * Sets the overlay tile url.
     *
     * @param {string} tileUrl
     * @memberof MapboxAdapter
     */
    setOverlayTileUrl(tileUrl: string): void {
        if (this.#map instanceof mapboxgl.Map && tileUrl > '') {
            const layer = this.#map.getLayer(MI_TILES_LAYER);
            const source = this.#map.getSource(MI_TILES_SOURCE) as RasterSource;

            if (source?.tiles?.includes(tileUrl))
                return;

            const insertBefore = this.#map.getLayer(MI_POLYGON_LAYER) ? MI_POLYGON_LAYER : null;
            const tileSource = { type: 'raster', tiles: [tileUrl], tileSize: 256 } as RasterSource;

            if (source && layer) {
                this.#map.removeLayer(MI_TILES_LAYER);
                this.#map.removeSource(MI_TILES_SOURCE);
            }

            this.#map.addSource(MI_TILES_SOURCE, tileSource);
            this.#map.addLayer({ 'id': MI_TILES_LAYER, 'type': 'raster', 'source': MI_TILES_SOURCE }, insertBefore);
            this.#tileUrl = tileUrl;
        }
    }

    /**
     * Sets the zoom of the map.
     *
     * @param {number} level
     * @memberof MapboxAdapter
     */
    setZoom(level: number): void {
        //To align MapsIndoors zoom levels with Mapbox 1 is subtracted.
        this.#map.setZoom(level - 1);
    }

    /**
     * Sets the opacity value for the wall and extrusion layers.
     *
     * @param {{ extrusionOpacity: number, wallOpacity: number }} settings3D
     */
    setSettings3D(settings3D: { extrusionOpacity: number, wallOpacity: number }): void {
        this.#wallOpacity = settings3D.wallOpacity ?? 1;
        this.#extrusionOpacity = settings3D.extrusionOpacity ?? 1;

        this.#map.setPaintProperty(MI_WALL_LAYER, 'fill-extrusion-opacity', this.#wallOpacity);
        this.#map.setPaintProperty(MI_EXTRUSION_LAYER, 'fill-extrusion-opacity', this.#extrusionOpacity);
    }

    /**
     * Returns a GeoJSON Point representing geographical coordinates that correspond to the specified pixel coordinates.
     *
     * @param {{ x: number; y: number }} point
     * @returns {GeoJSON.Point}
     * @memberof MapboxAdapter
     */
    unproject(point: { x: number; y: number; }): Promise<GeoJSON.Point> {
        const position = this.#map.unproject(point as mapboxgl.PointLike);
        return Promise.resolve(toGeoJSONPoint(position));
    }

    /**
     * Set the data displayed on the map.
     *
     * @param {GeoJSON.Feature[]} features
     * @memberof MapboxAdapter
     */
    setViewData(features: GeoJSON.Feature[]): void {
        const source = this.#map.getSource(MI_GEOJSON_SOURCE) as mapboxgl.GeoJSONSource;

        for (const feature of features.filter(feature => (feature.properties?.icon instanceof Image))) {
            const icon = feature.properties.icon;
            if (!this.#map.hasImage(icon?.src)) {
                this.#map.addImage(icon.src, icon);
            }
        }

        if (source) {
            this.#viewData = features;
            source.setData({ 'type': 'FeatureCollection', features });
            this.#mapbox3DLayer?.setData(features);
            this.#mapbox2DLayer?.setData(features);
        }
    }

    /**
     * Resets the rotate and the tilt properties of the map.
     */
    resetRotateAndTilt(): void {
        this.#map.setPitch(0);
        this.#map.setBearing(0);
    }
}

/**
 * Converts a mapboxgl.LngLat to a GeoJSON.Point.
 *
 * @param {mapboxgl.LngLat} point
 * @returns {GeoJSON.Point}
 * @memberof MapboxAdapter
 */
function toGeoJSONPoint(point: mapboxgl.LngLat): GeoJSON.Point {
    return { type: 'Point', coordinates: point.toArray() };
}

/**
 * Offsets the max zoom level for Mapbox.
 *
 * @param {number} maxZoom
 * @returns {number}
 * @memberof MapboxAdapter
 */
function offsetMaxZoom(maxZoom: number): number {
    const mapboxZoomLevelOffset = 1;

    if (isNaN(maxZoom)) {
        return 20;
    }

    return maxZoom - mapboxZoomLevelOffset > 0 ? maxZoom - mapboxZoomLevelOffset : 20;
}

/**
 * Load the Mapbox API.
 *
 * @returns {Promise<void>}
 * @memberof MapboxAdapter
 */
function loadMapboxCSS(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        const link = document.createElement('link');
        link.id = 'mapbox-gl.css';
        link.href = 'https://api.mapbox.com/mapbox-gl-js/v2.9.1/mapbox-gl.css';
        link.rel = 'stylesheet';
        link.addEventListener('load', () => resolve());
        link.addEventListener('error', () => reject());

        document.head.appendChild(link);
    });
}