import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';

@Component({
    selector: 'search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, OnDestroy {
    @ViewChild('searchFilter', { static: true }) searchFilterElement: ElementRef<HTMLInputElement>;

    @Output() public activeSearchQuery: EventEmitter<string> = new EventEmitter();

    public isClearButtonVisible: boolean = false;

    @Input()
    public placeholder: string = 'Search...';

    private subscription: Subscription = new Subscription();

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.subscription
            .add(fromEvent<KeyboardEvent>(this.searchFilterElement.nativeElement, 'input')
                .pipe(
                    tap(() => this.isClearButtonVisible = this.searchFilterElement.nativeElement.value.length > 0),
                    debounceTime(400),
                    map(event => (<HTMLInputElement>event.target).value),
                    distinctUntilChanged())
                .subscribe(term => this.activeSearchQuery.emit(term)));
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    /**
     * Shows/hides the clear button depending on the input.
     *
     * @param {Event} event
     */
    public onSearchInputEntered(event: Event): void {
        this.isClearButtonVisible = (event.target as HTMLInputElement).value !== '';
    }

    /**
     * Clear search input field.
     *
     * @memberof FiltersBarComponent
     */
    public onSearchFilterClear(): void {
        this.searchFilterElement.nativeElement.focus();
        this.searchFilterElement.nativeElement.value = '';
        this.searchFilterElement.nativeElement.dispatchEvent(new Event('input'));
        this.isClearButtonVisible = false;
    }
}