import { BBox, Feature, Geometry, GeometryObject, LineString, Point, Polygon, Position } from 'geojson';

import { lineString, polygon, point as toPoint } from '@turf/helpers';
import transformRotate from '@turf/transform-rotate';
import transformTranslate from '@turf/transform-translate';
import { bearing as calculateBearing, centroid, distance as calculateDistance, destination } from '@turf/turf';
import * as turf from '@turf/turf';

import { Geometry as MIGeometry, LatLng } from '../../locations/location.model';
import { GeoJSONGeometryType, UnitSystem } from '../../shared/enums';
import { convertMetersToFeet } from '../conversion-helper';
import { RouteElement } from '../../map/route-element-details/route-element.model';
import SnapTool from '../../GeodataEditor/SnapTool/SnapTool';
import booleanIntersects from '@turf/boolean-intersects';
import { isPointInPolygon, toMultiLineString } from './geojson';
import { calculateAngularDistance, calculateInitialBearingBetween, toDegrees, toRadians } from '../../../utilities/Math';


const EARTH_RADIUS_IN_METERS = 6378137;
const EARTH_CIRCUMFERENCE_IN_METERS = 40075016.68557849;

/**
 * Calculate center position from a GeoJSON geometry.
 *
 * @param {GeometryObject} geometry - GeoJSON geometry.
 * @returns {Position} - GeoJSON position.
 */
export function getCenterPosition(geometry: GeometryObject): Position {
    switch (geometry.type as GeoJSONGeometryType) {
        case GeoJSONGeometryType.LineString: {
            const lineString: Feature<LineString> = turf.lineString((geometry as LineString).coordinates);
            const lineStringDistance = turf.length(lineString, { units: 'meters' });
            const center = turf.along(lineString, lineStringDistance / 2, { units: 'meters' });

            return center.geometry.coordinates;
        }
        case GeoJSONGeometryType.Point:
            return (geometry as Point).coordinates;
        default:
            return;
    }
}

/**
 * Convert a GeoJSON Position to a LatLng object.
 *
 * @param {Position} position
 * @returns {LatLng}
 */
export function getLatLngFromPosition(position: Position): LatLng {
    return {
        lat: position[1],
        lng: position[0]
    };
}

/**
 * Util class helper to get length of a lineString.
 *
 * @param {GeoJSON.LineString} lineString
 * @param {UnitSystem} unitSystem
 * @returns {number}
 */
export function getLength(lineString: GeoJSON.LineString, unitSystem: UnitSystem): number {
    const lengthInMeters = getLineStringLength(lineString);
    return convertMetersToFeet(lengthInMeters, unitSystem);
}

/**
 * Util class helper to get width of a door.
 *
 * @param {RouteElement} routeElement
 * @param {UnitSystem} unitSystem
 * @returns {number}
 */
export function getDoorWidth(routeElement: RouteElement, unitSystem: UnitSystem): number {
    if (!routeElement.id || routeElement.geometry?.type !== GeoJSONGeometryType.LineString) {
        return null;
    }
    return getLength(routeElement.geometry, unitSystem);
}

/**
 * Get geometry from a Google Maps Polygon.
 *
 * @param {google.maps.Polygon} polygon
 * @returns {MIGeometry}
 */
export function getGeometryFromGooglePolygon(polygon: google.maps.Polygon): MIGeometry {
    const geometry: MIGeometry = { type: 'Polygon', bbox: [], coordinates: [[]] };
    geometry.coordinates.shift(); // remove empty array

    if (polygon) {
        const bounds = new google.maps.LatLngBounds();
        const paths = polygon.getPaths();
        const pathsLength = paths.getLength();

        for (let i = 0; i < pathsLength; i++) {
            const path = paths.getAt(i);
            const length = path.getLength();
            const coordinates = [];

            for (let j = 0; j < length; j++) {
                const coord = path.getAt(j);
                bounds.extend(coord);
                coordinates.push([+coord.lng().toFixed(8), +coord.lat().toFixed(8)]);
            }

            // if the first and last point are not equal, close the loop
            if (coordinates[0][0] !== coordinates[length - 1][0] || coordinates[0][1] !== coordinates[length - 1][1]) {
                coordinates.push(coordinates[0]);
            }

            geometry.coordinates.push(coordinates);
        }

        geometry.bbox = [
            +bounds.getNorthEast().lng().toFixed(8), +bounds.getNorthEast().lat().toFixed(8),
            +bounds.getSouthWest().lng().toFixed(8), +bounds.getSouthWest().lat().toFixed(8)
        ];
    }

    return geometry;
}

/**
 * Get collected bounds of bounding boxes, Google Maps specific.
 *
 * @param {number[][]} boundingBoxes
 * @returns {google.maps.LatLngBounds}
 */
export function getCollectedBounds(boundingBoxes: number[][]): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds();
    boundingBoxes.forEach((bbox) => {
        bounds.extend(new google.maps.LatLng({ lat: bbox[1], lng: bbox[0] }));
        bounds.extend(new google.maps.LatLng({ lat: bbox[3], lng: bbox[2] }));
    });

    return bounds;
}

/**
 * Get collection bounds of bounding boxes for Google Maps and Mapbox.
 *
 * @param {number[][]} boundingBoxes
 * @returns {any}
 */
export function getCollectionBounds(boundingBoxes: number[][]): number[] {
    return boundingBoxes.reduce((result, bbox) => {
        result[0] = Math.min(bbox[0], result[0]);
        result[1] = Math.min(bbox[1], result[1]);
        result[2] = Math.max(bbox[2], result[2]);
        result[3] = Math.max(bbox[3], result[3]);
        return result;
    }, [180, 90, -180, -90]);
}

/**
 * Calculate a lineString following existing lineString from a point with a given length.
 *
 * @param {Feature<LineString>} lineString - Existing lineString that the sub-lineString should follow.
 * @param {Feature<Point>} point - The center point of the calculated sub-lineString.
 * @param {number} length - Lenght of returned lineString in meters.
 * @returns {Feature<LineString>}
 */
export function getSubLineStringFromPointOnLineString(lineString: Feature<LineString>, point: Feature<Point>, length: number): Feature<LineString> {
    // Make sure linestring start/end is never near the point to avoid split problems
    const shiftedLocationLineString = shiftPolygonStartEndAwayFromDoorPoint(lineString, point);

    const split = turf.lineSplit(turf.truncate(shiftedLocationLineString), turf.truncate(point)); // Using truncate (defaults to 6 decimals) due to lineSplit sometimes not working on very precise coordinates.
    const [splitPart1, splitPart2] = split.features;

    // Reverse the coordinates of split part 1 if the first point is not closest to the door point
    const distanceToFirstPointOnSplitPart1 = turf.distance(point, splitPart1.geometry.coordinates[0], { units: 'meters' });
    const distanceToLastPointOnSplitPart1 = turf.distance(point, splitPart1.geometry.coordinates[splitPart1.geometry.coordinates.length - 1], { units: 'meters' });
    if (distanceToFirstPointOnSplitPart1 > distanceToLastPointOnSplitPart1) {
        splitPart1.geometry.coordinates = splitPart1.geometry.coordinates.reverse();
    }

    const doorStartPoint = turf.along(splitPart1, length / 2, { units: 'meters' });
    const doorEndPoint = turf.along(splitPart2, length / 2, { units: 'meters' });

    return turf.lineSlice(doorStartPoint, doorEndPoint, shiftedLocationLineString);
}

/**
 * Shift coordinates in linestring (polygon-like) to ensure that start/end never is near the door point.
 *
 * @param {Feature<LineString>} lineString
 * @param {Feature<Point>} doorPoint
 * @returns {Feature<LineString>}
 */
export function shiftPolygonStartEndAwayFromDoorPoint(lineString: Feature<LineString>, doorPoint: Feature<Point>): Feature<LineString> {
    const newPolygonCoordinates: Position[] = lineString.geometry.coordinates.slice(0);

    // Slice and reassemble coordinates at the coordinate index at the farthest distance from the door point.
    const spliceIndex = getFarthestCoordinateIndex(doorPoint, newPolygonCoordinates);

    const movedPoints: Position[] = newPolygonCoordinates.splice(spliceIndex);
    let shiftedPolygonCoordinates = [].concat(movedPoints, newPolygonCoordinates);

    // Remove duplicates and ensure ring is closed
    shiftedPolygonCoordinates = [...new Set(shiftedPolygonCoordinates)];
    shiftedPolygonCoordinates.push(shiftedPolygonCoordinates[0]);

    return turf.lineString(shiftedPolygonCoordinates);
}

/**
 * Return the index of the coordinate farthest away from a given origin point.
 *
 * @param {Feature<Point>} originPoint
 * @param {Position[]} coordinates
 * @returns {number}
 */
function getFarthestCoordinateIndex(originPoint: Feature<Point>, coordinates: Position[]): number {
    let largestDistance = turf.distance(originPoint, coordinates[0], { units: 'meters' });
    let largestDistanceIndex = 0;

    for (let index = 1; index < coordinates.length; index++) {
        const distance = turf.distance(originPoint, coordinates[index], { units: 'meters' });
        if (distance > largestDistance) {
            largestDistanceIndex = index;
            largestDistance = distance;
        }
    }

    return largestDistanceIndex;
}

/**
 * Get the length of a LineString.
 *
 * @param {GeometryObject} geometry - GeoJSON Geometry object.
 * @returns {number} Length of LineString in meters.
 */
export function getLineStringLength(geometry: GeometryObject): number {
    const lineString = turf.lineString((geometry as LineString).coordinates);
    return turf.length(lineString, { units: 'meters' });
}

/**
 * From an array of GeoJSON geometries, pick the one geometry based on the combination of
 * 1) an already established, very close point to the geometries
 * 2) (if necessary) the current mouse position.
 *
 * It will look at the polygons that intersect with the closest point.
 * If more polygons intersect, the current mouse position will be used to prioritize polygons close to that.
 *
 * @param {GeometryObject[]} geometries
 * @param {Feature<Point>} closestPointOnGeometries
 * @param {Feature<Point>} mousePosition
 * @returns {number} - The index of the chosen geometry from the array of geometries.
 */
export function pickPolygonBasedOnClosestPointAndMousePointer(geometries: GeometryObject[], closestPointOnGeometries: Feature<Point>, mousePosition: Feature<Point>): number {
    // Find the indexes of the polygon geometry array that intersects with the closest point.
    // To mitigate precision problems, we add a 0.1m buffer to the closest point (effectively converting the point to a polygon)
    const bufferFeature = turf.buffer(closestPointOnGeometries, 0.1, { units: 'meters' });
    const intersectingPolygonIndexes = [];
    const geometriesLength = geometries.length;
    for (let index = 0; index < geometriesLength; index++) { // using regular for loop for performance reasons.
        const polygon = turf.polygon((geometries[index] as Polygon).coordinates);
        if (turf.booleanOverlap(polygon, bufferFeature)) {
            intersectingPolygonIndexes.push(index);
        }
    }
    const intersectingPolygonLength = intersectingPolygonIndexes.length;
    if (intersectingPolygonLength === 0) {
        // If there are no intersections, dismiss.
        return;

    } else if (intersectingPolygonLength === 1) {
        // If there is exactly one intersection, return the intersection polygon index.
        return intersectingPolygonIndexes[0];

    } else {
        // In case of multiple intersections, a little more work is needed to determine the most correct polygon:
        // Polygon(s) underneath the current mouse position will have priority.

        // Find which polygon(s) are underneath the mouse pointer.
        const polygonsUnderneathMousePosition = [];
        for (let index = 0; index < intersectingPolygonLength; index++) { // using regular for loop for performance reasons.
            const polygon = turf.polygon((geometries[intersectingPolygonIndexes[index]] as Polygon).coordinates);
            if (turf.booleanPointInPolygon(mousePosition.geometry.coordinates, polygon)) {
                polygonsUnderneathMousePosition.push(intersectingPolygonIndexes[index]);
            }
        }

        if (polygonsUnderneathMousePosition.length === 0) {
            // If no polygons are underneath the mouse pointer:
            // Return intersecting polygon index with the smallest area.
            return getPolygonIndexWithSmallestAreaFromSubset(intersectingPolygonIndexes);

        } else if (polygonsUnderneathMousePosition.length === 1) {
            // If only one polygon is underneath the mouse pointer:
            // Return the polygon that the mouse pointer is underneath.
            return polygonsUnderneathMousePosition[0];

        } else {
            // If there are two or more polygons that are underneath the mouse pointer:
            // Return the one from the polygons underneath the mouse pointer having the smallest area
            return getPolygonIndexWithSmallestAreaFromSubset(polygonsUnderneathMousePosition);
        }
    }

    /**
     * Helper function to retreive a polygon index from a subset of polygons based on area.
     *
     * @param {number[]} polygonIndexSubset
     * @returns {number}
     */
    function getPolygonIndexWithSmallestAreaFromSubset(polygonIndexSubset: number[]): number {
        return polygonIndexSubset
            .map(polygonIndex => {
                const area = turf.area(turf.polygon((geometries[polygonIndex] as Polygon).coordinates));
                return { polygonIndex, area };
            })
            .reduce((prev, curr) => {
                return prev.area < curr.area ? prev : curr;
            })
            .polygonIndex;
    }
}

/**
 * Generate a rectangular GeoJSON polygon with given coordinates as center and with given width, height and bearing.
 *
 * @param {[number, number]} position
 * @param {number} width - In meters.
 * @param {number} height - In meters.
 * @param {number} bearing - Degrees from north.
 * @returns {Polygon}
 */
export function generateRectangularPolygonFromPoint(position: [number, number], width: number, height: number, bearing: number): Feature<Polygon> {
    const centerPoint = turf.point(position);

    // Generate points south-west and nort-east to the center according to width and height
    const radius = Math.sqrt(width ** 2 + height ** 2) / 2;
    const angle = Math.atan(width / height) * 57.29577951308232; //To transform from radians to degrees multiply by 57.29577951308232 which is the same as (180 / Math.PI).
    const northEast = transformTranslate(centerPoint, radius, angle, { units: 'meters' }); // The upper right corner.
    const southWest = transformTranslate(centerPoint, radius, angle + 180, { units: 'meters' }); // The lower left corner.

    // Generate polygon coordinates based on the four translated points.
    const rectanglePolygon = polygon([[
        // south west
        [southWest.geometry.coordinates[0], southWest.geometry.coordinates[1]],
        // south east
        [northEast.geometry.coordinates[0], southWest.geometry.coordinates[1]],
        // north east
        [northEast.geometry.coordinates[0], northEast.geometry.coordinates[1]],
        // north west
        [southWest.geometry.coordinates[0], northEast.geometry.coordinates[1]],
        // south west again, closing the polygon
        [southWest.geometry.coordinates[0], southWest.geometry.coordinates[1]]
    ]]);

    return transformRotate(rectanglePolygon, bearing, { mutate: true });
}

/**
 * Generates a polygon that is offset a number of pixels outward from the given polygon.
 *
 * @param {GeoJSON.Polygon} originalPolygon
 * @param {number} pixelOffset
 * @param {number} metersPerPixel
 * @returns {GeoJSON.Feature<Polygon>}
 */
export function generateOffsetPolygon(originalPolygon: GeoJSON.Polygon, pixelOffset: number, metersPerPixel: number): GeoJSON.Feature<Polygon> {
    const offset = pixelOffset * metersPerPixel;
    return turf.buffer(originalPolygon, offset, { units: 'meters' });
}

/**
 * Returns a subsection of the polygon's edge with the given length nearest the center point.
 *
 * @param {GeoJSON.Polygon} polygon
 * @param {GeoJSON.Point} center
 * @param {boolean} [snapToMidpoints=true] - Will snap to polygon's midpoints if true.
 * @param {number} length - Length of the subsection in meters.
 * @returns {GeoJSON.LineString}
 */
export function getPolygonSubsection(polygon: GeoJSON.Polygon, center: GeoJSON.Point, snapToMidpoints: boolean = true, length: number): GeoJSON.LineString {
    // Center of the new lineString.
    const centerOfLineString = SnapTool.getPointOnPolygon(polygon, center, snapToMidpoints);

    const subsectionGeometry = getPolygonEdgeAsLinestring(polygon, center, snapToMidpoints);

    // Creates a new lineString based on the closest wall, center point and static length.
    const newLineString = getSubLineStringFromPointOnLineString(subsectionGeometry, turf.point(centerOfLineString.coordinates), length);

    return newLineString ? newLineString.geometry : null;
}

/**
 * Returns a point on polygon that is the closest to the point. Boolean snapToMidpoints indicates if point can snap to the midpoint of the linestring.
 *
 * @param {GeoJSON.Polygon} polygon
 * @param {GeoJSON.Point} point
 * @param {boolean} snapToMidpoints
 * @returns {GeoJSON.Point}
 */
export function getNearestPointOnLineToPolygon(polygon: GeoJSON.Polygon, point: GeoJSON.Point, snapToMidpoints: boolean = true): GeoJSON.Point {
    // Center of the new lineString.
    const centerOfLineString = SnapTool.getPointOnPolygon(polygon, point, snapToMidpoints);
    const subsectionGeometry = getPolygonEdgeAsLinestring(polygon, point, snapToMidpoints);

    const pointOnLine = turf.nearestPointOnLine(subsectionGeometry, turf.point(centerOfLineString.coordinates));
    return point ? pointOnLine.geometry : null;
}


/**
 * Calculate the offset between two points (bearing and distance).
 *
 * @protected
 * @static
 * @param {GeoJSON.Position} p0
 * @param {GeoJSON.Position} p1
 * @returns {{ distance: number, bearing: number }}
 */
export function calculateOffset(p0: GeoJSON.Position, p1: GeoJSON.Position): { distance: number, bearing: number } {
    const bearing = calculateBearing(p0, p1);
    const distance = calculateDistance(p0, p1);

    return { distance, bearing };
}

/**
 * Returns index of the polygon that is the closest to the given point.
 *
 * @param {GeoJSON.Polygon[]} polygons
 * @param {GeoJSON.Point} point
 * @returns {number}
 */
export function indexOfNearestPolygon(polygons: GeoJSON.Polygon[], point: GeoJSON.Point): number {
    if (!polygons?.length) {
        return -1;
    }

    let indexOfPolygon = polygons.findIndex(polygon => isPointInPolygon(point.coordinates, polygon));

    if (indexOfPolygon < 0) {
        const multiLineString = polygons.reduce((multiLineString, polygon) => {
            multiLineString.geometry.coordinates = multiLineString.geometry.coordinates.concat(toMultiLineString(polygon as MIGeometry).coordinates);
            return multiLineString;
        }, turf.multiLineString([]));

        const pointOnLine = turf.nearestPointOnLine(multiLineString, point);
        const box = turf.buffer(pointOnLine, 1, { units: 'millimeters', steps: 4 });
        indexOfPolygon = polygons.findIndex(polygon => booleanIntersects(box, polygon));
    }

    return indexOfPolygon;
}

/**
 * Ensures that the first and last positions are identical.
 *
 * @param {GeoJSON.Polygon} polygon
 * @returns {GeoJSON.Polygon}
 */
export function closePolygon(polygon: GeoJSON.Polygon): GeoJSON.Polygon {
    const closedPolygon = polygon.coordinates.map((ring) => {
        const firstPosition = ring[0];
        const lastPosition = ring[ring.length - 1];

        if (firstPosition?.[0] !== lastPosition?.[0] || firstPosition?.[1] !== lastPosition?.[1]) {
            ring.push(firstPosition);
        }

        return ring;
    });

    return {
        type: 'Polygon',
        coordinates: closedPolygon,
    };
}

/**
 * Offsets the vertices of a polygon by a given distance in meters, perpendicular to the original polygon edges.
 * The offset can be positive (outward direction) or negative (inward direction) from the original polygon.
 *
 * @param {GeoJSON.Polygon} polygon - The polygon to offset.
 * @param {number} distance - The distance to offset the polygon vertices in meters.
 * @returns {GeoJSON.Polygon} The offset polygon.
 */
export function offsetPolygonEdges(polygon: GeoJSON.Polygon, distance: number): GeoJSON.Polygon {
    const coordinates = polygon.coordinates.map((ring) => {
        return ring.map((point, index) => {
            const prevPoint = index === 0 ? ring[ring.length - 2] : ring[index - 1];
            const nextPoint = index === ring.length - 1 ? ring[1] : ring[index + 1];

            // Calculate the bearing of the line between prevPoint and point
            const bearing1 = calculateBearing(prevPoint, point);
            const bearing2 = calculateBearing(point, nextPoint);

            const offset1 = offsetLineSegment([prevPoint, point], distance, 90 + bearing1);
            const offset2 = offsetLineSegment([point, nextPoint], distance, 90 + bearing2);

            return calculateLineIntersection(offset1, offset2) ?? offset1[1];
        });
    });

    return { type: 'Polygon', coordinates };
}

/**
 * Helper function to get the lineString of the polygon that the point intersects with.
 *
 * @param {GeoJSON.Polygon} polygon
 * @param {GeoJSON.Point} point
 * @param {boolean} snapToMidpoints
 * @returns {GeoJSON.Feature<LineString>}
 */
function getPolygonEdgeAsLinestring(polygon: GeoJSON.Polygon, point: GeoJSON.Point, snapToMidpoints: boolean = true): GeoJSON.Feature<LineString> {
    // Creating multiLineString from polygon.
    const polygonAsMultiLineString = turf.multiLineString(polygon.coordinates);

    // Center of the new lineString.
    const centerOfLineString = SnapTool.getPointOnPolygon(polygon, point, snapToMidpoints);

    // Get the lineString of the polygon that the centerPoint intersects with. If there is only one 'ring', we need the first coordinates.
    let subsectionGeometry: GeoJSON.Feature<LineString>;
    if (polygonAsMultiLineString.geometry.coordinates.length > 1) {
        subsectionGeometry = SnapTool.getIntersectingLineSegment(polygonAsMultiLineString.geometry, centerOfLineString);
    } else {
        subsectionGeometry = turf.lineString(polygonAsMultiLineString.geometry.coordinates[0]);
    }

    return subsectionGeometry;
}

/**
 * Calculates the intersection point of two lines defined by line segments.
 *
 * @param {GeoJSON.Position[]} line1 - The first line segment defined by two points.
 * @param {GeoJSON.Position[]} line2 - The second line segment defined by two points.
 * @returns {GeoJSON.Position | null} The intersection point of the two lines, or null if no intersection exists.
 */
function calculateLineIntersection(line1: GeoJSON.Position[], line2: GeoJSON.Position[]): GeoJSON.Position | null {
    const [p1, p2] = line1;
    const [p3, p4] = line2;

    const [lon1, lat1] = p1;
    const [lon2, lat2] = p2;
    const [lon3, lat3] = p3;
    const [lon4, lat4] = p4;

    const lonDelta1 = lon2 - lon1;
    const latDelta1 = lat2 - lat1;
    const lonDelta2 = lon4 - lon3;
    const latDelta2 = lat4 - lat3;

    // Calculate the denominator of the intersection equation.
    // If the denominator is zero, the lines are parallel and there is no intersection.
    const denominator = latDelta2 * lonDelta1 - lonDelta2 * latDelta1;

    if (denominator === 0) {
        // Lines are parallel, no intersection
        return null;
    }

    // Calculate the intersection point relative to line1.
    const t1 = ((lonDelta2 * (lat1 - lat3)) + (latDelta2 * (lon3 - lon1))) / denominator;

    const lonIntersection = lon1 + lonDelta1 * t1;
    const latIntersection = lat1 + latDelta1 * t1;

    return [lonIntersection, latIntersection];
}

/**
 * Offsets a line segment by a given distance and bearing.
 *
 * @param {GeoJSON.Position[]} segment - The line segment defined by two points.
 * @param {number} distance - The distance to offset the line segment in meters.
 * @param {number} bearing - The bearing (angle) in degrees.
 * @returns {GeoJSON.Position[]} The offset line segment.
 */
function offsetLineSegment(segment: GeoJSON.Position[], distance: number, bearing: number): GeoJSON.Position[] {
    const EARTH_RADIUS = 6371000; // Earth's radius in meters

    return segment.map(point => {
        const [lon, lat] = point;
        const latRad = toRadians(lat);
        const lonRad = toRadians(lon);
        const bearingRad = toRadians(bearing);
        const angularDistance = distance / EARTH_RADIUS;

        // Calculate the offset latitude in radians.
        const offsetLatRad = Math.asin(
            Math.sin(latRad) * Math.cos(angularDistance) +
            Math.cos(latRad) * Math.sin(angularDistance) * Math.cos(bearingRad)
        );

        // Calculate the offset longitude in radians.
        const offsetLonRad = lonRad + Math.atan2(
            Math.sin(bearingRad) * Math.sin(angularDistance) * Math.cos(latRad),
            Math.cos(angularDistance) - Math.sin(latRad) * Math.sin(offsetLatRad)
        );

        return [toDegrees(offsetLonRad), toDegrees(offsetLatRad)];
    });
}

/**
 * Returns buffered bounding box.
 *
 * @param {BBox} bounds
 * @param {number} radiusInMeters
 * @returns {BBox}
 */
export function bufferBounds(bounds: BBox, radiusInMeters: number): BBox {
    const bbox = turf.bboxPolygon(bounds);
    const bufferedBbox = turf.buffer(bbox, radiusInMeters, { units: 'meters', steps: 4 });
    return turf.bbox(bufferedBbox);
}

/**
 * Checks if any edges of given Polygon are intersecting.
 *
 * @param {GeoJSON.Polygon} geometry
 * @returns {GeoJSON.FeatureCollection<Point>}
 */
export function intersectingFeaturePoint(geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon | GeoJSON.LineString | GeoJSON.MultiLineString): GeoJSON.FeatureCollection<Point> {
    const intersectingPoints = turf.kinks(geometry);
    return intersectingPoints;
}

/**
 * Calculate how many meters a pixel on the map is.
 *
 * @param {GeoJSON.Polygon | GeoJSON.Point} geometry
 * @param {number} zoomLevel - Current zoom level.
 * @returns {number}
 */
export function getMetersPerPixel(geometry: GeoJSON.Polygon | GeoJSON.Point | GeoJSON.LineString, zoomLevel: number): number {
    const center = centroid(geometry);
    // The fixed tilesize of 256 pixels is compensated by the difference in zoom levels across map providers.
    return (Math.cos(center.geometry.coordinates[1] * (Math.PI / 180)) * EARTH_CIRCUMFERENCE_IN_METERS) / (256 * (2 ** zoomLevel));
}

/**
 * From the array of every two points (pair of points), function creates linestrings.
 *
 * @param {Point[]} points
 * @returns {LineString[]}
 */
export function createLineStringsFromPairOfPoints(points: Point[]): LineString[] {
    const lineStrings: LineString[] = [];
    for (let i = 0; i < points.length; i += 2) {
        if (i + 1 < points.length) {
            const lineString: LineString = {
                type: 'LineString',
                coordinates: [points[i].coordinates, points[i + 1].coordinates]
            };
            lineStrings.push(lineString);
        }
    }

    return lineStrings;
}

/**
 * Returns polygons that are intersecting with given geometry. Function support holes in the polygon.
 *
 * @param {GeoJSON.Polygon[]} polygons
 * @param {Feature<Geometry>} geometry
 * @returns {GeoJSON.Polygon[]}
 */
export function findPolygonsIntersectingWithGeometry(polygons: GeoJSON.Polygon[], geometry: Feature<Geometry>): GeoJSON.Polygon[] {
    // Create an array of all polygons as linestrings, filter those that are intersecting with point.
    const lineStrings = polygons
        .flatMap(polygon => polygon.coordinates.map(ring => turf.lineString(ring).geometry))
        .filter(lineString => booleanIntersects(geometry, lineString));

    // Return intersecting linestrings as polygons.
    return lineStrings.map(lineString => turf.lineToPolygon(lineString).geometry as Polygon);
}

/**
 * Converts a GeoJSON Position to a Mercator projection.
 *
 * @param {GeoJSON.Position} position - The position to convert.
 * @returns {[number, number]} The position in Mercator projection.
 */
export function toMercator([lng, lat]: GeoJSON.Position): [x: number, y: number] {
    const x = EARTH_RADIUS_IN_METERS * toRadians(lng);
    const y = EARTH_RADIUS_IN_METERS * Math.log(Math.tan(Math.PI / 4 + toRadians(lat) / 2));

    return [x, y];
}

/**
 * Converts a Mercator projection to a GeoJSON Position.
 *
 * @param {[number, number]} point - The position to convert.
 * @returns {[number, number]} The position in GeoJSON format.
 */
export function toLngLat([x, y]: [number, number]): [lng: number, lat: number] {
    const lng = toDegrees(x / EARTH_RADIUS_IN_METERS);
    const lat = toDegrees(2 * Math.atan(Math.exp(y / EARTH_RADIUS_IN_METERS)) - Math.PI / 2);

    return [lng, lat];
}

const METERS_PER_PIXEL_MAP = new Map([
    [0, 156543.03392804097],
    [1, 78271.51696402048],
    [2, 39135.75848201024],
    [3, 19567.87924100512],
    [4, 9783.93962050256],
    [5, 4891.96981025128],
    [6, 2445.98490512564],
    [7, 1222.99245256282],
    [8, 611.49622628141],
    [9, 305.748113140705],
    [10, 152.8740565703525],
    [11, 76.43702828517625],
    [12, 38.21851414258813],
    [13, 19.109257071294063],
    [14, 9.554628535647032],
    [15, 4.777314267823516],
    [16, 2.388657133911758],
    [17, 1.194328566955879],
    [18, 0.5971642834779395],
    [19, 0.29858214173896974],
    [20, 0.14929107086948487],
    [21, 0.07464553543474244],
    [22, 0.03732276771737122],
    [23, 0.01866138385868561],
    [24, 0.009330691929342804],
    [25, 0.004665345964671402],
    [26, 0.002332672982335701],
    [27, 0.0011663364911678506],
    [28, 0.0005831682455839253],
    [29, 0.00029158412279196264],
    [30, 0.00014579206139598132]
]);

/**
 * Get the meters per pixel at a given zoom level.
 *
 * @param {number} zoomLevel - The zoom level.
 * @returns {number} The meters per pixel.
 */
export function getMetersPerPixelAt(zoomLevel: number): number {
    if (METERS_PER_PIXEL_MAP.has(zoomLevel)) {
        return METERS_PER_PIXEL_MAP.get(zoomLevel);
    } else {
        METERS_PER_PIXEL_MAP.set(zoomLevel, EARTH_CIRCUMFERENCE_IN_METERS / (256 * Math.pow(2, zoomLevel)));
        return METERS_PER_PIXEL_MAP.get(zoomLevel);
    }
}

/**
 * Computes the intersection point of a line segment and a line defined by a point and bearing.
 *
 * @param {GeoJSON.Position} p1 - The point that defines the line.
 * @param {number} bearing - The bearing of the line in degrees.
 * @param {[GeoJSON.Position, GeoJSON.Position]} lineSegment - The line segment.
 * @returns {GeoJSON.Position | null} The intersection point or null if there is no intersection.
 */
export function computeIntersection(p1: GeoJSON.Position, bearing: number, lineSegment: [GeoJSON.Position, GeoJSON.Position]): GeoJSON.Position | null {
    const p2 = lineSegment[0];
    const lng1 = toRadians(p1[0]);
    const lat1 = toRadians(p1[1]);
    const lng2 = toRadians(p2[0]);
    const lat2 = toRadians(p2[1]);
    const bearing13 = toRadians(bearing);
    const bearing23 = toRadians(turf.rhumbBearing(lineSegment[0], lineSegment[1]));

    // Calculate angular distance between points
    const angularDistance = calculateAngularDistance([lng1, lat1], [lng2, lat2]);
    if (Math.abs(angularDistance) < Number.EPSILON) {
        return p1; // coincident points
    }

    // Calculate the initial bearings between the points
    const bearing12 = calculateInitialBearingBetween([lng1, lat1], [lng2, lat2]);
    const bearing21 = calculateInitialBearingBetween([lng2, lat2], [lng1, lat1]);

    // Calculate the angles between the bearings
    const angle213 = bearing13 - bearing12; // angle 2-1-3
    const angle123 = bearing21 - bearing23; // angle 1-2-3

    // If the sine of both angles is zero, there are infinite intersections.
    if (Math.sin(angle213) === 0 && Math.sin(angle123) === 0) {
        return null; // infinite intersections
    }

    // If the product of the sines of the angles is negative, the intersection is ambiguous (antipodal/360°).
    if (Math.sin(angle213) * Math.sin(angle123) < 0) {
        return computeIntersection(p1, (bearing + 180) % 360, lineSegment);
    }

    // Calculate the cosine of the third angle
    const cosAngle3 = -Math.cos(angle213) * Math.cos(angle123) + Math.sin(angle213) * Math.sin(angle123) * Math.cos(angularDistance);

    // Calculate the angular distance from the first point to the intersection point
    const delta13 = Math.atan2(Math.sin(angularDistance) * Math.sin(angle213) * Math.sin(angle123), Math.cos(angle123) + Math.cos(angle213) * cosAngle3);

    // Calculate the latitude of the intersection point
    const lat3 = Math.asin(Math.min(Math.max(Math.sin(lat1) * Math.cos(delta13) + Math.cos(lat1) * Math.sin(delta13) * Math.cos(bearing13), -1), 1));

    // Calculate the difference in longitude from the first point to the intersection point
    const deltaLng13 = Math.atan2(Math.sin(bearing13) * Math.sin(delta13) * Math.cos(lat1), Math.cos(delta13) - Math.sin(lat1) * Math.sin(lat3));

    // Calculate the longitude of the intersection point
    const lng3 = lng1 + deltaLng13;

    // Return the intersection point in degrees
    const intersection = [toDegrees(lng3), toDegrees(lat3)];

    // Check if the intersection point lies within the line segment
    const minLng = Math.min(lineSegment[0][0], lineSegment[1][0]);
    const maxLng = Math.max(lineSegment[0][0], lineSegment[1][0]);
    const minLat = Math.min(lineSegment[0][1], lineSegment[1][1]);
    const maxLat = Math.max(lineSegment[0][1], lineSegment[1][1]);

    if (intersection[0] < minLng || intersection[0] > maxLng || intersection[1] < minLat || intersection[1] > maxLat) {
        return null;
    }

    return intersection;
}


/**
 * Checks if two points are equal within a small epsilon.
 *
 * @param {GeoJSON.Position} a - The first point.
 * @param {GeoJSON.Position} b - The second point.
 * @returns {boolean} True if the points are equal, false otherwise.
 */
export function isPointsEqual(a: Position, b: Position): boolean {
    const EPSILON = Number.EPSILON;
    const deltaLng = Math.abs(a[0] - b[0]);
    const deltaLat = Math.abs(a[1] - b[1]);
    return deltaLng < EPSILON && deltaLat < EPSILON;
}


/**
 * Creates a line with a fixed angle from a starting point.
 *
 * @param {GeoJSON.Position} start - The starting point.
 * @param {GeoJSON.Position} limit - The straight line cut-off point.
 * @param {number} bearing - The angle in degrees.
 * @param {object} [properties] - Additional properties for the line.
 * @param {object} [options] - Additional options for the line.
 * @returns {GeoJSON.Feature<GeoJSON.LineString>} The line feature.
 */
export function fixedAngleLine(start: GeoJSON.Position, limit: GeoJSON.Position, bearing: number, properties?: { [key: string]: unknown; }, options?: { [key: string]: unknown; }): GeoJSON.Feature<GeoJSON.LineString> {
    let distance: number = 0;

    const absoluteBearing = Math.abs(bearing);
    if ((absoluteBearing > 45 && absoluteBearing <= 135)) {
        const p1 = toPoint([limit[0], start[1]]);
        const sineOfBearing = Math.sin(toRadians(bearing));
        if (limit[0] >= start[0]) {
            distance = calculateDistance(start, p1) / sineOfBearing;
        } else {
            distance = calculateDistance(start, p1) / -sineOfBearing;
        }
    } else {
        const p1 = toPoint([start[0], limit[1]]);
        const cosineOfBearing = Math.cos(toRadians(bearing));
        if (limit[1] >= start[1]) {
            distance = calculateDistance(start, p1) / cosineOfBearing;
        } else {
            distance = calculateDistance(start, p1) / -cosineOfBearing;
        }
    }

    return lineString([start, destination(start, distance, bearing).geometry.coordinates], properties, options);
}

