import { Edge, EdgeProperties } from './GraphEdge';
import { Node, NodeProperties } from './GraphNode';

/**
 * Generates unique IDs.
 *
 * @param {number} offset - The offset to start the ID generation from.
 * @returns {Generator<number>} The generator that yields unique IDs.
 */
function* idGenerator(offset: number = 0): Generator<number> {
    let index = offset;
    while (true) {
        yield index++;
    }
}

export class Graph {
    #generateId = (() => {
        const generator = idGenerator();
        return () => generator.next().value;
    })();
    #edges: Map<number, GraphEdge> = new Map();
    #floors: Map<number, { nodes: Set<GraphNode>, edges: Set<GraphEdge> }> = new Map();
    #graph: any;
    #nodes: Map<number, GraphNode> = new Map();

    readonly id: string;
    readonly name: string;
    readonly solutionId: string;

    constructor(graph: any) {
        this.id = graph.id;
        this.name = graph.graphId;
        this.solutionId = graph.solutionId;
        this.#graph = graph;
        this.#nodes = this.#parseNodes(graph);
        this.#edges = this.#parseEdges(graph, this.#nodes);
    }

    /**
     * Gets a specific node of the graph by its ID.
     *
     * @param {number} id - The ID of the node.
     * @returns {GraphNode | undefined} The node with the given ID, or undefined if no such node exists.
     */
    getNode(id: number): GraphNode | undefined {
        return this.#nodes.get(id);
    }

    /**
     * Adds a node to the graph.
     *
     * @param {GraphNode} node - The node to add.
     */
    addNode(node: GraphNode): void {
        if (!(node instanceof Node)) {
            return;
        }

        if (this.#nodes.has(node.id)) {
            return;
        }

        const floorIndex = node.properties.floorindex;
        this.#nodes.set(node.id, node);
        this.#floors.has(floorIndex) ? this.#floors.get(floorIndex).nodes.add(node) : this.#floors.set(floorIndex, { nodes: new Set([node]), edges: new Set() });
    }

    /**
     * Gets all nodes on a specific floor of the graph.
     *
     * @param {number} floorIndex - The index of the floor.
     * @returns {GraphNode[]} The nodes on the given floor.
     */
    getNodes(floorIndex: number): GraphNode[] {
        return Array.from(this.#floors.get(floorIndex)?.nodes ?? []);
    }

    /**
     * Gets a specific edge of the graph by its ID.
     *
     * @param {number} id - The ID of the edge.
     * @returns {GraphEdge | undefined} The edge with the given ID, or undefined if no such edge exists.
     */
    getEdge(id: number): GraphEdge | undefined {
        return this.#edges.get(id) as GraphEdge;
    }

    /**
     * Gets all edges on a specific floor of the graph.
     *
     * @param {number} floorIndex - The index of the floor.
     * @returns {GraphEdge[]} The edges on the given floor.
     */
    getEdges(floorIndex: number): GraphEdge[] {
        return Array.from(this.#floors.get(floorIndex)?.edges ?? []);
    }

    /**
     * Gets all edges connected to a specific node.
     *
     * @param {GraphNode} node - The node to get the connected edges of.
     * @returns {GraphEdge[]} The edges connected to the given node.
     */
    getConnectedEdges(node: GraphNode): GraphEdge[] {
        return [...this.#edges.values()].filter(edge => edge.from === node || edge.to === node);
    }

    /**
     * Parses nodes from the given graph data and stores them in a Map.
     *
     * @private
     * @param {any} graph - The graph data.
     * @returns {Map<number, GraphNode>} A Map of node IDs to GraphNode objects.
     */
    #parseNodes(graph: any): Map<number, GraphNode> {
        const nodes = new Map<number, Node>();

        for (const nodeData of graph?.nodeData ?? []) {
            const id = this.#generateId();
            const node = Node.create(id, nodeData, graph.nodeMapping);
            nodes.set(node.id, node);

            if (!this.#floors.has(nodeData[2])) {
                this.#floors.set(nodeData[2], { nodes: new Set(), edges: new Set() });
            }

            this.#floors.get(nodeData[2])?.nodes.add(node);
        }

        return nodes;
    }

    /**
     * Parses the edges of the graph.
     *
     * @private
     * @param {any} graph - The graph data.
     * @param {Map<number, Node>} nodes - The nodes of the graph.
     * @returns {Map<number, GraphEdge>} The edges of the graph.
     */
    #parseEdges(graph: any, nodes: Map<number, Node>): Map<number, GraphEdge> {
        const edges = new Map<number, GraphEdge>();

        for (const edgeData of graph?.edgeData ?? []) {
            const id = this.#generateId();
            const edge = Edge.create(id, edgeData, graph.edgeMapping, nodes);
            edges.set(edge.id, edge);

            this.#floors.get(edge.to.properties.floorindex)?.edges.add(edge);
            this.#floors.get(edge.from.properties.floorindex)?.edges.add(edge);
        }

        return edges;
    }

    /**
     * Creates a new node in the graph.
     *
     * @param {GeoJSON.Point} position - The position of the node.
     * @param {number} floorIndex - The index of the floor the node is on.
     * @returns {GraphNode} The created node.
     */
    createNode(position: GeoJSON.Point, floorIndex: number, floorName: string): GraphNode {
        const id = this.#generateId();
        const node = new Node(id, position, new NodeProperties(floorIndex, floorName));

        this.addNode(node);

        return node;
    }

    /**
     * Removes a node from the graph.
     *
     * @param {Node} node - The node to remove.
     */
    removeNode(node: Node): void {
        const edges = [...this.#edges.values()].filter(edge => edge.from === node || edge.to === node);

        this.#nodes.delete(node.id);
        this.#floors.get(node.properties.floorindex)?.nodes.delete(node);

        for (const edge of edges) {
            this.removeEdge(edge);
        }
    }

    /**
     * Removes an edge from the graph.
     *
     * @param {Edge} edge - The edge to remove.
     */
    removeEdge(edge: GraphEdge): void {
        this.#edges.delete(edge.id);
        this.#floors.get(edge.to.properties.floorindex)?.edges.delete(edge);
        this.#floors.get(edge.from.properties.floorindex)?.edges.delete(edge);
    }

    /**
     * Adds an edge to the graph.
     *
     * @param {GraphEdge} edge - The edge to add.
     */
    addEdge(edge: GraphEdge): void {
        if (!(edge instanceof Edge)) {
            return;
        }

        if (this.#edges.has(edge.id)) {
            this.#floors.forEach(floor => floor.edges.delete(edge));
        }

        const { from, to } = edge;

        this.#edges.set(edge.id, edge);
        this.#floors.has(to.properties.floorindex) ? this.#floors.get(to.properties.floorindex).edges.add(edge) : this.#floors.set(to.properties.floorindex, { nodes: new Set([to]), edges: new Set([edge]) });
        this.#floors.has(from.properties.floorindex) ? this.#floors.get(from.properties.floorindex).edges.add(edge) : this.#floors.set(from.properties.floorindex, { nodes: new Set([from]), edges: new Set([edge]) });
    }

    /**
     * Creates a new edge in the graph.
     *
     * @param {Node} from - The node the edge starts from.
     * @param {Node} to - The node the edge ends at.
     * @param {EdgeProperties} properties - The properties of the edge.
     * @returns {Edge} The created edge.
     */
    createEdge(from: GraphNode, to: GraphNode, properties: EdgeProperties): Edge {
        const id = this.#generateId();
        const edge = new Edge(id, from, to, properties);

        this.addEdge(edge);

        return edge;
    }

    /**
     * Splits an edge in the graph.
     *
     * @param {number} edgeId - The ID of the edge to split.
     * @param {Node} node - The node to split the edge at.
     */
    splitEdge(edgeId: number, node: Node): void {
        const edge = this.#edges.get(edgeId);
        if (!edge) {
            return;
        }

        this.createEdge(node, edge.to, { ...edge.properties });
        this.createEdge(edge.from, node, { ...edge.properties });

        this.removeEdge(edge);
    }

    /**
     * Serializes the graph to an object.
     *
     * @returns {object} The serialized graph.
     */
    serialize(): { [key: string]: any } {
        const nodes = Array.from(this.#nodes.values());
        const nodeMapping = this.#graph.nodeMapping;
        const edges = Array.from(this.#edges.values());
        const edgeMapping = this.#graph.edgeMapping;

        const serialized = {
            ...this.#graph,
            id: this.id,
            graphId: this.name,
            edgeData: edges.map(edge => [[nodes.indexOf(edge.from), nodes.indexOf(edge.to)], EdgeProperties.serialize(edge.properties, edgeMapping)]),
            nodeData: nodes.map(node => Node.serialize(node, nodeMapping))
        };

        delete serialized.edgeRoomIds;
        delete serialized.immutableNodes;

        return serialized;
    }

    /**
     * Checks if the given object is an instance of GraphEdge.
     *
     * @param {any} edge - The object to check.
     * @returns {boolean} True if the object is an instance of GraphEdge, false otherwise.
     */
    static isGraphEdge(edge: any): edge is GraphEdge {
        return edge instanceof Edge;
    }

    /**
     * Checks if the given object is an instance of GraphNode.
     *
     * @param {any} node - The object to check.
     * @returns {boolean} True if the object is an instance of GraphNode, false otherwise.
     */
    static isGraphNode(node: any): node is GraphNode {
        return node instanceof Node;
    }
}

export enum RouteContext {
    INSIDE_BUILING = 'InsideBuilding',
    OUTSIDE_ON_VENUE = 'OutsideOnVenue',
}

export interface GraphNode extends Node { }
export interface GraphEdge extends Edge { }