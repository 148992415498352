/**
 * Represents an undo/redo action.
 */
export interface UndoRedoAction<T> {
    undo: (args: T) => void;
    redo: (args: T) => void;
    args?: T;
}

/**
 * Represents an undo-redo manager for managing actions and their corresponding undo and redo functions.
 */
export class UndoRedoManager {
    #stack: UndoRedoAction<unknown>[] = [];
    #position: number = -1;

    /**
     * Pushes an action to the undo-redo stack.
     *
     * @param {UndoRedoAction} action - The action.
     */
    push<T>(action: UndoRedoAction<T>): void {
        this.#stack = this.#stack.slice(0, this.#position + 1);
        this.#stack.push(action);
        this.#position++;
    }

    /**
     * Undo the last action.
     */
    undo(): void {
        if (this.#position < 0) {
            return;
        }

        const { undo, args } = this.#stack[this.#position--];
        undo?.call(this, args);
    }

    /**
     * Redo the last undone action.
     */
    redo(): void {
        if (this.#position >= this.#stack.length - 1) {
            return;
        }

        const { redo, args } = this.#stack[++this.#position];
        redo?.call(this, args);
    }

    /**
     * Clear the undo-redo stack.
     */
    clear(): void {
        this.#stack = [];
        this.#position = -1;
    }

    /**
     * Gets the current position in the undo-redo stack.
     *
     * @returns {number} The current position.
     */
    get position(): number {
        return this.#position;
    }

    /**
     * Gets the length of the undo-redo stack.
     *
     * @returns {number} The length of the stack.
     */
    get length(): number {
        return this.#stack.length;
    }
}
