import { FormControl, FormGroup } from "@angular/forms";

/**
 * Get keyPath value in target object.
 *
 * @param {object} target - Target object.
 * @param {string} keyPath - Object key path.
 * @returns {any} - The nested property value.
 */
export function getKeypathValue(target: object, keyPath: string): any {
    if (!target) {
        return;
    }

    return keyPath.split('.').reduce((previous, current) => {
        return previous?.[current] ?? null;
    }, target);
}

/**
 * Returns the form control or form group from the form.
 *
 * @param {FormGroup} form
 * @param {string} formControlName
 * @returns {FormControl | FormGroup}
 */
export function getFormControl(form: FormGroup, formControlName: string): FormControl | FormGroup {
    return form.get(formControlName) as FormControl | FormGroup;
}