import { Translation } from '../shared/interfaces/translation.model';
import { PositionProviderConfig } from '../customers/customer.model';

export class Solution {
    customerId: string;
    name: string;
    defaultLanguage: string;
    availableLanguages: string[];
    locationTemplate?: string;
    modules: string[];
    expirationDate?: string;
    implementationType?: string;
    mapClientUrl?: string;
    whitelabel?: boolean;
    minimumVersion?: number;
    authIsRequired?: boolean;
    clonedFromId?: string;
    id: string;
    lastModified?: string;
    lastModifiedBy?: string;
    createdAt?: string;
    createdBy?: string;
    positionProviderConfigPriority?: PositionProviders;
    positionProviderConfigs?: PositionProviderConfig[];
    lineOfSightDistance?: number;

    /**
     * Helper function to get the maximum zoom level for the given Solution.
     *
     * @public
     * @static
     * @param {Solution} solution
     * @returns {number}
     */
    static getMaxZoomLevel(solution: Solution): number {
        const isMapboxProvider = solution?.modules?.some(module => module.toLowerCase() === 'mapbox');
        const isZ22Enabled = solution?.modules?.includes('z22');

        switch (Number(isMapboxProvider) + Number(isZ22Enabled)) {
            case 0: return 21;
            case 1: return 22;
            case 2: return 25;
            default: return 21;
        }
    }

    /**
     * Helper function to check if the given Solution has the given module.
     *
     * @param {Solution} solution
     * @param {string} moduleName
     * @returns {boolean}
     */
    static hasModule(solution: Solution, moduleName: string): boolean {
        return solution?.modules?.some(module => module.toLowerCase() === moduleName.toLowerCase());
    }

    /**
     * Creates translation objects from solution's languages.
     *
     * @param {Solution} solution
     * @param {boolean} withGenericLanguage
     * @returns {Translation[]}
     */
    static createTranslations(solution: Solution, withGenericLanguage: boolean = false): Translation[] {
        const translations: Translation[] = [];
        if (solution) {
            for (const lang of solution.availableLanguages) {
                const newLanguage: Translation = {
                    language: lang,
                    name: null,
                    description: null,
                    fields: {}
                };

                translations.push(newLanguage);
            }

            if (withGenericLanguage) {
                const genericLanguage: Translation = {
                    language: 'generic',
                    name: 'generic',
                    fields: {}
                };

                translations.push(genericLanguage);
            }
        }
        return translations;
    }
}

interface PositionProviders {
    ciscodna?: number;
    indooratlas3?: number;
}