import { ErrorHandler, Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {
    constructor(private authService: AuthService, private logger: ErrorHandler) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                let message;
                if (errorResponse.error instanceof ErrorEvent) { // client-side error
                    message = `${errorResponse.error.message}`;
                } else  if (errorResponse.status === 400) {
                    message = 'Invalid request. Please try again.';
                    if (errorResponse.error && errorResponse.error.error === 'invalid_grant') { //invalid login credentials
                        return throwError(errorResponse);
                    }
                    if (errorResponse.error && errorResponse.error.message) { // error message from the backend
                        message = `${errorResponse.error.message}`;
                    }
                } else if (errorResponse.status === 401) {
                    // log the user out only when 401 comes from mapsindoors backend and not an external api
                    if (req.url.includes(environment.APIEndpoint)
                        || req.url.includes(environment.AnalyticsEndpoint)
                        || req.url.includes(environment.authApi)) {
                        message = 'Your session has expired. Please log in again.';
                        this.authService.logout();
                    }
                } else if (errorResponse.status === 403) {
                    message = 'You do not have the necessary permissions to perform this action.';
                    if (errorResponse.error && errorResponse.error.message) { // error message from the backend
                        message = `${errorResponse.error.message}`;
                    }
                } else if (errorResponse.status === 404) {
                    message = 'The requested resource could not be found.';
                    if (errorResponse.error && errorResponse.error.message) { // error message from the backend
                        message = `${errorResponse.error.message}`;
                    }
                } else if (errorResponse.status >= 500) {
                    message = 'An error occured on the server. Please try again later.';
                    this.logger.handleError(errorResponse);
                }

                return message ? throwError(message) : throwError(errorResponse);
            })
        );
    }
}
