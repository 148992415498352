/**
 * Converts degrees to radians.
 *
 * @param {number} degrees - The angle in degrees.
 * @returns {number} The angle in radians.
 */
export function toRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
}

/**
 * Converts radians to degrees.
 *
 * @param {number} radians - The angle in radians.
 * @returns {number} The angle in degrees.
 */
export function toDegrees(radians: number): number {
    return radians * (180 / Math.PI);
}

/**
 * Calculates the dot product of two 2D vectors.
 *
 * @param {number[]} vector0 - The first vector.
 * @param {number[]} vector1 - The second vector.
 * @returns {number} The dot product of the two vectors.
 */
export function dotProduct(vector0: [number, number], vector1: [number, number]): number {
    return vector0[0] * vector1[0] + vector0[1] * vector1[1];
}

/**
 * Calculates the direction vector from the first point to the second point.
 *
 * @param {number[]} point1 - The first point.
 * @param {number[]} point2 - The second point.
 * @returns {number[]} The direction vector from point1 to point2.
 */
export function calculateDirectionVector(point1: [number, number], point2: [number, number]): [number, number] {
    return [point2[0] - point1[0], point2[1] - point1[1]];
}

/**
 * Calculates the squared magnitude of a 2D vector.
 *
 * @param {number[]} vector - The vector.
 * @returns {number} The squared magnitude of the vector.
 */
export function magnitudeSquared(vector: [number, number]): number {
    return vector[0] ** 2 + vector[1] ** 2;
}

/**
 * Calculates the Euclidean distance between two points in a 2D space.
 *
 * @param {number[]} point1 - The first point.
 * @param {number[]} point2 - The second point.
 * @returns {number} The Euclidean distance between the two points.
 */
export function euclideanDistance(point1: [number, number], point2: [number, number]): number {
    return Math.sqrt(magnitudeSquared(calculateDirectionVector(point1, point2)));
}

/**
 * Calculates the nearest point on a line to a given point in a 2D space.
 *
 * @param {number[]} point - The point.
 * @param {number[][]} line - The line defined by its start and end points.
 * @returns {number[]} The nearest point on the line to the given point.
 */
export function euclideanNearestPointOnLine(point: [number, number], [start, end]: [[number, number], [number, number]]): [number, number] {
    const line = calculateDirectionVector(start, end);
    const startToPoint = calculateDirectionVector(start, point);
    const t = dotProduct(line, startToPoint) / magnitudeSquared(line);

    if (t < 0) {
        return start;
    } else if (t > 1) {
        return end;
    }

    return [start[0] + t * line[0], start[1] + t * line[1]];
}

/**
 * Calculates the angular distance between two points on a sphere using their coordinates.
 *
 * @param {[number, number]} p1 - The coordinates in radians of the first point as [longitude, latitude].
 * @param {[number, number]} p2 - The coordinates in radians of the second point as [longitude, latitude].
 * @returns {number} The angular distance between the two points in radians.
 */
export function calculateAngularDistance(p1: [number, number], p2: [number, number]): number {
    const [lng1, lat1] = p1;
    const [lng2, lat2] = p2;
    const deltaLat = lat2 - lat1;
    const deltaLon = lng2 - lng1;

    return 2 * Math.asin(Math.sqrt(Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2)
        + Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2)));
}

/**
 * Calculates the initial bearing between two points on a sphere using their coordinates.
 *
 * @param {[number, number]} p1 - The coordinates in radians of the first point as [longitude, latitude].
 * @param {[number, number]} p2 - The coordinates in radians of the second point as [longitude, latitude].
 * @returns {number} The initial bearing between the two points in radians.
 */
export function calculateInitialBearingBetween(p1: [number, number], p2: [number, number]): number {
    const [lng1, lat1] = p1;
    const [lng2, lat2] = p2;
    const y = Math.sin(lng2 - lng1) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
    const theta = Math.atan2(y, x);

    return (theta + 2 * Math.PI) % (2 * Math.PI); // normalize to [0, 2π)
}