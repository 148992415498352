import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { zoomFromValidator, zoomToValidator } from '../../shared/threshold.directive';
import { createObjectFromKeypath, getKeyPaths, mergeObjects, nullifyParentProps, primitiveClone, setNestedProperty } from '../object-helper';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { DisplayRule } from '../../locations/location.model';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { ExtendedLocation, LocationService } from '../../locations/location.service';
import { GeoJSONGeometryType, RegexPatterns } from '../enums';
import { SolutionService } from '../../services/solution.service';
import { MediaLibraryService } from '../../media-library/media-library.service';
import { NotificationService } from '../../services/notification.service';
import { filter } from 'rxjs/operators';
import { LabelTypeFormat, labelFormats, labelTypeFormats } from '../../display-rule-details/label-format';
import { createDropdownItemElement } from '../mi-dropdown/mi-dropdown';
import { Subject, Subscription } from 'rxjs';
import { MediaLibraryComponent } from '../../media-library/media-library.component';
import { Router } from '@angular/router';
import { stayAtCurrentUrl } from '../../solution-settings/solution-settings-shared-functions.component';
import { MediaCategory, MediaLibrarySource } from '../../media-library/media.enum';
import { hasMoreDecimalsValidator, isSmallerThanValidator } from '../validators/validators';
import { Box3, Vector3 } from 'three';
import { formattedNumberWithDecimals } from '../number-helper';
import { ImageSize } from '../../display-rule-details/image-size.model';
import { environment } from '../../../environments/environment';
import { MapsIndoorsIcons } from '../../media-library/mapsindoors-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { isNullOrUndefined } from '../../../utilities/Object';
import equal from 'fast-deep-equal';
import { Solution } from '../../solutions/solution.model';
import { getFormControl, getKeypathValue } from '../../shared/form-helper';
import { IMediaItem } from '../../media-library/media-item/media-item.model';

enum SizeOption {
    Default,
    RealSize,
    CalculateSize,
    RelativeSize
}

enum ModelDataOption {
    Preview,
    Original
}

interface FormControlState {
    keyPath: string;
    value: string;
    disabled: boolean;
}

interface PreviewObject {
    name: string,
    src: string
}

@Component({
    selector: 'display-rule-details-editor',
    templateUrl: './display-rule-details-editor.component.html',
    styleUrls: ['./display-rule-details-editor.component.scss'],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {},
        },
    ],
})
export class DisplayRuleDetailsEditorComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('labelNameDropdown', { static: true }) labelNameDropdownElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('labelMaxWidthVisibleInput') labelMaxWidthVisibleInput: ElementRef<HTMLInputElement>;
    @ViewChild('labelTypeDropdown') newLabelNameDropdownElement: ElementRef<HTMLMiDropdownElement>;

    @ViewChildren('model2d__preview') model2dPreviewContainer: QueryList<ElementRef>;
    @ViewChildren('numberInputs') numberInputs: QueryList<ElementRef>;

    private _geometries: [GeoJSON.Geometry, GeoJSON.Point?];
    private _initialGeometries: [GeoJSON.Geometry, GeoJSON.Point?];
    private subscriptions = new Subscription();
    private selectedSolutionSubscription: Subscription;
    private _displayRule: DisplayRule;
    private initialDisplayRule: DisplayRule;
    private _inheritedDisplayRule: DisplayRule;
    private initialFormControlStates: FormControlState[] = [];
    private displayRuleKeyPaths: string[] = [];
    private mediaLibraryModalConfig: MatDialogConfig = {
        width: '90vw',
        minWidth: '1024px', // smallest screen size supported by CMS
        maxWidth: '1700px', // the number is a subject to change after UX has tested properly
        height: '85vh',     // the number is a subject to change after UX has tested properly
        maxHeight: '928px', // the number is a subject to change after UX has tested properly
        minHeight: '550px', // the number is a subject to change after UX has tested properly
        role: 'dialog',
        panelClass: 'details-dialog',
        disableClose: true
    };
    private model2DAspectRatio: number = 1;
    private model3DbaseMeasurements: ImageSize;
    private original2DModelSize: { width: number, height: number };

    public isDisplayRuleFormDirty = false;
    public solutionSubscription: any;
    public model2DModuleEnabled = false;
    public model3DModuleEnabled = false;
    public wallsModuleEnabled = false;
    public extrusionsModuleEnabled = false;
    public maxZoomLevel = 22;
    public minZoomLevel = 1;
    public labelMaxWidthVisible = false;
    public isFloatingLabelEnabled: boolean = false;
    public isGraphicLabelEnabled: boolean = false;
    public displayRuleSections = {
        general: ['visible'],
        icon: ['iconVisible', 'zoomFrom', 'zoomTo', 'icon', 'imageSize.width', 'imageSize.height', 'imageScale'],
        label: ['labelVisible', 'labelZoomFrom', 'labelZoomTo', 'label', 'labelMaxWidth'],
        labelStyle: ['labelType', 'labelStyle.textSize', 'labelStyle.textColor', 'labelStyle.textOpacity', 'labelStyle.haloColor', 'labelStyle.haloWidth', 'labelStyle.haloBlur', 'labelStyle.bearing', 'labelStyle.graphic'],
        polygon: ['polygon.visible', 'polygon.zoomFrom', 'polygon.zoomTo', 'polygon.strokeColor', 'polygon.strokeWidth', 'polygon.strokeOpacity', 'polygon.fillColor', 'polygon.fillOpacity'],
        model2d: ['model2D.visible', 'model2D.zoomFrom', 'model2D.zoomTo', 'model2D.model', 'model2D.widthMeters', 'model2D.heightMeters', 'model2D.bearing'],
        walls: ['walls.visible', 'walls.zoomFrom', 'walls.zoomTo', 'walls.color', 'walls.height'],
        extrusion: ['extrusion.visible', 'extrusion.zoomFrom', 'extrusion.zoomTo', 'extrusion.color', 'extrusion.height'],
        model3d: ['model3D.visible', 'model3D.zoomFrom', 'model3D.zoomTo', 'model3D.model', 'model3D.rotationX', 'model3D.rotationY', 'model3D.rotationZ', 'model3D.scale', 'model3D.widthMeters', 'model3D.heightMeters']
    };
    public displayRuleEditorForm = this.formBuilder.group({
        visible: ['', [Validators.required]],
        iconVisible: ['', [Validators.required]],
        model2D: this.formBuilder.group({
            visible: ['', [Validators.required]],
            widthMeters: ['', [Validators.required, Validators.min(0)]],
            heightMeters: ['', [Validators.required, Validators.min(0)]],
            bearing: ['', [Validators.required, Validators.min(0), isSmallerThanValidator(360)]],
            zoomFrom: ['', [Validators.required, zoomFromValidator('zoomTo')]],
            zoomTo: ['', [Validators.required, zoomToValidator('zoomFrom')]],
            model: ['']
        }),
        model: [''],
        zoomFrom: ['', [Validators.required, zoomFromValidator('zoomTo')]],
        zoomTo: ['', [Validators.required, zoomToValidator('zoomFrom')]],
        icon: ['', [Validators.required]],
        imageSize: this.formBuilder.group({
            width: [''],
            height: [''],
        }),
        imageScale: [''],
        labelVisible: ['', [Validators.required]],
        label: ['', [Validators.required]],
        labelZoomFrom: ['', [Validators.required, zoomFromValidator('labelZoomTo')]],
        labelZoomTo: ['', [Validators.required, zoomToValidator('labelZoomFrom')]],
        labelMaxWidth: ['', [Validators.required, Validators.min(0), Validators.pattern(RegexPatterns.NumericalNoDecimals)]],
        labelType: ['', [Validators.required]],
        labelStyle: this.formBuilder.group({
            textSize: ['', [Validators.required, Validators.min(1), Validators.max(255)]],
            textColor: ['', [Validators.required, Validators.pattern(RegexPatterns.HexColor)]],
            textOpacity: ['', [Validators.required, Validators.min(0), Validators.max(1)]],
            haloColor: ['', [Validators.required, Validators.pattern(RegexPatterns.HexColor)]],
            haloWidth: ['', [Validators.required, Validators.min(0), Validators.max(64)]],
            haloBlur: ['', [Validators.required, Validators.min(0), Validators.max(64)]],
            bearing: ['', [Validators.required, Validators.min(0), isSmallerThanValidator(361)]],
            graphic: this.formBuilder.group({
                backgroundImage: [''],
                stretchX: [''],
                stretchY: [''],
                content: ['']
            })
        }),
        polygon: this.formBuilder.group({
            visible: ['', [Validators.required]],
            zoomFrom: ['', [Validators.required, zoomFromValidator('zoomTo')]],
            zoomTo: ['', [Validators.required, zoomToValidator('zoomFrom')]],
            strokeWidth: ['', [Validators.required, Validators.min(0), Validators.pattern(RegexPatterns.NumericalNoDecimals)]],
            strokeColor: [''],
            strokeOpacity: ['', [Validators.required, Validators.min(0), Validators.max(1)]],
            fillColor: [''],
            fillOpacity: ['', [Validators.required, Validators.min(0), Validators.max(1)]],
        }),
        walls: this.formBuilder.group({
            visible: ['', [Validators.required]],
            zoomFrom: ['', [Validators.required, zoomFromValidator('zoomTo')]],
            zoomTo: ['', [Validators.required, zoomToValidator('zoomFrom')]],
            color: [''],
            height: ['', [Validators.required, Validators.min(0)]],
        }),
        extrusion: this.formBuilder.group({
            visible: ['', [Validators.required]],
            zoomFrom: ['', [Validators.required, zoomFromValidator('zoomTo')]],
            zoomTo: ['', [Validators.required, zoomToValidator('zoomFrom')]],
            color: [''],
            height: ['', [Validators.required, Validators.min(0)]],
        }),
        model3D: this.formBuilder.group({
            visible: ['', [Validators.required]],
            zoomFrom: ['', [Validators.required, zoomFromValidator('zoomTo')]],
            zoomTo: ['', [Validators.required, zoomToValidator('zoomFrom')]],
            model: [''],
            rotationX: ['', [Validators.required, Validators.min(0), Validators.max(360)]],
            rotationY: ['', [Validators.required, Validators.min(0), Validators.max(360)]],
            rotationZ: ['', [Validators.required, Validators.min(0), Validators.max(360)]],
            scale: ['', [Validators.required, Validators.min(0), Validators.max(1000), hasMoreDecimalsValidator(10)]],
            widthMeters: ['', [Validators.required, Validators.min(0)]],
            heightMeters: ['', [Validators.required, Validators.min(0)]]
        })
    });
    public model2DPreview: PreviewObject = { name: '', src: '' };
    public model3DPreview: PreviewObject = { name: '', src: '' };
    public graphicLabelPreview: PreviewObject = { name: '', src: '' };
    public isMapboxEnabled: boolean = false;
    public isDisplayRuleFormValid: boolean = true;
    public readonly fallBackPreview = 'assets/images/image_placeholder.svg';
    public showSaveToTypeButton: boolean = false;

    /**
     * Form control getter.
     */
    public getFormControl = getFormControl;

    /**
     * When we make changes and we want to discard them, we pass discard to Display Rule Details, where dialogRef (modal box) is known.
     */
    @Output() formDiscard = new EventEmitter<any>();

    /**
     * When we make changes and want to save them, we pass changes to Display Rule Details, where dialogRef (modal box) is known.
     */
    @Output() formSubmit = new EventEmitter<any>();

    /**
     * When we push Display Rule Details to the Location Type.
     */
    @Output() pushDisplayRuleToType = new EventEmitter<{ displayRuleProperties: string[], displayRule: DisplayRule, onSuccess: Function, onFailure: Function }>();

    /**
     * When a form control is enabled or disabled, we notify the parent component of the current status, including whether any form controls are enabled.
     */
    @Output() areFromControlsEnabled = new EventEmitter<boolean>();

    /**
     * Property that differentiate between Locations, Location Types and Main Display Rule. For first two we close modal, for Main DR we reset the values to original state.
     */
    @Input() discardChangesMainDisplayRule;

    /**
     * Property that defines location's type.
     */
    @Input() is3DWallsSectionVisible: boolean;

    /**
     * Differentiate between Locations/Types and Main Display Rule.
     */
    @Input() isMainDisplayRule: boolean = false;

    /**
     * The location the Display Rule Details Editor is opened for (if any).
     */
    @Input() location: ExtendedLocation;

    /**
     * Geometries setter.
     *
     * @type {[GeoJSON.Geometry, GeoJSON.Point?]}
     */
    @Input() set geometries(geometries: [GeoJSON.Geometry, GeoJSON.Point?]) {
        if (!isNullOrUndefined(this._initialGeometries) && !equal(this._initialGeometries, geometries)) {
            this.isDisplayRuleFormDirty = true;
        } else {
            this._initialGeometries = geometries;
        }
        this._geometries = geometries;
    }

    /**
     * The current display rule that is loaded before form is populated.
     *
     * @type {[DisplayRule, DisplayRule?]}
     * @memberof DisplayRuleDetailsComponent
     */
    @Input()
    set displayRules([displayRule, inheritedDisplayRule]: [DisplayRule, DisplayRule?]) {
        this._inheritedDisplayRule = inheritedDisplayRule;
        this._displayRule = displayRule;
        this.initialDisplayRule = displayRule;

        this.setFormValues(displayRule);
        this.setLabelFormatDropdownItems(
            this._displayRule?.label || this._inheritedDisplayRule?.label
        );

        if (this.newLabelNameDropdownElement?.nativeElement) {
            this.setLabelTypeFormatDropdownItems(
                this._displayRule?.labelType || this._inheritedDisplayRule?.labelType
            );
        }

        // When opening Display Rules, check what labelType is selected. Based on that either show Rotation or Graphic Label section.
        if (this._displayRule !== null) {
            if (displayRule?.labelType === LabelTypeFormat.TextLabel || (inheritedDisplayRule?.labelType === LabelTypeFormat.TextLabel && getFormControl(this.displayRuleEditorForm, 'labelType').disabled === true)) {
                this.isFloatingLabelEnabled = true;
                this.isGraphicLabelEnabled = false;
            } else if (displayRule?.labelType === LabelTypeFormat.FlatLabel || (inheritedDisplayRule?.labelType === LabelTypeFormat.FlatLabel && getFormControl(this.displayRuleEditorForm, 'labelType').disabled === true)) {
                this.isFloatingLabelEnabled = false;
                this.isGraphicLabelEnabled = false;
            } else {
                this.isFloatingLabelEnabled = false;
                this.isGraphicLabelEnabled = true;
            }
        }

        this.labelMaxWidthVisible =
            this._displayRule?.labelMaxWidth !== 0 ? true : false;

        // Get the formControls initial state for manually controlling the displayRule forms dirty state
        this.initialFormControlStates = this.getFormControlsInitialState();

        this.displayRuleEditorForm.markAsPristine();
        this.isDisplayRuleFormDirty = false;

        // Informs the parent component of the current, initial status of the form controls.
        this.areFromControlsEnabled.emit(this.areAnySettingsUnlocked());
    }

    /**
     * Toggle visibility of geometry related display rule settings.
     *
     * @type {boolean}
     * @memberof DisplayRuleDetailsComponent
     */
    @Input() isGeometrySettingsVisible: boolean = true;

    /**
     * IsFit2DModelEnabled property.
     *
     * @public
     * @readonly
     * @type {boolean}
     */
    public get isFit2DModelEnabled(): boolean {
        return !this.isMainDisplayRule && this._geometries?.[0].type === GeoJSONGeometryType.Polygon;
    }

    /**
     * Set additional information to be displayed in the panel-header component.
     *
     * @type {string}
     * @memberof DisplayRuleDetailsComponent
     */
    @Input() header: string;

    @Input() pushToTypeButtonClicked: Subject<void>;

    /**
     * If Display Rule Details Editor form is dirty.
     *
     * @returns {boolean}
     */
    public get dirty(): boolean {
        return this.displayRuleEditorForm.dirty;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private solutionService: SolutionService,
        private mediaLibraryService: MediaLibraryService,
        private locationService: LocationService,
        private notificationService: NotificationService,
        private matDialog: MatDialog,
        private router: Router,
        private mapsIndoorsIcons: MapsIndoorsIcons,
        private spinner: NgxSpinnerService,
        private displayRuleService: DisplayRuleService
    ) {
        this.selectedSolutionSubscription = this.solutionService.selectedSolution$.subscribe((solution) => {
            this.isMapboxEnabled = solution.modules.includes('mapbox');
            this.extrusionsModuleEnabled = solution?.modules?.includes('3dextrusions');
            this.wallsModuleEnabled = solution?.modules?.includes('3dwalls');
            this.model2DModuleEnabled = solution?.modules?.includes('2dmodels');
            this.model3DModuleEnabled = solution?.modules?.includes('3dmodels');
            this.maxZoomLevel = Solution.getMaxZoomLevel(solution);
        });

        this.displayRuleKeyPaths = getKeyPaths(
            DisplayRuleService.MAIN_DISPLAY_RULE
        ) as string[];
    }

    /**
     * Checks if any of the form controls are enabled.
     *
     * @returns {boolean}
     */
    private areAnySettingsUnlocked(): boolean {
        return this.displayRuleKeyPaths.some((keyPath) => {
            return this.getFormControl(this.displayRuleEditorForm, keyPath).enabled;
        });
    }

    /**
     * Is called after Angular has initialized all data-bound properties of a directive.
     */
    ngOnInit(): void {
        this.subscriptions
            .add(this.routerEventsSubscription())
            .add(this.labelSubscription())
            .add(this.labelTypeSubscription())
            .add(this.iconSubscription())
            .add(this.model2DBearingSubscription())
            .add(this.model2DModelSubscription())
            .add(this.model3DModelSubscription())
            .add(this.graphicLabelSubscription())
            .add(this.formValidSubscription())
            .add(this.pushToTypeButtonClickedSubscription());

        // The 2/3D models should be enabled by default if the display rules are opened from the solution settings page.
        const model2DmodelFormControl = getFormControl(this.displayRuleEditorForm, 'model2D.model');
        const model3DmodelFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.model');
        if (this.isMainDisplayRule) {
            model2DmodelFormControl.enable({ emitEvent: false });
            model3DmodelFormControl.enable({ emitEvent: false });
        }

        const model2DWidthControl = getFormControl(this.displayRuleEditorForm, 'model2D.widthMeters') as UntypedFormControl;
        const model2DHeightControl = getFormControl(this.displayRuleEditorForm, 'model2D.heightMeters') as UntypedFormControl;
        this.model2DDimensionSubscription(model2DWidthControl, model2DHeightControl).forEach(subscription => {
            this.subscriptions.add(subscription);
        });

        if ((model2DmodelFormControl.value && model2DmodelFormControl.disabled) || !model2DmodelFormControl.value) {
            model2DWidthControl.disable({ emitEvent: false });
            model2DHeightControl.disable({ emitEvent: false });
        } else {
            model2DWidthControl.enable({ emitEvent: false });
            model2DHeightControl.enable({ emitEvent: false });
        }

        // Setting the width and height of a 2D model is only available for users who have enabled the 2D Models feature.
        if (this.isModel2DInteractable()) {
            if (model2DmodelFormControl.value) {
                this.model2DPreview = this.getModelData(ModelDataOption.Original, model2DmodelFormControl.value);
            }
        }

        // If value is locked (null), we need to preview to backgroundImage to have it displayed.
        const graphicLabelFormControl = getFormControl(this.displayRuleEditorForm, 'labelStyle.graphic.backgroundImage');
        if (graphicLabelFormControl.value) {
            this.graphicLabelPreview = this.getModelData(ModelDataOption.Original, graphicLabelFormControl.value);
        }

        // Subscribing to changes related to the 3D model's measurements.
        this.model3DMeasurementsSubscription().forEach(subscription => {
            this.subscriptions.add(subscription);
        });

        // If the display rule shold a initial value for the 3D model, we need to load the preview from the backend.
        // This also affects the accessibility of the attached width and height form controls.
        const model3DscaleFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.scale');
        const model3DwidthFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.widthMeters');
        const model3DheightFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.heightMeters');
        if (model3DmodelFormControl.value) {
            this.fillMeasurementsControls(model3DmodelFormControl.value);

            this.model3DPreview = this.getModelData(ModelDataOption.Preview, model3DmodelFormControl.value);
        }

        if ((model3DmodelFormControl.value && model3DscaleFormControl.disabled) || !model3DmodelFormControl.value) {
            model3DwidthFormControl.disable({ emitEvent: false });
            model3DheightFormControl.disable({ emitEvent: false });
        } else {
            model3DwidthFormControl.enable({ emitEvent: false });
            model3DheightFormControl.enable({ emitEvent: false });
        }

        getFormControl(this.displayRuleEditorForm, 'labelType').valueChanges.subscribe((labelType) => {
            if (labelType === LabelTypeFormat.FlatLabel) {
                this.isFloatingLabelEnabled = false;
            } else {
                this.isFloatingLabelEnabled = true;
            }
        });

        this.labelMaxWidthVisible = getFormControl(this.displayRuleEditorForm, 'labelMaxWidth').value > 0 ? true : false;
        this.showSaveToTypeButton = this.location ? true : false;
    }

    /**
     * Is called after Angular has fully initialized a component's view.
     */
    ngAfterViewInit(): void {
        this.spinner.show('model2d-spinner');

        let currentContainer: HTMLImageElement;

        if (this.model2dPreviewContainer.first) {
            currentContainer = this.model2dPreviewContainer.first.nativeElement;
            this.listenToLoadedImageAndEvaluateOriginalSize(currentContainer);
        } else {
            this.model2dPreviewContainer.changes.subscribe(() => {
                if (this.model2dPreviewContainer.first && (!currentContainer || currentContainer !== this.model2dPreviewContainer.first.nativeElement)) {
                    currentContainer = this.model2dPreviewContainer.first.nativeElement;
                    this.listenToLoadedImageAndEvaluateOriginalSize(currentContainer);
                } else {
                    currentContainer = null;
                }
            });
        }

        if (this.newLabelNameDropdownElement?.nativeElement) {
            this.setLabelTypeFormatDropdownItems(
                this._displayRule?.labelType || this._inheritedDisplayRule?.labelType
            );
        }

        // When opening Display Rules, check what labelType is selected. If it is Flat Label, show Rotation section.
        if (this._displayRule !== null) {
            if (this._displayRule?.labelType === LabelTypeFormat.TextLabel || (this._inheritedDisplayRule?.labelType === LabelTypeFormat.TextLabel && getFormControl(this.displayRuleEditorForm, 'labelType').disabled === true)) {
                this.isFloatingLabelEnabled = true;
                this.isGraphicLabelEnabled = false;
            } else if (this._displayRule?.labelType === LabelTypeFormat.FlatLabel || (this._inheritedDisplayRule?.labelType === LabelTypeFormat.FlatLabel && getFormControl(this.displayRuleEditorForm, 'labelType').disabled === true)) {
                this.isFloatingLabelEnabled = false;
                this.isGraphicLabelEnabled = false;
            } else {
                this.isFloatingLabelEnabled = false;
                this.isGraphicLabelEnabled = true;
            }
        }

        this.numberInputs?.forEach((input: ElementRef) => {
            input.nativeElement.addEventListener('keydown', this.onKeyDown);
        });

        this.displayRuleEditorForm.valueChanges.subscribe(() => {
            this.isDisplayRuleFormDirty = this.isFormDirty(); // NOTE: Manually marking the displayRuleForm as dirty doesn't trigger the needed change detection
            this.areFromControlsEnabled.emit(this.areAnySettingsUnlocked());
        });
    }

    /**
     * Listens to when the image from the provided html image tag is loaded. It hides the spinner, and resets the original2DModelSize property.
     *
     * @param {HTMLImageElement} imageContainer
     */
    private listenToLoadedImageAndEvaluateOriginalSize(imageContainer: HTMLImageElement): void {
        imageContainer.addEventListener('load', () => {
            this.spinner.hide('model2d-spinner');

            const imageSizeInPixels = { width: imageContainer.naturalWidth, height: imageContainer.naturalHeight };
            this.original2DModelSize = this.calculateImageSize(SizeOption.RelativeSize, imageSizeInPixels);
        });
    }

    /**
     * Returns true/false depending on the dimensions of the 2D model and current width and height settings.
     *
     * @returns {boolean}
     */
    public model2DResetSizeDisabled(): boolean {
        return getFormControl(this.displayRuleEditorForm, 'model2D.model').disabled ||
            (this.original2DModelSize?.width === getFormControl(this.displayRuleEditorForm, 'model2D.widthMeters').value &&
                this.original2DModelSize?.height === getFormControl(this.displayRuleEditorForm, 'model2D.heightMeters').value);
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.selectedSolutionSubscription.unsubscribe();
    }

    /**
     * Dectects changes in URL path. Subscribe to form state. If dirty, triggers onDiscardChanges().
     *
     * @returns {Subscription}
     */
    private routerEventsSubscription(): Subscription {
        return this.router.events
            .subscribe(() => {
                if (this.displayRuleEditorForm.dirty) {
                    this.onDiscardChanges();
                }
            });
    }

    /**
     * Subscribing to changes on the label form control.
     * Set main display rule value when label form control is being disabled.
     *
     * @returns {Subscription}
     */
    private labelSubscription(): Subscription {
        const labelFormControl = getFormControl(this.displayRuleEditorForm, 'label');
        return labelFormControl.valueChanges
            .pipe(filter(() => labelFormControl.disabled))
            .subscribe(() => this.resetLabelFormatDropdown());
    }

    /**
     * Subscribing to changes on the label type form control.
     * Set main display rule value when label form control is being disabled.
     *
     * @returns {Subscription}
     */
    private labelTypeSubscription(): Subscription {
        const labelTypeFormControl = getFormControl(this.displayRuleEditorForm, 'labelType');
        return labelTypeFormControl.valueChanges
            .pipe(filter(() => labelTypeFormControl.disabled))
            .subscribe(() => this.resetLabelTypeFormatDropdown());
    }

    /**
     * Subscribing to changes on the icon form control.
     * Reset icon related controls when icon control is disabled.
     *
     * @returns {Subscription}
     */
    private iconSubscription(): Subscription {
        const iconFormControl = getFormControl(this.displayRuleEditorForm, 'icon');
        return iconFormControl.valueChanges
            .pipe(filter(() => iconFormControl.disabled))
            .subscribe(() => {
                const iconRelatedControls = [
                    'imageSize.width',
                    'imageSize.height',
                    'imageScale',
                ];
                iconRelatedControls.forEach((keyPath) => {
                    const formControl = getFormControl(this.displayRuleEditorForm, keyPath);
                    formControl.setValue(null);
                    formControl.disable();
                    // TODO: Check if this is needed here:
                    formControl.markAsDirty();
                });
            });
    }

    /**
     * Subscribing to changes on the model2D.bearing form control.
     *
     * @returns {Subscription}
     */
    private model2DBearingSubscription(): Subscription {
        let previousBearingValue: number;
        const model2DBearingFormControl = getFormControl(this.displayRuleEditorForm, 'model2D.bearing');

        return model2DBearingFormControl.valueChanges.subscribe(value => {
            if (!value) return;

            if (!Number.isInteger(value)) { // Checks if number has decimals
                const decimals = Number((value - Math.floor(value)).toFixed(8)); // Extracting the round number from the whole number and setting the decimal to 8 places (8 because we have to check if it will be longer than 7 places)
                if (Number(decimals).toString().length - 2 > 7) { //  We have to remove 2 from the converted number for: '0' and '.'
                    if (!previousBearingValue) {
                        previousBearingValue = Number(decimals.toFixed(7)); // Must be rounded to 7 places because the value has more than 7 decimal places
                    }
                    model2DBearingFormControl.patchValue(previousBearingValue, { emitEvent: false }); // If the value is too long, we reset it to the previous value
                } else {
                    previousBearingValue = value;
                }
            }
        });
    }

    /**
     * Subscribing to changes on the model2D.model form control.
     *
     * @returns {Subscription}
     */
    private model2DModelSubscription(): Subscription {
        const model2DmodelFormControl = getFormControl(this.displayRuleEditorForm, 'model2D.model');

        return model2DmodelFormControl.valueChanges
            .subscribe(newModel => this.model2DPreview = this.getModelData(ModelDataOption.Original, newModel));
    }

    /**
     * Subscribing to changes on the labelStyle.graphic.backgroundImage form control.
     *
     * @returns {Subscription}
     */
    private graphicLabelSubscription(): Subscription {
        const graphicLabelFormControl = getFormControl(this.displayRuleEditorForm, 'labelStyle.graphic.backgroundImage');

        return graphicLabelFormControl.valueChanges
            .subscribe(newModel => this.graphicLabelPreview = this.getModelData(ModelDataOption.Original, newModel));
    }


    /**
     * Subscribing to changes on the 2D dimension related form controls.
     *
     * @param {UntypedFormControl} widthControl
     * @param {UntypedFormControl} heightControl
     * @returns {Array<Subscription>}
     */
    private model2DDimensionSubscription(widthControl: UntypedFormControl, heightControl: UntypedFormControl): Array<Subscription> {
        const subscription: Array<Subscription> = [];

        if (widthControl.value > 0 && heightControl.value > 0) {
            this.model2DAspectRatio = this.calculateAspectRatio(widthControl.value, heightControl.value);
        }

        subscription.push(
            widthControl.valueChanges.subscribe(() => {
                heightControl.patchValue(Math.round((widthControl.value / this.model2DAspectRatio) * 100) / 100, { emitEvent: false });
                heightControl.markAsDirty();
            }),

            heightControl.valueChanges.subscribe(() => {
                widthControl.patchValue(Math.round((heightControl.value * this.model2DAspectRatio) * 100) / 100, { emitEvent: false });
                widthControl.markAsDirty();
            })
        );

        return subscription;
    }

    /**
     * Calculates the ascpect ratio for the 2D model section.
     *
     * @param {number} width
     * @param {number} height
     * @returns {number}
     */
    private calculateAspectRatio(width: number, height: number): number {
        return width / height;
    }

    /**
     * Subscribing to changes on the model3D.model form control.
     *
     * @returns {Subscription}
     */
    private model3DModelSubscription(): Subscription {
        const model3DmodelFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.model');

        return model3DmodelFormControl.valueChanges
            .subscribe((newModel) => {
                this.fillMeasurementsControls(model3DmodelFormControl.value);
                this.model3DPreview = this.getModelData(ModelDataOption.Preview, newModel);
            });
    }

    /**
     * Subscribing to changes on the 3D measurements related form controls.
     *
     * @returns {Array<Subscription>}
     */
    private model3DMeasurementsSubscription(): Array<Subscription> {
        const subscription: Array<Subscription> = [];
        const model3DscaleFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.scale') as UntypedFormControl;
        const model3DwidthFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.widthMeters') as UntypedFormControl;
        const model3DheightFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.heightMeters') as UntypedFormControl;

        subscription.push(
            model3DscaleFormControl.valueChanges.subscribe((newScale) => {
                if (!this.model3DbaseMeasurements) return;
                this.update3dDimensionControls(newScale, [model3DwidthFormControl, model3DheightFormControl]);
            }),
            model3DwidthFormControl.valueChanges.subscribe((newWidth) => {
                if (!this.model3DbaseMeasurements?.width) return;
                const newScale = newWidth / this.model3DbaseMeasurements.width;
                this.update3dDimensionControls(newScale, [model3DscaleFormControl, model3DheightFormControl]);
            }),
            model3DheightFormControl.valueChanges.subscribe((newHeight) => {
                if (!this.model3DbaseMeasurements?.height) return;
                const newScale = newHeight / this.model3DbaseMeasurements.height;
                this.update3dDimensionControls(newScale, [model3DscaleFormControl, model3DwidthFormControl]);
            })
        );

        return subscription;
    }

    /**
     * Subscribing to changes on the form valid state.
     *
     * @returns {Subscription}
     */
    private formValidSubscription(): Subscription {
        return this.displayRuleEditorForm.valueChanges
            .subscribe((value) => {
                const isGraphicLabel = value.labelType === LabelTypeFormat.GraphicLabel;
                const hasNoBackgroundImage = (!value.labelStyle?.graphic?.backgroundImage && !this._inheritedDisplayRule?.labelStyle?.graphic?.backgroundImage);

                this.isDisplayRuleFormValid =
                    this.displayRuleEditorForm.valid
                    && !(isGraphicLabel && hasNoBackgroundImage);
            });
    }

    /**
     * Subscribing to the push to type button clicked event.
     *
     * @returns {Subscription}
     */
    private pushToTypeButtonClickedSubscription(): Subscription {
        return this.pushToTypeButtonClicked?.subscribe(() => {
            const fullArray = Object.keys(this.displayRuleSections).reduce((acc, key) => {
                return acc.concat(this.displayRuleSections[key]);
            }, []);
            this.onSaveToType(fullArray);
        });
    }

    /**
     * Returns whether the 2D model is interactable (is enabled and does exist).
     *
     * @returns {boolean}
     * @memberof DisplayRuleDetailsComponent
     */
    private isModel2DInteractable(): boolean {
        return this.model2DModuleEnabled && this._displayRule?.model2D !== undefined;
    }

    /**
     * Constructs the objects used to display the 2/3D models' names and preview images.
     *
     * @param {ModelDataOption} modelDataType
     * @param {string} modelUrl
     * @returns {Promise<PreviewObject>}
     */
    private getModelData(modelDataType: ModelDataOption, modelUrl: string): PreviewObject {
        if (!modelUrl) {
            return null;
        }

        if (modelUrl.includes(environment.iconsBaseUrl)) {
            const mapsIndoorsIconName = this.mapsIndoorsIcons.getIcons().find(icon => icon.preview === modelUrl).name;
            return {
                name: mapsIndoorsIconName,
                src: modelUrl
            };
        }

        const nameWithExtension: string = modelUrl?.substring(modelUrl.lastIndexOf('/') + 1);
        const nameWithoutExtension: string = nameWithExtension.substring(0, nameWithExtension.lastIndexOf('.'));
        const decodedNameWithoutExtension: string = decodeURIComponent(nameWithoutExtension);

        if (modelDataType === ModelDataOption.Original) {
            return {
                name: decodedNameWithoutExtension,
                src: modelUrl
            };
        }

        return {
            name: decodedNameWithoutExtension,
            src: this.mediaLibraryService.getMediaPreviewByName(nameWithExtension)
        };
    }

    /**
     * Fills in the measurement related form controls based on the scale's value.
     * The agreed unit-to-meter value is 1:1, this means that 1 meter is 1 vector.
     *
     * @param {string} fileSrc
     */
    private fillMeasurementsControls(fileSrc: string): void {
        const scaleControl = getFormControl(this.displayRuleEditorForm, 'model3D.scale');
        const widthControl = getFormControl(this.displayRuleEditorForm, 'model3D.widthMeters');
        const heightControl = getFormControl(this.displayRuleEditorForm, 'model3D.heightMeters');

        if (!fileSrc) {
            widthControl.setValue('', { emitEvent: false });
            heightControl.setValue('', { emitEvent: false });
            widthControl.disable({ emitEvent: false });
            heightControl.disable({ emitEvent: false });
            return;
        }

        this.mediaLibraryService.load3D(fileSrc)
            .then((gltf) => {
                // 3D model bounding box - created a box with the minimum dimensions needed to fit the 3D model inside.
                const boundingBox = new Box3().setFromObject(gltf?.scene);
                const size = boundingBox?.getSize(new Vector3());

                if (!size || scaleControl.disabled) {
                    widthControl.disable({ emitEvent: false });
                    heightControl.disable({ emitEvent: false });

                    if (!size) return;
                } else {
                    widthControl.enable({ emitEvent: false });
                    heightControl.enable({ emitEvent: false });
                }

                this.model3DbaseMeasurements = {
                    width: size.x,
                    height: size.y
                };

                // Upper limit is the size of the bounding box's dimensions multiplied by the maximum scale level.
                widthControl.setValidators([Validators.max(this.model3DbaseMeasurements.width * 1000)]);
                heightControl.setValidators([Validators.max(this.model3DbaseMeasurements.height * 1000)]);

                const scaledWidth = formattedNumberWithDecimals(size.x * scaleControl.value, 2);
                const scaledHeight = formattedNumberWithDecimals(size.y * scaleControl.value, 2);

                widthControl.setValue(scaledWidth, { emitEvent: false });
                heightControl.setValue(scaledHeight, { emitEvent: false });
            })
            .catch(() => {
                this.notificationService.showError('3D model could not be loaded.');
                widthControl.disable({ emitEvent: false });
                heightControl.disable({ emitEvent: false });
            });
    }

    /**
     * Updates the dimensions formcontrols based on the new scale value.
     *
     * @param {number} newScale
     * @param {Array<UntypedFormControl>} formControls
     */
    private update3dDimensionControls(newScale: number, formControls: Array<UntypedFormControl>): void {
        const model3DscaleFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.scale') as UntypedFormControl;
        const model3DwidthFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.widthMeters') as UntypedFormControl;
        const model3DheightFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.heightMeters') as UntypedFormControl;

        const scale = formattedNumberWithDecimals(newScale, 10);
        if (scale && formControls.includes(model3DscaleFormControl)) {
            model3DscaleFormControl.patchValue(scale, { emitEvent: false });
        }

        const scaledWidth = formattedNumberWithDecimals(this.model3DbaseMeasurements.width * scale, 2);
        if (scaledWidth && formControls.includes(model3DwidthFormControl)) {
            model3DwidthFormControl.patchValue(scaledWidth, { emitEvent: false });
        }

        const scaledHeight = formattedNumberWithDecimals(this.model3DbaseMeasurements.height * scale, 2);
        if (scaledHeight && formControls.includes(model3DheightFormControl)) {
            model3DheightFormControl.patchValue(scaledHeight, { emitEvent: false });
        }

        if (scale !== this._displayRule?.model3D?.scale) {
            model3DscaleFormControl.markAsDirty();
        }
    }

    /**
     * Populate form with display rule values.
     * Set values from main display rule for undefined properties.
     *
     * @private
     * @param {DisplayRule} displayRule
     * @memberof DisplayRuleDetailsComponent
     */
    private setFormValues(displayRule: DisplayRule): void {
        for (const keyPath of this.displayRuleKeyPaths) {
            const formControl = getFormControl(this.displayRuleEditorForm, keyPath);

            // Skip undefined form controls
            if (!formControl) {
                continue;
            }

            const keyValue = getKeypathValue(displayRule, keyPath);

            // Set inherited value and disable control when not present in the initial display rule
            if (keyValue === null || keyValue === undefined) {
                const inheritedValue = getKeypathValue(
                    this._inheritedDisplayRule,
                    keyPath
                );
                formControl.patchValue(inheritedValue, { emitEvent: false });
                formControl.disable({ emitEvent: false });

                continue;
            }

            if (keyPath === 'model2D.model') {
                this.model2DPreview = this.getModelData(ModelDataOption.Original, keyValue);
            }

            if (keyPath === 'model3D.model') {
                this.model3DPreview = this.getModelData(ModelDataOption.Preview, keyValue);
            }

            if (keyPath === 'labelStyle.graphic.backgroundImage') {
                this.graphicLabelPreview = this.getModelData(ModelDataOption.Original, keyValue);
            }

            formControl.patchValue(keyValue, { emitEvent: false });
            formControl.enable({ emitEvent: false });
        }

        this.displayRuleEditorForm.updateValueAndValidity();
    }

    /**
     * Initialize the label type format dropdown.
     *
     * @private
     * @param {string} labelType
     * @memberof DisplayRuleDetailsComponent
     */
    private setLabelTypeFormatDropdownItems(labelType: string): void {
        this.newLabelNameDropdownElement.nativeElement.items = labelTypeFormats.map(
            (labelFormat) => {
                const selected = labelType === labelFormat.value;
                return createDropdownItemElement({
                    label: labelFormat.viewValue,
                    value: labelFormat.value,
                    selected
                });
            }
        );
    }

    /**
     * Initialize the label format dropdown.
     *
     * @private
     * @param {string} label
     * @memberof DisplayRuleDetailsComponent
     */
    private setLabelFormatDropdownItems(label: string): void {
        this.labelNameDropdownElement.nativeElement.items = labelFormats.map(
            (labelFormat) => {
                const selected = label === labelFormat.value;
                return createDropdownItemElement({
                    label: labelFormat.viewValue,
                    value: labelFormat.value,
                    selected
                });
            }
        );

        // Add an unknown option when an unknown label format is used or set as inheritance value
        const labelFormatIsUnknown =
            label !== null &&
            labelFormats.findIndex(
                (labelFormat) => labelFormat.value === label
            ) === -1;
        const inheritanceLabelFormatIsUnknown =
            this._inheritedDisplayRule?.label !== null &&
            labelFormats.findIndex(
                (labelFormat) =>
                    labelFormat.value ===
                    this._inheritedDisplayRule?.label
            ) === -1;
        if (labelFormatIsUnknown || inheritanceLabelFormatIsUnknown) {
            const dropdownItemValue = inheritanceLabelFormatIsUnknown
                ? this._inheritedDisplayRule?.label
                : label;
            const selected = labelFormatIsUnknown;
            const dropdownItem = createDropdownItemElement({
                label: '-',
                value: dropdownItemValue,
                selected
            });
            this.labelNameDropdownElement.nativeElement.items.unshift(
                dropdownItem
            );
        }
    }

    /**
     * Get form controls initial states.
     *
     * @private
     * @returns {FormControlState[]}
     * @memberof DisplayRuleDetailsComponent
     */
    private getFormControlsInitialState(): FormControlState[] {
        const initialStates: FormControlState[] = [];

        for (const keyPath of this.displayRuleKeyPaths) {
            const formControl = getFormControl(this.displayRuleEditorForm, keyPath);
            if (formControl) {
                initialStates.push({
                    keyPath: keyPath,
                    value: formControl.value,
                    disabled: formControl.disabled
                });
            }
        }

        return initialStates;
    }

    /**
     * Reset label format dropdown to match inherit display rule value.
     *
     * @private
     * @memberof DisplayRuleDetailsComponent
     */
    private resetLabelFormatDropdown(): void {
        const defaultDropdownItem =
            this.labelNameDropdownElement.nativeElement.items.find(
                (item) => item.value === this._inheritedDisplayRule?.label
            );
        this.labelNameDropdownElement.nativeElement.selected = [
            defaultDropdownItem,
        ];
    }

    /**
     * Reset label format dropdown to match inherit display rule value.
     *
     * @private
     * @memberof DisplayRuleDetailsComponent
     */
    private resetLabelTypeFormatDropdown(): void {
        const defaultDropdownItem =
            this.newLabelNameDropdownElement.nativeElement.items.find(
                (item) => item.value === this._inheritedDisplayRule.labelType
            );
        this.newLabelNameDropdownElement.nativeElement.selected = [
            defaultDropdownItem,
        ];
    }

    /**
     * Check if any of the controls value or inheritance state has changed from the initial state.
     *
     * @private
     * @returns {boolean}
     * @memberof DisplayRuleDetailsComponent
     */
    private isFormDirty(): boolean {
        if (!isNullOrUndefined(this._initialGeometries) && !equal(this._initialGeometries, this._geometries)) {
            return true;
        }

        const dirtyControls = this.displayRuleKeyPaths
            .filter((keyPath) => getFormControl(this.displayRuleEditorForm, keyPath)?.dirty)
            .filter((keyPath) => {
                const formControl = getFormControl(this.displayRuleEditorForm, keyPath);
                const initialFormControlState = this.initialFormControlStates.find(state => state.keyPath === keyPath);

                // If there are changes to graphic label backgroundImage/stretchX/stretchY/content and label type is not Graphic, do not consider the form control dirty.
                // If there are changes to bearing and label type is not Flat, do not consider the form control dirty.
                if ((keyPath === 'labelStyle.graphic.backgroundImage' && getFormControl(this.displayRuleEditorForm, 'labelType').value !== LabelTypeFormat.GraphicLabel)
                    || (keyPath === 'labelStyle.graphic.stretchX' && getFormControl(this.displayRuleEditorForm, 'labelType').value !== LabelTypeFormat.GraphicLabel)
                    || (keyPath === 'labelStyle.graphic.stretchY' && getFormControl(this.displayRuleEditorForm, 'labelType').value !== LabelTypeFormat.GraphicLabel)
                    || (keyPath === 'labelStyle.graphic.content' && getFormControl(this.displayRuleEditorForm, 'labelType').value !== LabelTypeFormat.GraphicLabel)
                    || keyPath === 'labelStyle.bearing' && getFormControl(this.displayRuleEditorForm, 'labelType').value !== LabelTypeFormat.FlatLabel) {
                    return false;
                }

                return (formControl.value !== initialFormControlState.value || formControl.disabled !== initialFormControlState.disabled);
            });

        return dirtyControls.length > 0;
    }

    /**
     * Confirmation box when are discarding changes for Location Details, Types and Main Display Rule.
     *
     * @returns {boolean}
     */
    private confirmDiscardChanges(): boolean {
        // eslint-disable-next-line no-alert
        return confirm('You will lose your changes if you continue without saving. Do you want to continue?');
    }


    /**
     * Compares the incloming and the original value of a formControl and sets the dirty state of the formControl accordingly.
     *
     * @param {string} formControlName
     * @param {any} value
     */
    private setDirtyState(formControlName: string, value: any): void {
        const formControl = getFormControl(this.displayRuleEditorForm, formControlName);
        const originalValue = this._displayRule[formControlName];

        originalValue === value
            ? formControl.markAsPristine()
            : formControl.markAsDirty();
        this.labelMaxWidthVisible = value === 0 ? false : true;
    }

    /**
     * Get updated values from dirty controls.
     *
     * @private
     * @returns {Object<string, any>} The updated values.
     * @memberof DisplayRuleDetailsComponent
     */
    private getUpdatedValues(): { [key: string]: any } {
        // The displayRule form value property doesn't include the values for disabled controls
        // therefor we need to loop through all key paths to find the controls marked as dirty and build our own object with updated display rule properties

        let updatedValues: { [key: string]: any } = {};
        this.displayRuleKeyPaths.forEach((keyPath) => {
            // As nested objects can be null when no child properties is set we loop all possible keyPaths
            const formControl = getFormControl(this.displayRuleEditorForm, keyPath);

            if (!formControl || !formControl.dirty) {
                return;
            }

            // Set value to null when form control is disabled to inherit from main displayRule
            const keyPathValue = formControl.disabled ? null :
                getKeypathValue(this.displayRuleEditorForm.value, keyPath);

            const newValueObject = createObjectFromKeypath(keyPath, keyPathValue);

            const updatedValuesClone = primitiveClone(updatedValues);
            updatedValues = mergeObjects(updatedValuesClone, newValueObject);

            // If backgroundImage is null, the whole graphic object should be null.
            if (updatedValues?.labelStyle?.graphic?.backgroundImage === null) {
                updatedValues.labelStyle.graphic = null;
                // If backgroundImage is defined, stretchX, stretchY and content values should be applied.
            } else if (!isNullOrUndefined(updatedValues?.labelStyle?.graphic?.backgroundImage)) {
                updatedValues.labelStyle.graphic.stretchX = [[15, 64]];
                updatedValues.labelStyle.graphic.stretchY = [[15, 16]];
                updatedValues.labelStyle.graphic.content = [10, 10, 69, 22];
            }
        });

        return updatedValues;
    }

    /**
     * Opens the Media Library and sets the new values for the formControls.
     *
     * @param {UntypedFormControl} urlFormControl
     * @param {MediaLibrarySource} source
     */
    private openMediaLibrary(urlFormControl: UntypedFormControl, source: MediaLibrarySource): void {
        const dialog = this.matDialog.open(MediaLibraryComponent, { ...this.mediaLibraryModalConfig, data: { url: urlFormControl.value, disableClose: true, source: source } });

        dialog.afterClosed().subscribe(
            mediaInfo => {
                if (!mediaInfo) return;

                let imageSize: ImageSize;

                // Check if it is a MapsIndoors icon
                if (mediaInfo.selectedMedia.category === MediaCategory.MIIcon) {
                    imageSize = this.calculateImageSize(SizeOption.Default);
                } else if (source === MediaLibrarySource.Icon) {
                    if (mediaInfo.selectedMedia.type.includes('svg')) {
                        imageSize = this.calculateImageSize(SizeOption.RealSize, mediaInfo.selectedMedia);
                    } else {
                        imageSize = this.calculateImageSize(SizeOption.CalculateSize, mediaInfo.selectedMedia);
                    }
                } else if (source === MediaLibrarySource.Model2D) {
                    this.model2DAspectRatio = this.calculateAspectRatio(mediaInfo.selectedMedia.width, mediaInfo.selectedMedia.height);
                    imageSize = this.calculateImageSize(SizeOption.RelativeSize, mediaInfo.selectedMedia);
                    this.original2DModelSize = imageSize;
                } else if (source === MediaLibrarySource.GraphicLabel) {
                    imageSize = this.calculateImageSize(SizeOption.RealSize, mediaInfo.selectedMedia);
                }

                this.updateFormControls(source, urlFormControl, mediaInfo.selectedMedia, imageSize);
            }
        );
    }

    /**
     * Calculates the correct image size for the selected media.
     * For MapsIndoors Icons, it is always default values.
     * For Uploads, that are SVGs, it uses their real size.
     * For Uploads, that are not SVGs, it uses the third of their sizes.
     *
     * @param {SizeOption} setTo
     * @param {ImageSize} size
     * @returns {ImageSize}
     */
    private calculateImageSize(setTo: SizeOption, size?: ImageSize): ImageSize {
        switch (setTo) {
            case SizeOption.Default:
                return {
                    width: this.locationService.defaultIconSize,
                    height: this.locationService.defaultIconSize
                };
            case SizeOption.RealSize:
                return {
                    width: +size.width,
                    height: +size.height
                };
            case SizeOption.CalculateSize:
                return {
                    width: Math.floor(+size.width / 3),
                    height: Math.floor(+size.height / 3)
                };
            case SizeOption.RelativeSize:
                return {
                    // Based on the Mapbox documentation, 0.014 is the multiplier for a zoom level of 22 in order to calculate the value in meters.
                    width: Math.round((size.width * 0.014) * 100) / 100,
                    height: Math.round((size.height * 0.014) * 100) / 100
                };
        }
    }

    /**
     * Sets the new values for the affected formControls, enables them and marks them dirty.
     *
     * @param {MediaLibrarySource} source
     * @param {UntypedFormControl} urlFormControl
     * @param {IMediaItem} selectedMedia
     * @param {ImageSize} imageSize
     */
    private updateFormControls(source: MediaLibrarySource, urlFormControl: UntypedFormControl, selectedMedia: IMediaItem, imageSize?: ImageSize): void {
        switch (source) {
            case MediaLibrarySource.Icon:
                selectedMedia.url === this._displayRule?.icon ? urlFormControl.markAsPristine() : urlFormControl.markAsDirty();
                urlFormControl.setValue(selectedMedia.url);

                this.setAndEnableFormControl('imageSize.width', imageSize.width);
                this.setAndEnableFormControl('imageSize.height', imageSize.height);
                this.setAndEnableFormControl('imageScale', 1);
                break;
            case MediaLibrarySource.Model2D:
                selectedMedia.url === this._displayRule?.model2D?.model ? urlFormControl.markAsPristine() : urlFormControl.markAsDirty();
                urlFormControl.setValue(selectedMedia.url);
                this.spinner.show('model2d-spinner');

                this.setAndEnableFormControl(`${source}.widthMeters`, imageSize.width);
                this.setAndEnableFormControl(`${source}.heightMeters`, imageSize.height);
                break;
            case MediaLibrarySource.Model3D:
                selectedMedia.url === this._displayRule?.model3D?.model ? urlFormControl.markAsPristine() : urlFormControl.markAsDirty();
                urlFormControl.setValue(selectedMedia.url);
                break;
            case MediaLibrarySource.GraphicLabel:
                selectedMedia.url === this._displayRule?.labelStyle?.graphic?.backgroundImage ? urlFormControl.markAsPristine() : urlFormControl.markAsDirty();
                urlFormControl.setValue(selectedMedia.url);
                this.setAndEnableFormControl('labelStyle.graphic.stretchX', selectedMedia?.stretchX);
                this.setAndEnableFormControl('labelStyle.graphic.stretchY', selectedMedia?.stretchY);
                this.setAndEnableFormControl('labelStyle.graphic.content', selectedMedia?.content);
                break;
        }
    }

    /**
     * Sets a new value, enables and marks the formControls as dirty.
     *
     * @param {string} formControlName
     * @param {string | number} value
     */
    private setAndEnableFormControl(formControlName: string, value: string | number | [number, number][] | [number, number, number, number]): void {
        const formControl = getFormControl(this.displayRuleEditorForm, formControlName);
        formControl.patchValue(value, { emitEvent: false });
        formControl.enable();
        formControl.markAsDirty();
    }

    /**
     * Remove and set 2D Model value to null, then make the form dirty to be able to Save/Discard the changes.
     */
    public removeModel2D(): void {
        const model2DFormControl = getFormControl(this.displayRuleEditorForm, 'model2D.model');
        model2DFormControl.setValue(null);
        model2DFormControl.markAsDirty();

        this.isDisplayRuleFormDirty = true;
    }

    /**
     * Remove and set 3D Model value to null, then make the form dirty to be able to Save/Discard the changes.
     */
    public removeModel3D(): void {
        const model3DFormControl = getFormControl(this.displayRuleEditorForm, 'model3D.model');
        model3DFormControl.setValue(null);
        model3DFormControl.markAsDirty();

        this.isDisplayRuleFormDirty = true;
    }

    /**
     * Remove and set Graphic Label value to null, then make the form dirty to be able to Save/Discard the changes.
     */
    public removeGraphicLabel(): void {
        const graphicLabelFormControl = getFormControl(this.displayRuleEditorForm, 'labelStyle.graphic.backgroundImage');
        graphicLabelFormControl.setValue(null);
        graphicLabelFormControl.markAsDirty();

        this.isDisplayRuleFormDirty = true;
    }

    /**
     * Unlock the display rule setting.
     *
     * @param {MouseEvent} event
     * @param {string} keyPath
     * @memberof DisplayRuleDetailsComponent
     */
    public unlockSetting(event: MouseEvent, keyPath: string): void {
        const formControl = getFormControl(this.displayRuleEditorForm, keyPath);

        if (!formControl || formControl.enabled) {
            return;
        }

        this.toggleInheritance(keyPath, event);
    }

    /**
     * Toggle main DisplayRule value inheritance for a single formControl.
     *
     * @param {string} keyPath
     * @param {MouseEvent} event
     * @memberof DisplayRuleDetailsComponent
     */
    public toggleInheritance(keyPath: string, event?: MouseEvent): void {
        event?.stopPropagation();
        const formControl = getFormControl(this.displayRuleEditorForm, keyPath);
        formControl.markAsDirty();

        if (keyPath === 'model3D.scale' && getFormControl(this.displayRuleEditorForm, 'model3D.widthMeters').value && getFormControl(this.displayRuleEditorForm, 'model3D.heightMeters').value) {
            if (!getFormControl(this.displayRuleEditorForm, 'model3D.model').value) {
                getFormControl(this.displayRuleEditorForm, 'model3D.widthMeters').patchValue('', { emitEvent: false });
                getFormControl(this.displayRuleEditorForm, 'model3D.heightMeters').patchValue('', { emitEvent: false });
            }
            if (formControl.disabled) {
                getFormControl(this.displayRuleEditorForm, 'model3D.widthMeters').enable({ emitEvent: false });
                getFormControl(this.displayRuleEditorForm, 'model3D.heightMeters').enable({ emitEvent: false });
            } else if (!formControl.disabled && getFormControl(this.displayRuleEditorForm, 'model3D.model').value) {
                getFormControl(this.displayRuleEditorForm, 'model3D.widthMeters').disable({ emitEvent: false });
                getFormControl(this.displayRuleEditorForm, 'model3D.heightMeters').disable({ emitEvent: false });
            }
        }

        if (formControl.disabled) {
            formControl.enable();
        } else {
            formControl.setValue(getKeypathValue(this._inheritedDisplayRule, keyPath));
            formControl.disable();
        }

        if (keyPath === 'model2D.model') {
            if (formControl.value !== getKeypathValue(this._inheritedDisplayRule, keyPath)) {
                this.spinner.show('model2d-spinner');
            }

            const widthFormControl = getFormControl(this.displayRuleEditorForm, 'model2D.widthMeters');
            const heightFormControl = getFormControl(this.displayRuleEditorForm, 'model2D.heightMeters');

            if (formControl.disabled) {
                const inheritedWidth = getKeypathValue(this._inheritedDisplayRule, 'model2D.widthMeters');
                const inheritedHeight = getKeypathValue(this._inheritedDisplayRule, 'model2D.heightMeters');

                this.model2DAspectRatio = this.calculateAspectRatio(inheritedWidth, inheritedHeight);

                widthFormControl.patchValue(inheritedWidth);
                widthFormControl.disable();

                heightFormControl.patchValue(inheritedHeight);
                heightFormControl.disable();
            } else if (formControl.enabled && formControl.value) {
                widthFormControl.enable({ emitEvent: false });
                heightFormControl.enable({ emitEvent: false });
            }
        }

        // When locking the type, we need to make sure that the right section is displayed at the bottom of Label Style.
        if (keyPath === 'labelType') {
            const newLabelType = getFormControl(this.displayRuleEditorForm, 'labelType');
            this.isGraphicLabelEnabled = newLabelType.value === LabelTypeFormat.GraphicLabel;
            this.isFloatingLabelEnabled = newLabelType.value === LabelTypeFormat.TextLabel;
        }


        //Reset image size and scale when icon is inherited.
        if (keyPath === 'icon' && formControl.disabled) {
            const widthFormControl = getFormControl(this.displayRuleEditorForm, 'imageSize.width');
            const heightFormControl = getFormControl(this.displayRuleEditorForm, 'imageSize.height');
            const scaleFormControl = getFormControl(this.displayRuleEditorForm, 'imageScale');

            widthFormControl.setValue(getKeypathValue(this._inheritedDisplayRule, 'imageSize.width'));
            heightFormControl.setValue(getKeypathValue(this._inheritedDisplayRule, 'imageSize.height'));
            scaleFormControl.setValue(getKeypathValue(this._inheritedDisplayRule, 'imageScale'));
        }
    }

    /**
     * Set value for label form control.
     *
     * @param {CustomEvent} detail
     * @memberof DisplayRuleDetailsComponent
     */
    public onLabelFormatDropdownChange({ detail }: CustomEvent): void {
        const labelFormControl = getFormControl(this.displayRuleEditorForm, 'label');
        labelFormControl.markAsDirty();
        labelFormControl.setValue(detail[0].value);
    }

    /**
     * Set value for label type form control.
     *
     * @param {CustomEvent} detail
     * @memberof DisplayRuleDetailsComponent
     */
    public onLabelTypeFormatDropdownChange({ detail }: CustomEvent): void {
        const labelFormControl = getFormControl(this.displayRuleEditorForm, 'labelType');
        labelFormControl.markAsDirty();
        labelFormControl.setValue(detail[0].value);

        this.isFloatingLabelEnabled = (detail[0].value === LabelTypeFormat.TextLabel || detail[0].value === LabelTypeFormat.GraphicLabel);
        this.isGraphicLabelEnabled = (detail[0].value === LabelTypeFormat.GraphicLabel);
    }

    /**
     * Toggle visibility of labelMaxWidth input.
     * Reset labelMaxWidth formControl when input is hidden.
     *
     * @memberof DisplayRuleDetailsComponent
     */
    public toggleLabelMaxWidthVisible(): void {
        this.labelMaxWidthVisible = !this.labelMaxWidthVisible;
        const labelMaxWidthFormControl = getFormControl(this.displayRuleEditorForm, 'labelMaxWidth');

        // Reset labelMaxWidth to 0 (infinity) when toggle is unchecked
        if (!this.labelMaxWidthVisible) {
            this.setDirtyState('labelMaxWidth', 0);
            labelMaxWidthFormControl.setValue(0);
        } else {
            this.setDirtyState('labelMaxWidth', 1);
            labelMaxWidthFormControl.setValue(1);
        }
    }

    /**
     * Toggle main DisplayRule value inheritance for labelMaxWidth formControl.
     * Hide labelMaxWidth input when disabled.
     *
     * @param {MouseEvent} event
     * @memberof DisplayRuleDetailsComponent
     */
    public toggleLabelMaxWidthInheritance(event: MouseEvent): void {
        const keyPath = 'labelMaxWidth';

        this.toggleInheritance(keyPath, event);

        const isLabelMaxWidthFormControlDisabled =
            getFormControl(this.displayRuleEditorForm, keyPath).disabled;
        if (isLabelMaxWidthFormControlDisabled) {
            this.labelMaxWidthVisible = false;
        }
    }

    /**
     * Changes the labelMaxWidth's formControl's value to user input.
     *
     * @param {any} event
     */
    public setlabelMaxWidthValue(event: any): void {
        this.setDirtyState('labelMaxWidth', parseInt(event.target.value));
        getFormControl(this.displayRuleEditorForm, 'labelMaxWidth').setValue(
            parseInt(event.target.value)
        );
    }

    /**
     * Discarding the form and setting values to initial state else staying at the current URL.
     *
     * @returns {boolean}
     * @memberof DisplayRuleDetailsEditorComponent
     */
    public onDiscardChanges(): boolean {
        if (!this.isFormDirty() || this.confirmDiscardChanges()) {
            this.discardChanges();
            return true;
        } else {
            stayAtCurrentUrl(this.router);
            return false;
        }
    }

    /**
     * When discard is triggered for Locations and Types we close the modal. For Main Display Rule we are reverting changes.
     */
    public discardChanges(): void {
        this._geometries = this._initialGeometries;
        this.setFormValues(this.initialDisplayRule);
        this.displayRuleEditorForm.markAsPristine();
        this.initialFormControlStates = this.getFormControlsInitialState();
        this.formDiscard.emit();
    }

    /**
     * Submit display rule changes.
     *
     * @memberof DisplayRuleDetailsComponent
     */
    public onSubmit(): void {
        if (this.displayRuleEditorForm.invalid) {
            return;
        }

        const updatedValues = this.getUpdatedValues();
        let updatedDisplayRule = mergeObjects(
            this.initialDisplayRule ?? {},
            updatedValues
        ) as DisplayRule;
        updatedDisplayRule = nullifyParentProps(updatedDisplayRule);

        this.formSubmit.emit(updatedDisplayRule);

        this.displayRuleEditorForm.markAsPristine();
        this.isDisplayRuleFormDirty = this.displayRuleEditorForm.dirty;
        this.initialFormControlStates = this.getFormControlsInitialState();
        this.initialDisplayRule = updatedDisplayRule;
    }

    /**
     * Open Media Library for Icons.
     */
    public openMediaLibraryForIcon(): void {
        const iconFormControl = this.displayRuleEditorForm.get('icon');
        this.openMediaLibrary(iconFormControl as UntypedFormControl, MediaLibrarySource.Icon);
    }

    /**
     * Open Media Library for 2D Model.
     *
     * @memberof DisplayRuleDetailsComponent
     */
    public openMediaLibraryFor2DModel(): void {
        const model2dFromControl = (this.displayRuleEditorForm.controls['model2D'] as FormGroup).controls['model'];
        this.openMediaLibrary(model2dFromControl as UntypedFormControl, MediaLibrarySource.Model2D);
    }

    /**
     * Open Media Library for 3D Model.
     *
     * @memberof DisplayRuleDetailsComponent
     */
    public openMediaLibraryFor3DModel(): void {
        const model3dFromControl = (this.displayRuleEditorForm.controls['model3D'] as FormGroup).controls['model'];
        this.openMediaLibrary(model3dFromControl as UntypedFormControl, MediaLibrarySource.Model3D);
    }

    /**
     * Open Media Library for Graphic Label.
     *
     * @memberof DisplayRuleDetailsComponent
     */
    public openMediaLibraryForGraphicLabel(): void {
        const backgroundImageControl = getFormControl(this.displayRuleEditorForm, 'labelStyle.graphic.backgroundImage');
        this.openMediaLibrary(backgroundImageControl as UntypedFormControl, MediaLibrarySource.GraphicLabel);
    }

    /**
     * Resetting the 2D model's width and height controls to the original image size.
     */
    public reset2DModelOriginalSize(): void {
        getFormControl(this.displayRuleEditorForm, 'model2D.widthMeters').patchValue(this.original2DModelSize.width);
        getFormControl(this.displayRuleEditorForm, 'model2D.heightMeters').patchValue(this.original2DModelSize.height);
    }

    /**
     * Fits the 2D model inside the locations polygon, keeping the anchor point's position.
     *
     * @public
     */
    public fitModel2D(): void {
        this.displayRuleService.fitToPolygon(this.original2DModelSize as ImageSize, this._geometries[0] as GeoJSON.Polygon, this._geometries[1])
            .then(({ width, height, bearing }) => {
                this.setAndEnableFormControl('model2D.widthMeters', width);
                this.setAndEnableFormControl('model2D.heightMeters', height);
                this.setAndEnableFormControl('model2D.bearing', bearing);
            });
    }

    /**
     * Blocks user to type '-' (dash).
     *
     * @param {KeyboardEvent} event
     */
    public onKeyDown(event: KeyboardEvent): void {
        if (event.key === '-') {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    /**
     * Evaluates if the form controls are enabled.
     *
     * @param {string[]} displayRuleProperties
     * @returns {boolean}
     */
    public areFormControlsEnabled(displayRuleProperties: string[]): boolean {
        return displayRuleProperties.some(displayRuleProperty => this.getFormControl(this.displayRuleEditorForm, displayRuleProperty)?.enabled);
    }

    /**
     * Saves the specified display rule part to the type. 
     *
     * @param {string[]} displayRuleProperties
     */
    public onSaveToType(displayRuleProperties: string[]): void {
        const relevantDisplayRuleProperties = displayRuleProperties.filter(displayRuleProperty => this.getFormControl(this.displayRuleEditorForm, displayRuleProperty).enabled);
        const locationTypeDisplayRuleToPush = this.createLocationTypeDisplayRuleToPush(relevantDisplayRuleProperties);

        if (!confirm(`You are about to save ${relevantDisplayRuleProperties.length} display rule ${relevantDisplayRuleProperties.length > 1 ? 'settings' : 'setting'} on the ${this.location?.typeOfLocation?.displayName} location type. Are you sure you want to save the changes?`)) {
            return;
        }

        // Success callback function for the location and type updates.
        // Updates the inherited display rule and disables the form controls that were synced up with the location type.
        // Can be called when listening to the pushDisplayRuleToType event.
        const onSuccess = async () => {
            this._inheritedDisplayRule = await this.displayRuleService.getDisplayRule(this.location.type);
            this._displayRule = this.displayRuleService.getDisplayRuleForLocation(this.location.id);
            this.initialDisplayRule = primitiveClone(this._displayRule);
            relevantDisplayRuleProperties.forEach(displayRuleProperty => {
                const formControl = this.getFormControl(this.displayRuleEditorForm, displayRuleProperty);
                const initialFormControlState = this.initialFormControlStates.find(state => state.keyPath === displayRuleProperty);

                initialFormControlState.value = formControl.value;
                initialFormControlState.disabled = true;

                formControl.markAsPristine();
                formControl.disable();
            });
        };

        // Failed callback function for the location and type updates
        // Because the location type is save before the location, the inherited display rule needs to be updated, because it is dependent on the location type.
        // Can be called when listening to the pushDisplayRuleToType event.
        const onFailure = async () => {
            this._inheritedDisplayRule = await this.displayRuleService.getDisplayRule(this.location.type);
        };

        this.pushDisplayRuleToType.emit({ displayRuleProperties: relevantDisplayRuleProperties, displayRule: locationTypeDisplayRuleToPush, onSuccess, onFailure });
    }

    /**
     * Creates a display rule based on the display rule setting names provided.
     *
     * @param {string[]} displayRuleProperties
     * @returns {DisplayRule}
     */
    private createLocationTypeDisplayRuleToPush(displayRuleProperties: string[]): DisplayRule {
        const locationTypeDisplayRuleToSave: DisplayRule = {};

        displayRuleProperties.forEach(displayRuleProperty => {
            const formControl = this.getFormControl(this.displayRuleEditorForm, displayRuleProperty);
            setNestedProperty(locationTypeDisplayRuleToSave, displayRuleProperty, formControl.value);
        });

        return locationTypeDisplayRuleToSave;
    }
}