import { RouterModule, Routes } from '@angular/router';

import { AdminGuard } from './guards/admin-guard.service';
import { AuthGuard } from './auth/auth.guard';
import { CategoriesComponent } from './categories/categories.component';
import { ConfigComponent } from './app-settings/config/config.component';
import { CustomersComponent } from './customers/customers.component';
import { DataIssueComponent } from './data-issue/data-issue.component';
import { DataIssueResolver } from './data-issue/data-issue-resolver.service';
import { DeploymentConfigComponent } from './deployment-config/deployment-config.component';
import { DeploymentVenueComponent } from './deployment-venue/deployment-venue.component';
import { ExportComponent } from './export.component';
import { FallbackComponent } from './fallback/fallback.component';
import { ImportExportComponent } from './import-export/import-export.component';
import { LocationTemplatesComponent } from './location-types/location-templates/location-templates.component';
import { LogComponent } from './log.component';
import { MapComponent } from './map/map.component';
import { NewVenueComponent } from './new-venue/new-venue.component';
import { NgModule } from '@angular/core';
import { OwnerGuard } from './guards/owner-guard.service';
import { UsersComponent } from './users.component';
import { MapContainerComponent } from './adapter/map-container/map-container.component';
import { OccupantTemplatesModule } from './occupant-templates/occupant-templates.module';

const routes: Routes = [
    { path: '', redirectTo: '/map', pathMatch: 'full' },
    { path: 'old-map', component: MapComponent, canActivate: [AuthGuard, OwnerGuard] },
    { path: 'map', component: MapContainerComponent, canActivate: [AuthGuard] },
    { path: 'mapbox', redirectTo: '/map' },
    { path: 'google', redirectTo: '/map' },
    {
        path: '',
        canActivateChild: [AuthGuard, AdminGuard],
        children: [
            {
                path: 'app-settings',
                canActivateChild: [AuthGuard, AdminGuard],
                children: [
                    { path: '', redirectTo: 'app-config', pathMatch: 'full' },
                    { path: 'app-config', component: ConfigComponent },
                    { path: 'api-keys', loadChildren: () => import('./api-keys/api-keys.module').then(m => m.ApiKeysModule) },
                    { path: 'booking', loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule) },
                    { path: 'position', loadChildren: () => import('./position/position.module').then(m => m.PositionModule) },
                    { path: 'webex', loadChildren: () => import('./webex/webex.module').then(m => m.WebexModule) },
                ]
            },
            { path: 'types', loadChildren: () => import('./location-types/location-types.module').then(m => m.LocationTypesModule) },
            { path: 'venues', loadChildren: () => import('./venues/venues.module').then(module => module.VenuesModule) },
            { path: 'occupant-templates', loadChildren: () => OccupantTemplatesModule },
            { path: 'location-templates', component: LocationTemplatesComponent },
            { path: 'categories', component: CategoriesComponent },
            { path: 'users', component: UsersComponent },
            { path: 'logs', component: LogComponent },
            { path: 'solution-settings', redirectTo: '/solution-settings/main-display-rule', pathMatch: 'full' },
            { path: 'solution-settings', canActivate: [AuthGuard, AdminGuard || OwnerGuard], loadChildren: () => import('./solution-settings/solution-settings-module').then(m => m.SolutionSettingModule) }
        ]
    },
    {
        path: 'deployment', canActivateChild: [AuthGuard, OwnerGuard],
        children: [
            { path: 'venue', component: DeploymentVenueComponent },
            { path: 'config', component: DeploymentConfigComponent },
            { path: 'customers', component: CustomersComponent },
            { path: 'export', component: ExportComponent },
            { path: 'import-export', component: ImportExportComponent },
            { path: 'new-venue', component: NewVenueComponent },
            { path: 'solutions', loadChildren: () => import('./solutions/solutions.module').then(m => m.SolutionsModule) },
            { path: 'data-issue/:solutionId', component: DataIssueComponent, resolve: { resolver: DataIssueResolver } }
        ]
    },
    {
        path: 'settings', loadChildren: () => import('./settings/settings.module')
            .then(module => module.SettingsModule), canLoad: [AuthGuard]
    },
    { path: 'page-not-found', component: FallbackComponent },
    { path: '**', redirectTo: '/page-not-found' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            enableTracing: false
        })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
