import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { take, exhaustMap, tap, catchError } from 'rxjs/operators';
import { forkJoin, throwError, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { authModuleConfig } from '../auth/auth-module-config';

@Injectable()
export class ApiProvider {
    private firstResponder: string = null;
    private readonly NO_CONNECTION = 'NO_CONNECTION';
    private readonly ERROR_MESSAGE = 'Unable to reach the servers! Check that you are not offline and try again.';

    constructor(private http: HttpClient, public snackBar: MatSnackBar) {}

    /**
     * Determine which endpoint is fastest.
     * Set the environment variable.
     *
     * @returns {Observable<any>}
     * @memberof ApiProvider
     */
    public checkFastestApi(): Promise<any> {
        const headers = new HttpHeaders({ 'Skip-Authorization-Header': 'no-auth' });
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.APIEndpoint}api`, { headers })
                .pipe(
                    take(1),
                    exhaustMap((res: any) => {
                        if (res && res.backendUrls && res.backendUrls.length > 0) {
                            return forkJoin(this.pingApis(res.backendUrls));
                        }
                        return throwError(this.ERROR_MESSAGE);
                    }),
                    catchError(() => throwError(this.ERROR_MESSAGE))
                ).subscribe((res: any[]) => {
                    if (this.firstResponder) {
                        environment.APIEndpoint = this.firstResponder;
                        if (!authModuleConfig.resourceServer.allowedUrls.includes(this.firstResponder)) {
                            authModuleConfig.resourceServer.allowedUrls.push(this.firstResponder);
                        }

                        resolve(true);
                    }
                    // reject if all pings fail.
                    if (res.every(value => value === this.NO_CONNECTION)) {
                        this.snackBar.open(this.ERROR_MESSAGE, null, {
                            duration: 4000,
                            panelClass: 'snack-bar-warning'
                        });
                        reject(false);
                    }
                }, error => {
                    this.snackBar.open(error, null, {
                        duration: 4000,
                        panelClass: 'snack-bar-warning'
                    });
                    reject(false);
                });
        });
    }

    /**
     * Make a request to the given urls.
     * Save the first one that responds.
     *
     * @private
     * @param {string[]} apiUrls
     * @returns {Observable<any>[]}
     * @memberof ApiProvider
     */
    private pingApis(apiUrls: string[]): Observable<any>[] {
        const headers = new HttpHeaders({ 'Skip-Authorization-Header': 'no-auth' });
        return apiUrls.map(url => {
            return this.http.get(url, { headers })
                .pipe(
                    tap(() => this.firstResponder = this.firstResponder ? this.firstResponder : `${url}/`),
                    catchError(() => of(this.NO_CONNECTION))
                );
        });
    }
}
