import { Component, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DrawingMode, GeodataEditor, GeodataEditorFactory } from '../../../../../GeodataEditor/GeodataEditor.factory';
import { BaseMapAdapter } from '../../../../../MapAdapter/BaseMapAdapter';
import { MapAdapterMediator } from '../../../../map-adapter.mediator';
import { DisplayRuleService } from '../../../../../services/DisplayRuleService/DisplayRuleService';
import { LocationService } from '../../../../../locations/location.service';
import { VenueService } from '../../../../../venues/venue.service';
import { Venue } from '../../../../../venues/venue.model';
import { NetworkService } from '../../../../../network-access/network.service';
import { ImportService } from '../../../../../services/import.service';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../../../services/notification.service';
import { GraphBoundsEditorOperation } from '../../../../../GeodataEditor/GeodataEditorOperation/GraphBoundsEditorOperation';
import { GraphData } from '../../../../../map/graph-data.model';
import { Geometry } from '../../../../../locations/location.model';

@Component({
    selector: 'edit-graph-bounds',
    templateUrl: './edit-graph-bounds.component.html',
    styleUrls: ['./edit-graph-bounds.component.scss']
})
export class EditGraphBoundsComponent implements OnInit, OnDestroy {
    private _destroySubject: Subject<void> = new Subject();
    private _geoDataEditor: GeodataEditor;
    private _mapAdapter: BaseMapAdapter;
    private _subscriptions: Subscription = new Subscription();
    private _editorOperation: GraphBoundsEditorOperation;
    private _currentGraph: GraphData;

    public isSaveButtonDisabled: boolean = false;

    @Output() public readonly destroy = this._destroySubject.asObservable();

    constructor(
        private displayRuleService: DisplayRuleService,
        private mapAdapterMediator: MapAdapterMediator,
        private locationService: LocationService,
        private venueService: VenueService,
        private networkService: NetworkService,
        private importService: ImportService,
        private spinner: NgxSpinnerService,
        private notificationService: NotificationService
    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        this._mapAdapter = this.mapAdapterMediator?.getMapAdapter();
        this._geoDataEditor = GeodataEditorFactory.create(this._mapAdapter, this.displayRuleService, this.locationService);
        this._mapAdapter.isHoverable = false;
        this._mapAdapter.isClickable = false;

        let currentVenue: Venue;

        this.venueService.selectedVenue$.subscribe(venue => {
            currentVenue = venue;
        });

        const currentGraph = this.networkService.getGraphByVenueId(currentVenue)
            .subscribe(graph => {
                this._currentGraph = graph;

                this.editGraphBounds(this._currentGraph);
            });

        this._subscriptions
            .add(currentGraph);
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this._mapAdapter.isHoverable = true;
        this._subscriptions.unsubscribe();
        this._destroySubject.complete();
        this._editorOperation?.complete();
    }

    /**
     * Resets the cursor to defualt, stops the event-listener on the map and hides the current toolbar.
     */
    public onCancel(): void {
        // eslint-disable-next-line no-alert
        if (confirm('Canceling without saving will discard any changes you have made.')) {
            this._mapAdapter.isClickable = true;
            this._destroySubject.next();
        }
    }

    /**
     * Create a new graph bounds outline.
     */
    public createNetworkGraphOutline(): void {
        this.isSaveButtonDisabled = true;
        this._editorOperation?.complete();

        this.drawGraphOutline();
    }

    /**
     * Draw Graph outline. Finalize with showing Polygon's handles.
     */
    private drawGraphOutline(): void {
        this._subscriptions.unsubscribe();

        this._subscriptions = this._geoDataEditor.drawArea(DrawingMode.FreeHand)
            .pipe(finalize(() => this._geoDataEditor.showPolygonHandles(true)))
            .subscribe((polygon) => {
                this._currentGraph.graphArea = polygon as Geometry;

                this.editGraphBounds(this._currentGraph);

                this.isSaveButtonDisabled = false;
            });
    }

    /**
     * Edit Graph Bounds function.
     *
     * @param {GraphData} graphData
     */
    private editGraphBounds(graphData: GraphData): void {
        this._editorOperation = this._geoDataEditor.editGraphBounds(graphData);

        this._editorOperation.changes.subscribe((changes) => {
            graphData.graphArea = changes.geometry as Geometry;
        });
    }

    /**
     * Saves a graph bounds outline.
     */
    public saveNetworkGraphOutline(): void {
        this.spinner.show();
        this.importService.saveSolutionGraph(this._currentGraph)
            .pipe(
                finalize(() => {
                    this.spinner.hide();
                    this._destroySubject.next();
                    this._mapAdapter.isClickable = true;
                    this._editorOperation?.complete();
                })
            ).subscribe({
                complete: () => {
                    this.notificationService.showSuccess('Success.');
                },
                error: () => {
                    this.notificationService.showError('Error');
                    this.spinner.hide();
                }
            });
    }

    /**
     * Cancels the edit graph bounds operation.
     *
     * @param {KeyboardEvent} event
     */
    @HostListener('document:keydown.escape', ['$event'])
    private onEscapeHandler(event: KeyboardEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.onCancel();
    }
}