import { Injectable } from '@angular/core';
import { Occupant } from './Occupant';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SolutionService } from '../solution.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../notification.service';
import { VenueService } from '../../venues/venue.service';
import { LocationService } from '../../locations/location.service';

const API_ENDPOINT = environment.APIEndpoint;

export interface CorrelationId {
    correlationId: string;
    locationIds: string;
    floorIds: Array<string | number>;
    buildingId: string;
    venueId: string;
}

@Injectable({ providedIn: 'root' })
export class OccupantService {
    private _currentSolutionId: string;
    private _currentVenueId: string;
    private _occupantsMap: Map<string, Occupant> = new Map();
    private _occupantsSubject = new ReplaySubject<Occupant[]>(1);
    private _correlationIdListSubject = new ReplaySubject<CorrelationId[]>(null);

    constructor(
        private solutionService: SolutionService,
        private http: HttpClient,
        private notificationService: NotificationService,
        private venueService: VenueService,
        private locationService: LocationService
    ) {
        this.solutionService.getCurrentSolution().pipe(
            tap(solution => this._currentSolutionId = solution.id),
            switchMap(() => this.venueService.selectedVenue$),
            tap(venue => this._currentVenueId = venue.id),
            switchMap(venue => this.fetchOccupants({ venueId: venue.id })),
            tap((occupants: Occupant[]) => {
                this._occupantsMap = new Map(occupants.map(occupant => ([occupant?.locationId, occupant])));
                this._occupantsSubject.next(occupants);
            })
        ).subscribe();

        this.locationService.locations$
            .pipe(
                switchMap(() => this.occupants$),
                map(occupants => occupants
                    .filter(occupant => occupant.correlationId)
                    // For every occurrence of a correlation id, we want to collect the location ids and floor ids that are associated with it.
                    .reduce((accumulator, occupant) => {
                        const location = this.locationService.getLocation(occupant.locationId);
                        const existingOccupant = accumulator.find(existingOccupant => existingOccupant.correlationId === occupant.correlationId);
                
                        if (existingOccupant) {
                            existingOccupant.floorIds.push(location?.pathData?.floor);
                            existingOccupant.locationIds.push(existingOccupant.locationId);
                        } else {
                            accumulator.push({
                                correlationId: occupant.correlationId,
                                locationIds: [occupant.locationId],
                                floorIds: [location?.pathData?.floor],
                                buildingId: location?.building,
                                venueId: occupant.venueId
                            });
                        }
                
                        return accumulator;
                    }, [])),
                tap(correlationIdList => this._correlationIdListSubject.next(correlationIdList))
            )
            .subscribe();
    }

    /**
     * Get occupants as an observable.
     *
     * @returns {Observable<Occupant[]>}
     */
    get occupants$(): Observable<Occupant[]> {
        return this._occupantsSubject.asObservable();
    }

    /**
     * Get the list of correlation ids.
     *
     * @returns {Observable<CorrelationId[]>}
     */
    get correlationIdList$(): Observable<CorrelationId[]> {
        return this._correlationIdListSubject.asObservable();
    }

    /**
     * Fetch occupants.
     *
     * @param {object} params
     * @param {string} params.venueId
     * @returns {Observable<Occupant[]>}
     */
    private fetchOccupants(params: { venueId: string }): Observable<Occupant[]> {
        return this.http.get<Occupant[]>(`${API_ENDPOINT}${this._currentSolutionId}/api/occupant?venueId=${params.venueId}`);
    }

    /**
     * Get occupant by id.
     *
     * @param {string} id
     * @returns {Observable<Occupant>}
     */
    private getOccupantById(id: string): Observable<Occupant> {
        return this.http.get<Occupant>(`${API_ENDPOINT}${this._currentSolutionId}/api/occupant/details/${id}?venueId=${this._currentVenueId}`).pipe(
            catchError((err: Error) => {
                this.notificationService.showError(err);
                return of(null);
            })
        );
    }

    /**
     * Get occupant by location id.
     *
     * @param {string} locationId
     * @returns {Occupant}
     */
    public getOccupantByLocationId(locationId: string): Occupant {
        return this._occupantsMap?.get(locationId) ?? null;
    }
    /**
     * Create occupant.
     *
     * @param {Occupant} newOccupant
     * @returns {Observable<Occupant>}
     */
    public createOccupant(newOccupant: Occupant): Observable<Occupant> {
        return this.http.post<string>(`${API_ENDPOINT}${this._currentSolutionId}/api/occupant/?venueId=${this._currentVenueId}`, [newOccupant])
            .pipe(
                switchMap((id: string) => this.getOccupantById(id)),
                map((occupant: Occupant) => {
                    this._occupantsMap.set(occupant.locationId, occupant);
                    this._occupantsSubject.next(Array.from(this._occupantsMap.values()));
                }),
                catchError((err: Error) => {
                    this.notificationService.showError(err);
                    return of(null);
                })
            );
    }

    /**
     * Update occupant.
     *
     * @param {Occupant} updatedOccupant
     * @returns {Observable<Occupant>}
     */
    public updateOccupant(updatedOccupant: Occupant): Observable<Occupant> {
        return this.http.put<Occupant>(`${API_ENDPOINT}${this._currentSolutionId}/api/occupant/?venueId=${this._currentVenueId}`, updatedOccupant)
            .pipe(
                map(() => {
                    if (this._occupantsMap.has(updatedOccupant.locationId)) {
                        this._occupantsMap.set(updatedOccupant.locationId, updatedOccupant);
                    }
                    this._occupantsSubject.next(Array.from(this._occupantsMap.values()));
                }),
                catchError((err: Error) => {
                    this.notificationService.showError(err);
                    return of(null);
                })
            );
    }

    /**
     * Delete occupant.
     *
     * @param {Occupant} occupant
     * @returns {Observable<void>}
     */
    public deleteOccupant(occupant: Occupant): Observable<void> {
        return this.http.delete<void>(`${API_ENDPOINT}${this._currentSolutionId}/api/occupant/${occupant.id}?venueId=${this._currentVenueId}`)
            .pipe(
                tap(() => {
                    this._occupantsMap.delete(occupant.locationId);
                    this._occupantsSubject.next(Array.from(this._occupantsMap.values()));
                })
            );
    }
}