import { RouteContext } from './Graph';
import { Node } from './GraphNode';

export class EdgeProperties {
    context: RouteContext;
    distance: number;
    highway: string;
    [key: string]: unknown;

    /**
     * Creates an instance of EdgeProperties.
     *
     * @param {any} edgeData - The edge data.
     * @param {any} edgeMapping - The edge mapping.
     * @returns {EdgeProperties} The created EdgeProperties instance.
     */
    static create(edgeData: any, edgeMapping: any): EdgeProperties {
        const properties = new EdgeProperties();
        return edgeData.reduce((properties: EdgeProperties, value, index) => {
            const property = edgeMapping.properties[index];
            properties[property] = (edgeMapping.values[index]?.length > 0 ? edgeMapping.values[index][value] : value) ?? 0;
            return properties;
        }, properties);
    }

    /**
     * Serializes the edge properties to an array of numbers.
     *
     * @param {EdgeProperties} properties - The edge properties to serialize.
     * @param {any} edgeMapping - The edge mapping.
     * @returns {number[]} The serialized edge properties.
     */
    static serialize(properties: EdgeProperties, edgeMapping: any): any {
        return edgeMapping.properties.map((property, index) => {
            if (edgeMapping.values[index].length === 0) {
                return properties[property] ?? 0;
            }
            return edgeMapping.values[index].indexOf(properties[property]);
        });
    }
}

export class Edge {
    readonly id: number;
    from: Node;
    to: Node;
    properties: EdgeProperties;

    constructor(id: number, from: Node, to: Node, properties: EdgeProperties) {
        this.id = id;
        this.from = from;
        this.to = to;
        this.properties = properties;
    }

    /**
     * Converts the graph edge to a GeoJSON LineString feature.
     *
     * @returns {GeoJSON.Feature<GeoJSON.LineString>}
     */
    toLineString(): GeoJSON.Feature<GeoJSON.LineString> {
        return {
            id: this.id,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [this.from.geometry.coordinates, this.to.geometry.coordinates]
            },
            properties: this.properties
        };
    }

    /**
     * Creates a new edge in the graph.
     *
     * @param {number} id - The id of the edge.
     * @param {any} edgeData - The edge data.
     * @param {any} edgeMapping - The edge mapping.
     * @param {Map<number, Node>} nodes - The nodes in the graph.
     * @returns {Edge} The created edge.
     */
    static create(id: number, edgeData: any, edgeMapping: any, nodes: Map<number, Node>): Edge {
        const from = nodes.get(edgeData[0][0]);
        const to = nodes.get(edgeData[0][1]);
        const properties = EdgeProperties.create(edgeData[1], edgeMapping);

        return new Edge(id, from, to, properties);
    }
}