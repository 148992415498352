import { Component, OnInit, OnDestroy, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, FormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { AppUserRole } from '../../../app-settings/config/app-user-roles/app-user-role.model';
import { AppUserRolesService } from '../../../app-settings/config/app-user-roles/app-user-roles.service';
import { Accessibility } from '../../enums';
import { primitiveClone } from '../../object-helper';
import { TypesService } from '../../../services/types.service';
import { ExtendedLocation, LocationService } from '../../../locations/location.service';
import { LocationType } from '../../../location-types/location-type.model';
import isEqual from 'fast-deep-equal';
import { catchError, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../services/notification.service';

@Component({
    selector: 'restrictions-input',
    templateUrl: './restrictions-input.component.html',
    styleUrls: ['./restrictions-input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RestrictionsInputComponent),
        multi: true,
    }]
})
export class RestrictionsInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() public locationType: LocationType;
    @Input() public location: ExtendedLocation;

    private _restrictions: string[] = [];
    private _appUserRoles: AppUserRole[] = [];
    private _subscriptions = new Subscription();
    private _originalRestriction;
    private _restrictionsFormControl = new UntypedFormControl([]);

    public accessibilityTypes = Accessibility;
    public restrictionsForm = this.formBuilder.group({
        accessibilityType: [0, [Validators.required]],
        restrictions: this.formBuilder.array([])
    });

    public onChange: (restrictions: string[]) => void = () => { };
    public onTouch: () => void = () => { };

    constructor(
        private formBuilder: FormBuilder,
        private appUserRolesService: AppUserRolesService,
        private typeService: TypesService,
        private locationService: LocationService,
        private spinner: NgxSpinnerService,
        private notificationService: NotificationService
    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        this._restrictionsFormControl
            .setValue(this.getRestrictions(this.accessibilityTypeFormControl.value));

        this.appUserRolesService.appUserRoles$
            .subscribe(roles => {
                this._appUserRoles = roles;
                this.setRestrictionsFormArray(roles);
            });

        this._subscriptions
            // Subscribing to formControl's valueChanges - in case of Discarding the parent form (then the change is coming from outside)
            .add(this._restrictionsFormControl.valueChanges
                .subscribe(() => {
                    // Needs to be stringified because the value is an array (the order of items are always the same)
                    // It is executed when the user Discards changes on the parent form
                    if (JSON.stringify(this._restrictionsFormControl.value) !== JSON.stringify(this.getRestrictions(this.accessibilityTypeFormControl.value))) {
                        this.restrictionsForm.setValue(this._originalRestriction);
                    }
                }));
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    /**
     * Returns the list of appUserRoles.
     *
     * @readonly
     * @type {AppUserRole[]}
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    get appUserRoles(): AppUserRole[] {
        return this._appUserRoles;
    }

    /**
     * Get restrictions form array.
     *
     * @readonly
     * @type {UntypedFormArray}
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    get restrictionsFormArray(): UntypedFormArray {
        return this.restrictionsForm.get('restrictions') as UntypedFormArray;
    }

    /**
     * Get accessibilityType form control.
     *
     * @readonly
     * @type {UntypedFormControl}
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    get accessibilityTypeFormControl(): UntypedFormControl {
        return this.restrictionsForm.controls['accessibilityType'] as UntypedFormControl;
    }

    /**
     * Set the restrictions.
     *
     * @private
     * @param {string[]} restrictions
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    private setRestrictions(restrictions: string[]): void {
        this._restrictions = restrictions;
        const accessibilityType = this.getAccessibilityType(restrictions);
        this.accessibilityTypeFormControl.setValue(accessibilityType);
        this.setRestrictionsFormArray(this._appUserRoles);
    }

    /**
     * Get accessibility type.
     *
     * @private
     * @param {string[]} restrictions
     * @returns {Accessibility}
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    private getAccessibilityType(restrictions: string[]): Accessibility {
        if (!restrictions) {
            return Accessibility.OpenForAll;
        } else if (restrictions.includes('locked')) {
            return Accessibility.Closed;
        } else if (restrictions.length > 0) {
            return Accessibility.OpenForSome;
        }

        return Accessibility.OpenForAll;
    }

    /**
     * Set App User Roles as form controls in restrictions form array.
     *
     * @private
     * @param {AppUserRole[]} appUserRoles
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    private setRestrictionsFormArray(appUserRoles: AppUserRole[]): void {
        this.restrictionsFormArray.clear();

        // Create restriction form controls
        appUserRoles.forEach((appUserRole) => {
            const checked = this._restrictions?.includes(appUserRole.id);
            this.restrictionsFormArray.push(this.formBuilder.control(checked));
        });

        // Fallback to accessibility type of "open for all" if no roles match with the specified restrictions
        if (this.accessibilityTypeFormControl.value === Accessibility.OpenForSome && !this.restrictionsFormArray.value.includes(true)) {
            // Original values for Locations and Location Types
            const previousLocationValue = { ...this.location };
            const previousLocationTypeValue = { ...this.locationType };

            this.accessibilityTypeFormControl.setValue(Accessibility.OpenForAll);
            this.spinner.show();

            // If User Role does not exist anymore for the Location, set it to open (open for all)
            if (this.location) {
                this.location.restrictions = [];
                if (!isEqual(previousLocationValue, this.location)) {
                    this.locationService.updateLocation(this.location)
                        .pipe(finalize(() => this.spinner.hide()))
                        .subscribe(),
                    catchError((error) => {
                        this.spinner.hide();
                        this.notificationService.showError(error);
                        return of(error);
                    });
                }
            }

            // If User Role does not exist anymore for the Location Type, set it to open (open for all)
            if (this.locationType) {
                this.locationType.restrictions = [];
                if (!isEqual(previousLocationTypeValue, this.locationType)) {
                    this.typeService.updateTypes([this.locationType])
                        .pipe(finalize(() => this.spinner.hide()))
                        .subscribe(),
                    catchError((error) => {
                        this.spinner.hide();
                        this.notificationService.showError(error);
                        return of(error);
                    });
                }
            }
        }
    }

    /**
     * On change handler that sets the value of the restrictions formcontrol.
     *
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    public onRestrictionsChange(): void {
        this._restrictionsFormControl.setValue(this.getRestrictions(this.accessibilityTypeFormControl.value));
        this.onChange(this.getRestrictions(this.accessibilityTypeFormControl.value));
    }

    /**
     * Returns a string/null depending on what users are provided.
     *
     * @param {Accessibility} accessibility
     * @returns {any}
     */
    private getRestrictions(accessibility: Accessibility): any {
        switch (accessibility) {
            case Accessibility.OpenForAll:
                return [];
            case Accessibility.OpenForSome:
                return this.restrictionsForm.value.restrictions
                    .map((checked, i) => checked ? this._appUserRoles[i].id : null)
                    .filter(appUserRoleId => appUserRoleId !== null);
            case Accessibility.Closed:
                return ['locked'];
            default:
                break;
        }
    }

    /**
     * Writes a new value to the element.
     *
     * @param {string[]} restrictions
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    writeValue(restrictions: string[]): void {
        if (!this.restrictionsForm.controls['accessibilityType']) {
            this._restrictionsFormControl.setValue(restrictions, { onlySelf: true });
        }

        this.setRestrictions(restrictions);
        this._originalRestriction = Object.freeze(primitiveClone(this.restrictionsForm.value));
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     *
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    registerOnChange(fn: (restrictions: string[]) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     *
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * Called by the forms API when the control status changes to or from 'DISABLED'.
     *
     * @param {boolean} isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.restrictionsForm.disable() : this.restrictionsForm.enable();
    }
}