import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { take } from 'rxjs/operators';

import { MenuNode } from '../services/menu.service';
import { SolutionService } from '../services/solution.service';
import { Solution } from '../solutions/solution.model';
import { AuthService } from '../auth/auth.service';
import { Venue } from '../venues/venue.model';
import { environment } from '../../environments/environment';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { createComboBoxItemElement } from '../shared/mi-combo-box/mi-combo-box';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MediaLibraryComponent } from '../media-library/media-library.component';
import { MediaLibrarySource } from '../media-library/media.enum';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnChanges, AfterViewInit, OnDestroy {
    @ViewChild('solutionSelector', { static: true }) solutionSelectorElement: ElementRef<HTMLMiDropdownElement>;

    /**
     * Solution input setter.
     */
    @Input() set solutions(solutions: Solution[]) {
        if (solutions.length <= 0) {
            return;
        }

        this._solutions = solutions;

        const comboBoxItems: HTMLMiDropdownItemElement[] = solutions.map(solution => {
            return createComboBoxItemElement(solution.name, solution.id);
        });

        const { id: currentSolutionId } = this.solutionService.getStaticSolution();
        comboBoxItems.find(({ value: solutionName }) => solutionName === currentSolutionId).selected = true;

        this.solutionSelectorElement.nativeElement.items = comboBoxItems;
    }

    @Input() appLink: string;
    @Input() userImageUrl: string;
    @Input() menus: MenuNode[] = [];
    @Input() venues: Venue[] = [];
    @Output() solutionChangedEvent = new EventEmitter<Solution>();
    @Output() newsEvent = new EventEmitter();

    public subMenu: MenuNode[] = [];
    public subSubMenu: MenuNode[] = [];
    public activeMenu: string = null;
    public mapLink: string = environment.startPage;
    public profilePictureUrl = '../../assets/account-circle-white.svg';

    public subscription = new Subscription;
    public mapBoxEnabled = false;

    private _solutions: Solution[] = [];
    private mediaLibraryModalConfig: MatDialogConfig = {
        width: '90vw',
        minWidth: '1024px', // smallest screen size supported by CMS
        maxWidth: '1700px', // the number is a subject to change after UX has tested properly
        height: '85vh',     // the number is a subject to change after UX has tested properly
        maxHeight: '928px', // the number is a subject to change after UX has tested properly
        minHeight: '550px', // the number is a subject to change after UX has tested properly
        role: 'dialog',
        panelClass: 'details-dialog',
        disableClose: true
    };

    constructor(
        private authService: AuthService,
        private router: Router,
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private matIconRegistry: MatIconRegistry,
        private matDialog: MatDialog,
        private domSanitizer: DomSanitizer) {
        this.matIconRegistry.addSvgIcon(
            'mapsindoors',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/mapsindoors.svg')
        );

        this.subscription = this.solutionService.selectedSolution$.subscribe((solution) => {
            this.mapBoxEnabled = solution?.modules?.includes('mapbox');
        });
    }

    /** NgOnChanges. */
    ngOnChanges(changes: SimpleChanges): void {
        // Set the active top menu.
        if (typeof changes['menus'] !== 'undefined') {
            const menus = changes['menus'].currentValue as MenuNode[];
            const activeUrl = this.router.url;
            const menu = menus.find(menu => {
                return menu.url === activeUrl
                    || menu.children?.some(child => child.url === activeUrl
                        || child.children?.some(grandchild => grandchild.url === activeUrl));
            });

            this.activeMenu = menu?.name;
            this.subMenu = menu?.children || [];

            // find App Settings sub-menu
            this.subSubMenu = activeUrl.includes('/app-settings')
                ? menu?.children?.find(child => child.url === '/app-settings')?.children || []
                : [];
        }
    }

    /** NgAfterViewInit. */
    ngAfterViewInit(): void {
        this.authService.getUserProfile()
            .pipe(take(1))
            .subscribe(
                userInfo => this.profilePictureUrl = userInfo?.profile_picture_url || this.profilePictureUrl,
                error => this.notificationService.showError(error)
            );
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Emits solution changed event.
     *
     * @param {Solution} solution
     * @memberof HeaderComponent
     */
    public onSolutionChange({ detail }: CustomEvent): void {
        const solutionId: string = (detail as HTMLMiDropdownItemElement[]).map(item => item.value).toString();
        const selectedSolution: Solution = this._solutions.find(solution => solution.id === solutionId);

        this.solutionChangedEvent.emit(selectedSolution);
    }

    /**
     * Opens the Media Librray.
     */
    public openMediaLibrary(): void {
        this.matDialog.open(MediaLibraryComponent, { ...this.mediaLibraryModalConfig, data: { disableClose: true, source: MediaLibrarySource.General } });
    }

    /**
     * Log out.
     *
     * @memberof HeaderComponent
     */
    public logOut(): void {
        this.authService.logout();
    }

    public onImgError(e): void { 
        e.target.src = '../../assets/account-circle-white.svg';
    }
}
