import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { OccupantTemplatesListComponent } from './occupant-templates-list.component';
import { OccupantTemplatesRoutingModule } from './occupant-templates-routing.module';
import { OccupantTemplateEditorComponent } from './occupant-template-editor/occupant-template-editor.component';
import { LocationsModule } from '../locations/location.module';

@NgModule({
    declarations: [
        OccupantTemplatesListComponent,
        OccupantTemplateEditorComponent
    ],
    imports: [
        SharedModule,
        OccupantTemplatesRoutingModule,
        LocationsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OccupantTemplatesModule { }
