import { environment } from '../../../environments/environment';
import { MediaCategory } from '../media.enum';

export interface IMediaItem {
    id: string,
    name: string,
    type: string,
    width: number,
    height: number,
    solutionId?: string,
    preview?: any,
    sizeBytes?: number,
    labels?: string[],
    category?: MediaCategory,
    lastModified?: string,
    references?: {
        appConfigCategoryKeys: string[],
        buildingIds: string[],
        categoryIds: string[],
        isUsedBySolutionConfig: boolean,
        locationIds: string[],
        locationTypeIds: string[],
        referencesCount: number,
        venueIds: string[]
    },
    url?: string,
    selected?: boolean,
    stretchX?: [number, number][],
    stretchY?: [number, number][],
    content?: [number, number, number, number]
}

export class MediaItem implements IMediaItem {
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly width: number;
    readonly height: number;
    readonly solutionId?: string;
    readonly sizeBytes?: number;
    readonly labels?: string[];
    readonly stretchX?: [number, number][];
    readonly stretchY?: [number, number][];
    readonly content?: [number, number, number, number];
    readonly references?: {
        appConfigCategoryKeys: string[],
        buildingIds: string[],
        categoryIds: string[],
        isUsedBySolutionConfig: boolean,
        locationIds: string[],
        locationTypeIds: string[],
        referencesCount: number,
        venueIds: string[]
    };
    readonly url: string;
    category?: MediaCategory;
    preview?: any;
    lastModified?: string;
    selected: boolean = false;

    constructor(mediaItem: IMediaItem) {
        Object.assign(this, mediaItem);
        this.url = this.createUrl(mediaItem.category);
    }

    /**
     * Create the URL for the media item based on its category.
     *
     * @returns {string}
     */
    private createUrl(category: MediaCategory): string {
        let url: string;
        switch (category) {
            case MediaCategory.MIIcon:
            case MediaCategory.MILabel: {
                url = this.preview;
                break;
            }
            case MediaCategory.Image: {
                //Rewrite the file type to png if the media item is an SVG.
                const type = this.type?.toLocaleLowerCase() === 'svg' ? 'png' : this.type;
                url = `${environment.MediaEndpoint}${this.solutionId}/media/${encodeURIComponent(this.name)}.${type}`;
                break;
            }
            case MediaCategory.Model3D: {
                // 3D model links end with '.glb' but in the future they may end with '.gltf'
                url = `${environment.MediaEndpoint}${this.solutionId}/media/${encodeURIComponent(this.name)}.${this.type}`;
                break;
            }
        }
        return url;
    }
}