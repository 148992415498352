import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MediaLibraryService } from '../media-library.service';
import { NotificationService } from '../../services/notification.service';
import { MediaAcceptedFileType, MediaFileType, MediaUploadError } from '../media.enum';
import { IMediaItem } from '../media-item/media-item.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MediaUpload3DComponent } from '../media-upload-3d/media-upload-3d.component';
import { MediaErrorComponent } from '../media-error/media-error.component';
import { MediaFileUploadRequestBody } from '../media-request-upload.model';
import { removeBase64Prefix } from '../media-library.shared';
import { toHexString } from '../../../utilities/string';

@Component({
    selector: 'media-upload',
    templateUrl: './media-upload.component.html',
    styleUrls: ['./media-upload.component.scss']
})

export class MediaUploadComponent implements OnInit {
    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

    @Input() public isUploadEnabled: boolean;

    // All svg tags that are not suppoerted in the backend.
    private readonly unsupportedTags: string[] = ['feBlend', 'feColorMatrix', 'feComposite', 'feFlood', 'feGaussianBlur', 'feMerge', 'feMergeNode', 'feOffset', 'filter', 'foreignObject', 'image', 'mask', 'namedview', 'perspective', 'pgf', 'pgfRef', 'script', 'switch'];
    private readonly MediaUploadErrorMessages = {
        [MediaUploadError.FileTypeError]: 'File must be of type svg, png, jpg/jpeg or glb.',
        [MediaUploadError.MaxFileSizeError]: 'File must not be more than 8MB.',
        [MediaUploadError.MinFileSizeError]: 'File must be more than 0MB.',
        [MediaUploadError.ExistingFileNameError]: 'The uploaded file has the same name as another file in the Media Library. Please rename your file before uploading.',
        [MediaUploadError.UploadedFileNameError]: 'You cannot upload files with the same name. Please rename your files before uploading again.',
        [MediaUploadError.UnsupportedElements]: 'The file cannot contain <a href="https://docs.mapsindoors.com/cms-2d-models/#uploading-svgs" target="_blank">unsupported elements</a>.',
        [MediaUploadError.NoError]: 'No error'
    };
    private readonly maxFileSize: number = 8388608; // (8 * 1024 * 1024) 8MB
    private readonly maxCollectiveFileSize: number = 16777216; // (16 * 1024 * 1024) 16MB
    private mediaItems: IMediaItem[] = [];
    private validImages: { mediaName: string }[] = [];
    private invalidImages: { mediaName: string, mediaType: string, errors: string[] }[] = [];
    private mediaUpload3DModalConfig: MatDialogConfig = {
        width: '90vw',
        minWidth: '1024px', // smallest screen size supported by CMS
        maxWidth: '1700px', // the number is a subject to change after UX has tested properly
        height: '85vh',     // the number is a subject to change after UX has tested properly
        maxHeight: '928px', // the number is a subject to change after UX has tested properly
        minHeight: '550px', // the number is a subject to change after UX has tested properly
        role: 'dialog',
        panelClass: 'details-dialog',
        disableClose: true
    };
    private mediaErrorModalConfig: MatDialogConfig = {
        minWidth: '792px',
        maxHeight: '75vh',
        role: 'dialog',
        panelClass: 'details-dialog',
        maxWidth: '1024px',
        minHeight: 'fit-content',
    };

    /**
     * Returns the list of acceptable file types.
     *
     * @returns {Array<string>}
     */
    public get acceptedFileTypes(): Array<string> {
        return Object.keys(MediaAcceptedFileType);
    }

    constructor(
        private mediaLibraryService: MediaLibraryService,
        private notificationService: NotificationService,
        private matDialog: MatDialog
    ) {
    }

    /**
     * A lifecycle hook that is called after Angular has initialized.
     */
    ngOnInit(): void {
        this.mediaLibraryService.mediaItems$
            .subscribe(
                (items) => {
                    this.mediaItems = items;
                });
    }

    /**
     * First reads the uploaded media from the input field, validates it, and sends a POST request to the backend.
     */
    public async uploadMedia(): Promise<void> {
        const fileUploadPromises = [];
        const fileUploads = Array.from(this.fileInput.nativeElement.files);
        const uploadedFileNames: string[] = this.getUploadedFileNames(fileUploads);
        this.fileInput.nativeElement.value = '';

        if (!fileUploads[0]) {
            return;
        }
        if (!this.checkFileCount(fileUploads)) {
            this.notificationService.showError('You can maximum upload 100 files at a time.');
            return;
        }
        if (!this.collectiveFileSizeIsValid(fileUploads)) {
            this.notificationService.showError('You cannot upload files of more than 16MB at a time.');
            return;
        }

        for (const file of fileUploads) {
            // If the uploaded media file is valid, it will get pushed into the array of items that will be uploaded.
            await this.checkIfFileIsValid(file, uploadedFileNames)
                .then((isFileValid) => {
                    if (isFileValid) {
                        fileUploadPromises.push(this.createFileToUpload(file));
                    }
                });
        }

        Promise.all(fileUploadPromises)
            .then((requestBodies: MediaFileUploadRequestBody[]) => requestBodies.filter((item) => item !== null))
            .then((requestBodies: MediaFileUploadRequestBody[]) => {
                if (requestBodies.length > 0) {
                    this.uploadOnloaded(requestBodies);
                }

                if (this.invalidImages.length > 0) {
                    const dialog = this.matDialog.open(MediaErrorComponent, { ...this.mediaErrorModalConfig, data: { failed: this.invalidImages, uploaded: requestBodies } });
                    dialog.afterClosed().subscribe(() => {
                        this.invalidImages = [];
                        this.validImages = [];
                    });
                }
            });
    }

    /**
     * Returns the array of the uploaded file names.
     *
     * @param {File[]} fileUploads
     * @returns {string[]}
     */
    private getUploadedFileNames(fileUploads: File[]): string[] {
        return fileUploads.map(file => this.getFileNameWithoutExtension(file));
    }

    /**
     * Returns false if the array of files contains more than 100 files.
     *
     * @param {File[]} fileUploads
     * @returns {boolean}
     */
    private checkFileCount(fileUploads: File[]): boolean {
        return fileUploads.length > 100 ? false : true;
    }

    /**
     * Returns false if the files' collective size is bigger than 150mb.
     *
     * @param {File[]} fileUploads
     * @returns {boolean}
     */
    private collectiveFileSizeIsValid(fileUploads: File[]): boolean {
        let collectiveSize = 0;
        Array.from(fileUploads).forEach(file => {
            collectiveSize += file.size;
        });

        return collectiveSize <= this.maxCollectiveFileSize;
    }

    /**
     * Returns the file name without the extension.
     *
     * @param {File} file
     * @returns {string}
     */
    private getFileNameWithoutExtension(file: File): string {
        return file.name.substring(0, file.name.lastIndexOf('.'));
    }

    /**
     * Creates a new Promise for a file that was uploaded following the interface for a FileUpload.
     *
     * @param {File} file
     * @returns {Promise<MediaFileUploadRequestBody>}
     */
    private createFileToUpload(file: File): Promise<MediaFileUploadRequestBody> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.addEventListener('load', () => {
                // The Backend expects the data to be without the Data-URL declaration (E.g.: data:image/jpeg;base64)
                const base64result = removeBase64Prefix(reader.result as string);
                this.createRequestBodyFile(base64result, file)
                    .then((fileToUpload: MediaFileUploadRequestBody) => resolve(fileToUpload));
            });

            reader.addEventListener('error', () => {
                this.notificationService.showError(reader.error);
            });
        });
    }

    /**
     * Returns an object to insert into the requestBody later.
     *
     * @param {string} base64result
     * @param {File} file
     * @returns {MediaFileUploadRequestBody}
     */
    private async createRequestBodyFile(base64result: string, file: File): Promise<MediaFileUploadRequestBody> {
        const name: string = this.getFileNameWithoutExtension(file);
        const type: string = await this.getMediaType(file);

        const requestBodyFile: MediaFileUploadRequestBody = {
            'name': name,
            'type': type,
            'data': base64result
        };

        return new Promise((resolve) => {
            if (type !== MediaFileType.GLB) {
                return resolve(requestBodyFile);
            } else {
                const dialog = this.matDialog.open(MediaUpload3DComponent, { ...this.mediaUpload3DModalConfig, data: { file: file } });
                dialog.afterClosed()
                    .subscribe((response) => {
                        if (!response) {
                            this.notificationService.showInfo(`The file ${file.name} was not uploaded.`);
                            return resolve(null);
                        } else {
                            const previewSrc = removeBase64Prefix(response?.previewSrc);
                            if (previewSrc) {
                                requestBodyFile.preview = previewSrc;
                                return resolve(requestBodyFile);
                            }
                        }
                    });
            }
        });
    }

    /**
     * Checks if the uploaded file has acceptable size/type/name and if it is present.
     *
     * @param {File} file
     * @param {string[]} uploadedFileNames
     * @returns {boolean}
     */
    private async checkIfFileIsValid(file: File, uploadedFileNames: string[]): Promise<boolean> {
        const currentMediaName = this.getFileNameWithoutExtension(file);
        const currentMediaType = await this.getMediaType(file);

        const mediaUploadErrors: MediaUploadError[] = [];
        mediaUploadErrors.push(this.checkIfFileTypeIsSet(currentMediaType));
        mediaUploadErrors.push(this.checkMaxFileSize(file));
        mediaUploadErrors.push(this.checkMinFileSize(file));
        mediaUploadErrors.push(this.checkFileName(currentMediaName));

        if (uploadedFileNames.length > 1) {
            mediaUploadErrors.push(this.checkUploadedFileNames(currentMediaName, uploadedFileNames));
        }

        if (currentMediaType === MediaFileType.SVG) {
            await this.checkFileForUnsupportedSVGTags(file).then((val) => mediaUploadErrors.push(val));
        }

        const validationErrors: string[] = [];
        for (const validation of mediaUploadErrors) {
            if (validation !== MediaUploadError.NoError) {
                validationErrors.push(this.MediaUploadErrorMessages[validation]);
            }
        }

        if (validationErrors.length > 0) {
            this.invalidImages.push({ mediaName: currentMediaName, mediaType: currentMediaType, errors: validationErrors });
            return Promise.resolve(false);
        } else {
            this.validImages.push({ mediaName: currentMediaName });
            return Promise.resolve(true);
        }
    }

    /**
     * If the uploaded media is of an unsupported file type, it will return an error.
     *
     * @param {string} currentMediaType
     * @returns {MediaUploadError}
     */
    private checkIfFileTypeIsSet(currentMediaType: string): MediaUploadError {
        return currentMediaType ? MediaUploadError.NoError : MediaUploadError.FileTypeError;
    }

    /**
     * If the uploaded media's size is too big, it will return an error.
     *
     * @param {File} file
     * @returns {MediaUploadError}
     */
    private checkMaxFileSize(file: File): MediaUploadError {
        return file?.size < this.maxFileSize ? MediaUploadError.NoError : MediaUploadError.MaxFileSizeError;
    }

    /**
     * If the uploaded media is missing, it will return an error.
     *
     * @param {File} file
     * @returns {MediaUploadError}
     */
    private checkMinFileSize(file: File): MediaUploadError {
        return file?.size > 0 ? MediaUploadError.NoError : MediaUploadError.MinFileSizeError;
    }

    /**
     * If the uploaded media's name is the same as another (previously) uploaded media's name, it will return an error.
     *
     * @param {string} currentMediaName
     * @returns {MediaUploadError}
     */
    private checkFileName(currentMediaName: string): MediaUploadError {
        const existingMediasWithName = this.mediaItems.filter(item => item.name.toLowerCase() === currentMediaName.toLowerCase());
        return existingMediasWithName.length > 0 ? MediaUploadError.ExistingFileNameError : MediaUploadError.NoError;
    }

    /**
     * If the uploaded media's name is the same as another (previously) uploaded media's name, it will return an error.
     *
     * @param {string} currentMediaName
     * @returns {MediaUploadError}
     */
    private checkUploadedFileNames(currentMediaName: string, uploadedFileNames: string[]): MediaUploadError {
        const uploadedMediasWithName = uploadedFileNames?.length > 1 ? uploadedFileNames?.filter(name => name.toLowerCase() === currentMediaName.toLowerCase()) : null;
        return uploadedMediasWithName?.length > 1 ? MediaUploadError.UploadedFileNameError : MediaUploadError.NoError;
    }

    /**
     * If the uploaded media contains unsupported SVG elements, it will return an error.
     *
     * @param {File} file
     * @returns {MediaUploadError}
     */
    private checkFileForUnsupportedSVGTags(file: File): Promise<MediaUploadError> {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
            reader.onload = () => {
                const resultAsText = reader.result as String;
                const resultData = atob(resultAsText.split(',')[1]);
                let hasError: boolean = false;

                for (const element of this.unsupportedTags) {
                    if (resultData.includes(element)) {
                        resolve(MediaUploadError.UnsupportedElements);
                        hasError = true;
                    }
                }

                hasError ? resolve(MediaUploadError.UnsupportedElements) : resolve(MediaUploadError.NoError);
            };
        });
    }

    /**
     * Returns the file type of an uplodaed file.
     *
     * @returns {MediaFileType}
     */
    private async getMediaType(file: File): Promise<MediaFileType> {
        if (file.type in MediaAcceptedFileType) {
            return MediaAcceptedFileType[file.type];
        } else if (await this.checkIfFileTypeIsGlb(file)) {
            return MediaFileType.GLB;
        } else {
            return null;
        }
    }

    /**
     * Check if the incoming file is type of glTF - applies to glb file types.
     *
     * @see https://docs.fileformat.com/3d/glb/#glb-header
     * @param {File} file
     * @returns {Promise<boolean>}
     */
    private checkIfFileTypeIsGlb(file: File): Promise<boolean> {
        return file.slice(0, 12).arrayBuffer().then(buffer => {
            const magic = toHexString(buffer.slice(0, 4)).toLowerCase();
            const length = parseInt(toHexString(buffer.slice(8, 12)), 16);
            //magic equals 0x46546c67. It is ASCII string glTF.
            return magic === '0x46546c67' && length === file.size;
        });
    }

    /**
     * Uploads the new media using the request body.
     * Clears the files from the input field and then the request body.
     */
    private uploadOnloaded(requestBody: MediaFileUploadRequestBody[]): void {
        this.mediaLibraryService.uploadNewMedia(requestBody)
            .subscribe(
                () => {
                    this.notificationService.showSuccess('Successfully uploaded.');
                    this.invalidImages = [];
                    this.validImages = [];
                },
                () => {
                    this.notificationService.showError('Something went wrong. Please try again.');
                }
            );
    }
}