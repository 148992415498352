import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum Tab {
    LocationDetails,
    OccupantDetails
}

export interface PanelTab {
    isActive: boolean;
    isDisabled: boolean;
    isToggleChecked?: boolean;
    id: Tab;
    title: string;
    hasToggle: boolean;
}

@Component({
    selector: 'panel-header-tab',
    templateUrl: './panel-header-tab.component.html',
    styleUrls: ['./panel-header-tab.component.scss']
})
export class PanelHeaderTabComponent {
    @Input() title: string;
    @Input() id: Tab;
    @Input() hasToggle?: boolean = false;
    @Input() isActive: boolean;
    @Input() isDisabled: boolean;
    @Input() isToggleChecked: boolean;

    @Output() tabClicked = new EventEmitter<Tab>();
    @Output() toggleChanged = new EventEmitter<Tab>();

    /**
     * On tab click emit the tab id.
     *
     * @param {Event} event
     * @param {Tab} id
     */
    public onTabClicked(event: Event, id: Tab): void {
        const targetElementTag = ((event.target as HTMLElement).tagName).toLowerCase();
        if (targetElementTag !== 'button') {
            return;
        }

        this.tabClicked.emit(id);
    }

    /**
     * On toggle change emit the tab id.
     *
     * @param {Event} event
     * @param {Tab} id
     */
    public onToggleChange(event: Event, id: Tab): void {
        this.toggleChanged.emit(id);
        // We reset the input's value to its previous value because we want the value to come from the parent component.
        (event.target as HTMLInputElement).checked = this.isToggleChecked;
    }
}