import { Observable } from 'rxjs';
import { BaseMapAdapter } from '../MapAdapter/BaseMapAdapter';

export abstract class GeodataEditor {
    protected map: google.maps.Map | mapboxgl.Map;
    protected constructor(protected readonly adapter: BaseMapAdapter) {
        adapter.getMap().then(map => this.map = map);
    }

    /**
     * Observable for map click event.
     *
     * @readonly
     * @abstract
     * @type {Observable<GeoJSON.Point>}
     * @memberof GeodataEditor
     */
    abstract get mapClick$(): Observable<GeoJSON.Point>;

    /**
     * Observable for mouse move event.
     *
     * @readonly
     * @abstract
     * @type {Observable<GeoJSON.Point>}
     * @memberof GeodataEditor
     */
    abstract get mouseMove$(): Observable<GeoJSON.Point>;

    /**
     * Observable for mouse hover event.
     *
     * @readonly
     * @abstract
     * @type {Observable<GeoJSON.Feature>}
     * @memberof GeodataEditor
     */
    abstract get mouseHover$(): Observable<GeoJSON.Feature>;

    /**
     * Observable for geometry click event.
     *
     * @readonly
     * @abstract
     * @type {Observable<GeoJSON.Feature>}
     * @memberof GeodataEditor
     */
    abstract get geometryClick$(): Observable<GeoJSON.Feature>;

    /**
     * Observable for click on geometry handles.
     *
     * @readonly
     * @abstract
     * @type {Observable<GeoJSON.Feature>}
     * @memberof GeodataEditor
     */
    abstract get handleClick$(): Observable<number>;

    /**
     * Observable for right click on geometry handles.
     *
     * @readonly
     * @abstract
     * @type {Observable<number>}
     * @memberof GeodataEditor
     */
    abstract get handleRightClick$(): Observable<number>;

    /**
     * Observable for drag event on geometry handles.
     *
     * @readonly
     * @abstract
     * @type {Observable<{ index: number, position: GeoJSON.Position }>}
     * @memberof GeodataEditor
     */
    abstract get handleDrag$(): Observable<{ index: number, position: GeoJSON.Position }>;

    abstract renderHandlesFor(geometry: GeoJSON.MultiPoint | GeoJSON.LineString | GeoJSON.Polygon): void;
    abstract renderAsHandles(features: GeoJSON.Feature<GeoJSON.Point>[]): void;
    abstract clear(): void;
    abstract clearHandles(): void;

    abstract render(features: GeoJSON.Feature[]);
}
